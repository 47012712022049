import React, { useContext } from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';

import { AuthContext } from 'authContext';
import { UserRole } from 'codegen/generated/graphql';
import Routes from 'router/routes';

const PrivateRoute: React.FC<{
  component: React.FC;
  path: string;
  exact: boolean;
}> = ({ component, path, exact }) => {
  const { user } = useContext(AuthContext);
  const { replace } = useHistory();

  if (user && user.roles.includes(UserRole.KioskCustomer)) {
    return <Route path={path} exact={exact} component={component} />;
  } else {
    replace(Routes.LOGIN.path);

    return <Redirect push to={Routes.LOGIN.path} />;
  }
};

export default PrivateRoute;
