import { Button } from 'antd';
import { Property as CsstypeProperty } from 'csstype';
import styled, { Color, css } from 'styled-components';

import {
  justifyContent,
  JustifyContentProps,
  display,
  alignItems,
  AlignItemsProps,
  FlexDirectionProps,
  flexDirection,
  position,
  PositonType,
  background,
} from 'utils/styleHelpers';

interface LinkProps {
  $padding?: string;
  $showTextColor?: boolean;
}

interface WrapperProps {
  $padding?: string;
  $flex?: number;
  $display?: CsstypeProperty.Display;
  $justify?: JustifyContentProps;
  $align?: AlignItemsProps;
  $margin?: string;
  $flexDirection?: FlexDirectionProps;
  $height?: string;
  $width?: string;
  $position?: PositonType;
  $rightPos?: string;
}

export const FlexColumn = css`
  display: flex;
  flex-direction: column;
`;

export const ButtonLink = styled(Button)<LinkProps>(
  ({ $padding = '0px 16px', $showTextColor = false }) => `
  cursor: pointer;
  color: black;
  background: transparent;
  border: none !important;
  transition: none !important;
  padding: ${$padding};
  font-size: 16px !important;
  height: 22px !important;
  span {
    border: none !important;
    text-decoration: underline;
  } 
  :hover, :focus, :active {
    background: transparent;
    color: ${$showTextColor ? 'black' : 'transparent'};
  }
  `,
);

export const Container = styled.div<{ isDelivery: boolean }>(
  ({ isDelivery }) => `
  padding-top: 50px;
  display: flex;
  height: ${isDelivery ? '450px' : '400px'};
  justify-content: center;
  overflow: hidden;
  margin: 0 auto;

  .ant-image {
    margin: 0 auto;
  }
  `,
);

export const SideBarContainer = styled.div`
  ${FlexColumn}
  height: 100%;
`;

export const OrderInfoContainer = styled.div`
  ${FlexColumn}
`;

export const SideCartContainer = styled.div`
  ${FlexColumn}
  flex: 1;
  overflow-y: scroll;
  padding: 16px;
`;

export const Box = styled.div<{ bgColor: keyof Color; height: number }>(
  ({ bgColor, height }) => `
  ${background(bgColor)}
  ${FlexColumn}
  justify-content: center;
  height: ${height}px;
  width: 100%;
  padding: 20px;
`,
);

export const AntButtonWrapper = styled.div<WrapperProps>(
  ({
    $padding = '0px',
    $flex,
    $display = 'inline',
    $justify = 'center',
    $align = 'center',
    $margin = '0 auto',
    $flexDirection = 'column',
    $height,
    $width,
    $position = 'relative',
    $rightPos,
  }) => `
  ${display($display)}
  ${justifyContent($justify)}
  ${alignItems($align)}
  ${flexDirection($flexDirection)}
  ${position($position)}
  padding: ${$padding};
  right: ${$rightPos};
  flex: ${$flex};
  margin: ${$margin} !important;
  height: ${$height};
  width: ${$width};
  margin-bottom: 80px !important;

  .ant-btn {
    border-color: #000000;
    border-width: 2px;
    height: 56px;
    font-weight: 600;
  }
`,
);
