import React from 'react';

import Button from 'components/Button';
import LandingLayout from 'containers/LandingLayout';

const Login: React.FC = () => {
  return (
    <LandingLayout>
      <Button
        size="large"
        type="primary"
        text="Test Sentry Error Reporting"
        onClick={() => {
          throw new Error('test error in kiosk');
        }}
      />
    </LandingLayout>
  );
};

export default Login;
