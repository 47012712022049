import * as Sentry from '@sentry/browser';

import { isProduction, isStaging } from './env';

const enableSentry =
  (isStaging || isProduction) &&
  !window?.location.hostname.includes('localhost');

export const initSentry = () => {
  Sentry.init({
    enabled: enableSentry,
    dsn: process.env.REACT_APP_SENTRY_DSN_KIOSK,
    environment: process.env.REACT_APP_CUSTOM_ENV,
    ignoreErrors: [
      'Network Error',
      'Network request failed',
      'Loading chunk',
      "Unexpected token '<'",
    ],
  });
};
