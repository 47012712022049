import React, { useEffect, useContext, useReducer } from 'react';

import InfoModal from 'components/InfoModal';
import Routes from 'router/routes';
import { AppContext } from 'utils/context';

import { MODAL_IDLE_COUNTDOWN } from './constants';

interface Props {
  isVisible: boolean;
  onAcceptClick: () => void;
  onTimeout: () => void;
}

const initialState = {
  isRunning: false,
  time: MODAL_IDLE_COUNTDOWN,
  timeout: false,
};

const IdleTimeout = ({ isVisible, onAcceptClick, onTimeout }: Props) => {
  const { isPaymentProcessing, emptyCart } = useContext(AppContext);

  const reducer = (state: any, action: any) => {
    switch (action.type) {
      case 'setCountDown':
        return { ...state, isRunning: true };
      case 'resetCountDown':
        return { ...state, isRunning: false, time: MODAL_IDLE_COUNTDOWN };
      case 'tickCountDown':
        return { ...state, time: state.time - 1 };
      case 'timeOut':
        return { isRunning: true, timeout: true, time: MODAL_IDLE_COUNTDOWN };
      default:
        return { ...state };
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const handleAcceptClick = () => {
    onAcceptClick();
    dispatch({ type: 'resetCountDown ' });
  };

  const routeCheck =
    window.location.hash !== Routes.LOGIN.hash &&
    window.location.hash !== Routes.CLOSED.hash;

  const timeoutValid =
    routeCheck && !isPaymentProcessing && isVisible && !emptyCart;

  useEffect(() => {
    if (!timeoutValid && !isVisible && !state.isRunning) {
      dispatch({ type: 'setCountDown' });

      return;
    }

    if (state.time > 0) {
      const timeOut = setInterval(
        () => dispatch({ type: 'tickCountDown' }),
        1000,
      );

      return () => {
        clearInterval(timeOut);
      };
    }

    if (state.time <= 0 && isVisible) {
      dispatch({ type: 'resetCountDown' });
      onTimeout();
    } else {
      dispatch({ type: 'resetCountDown' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeoutValid, isVisible, state.time]);

  return (
    <>
      <InfoModal
        message="You still there?"
        buttonText="Yes, I'm here"
        width="364px"
        basic
        isIdleTimeOut
        onClickAction={handleAcceptClick}
        title={state.time.toString()}
        visible={timeoutValid}
        titleFontSize="56px"
      />
    </>
  );
};

export default IdleTimeout;
