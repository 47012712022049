import React from 'react';

import { Button } from 'antd';

import { SizeType } from 'antd/lib/config-provider/SizeContext';
import Text from 'components/Text';

import { QuantitySelectorWrapper } from './styles';

interface Props {
  handleAddBtnClick: () => void;
  handleMinusBtnClick: () => void;
  quantity: number;
  size?: SizeType;
}

const QuantitySelector = ({
  handleAddBtnClick,
  handleMinusBtnClick,
  quantity,
  size,
}: Props) => {
  return (
    <QuantitySelectorWrapper>
      <Button
        shape="circle"
        size={size ? size : 'middle'}
        onClick={handleMinusBtnClick}
      >
        -
      </Button>
      <Text fontSize="sm" fontWeight="bold">
        {quantity}
      </Text>
      <Button
        shape="circle"
        size={size ? size : 'middle'}
        onClick={handleAddBtnClick}
      >
        +
      </Button>
    </QuantitySelectorWrapper>
  );
};

export default QuantitySelector;
