import React from 'react';
import { useHistory } from 'react-router-dom';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Layout, Image } from 'antd';

import { ItemWrapper, Row } from 'components/Layout';
import Routes from 'router/routes';
import KeyboardHOC from 'utils/context/keyboard';

import { Container, StyledLayout, GoBackButton } from './styles';

const { Content } = Layout;

const SettingsLayout: React.FC = ({ children }) => {
  const { push } = useHistory();

  return (
    <Container>
      <StyledLayout>
        <Content>
          <Row>
            <GoBackButton onClick={() => push(Routes.LOGIN.path)}>
              <ArrowLeftOutlined />
              Go Back
            </GoBackButton>
            <ItemWrapper>
              <Image
                src="./svg/crave-black-plain.svg"
                width={152}
                alt="Crave Logo"
              />
            </ItemWrapper>
          </Row>
          <KeyboardHOC>{children}</KeyboardHOC>
        </Content>
      </StyledLayout>
    </Container>
  );
};

export default SettingsLayout;
