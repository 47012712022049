import { Property as CsstypeProperty } from 'csstype';
import styled, { Color } from 'styled-components';

import { theme } from 'theme';
import {
  alignItems,
  radius,
  color,
  background,
  border,
  flexDirection,
  justifyContent,
  JustifyContentProps,
  display,
  position,
  PositonType,
  FlexDirectionProps,
  AlignSelfProps,
  alignSelf,
  SpacingType,
  marginT,
  marginL,
} from 'utils/styleHelpers';

export const StyledWrapper = styled.div<{
  $padding: string;
  $position: PositonType;
  $left: string;
  $flexDirection: FlexDirectionProps;
  $display: CsstypeProperty.Display;
  $justifyContent: JustifyContentProps;
  $alignSelf: AlignSelfProps;
  $flex: number;
  $marginT: SpacingType;
  $marginLeft: SpacingType;
  $border: number;
  $borderColor: keyof Color;
  primary?: boolean;
  secondary?: boolean;
  skeleton?: boolean;
  selected?: boolean;
}>(
  ({
    $padding,
    $position,
    $left,
    $flexDirection,
    $display,
    $justifyContent,
    $alignSelf,
    $flex,
    $marginT,
    $marginLeft,
    $border,
    $borderColor,
    primary,
    secondary,
    skeleton,
    selected,
  }) => `
  ${marginL($marginLeft)}
  ${display($display)}
  .ant-btn {
    height: 56px;
    ${alignItems('center')}
    ${radius('xxl')}
    ${color('black')}
    ${primary ? primaryFn('normal') : ''}
    ${
      secondary
        ? selected
          ? secondaryFn('selected')
          : secondaryFn('normal')
        : ''
    }
    ${skeleton ? skeletonFn() : ''}
    ${border($border)}
    ${flexDirection($flexDirection)}
    ${justifyContent($justifyContent)}
    ${display($display)}
    ${position($position)}
    ${alignSelf($alignSelf)}
    ${marginT($marginT)}
    border-color: ${$borderColor};
    flex: ${$flex};
    cursor: pointer;
    font-weight: ${theme.fontWeight.thin};
    left: ${$left};
    padding: ${$padding};
    &:active {
      border: none !important;
      ${primary && primaryFn('selected')};
    }
    .ant-btn[ant-click-animating-without-extra-node]:after {
      transition: none !important;
    } 
  }
  .ant-btn.ant-btn-lg {
    height: 80px;
  }

  .ant-btn.ant-btn-sm {
    height: 40px;
  }

  & [ant-click-animating-without-extra-node]:after {
    animation: 0.1s !important;
 }
 .ant-btn.ant-btn-background-ghost::after {
  border: none !important;
  ${secondaryFn('selected')}
  }
  
  .ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active {
    ${primary && primaryFn('disabled')};
    ${secondary && secondaryFn('disabled')};
  }
`,
);

type stateProps = 'normal' | 'disabled' | 'selected';

export const primaryFn = (state?: stateProps) => {
  let css = '';
  switch (state) {
    case 'selected':
      css += background(['gray', 0.8]);
      break;
    case 'disabled':
      css += background(['gray', 0.6]);
      break;
    default:
      css += background('black');
      css += 'font-color: white !important;';
      break;
  }

  return css;
};

export const secondaryFn = (state?: stateProps) => {
  let css = '';
  switch (state) {
    case 'selected':
      css += background(['gray', 1]);
      break;
    case 'disabled':
      css += background(['gray', 0.6]);
      break;
    default:
      css += background('white');
      break;
  }

  return css;
};

export const skeletonFn = () => {
  return background('transparent');
};
