import React, { useState, useMemo, useContext } from 'react';

import {
  DishType,
  InventoryStatus,
  KitchenMenuItem,
} from 'codegen/generated/graphql';
import ContentModal from 'components/ContentModal';
import { Logo } from 'components/Image';
import InfoModal from 'components/InfoModal';
import { MenuItem } from 'utils/apollo/models';
import { useAppContext } from 'utils/context';
import { CartContext } from 'utils/context/cartContext';
import { menuItemIdLogger } from 'utils/helpers';

import MenuItemModalBody from './components/MenuItemModalBody';
import { setItemQuantity, addToCart } from './helpers';

interface Props {
  id?: string;
  kitchenMenuItemId: string;
  kitchenMenuItem: KitchenMenuItem;
  visible: boolean;
  handleModalClose: () => void;
  cartMenuItem?: MenuItem;
  isCheckout?: boolean;
}

const MenuItemModal = ({
  id,
  handleModalClose,
  visible,
  cartMenuItem,
  isCheckout,
  kitchenMenuItem,
}: Props) => {
  const [isAgeRestricted, setAgeRestricted] = useState(false);
  const { ageConfirmed, setAgeConfirmed, menuItems } = useAppContext();
  const { setRecalculate } = useContext(CartContext);

  const menuItem = menuItems;
  const quantity = menuItems.quantity;
  const menuItemImg = kitchenMenuItem?.imgUrl || '';
  const ResturauntLogoImg = kitchenMenuItem?.kitchen.logoUrl || '';
  const soldOut = kitchenMenuItem?.inventoryStatus === InventoryStatus.SoldOut;

  const isAlcohol = useMemo(() => {
    return kitchenMenuItem?.dishTypes.includes(DishType.Alcohol);
  }, [kitchenMenuItem]);

  const showSubTotal = useMemo(() => {
    if (
      quantity > 1 ||
      menuItem.selectedExtras.length > 0 ||
      (isCheckout && cartMenuItem && cartMenuItem.selectedExtras.length > 0)
    )
      return true;
    else return false;
  }, [cartMenuItem, isCheckout, quantity, menuItem.selectedExtras.length]);

  const handleAddCartClick = () => {
    menuItemIdLogger(kitchenMenuItem?.id);
    if (ageConfirmed === true || !isAlcohol) {
      addToCart({
        menuItem: cartMenuItem ?? menuItems,
        isEdit: isCheckout ?? false,
      });
      setRecalculate(true);
      handleModalClose();

      return false;
    } else if (isAlcohol && ageConfirmed === false) {
      return true;
    } else {
      return false;
    }
  };

  const handleAgeConfirm = () => {
    setAgeRestricted(false);
    setAgeConfirmed(true);
    addToCart({
      menuItem: cartMenuItem ?? menuItems,
      isEdit: isCheckout ?? false,
    });
    handleModalClose();
  };

  return (
    <>
      <ContentModal
        visible={visible}
        width="860px"
        onCancel={() => {
          handleModalClose();
        }}
        body={
          <MenuItemModalBody
            resturauntLogoImg={ResturauntLogoImg}
            name={kitchenMenuItem?.name ?? ''}
            description={kitchenMenuItem?.description ?? ''}
            allergies={kitchenMenuItem?.allergies ?? []}
            dishTypes={kitchenMenuItem?.dishTypes ?? []}
            price={kitchenMenuItem?.price ?? 0}
            extras={kitchenMenuItem?.extras ?? []}
            isCheckout={isCheckout ?? false}
            cartMenuItem={cartMenuItem}
            menuItem={menuItem}
            itemQuantity={quantity}
            soldOut={soldOut}
            setItemQuantity={(quantity) =>
              setItemQuantity({
                quantity,
                menuItemId: menuItem.id,
                cartMenuItemId: id,
                isCheckout: isCheckout ?? false,
              })
            }
            showSubTotal={showSubTotal}
            handleAddCartClick={() => {
              setAgeRestricted(handleAddCartClick());
            }}
          />
        }
        headerImg={
          <Logo
            src={menuItemImg || './svg/menu-item.svg'}
            width="860px"
            height="500px"
          />
        }
      />
      <InfoModal
        title="You must be at least 21 years old to order this item."
        visible={isAgeRestricted}
        buttonText="I am 21 or older"
        basic={true}
        message="Please have your ID ready for our staff when you pick up your items."
        onClickBack={() => setAgeRestricted(false)}
        onClickAction={handleAgeConfirm}
      />
    </>
  );
};

export default MenuItemModal;
