export const USER_IDLE_COUNTDOWN =
  parseInt(process.env.REACT_APP_USER_IDLE_COUNTDOWN || '') || 30;
export const MODAL_IDLE_COUNTDOWN = 15;
export const IDLE_EVENTS = [
  'click',
  'scroll',
  'keydown',
  'touchstart',
  'touchend',
  'touchmove',
];
