import { ReactiveVar } from '@apollo/client';

import { menuItemInitialValue } from '../localStore';
import { MenuItem } from '../models';

export default function deleteMenuItem(menuItemVar: ReactiveVar<MenuItem>) {
  return (id: string) => {
    const menuItem = menuItemVar();
    if (id === menuItem.id || id === '') {
      menuItemVar(menuItemInitialValue);
    }
  };
}
