import React from 'react';

import Button from 'components/Button';
import { Row, ItemWrapper, Divider } from 'components/Layout';
import Text from 'components/Text';
import { ButtonLink } from 'containers/SideBar/styles';
import { cartMutations } from 'utils/apollo/mutations';
import { priceFormatter } from 'utils/formatHelpers';

interface Props {
  menuItem: any;
  setMenuItemModal: (id: string, menuItem: any) => void;
  setRemoveModal: (id: string) => void;
}

const CartMenuItem = ({
  menuItem,
  setMenuItemModal,
  setRemoveModal,
}: Props) => {
  return (
    <>
      <Row $justifyContent="space-evenly" $padding="1px 6px" $flex={1}>
        <Text fontSize="lg" fontWeight="semiBold" flex="20">
          {menuItem.name}
        </Text>
        <Text fontSize="xs" flex="1">
          {priceFormatter(menuItem.price)}
        </Text>
      </Row>
      {menuItem.selectedExtras.map((extra: any) => {
        return (
          <Row
            key={extra.item.id}
            $justifyContent="space-evenly"
            $padding="5px 6px"
          >
            <Row $justifyContent="flex-start">
              <Text marginRight="md" fontSize="xs">
                {extra.quantity}X
              </Text>

              <Text fontSize="xs">{extra.item.name}</Text>
            </Row>
            <Text fontSize="xs">
              {priceFormatter(extra.item.price * extra.quantity)}
            </Text>
          </Row>
        );
      })}
      <Divider />
      <Row $justifyContent="space-between" $padding="0px 6px 50px">
        <ItemWrapper
          $display="flex"
          $justify="flex-start"
          $align="baseline"
          $flexDirection="row"
          $margin="0"
        >
          <Button
            onClick={() => setRemoveModal(menuItem.id)}
            text="Remove"
            padding="0px 3px 0px 0px"
            display="flex"
            warning
            skeleton
          />
          <ButtonLink
            $showTextColor={true}
            onClick={() => {
              setMenuItemModal(menuItem.id, menuItem);
              cartMutations.createMenuItem(menuItem.kitchenMenuItem);
            }}
          >
            Edit
          </ButtonLink>
        </ItemWrapper>
        <Text
          marginTop={10}
          marginLeft="md"
          fontWeight="semiBold"
          fontSize="xs"
        >
          {priceFormatter(menuItem.totalPrice)}
        </Text>
      </Row>
    </>
  );
};

export default CartMenuItem;
