import React from 'react';

import { Layout } from 'antd';

import SideBar from 'containers/SideBar';

import { AntLayout, AntSider } from './styles';

type Props = {
  orderName: string;
};

const { Content } = Layout;

const MenuLayout: React.FC<Props> = ({ children, orderName }) => {
  return (
    <AntLayout>
      <Layout>
        <Content>{children}</Content>
      </Layout>
      <AntSider>
        <SideBar orderName={orderName} />
      </AntSider>
    </AntLayout>
  );
};

export default MenuLayout;
