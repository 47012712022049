import React from 'react';

import { Allergy, DishType } from 'codegen/generated/graphql';
import { Row, ItemWrapper } from 'components/Layout';
import MenuItemTag from 'components/MenuItemTag';
import Text from 'components/Text';
import { allergyValues, dishTypeValues } from 'utils/constants';
import { priceFormatter } from 'utils/formatHelpers';

interface Props {
  name: string;
  description: string;
  allergies: Allergy[];
  dishTypes: DishType[];
  price: number;
}

const ItemDetail = ({
  name,
  description,
  allergies,
  dishTypes,
  price,
}: Props) => {
  return (
    <ItemWrapper
      $flexDirection="column"
      $display="flex"
      $align="start"
      $margin="15px 0px 0px 0px"
    >
      <Text alignSelf="flex-start" fontSize="h2" fontWeight="bold">
        {name}
      </Text>
      <Text fontSize="lg" fontWeight="normal" marginTop="lg">
        {description}
      </Text>
      <Row
        $width="fit-content"
        $flex={2}
        style={{ marginTop: 30 }}
        $justifyContent="flex-start"
      >
        {allergies.map((allergy) => {
          const tagLabel = allergyValues.filter((i) => i.value === allergy);

          return (
            <MenuItemTag
              key={allergy}
              tagLabel={tagLabel[0].label}
              color="black"
            />
          );
        })}
        {dishTypes.map((dishType) => {
          const tagLabel = dishTypeValues.filter((i) => i.value === dishType);

          return (
            <MenuItemTag
              key={dishType}
              tagLabel={tagLabel[0].label}
              color="black"
            />
          );
        })}
      </Row>
      <Text fontSize="h2" fontWeight="bold" marginTop="lg">
        {priceFormatter(price)}
      </Text>
    </ItemWrapper>
  );
};

export default ItemDetail;
