import { cartsVars, menuItemVars } from '../localStore';
import createAddCart from './CreateCart';
import createMenuItem from './CreateMenuItem';
import deleteCart from './DeleteCart';
import deleteMenuItem from './DeleteMenuItem';
import editCart from './EditCart';
import editCartCosts from './EditCartCosts';
import editCartMenuItem from './EditCartMenuItem';
import editRecalculateCart from './EditRecalculateCart';
import removeCartMenuItem from './RemoveCartMenuItem';
import removeUnavailableItems from './RemoveUnavailableItems';
import editMenuItem from './editMenuItem';

export const cartMutations = {
  createCart: createAddCart(cartsVars),
  editCart: editCart(cartsVars),
  deleteCart: deleteCart(cartsVars),
  createMenuItem: createMenuItem(menuItemVars),
  editMenuItem: editMenuItem(menuItemVars),
  deleteMenuItem: deleteMenuItem(menuItemVars),
  editCartMenuItem: editCartMenuItem(cartsVars),
  removeCartMenuItem: removeCartMenuItem(cartsVars),
  editCartCosts: editCartCosts(cartsVars),
  editRecalculateCart: editRecalculateCart(cartsVars),
  removeUnavailableItems: removeUnavailableItems(cartsVars),
};
