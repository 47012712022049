import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { captureMessage } from '@sentry/browser';
import { Terminal } from '@stripe/terminal-js';
import { message } from 'antd';
import Filter from 'bad-words';
import { array } from 'badwords-list';

import { AuthContext } from 'authContext';
import {
  OrderInfoFragment,
  OrderValidationFragment,
  useConfirmOrderMutation,
  usePromoValidationMutation,
  OrderMethod,
} from 'codegen/generated/graphql';
import Button from 'components/Button';
import InfoModal from 'components/InfoModal';
import { Input, Overlay, CounterWrapper } from 'components/Input';
import { ItemWrapper, Row } from 'components/Layout';
import { Spin } from 'components/Spin';
import Text from 'components/Text';
import ConfirmModal from 'containers/ConfirmModal';
import { usePaymentCtx } from 'paymentContext';
import Routes from 'router/routes';
import { getTipTotal } from 'utils/apollo/helpers';
import { Cart } from 'utils/apollo/models';
import { cartMutations } from 'utils/apollo/mutations';
import { getOrderValidationErrorMessage } from 'utils/confirmOrder';
import {
  TipAmounts,
  MAX_TIP_AMOUNT,
  ACCEPTABLE_NAMES,
  TOUCH_KEYBOARD_INPUT_LIMITS,
} from 'utils/constants';
import { AppContext } from 'utils/context';
import { reportValidationErrors } from 'utils/errors';
import { priceFormatter } from 'utils/formatHelpers';

import { stripeCardNumbers } from './stripeConstants';
import {
  createTerminal,
  readerConnect,
  initPayment,
  getPaymentIntent,
  capturePayment,
  cancelPaymentIntent,
  PaymentStatus,
} from './stripeUtils';
import { reduceCartItems } from './utils';

import {
  CheckoutBarContainerWrapper,
  PlaceOrderBtnWrapper,
  Wrapper,
} from './styles';

interface Props {
  cart: Cart;
  promoCodes: string[];
}

const paymentStatusDisplay = [
  { title: 'Declined', src: '/images/card-declined.gif' },
  {
    title: 'Card error.\nTry again or use a\ndifferent card.',
    src: '/images/card-error.gif',
  },
  { title: 'Complete', src: '/images/payment-complete.gif' },
  { title: 'Processing...', src: '/images/processing-card.gif' },
  { title: 'Insert card\n or tap to pay', src: '/images/insert-card.gif' },
];

const filterOrderNames = new Filter({ list: array });
filterOrderNames.removeWords(...ACCEPTABLE_NAMES);

const CheckoutPriceBar = ({ cart, promoCodes }: Props) => {
  const [showCustomModal, setShowCustomModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showUnavailableModal, setShowUnavailableModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [nameOrderModal, setNameOrderModal] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(PaymentStatus.READY);
  const [paymentIntendId, setPaymentIntentId] = useState('');
  const [stripeTerminal, setStripeTerminal] = useState<Terminal | null>(null);
  const [unavailableIds, setUnavailableIds] = useState<string[]>();
  const [selectedAmount, setSelectedAmount] = useState('');
  const [profanityError, setProfanityError] = useState('');
  const [orderName, setOrderName] = useState('');
  const [orderConfirmation, setOrderConfirmation] =
    useState<{
      order?: OrderInfoFragment | null;
      validation: OrderValidationFragment;
    } | null>();
  const [isLoading, setIsLoading] = useState(false);
  const [tip, setTip] = useState('');

  const { isPaymentMocked } = usePaymentCtx();

  const { user, isDelivery } = useContext(AuthContext);

  const { setIsPaymentProcessing, setStartNewOrder } = useContext(AppContext);

  const { replace, push } = useHistory();

  const orderMethod = isDelivery ? OrderMethod.Delivery : OrderMethod.PickUp;

  const [confirmOrder] = useConfirmOrderMutation({
    variables: {
      input: {
        order: {
          orderMethod,
          orderName,
          costs: {
            tip: cart.tip,
            total: cart.total,
            subtotal: cart.subTotal,
            tax: cart.tax,
            promoDiscount: cart.promoDiscount,
            giftCardsDiscount: cart.giftCardsDiscount,
            fee: cart.fee,
            delivery: cart.delivery,
          },
        },
        cart: {
          promoCodes,
          cartItems: reduceCartItems(cart.items).map((item) => ({
            id: item.kitchenMenuItem?.id ?? '',
            quantity: item.quantity,
            extraItems: item.selectedExtras.map((extra) => ({
              extraItemId: extra.item.id,
              quantity: extra.quantity,
            })),
          })),
        },
      },
    },
  });

  const [validatePromo] = usePromoValidationMutation({
    variables: {
      input: {
        order: {
          orderName: cart.orderName,
          costs: {
            tip: cart.tip,
            total: cart.total,
            subtotal: cart.subTotal,
            tax: cart.tax,
            fee: 10,
          },
        },
        cart: {
          promoCodes: cart.promoCodes,
          cartItems: reduceCartItems(cart.items).map((item) => ({
            id: item.kitchenMenuItem?.id ?? '',
            quantity: item.quantity,
            extraItems: item.selectedExtras.map((extra) => ({
              extraItemId: extra.item.id,
              quantity: extra.quantity,
            })),
          })),
        },
      },
    },
  });

  const handleTipAmount = (tip: number) => {
    cartMutations.editCartCosts(
      1,
      cart.subTotal,
      cart.tax,
      cart.fee,
      cart?.promoDiscount ?? 0,
      cart?.giftCardsDiscount ?? 0,
      null,
      cart.delivery,
      true,
      getTipTotal(cart.subTotal, cart.tax, tip),
    );
  };

  let unavailableItems = [] as UnavailableItemNames[];
  //TODO: Add Error handling like in PromoValidation

  const onCompleteOrderHandler = () => {
    setShowConfirmationModal(false);
    setOrderConfirmation(null);
    // @TODO this side effect (redirection) should be rather extracted into the context where it belongs with useEffect
    replace(Routes.MENU.path);
  };

  const itemNames = cart.items.map((item) => ({
    id: item.kitchenMenuItem?.id || '',
    name: item.kitchenMenuItem?.name || '',
  }));

  if (unavailableIds && unavailableIds.length > 1) {
    unavailableItems = itemNames.filter((i) => unavailableIds.includes(i?.id));
  }

  const placeOrder = async () => {
    const orderData = await confirmOrder();

    if (!orderData.data) {
      return message.error(
        'There was an issue processing your order. Please try again.',
      );
    }
    const error = orderData.data.customerConfirmKioskOrder.validation.errors[0];
    if (error) {
      return message.error(getOrderValidationErrorMessage(error));
    }

    setOrderConfirmation(orderData.data.customerConfirmKioskOrder);
    setIsPaymentProcessing(false);
    setTimeout(() => {
      setShowConfirmationModal(true);
    }, 500);

    setStartNewOrder(true);

    return;
  };

  const getOrderName = () => {
    setNameOrderModal(false);
    cartMutations.editCart(1, undefined, cart.tip / cart.subTotal);
  };

  const onPlaceOrderHandlerPayments = async () => {
    if (filterOrderNames.isProfane(orderName)) {
      setProfanityError(
        'This order name is not allowed. Please create a new order name.',
      );
      setOrderName('');

      return;
    }
    setIsLoading(true);
    getOrderName();

    try {
      const { data, errors: queryErrors } = await validatePromo({});

      const { errors, promoErrors, unavailableItemIds } =
        data?.customerValidateKioskPromo ?? {};

      if (unavailableItemIds?.length) {
        setShowUnavailableModal(true);
        setUnavailableIds(unavailableItemIds);
      }

      if (reportValidationErrors(promoErrors, errors, queryErrors)) return;

      if (cart.total <= 0 && cart.promoCodes !== []) {
        const orderData = await confirmOrder();

        setOrderConfirmation(orderData.data?.customerConfirmKioskOrder);
        setIsPaymentProcessing(false);
        setShowConfirmationModal(true);

        setStartNewOrder(true);

        return;
      }

      const readerLabel = isPaymentMocked ? 'Reader Simulator' : user?.email;

      if (isPaymentMocked) {
        placeOrder();

        return;
      }

      const getCreateTerminal = await createTerminal();

      const terminal =
        getCreateTerminal !== undefined
          ? await readerConnect(
              getCreateTerminal,
              readerLabel ?? '',
              isPaymentMocked,
            )
          : null;

      if (!terminal) {
        captureMessage('No terminal initialization present');

        return;
      }

      setStripeTerminal(terminal);
      setShowPaymentModal(true);
      setIsPaymentProcessing(true);

      const secretKey = await initPayment(cart.total);
      const processedIntentId = secretKey.substring(
        0,
        secretKey.indexOf('_secret'),
      );
      setPaymentIntentId(processedIntentId);

      if (isPaymentMocked) {
        await terminal?.setSimulatorConfiguration({
          testCardNumber: stripeCardNumbers.CARD_APPROVED,
        });
      }

      const processPaymentIntent = await getPaymentIntent(
        terminal,
        setPaymentStatus,
      );

      if (!processPaymentIntent) {
        captureMessage('Failed to process payment intent.');
        console.error('ERROR: Failed to process payment intent.');
        setIsPaymentProcessing(false);

        return;
      }

      await capturePayment(processedIntentId, cart.total);

      placeOrder();
    } catch (e) {
      return reportValidationErrors([], [], [e]);
    } finally {
      setIsLoading(false);
    }
  };

  const cancelOrder = async () => {
    await cancelPaymentIntent(paymentIntendId);
    setPaymentStatus(PaymentStatus.READY);
    setPaymentIntentId('');
    setShowPaymentModal(false);
    stripeTerminal?.cancelCollectPaymentMethod();
  };

  interface UnavailableItemNames {
    id: string;
    name: string;
  }

  if (unavailableIds) {
    unavailableItems = itemNames.filter((i) => unavailableIds.includes(i?.id));
  }

  return (
    <CheckoutBarContainerWrapper>
      <ItemWrapper
        $display="flex"
        $flex={1}
        $flexDirection="column"
        $height="535px"
        $align="none"
        $justify="none"
      >
        <Row $justifyContent="space-between">
          <Text color="white" fontSize="xs">
            Subtotal
          </Text>
          <Text color="white" fontSize="xs">
            {priceFormatter(cart.subTotal)}
          </Text>
        </Row>
        <Row $justifyContent="space-between">
          <Text color="white" fontSize="xs">
            Tax and Fees
          </Text>
          <Text color="white" fontSize="xs">
            {priceFormatter(cart.tax + cart.fee)}
          </Text>
        </Row>
        {isDelivery && (
          <Row $justifyContent="space-between">
            <Text color="white" fontSize="xs">
              Delivery Fee
            </Text>
            <Text color="white" fontSize="xs">
              {`$${cart?.delivery}`}
            </Text>
          </Row>
        )}
        {!!cart.promoDiscount && cart.promoDiscount > 0 && (
          <Row $justifyContent="space-between">
            <Text color="white" fontSize="xs">
              Promo
            </Text>
            <Text color="white" fontSize="xs">
              - {priceFormatter(cart.promoDiscount)}
            </Text>
          </Row>
        )}
        {!!cart.giftCardsDiscount && cart.giftCardsDiscount > 0 && (
          <Row $justifyContent="space-between">
            <Text color="white" fontSize="xs">
              Gift Cards
            </Text>
            <Text color="white" fontSize="xs">
              - {priceFormatter(cart.giftCardsDiscount)}
            </Text>
          </Row>
        )}
        <Row $justifyContent="space-between">
          <Text color="white" fontSize="xs">
            Gratuity
          </Text>
          <Text color="white" fontSize="xs">
            {priceFormatter(cart.tip)}
          </Text>
        </Row>
        <Row $flex={2} $justifyContent="flex-end">
          {TipAmounts.map((tipAmount) => {
            const isSelected = tipAmount.label === selectedAmount;

            return (
              <Button
                key={tipAmount.label}
                onClick={() => {
                  setSelectedAmount(tipAmount.label);
                  tipAmount.label !== 'Custom'
                    ? handleTipAmount(tipAmount.amount)
                    : setShowCustomModal(true);
                }}
                size="small"
                secondary
                text={tipAmount.label}
                padding="0px 55px"
                marginLeft="xl"
                selected={isSelected}
              />
            );
          })}
          <InfoModal
            title="Enter custom gratuity amount"
            onClickBack={() => setShowCustomModal(false)}
            visible={showCustomModal}
            message="Add a dollar amount to add to your order."
          >
            <Input
              placeholder={tip || '0.00'}
              type="number"
              onChange={(e) => {
                setTip(e.toString());
              }}
              placeholderColor="gray"
              maxLength={4}
              value={tip}
              width="200px"
              height="49px"
              align="center"
            />
            {Number(tip) > MAX_TIP_AMOUNT && (
              <Text
                color="warning"
                alignSelf="center"
                textAlign="center"
                marginTop="sm"
              >
                Tip amount must be under $100
              </Text>
            )}
            <ItemWrapper
              $display="flex"
              $flexDirection="column"
              $padding="20px 0px 0px"
            >
              <Button
                text="Confirm"
                primary
                disabled={Number(tip) > MAX_TIP_AMOUNT}
                onClick={() => {
                  if (cart.total > 0) {
                    const tipPercentage =
                      parseFloat(tip) / (cart.subTotal + cart.tax);
                    handleTipAmount(tipPercentage);
                    setShowCustomModal(false);
                  }
                }}
              />
              <Button
                text="Go Back"
                skeleton
                onClick={() => setShowCustomModal(false)}
              />
            </ItemWrapper>
          </InfoModal>
        </Row>
        <Row $justifyContent="space-between" $padding="0px 0px 50px">
          <Text color="white" fontSize="xs">
            Total
          </Text>
          <Text color="white" fontSize="h2" fontWeight="semiBold">
            {priceFormatter(cart.total)}
          </Text>
        </Row>
        <PlaceOrderBtnWrapper>
          <Button
            text="Name & Place Order"
            fontSize="h2"
            padding="0px 70px"
            size="large"
            secondary
            onClick={() => setNameOrderModal(true)}
          />
        </PlaceOrderBtnWrapper>

        <ConfirmModal
          visible={showConfirmationModal}
          closeFn={onCompleteOrderHandler}
          orderInfo={orderConfirmation?.order}
        />

        <InfoModal
          visible={showPaymentModal}
          title={paymentStatusDisplay[paymentStatus.valueOf()].title}
          imageSrc={paymentStatusDisplay[paymentStatus.valueOf()].src}
          width={'535px'}
          cancelText={'Cancel'}
          isIdleTimeOut={
            paymentStatus === PaymentStatus.PROCESSING ||
            paymentStatus === PaymentStatus.COMPLETE
          }
          onClickBack={cancelOrder}
          basic
        />
        <InfoModal
          visible={nameOrderModal}
          title="Name your order to finish."
          width={'719px'}
          cancelText={'Cancel'}
        >
          <Wrapper>
            <Text alignSelf="center" textAlign="center">
              Once your order is submitted, we’ll use this information to let
              you know when it’s ready.
            </Text>
            <CounterWrapper marginTop={50}>
              <Input
                bordered={false}
                maxLength={TOUCH_KEYBOARD_INPUT_LIMITS.orderNameInput}
                onChange={(e) => {
                  setOrderName(e);
                  profanityError && setProfanityError('');
                }}
                placeholder="Your Order Name"
                value={orderName}
                align="center"
                id="orderNameInput"
              />
              <Overlay>
                {TOUCH_KEYBOARD_INPUT_LIMITS.orderNameInput - orderName.length}
              </Overlay>
            </CounterWrapper>
            {profanityError && (
              <ItemWrapper $flex={0.25}>
                <Text
                  color="warning"
                  textAlign="center"
                  fontSize="lg"
                  spaceAfter="lg"
                >
                  {profanityError}
                </Text>
              </ItemWrapper>
            )}
            <div style={{ width: '200px', alignSelf: 'center' }}>
              <Button
                disabled={orderName.length < 1}
                marginT="xl"
                size="large"
                primary
                text="Place Order"
                onClick={onPlaceOrderHandlerPayments}
              />
            </div>

            <Button
              marginT="lg"
              skeleton
              text="Go Back"
              onClick={() => setNameOrderModal(false)}
            />
          </Wrapper>
        </InfoModal>
        <InfoModal
          visible={showUnavailableModal}
          title="Oops!"
          buttonText="Remove Items from My Cart"
          onClickAction={() => setShowUnavailableModal(false)}
          basic={false}
          message={
            'The following items in your cart are no longer available to be ordered.'
          }
        >
          {unavailableItems.length > 0 &&
            //TODO: remove index as key
            unavailableItems.map((item, index) => (
              <Text
                key={index}
                textAlign="center"
                fontWeight="bold"
                spaceAfter="xl"
              >
                {item.name}
              </Text>
            ))}
          <Button
            text="Remove Items From My Cart"
            primary
            onClick={() => {
              unavailableItems.map((item) => {
                cartMutations.removeUnavailableItems(1, item.id);
              });
              setShowUnavailableModal(false);
              if (cart.items.length === unavailableItems.length)
                push(Routes.MENU.path);
            }}
          />
        </InfoModal>
        <InfoModal visible={isLoading} title="Verifying the order...">
          <Spin tip="Loading..." size="large" />
        </InfoModal>
      </ItemWrapper>
    </CheckoutBarContainerWrapper>
  );
};

export default CheckoutPriceBar;
