import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Routes from 'router/routes';

const PAYMENTS_FLAG = 'payments_mocked';

export const usePayment = () => {
  const { replace } = useHistory();

  const [isPaymentMocked, setIsPaymentMocked] = useState(
    localStorage.getItem(PAYMENTS_FLAG)
      ? JSON.parse(localStorage.getItem(PAYMENTS_FLAG) ?? 'true')
      : false,
  );

  useEffect(() => {
    localStorage.setItem(PAYMENTS_FLAG, JSON.stringify(isPaymentMocked));
  }, [isPaymentMocked]);

  useEffect(() => {
    replace(Routes.MENU.path);
  }, [replace]);

  return [isPaymentMocked, setIsPaymentMocked];
};
