import React from 'react';

import { Card } from 'antd';

import StripeCardReaders from 'components/StripeCardReaders';
import Text from 'components/Text';
import SettingsLayout from 'containers/SettingsLayout';

const Settings = () => {
  return (
    <SettingsLayout>
      <Text
        marginTop={86}
        spaceAfter={26}
        marginLeft={16}
        fontSize={'h2'}
        color={'black'}
        fontWeight={'bold'}
      >
        Kiosk Settings
      </Text>
      <Card>
        <StripeCardReaders />
      </Card>
    </SettingsLayout>
  );
};

export default Settings;
