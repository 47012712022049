import { createGlobalStyle } from 'styled-components';

import { theme } from './index';

// TODO: Add default fonts
export const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'benton-sans';
  }

  body,
  html {
    padding: 0;
    margin: 0;
    font-size: 22px;
    overflow-y: hidden;
  }

  a {
    color: ${theme.color.black} !important;
  }

  .ant-switch-checked {
    background-color: #00BBDD;
  }
  
  .ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.5) !important;
  }

  /* KEYBOARD Styles */

  .crave-keyboard {
    height: 381px;
    background-color: black;
    font-size: 30px;
    font-family: 'benton-sans';
    display: flex;
    align-items: center;

    .hg-rows {
      width: 100%;
    }

    .hg-button {
      width: 82px;
      height: 72px !important;
      background-color: black;
      color: white;
      border-bottom: 1px solid #757272;
      border-radius: 6px;

      &.hg-activeButton {
        background-color: #333333;
      }

      &.hg-button-space {
        background-color: #222222;
        min-width: 45% !important;
      }

      &.hg-button-bksp {
        width: 20px;
      }

      &.hg-button-123, &.hg-button-abc, &.hg-button-ABC {
        max-width: 150px;
        min-width: 60px;
      }

      &.hg-standardBtn[data-skbtn="@"] {
        max-width: 65px;
      }

    .hg-button-enter {
      width: 143px;
    }
    
    .hg-button-space {
      width: 525px;
    }
  }
`;
