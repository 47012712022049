import { ReactiveVar } from '@apollo/client';

import { KitchenMenuItemQuery } from 'codegen/generated/graphql';

import { merge, getMenuItemSubTotal } from '../helpers';
import { MenuItem, ExtraItem } from '../models';

interface Props {
  id: string;
  extras?: ExtraItem[] | [];
  price?: number;
  quantity?: number;
  menuItem?: KitchenMenuItemQuery['customerKitchenMenuItem'];
}

export default (menuItemVar: ReactiveVar<MenuItem>) => {
  return ({ id, extras, price, quantity, menuItem }: Props) => {
    const data = menuItemVar();
    const values = {
      selectedExtras: extras,
      totalPrice: price,
      quantity,
      kitchenMenuItem: menuItem,
    };

    if (id) {
      const merged = merge({}, data, values);

      if (merged.kitchenMenuItem && merged.selectedExtras) {
        const subTotal = getMenuItemSubTotal(
          merged.kitchenMenuItem,
          merged.quantity,
          merged.selectedExtras,
        );

        menuItemVar({
          ...merged,
          totalPrice: subTotal,
        });
      }
    }
  };
};
