import React from 'react';

import { ApolloProvider } from '@apollo/client';
import { Router } from 'router';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle } from 'theme/GlobalStyle';

import AuthProvider from 'authContext';
import { withErrorBoundary } from 'components/ErrorBoundary';
import { theme } from 'theme';
import { initApollo } from 'utils/apollo';
import AppContext from 'utils/context';
import CartContextProvider from 'utils/context/cartContext';
import FacilityContextProvider from 'utils/context/facilityContext';
import HoursContextProvider from 'utils/context/operationHours';
import { initSentry } from 'utils/sentry';

initSentry();

export const client = initApollo();

function App() {
  return (
    <ApolloProvider client={client}>
      <GlobalStyle />
      <AppContext>
        <ThemeProvider theme={theme}>
          <AuthProvider>
            <FacilityContextProvider>
              <CartContextProvider>
                <HoursContextProvider>
                  <Router />
                </HoursContextProvider>
              </CartContextProvider>
            </FacilityContextProvider>
          </AuthProvider>
        </ThemeProvider>
      </AppContext>
    </ApolloProvider>
  );
}

export default withErrorBoundary(App);
