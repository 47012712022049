import { Spin as ASpin } from 'antd';
import styled from 'styled-components';

import { theme } from 'theme';

export const Spin = styled(ASpin)`
  .ant-spin-dot-item {
    background-color: ${theme.color.black};
  }
`;
