import React, { useContext } from 'react';
import { HashRouter, Switch } from 'react-router-dom';

import { Spin } from 'antd';

import { AuthContext } from 'authContext';
import DevToolsWidget from 'containers/DevToolsWidget';
import LandingLayout from 'containers/LandingLayout';
import CheckoutPage from 'pages/checkout';
import ClosedModal from 'pages/closed';
import DeveloperToolsComponent from 'pages/developerTools';
import ForgotPassword from 'pages/forgotPassword';
import LoginComponent from 'pages/login';
import MenuComponent from 'pages/menu';
import SettingsComponent from 'pages/settings';
import { PaymentProvider } from 'paymentContext';
import IdleTimeoutHOC from 'utils/context/idleTimeout';

import PrivateRoute from './PrivateRoute/index';
import PublicRoute from './PublicRoute/index';
import Routes from './routes';

export const Router = () => {
  const { loading } = useContext(AuthContext);

  if (loading) {
    return (
      <LandingLayout>
        <Spin tip="Loading..." spinning={loading} />
      </LandingLayout>
    );
  }

  return (
    <HashRouter>
      <PaymentProvider>
        <Switch>
          <PublicRoute
            exact
            path={Routes.LOGIN.path}
            component={LoginComponent}
          />
          <PrivateRoute
            exact
            path={Routes.MENU.path}
            component={MenuComponent}
          />
          <PrivateRoute
            exact
            path={Routes.CHECKOUT.path}
            component={CheckoutPage}
          />
          <PublicRoute
            exact
            path={Routes.RESET_PASSWORD.path}
            component={ForgotPassword}
          />
          <PrivateRoute
            exact
            path={Routes.SETTINGS.path}
            component={SettingsComponent}
          />
          <PrivateRoute
            exact
            path={Routes.DEVELOPER_TOOLS.path}
            component={DeveloperToolsComponent}
          />
        </Switch>
        <ClosedModal />
        <IdleTimeoutHOC />
        <DevToolsWidget />
      </PaymentProvider>
    </HashRouter>
  );
};
