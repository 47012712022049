import React from 'react';

import Checkout from 'containers/Checkout';
import KeyboardHOC from 'utils/context/keyboard';

const CheckoutPage = () => (
  <KeyboardHOC>
    <Checkout />
  </KeyboardHOC>
);

export default CheckoutPage;
