import { ApolloClient, from, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

import { Facility } from 'codegen/generated/graphql';

import { auth } from '../utils/firebase';
import { cache } from './apollo/cache';

interface KioskHeaders {
  headers: {
    authorization: string;
    platform: string;
    timezone: string;
    facility_id: Facility['id'];
  };
}

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );
  if (networkError) console.error(`[Network error]: ${networkError}`);
});

const getHeaders = async (): Promise<KioskHeaders> => {
  const token = await auth.currentUser?.getIdToken();
  const facilityId = localStorage.getItem('facilityId');
  const facilityTz = localStorage.getItem('facilityTz');

  return {
    headers: {
      authorization: token ? `Bearer ${token}` : '',
      platform: 'kiosk',
      timezone: facilityTz || '',
      facility_id: facilityId || '',
    },
  };
};

const authLink = setContext(getHeaders);

const httpLink = createHttpLink({
  uri:
    process.env.REACT_APP_KIOSK_GRAPHQL_API_URL ||
    'http://localhost:4000/graphql',
});

export const initApollo = () => {
  const client = new ApolloClient({
    link: from([authLink as any, errorLink, httpLink]),
    cache,
  });

  return client;
};
