import { makeVar, ReactiveVar } from '@apollo/client';

import { Cart, MenuItem } from './models';

export const cartsInitialValue: Cart = {
  id: 0,
  orderName: '',
  items: [],
  subTotal: 0.0,
  tax: 0.0,
  total: 0.0,
  tip: 0.0,
  delivery: 0.0,
  promoDiscount: 0.0,
  giftCardsDiscount: 0.0,
  promoCodes: [],
  recalculateDiscount: false,
  fee: 0.0,
};

export const menuItemInitialValue: MenuItem = {
  id: '',
  kitchenMenuItem: null,
  selectedExtras: [],
  price: 0.0,
  totalPrice: 0.0,
  quantity: 0,
  sortNumber: 1,
};

export const cartsVars: ReactiveVar<Cart> = makeVar<Cart>(cartsInitialValue);
export const menuItemVars: ReactiveVar<MenuItem> =
  makeVar<MenuItem>(menuItemInitialValue);
