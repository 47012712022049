import React, { FC } from 'react';

import { Wrapper, Scrollable } from './styles';

const Scroll: FC = ({ children }) => {
  return (
    <Wrapper>
      <Scrollable>{children}</Scrollable>
    </Wrapper>
  );
};

export default Scroll;
