import styled from 'styled-components';

import { background } from 'utils/styleHelpers';

export const Container = styled.div`
  position: absolute;
  display: flex;
  height: 100%;
  width: 100%;
  ${background('black')};
  justify-content: center;
  overflow: hidden;
  margin: 0 auto;
  z-index: 1;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 200px;
`;
