import React, { useState } from 'react';

import { Button } from 'antd';

import { MultiSelectQuantityCounterWrapper } from 'components/QuantitySelector/styles';
import Text from 'components/Text';

interface Props {
  onChange: (quantity: number) => void;
  quantity: number;
  disableAdd: boolean;
}

const MultiSelectQuantityCounter = ({
  onChange,
  quantity,
  disableAdd,
}: Props) => {
  const [count, setCount] = useState<number>(quantity);

  return (
    <MultiSelectQuantityCounterWrapper>
      <Button
        shape="circle"
        disabled={count === 0}
        onClick={() => {
          onChange(count - 1);
          setCount(count - 1);
        }}
      >
        -
      </Button>
      <Text fontSize="sm" fontWeight="bold" marginLeft="xs" marginRight="xs">
        {count}
      </Text>
      <Button
        shape="circle"
        disabled={disableAdd}
        onClick={() => {
          onChange(count + 1);
          setCount(count + 1);
        }}
      >
        +
      </Button>
    </MultiSelectQuantityCounterWrapper>
  );
};

export default MultiSelectQuantityCounter;
