import React, { useState } from 'react';
import Keyboard from 'react-simple-keyboard';

import { TOUCH_KEYBOARD_INPUT_LIMITS } from 'utils/constants';

import 'react-simple-keyboard/build/css/index.css';

import { KeyboardWrapper } from './styles';

interface Props {
  onChange: (e: any) => void;
  inputName: string;
  isVisible: boolean;
  isNumLock: boolean;
}

const OnScreenKeyboard = React.forwardRef(
  (
    { onChange, inputName, isVisible, isNumLock, ...props }: Props,
    ref: any,
  ) => {
    const [isShift, setIsShift] = useState(false);
    const [isNumeric, setIsNumeric] = useState(false);

    return (
      <KeyboardWrapper {...props} className={isVisible ? 'show' : ''}>
        <Keyboard
          inputName={inputName}
          keyboardRef={(keyboardRef) => (ref.current = keyboardRef)}
          preventMouseDownDefault={true}
          theme="hg-theme-default hg-layout-default crave-keyboard"
          layoutName={
            (isNumeric && 'numeric') ||
            (isShift && 'shift') ||
            (isNumLock && 'numLock') ||
            'default'
          }
          layout={{
            default: [
              'q w e r t y u i o p',
              'a s d f g h j k l ` _ -',
              '{shift} z x c v b n m , .',
              '{123} .com @ {space} {bksp}',
            ],
            shift: [
              'Q W E R T Y U I O P',
              'A S D F G H J K L ` _ -',
              '{shift} Z X C V B N M , .',
              '{123} .com @ {space} {bksp}',
            ],
            numeric: [
              '+ 1 2 3 ,',
              '- 4 5 6 .',
              '= 7 8 9 0',
              `${isShift ? '{ABC}' : '{abc}'} {space} {bksp}`,
            ],
            numLock: ['1 2 3', '4 5 6', '7 8 9', '. 0 {bksp}'],
          }}
          mergeDisplay
          display={{
            '{123}': '123',
            '{ABC}': 'ABC',
            '{abc}': 'abc',
          }}
          onChangeAll={onChange}
          onKeyPress={(e: string) => {
            if (e === '{shift}') setIsShift(!isShift);
            if (['{123}', '{abc}', '{ABC}'].includes(e))
              setIsNumeric(!isNumeric);
          }}
          maxLength={TOUCH_KEYBOARD_INPUT_LIMITS}
        />
      </KeyboardWrapper>
    );
  },
);
export default OnScreenKeyboard;
