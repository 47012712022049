import React, { useState, useEffect, useMemo } from 'react';

import { Image, message } from 'antd';
import { chunk, uniqBy } from 'lodash';

import {
  useRestaurantDetailLazyQuery,
  DashboardQuery,
  useKitchenMenuItemLazyQuery,
  KitchenMenuItem,
} from 'codegen/generated/graphql';
import Carousel from 'components/Carousel';
import { Column, Row } from 'components/Layout';
import Text from 'components/Text';
import MenuItemModal from 'containers/MenuItemModal';
import { cartMutations } from 'utils/apollo/mutations';
import { ITEMS_MAX, SPECIAL_MENU } from 'utils/constants';
import { useAppContext } from 'utils/context';

import MenuItems from './components';
import { RegularMenuItems } from './types';

import { ImageWrapper } from './styles';

interface Props {
  data: DashboardQuery['customerSpecialMenu'];
  selectedKitchen: string;
}

const MenuView: React.FC<Props> = ({ data, selectedKitchen }) => {
  const [category, setCategory] = useState('');
  const [menuItemId, setMenuItemId] = useState('');
  const [showMenuItemModal, setShowMenuItemModal] = useState(false);

  const { menuItems: menuItem } = useAppContext();

  const [getRestaurantDetails, { data: kitchenMenu, error, loading }] =
    useRestaurantDetailLazyQuery();

  const [
    getMenuItemQuery,
    { data: menuData, error: menuItemError, loading: menuItemLoading },
  ] = useKitchenMenuItemLazyQuery({
    onCompleted: (data) => {
      cartMutations.createMenuItem(data.customerKitchenMenuItem);
      setShowMenuItemModal(true);
    },
  });

  const kitchenName = kitchenMenu?.customerKitchen.name;
  const kitchenLocation = kitchenMenu?.customerKitchen.originalLocation;

  useEffect(() => {
    switch (selectedKitchen) {
      case SPECIAL_MENU:
        const intialCategory = data?.find((i) => i.menuItems.length > 0);
        setCategory(intialCategory?.id ?? '');
        break;
      case selectedKitchen:
        getRestaurantDetails({ variables: { id: selectedKitchen } });
        break;
      default:
        break;
    }
  }, [getRestaurantDetails, selectedKitchen, data, kitchenName]);

  if (!loading && error) {
    message.error(
      `Failed to load ${kitchenName}'s menu please try reloading the page`,
    );
  }

  if (!menuItemLoading && menuItemError) {
    message.error(`Failed to load menu item please try reloading the page`);
  }

  const categoryWithItems = useMemo(() => {
    return data.filter((i) => i.menuItems.length > 0) ?? [];
  }, [data]);

  const specialMenu = useMemo(() => {
    return (
      data
        ?.find((cat) => cat.id === category)
        ?.menuItems.slice()
        .sort((a, b) => a.globalSortId - b.globalSortId) ?? []
    );
  }, [category, data]);

  const regularMenu = useMemo(() => {
    return uniqBy(
      kitchenMenu?.customerKitchen?.menuCategories.flatMap(
        ({ menuItems }) => menuItems,
      ),
      'id',
    ) as RegularMenuItems[];
  }, [kitchenMenu?.customerKitchen?.menuCategories]);

  // Ensure only 12 menu items show on each carousel slide
  const specialMenuSlides = chunk(specialMenu, ITEMS_MAX);
  const regularMenuSlides = chunk(regularMenu, ITEMS_MAX);

  const handleModalOpen = (id: string) => {
    setMenuItemId(id);
    if (id !== '') {
      getMenuItemQuery({
        variables: {
          id,
        },
      });
      if (menuData) {
        cartMutations.createMenuItem(menuData.customerKitchenMenuItem);
        setShowMenuItemModal(true);
      }
    }
    cartMutations.deleteMenuItem(menuItem.id ?? '');
  };

  const handleModalClose = () => {
    setMenuItemId('');
    setShowMenuItemModal(false);
  };

  useEffect(() => {
    if (menuData) {
      cartMutations.createMenuItem(menuData.customerKitchenMenuItem);
      setShowMenuItemModal(true);
    }
  }, [menuData]);

  const fontDecrease = kitchenName && kitchenName?.length >= 27;

  return (
    <>
      {selectedKitchen === SPECIAL_MENU ? (
        <>
          <Row align="top">
            <Column $paddingBottom={25} $width="100%">
              <Text fontSize="h1" textAlign="center" fontWeight="semiBold">
                Top Picks
              </Text>
            </Column>
            <Row gutter={[30, 30]} $padding="30px 0 50px 0">
              {selectedKitchen === SPECIAL_MENU &&
                categoryWithItems.map((i, index) => {
                  const active = category ? category === i.id : index === 0;

                  return (
                    <Column key={i.id} onClick={() => setCategory(i.id)}>
                      <Text
                        color={active ? 'black' : 'gray'}
                        fontSize="h3"
                        fontWeight="semiBold"
                      >
                        {i.name}
                      </Text>
                    </Column>
                  );
                })}
            </Row>
          </Row>
          <Carousel>
            {specialMenuSlides.map((i, index) => {
              return (
                <div key={index}>
                  <MenuItems items={i} handleModalOpen={handleModalOpen} />
                </div>
              );
            })}
          </Carousel>
        </>
      ) : (
        <>
          <Row align="top">
            <Column $padding="25px 10px 75px">
              <Text
                fontSize={fontDecrease ? 'xxl' : 'h1'}
                fontWeight="semiBold"
                textAlign="center"
                spaceAfter="lg"
              >
                {kitchenName}
              </Text>
              <ImageWrapper>
                <Image preview={false} src="/svg/location.svg" />
                <Text textAlign="center" fontSize="h3">
                  {kitchenLocation}
                </Text>
              </ImageWrapper>
            </Column>
          </Row>
          <Carousel>
            {regularMenuSlides.map((items, i) => {
              return (
                <MenuItems
                  key={i}
                  items={items}
                  handleModalOpen={handleModalOpen}
                />
              );
            })}
          </Carousel>
        </>
      )}
      <MenuItemModal
        handleModalClose={handleModalClose}
        kitchenMenuItemId={menuItemId}
        visible={showMenuItemModal}
        kitchenMenuItem={menuData?.customerKitchenMenuItem as KitchenMenuItem}
      />
    </>
  );
};

export default MenuView;
