import React from 'react';

import { Button as AntdButton, ButtonProps } from 'antd';
import { Property as CsstypeProperty } from 'csstype';
import { Color, LineHeight, FontSizes } from 'styled-components';

import Text from 'components/Text';
import {
  TextAlignProps,
  JustifyContentProps,
  PositonType,
  FlexDirectionProps,
  AlignSelfProps,
  SpacingType,
} from 'utils/styleHelpers';

import { StyledWrapper } from './styles';

interface Props extends ButtonProps {
  text?: string;
  color?: keyof Color;
  lineHeight?: keyof LineHeight;
  fontSize?: keyof FontSizes;
  textAlign?: TextAlignProps;
  padding?: string;
  position?: PositonType;
  left?: string;
  border?: number;
  borderColor?: keyof Color;
  flexDirection?: FlexDirectionProps;
  display?: CsstypeProperty.Display;
  justifyContent?: JustifyContentProps;
  alignSelf?: AlignSelfProps;
  flex?: number;
  marginT?: SpacingType;
  marginLeft?: SpacingType;
  primary?: boolean;
  secondary?: boolean;
  skeleton?: boolean;
  warning?: boolean;
  selected?: boolean;
}

const Button = ({
  text,
  color,
  lineHeight = 'normal',
  fontSize = 'sm',
  textAlign = 'center',
  padding = '0 40px',
  position = 'relative',
  left = '',
  border = 0,
  borderColor = 'transparent',
  flexDirection = 'row',
  display = 'flex',
  justifyContent = 'center',
  alignSelf = 'center',
  flex = 1,
  marginT = 'none',
  marginLeft = 'none',
  primary,
  secondary,
  skeleton,
  warning,
  selected,
  ...rest
}: Props) => {
  return (
    <StyledWrapper
      $padding={padding}
      $position={position}
      $left={left}
      $flexDirection={flexDirection}
      $display={display}
      $justifyContent={justifyContent}
      $alignSelf={alignSelf}
      $flex={flex}
      $marginT={marginT}
      $marginLeft={marginLeft}
      $border={border}
      $borderColor={borderColor}
      primary={primary}
      secondary={secondary}
      skeleton={skeleton}
      selected={selected}
      color={color}
    >
      <AntdButton {...rest}>
        <Text
          color={
            primary ? 'white' : warning ? 'warning' : color ? color : 'black'
          }
          fontSize={fontSize}
          fontWeight={'bold'}
          lineHeight={lineHeight}
          textAlign={textAlign}
        >
          {text}
        </Text>
      </AntdButton>
    </StyledWrapper>
  );
};

export default Button;
