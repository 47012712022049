import { ReactiveVar } from '@apollo/client';

import { getSubTotal } from '../helpers';
import { Cart, MenuItem } from '../models';

export default (singleCartVar: ReactiveVar<Cart>) => {
  return (cartId: number, menuItemId: string) => {
    const cartToEdit = (cart: Cart) => {
      const filteredItems = cart.items.filter(
        (item: MenuItem) => item.id !== menuItemId,
      );
      let subTotal = 0;
      filteredItems.forEach((item) => {
        subTotal = getSubTotal(item, subTotal);
      });
      const tax = cart.tax;
      const delivery = cart.delivery ?? 0;
      const fee = cart.fee;

      return cart.id === cartId
        ? {
            id: cart.id,
            items: filteredItems ? filteredItems : [],
            orderName: cart.orderName,
            subTotal,
            tax,
            tip: cart.tip,
            delivery,
            fee,
            total: tax + subTotal + delivery + fee,
            promoCodes: cart.promoCodes,
            recalculateDiscount:
              cart.promoDiscount || cart.giftCardsDiscount ? true : false,
          }
        : cart;
    };

    singleCartVar(cartToEdit(singleCartVar()));
  };
};
