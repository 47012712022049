import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Json: any;
};

export type Address = {
  __typename?: 'Address';
  customerInfo: UserCustomerInfo;
  deliveryEligibilities: Array<DeliveryEligibility>;
  formattedAddress?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isDefault: Scalars['Boolean'];
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  locality?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  placeId: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
};

export type AddressCreateInput = {
  nickname?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  placeId: Scalars['String'];
};

export type AddressCreateManyCustomerInfoInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  formattedAddress?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  locality?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  placeId: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AddressCreateManyCustomerInfoInputEnvelope = {
  data?: Maybe<Array<AddressCreateManyCustomerInfoInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AddressCreateNestedManyWithoutCustomerInfoInput = {
  connect?: Maybe<Array<AddressWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AddressCreateOrConnectWithoutCustomerInfoInput>>;
  create?: Maybe<Array<AddressCreateWithoutCustomerInfoInput>>;
  createMany?: Maybe<AddressCreateManyCustomerInfoInputEnvelope>;
};

export type AddressCreateNestedOneWithoutDeliveryEligibilitiesInput = {
  connect?: Maybe<AddressWhereUniqueInput>;
  connectOrCreate?: Maybe<AddressCreateOrConnectWithoutDeliveryEligibilitiesInput>;
  create?: Maybe<AddressCreateWithoutDeliveryEligibilitiesInput>;
};

export type AddressCreateOrConnectWithoutCustomerInfoInput = {
  create: AddressCreateWithoutCustomerInfoInput;
  where: AddressWhereUniqueInput;
};

export type AddressCreateOrConnectWithoutDeliveryEligibilitiesInput = {
  create: AddressCreateWithoutDeliveryEligibilitiesInput;
  where: AddressWhereUniqueInput;
};

export type AddressCreateWithoutCustomerInfoInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  deliveryEligibilities?: Maybe<DeliveryEligibilityCreateNestedManyWithoutAddressInput>;
  formattedAddress?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  locality?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  placeId: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AddressCreateWithoutDeliveryEligibilitiesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  customerInfo: UserCustomerInfoCreateNestedOneWithoutAddressesInput;
  formattedAddress?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  locality?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  placeId: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AddressListRelationFilter = {
  every?: Maybe<AddressWhereInput>;
  none?: Maybe<AddressWhereInput>;
  some?: Maybe<AddressWhereInput>;
};

export type AddressScalarWhereInput = {
  AND?: Maybe<Array<AddressScalarWhereInput>>;
  NOT?: Maybe<Array<AddressScalarWhereInput>>;
  OR?: Maybe<Array<AddressScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  customerInfoId?: Maybe<StringFilter>;
  formattedAddress?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  lat?: Maybe<FloatNullableFilter>;
  lng?: Maybe<FloatNullableFilter>;
  locality?: Maybe<StringNullableFilter>;
  nickname?: Maybe<StringNullableFilter>;
  note?: Maybe<StringNullableFilter>;
  placeId?: Maybe<StringFilter>;
  postalCode?: Maybe<StringNullableFilter>;
  region?: Maybe<StringNullableFilter>;
  street?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type AddressUpdateInput = {
  id: Scalars['ID'];
  nickname?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  placeId: Scalars['String'];
};

export type AddressUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  formattedAddress?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  lat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  lng?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  locality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  nickname?: Maybe<NullableStringFieldUpdateOperationsInput>;
  note?: Maybe<NullableStringFieldUpdateOperationsInput>;
  placeId?: Maybe<StringFieldUpdateOperationsInput>;
  postalCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  region?: Maybe<NullableStringFieldUpdateOperationsInput>;
  street?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type AddressUpdateManyWithWhereWithoutCustomerInfoInput = {
  data: AddressUpdateManyMutationInput;
  where: AddressScalarWhereInput;
};

export type AddressUpdateManyWithoutCustomerInfoInput = {
  connect?: Maybe<Array<AddressWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AddressCreateOrConnectWithoutCustomerInfoInput>>;
  create?: Maybe<Array<AddressCreateWithoutCustomerInfoInput>>;
  createMany?: Maybe<AddressCreateManyCustomerInfoInputEnvelope>;
  delete?: Maybe<Array<AddressWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AddressScalarWhereInput>>;
  disconnect?: Maybe<Array<AddressWhereUniqueInput>>;
  set?: Maybe<Array<AddressWhereUniqueInput>>;
  update?: Maybe<Array<AddressUpdateWithWhereUniqueWithoutCustomerInfoInput>>;
  updateMany?: Maybe<Array<AddressUpdateManyWithWhereWithoutCustomerInfoInput>>;
  upsert?: Maybe<Array<AddressUpsertWithWhereUniqueWithoutCustomerInfoInput>>;
};

export type AddressUpdateOneRequiredWithoutDeliveryEligibilitiesInput = {
  connect?: Maybe<AddressWhereUniqueInput>;
  connectOrCreate?: Maybe<AddressCreateOrConnectWithoutDeliveryEligibilitiesInput>;
  create?: Maybe<AddressCreateWithoutDeliveryEligibilitiesInput>;
  update?: Maybe<AddressUpdateWithoutDeliveryEligibilitiesInput>;
  upsert?: Maybe<AddressUpsertWithoutDeliveryEligibilitiesInput>;
};

export type AddressUpdateWithWhereUniqueWithoutCustomerInfoInput = {
  data: AddressUpdateWithoutCustomerInfoInput;
  where: AddressWhereUniqueInput;
};

export type AddressUpdateWithoutCustomerInfoInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deliveryEligibilities?: Maybe<DeliveryEligibilityUpdateManyWithoutAddressInput>;
  formattedAddress?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  lat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  lng?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  locality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  nickname?: Maybe<NullableStringFieldUpdateOperationsInput>;
  note?: Maybe<NullableStringFieldUpdateOperationsInput>;
  placeId?: Maybe<StringFieldUpdateOperationsInput>;
  postalCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  region?: Maybe<NullableStringFieldUpdateOperationsInput>;
  street?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type AddressUpdateWithoutDeliveryEligibilitiesInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customerInfo?: Maybe<UserCustomerInfoUpdateOneRequiredWithoutAddressesInput>;
  formattedAddress?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  lat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  lng?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  locality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  nickname?: Maybe<NullableStringFieldUpdateOperationsInput>;
  note?: Maybe<NullableStringFieldUpdateOperationsInput>;
  placeId?: Maybe<StringFieldUpdateOperationsInput>;
  postalCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  region?: Maybe<NullableStringFieldUpdateOperationsInput>;
  street?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type AddressUpsertWithWhereUniqueWithoutCustomerInfoInput = {
  create: AddressCreateWithoutCustomerInfoInput;
  update: AddressUpdateWithoutCustomerInfoInput;
  where: AddressWhereUniqueInput;
};

export type AddressUpsertWithoutDeliveryEligibilitiesInput = {
  create: AddressCreateWithoutDeliveryEligibilitiesInput;
  update: AddressUpdateWithoutDeliveryEligibilitiesInput;
};

export type AddressWhereInput = {
  AND?: Maybe<Array<AddressWhereInput>>;
  NOT?: Maybe<Array<AddressWhereInput>>;
  OR?: Maybe<Array<AddressWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  customerInfo?: Maybe<UserCustomerInfoWhereInput>;
  customerInfoId?: Maybe<StringFilter>;
  deliveryEligibilities?: Maybe<DeliveryEligibilityListRelationFilter>;
  formattedAddress?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  lat?: Maybe<FloatNullableFilter>;
  lng?: Maybe<FloatNullableFilter>;
  locality?: Maybe<StringNullableFilter>;
  nickname?: Maybe<StringNullableFilter>;
  note?: Maybe<StringNullableFilter>;
  placeId?: Maybe<StringFilter>;
  postalCode?: Maybe<StringNullableFilter>;
  region?: Maybe<StringNullableFilter>;
  street?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type AddressWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type AdminAddressUpdateInput = {
  id: Scalars['ID'];
  isDefault?: Maybe<Scalars['Boolean']>;
  placeId: Scalars['String'];
};

export type AdminUserUpdateInput = {
  customerNotes?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  internalNotes?: Maybe<Array<Scalars['String']>>;
  isAllowedToPayByInvoice: Scalars['Boolean'];
  isEmployee: Scalars['Boolean'];
  vip: Scalars['Boolean'];
};

export enum Allergy {
  DairyFree = 'DAIRY_FREE',
  GlutenFree = 'GLUTEN_FREE',
  PeanutFree = 'PEANUT_FREE',
  ShellfishFree = 'SHELLFISH_FREE',
  TreeNutFree = 'TREE_NUT_FREE'
}

export enum ApiError {
  BadUserInput = 'BAD_USER_INPUT',
  BraintreeAuthentication = 'BRAINTREE_AUTHENTICATION',
  BraintreeAuthorization = 'BRAINTREE_AUTHORIZATION',
  BraintreeError = 'BRAINTREE_ERROR',
  BraintreeGatewayUnreachable = 'BRAINTREE_GATEWAY_UNREACHABLE',
  BraintreeInternal = 'BRAINTREE_INTERNAL',
  BraintreeNotFound = 'BRAINTREE_NOT_FOUND',
  BraintreeNotImplemented = 'BRAINTREE_NOT_IMPLEMENTED',
  BraintreePaymentAuthorizationExpired = 'BRAINTREE_PAYMENT_AUTHORIZATION_EXPIRED',
  BraintreePaymentAuthorizing = 'BRAINTREE_PAYMENT_AUTHORIZING',
  BraintreePaymentFailed = 'BRAINTREE_PAYMENT_FAILED',
  BraintreePaymentGatewayRejected = 'BRAINTREE_PAYMENT_GATEWAY_REJECTED',
  BraintreePaymentProcessorDeclined = 'BRAINTREE_PAYMENT_PROCESSOR_DECLINED',
  BraintreePaymentSettlementDeclined = 'BRAINTREE_PAYMENT_SETTLEMENT_DECLINED',
  BraintreePaymentUnknownStatus = 'BRAINTREE_PAYMENT_UNKNOWN_STATUS',
  BraintreePaymentVoided = 'BRAINTREE_PAYMENT_VOIDED',
  BraintreeResourceLimit = 'BRAINTREE_RESOURCE_LIMIT',
  BraintreeServiceAvailability = 'BRAINTREE_SERVICE_AVAILABILITY',
  BraintreeUnsupportedClient = 'BRAINTREE_UNSUPPORTED_CLIENT',
  BraintreeValidation = 'BRAINTREE_VALIDATION',
  Forbidden = 'FORBIDDEN',
  GraphqlParseFailed = 'GRAPHQL_PARSE_FAILED',
  GraphqlValidationFailed = 'GRAPHQL_VALIDATION_FAILED',
  InternalServerError = 'INTERNAL_SERVER_ERROR',
  PersistedQueryNotFound = 'PERSISTED_QUERY_NOT_FOUND',
  PersistedQueryNotSupported = 'PERSISTED_QUERY_NOT_SUPPORTED',
  SecurityError = 'SECURITY_ERROR',
  Unauthenticated = 'UNAUTHENTICATED'
}

export type AvailablePowerBiReport = {
  __typename?: 'AvailablePowerBiReport';
  datasetId: Scalars['String'];
  embedUrl: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
};

export type AvailablePowerBiWorkspace = {
  __typename?: 'AvailablePowerBiWorkspace';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type BoolFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Boolean']>;
};

export type BoolFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolFilter>;
};

export type BoolNullableFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolNullableFilter>;
};

export enum BraintreePaymentMethod {
  CreditCard = 'CREDIT_CARD',
  Paypal = 'PAYPAL',
  Venmo = 'VENMO'
}

export enum BraintreePaymentSuccessStatus {
  Authorized = 'AUTHORIZED',
  Settled = 'SETTLED',
  SettlementPending = 'SETTLEMENT_PENDING',
  Settling = 'SETTLING',
  SubmittedForSettlement = 'SUBMITTED_FOR_SETTLEMENT'
}

export type BraintreeTransaction = {
  __typename?: 'BraintreeTransaction';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  order: Order;
  orderId: Scalars['String'];
  paymentMethod: BraintreePaymentMethod;
  paymentMethodId: Scalars['String'];
  paymentMethodInfo: Scalars['String'];
  status: BraintreePaymentSuccessStatus;
  terminal: Scalars['Boolean'];
};

export type BraintreeTransactionCreateManyOrderInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  paymentMethod: BraintreePaymentMethod;
  paymentMethodId: Scalars['String'];
  paymentMethodInfo: Scalars['String'];
  status: BraintreePaymentSuccessStatus;
  terminal: Scalars['Boolean'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BraintreeTransactionCreateManyOrderInputEnvelope = {
  data?: Maybe<Array<BraintreeTransactionCreateManyOrderInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BraintreeTransactionCreateNestedManyWithoutOrderInput = {
  connect?: Maybe<Array<BraintreeTransactionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BraintreeTransactionCreateOrConnectWithoutOrderInput>>;
  create?: Maybe<Array<BraintreeTransactionCreateWithoutOrderInput>>;
  createMany?: Maybe<BraintreeTransactionCreateManyOrderInputEnvelope>;
};

export type BraintreeTransactionCreateOrConnectWithoutOrderInput = {
  create: BraintreeTransactionCreateWithoutOrderInput;
  where: BraintreeTransactionWhereUniqueInput;
};

export type BraintreeTransactionCreateWithoutOrderInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  paymentMethod: BraintreePaymentMethod;
  paymentMethodId: Scalars['String'];
  paymentMethodInfo: Scalars['String'];
  status: BraintreePaymentSuccessStatus;
  terminal: Scalars['Boolean'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BraintreeTransactionListRelationFilter = {
  every?: Maybe<BraintreeTransactionWhereInput>;
  none?: Maybe<BraintreeTransactionWhereInput>;
  some?: Maybe<BraintreeTransactionWhereInput>;
};

export type BraintreeTransactionScalarWhereInput = {
  AND?: Maybe<Array<BraintreeTransactionScalarWhereInput>>;
  NOT?: Maybe<Array<BraintreeTransactionScalarWhereInput>>;
  OR?: Maybe<Array<BraintreeTransactionScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  orderId?: Maybe<StringFilter>;
  paymentMethod?: Maybe<EnumBraintreePaymentMethodFilter>;
  paymentMethodId?: Maybe<StringFilter>;
  paymentMethodInfo?: Maybe<StringFilter>;
  status?: Maybe<EnumBraintreePaymentSuccessStatusFilter>;
  terminal?: Maybe<BoolFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type BraintreeTransactionUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  paymentMethod?: Maybe<EnumBraintreePaymentMethodFieldUpdateOperationsInput>;
  paymentMethodId?: Maybe<StringFieldUpdateOperationsInput>;
  paymentMethodInfo?: Maybe<StringFieldUpdateOperationsInput>;
  status?: Maybe<EnumBraintreePaymentSuccessStatusFieldUpdateOperationsInput>;
  terminal?: Maybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type BraintreeTransactionUpdateManyWithWhereWithoutOrderInput = {
  data: BraintreeTransactionUpdateManyMutationInput;
  where: BraintreeTransactionScalarWhereInput;
};

export type BraintreeTransactionUpdateManyWithoutOrderInput = {
  connect?: Maybe<Array<BraintreeTransactionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BraintreeTransactionCreateOrConnectWithoutOrderInput>>;
  create?: Maybe<Array<BraintreeTransactionCreateWithoutOrderInput>>;
  createMany?: Maybe<BraintreeTransactionCreateManyOrderInputEnvelope>;
  delete?: Maybe<Array<BraintreeTransactionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BraintreeTransactionScalarWhereInput>>;
  disconnect?: Maybe<Array<BraintreeTransactionWhereUniqueInput>>;
  set?: Maybe<Array<BraintreeTransactionWhereUniqueInput>>;
  update?: Maybe<Array<BraintreeTransactionUpdateWithWhereUniqueWithoutOrderInput>>;
  updateMany?: Maybe<Array<BraintreeTransactionUpdateManyWithWhereWithoutOrderInput>>;
  upsert?: Maybe<Array<BraintreeTransactionUpsertWithWhereUniqueWithoutOrderInput>>;
};

export type BraintreeTransactionUpdateWithWhereUniqueWithoutOrderInput = {
  data: BraintreeTransactionUpdateWithoutOrderInput;
  where: BraintreeTransactionWhereUniqueInput;
};

export type BraintreeTransactionUpdateWithoutOrderInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  paymentMethod?: Maybe<EnumBraintreePaymentMethodFieldUpdateOperationsInput>;
  paymentMethodId?: Maybe<StringFieldUpdateOperationsInput>;
  paymentMethodInfo?: Maybe<StringFieldUpdateOperationsInput>;
  status?: Maybe<EnumBraintreePaymentSuccessStatusFieldUpdateOperationsInput>;
  terminal?: Maybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type BraintreeTransactionUpsertWithWhereUniqueWithoutOrderInput = {
  create: BraintreeTransactionCreateWithoutOrderInput;
  update: BraintreeTransactionUpdateWithoutOrderInput;
  where: BraintreeTransactionWhereUniqueInput;
};

export type BraintreeTransactionWhereInput = {
  AND?: Maybe<Array<BraintreeTransactionWhereInput>>;
  NOT?: Maybe<Array<BraintreeTransactionWhereInput>>;
  OR?: Maybe<Array<BraintreeTransactionWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  order?: Maybe<OrderWhereInput>;
  orderId?: Maybe<StringFilter>;
  paymentMethod?: Maybe<EnumBraintreePaymentMethodFilter>;
  paymentMethodId?: Maybe<StringFilter>;
  paymentMethodInfo?: Maybe<StringFilter>;
  status?: Maybe<EnumBraintreePaymentSuccessStatusFilter>;
  terminal?: Maybe<BoolFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type BraintreeTransactionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum BusinessType {
  Accounting = 'ACCOUNTING',
  Beauty = 'BEAUTY',
  Construction = 'CONSTRUCTION',
  Education = 'EDUCATION',
  Events = 'EVENTS',
  Finance = 'FINANCE',
  Government = 'GOVERNMENT',
  Legal = 'LEGAL',
  NonProfits = 'NON_PROFITS',
  Other = 'OTHER',
  RealEstate = 'REAL_ESTATE',
  Retail = 'RETAIL',
  Technology = 'TECHNOLOGY',
  Telecommunication = 'TELECOMMUNICATION',
  Transportation = 'TRANSPORTATION'
}

export type Cart = {
  __typename?: 'Cart';
  id: Scalars['String'];
  items: Array<CartItem>;
  promoCodes: Array<Scalars['String']>;
  promoErrors: Array<PromoValidationErrorWithCode>;
  utensilsCount: Scalars['Int'];
};


export type CartItemsArgs = {
  after?: Maybe<CartItemWhereUniqueInput>;
  before?: Maybe<CartItemWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CartCosts = {
  __typename?: 'CartCosts';
  delivery: Scalars['Float'];
  fee: Scalars['Float'];
  giftCardsDiscount: Scalars['Float'];
  promoDiscount: Scalars['Float'];
  subtotal: Scalars['Float'];
  tax: Scalars['Float'];
  taxFees: Scalars['Float'];
  tip: Scalars['Float'];
  total: Scalars['Float'];
};

export type CartCreateNestedOneWithoutItemsInput = {
  connect?: Maybe<CartWhereUniqueInput>;
  connectOrCreate?: Maybe<CartCreateOrConnectWithoutItemsInput>;
  create?: Maybe<CartCreateWithoutItemsInput>;
};

export type CartCreateNestedOneWithoutUserCustomerInfoInput = {
  connect?: Maybe<CartWhereUniqueInput>;
  connectOrCreate?: Maybe<CartCreateOrConnectWithoutUserCustomerInfoInput>;
  create?: Maybe<CartCreateWithoutUserCustomerInfoInput>;
};

export type CartCreateOrConnectWithoutItemsInput = {
  create: CartCreateWithoutItemsInput;
  where: CartWhereUniqueInput;
};

export type CartCreateOrConnectWithoutUserCustomerInfoInput = {
  create: CartCreateWithoutUserCustomerInfoInput;
  where: CartWhereUniqueInput;
};

export type CartCreateWithoutItemsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  promoCodes?: Maybe<CartCreatepromoCodesInput>;
  promoErrors?: Maybe<CartCreatepromoErrorsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userCustomerInfo?: Maybe<UserCustomerInfoCreateNestedManyWithoutCartInput>;
  utensilsCount?: Maybe<Scalars['Int']>;
};

export type CartCreateWithoutUserCustomerInfoInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  items?: Maybe<CartItemCreateNestedManyWithoutCartInput>;
  promoCodes?: Maybe<CartCreatepromoCodesInput>;
  promoErrors?: Maybe<CartCreatepromoErrorsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  utensilsCount?: Maybe<Scalars['Int']>;
};

export type CartCreatepromoCodesInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type CartCreatepromoErrorsInput = {
  set?: Maybe<Array<Scalars['Json']>>;
};

export type CartExtraItem = {
  __typename?: 'CartExtraItem';
  extraItem: ExtraItem;
  id: Scalars['String'];
  mealPackItemId?: Maybe<Scalars['String']>;
  quantity: Scalars['Int'];
};

export type CartExtraItemCreateManyCartItemInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  extraItemId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  mealPackItemId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CartExtraItemCreateManyCartItemInputEnvelope = {
  data?: Maybe<Array<CartExtraItemCreateManyCartItemInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CartExtraItemCreateManyExtraItemInput = {
  cartItemId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  mealPackItemId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CartExtraItemCreateManyExtraItemInputEnvelope = {
  data?: Maybe<Array<CartExtraItemCreateManyExtraItemInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CartExtraItemCreateNestedManyWithoutCartItemInput = {
  connect?: Maybe<Array<CartExtraItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CartExtraItemCreateOrConnectWithoutCartItemInput>>;
  create?: Maybe<Array<CartExtraItemCreateWithoutCartItemInput>>;
  createMany?: Maybe<CartExtraItemCreateManyCartItemInputEnvelope>;
};

export type CartExtraItemCreateNestedManyWithoutExtraItemInput = {
  connect?: Maybe<Array<CartExtraItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CartExtraItemCreateOrConnectWithoutExtraItemInput>>;
  create?: Maybe<Array<CartExtraItemCreateWithoutExtraItemInput>>;
  createMany?: Maybe<CartExtraItemCreateManyExtraItemInputEnvelope>;
};

export type CartExtraItemCreateOrConnectWithoutCartItemInput = {
  create: CartExtraItemCreateWithoutCartItemInput;
  where: CartExtraItemWhereUniqueInput;
};

export type CartExtraItemCreateOrConnectWithoutExtraItemInput = {
  create: CartExtraItemCreateWithoutExtraItemInput;
  where: CartExtraItemWhereUniqueInput;
};

export type CartExtraItemCreateWithoutCartItemInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  extraItem: ExtraItemCreateNestedOneWithoutCartExtraItemsInput;
  id?: Maybe<Scalars['String']>;
  mealPackItemId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CartExtraItemCreateWithoutExtraItemInput = {
  cartItem: CartItemCreateNestedOneWithoutCartExtraItemsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  mealPackItemId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CartExtraItemInput = {
  cartExtraItemId?: Maybe<Scalars['ID']>;
  extraItemId: Scalars['ID'];
  mealPackItemId?: Maybe<Scalars['ID']>;
  quantity?: Maybe<Scalars['Int']>;
};

export type CartExtraItemListRelationFilter = {
  every?: Maybe<CartExtraItemWhereInput>;
  none?: Maybe<CartExtraItemWhereInput>;
  some?: Maybe<CartExtraItemWhereInput>;
};

export type CartExtraItemScalarWhereInput = {
  AND?: Maybe<Array<CartExtraItemScalarWhereInput>>;
  NOT?: Maybe<Array<CartExtraItemScalarWhereInput>>;
  OR?: Maybe<Array<CartExtraItemScalarWhereInput>>;
  cartItemId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  extraItemId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  mealPackItemId?: Maybe<StringNullableFilter>;
  quantity?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CartExtraItemUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  mealPackItemId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  quantity?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type CartExtraItemUpdateManyWithWhereWithoutCartItemInput = {
  data: CartExtraItemUpdateManyMutationInput;
  where: CartExtraItemScalarWhereInput;
};

export type CartExtraItemUpdateManyWithWhereWithoutExtraItemInput = {
  data: CartExtraItemUpdateManyMutationInput;
  where: CartExtraItemScalarWhereInput;
};

export type CartExtraItemUpdateManyWithoutCartItemInput = {
  connect?: Maybe<Array<CartExtraItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CartExtraItemCreateOrConnectWithoutCartItemInput>>;
  create?: Maybe<Array<CartExtraItemCreateWithoutCartItemInput>>;
  createMany?: Maybe<CartExtraItemCreateManyCartItemInputEnvelope>;
  delete?: Maybe<Array<CartExtraItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CartExtraItemScalarWhereInput>>;
  disconnect?: Maybe<Array<CartExtraItemWhereUniqueInput>>;
  set?: Maybe<Array<CartExtraItemWhereUniqueInput>>;
  update?: Maybe<Array<CartExtraItemUpdateWithWhereUniqueWithoutCartItemInput>>;
  updateMany?: Maybe<Array<CartExtraItemUpdateManyWithWhereWithoutCartItemInput>>;
  upsert?: Maybe<Array<CartExtraItemUpsertWithWhereUniqueWithoutCartItemInput>>;
};

export type CartExtraItemUpdateManyWithoutExtraItemInput = {
  connect?: Maybe<Array<CartExtraItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CartExtraItemCreateOrConnectWithoutExtraItemInput>>;
  create?: Maybe<Array<CartExtraItemCreateWithoutExtraItemInput>>;
  createMany?: Maybe<CartExtraItemCreateManyExtraItemInputEnvelope>;
  delete?: Maybe<Array<CartExtraItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CartExtraItemScalarWhereInput>>;
  disconnect?: Maybe<Array<CartExtraItemWhereUniqueInput>>;
  set?: Maybe<Array<CartExtraItemWhereUniqueInput>>;
  update?: Maybe<Array<CartExtraItemUpdateWithWhereUniqueWithoutExtraItemInput>>;
  updateMany?: Maybe<Array<CartExtraItemUpdateManyWithWhereWithoutExtraItemInput>>;
  upsert?: Maybe<Array<CartExtraItemUpsertWithWhereUniqueWithoutExtraItemInput>>;
};

export type CartExtraItemUpdateWithWhereUniqueWithoutCartItemInput = {
  data: CartExtraItemUpdateWithoutCartItemInput;
  where: CartExtraItemWhereUniqueInput;
};

export type CartExtraItemUpdateWithWhereUniqueWithoutExtraItemInput = {
  data: CartExtraItemUpdateWithoutExtraItemInput;
  where: CartExtraItemWhereUniqueInput;
};

export type CartExtraItemUpdateWithoutCartItemInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  extraItem?: Maybe<ExtraItemUpdateOneRequiredWithoutCartExtraItemsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  mealPackItemId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  quantity?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type CartExtraItemUpdateWithoutExtraItemInput = {
  cartItem?: Maybe<CartItemUpdateOneRequiredWithoutCartExtraItemsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  mealPackItemId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  quantity?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type CartExtraItemUpsertWithWhereUniqueWithoutCartItemInput = {
  create: CartExtraItemCreateWithoutCartItemInput;
  update: CartExtraItemUpdateWithoutCartItemInput;
  where: CartExtraItemWhereUniqueInput;
};

export type CartExtraItemUpsertWithWhereUniqueWithoutExtraItemInput = {
  create: CartExtraItemCreateWithoutExtraItemInput;
  update: CartExtraItemUpdateWithoutExtraItemInput;
  where: CartExtraItemWhereUniqueInput;
};

export type CartExtraItemWhereInput = {
  AND?: Maybe<Array<CartExtraItemWhereInput>>;
  NOT?: Maybe<Array<CartExtraItemWhereInput>>;
  OR?: Maybe<Array<CartExtraItemWhereInput>>;
  cartItem?: Maybe<CartItemWhereInput>;
  cartItemId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  extraItem?: Maybe<ExtraItemWhereInput>;
  extraItemId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  mealPackItemId?: Maybe<StringNullableFilter>;
  quantity?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CartExtraItemWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type CartInput = {
  cartItems: Array<CartItemInput>;
  promoCodes?: Maybe<Array<Scalars['String']>>;
  utensilsCount?: Maybe<Scalars['Int']>;
};

export type CartItem = {
  __typename?: 'CartItem';
  cartExtraItems: Array<CartExtraItem>;
  id: Scalars['String'];
  kitchenMenuItem: KitchenMenuItem;
  quantity: Scalars['Int'];
};


export type CartItemCartExtraItemsArgs = {
  after?: Maybe<CartExtraItemWhereUniqueInput>;
  before?: Maybe<CartExtraItemWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CartItemCreateManyCartInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  kitchenMenuItemId: Scalars['String'];
  quantity?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CartItemCreateManyCartInputEnvelope = {
  data?: Maybe<Array<CartItemCreateManyCartInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CartItemCreateManyKitchenMenuItemInput = {
  cartId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CartItemCreateManyKitchenMenuItemInputEnvelope = {
  data?: Maybe<Array<CartItemCreateManyKitchenMenuItemInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CartItemCreateNestedManyWithoutCartInput = {
  connect?: Maybe<Array<CartItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CartItemCreateOrConnectWithoutCartInput>>;
  create?: Maybe<Array<CartItemCreateWithoutCartInput>>;
  createMany?: Maybe<CartItemCreateManyCartInputEnvelope>;
};

export type CartItemCreateNestedManyWithoutKitchenMenuItemInput = {
  connect?: Maybe<Array<CartItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CartItemCreateOrConnectWithoutKitchenMenuItemInput>>;
  create?: Maybe<Array<CartItemCreateWithoutKitchenMenuItemInput>>;
  createMany?: Maybe<CartItemCreateManyKitchenMenuItemInputEnvelope>;
};

export type CartItemCreateNestedOneWithoutCartExtraItemsInput = {
  connect?: Maybe<CartItemWhereUniqueInput>;
  connectOrCreate?: Maybe<CartItemCreateOrConnectWithoutCartExtraItemsInput>;
  create?: Maybe<CartItemCreateWithoutCartExtraItemsInput>;
};

export type CartItemCreateOrConnectWithoutCartExtraItemsInput = {
  create: CartItemCreateWithoutCartExtraItemsInput;
  where: CartItemWhereUniqueInput;
};

export type CartItemCreateOrConnectWithoutCartInput = {
  create: CartItemCreateWithoutCartInput;
  where: CartItemWhereUniqueInput;
};

export type CartItemCreateOrConnectWithoutKitchenMenuItemInput = {
  create: CartItemCreateWithoutKitchenMenuItemInput;
  where: CartItemWhereUniqueInput;
};

export type CartItemCreateWithoutCartExtraItemsInput = {
  cart: CartCreateNestedOneWithoutItemsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  kitchenMenuItem: KitchenMenuItemCreateNestedOneWithoutCartItemsInput;
  quantity?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CartItemCreateWithoutCartInput = {
  cartExtraItems?: Maybe<CartExtraItemCreateNestedManyWithoutCartItemInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  kitchenMenuItem: KitchenMenuItemCreateNestedOneWithoutCartItemsInput;
  quantity?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CartItemCreateWithoutKitchenMenuItemInput = {
  cart: CartCreateNestedOneWithoutItemsInput;
  cartExtraItems?: Maybe<CartExtraItemCreateNestedManyWithoutCartItemInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CartItemInput = {
  extraItems: Array<CartExtraItemInput>;
  id: Scalars['ID'];
  quantity: Scalars['Int'];
};

export type CartItemListRelationFilter = {
  every?: Maybe<CartItemWhereInput>;
  none?: Maybe<CartItemWhereInput>;
  some?: Maybe<CartItemWhereInput>;
};

export type CartItemScalarWhereInput = {
  AND?: Maybe<Array<CartItemScalarWhereInput>>;
  NOT?: Maybe<Array<CartItemScalarWhereInput>>;
  OR?: Maybe<Array<CartItemScalarWhereInput>>;
  cartId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  kitchenMenuItemId?: Maybe<StringFilter>;
  quantity?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CartItemUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  quantity?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type CartItemUpdateManyWithWhereWithoutCartInput = {
  data: CartItemUpdateManyMutationInput;
  where: CartItemScalarWhereInput;
};

export type CartItemUpdateManyWithWhereWithoutKitchenMenuItemInput = {
  data: CartItemUpdateManyMutationInput;
  where: CartItemScalarWhereInput;
};

export type CartItemUpdateManyWithoutCartInput = {
  connect?: Maybe<Array<CartItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CartItemCreateOrConnectWithoutCartInput>>;
  create?: Maybe<Array<CartItemCreateWithoutCartInput>>;
  createMany?: Maybe<CartItemCreateManyCartInputEnvelope>;
  delete?: Maybe<Array<CartItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CartItemScalarWhereInput>>;
  disconnect?: Maybe<Array<CartItemWhereUniqueInput>>;
  set?: Maybe<Array<CartItemWhereUniqueInput>>;
  update?: Maybe<Array<CartItemUpdateWithWhereUniqueWithoutCartInput>>;
  updateMany?: Maybe<Array<CartItemUpdateManyWithWhereWithoutCartInput>>;
  upsert?: Maybe<Array<CartItemUpsertWithWhereUniqueWithoutCartInput>>;
};

export type CartItemUpdateManyWithoutKitchenMenuItemInput = {
  connect?: Maybe<Array<CartItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CartItemCreateOrConnectWithoutKitchenMenuItemInput>>;
  create?: Maybe<Array<CartItemCreateWithoutKitchenMenuItemInput>>;
  createMany?: Maybe<CartItemCreateManyKitchenMenuItemInputEnvelope>;
  delete?: Maybe<Array<CartItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CartItemScalarWhereInput>>;
  disconnect?: Maybe<Array<CartItemWhereUniqueInput>>;
  set?: Maybe<Array<CartItemWhereUniqueInput>>;
  update?: Maybe<Array<CartItemUpdateWithWhereUniqueWithoutKitchenMenuItemInput>>;
  updateMany?: Maybe<Array<CartItemUpdateManyWithWhereWithoutKitchenMenuItemInput>>;
  upsert?: Maybe<Array<CartItemUpsertWithWhereUniqueWithoutKitchenMenuItemInput>>;
};

export type CartItemUpdateOneRequiredWithoutCartExtraItemsInput = {
  connect?: Maybe<CartItemWhereUniqueInput>;
  connectOrCreate?: Maybe<CartItemCreateOrConnectWithoutCartExtraItemsInput>;
  create?: Maybe<CartItemCreateWithoutCartExtraItemsInput>;
  update?: Maybe<CartItemUpdateWithoutCartExtraItemsInput>;
  upsert?: Maybe<CartItemUpsertWithoutCartExtraItemsInput>;
};

export type CartItemUpdateWithWhereUniqueWithoutCartInput = {
  data: CartItemUpdateWithoutCartInput;
  where: CartItemWhereUniqueInput;
};

export type CartItemUpdateWithWhereUniqueWithoutKitchenMenuItemInput = {
  data: CartItemUpdateWithoutKitchenMenuItemInput;
  where: CartItemWhereUniqueInput;
};

export type CartItemUpdateWithoutCartExtraItemsInput = {
  cart?: Maybe<CartUpdateOneRequiredWithoutItemsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  kitchenMenuItem?: Maybe<KitchenMenuItemUpdateOneRequiredWithoutCartItemsInput>;
  quantity?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type CartItemUpdateWithoutCartInput = {
  cartExtraItems?: Maybe<CartExtraItemUpdateManyWithoutCartItemInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  kitchenMenuItem?: Maybe<KitchenMenuItemUpdateOneRequiredWithoutCartItemsInput>;
  quantity?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type CartItemUpdateWithoutKitchenMenuItemInput = {
  cart?: Maybe<CartUpdateOneRequiredWithoutItemsInput>;
  cartExtraItems?: Maybe<CartExtraItemUpdateManyWithoutCartItemInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  quantity?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type CartItemUpsertWithWhereUniqueWithoutCartInput = {
  create: CartItemCreateWithoutCartInput;
  update: CartItemUpdateWithoutCartInput;
  where: CartItemWhereUniqueInput;
};

export type CartItemUpsertWithWhereUniqueWithoutKitchenMenuItemInput = {
  create: CartItemCreateWithoutKitchenMenuItemInput;
  update: CartItemUpdateWithoutKitchenMenuItemInput;
  where: CartItemWhereUniqueInput;
};

export type CartItemUpsertWithoutCartExtraItemsInput = {
  create: CartItemCreateWithoutCartExtraItemsInput;
  update: CartItemUpdateWithoutCartExtraItemsInput;
};

export type CartItemWhereInput = {
  AND?: Maybe<Array<CartItemWhereInput>>;
  NOT?: Maybe<Array<CartItemWhereInput>>;
  OR?: Maybe<Array<CartItemWhereInput>>;
  cart?: Maybe<CartWhereInput>;
  cartExtraItems?: Maybe<CartExtraItemListRelationFilter>;
  cartId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  kitchenMenuItem?: Maybe<KitchenMenuItemWhereInput>;
  kitchenMenuItemId?: Maybe<StringFilter>;
  quantity?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CartItemWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type CartUpdateOneRequiredWithoutItemsInput = {
  connect?: Maybe<CartWhereUniqueInput>;
  connectOrCreate?: Maybe<CartCreateOrConnectWithoutItemsInput>;
  create?: Maybe<CartCreateWithoutItemsInput>;
  update?: Maybe<CartUpdateWithoutItemsInput>;
  upsert?: Maybe<CartUpsertWithoutItemsInput>;
};

export type CartUpdateOneRequiredWithoutUserCustomerInfoInput = {
  connect?: Maybe<CartWhereUniqueInput>;
  connectOrCreate?: Maybe<CartCreateOrConnectWithoutUserCustomerInfoInput>;
  create?: Maybe<CartCreateWithoutUserCustomerInfoInput>;
  update?: Maybe<CartUpdateWithoutUserCustomerInfoInput>;
  upsert?: Maybe<CartUpsertWithoutUserCustomerInfoInput>;
};

export type CartUpdateWithoutItemsInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  promoCodes?: Maybe<CartUpdatepromoCodesInput>;
  promoErrors?: Maybe<CartUpdatepromoErrorsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  userCustomerInfo?: Maybe<UserCustomerInfoUpdateManyWithoutCartInput>;
  utensilsCount?: Maybe<IntFieldUpdateOperationsInput>;
};

export type CartUpdateWithoutUserCustomerInfoInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  items?: Maybe<CartItemUpdateManyWithoutCartInput>;
  promoCodes?: Maybe<CartUpdatepromoCodesInput>;
  promoErrors?: Maybe<CartUpdatepromoErrorsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  utensilsCount?: Maybe<IntFieldUpdateOperationsInput>;
};

export type CartUpdatepromoCodesInput = {
  push?: Maybe<Scalars['String']>;
  set?: Maybe<Array<Scalars['String']>>;
};

export type CartUpdatepromoErrorsInput = {
  push?: Maybe<Scalars['Json']>;
  set?: Maybe<Array<Scalars['Json']>>;
};

export type CartUpsertWithoutItemsInput = {
  create: CartCreateWithoutItemsInput;
  update: CartUpdateWithoutItemsInput;
};

export type CartUpsertWithoutUserCustomerInfoInput = {
  create: CartCreateWithoutUserCustomerInfoInput;
  update: CartUpdateWithoutUserCustomerInfoInput;
};

export type CartWhereInput = {
  AND?: Maybe<Array<CartWhereInput>>;
  NOT?: Maybe<Array<CartWhereInput>>;
  OR?: Maybe<Array<CartWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  items?: Maybe<CartItemListRelationFilter>;
  promoCodes?: Maybe<StringNullableListFilter>;
  promoErrors?: Maybe<JsonNullableListFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  userCustomerInfo?: Maybe<UserCustomerInfoListRelationFilter>;
  utensilsCount?: Maybe<IntFilter>;
};

export type CartWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ChowlyOrderConfirmation = {
  __typename?: 'ChowlyOrderConfirmation';
  test: Scalars['Boolean'];
};

export type ConfirmChowlyOrderInput = {
  test?: Maybe<Scalars['Boolean']>;
};

export type ConfirmKioskOrderInput = {
  cart: CartInput;
  order: KioskOrderInput;
};

export type ConfirmOrderInput = {
  order: OrderInput;
  payByInvoice?: Maybe<Scalars['Boolean']>;
  paymentMethodId?: Maybe<Scalars['ID']>;
};

export type ConsumerAppSetup = {
  __typename?: 'ConsumerAppSetup';
  facilities: Array<Scalars['String']>;
  isOutdated: Scalars['Boolean'];
  updateUrl: Scalars['String'];
};

export type CookTimesReport = {
  __typename?: 'CookTimesReport';
  cookTime: Scalars['Float'];
  date: Scalars['DateTime'];
  kitchenName: Scalars['String'];
  timeslot: Scalars['DateTime'];
  timeslotTime: Scalars['String'];
};

export type CustomerKitchen = {
  __typename?: 'CustomerKitchen';
  cuisine?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  headerImgUrl?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isOpen?: Maybe<Scalars['Boolean']>;
  logoUrl?: Maybe<Scalars['String']>;
  menuCategories: Array<MenuCategory>;
  name: Scalars['String'];
  operationHours: Array<Scalars['Json']>;
  originalLocation?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  status: KitchenStatus;
  story?: Maybe<Scalars['String']>;
  type: KitchenType;
  /** For consumer app only. If there are no menu items for selected dateTime, next upcoming menu is returned. This is helper field to let client know when the next menu will be available. */
  upcomingMenuStart?: Maybe<Scalars['DateTime']>;
};

export type CustomerKitchenByOpen = {
  __typename?: 'CustomerKitchenByOpen';
  cuisine?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  headerImgUrl?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isOpen: Scalars['Boolean'];
  logoUrl?: Maybe<Scalars['String']>;
  menuCategories: Array<MenuCategory>;
  name: Scalars['String'];
  operationHours: Array<Scalars['Json']>;
  originalLocation?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  status: KitchenStatus;
  story?: Maybe<Scalars['String']>;
  type: KitchenType;
  /**
   * If there are no menu items for selected dateTime, next upcoming menu is returned. This is helper field to let client know when the next menu will be available.
   *
   * If null is returned there is menu schedule for selected time.
   */
  upcomingMenuStart?: Maybe<Scalars['DateTime']>;
};

export type CustomerKitchenInList = {
  __typename?: 'CustomerKitchenInList';
  cuisine?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  headerImgUrl?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  logoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  story?: Maybe<Scalars['String']>;
};

export type CustomerLead = {
  __typename?: 'CustomerLead';
  email: Scalars['String'];
  id: Scalars['String'];
  location: Scalars['String'];
};

export type CustomerLeadCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  location: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CustomerRatingCreateManyCustomerInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  ratingType: CustomerRatingType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['Int'];
};

export type CustomerRatingCreateManyCustomerInputEnvelope = {
  data?: Maybe<Array<CustomerRatingCreateManyCustomerInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CustomerRatingCreateNestedManyWithoutCustomerInput = {
  connect?: Maybe<Array<CustomerRatingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CustomerRatingCreateOrConnectWithoutCustomerInput>>;
  create?: Maybe<Array<CustomerRatingCreateWithoutCustomerInput>>;
  createMany?: Maybe<CustomerRatingCreateManyCustomerInputEnvelope>;
};

export type CustomerRatingCreateOrConnectWithoutCustomerInput = {
  create: CustomerRatingCreateWithoutCustomerInput;
  where: CustomerRatingWhereUniqueInput;
};

export type CustomerRatingCreateWithoutCustomerInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  ratingType: CustomerRatingType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['Int'];
};

export type CustomerRatingListRelationFilter = {
  every?: Maybe<CustomerRatingWhereInput>;
  none?: Maybe<CustomerRatingWhereInput>;
  some?: Maybe<CustomerRatingWhereInput>;
};

export type CustomerRatingScalarWhereInput = {
  AND?: Maybe<Array<CustomerRatingScalarWhereInput>>;
  NOT?: Maybe<Array<CustomerRatingScalarWhereInput>>;
  OR?: Maybe<Array<CustomerRatingScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  customerId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  ratingType?: Maybe<EnumCustomerRatingTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  value?: Maybe<IntFilter>;
};

export enum CustomerRatingType {
  Delivery = 'DELIVERY',
  FoodQuality = 'FOOD_QUALITY',
  Overall = 'OVERALL'
}

export type CustomerRatingUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  ratingType?: Maybe<EnumCustomerRatingTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  value?: Maybe<IntFieldUpdateOperationsInput>;
};

export type CustomerRatingUpdateManyWithWhereWithoutCustomerInput = {
  data: CustomerRatingUpdateManyMutationInput;
  where: CustomerRatingScalarWhereInput;
};

export type CustomerRatingUpdateManyWithoutCustomerInput = {
  connect?: Maybe<Array<CustomerRatingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CustomerRatingCreateOrConnectWithoutCustomerInput>>;
  create?: Maybe<Array<CustomerRatingCreateWithoutCustomerInput>>;
  createMany?: Maybe<CustomerRatingCreateManyCustomerInputEnvelope>;
  delete?: Maybe<Array<CustomerRatingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CustomerRatingScalarWhereInput>>;
  disconnect?: Maybe<Array<CustomerRatingWhereUniqueInput>>;
  set?: Maybe<Array<CustomerRatingWhereUniqueInput>>;
  update?: Maybe<Array<CustomerRatingUpdateWithWhereUniqueWithoutCustomerInput>>;
  updateMany?: Maybe<Array<CustomerRatingUpdateManyWithWhereWithoutCustomerInput>>;
  upsert?: Maybe<Array<CustomerRatingUpsertWithWhereUniqueWithoutCustomerInput>>;
};

export type CustomerRatingUpdateWithWhereUniqueWithoutCustomerInput = {
  data: CustomerRatingUpdateWithoutCustomerInput;
  where: CustomerRatingWhereUniqueInput;
};

export type CustomerRatingUpdateWithoutCustomerInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  ratingType?: Maybe<EnumCustomerRatingTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  value?: Maybe<IntFieldUpdateOperationsInput>;
};

export type CustomerRatingUpsertWithWhereUniqueWithoutCustomerInput = {
  create: CustomerRatingCreateWithoutCustomerInput;
  update: CustomerRatingUpdateWithoutCustomerInput;
  where: CustomerRatingWhereUniqueInput;
};

export type CustomerRatingWhereInput = {
  AND?: Maybe<Array<CustomerRatingWhereInput>>;
  NOT?: Maybe<Array<CustomerRatingWhereInput>>;
  OR?: Maybe<Array<CustomerRatingWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  customer?: Maybe<UserCustomerInfoWhereInput>;
  customerId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  ratingType?: Maybe<EnumCustomerRatingTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  value?: Maybe<IntFilter>;
};

export type CustomerRatingWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type CustomersReturnType = {
  __typename?: 'CustomersReturnType';
  total: Scalars['Int'];
  users: Array<User>;
};

export type DailySalesReport = {
  __typename?: 'DailySalesReport';
  averageRestaurantTotal: Scalars['Float'];
  craveShareInSales: Scalars['Float'];
  craveTotal: Scalars['Float'];
  date: Scalars['DateTime'];
  itemsCount: Scalars['Int'];
  itemsPerOrder: Scalars['Float'];
  kitchenName: Scalars['String'];
  mix: Scalars['Float'];
  orderCount: Scalars['Int'];
  restaurantTotal: Scalars['Float'];
  tips: Scalars['Float'];
};


export type DateTimeFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['DateTime']>;
};

export type DateTimeFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeFilter>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
};

export type DateTimeNullableFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeNullableFilter>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
};

export type DeleteCartItemInput = {
  cartItemId: Scalars['ID'];
};

export type DeliveryEligibility = {
  __typename?: 'DeliveryEligibility';
  deliveryDistanceInMeters: Scalars['Int'];
  deliveryTimeInSeconds: Scalars['Int'];
  facility: Facility;
  id: Scalars['String'];
};

export type DeliveryEligibilityCreateManyAddressInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  deliveryDistanceInMeters: Scalars['Int'];
  deliveryTimeInSeconds: Scalars['Int'];
  facilityId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DeliveryEligibilityCreateManyAddressInputEnvelope = {
  data?: Maybe<Array<DeliveryEligibilityCreateManyAddressInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type DeliveryEligibilityCreateManyFacilityInput = {
  addressId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  deliveryDistanceInMeters: Scalars['Int'];
  deliveryTimeInSeconds: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DeliveryEligibilityCreateManyFacilityInputEnvelope = {
  data?: Maybe<Array<DeliveryEligibilityCreateManyFacilityInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type DeliveryEligibilityCreateNestedManyWithoutAddressInput = {
  connect?: Maybe<Array<DeliveryEligibilityWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DeliveryEligibilityCreateOrConnectWithoutAddressInput>>;
  create?: Maybe<Array<DeliveryEligibilityCreateWithoutAddressInput>>;
  createMany?: Maybe<DeliveryEligibilityCreateManyAddressInputEnvelope>;
};

export type DeliveryEligibilityCreateNestedManyWithoutFacilityInput = {
  connect?: Maybe<Array<DeliveryEligibilityWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DeliveryEligibilityCreateOrConnectWithoutFacilityInput>>;
  create?: Maybe<Array<DeliveryEligibilityCreateWithoutFacilityInput>>;
  createMany?: Maybe<DeliveryEligibilityCreateManyFacilityInputEnvelope>;
};

export type DeliveryEligibilityCreateOrConnectWithoutAddressInput = {
  create: DeliveryEligibilityCreateWithoutAddressInput;
  where: DeliveryEligibilityWhereUniqueInput;
};

export type DeliveryEligibilityCreateOrConnectWithoutFacilityInput = {
  create: DeliveryEligibilityCreateWithoutFacilityInput;
  where: DeliveryEligibilityWhereUniqueInput;
};

export type DeliveryEligibilityCreateWithoutAddressInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  deliveryDistanceInMeters: Scalars['Int'];
  deliveryTimeInSeconds: Scalars['Int'];
  facility: FacilityCreateNestedOneWithoutDeliveryEligibilitiesInput;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DeliveryEligibilityCreateWithoutFacilityInput = {
  address: AddressCreateNestedOneWithoutDeliveryEligibilitiesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  deliveryDistanceInMeters: Scalars['Int'];
  deliveryTimeInSeconds: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DeliveryEligibilityListRelationFilter = {
  every?: Maybe<DeliveryEligibilityWhereInput>;
  none?: Maybe<DeliveryEligibilityWhereInput>;
  some?: Maybe<DeliveryEligibilityWhereInput>;
};

export type DeliveryEligibilityScalarWhereInput = {
  AND?: Maybe<Array<DeliveryEligibilityScalarWhereInput>>;
  NOT?: Maybe<Array<DeliveryEligibilityScalarWhereInput>>;
  OR?: Maybe<Array<DeliveryEligibilityScalarWhereInput>>;
  addressId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  deliveryDistanceInMeters?: Maybe<IntFilter>;
  deliveryTimeInSeconds?: Maybe<IntFilter>;
  facilityId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type DeliveryEligibilityUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deliveryDistanceInMeters?: Maybe<IntFieldUpdateOperationsInput>;
  deliveryTimeInSeconds?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type DeliveryEligibilityUpdateManyWithWhereWithoutAddressInput = {
  data: DeliveryEligibilityUpdateManyMutationInput;
  where: DeliveryEligibilityScalarWhereInput;
};

export type DeliveryEligibilityUpdateManyWithWhereWithoutFacilityInput = {
  data: DeliveryEligibilityUpdateManyMutationInput;
  where: DeliveryEligibilityScalarWhereInput;
};

export type DeliveryEligibilityUpdateManyWithoutAddressInput = {
  connect?: Maybe<Array<DeliveryEligibilityWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DeliveryEligibilityCreateOrConnectWithoutAddressInput>>;
  create?: Maybe<Array<DeliveryEligibilityCreateWithoutAddressInput>>;
  createMany?: Maybe<DeliveryEligibilityCreateManyAddressInputEnvelope>;
  delete?: Maybe<Array<DeliveryEligibilityWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DeliveryEligibilityScalarWhereInput>>;
  disconnect?: Maybe<Array<DeliveryEligibilityWhereUniqueInput>>;
  set?: Maybe<Array<DeliveryEligibilityWhereUniqueInput>>;
  update?: Maybe<Array<DeliveryEligibilityUpdateWithWhereUniqueWithoutAddressInput>>;
  updateMany?: Maybe<Array<DeliveryEligibilityUpdateManyWithWhereWithoutAddressInput>>;
  upsert?: Maybe<Array<DeliveryEligibilityUpsertWithWhereUniqueWithoutAddressInput>>;
};

export type DeliveryEligibilityUpdateManyWithoutFacilityInput = {
  connect?: Maybe<Array<DeliveryEligibilityWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DeliveryEligibilityCreateOrConnectWithoutFacilityInput>>;
  create?: Maybe<Array<DeliveryEligibilityCreateWithoutFacilityInput>>;
  createMany?: Maybe<DeliveryEligibilityCreateManyFacilityInputEnvelope>;
  delete?: Maybe<Array<DeliveryEligibilityWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DeliveryEligibilityScalarWhereInput>>;
  disconnect?: Maybe<Array<DeliveryEligibilityWhereUniqueInput>>;
  set?: Maybe<Array<DeliveryEligibilityWhereUniqueInput>>;
  update?: Maybe<Array<DeliveryEligibilityUpdateWithWhereUniqueWithoutFacilityInput>>;
  updateMany?: Maybe<Array<DeliveryEligibilityUpdateManyWithWhereWithoutFacilityInput>>;
  upsert?: Maybe<Array<DeliveryEligibilityUpsertWithWhereUniqueWithoutFacilityInput>>;
};

export type DeliveryEligibilityUpdateWithWhereUniqueWithoutAddressInput = {
  data: DeliveryEligibilityUpdateWithoutAddressInput;
  where: DeliveryEligibilityWhereUniqueInput;
};

export type DeliveryEligibilityUpdateWithWhereUniqueWithoutFacilityInput = {
  data: DeliveryEligibilityUpdateWithoutFacilityInput;
  where: DeliveryEligibilityWhereUniqueInput;
};

export type DeliveryEligibilityUpdateWithoutAddressInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deliveryDistanceInMeters?: Maybe<IntFieldUpdateOperationsInput>;
  deliveryTimeInSeconds?: Maybe<IntFieldUpdateOperationsInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutDeliveryEligibilitiesInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type DeliveryEligibilityUpdateWithoutFacilityInput = {
  address?: Maybe<AddressUpdateOneRequiredWithoutDeliveryEligibilitiesInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deliveryDistanceInMeters?: Maybe<IntFieldUpdateOperationsInput>;
  deliveryTimeInSeconds?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type DeliveryEligibilityUpsertWithWhereUniqueWithoutAddressInput = {
  create: DeliveryEligibilityCreateWithoutAddressInput;
  update: DeliveryEligibilityUpdateWithoutAddressInput;
  where: DeliveryEligibilityWhereUniqueInput;
};

export type DeliveryEligibilityUpsertWithWhereUniqueWithoutFacilityInput = {
  create: DeliveryEligibilityCreateWithoutFacilityInput;
  update: DeliveryEligibilityUpdateWithoutFacilityInput;
  where: DeliveryEligibilityWhereUniqueInput;
};

export type DeliveryEligibilityWhereInput = {
  AND?: Maybe<Array<DeliveryEligibilityWhereInput>>;
  NOT?: Maybe<Array<DeliveryEligibilityWhereInput>>;
  OR?: Maybe<Array<DeliveryEligibilityWhereInput>>;
  address?: Maybe<AddressWhereInput>;
  addressId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  deliveryDistanceInMeters?: Maybe<IntFilter>;
  deliveryTimeInSeconds?: Maybe<IntFilter>;
  facility?: Maybe<FacilityWhereInput>;
  facilityId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type DeliveryEligibilityWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type DeliveryInfo = {
  __typename?: 'DeliveryInfo';
  deliveryDistanceInMeters?: Maybe<Scalars['Int']>;
  deliveryTimeInSeconds?: Maybe<Scalars['Int']>;
  facilityId: Scalars['ID'];
  isDeliverable: Scalars['Boolean'];
};

export enum DeliveryOption {
  LeaveAtDoor = 'LEAVE_AT_DOOR',
  MeetAtDoor = 'MEET_AT_DOOR'
}

export type Device = {
  __typename?: 'Device';
  errorCount: Scalars['Int'];
  id: Scalars['String'];
  lastError?: Maybe<Scalars['String']>;
  lastErrorAt?: Maybe<Scalars['DateTime']>;
  lastSeenAt: Scalars['DateTime'];
  name: Scalars['String'];
  status: DeviceStatus;
  token: Scalars['String'];
  user: User;
};

export type DeviceCreateManyUserInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  errorCount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  lastError?: Maybe<Scalars['String']>;
  lastErrorAt?: Maybe<Scalars['DateTime']>;
  lastSeenAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  status?: Maybe<DeviceStatus>;
  token: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DeviceCreateManyUserInputEnvelope = {
  data?: Maybe<Array<DeviceCreateManyUserInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type DeviceCreateNestedManyWithoutUserInput = {
  connect?: Maybe<Array<DeviceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DeviceCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<DeviceCreateWithoutUserInput>>;
  createMany?: Maybe<DeviceCreateManyUserInputEnvelope>;
};

export type DeviceCreateOrConnectWithoutUserInput = {
  create: DeviceCreateWithoutUserInput;
  where: DeviceWhereUniqueInput;
};

export type DeviceCreateWithoutUserInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  errorCount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  lastError?: Maybe<Scalars['String']>;
  lastErrorAt?: Maybe<Scalars['DateTime']>;
  lastSeenAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  status?: Maybe<DeviceStatus>;
  token: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DeviceListRelationFilter = {
  every?: Maybe<DeviceWhereInput>;
  none?: Maybe<DeviceWhereInput>;
  some?: Maybe<DeviceWhereInput>;
};

export type DeviceRegisterInput = {
  name: Scalars['String'];
  token: Scalars['String'];
};

export type DeviceScalarWhereInput = {
  AND?: Maybe<Array<DeviceScalarWhereInput>>;
  NOT?: Maybe<Array<DeviceScalarWhereInput>>;
  OR?: Maybe<Array<DeviceScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  errorCount?: Maybe<IntFilter>;
  id?: Maybe<StringFilter>;
  lastError?: Maybe<StringNullableFilter>;
  lastErrorAt?: Maybe<DateTimeNullableFilter>;
  lastSeenAt?: Maybe<DateTimeFilter>;
  name?: Maybe<StringFilter>;
  status?: Maybe<EnumDeviceStatusFilter>;
  token?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  userId?: Maybe<StringFilter>;
};

export enum DeviceStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type DeviceUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  errorCount?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  lastError?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastErrorAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastSeenAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  status?: Maybe<EnumDeviceStatusFieldUpdateOperationsInput>;
  token?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type DeviceUpdateManyWithWhereWithoutUserInput = {
  data: DeviceUpdateManyMutationInput;
  where: DeviceScalarWhereInput;
};

export type DeviceUpdateManyWithoutUserInput = {
  connect?: Maybe<Array<DeviceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DeviceCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<DeviceCreateWithoutUserInput>>;
  createMany?: Maybe<DeviceCreateManyUserInputEnvelope>;
  delete?: Maybe<Array<DeviceWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DeviceScalarWhereInput>>;
  disconnect?: Maybe<Array<DeviceWhereUniqueInput>>;
  set?: Maybe<Array<DeviceWhereUniqueInput>>;
  update?: Maybe<Array<DeviceUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<DeviceUpdateManyWithWhereWithoutUserInput>>;
  upsert?: Maybe<Array<DeviceUpsertWithWhereUniqueWithoutUserInput>>;
};

export type DeviceUpdateWithWhereUniqueWithoutUserInput = {
  data: DeviceUpdateWithoutUserInput;
  where: DeviceWhereUniqueInput;
};

export type DeviceUpdateWithoutUserInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  errorCount?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  lastError?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastErrorAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastSeenAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  status?: Maybe<EnumDeviceStatusFieldUpdateOperationsInput>;
  token?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type DeviceUpsertWithWhereUniqueWithoutUserInput = {
  create: DeviceCreateWithoutUserInput;
  update: DeviceUpdateWithoutUserInput;
  where: DeviceWhereUniqueInput;
};

export type DeviceWhereInput = {
  AND?: Maybe<Array<DeviceWhereInput>>;
  NOT?: Maybe<Array<DeviceWhereInput>>;
  OR?: Maybe<Array<DeviceWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  errorCount?: Maybe<IntFilter>;
  id?: Maybe<StringFilter>;
  lastError?: Maybe<StringNullableFilter>;
  lastErrorAt?: Maybe<DateTimeNullableFilter>;
  lastSeenAt?: Maybe<DateTimeFilter>;
  name?: Maybe<StringFilter>;
  status?: Maybe<EnumDeviceStatusFilter>;
  token?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  user?: Maybe<UserWhereInput>;
  userId?: Maybe<StringFilter>;
};

export type DeviceWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

export enum DishType {
  Alcohol = 'ALCOHOL',
  KidFriendly = 'KID_FRIENDLY',
  Vegan = 'VEGAN',
  Vegetarian = 'VEGETARIAN'
}

export type Driver = {
  __typename?: 'Driver';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type EmailUpdate = {
  __typename?: 'EmailUpdate';
  email: Scalars['String'];
};

export type EmailUpdateInput = {
  newEmail: Scalars['String'];
  oldEmail: Scalars['String'];
};

export type EnumAllergyNullableListFilter = {
  equals?: Maybe<Array<Allergy>>;
  has?: Maybe<Allergy>;
  hasEvery?: Maybe<Array<Allergy>>;
  hasSome?: Maybe<Array<Allergy>>;
  isEmpty?: Maybe<Scalars['Boolean']>;
};

export type EnumBraintreePaymentMethodFieldUpdateOperationsInput = {
  set?: Maybe<BraintreePaymentMethod>;
};

export type EnumBraintreePaymentMethodFilter = {
  equals?: Maybe<BraintreePaymentMethod>;
  in?: Maybe<Array<BraintreePaymentMethod>>;
  not?: Maybe<NestedEnumBraintreePaymentMethodFilter>;
  notIn?: Maybe<Array<BraintreePaymentMethod>>;
};

export type EnumBraintreePaymentSuccessStatusFieldUpdateOperationsInput = {
  set?: Maybe<BraintreePaymentSuccessStatus>;
};

export type EnumBraintreePaymentSuccessStatusFilter = {
  equals?: Maybe<BraintreePaymentSuccessStatus>;
  in?: Maybe<Array<BraintreePaymentSuccessStatus>>;
  not?: Maybe<NestedEnumBraintreePaymentSuccessStatusFilter>;
  notIn?: Maybe<Array<BraintreePaymentSuccessStatus>>;
};

export type EnumBusinessTypeNullableFilter = {
  equals?: Maybe<BusinessType>;
  in?: Maybe<Array<BusinessType>>;
  not?: Maybe<NestedEnumBusinessTypeNullableFilter>;
  notIn?: Maybe<Array<BusinessType>>;
};

export type EnumCustomerRatingTypeFieldUpdateOperationsInput = {
  set?: Maybe<CustomerRatingType>;
};

export type EnumCustomerRatingTypeFilter = {
  equals?: Maybe<CustomerRatingType>;
  in?: Maybe<Array<CustomerRatingType>>;
  not?: Maybe<NestedEnumCustomerRatingTypeFilter>;
  notIn?: Maybe<Array<CustomerRatingType>>;
};

export type EnumDeliveryOptionNullableFilter = {
  equals?: Maybe<DeliveryOption>;
  in?: Maybe<Array<DeliveryOption>>;
  not?: Maybe<NestedEnumDeliveryOptionNullableFilter>;
  notIn?: Maybe<Array<DeliveryOption>>;
};

export type EnumDeviceStatusFieldUpdateOperationsInput = {
  set?: Maybe<DeviceStatus>;
};

export type EnumDeviceStatusFilter = {
  equals?: Maybe<DeviceStatus>;
  in?: Maybe<Array<DeviceStatus>>;
  not?: Maybe<NestedEnumDeviceStatusFilter>;
  notIn?: Maybe<Array<DeviceStatus>>;
};

export type EnumDishTypeNullableListFilter = {
  equals?: Maybe<Array<DishType>>;
  has?: Maybe<DishType>;
  hasEvery?: Maybe<Array<DishType>>;
  hasSome?: Maybe<Array<DishType>>;
  isEmpty?: Maybe<Scalars['Boolean']>;
};

export type EnumInventoryStatusFieldUpdateOperationsInput = {
  set?: Maybe<InventoryStatus>;
};

export type EnumInventoryStatusFilter = {
  equals?: Maybe<InventoryStatus>;
  in?: Maybe<Array<InventoryStatus>>;
  not?: Maybe<NestedEnumInventoryStatusFilter>;
  notIn?: Maybe<Array<InventoryStatus>>;
};

export type EnumKdsTypeFieldUpdateOperationsInput = {
  set?: Maybe<KdsType>;
};

export type EnumKdsTypeFilter = {
  equals?: Maybe<KdsType>;
  in?: Maybe<Array<KdsType>>;
  not?: Maybe<NestedEnumKdsTypeFilter>;
  notIn?: Maybe<Array<KdsType>>;
};

export type EnumKitchenMenuItemStatusFieldUpdateOperationsInput = {
  set?: Maybe<KitchenMenuItemStatus>;
};

export type EnumKitchenMenuItemStatusFilter = {
  equals?: Maybe<KitchenMenuItemStatus>;
  in?: Maybe<Array<KitchenMenuItemStatus>>;
  not?: Maybe<NestedEnumKitchenMenuItemStatusFilter>;
  notIn?: Maybe<Array<KitchenMenuItemStatus>>;
};

export type EnumKitchenStatusFieldUpdateOperationsInput = {
  set?: Maybe<KitchenStatus>;
};

export type EnumKitchenStatusFilter = {
  equals?: Maybe<KitchenStatus>;
  in?: Maybe<Array<KitchenStatus>>;
  not?: Maybe<NestedEnumKitchenStatusFilter>;
  notIn?: Maybe<Array<KitchenStatus>>;
};

export type EnumKitchenTicketDelayReasonNullableFilter = {
  equals?: Maybe<KitchenTicketDelayReason>;
  in?: Maybe<Array<KitchenTicketDelayReason>>;
  not?: Maybe<NestedEnumKitchenTicketDelayReasonNullableFilter>;
  notIn?: Maybe<Array<KitchenTicketDelayReason>>;
};

export type EnumKitchenTicketStatusFieldUpdateOperationsInput = {
  set?: Maybe<KitchenTicketStatus>;
};

export type EnumKitchenTicketStatusFilter = {
  equals?: Maybe<KitchenTicketStatus>;
  in?: Maybe<Array<KitchenTicketStatus>>;
  not?: Maybe<NestedEnumKitchenTicketStatusFilter>;
  notIn?: Maybe<Array<KitchenTicketStatus>>;
};

export type EnumKitchenTypeFieldUpdateOperationsInput = {
  set?: Maybe<KitchenType>;
};

export type EnumKitchenTypeFilter = {
  equals?: Maybe<KitchenType>;
  in?: Maybe<Array<KitchenType>>;
  not?: Maybe<NestedEnumKitchenTypeFilter>;
  notIn?: Maybe<Array<KitchenType>>;
};

export type EnumMenuCategoryTypeFieldUpdateOperationsInput = {
  set?: Maybe<MenuCategoryType>;
};

export type EnumMenuCategoryTypeFilter = {
  equals?: Maybe<MenuCategoryType>;
  in?: Maybe<Array<MenuCategoryType>>;
  not?: Maybe<NestedEnumMenuCategoryTypeFilter>;
  notIn?: Maybe<Array<MenuCategoryType>>;
};

export type EnumMenuItemAvailabilityNullableListFilter = {
  equals?: Maybe<Array<MenuItemAvailability>>;
  has?: Maybe<MenuItemAvailability>;
  hasEvery?: Maybe<Array<MenuItemAvailability>>;
  hasSome?: Maybe<Array<MenuItemAvailability>>;
  isEmpty?: Maybe<Scalars['Boolean']>;
};

export type EnumMenuItemLabelNullableListFilter = {
  equals?: Maybe<Array<MenuItemLabel>>;
  has?: Maybe<MenuItemLabel>;
  hasEvery?: Maybe<Array<MenuItemLabel>>;
  hasSome?: Maybe<Array<MenuItemLabel>>;
  isEmpty?: Maybe<Scalars['Boolean']>;
};

export type EnumMenuItemTypeFieldUpdateOperationsInput = {
  set?: Maybe<MenuItemType>;
};

export type EnumMenuItemTypeFilter = {
  equals?: Maybe<MenuItemType>;
  in?: Maybe<Array<MenuItemType>>;
  not?: Maybe<NestedEnumMenuItemTypeFilter>;
  notIn?: Maybe<Array<MenuItemType>>;
};

export type EnumMenuScheduleTypeFieldUpdateOperationsInput = {
  set?: Maybe<MenuScheduleType>;
};

export type EnumMenuScheduleTypeFilter = {
  equals?: Maybe<MenuScheduleType>;
  in?: Maybe<Array<MenuScheduleType>>;
  not?: Maybe<NestedEnumMenuScheduleTypeFilter>;
  notIn?: Maybe<Array<MenuScheduleType>>;
};

export type EnumOrderBundleStatusFieldUpdateOperationsInput = {
  set?: Maybe<OrderBundleStatus>;
};

export type EnumOrderBundleStatusFilter = {
  equals?: Maybe<OrderBundleStatus>;
  in?: Maybe<Array<OrderBundleStatus>>;
  not?: Maybe<NestedEnumOrderBundleStatusFilter>;
  notIn?: Maybe<Array<OrderBundleStatus>>;
};

export type EnumOrderEventTypeFieldUpdateOperationsInput = {
  set?: Maybe<OrderEventType>;
};

export type EnumOrderEventTypeFilter = {
  equals?: Maybe<OrderEventType>;
  in?: Maybe<Array<OrderEventType>>;
  not?: Maybe<NestedEnumOrderEventTypeFilter>;
  notIn?: Maybe<Array<OrderEventType>>;
};

export type EnumOrderItemTypeFieldUpdateOperationsInput = {
  set?: Maybe<OrderItemType>;
};

export type EnumOrderItemTypeFilter = {
  equals?: Maybe<OrderItemType>;
  in?: Maybe<Array<OrderItemType>>;
  not?: Maybe<NestedEnumOrderItemTypeFilter>;
  notIn?: Maybe<Array<OrderItemType>>;
};

export type EnumOrderMethodFieldUpdateOperationsInput = {
  set?: Maybe<OrderMethod>;
};

export type EnumOrderMethodFilter = {
  equals?: Maybe<OrderMethod>;
  in?: Maybe<Array<OrderMethod>>;
  not?: Maybe<NestedEnumOrderMethodFilter>;
  notIn?: Maybe<Array<OrderMethod>>;
};

export type EnumOrderMethodNullableListFilter = {
  equals?: Maybe<Array<OrderMethod>>;
  has?: Maybe<OrderMethod>;
  hasEvery?: Maybe<Array<OrderMethod>>;
  hasSome?: Maybe<Array<OrderMethod>>;
  isEmpty?: Maybe<Scalars['Boolean']>;
};

export type EnumOrderStatusFieldUpdateOperationsInput = {
  set?: Maybe<OrderStatus>;
};

export type EnumOrderStatusFilter = {
  equals?: Maybe<OrderStatus>;
  in?: Maybe<Array<OrderStatus>>;
  not?: Maybe<NestedEnumOrderStatusFilter>;
  notIn?: Maybe<Array<OrderStatus>>;
};

export type EnumPlatformFieldUpdateOperationsInput = {
  set?: Maybe<Platform>;
};

export type EnumPlatformFilter = {
  equals?: Maybe<Platform>;
  in?: Maybe<Array<Platform>>;
  not?: Maybe<NestedEnumPlatformFilter>;
  notIn?: Maybe<Array<Platform>>;
};

export type EnumPromoAppliesToFieldUpdateOperationsInput = {
  set?: Maybe<PromoAppliesTo>;
};

export type EnumPromoAppliesToFilter = {
  equals?: Maybe<PromoAppliesTo>;
  in?: Maybe<Array<PromoAppliesTo>>;
  not?: Maybe<NestedEnumPromoAppliesToFilter>;
  notIn?: Maybe<Array<PromoAppliesTo>>;
};

export type EnumPromoEligibilityFieldUpdateOperationsInput = {
  set?: Maybe<PromoEligibility>;
};

export type EnumPromoEligibilityFilter = {
  equals?: Maybe<PromoEligibility>;
  in?: Maybe<Array<PromoEligibility>>;
  not?: Maybe<NestedEnumPromoEligibilityFilter>;
  notIn?: Maybe<Array<PromoEligibility>>;
};

export type EnumPromoRequirementFieldUpdateOperationsInput = {
  set?: Maybe<PromoRequirement>;
};

export type EnumPromoRequirementFilter = {
  equals?: Maybe<PromoRequirement>;
  in?: Maybe<Array<PromoRequirement>>;
  not?: Maybe<NestedEnumPromoRequirementFilter>;
  notIn?: Maybe<Array<PromoRequirement>>;
};

export type EnumPromoStatusFieldUpdateOperationsInput = {
  set?: Maybe<PromoStatus>;
};

export type EnumPromoStatusFilter = {
  equals?: Maybe<PromoStatus>;
  in?: Maybe<Array<PromoStatus>>;
  not?: Maybe<NestedEnumPromoStatusFilter>;
  notIn?: Maybe<Array<PromoStatus>>;
};

export type EnumPromoTypeFieldUpdateOperationsInput = {
  set?: Maybe<PromoType>;
};

export type EnumPromoTypeFilter = {
  equals?: Maybe<PromoType>;
  in?: Maybe<Array<PromoType>>;
  not?: Maybe<NestedEnumPromoTypeFilter>;
  notIn?: Maybe<Array<PromoType>>;
};

export type EnumPromoUsageLimitsFieldUpdateOperationsInput = {
  set?: Maybe<PromoUsageLimits>;
};

export type EnumPromoUsageLimitsFilter = {
  equals?: Maybe<PromoUsageLimits>;
  in?: Maybe<Array<PromoUsageLimits>>;
  not?: Maybe<NestedEnumPromoUsageLimitsFilter>;
  notIn?: Maybe<Array<PromoUsageLimits>>;
};

export type EnumRefireReasonNullableFilter = {
  equals?: Maybe<RefireReason>;
  in?: Maybe<Array<RefireReason>>;
  not?: Maybe<NestedEnumRefireReasonNullableFilter>;
  notIn?: Maybe<Array<RefireReason>>;
};

export type EnumUserRoleNullableListFilter = {
  equals?: Maybe<Array<UserRole>>;
  has?: Maybe<UserRole>;
  hasEvery?: Maybe<Array<UserRole>>;
  hasSome?: Maybe<Array<UserRole>>;
  isEmpty?: Maybe<Scalars['Boolean']>;
};

export type EnumWorkplaceTypeFieldUpdateOperationsInput = {
  set?: Maybe<WorkplaceType>;
};

export type EnumWorkplaceTypeFilter = {
  equals?: Maybe<WorkplaceType>;
  in?: Maybe<Array<WorkplaceType>>;
  not?: Maybe<NestedEnumWorkplaceTypeFilter>;
  notIn?: Maybe<Array<WorkplaceType>>;
};

export type Extra = {
  __typename?: 'Extra';
  createdAt: Scalars['DateTime'];
  defaultItemId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  items: Array<ExtraItem>;
  kitchenId?: Maybe<Scalars['String']>;
  kitchenMenuItems: Array<KitchenMenuItem>;
  maximum: Scalars['Int'];
  minimum: Scalars['Int'];
  name: Scalars['String'];
  required: Scalars['Boolean'];
  singleOption: Scalars['Boolean'];
  sortId: Scalars['Int'];
};


export type ExtraItemsArgs = {
  after?: Maybe<ExtraItemWhereUniqueInput>;
  before?: Maybe<ExtraItemWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ExtraKitchenMenuItemsArgs = {
  after?: Maybe<KitchenMenuItemWhereUniqueInput>;
  before?: Maybe<KitchenMenuItemWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type ExtraCreateManyKitchenInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultItemId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  maximum?: Maybe<Scalars['Int']>;
  minimum?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  sortId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ExtraCreateManyKitchenInputEnvelope = {
  data?: Maybe<Array<ExtraCreateManyKitchenInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ExtraCreateNestedManyWithoutKitchenInput = {
  connect?: Maybe<Array<ExtraWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ExtraCreateOrConnectWithoutKitchenInput>>;
  create?: Maybe<Array<ExtraCreateWithoutKitchenInput>>;
  createMany?: Maybe<ExtraCreateManyKitchenInputEnvelope>;
};

export type ExtraCreateNestedManyWithoutKitchenMenuItemsInput = {
  connect?: Maybe<Array<ExtraWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ExtraCreateOrConnectWithoutKitchenMenuItemsInput>>;
  create?: Maybe<Array<ExtraCreateWithoutKitchenMenuItemsInput>>;
};

export type ExtraCreateNestedOneWithoutItemsInput = {
  connect?: Maybe<ExtraWhereUniqueInput>;
  connectOrCreate?: Maybe<ExtraCreateOrConnectWithoutItemsInput>;
  create?: Maybe<ExtraCreateWithoutItemsInput>;
};

export type ExtraCreateOrConnectWithoutItemsInput = {
  create: ExtraCreateWithoutItemsInput;
  where: ExtraWhereUniqueInput;
};

export type ExtraCreateOrConnectWithoutKitchenInput = {
  create: ExtraCreateWithoutKitchenInput;
  where: ExtraWhereUniqueInput;
};

export type ExtraCreateOrConnectWithoutKitchenMenuItemsInput = {
  create: ExtraCreateWithoutKitchenMenuItemsInput;
  where: ExtraWhereUniqueInput;
};

export type ExtraCreateWithoutItemsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultItemId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  kitchen?: Maybe<KitchenCreateNestedOneWithoutExtraInput>;
  kitchenMenuItems?: Maybe<KitchenMenuItemCreateNestedManyWithoutExtrasInput>;
  maximum?: Maybe<Scalars['Int']>;
  minimum?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  sortId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ExtraCreateWithoutKitchenInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultItemId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  items?: Maybe<ExtraItemCreateNestedManyWithoutExtraInput>;
  kitchenMenuItems?: Maybe<KitchenMenuItemCreateNestedManyWithoutExtrasInput>;
  maximum?: Maybe<Scalars['Int']>;
  minimum?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  sortId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ExtraCreateWithoutKitchenMenuItemsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultItemId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  items?: Maybe<ExtraItemCreateNestedManyWithoutExtraInput>;
  kitchen?: Maybe<KitchenCreateNestedOneWithoutExtraInput>;
  maximum?: Maybe<Scalars['Int']>;
  minimum?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  sortId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ExtraItem = {
  __typename?: 'ExtraItem';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  price: Scalars['Float'];
  sortId: Scalars['Int'];
};

export type ExtraItemCreateManyExtraInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  price?: Maybe<Scalars['Float']>;
  sortId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ExtraItemCreateManyExtraInputEnvelope = {
  data?: Maybe<Array<ExtraItemCreateManyExtraInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ExtraItemCreateNestedManyWithoutExtraInput = {
  connect?: Maybe<Array<ExtraItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ExtraItemCreateOrConnectWithoutExtraInput>>;
  create?: Maybe<Array<ExtraItemCreateWithoutExtraInput>>;
  createMany?: Maybe<ExtraItemCreateManyExtraInputEnvelope>;
};

export type ExtraItemCreateNestedOneWithoutCartExtraItemsInput = {
  connect?: Maybe<ExtraItemWhereUniqueInput>;
  connectOrCreate?: Maybe<ExtraItemCreateOrConnectWithoutCartExtraItemsInput>;
  create?: Maybe<ExtraItemCreateWithoutCartExtraItemsInput>;
};

export type ExtraItemCreateNestedOneWithoutOrderItemExtrasInput = {
  connect?: Maybe<ExtraItemWhereUniqueInput>;
  connectOrCreate?: Maybe<ExtraItemCreateOrConnectWithoutOrderItemExtrasInput>;
  create?: Maybe<ExtraItemCreateWithoutOrderItemExtrasInput>;
};

export type ExtraItemCreateOrConnectWithoutCartExtraItemsInput = {
  create: ExtraItemCreateWithoutCartExtraItemsInput;
  where: ExtraItemWhereUniqueInput;
};

export type ExtraItemCreateOrConnectWithoutExtraInput = {
  create: ExtraItemCreateWithoutExtraInput;
  where: ExtraItemWhereUniqueInput;
};

export type ExtraItemCreateOrConnectWithoutOrderItemExtrasInput = {
  create: ExtraItemCreateWithoutOrderItemExtrasInput;
  where: ExtraItemWhereUniqueInput;
};

export type ExtraItemCreateWithoutCartExtraItemsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  extra: ExtraCreateNestedOneWithoutItemsInput;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  orderItemExtras?: Maybe<OrderItemExtraCreateNestedManyWithoutExtraItemInput>;
  price?: Maybe<Scalars['Float']>;
  sortId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ExtraItemCreateWithoutExtraInput = {
  cartExtraItems?: Maybe<CartExtraItemCreateNestedManyWithoutExtraItemInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  orderItemExtras?: Maybe<OrderItemExtraCreateNestedManyWithoutExtraItemInput>;
  price?: Maybe<Scalars['Float']>;
  sortId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ExtraItemCreateWithoutOrderItemExtrasInput = {
  cartExtraItems?: Maybe<CartExtraItemCreateNestedManyWithoutExtraItemInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  extra: ExtraCreateNestedOneWithoutItemsInput;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  price?: Maybe<Scalars['Float']>;
  sortId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ExtraItemInput = {
  extraItemId: Scalars['ID'];
  quantity?: Maybe<Scalars['Int']>;
};

export type ExtraItemListRelationFilter = {
  every?: Maybe<ExtraItemWhereInput>;
  none?: Maybe<ExtraItemWhereInput>;
  some?: Maybe<ExtraItemWhereInput>;
};

export type ExtraItemScalarWhereInput = {
  AND?: Maybe<Array<ExtraItemScalarWhereInput>>;
  NOT?: Maybe<Array<ExtraItemScalarWhereInput>>;
  OR?: Maybe<Array<ExtraItemScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  extraId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  isActive?: Maybe<BoolFilter>;
  name?: Maybe<StringFilter>;
  price?: Maybe<FloatFilter>;
  sortId?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ExtraItemUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isActive?: Maybe<BoolFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type ExtraItemUpdateManyWithWhereWithoutExtraInput = {
  data: ExtraItemUpdateManyMutationInput;
  where: ExtraItemScalarWhereInput;
};

export type ExtraItemUpdateManyWithoutExtraInput = {
  connect?: Maybe<Array<ExtraItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ExtraItemCreateOrConnectWithoutExtraInput>>;
  create?: Maybe<Array<ExtraItemCreateWithoutExtraInput>>;
  createMany?: Maybe<ExtraItemCreateManyExtraInputEnvelope>;
  delete?: Maybe<Array<ExtraItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ExtraItemScalarWhereInput>>;
  disconnect?: Maybe<Array<ExtraItemWhereUniqueInput>>;
  set?: Maybe<Array<ExtraItemWhereUniqueInput>>;
  update?: Maybe<Array<ExtraItemUpdateWithWhereUniqueWithoutExtraInput>>;
  updateMany?: Maybe<Array<ExtraItemUpdateManyWithWhereWithoutExtraInput>>;
  upsert?: Maybe<Array<ExtraItemUpsertWithWhereUniqueWithoutExtraInput>>;
};

export type ExtraItemUpdateOneRequiredWithoutCartExtraItemsInput = {
  connect?: Maybe<ExtraItemWhereUniqueInput>;
  connectOrCreate?: Maybe<ExtraItemCreateOrConnectWithoutCartExtraItemsInput>;
  create?: Maybe<ExtraItemCreateWithoutCartExtraItemsInput>;
  update?: Maybe<ExtraItemUpdateWithoutCartExtraItemsInput>;
  upsert?: Maybe<ExtraItemUpsertWithoutCartExtraItemsInput>;
};

export type ExtraItemUpdateOneWithoutOrderItemExtrasInput = {
  connect?: Maybe<ExtraItemWhereUniqueInput>;
  connectOrCreate?: Maybe<ExtraItemCreateOrConnectWithoutOrderItemExtrasInput>;
  create?: Maybe<ExtraItemCreateWithoutOrderItemExtrasInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ExtraItemUpdateWithoutOrderItemExtrasInput>;
  upsert?: Maybe<ExtraItemUpsertWithoutOrderItemExtrasInput>;
};

export type ExtraItemUpdateWithWhereUniqueWithoutExtraInput = {
  data: ExtraItemUpdateWithoutExtraInput;
  where: ExtraItemWhereUniqueInput;
};

export type ExtraItemUpdateWithoutCartExtraItemsInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  extra?: Maybe<ExtraUpdateOneRequiredWithoutItemsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isActive?: Maybe<BoolFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  orderItemExtras?: Maybe<OrderItemExtraUpdateManyWithoutExtraItemInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type ExtraItemUpdateWithoutExtraInput = {
  cartExtraItems?: Maybe<CartExtraItemUpdateManyWithoutExtraItemInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isActive?: Maybe<BoolFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  orderItemExtras?: Maybe<OrderItemExtraUpdateManyWithoutExtraItemInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type ExtraItemUpdateWithoutOrderItemExtrasInput = {
  cartExtraItems?: Maybe<CartExtraItemUpdateManyWithoutExtraItemInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  extra?: Maybe<ExtraUpdateOneRequiredWithoutItemsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isActive?: Maybe<BoolFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type ExtraItemUpsertInput = {
  id?: Maybe<Scalars['ID']>;
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  price: Scalars['Float'];
  sortId: Scalars['Int'];
};

export type ExtraItemUpsertWithWhereUniqueWithoutExtraInput = {
  create: ExtraItemCreateWithoutExtraInput;
  update: ExtraItemUpdateWithoutExtraInput;
  where: ExtraItemWhereUniqueInput;
};

export type ExtraItemUpsertWithoutCartExtraItemsInput = {
  create: ExtraItemCreateWithoutCartExtraItemsInput;
  update: ExtraItemUpdateWithoutCartExtraItemsInput;
};

export type ExtraItemUpsertWithoutOrderItemExtrasInput = {
  create: ExtraItemCreateWithoutOrderItemExtrasInput;
  update: ExtraItemUpdateWithoutOrderItemExtrasInput;
};

export type ExtraItemWhereInput = {
  AND?: Maybe<Array<ExtraItemWhereInput>>;
  NOT?: Maybe<Array<ExtraItemWhereInput>>;
  OR?: Maybe<Array<ExtraItemWhereInput>>;
  cartExtraItems?: Maybe<CartExtraItemListRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  extra?: Maybe<ExtraWhereInput>;
  extraId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  isActive?: Maybe<BoolFilter>;
  name?: Maybe<StringFilter>;
  orderItemExtras?: Maybe<OrderItemExtraListRelationFilter>;
  price?: Maybe<FloatFilter>;
  sortId?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ExtraItemWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ExtraListRelationFilter = {
  every?: Maybe<ExtraWhereInput>;
  none?: Maybe<ExtraWhereInput>;
  some?: Maybe<ExtraWhereInput>;
};

export type ExtraScalarWhereInput = {
  AND?: Maybe<Array<ExtraScalarWhereInput>>;
  NOT?: Maybe<Array<ExtraScalarWhereInput>>;
  OR?: Maybe<Array<ExtraScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  defaultItemId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  kitchenId?: Maybe<StringNullableFilter>;
  maximum?: Maybe<IntFilter>;
  minimum?: Maybe<IntFilter>;
  name?: Maybe<StringFilter>;
  sortId?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ExtraUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  defaultItemId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  maximum?: Maybe<IntFieldUpdateOperationsInput>;
  minimum?: Maybe<IntFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type ExtraUpdateManyWithWhereWithoutKitchenInput = {
  data: ExtraUpdateManyMutationInput;
  where: ExtraScalarWhereInput;
};

export type ExtraUpdateManyWithWhereWithoutKitchenMenuItemsInput = {
  data: ExtraUpdateManyMutationInput;
  where: ExtraScalarWhereInput;
};

export type ExtraUpdateManyWithoutKitchenInput = {
  connect?: Maybe<Array<ExtraWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ExtraCreateOrConnectWithoutKitchenInput>>;
  create?: Maybe<Array<ExtraCreateWithoutKitchenInput>>;
  createMany?: Maybe<ExtraCreateManyKitchenInputEnvelope>;
  delete?: Maybe<Array<ExtraWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ExtraScalarWhereInput>>;
  disconnect?: Maybe<Array<ExtraWhereUniqueInput>>;
  set?: Maybe<Array<ExtraWhereUniqueInput>>;
  update?: Maybe<Array<ExtraUpdateWithWhereUniqueWithoutKitchenInput>>;
  updateMany?: Maybe<Array<ExtraUpdateManyWithWhereWithoutKitchenInput>>;
  upsert?: Maybe<Array<ExtraUpsertWithWhereUniqueWithoutKitchenInput>>;
};

export type ExtraUpdateManyWithoutKitchenMenuItemsInput = {
  connect?: Maybe<Array<ExtraWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ExtraCreateOrConnectWithoutKitchenMenuItemsInput>>;
  create?: Maybe<Array<ExtraCreateWithoutKitchenMenuItemsInput>>;
  delete?: Maybe<Array<ExtraWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ExtraScalarWhereInput>>;
  disconnect?: Maybe<Array<ExtraWhereUniqueInput>>;
  set?: Maybe<Array<ExtraWhereUniqueInput>>;
  update?: Maybe<Array<ExtraUpdateWithWhereUniqueWithoutKitchenMenuItemsInput>>;
  updateMany?: Maybe<Array<ExtraUpdateManyWithWhereWithoutKitchenMenuItemsInput>>;
  upsert?: Maybe<Array<ExtraUpsertWithWhereUniqueWithoutKitchenMenuItemsInput>>;
};

export type ExtraUpdateOneRequiredWithoutItemsInput = {
  connect?: Maybe<ExtraWhereUniqueInput>;
  connectOrCreate?: Maybe<ExtraCreateOrConnectWithoutItemsInput>;
  create?: Maybe<ExtraCreateWithoutItemsInput>;
  update?: Maybe<ExtraUpdateWithoutItemsInput>;
  upsert?: Maybe<ExtraUpsertWithoutItemsInput>;
};

export type ExtraUpdateWithWhereUniqueWithoutKitchenInput = {
  data: ExtraUpdateWithoutKitchenInput;
  where: ExtraWhereUniqueInput;
};

export type ExtraUpdateWithWhereUniqueWithoutKitchenMenuItemsInput = {
  data: ExtraUpdateWithoutKitchenMenuItemsInput;
  where: ExtraWhereUniqueInput;
};

export type ExtraUpdateWithoutItemsInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  defaultItemId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  kitchen?: Maybe<KitchenUpdateOneWithoutExtraInput>;
  kitchenMenuItems?: Maybe<KitchenMenuItemUpdateManyWithoutExtrasInput>;
  maximum?: Maybe<IntFieldUpdateOperationsInput>;
  minimum?: Maybe<IntFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type ExtraUpdateWithoutKitchenInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  defaultItemId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  items?: Maybe<ExtraItemUpdateManyWithoutExtraInput>;
  kitchenMenuItems?: Maybe<KitchenMenuItemUpdateManyWithoutExtrasInput>;
  maximum?: Maybe<IntFieldUpdateOperationsInput>;
  minimum?: Maybe<IntFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type ExtraUpdateWithoutKitchenMenuItemsInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  defaultItemId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  items?: Maybe<ExtraItemUpdateManyWithoutExtraInput>;
  kitchen?: Maybe<KitchenUpdateOneWithoutExtraInput>;
  maximum?: Maybe<IntFieldUpdateOperationsInput>;
  minimum?: Maybe<IntFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type ExtraUpsertInput = {
  defaultItemId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  items: Array<ExtraItemUpsertInput>;
  kitchenId: Scalars['String'];
  maximum: Scalars['Int'];
  minimum: Scalars['Int'];
  name: Scalars['String'];
  sortId: Scalars['Int'];
};

export type ExtraUpsertWithWhereUniqueWithoutKitchenInput = {
  create: ExtraCreateWithoutKitchenInput;
  update: ExtraUpdateWithoutKitchenInput;
  where: ExtraWhereUniqueInput;
};

export type ExtraUpsertWithWhereUniqueWithoutKitchenMenuItemsInput = {
  create: ExtraCreateWithoutKitchenMenuItemsInput;
  update: ExtraUpdateWithoutKitchenMenuItemsInput;
  where: ExtraWhereUniqueInput;
};

export type ExtraUpsertWithoutItemsInput = {
  create: ExtraCreateWithoutItemsInput;
  update: ExtraUpdateWithoutItemsInput;
};

export type ExtraWhereInput = {
  AND?: Maybe<Array<ExtraWhereInput>>;
  NOT?: Maybe<Array<ExtraWhereInput>>;
  OR?: Maybe<Array<ExtraWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  defaultItemId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  items?: Maybe<ExtraItemListRelationFilter>;
  kitchen?: Maybe<KitchenWhereInput>;
  kitchenId?: Maybe<StringNullableFilter>;
  kitchenMenuItems?: Maybe<KitchenMenuItemListRelationFilter>;
  maximum?: Maybe<IntFilter>;
  minimum?: Maybe<IntFilter>;
  name?: Maybe<StringFilter>;
  sortId?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ExtraWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type Facility = {
  __typename?: 'Facility';
  createdAt: Scalars['DateTime'];
  deliveryLimitInSeconds: Scalars['Int'];
  enableCatering: Scalars['Boolean'];
  enableKiosk: Scalars['Boolean'];
  formattedAddress: Scalars['String'];
  id: Scalars['String'];
  kdsType: KdsType;
  kioskLogoUrl?: Maybe<Scalars['String']>;
  /** Number of active facility kitchens  */
  kitchenCount: Scalars['Int'];
  /** List of kitchens logos for consumer app, only logos of ACTIVE kitchens are returned */
  kitchenLogos: Array<Scalars['String']>;
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  logoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  operationHours: Array<Scalars['Json']>;
  orderMethods: Array<OrderMethod>;
  placeId: Scalars['String'];
  timeZone: Scalars['String'];
};

export type FacilityCreateNestedManyWithoutPowerBiReportsInput = {
  connect?: Maybe<Array<FacilityWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FacilityCreateOrConnectWithoutPowerBiReportsInput>>;
  create?: Maybe<Array<FacilityCreateWithoutPowerBiReportsInput>>;
};

export type FacilityCreateNestedManyWithoutUsersInput = {
  connect?: Maybe<Array<FacilityWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FacilityCreateOrConnectWithoutUsersInput>>;
  create?: Maybe<Array<FacilityCreateWithoutUsersInput>>;
};

export type FacilityCreateNestedOneWithoutDeliveryEligibilitiesInput = {
  connect?: Maybe<FacilityWhereUniqueInput>;
  connectOrCreate?: Maybe<FacilityCreateOrConnectWithoutDeliveryEligibilitiesInput>;
  create?: Maybe<FacilityCreateWithoutDeliveryEligibilitiesInput>;
};

export type FacilityCreateNestedOneWithoutKitchensInput = {
  connect?: Maybe<FacilityWhereUniqueInput>;
  connectOrCreate?: Maybe<FacilityCreateOrConnectWithoutKitchensInput>;
  create?: Maybe<FacilityCreateWithoutKitchensInput>;
};

export type FacilityCreateNestedOneWithoutMenuCategoriesInput = {
  connect?: Maybe<FacilityWhereUniqueInput>;
  connectOrCreate?: Maybe<FacilityCreateOrConnectWithoutMenuCategoriesInput>;
  create?: Maybe<FacilityCreateWithoutMenuCategoriesInput>;
};

export type FacilityCreateNestedOneWithoutMenuSchedulesInput = {
  connect?: Maybe<FacilityWhereUniqueInput>;
  connectOrCreate?: Maybe<FacilityCreateOrConnectWithoutMenuSchedulesInput>;
  create?: Maybe<FacilityCreateWithoutMenuSchedulesInput>;
};

export type FacilityCreateNestedOneWithoutOrdersInput = {
  connect?: Maybe<FacilityWhereUniqueInput>;
  connectOrCreate?: Maybe<FacilityCreateOrConnectWithoutOrdersInput>;
  create?: Maybe<FacilityCreateWithoutOrdersInput>;
};

export type FacilityCreateNestedOneWithoutPromoFacilityRulesInput = {
  connect?: Maybe<FacilityWhereUniqueInput>;
  connectOrCreate?: Maybe<FacilityCreateOrConnectWithoutPromoFacilityRulesInput>;
  create?: Maybe<FacilityCreateWithoutPromoFacilityRulesInput>;
};

export type FacilityCreateNestedOneWithoutStationsInput = {
  connect?: Maybe<FacilityWhereUniqueInput>;
  connectOrCreate?: Maybe<FacilityCreateOrConnectWithoutStationsInput>;
  create?: Maybe<FacilityCreateWithoutStationsInput>;
};

export type FacilityCreateNestedOneWithoutWorkplacesInput = {
  connect?: Maybe<FacilityWhereUniqueInput>;
  connectOrCreate?: Maybe<FacilityCreateOrConnectWithoutWorkplacesInput>;
  create?: Maybe<FacilityCreateWithoutWorkplacesInput>;
};

export type FacilityCreateOrConnectWithoutDeliveryEligibilitiesInput = {
  create: FacilityCreateWithoutDeliveryEligibilitiesInput;
  where: FacilityWhereUniqueInput;
};

export type FacilityCreateOrConnectWithoutKitchensInput = {
  create: FacilityCreateWithoutKitchensInput;
  where: FacilityWhereUniqueInput;
};

export type FacilityCreateOrConnectWithoutMenuCategoriesInput = {
  create: FacilityCreateWithoutMenuCategoriesInput;
  where: FacilityWhereUniqueInput;
};

export type FacilityCreateOrConnectWithoutMenuSchedulesInput = {
  create: FacilityCreateWithoutMenuSchedulesInput;
  where: FacilityWhereUniqueInput;
};

export type FacilityCreateOrConnectWithoutOrdersInput = {
  create: FacilityCreateWithoutOrdersInput;
  where: FacilityWhereUniqueInput;
};

export type FacilityCreateOrConnectWithoutPowerBiReportsInput = {
  create: FacilityCreateWithoutPowerBiReportsInput;
  where: FacilityWhereUniqueInput;
};

export type FacilityCreateOrConnectWithoutPromoBannersInput = {
  create: FacilityCreateWithoutPromoBannersInput;
  where: FacilityWhereUniqueInput;
};

export type FacilityCreateOrConnectWithoutPromoFacilityRulesInput = {
  create: FacilityCreateWithoutPromoFacilityRulesInput;
  where: FacilityWhereUniqueInput;
};

export type FacilityCreateOrConnectWithoutStationsInput = {
  create: FacilityCreateWithoutStationsInput;
  where: FacilityWhereUniqueInput;
};

export type FacilityCreateOrConnectWithoutUsersInput = {
  create: FacilityCreateWithoutUsersInput;
  where: FacilityWhereUniqueInput;
};

export type FacilityCreateOrConnectWithoutWorkplacesInput = {
  create: FacilityCreateWithoutWorkplacesInput;
  where: FacilityWhereUniqueInput;
};

export type FacilityCreateWithoutDeliveryEligibilitiesInput = {
  cravePlatforms?: Maybe<FacilityCreatecravePlatformsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deliveryEligibleAreas: Scalars['Json'];
  deliveryLimitInSeconds: Scalars['Int'];
  enableCatering?: Maybe<Scalars['Boolean']>;
  enableKiosk?: Maybe<Scalars['Boolean']>;
  formattedAddress: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  isDeliveryFeeTaxable?: Maybe<Scalars['Boolean']>;
  isServiceFeeTaxable?: Maybe<Scalars['Boolean']>;
  kdsType?: Maybe<KdsType>;
  kioskLogoUrl?: Maybe<Scalars['String']>;
  kitchens?: Maybe<KitchenCreateNestedManyWithoutFacilityInput>;
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  logoUrl?: Maybe<Scalars['String']>;
  menuCategories?: Maybe<MenuCategoryCreateNestedManyWithoutFacilityInput>;
  menuSchedules?: Maybe<MenuScheduleCreateNestedManyWithoutFacilityInput>;
  methodOverrides?: Maybe<OrderMethodOverrideCreateNestedManyWithoutFacilityInput>;
  name: Scalars['String'];
  operationHours?: Maybe<FacilityCreateoperationHoursInput>;
  orderMethods?: Maybe<FacilityCreateorderMethodsInput>;
  orders?: Maybe<OrderCreateNestedManyWithoutFacilityInput>;
  placeId: Scalars['String'];
  powerBiReports?: Maybe<PowerBiReportCreateNestedManyWithoutAvailableToFacilitiesInput>;
  promoBanners?: Maybe<PromoBannerCreateNestedManyWithoutFacilityInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleCreateNestedManyWithoutFacilityInput>;
  stations?: Maybe<StationCreateNestedManyWithoutFacilityInput>;
  taxRate?: Maybe<Scalars['Float']>;
  timeZone?: Maybe<Scalars['String']>;
  tookanTeamId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserCreateNestedManyWithoutFacilitiesInput>;
  workplaces?: Maybe<WorkplaceCreateNestedManyWithoutFacilityInput>;
};

export type FacilityCreateWithoutKitchensInput = {
  cravePlatforms?: Maybe<FacilityCreatecravePlatformsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deliveryEligibilities?: Maybe<DeliveryEligibilityCreateNestedManyWithoutFacilityInput>;
  deliveryEligibleAreas: Scalars['Json'];
  deliveryLimitInSeconds: Scalars['Int'];
  enableCatering?: Maybe<Scalars['Boolean']>;
  enableKiosk?: Maybe<Scalars['Boolean']>;
  formattedAddress: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  isDeliveryFeeTaxable?: Maybe<Scalars['Boolean']>;
  isServiceFeeTaxable?: Maybe<Scalars['Boolean']>;
  kdsType?: Maybe<KdsType>;
  kioskLogoUrl?: Maybe<Scalars['String']>;
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  logoUrl?: Maybe<Scalars['String']>;
  menuCategories?: Maybe<MenuCategoryCreateNestedManyWithoutFacilityInput>;
  menuSchedules?: Maybe<MenuScheduleCreateNestedManyWithoutFacilityInput>;
  methodOverrides?: Maybe<OrderMethodOverrideCreateNestedManyWithoutFacilityInput>;
  name: Scalars['String'];
  operationHours?: Maybe<FacilityCreateoperationHoursInput>;
  orderMethods?: Maybe<FacilityCreateorderMethodsInput>;
  orders?: Maybe<OrderCreateNestedManyWithoutFacilityInput>;
  placeId: Scalars['String'];
  powerBiReports?: Maybe<PowerBiReportCreateNestedManyWithoutAvailableToFacilitiesInput>;
  promoBanners?: Maybe<PromoBannerCreateNestedManyWithoutFacilityInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleCreateNestedManyWithoutFacilityInput>;
  stations?: Maybe<StationCreateNestedManyWithoutFacilityInput>;
  taxRate?: Maybe<Scalars['Float']>;
  timeZone?: Maybe<Scalars['String']>;
  tookanTeamId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserCreateNestedManyWithoutFacilitiesInput>;
  workplaces?: Maybe<WorkplaceCreateNestedManyWithoutFacilityInput>;
};

export type FacilityCreateWithoutMenuCategoriesInput = {
  cravePlatforms?: Maybe<FacilityCreatecravePlatformsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deliveryEligibilities?: Maybe<DeliveryEligibilityCreateNestedManyWithoutFacilityInput>;
  deliveryEligibleAreas: Scalars['Json'];
  deliveryLimitInSeconds: Scalars['Int'];
  enableCatering?: Maybe<Scalars['Boolean']>;
  enableKiosk?: Maybe<Scalars['Boolean']>;
  formattedAddress: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  isDeliveryFeeTaxable?: Maybe<Scalars['Boolean']>;
  isServiceFeeTaxable?: Maybe<Scalars['Boolean']>;
  kdsType?: Maybe<KdsType>;
  kioskLogoUrl?: Maybe<Scalars['String']>;
  kitchens?: Maybe<KitchenCreateNestedManyWithoutFacilityInput>;
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  logoUrl?: Maybe<Scalars['String']>;
  menuSchedules?: Maybe<MenuScheduleCreateNestedManyWithoutFacilityInput>;
  methodOverrides?: Maybe<OrderMethodOverrideCreateNestedManyWithoutFacilityInput>;
  name: Scalars['String'];
  operationHours?: Maybe<FacilityCreateoperationHoursInput>;
  orderMethods?: Maybe<FacilityCreateorderMethodsInput>;
  orders?: Maybe<OrderCreateNestedManyWithoutFacilityInput>;
  placeId: Scalars['String'];
  powerBiReports?: Maybe<PowerBiReportCreateNestedManyWithoutAvailableToFacilitiesInput>;
  promoBanners?: Maybe<PromoBannerCreateNestedManyWithoutFacilityInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleCreateNestedManyWithoutFacilityInput>;
  stations?: Maybe<StationCreateNestedManyWithoutFacilityInput>;
  taxRate?: Maybe<Scalars['Float']>;
  timeZone?: Maybe<Scalars['String']>;
  tookanTeamId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserCreateNestedManyWithoutFacilitiesInput>;
  workplaces?: Maybe<WorkplaceCreateNestedManyWithoutFacilityInput>;
};

export type FacilityCreateWithoutMenuSchedulesInput = {
  cravePlatforms?: Maybe<FacilityCreatecravePlatformsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deliveryEligibilities?: Maybe<DeliveryEligibilityCreateNestedManyWithoutFacilityInput>;
  deliveryEligibleAreas: Scalars['Json'];
  deliveryLimitInSeconds: Scalars['Int'];
  enableCatering?: Maybe<Scalars['Boolean']>;
  enableKiosk?: Maybe<Scalars['Boolean']>;
  formattedAddress: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  isDeliveryFeeTaxable?: Maybe<Scalars['Boolean']>;
  isServiceFeeTaxable?: Maybe<Scalars['Boolean']>;
  kdsType?: Maybe<KdsType>;
  kioskLogoUrl?: Maybe<Scalars['String']>;
  kitchens?: Maybe<KitchenCreateNestedManyWithoutFacilityInput>;
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  logoUrl?: Maybe<Scalars['String']>;
  menuCategories?: Maybe<MenuCategoryCreateNestedManyWithoutFacilityInput>;
  methodOverrides?: Maybe<OrderMethodOverrideCreateNestedManyWithoutFacilityInput>;
  name: Scalars['String'];
  operationHours?: Maybe<FacilityCreateoperationHoursInput>;
  orderMethods?: Maybe<FacilityCreateorderMethodsInput>;
  orders?: Maybe<OrderCreateNestedManyWithoutFacilityInput>;
  placeId: Scalars['String'];
  powerBiReports?: Maybe<PowerBiReportCreateNestedManyWithoutAvailableToFacilitiesInput>;
  promoBanners?: Maybe<PromoBannerCreateNestedManyWithoutFacilityInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleCreateNestedManyWithoutFacilityInput>;
  stations?: Maybe<StationCreateNestedManyWithoutFacilityInput>;
  taxRate?: Maybe<Scalars['Float']>;
  timeZone?: Maybe<Scalars['String']>;
  tookanTeamId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserCreateNestedManyWithoutFacilitiesInput>;
  workplaces?: Maybe<WorkplaceCreateNestedManyWithoutFacilityInput>;
};

export type FacilityCreateWithoutOrdersInput = {
  cravePlatforms?: Maybe<FacilityCreatecravePlatformsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deliveryEligibilities?: Maybe<DeliveryEligibilityCreateNestedManyWithoutFacilityInput>;
  deliveryEligibleAreas: Scalars['Json'];
  deliveryLimitInSeconds: Scalars['Int'];
  enableCatering?: Maybe<Scalars['Boolean']>;
  enableKiosk?: Maybe<Scalars['Boolean']>;
  formattedAddress: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  isDeliveryFeeTaxable?: Maybe<Scalars['Boolean']>;
  isServiceFeeTaxable?: Maybe<Scalars['Boolean']>;
  kdsType?: Maybe<KdsType>;
  kioskLogoUrl?: Maybe<Scalars['String']>;
  kitchens?: Maybe<KitchenCreateNestedManyWithoutFacilityInput>;
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  logoUrl?: Maybe<Scalars['String']>;
  menuCategories?: Maybe<MenuCategoryCreateNestedManyWithoutFacilityInput>;
  menuSchedules?: Maybe<MenuScheduleCreateNestedManyWithoutFacilityInput>;
  methodOverrides?: Maybe<OrderMethodOverrideCreateNestedManyWithoutFacilityInput>;
  name: Scalars['String'];
  operationHours?: Maybe<FacilityCreateoperationHoursInput>;
  orderMethods?: Maybe<FacilityCreateorderMethodsInput>;
  placeId: Scalars['String'];
  powerBiReports?: Maybe<PowerBiReportCreateNestedManyWithoutAvailableToFacilitiesInput>;
  promoBanners?: Maybe<PromoBannerCreateNestedManyWithoutFacilityInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleCreateNestedManyWithoutFacilityInput>;
  stations?: Maybe<StationCreateNestedManyWithoutFacilityInput>;
  taxRate?: Maybe<Scalars['Float']>;
  timeZone?: Maybe<Scalars['String']>;
  tookanTeamId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserCreateNestedManyWithoutFacilitiesInput>;
  workplaces?: Maybe<WorkplaceCreateNestedManyWithoutFacilityInput>;
};

export type FacilityCreateWithoutPowerBiReportsInput = {
  cravePlatforms?: Maybe<FacilityCreatecravePlatformsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deliveryEligibilities?: Maybe<DeliveryEligibilityCreateNestedManyWithoutFacilityInput>;
  deliveryEligibleAreas: Scalars['Json'];
  deliveryLimitInSeconds: Scalars['Int'];
  enableCatering?: Maybe<Scalars['Boolean']>;
  enableKiosk?: Maybe<Scalars['Boolean']>;
  formattedAddress: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  isDeliveryFeeTaxable?: Maybe<Scalars['Boolean']>;
  isServiceFeeTaxable?: Maybe<Scalars['Boolean']>;
  kdsType?: Maybe<KdsType>;
  kioskLogoUrl?: Maybe<Scalars['String']>;
  kitchens?: Maybe<KitchenCreateNestedManyWithoutFacilityInput>;
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  logoUrl?: Maybe<Scalars['String']>;
  menuCategories?: Maybe<MenuCategoryCreateNestedManyWithoutFacilityInput>;
  menuSchedules?: Maybe<MenuScheduleCreateNestedManyWithoutFacilityInput>;
  methodOverrides?: Maybe<OrderMethodOverrideCreateNestedManyWithoutFacilityInput>;
  name: Scalars['String'];
  operationHours?: Maybe<FacilityCreateoperationHoursInput>;
  orderMethods?: Maybe<FacilityCreateorderMethodsInput>;
  orders?: Maybe<OrderCreateNestedManyWithoutFacilityInput>;
  placeId: Scalars['String'];
  promoBanners?: Maybe<PromoBannerCreateNestedManyWithoutFacilityInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleCreateNestedManyWithoutFacilityInput>;
  stations?: Maybe<StationCreateNestedManyWithoutFacilityInput>;
  taxRate?: Maybe<Scalars['Float']>;
  timeZone?: Maybe<Scalars['String']>;
  tookanTeamId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserCreateNestedManyWithoutFacilitiesInput>;
  workplaces?: Maybe<WorkplaceCreateNestedManyWithoutFacilityInput>;
};

export type FacilityCreateWithoutPromoBannersInput = {
  cravePlatforms?: Maybe<FacilityCreatecravePlatformsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deliveryEligibilities?: Maybe<DeliveryEligibilityCreateNestedManyWithoutFacilityInput>;
  deliveryEligibleAreas: Scalars['Json'];
  deliveryLimitInSeconds: Scalars['Int'];
  enableCatering?: Maybe<Scalars['Boolean']>;
  enableKiosk?: Maybe<Scalars['Boolean']>;
  formattedAddress: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  isDeliveryFeeTaxable?: Maybe<Scalars['Boolean']>;
  isServiceFeeTaxable?: Maybe<Scalars['Boolean']>;
  kdsType?: Maybe<KdsType>;
  kioskLogoUrl?: Maybe<Scalars['String']>;
  kitchens?: Maybe<KitchenCreateNestedManyWithoutFacilityInput>;
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  logoUrl?: Maybe<Scalars['String']>;
  menuCategories?: Maybe<MenuCategoryCreateNestedManyWithoutFacilityInput>;
  menuSchedules?: Maybe<MenuScheduleCreateNestedManyWithoutFacilityInput>;
  methodOverrides?: Maybe<OrderMethodOverrideCreateNestedManyWithoutFacilityInput>;
  name: Scalars['String'];
  operationHours?: Maybe<FacilityCreateoperationHoursInput>;
  orderMethods?: Maybe<FacilityCreateorderMethodsInput>;
  orders?: Maybe<OrderCreateNestedManyWithoutFacilityInput>;
  placeId: Scalars['String'];
  powerBiReports?: Maybe<PowerBiReportCreateNestedManyWithoutAvailableToFacilitiesInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleCreateNestedManyWithoutFacilityInput>;
  stations?: Maybe<StationCreateNestedManyWithoutFacilityInput>;
  taxRate?: Maybe<Scalars['Float']>;
  timeZone?: Maybe<Scalars['String']>;
  tookanTeamId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserCreateNestedManyWithoutFacilitiesInput>;
  workplaces?: Maybe<WorkplaceCreateNestedManyWithoutFacilityInput>;
};

export type FacilityCreateWithoutPromoFacilityRulesInput = {
  cravePlatforms?: Maybe<FacilityCreatecravePlatformsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deliveryEligibilities?: Maybe<DeliveryEligibilityCreateNestedManyWithoutFacilityInput>;
  deliveryEligibleAreas: Scalars['Json'];
  deliveryLimitInSeconds: Scalars['Int'];
  enableCatering?: Maybe<Scalars['Boolean']>;
  enableKiosk?: Maybe<Scalars['Boolean']>;
  formattedAddress: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  isDeliveryFeeTaxable?: Maybe<Scalars['Boolean']>;
  isServiceFeeTaxable?: Maybe<Scalars['Boolean']>;
  kdsType?: Maybe<KdsType>;
  kioskLogoUrl?: Maybe<Scalars['String']>;
  kitchens?: Maybe<KitchenCreateNestedManyWithoutFacilityInput>;
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  logoUrl?: Maybe<Scalars['String']>;
  menuCategories?: Maybe<MenuCategoryCreateNestedManyWithoutFacilityInput>;
  menuSchedules?: Maybe<MenuScheduleCreateNestedManyWithoutFacilityInput>;
  methodOverrides?: Maybe<OrderMethodOverrideCreateNestedManyWithoutFacilityInput>;
  name: Scalars['String'];
  operationHours?: Maybe<FacilityCreateoperationHoursInput>;
  orderMethods?: Maybe<FacilityCreateorderMethodsInput>;
  orders?: Maybe<OrderCreateNestedManyWithoutFacilityInput>;
  placeId: Scalars['String'];
  powerBiReports?: Maybe<PowerBiReportCreateNestedManyWithoutAvailableToFacilitiesInput>;
  promoBanners?: Maybe<PromoBannerCreateNestedManyWithoutFacilityInput>;
  stations?: Maybe<StationCreateNestedManyWithoutFacilityInput>;
  taxRate?: Maybe<Scalars['Float']>;
  timeZone?: Maybe<Scalars['String']>;
  tookanTeamId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserCreateNestedManyWithoutFacilitiesInput>;
  workplaces?: Maybe<WorkplaceCreateNestedManyWithoutFacilityInput>;
};

export type FacilityCreateWithoutStationsInput = {
  cravePlatforms?: Maybe<FacilityCreatecravePlatformsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deliveryEligibilities?: Maybe<DeliveryEligibilityCreateNestedManyWithoutFacilityInput>;
  deliveryEligibleAreas: Scalars['Json'];
  deliveryLimitInSeconds: Scalars['Int'];
  enableCatering?: Maybe<Scalars['Boolean']>;
  enableKiosk?: Maybe<Scalars['Boolean']>;
  formattedAddress: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  isDeliveryFeeTaxable?: Maybe<Scalars['Boolean']>;
  isServiceFeeTaxable?: Maybe<Scalars['Boolean']>;
  kdsType?: Maybe<KdsType>;
  kioskLogoUrl?: Maybe<Scalars['String']>;
  kitchens?: Maybe<KitchenCreateNestedManyWithoutFacilityInput>;
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  logoUrl?: Maybe<Scalars['String']>;
  menuCategories?: Maybe<MenuCategoryCreateNestedManyWithoutFacilityInput>;
  menuSchedules?: Maybe<MenuScheduleCreateNestedManyWithoutFacilityInput>;
  methodOverrides?: Maybe<OrderMethodOverrideCreateNestedManyWithoutFacilityInput>;
  name: Scalars['String'];
  operationHours?: Maybe<FacilityCreateoperationHoursInput>;
  orderMethods?: Maybe<FacilityCreateorderMethodsInput>;
  orders?: Maybe<OrderCreateNestedManyWithoutFacilityInput>;
  placeId: Scalars['String'];
  powerBiReports?: Maybe<PowerBiReportCreateNestedManyWithoutAvailableToFacilitiesInput>;
  promoBanners?: Maybe<PromoBannerCreateNestedManyWithoutFacilityInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleCreateNestedManyWithoutFacilityInput>;
  taxRate?: Maybe<Scalars['Float']>;
  timeZone?: Maybe<Scalars['String']>;
  tookanTeamId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserCreateNestedManyWithoutFacilitiesInput>;
  workplaces?: Maybe<WorkplaceCreateNestedManyWithoutFacilityInput>;
};

export type FacilityCreateWithoutUsersInput = {
  cravePlatforms?: Maybe<FacilityCreatecravePlatformsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deliveryEligibilities?: Maybe<DeliveryEligibilityCreateNestedManyWithoutFacilityInput>;
  deliveryEligibleAreas: Scalars['Json'];
  deliveryLimitInSeconds: Scalars['Int'];
  enableCatering?: Maybe<Scalars['Boolean']>;
  enableKiosk?: Maybe<Scalars['Boolean']>;
  formattedAddress: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  isDeliveryFeeTaxable?: Maybe<Scalars['Boolean']>;
  isServiceFeeTaxable?: Maybe<Scalars['Boolean']>;
  kdsType?: Maybe<KdsType>;
  kioskLogoUrl?: Maybe<Scalars['String']>;
  kitchens?: Maybe<KitchenCreateNestedManyWithoutFacilityInput>;
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  logoUrl?: Maybe<Scalars['String']>;
  menuCategories?: Maybe<MenuCategoryCreateNestedManyWithoutFacilityInput>;
  menuSchedules?: Maybe<MenuScheduleCreateNestedManyWithoutFacilityInput>;
  methodOverrides?: Maybe<OrderMethodOverrideCreateNestedManyWithoutFacilityInput>;
  name: Scalars['String'];
  operationHours?: Maybe<FacilityCreateoperationHoursInput>;
  orderMethods?: Maybe<FacilityCreateorderMethodsInput>;
  orders?: Maybe<OrderCreateNestedManyWithoutFacilityInput>;
  placeId: Scalars['String'];
  powerBiReports?: Maybe<PowerBiReportCreateNestedManyWithoutAvailableToFacilitiesInput>;
  promoBanners?: Maybe<PromoBannerCreateNestedManyWithoutFacilityInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleCreateNestedManyWithoutFacilityInput>;
  stations?: Maybe<StationCreateNestedManyWithoutFacilityInput>;
  taxRate?: Maybe<Scalars['Float']>;
  timeZone?: Maybe<Scalars['String']>;
  tookanTeamId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workplaces?: Maybe<WorkplaceCreateNestedManyWithoutFacilityInput>;
};

export type FacilityCreateWithoutWorkplacesInput = {
  cravePlatforms?: Maybe<FacilityCreatecravePlatformsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deliveryEligibilities?: Maybe<DeliveryEligibilityCreateNestedManyWithoutFacilityInput>;
  deliveryEligibleAreas: Scalars['Json'];
  deliveryLimitInSeconds: Scalars['Int'];
  enableCatering?: Maybe<Scalars['Boolean']>;
  enableKiosk?: Maybe<Scalars['Boolean']>;
  formattedAddress: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  isDeliveryFeeTaxable?: Maybe<Scalars['Boolean']>;
  isServiceFeeTaxable?: Maybe<Scalars['Boolean']>;
  kdsType?: Maybe<KdsType>;
  kioskLogoUrl?: Maybe<Scalars['String']>;
  kitchens?: Maybe<KitchenCreateNestedManyWithoutFacilityInput>;
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  logoUrl?: Maybe<Scalars['String']>;
  menuCategories?: Maybe<MenuCategoryCreateNestedManyWithoutFacilityInput>;
  menuSchedules?: Maybe<MenuScheduleCreateNestedManyWithoutFacilityInput>;
  methodOverrides?: Maybe<OrderMethodOverrideCreateNestedManyWithoutFacilityInput>;
  name: Scalars['String'];
  operationHours?: Maybe<FacilityCreateoperationHoursInput>;
  orderMethods?: Maybe<FacilityCreateorderMethodsInput>;
  orders?: Maybe<OrderCreateNestedManyWithoutFacilityInput>;
  placeId: Scalars['String'];
  powerBiReports?: Maybe<PowerBiReportCreateNestedManyWithoutAvailableToFacilitiesInput>;
  promoBanners?: Maybe<PromoBannerCreateNestedManyWithoutFacilityInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleCreateNestedManyWithoutFacilityInput>;
  stations?: Maybe<StationCreateNestedManyWithoutFacilityInput>;
  taxRate?: Maybe<Scalars['Float']>;
  timeZone?: Maybe<Scalars['String']>;
  tookanTeamId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserCreateNestedManyWithoutFacilitiesInput>;
};

export type FacilityCreatecravePlatformsInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type FacilityCreateoperationHoursInput = {
  set?: Maybe<Array<Scalars['Json']>>;
};

export type FacilityCreateorderMethodsInput = {
  set?: Maybe<Array<OrderMethod>>;
};

export type FacilityItem = {
  __typename?: 'FacilityItem';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  menuItem: KitchenMenuItem;
  orderItem: OrderItem;
  quantity: Scalars['Float'];
  refired?: Maybe<Scalars['Boolean']>;
  refiredFrom?: Maybe<FacilityItem>;
  refiredTo: Array<FacilityItem>;
  ticket: KitchenTicket;
};


export type FacilityItemRefiredToArgs = {
  after?: Maybe<FacilityItemWhereUniqueInput>;
  before?: Maybe<FacilityItemWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type FacilityItemCreateManyMenuItemInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  orderItemId: Scalars['String'];
  quantity?: Maybe<Scalars['Float']>;
  refired?: Maybe<Scalars['Boolean']>;
  refiredFromId?: Maybe<Scalars['String']>;
  ticketId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FacilityItemCreateManyMenuItemInputEnvelope = {
  data?: Maybe<Array<FacilityItemCreateManyMenuItemInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type FacilityItemCreateManyOrderItemInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  menuItemId: Scalars['String'];
  quantity?: Maybe<Scalars['Float']>;
  refired?: Maybe<Scalars['Boolean']>;
  refiredFromId?: Maybe<Scalars['String']>;
  ticketId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FacilityItemCreateManyOrderItemInputEnvelope = {
  data?: Maybe<Array<FacilityItemCreateManyOrderItemInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type FacilityItemCreateManyRefiredFromInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  menuItemId: Scalars['String'];
  orderItemId: Scalars['String'];
  quantity?: Maybe<Scalars['Float']>;
  refired?: Maybe<Scalars['Boolean']>;
  ticketId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FacilityItemCreateManyRefiredFromInputEnvelope = {
  data?: Maybe<Array<FacilityItemCreateManyRefiredFromInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type FacilityItemCreateManyTicketInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  menuItemId: Scalars['String'];
  orderItemId: Scalars['String'];
  quantity?: Maybe<Scalars['Float']>;
  refired?: Maybe<Scalars['Boolean']>;
  refiredFromId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FacilityItemCreateManyTicketInputEnvelope = {
  data?: Maybe<Array<FacilityItemCreateManyTicketInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type FacilityItemCreateNestedManyWithoutMenuItemInput = {
  connect?: Maybe<Array<FacilityItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FacilityItemCreateOrConnectWithoutMenuItemInput>>;
  create?: Maybe<Array<FacilityItemCreateWithoutMenuItemInput>>;
  createMany?: Maybe<FacilityItemCreateManyMenuItemInputEnvelope>;
};

export type FacilityItemCreateNestedManyWithoutOrderItemInput = {
  connect?: Maybe<Array<FacilityItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FacilityItemCreateOrConnectWithoutOrderItemInput>>;
  create?: Maybe<Array<FacilityItemCreateWithoutOrderItemInput>>;
  createMany?: Maybe<FacilityItemCreateManyOrderItemInputEnvelope>;
};

export type FacilityItemCreateNestedManyWithoutRefiredFromInput = {
  connect?: Maybe<Array<FacilityItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FacilityItemCreateOrConnectWithoutRefiredFromInput>>;
  create?: Maybe<Array<FacilityItemCreateWithoutRefiredFromInput>>;
  createMany?: Maybe<FacilityItemCreateManyRefiredFromInputEnvelope>;
};

export type FacilityItemCreateNestedManyWithoutTicketInput = {
  connect?: Maybe<Array<FacilityItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FacilityItemCreateOrConnectWithoutTicketInput>>;
  create?: Maybe<Array<FacilityItemCreateWithoutTicketInput>>;
  createMany?: Maybe<FacilityItemCreateManyTicketInputEnvelope>;
};

export type FacilityItemCreateNestedOneWithoutRefiredToInput = {
  connect?: Maybe<FacilityItemWhereUniqueInput>;
  connectOrCreate?: Maybe<FacilityItemCreateOrConnectWithoutRefiredToInput>;
  create?: Maybe<FacilityItemCreateWithoutRefiredToInput>;
};

export type FacilityItemCreateOrConnectWithoutMenuItemInput = {
  create: FacilityItemCreateWithoutMenuItemInput;
  where: FacilityItemWhereUniqueInput;
};

export type FacilityItemCreateOrConnectWithoutOrderItemInput = {
  create: FacilityItemCreateWithoutOrderItemInput;
  where: FacilityItemWhereUniqueInput;
};

export type FacilityItemCreateOrConnectWithoutRefiredFromInput = {
  create: FacilityItemCreateWithoutRefiredFromInput;
  where: FacilityItemWhereUniqueInput;
};

export type FacilityItemCreateOrConnectWithoutRefiredToInput = {
  create: FacilityItemCreateWithoutRefiredToInput;
  where: FacilityItemWhereUniqueInput;
};

export type FacilityItemCreateOrConnectWithoutTicketInput = {
  create: FacilityItemCreateWithoutTicketInput;
  where: FacilityItemWhereUniqueInput;
};

export type FacilityItemCreateWithoutMenuItemInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  orderItem: OrderItemCreateNestedOneWithoutFacilityItemInput;
  quantity?: Maybe<Scalars['Float']>;
  refired?: Maybe<Scalars['Boolean']>;
  refiredFrom?: Maybe<FacilityItemCreateNestedOneWithoutRefiredToInput>;
  refiredTo?: Maybe<FacilityItemCreateNestedManyWithoutRefiredFromInput>;
  ticket: KitchenTicketCreateNestedOneWithoutItemsInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FacilityItemCreateWithoutOrderItemInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  menuItem: KitchenMenuItemCreateNestedOneWithoutFacilityItemsInput;
  quantity?: Maybe<Scalars['Float']>;
  refired?: Maybe<Scalars['Boolean']>;
  refiredFrom?: Maybe<FacilityItemCreateNestedOneWithoutRefiredToInput>;
  refiredTo?: Maybe<FacilityItemCreateNestedManyWithoutRefiredFromInput>;
  ticket: KitchenTicketCreateNestedOneWithoutItemsInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FacilityItemCreateWithoutRefiredFromInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  menuItem: KitchenMenuItemCreateNestedOneWithoutFacilityItemsInput;
  orderItem: OrderItemCreateNestedOneWithoutFacilityItemInput;
  quantity?: Maybe<Scalars['Float']>;
  refired?: Maybe<Scalars['Boolean']>;
  refiredTo?: Maybe<FacilityItemCreateNestedManyWithoutRefiredFromInput>;
  ticket: KitchenTicketCreateNestedOneWithoutItemsInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FacilityItemCreateWithoutRefiredToInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  menuItem: KitchenMenuItemCreateNestedOneWithoutFacilityItemsInput;
  orderItem: OrderItemCreateNestedOneWithoutFacilityItemInput;
  quantity?: Maybe<Scalars['Float']>;
  refired?: Maybe<Scalars['Boolean']>;
  refiredFrom?: Maybe<FacilityItemCreateNestedOneWithoutRefiredToInput>;
  ticket: KitchenTicketCreateNestedOneWithoutItemsInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FacilityItemCreateWithoutTicketInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  menuItem: KitchenMenuItemCreateNestedOneWithoutFacilityItemsInput;
  orderItem: OrderItemCreateNestedOneWithoutFacilityItemInput;
  quantity?: Maybe<Scalars['Float']>;
  refired?: Maybe<Scalars['Boolean']>;
  refiredFrom?: Maybe<FacilityItemCreateNestedOneWithoutRefiredToInput>;
  refiredTo?: Maybe<FacilityItemCreateNestedManyWithoutRefiredFromInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FacilityItemListRelationFilter = {
  every?: Maybe<FacilityItemWhereInput>;
  none?: Maybe<FacilityItemWhereInput>;
  some?: Maybe<FacilityItemWhereInput>;
};

export type FacilityItemScalarWhereInput = {
  AND?: Maybe<Array<FacilityItemScalarWhereInput>>;
  NOT?: Maybe<Array<FacilityItemScalarWhereInput>>;
  OR?: Maybe<Array<FacilityItemScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  menuItemId?: Maybe<StringFilter>;
  orderItemId?: Maybe<StringFilter>;
  quantity?: Maybe<FloatFilter>;
  refired?: Maybe<BoolNullableFilter>;
  refiredFromId?: Maybe<StringNullableFilter>;
  ticketId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type FacilityItemUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  quantity?: Maybe<FloatFieldUpdateOperationsInput>;
  refired?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type FacilityItemUpdateManyWithWhereWithoutMenuItemInput = {
  data: FacilityItemUpdateManyMutationInput;
  where: FacilityItemScalarWhereInput;
};

export type FacilityItemUpdateManyWithWhereWithoutOrderItemInput = {
  data: FacilityItemUpdateManyMutationInput;
  where: FacilityItemScalarWhereInput;
};

export type FacilityItemUpdateManyWithWhereWithoutRefiredFromInput = {
  data: FacilityItemUpdateManyMutationInput;
  where: FacilityItemScalarWhereInput;
};

export type FacilityItemUpdateManyWithWhereWithoutTicketInput = {
  data: FacilityItemUpdateManyMutationInput;
  where: FacilityItemScalarWhereInput;
};

export type FacilityItemUpdateManyWithoutMenuItemInput = {
  connect?: Maybe<Array<FacilityItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FacilityItemCreateOrConnectWithoutMenuItemInput>>;
  create?: Maybe<Array<FacilityItemCreateWithoutMenuItemInput>>;
  createMany?: Maybe<FacilityItemCreateManyMenuItemInputEnvelope>;
  delete?: Maybe<Array<FacilityItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<FacilityItemScalarWhereInput>>;
  disconnect?: Maybe<Array<FacilityItemWhereUniqueInput>>;
  set?: Maybe<Array<FacilityItemWhereUniqueInput>>;
  update?: Maybe<Array<FacilityItemUpdateWithWhereUniqueWithoutMenuItemInput>>;
  updateMany?: Maybe<Array<FacilityItemUpdateManyWithWhereWithoutMenuItemInput>>;
  upsert?: Maybe<Array<FacilityItemUpsertWithWhereUniqueWithoutMenuItemInput>>;
};

export type FacilityItemUpdateManyWithoutOrderItemInput = {
  connect?: Maybe<Array<FacilityItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FacilityItemCreateOrConnectWithoutOrderItemInput>>;
  create?: Maybe<Array<FacilityItemCreateWithoutOrderItemInput>>;
  createMany?: Maybe<FacilityItemCreateManyOrderItemInputEnvelope>;
  delete?: Maybe<Array<FacilityItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<FacilityItemScalarWhereInput>>;
  disconnect?: Maybe<Array<FacilityItemWhereUniqueInput>>;
  set?: Maybe<Array<FacilityItemWhereUniqueInput>>;
  update?: Maybe<Array<FacilityItemUpdateWithWhereUniqueWithoutOrderItemInput>>;
  updateMany?: Maybe<Array<FacilityItemUpdateManyWithWhereWithoutOrderItemInput>>;
  upsert?: Maybe<Array<FacilityItemUpsertWithWhereUniqueWithoutOrderItemInput>>;
};

export type FacilityItemUpdateManyWithoutRefiredFromInput = {
  connect?: Maybe<Array<FacilityItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FacilityItemCreateOrConnectWithoutRefiredFromInput>>;
  create?: Maybe<Array<FacilityItemCreateWithoutRefiredFromInput>>;
  createMany?: Maybe<FacilityItemCreateManyRefiredFromInputEnvelope>;
  delete?: Maybe<Array<FacilityItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<FacilityItemScalarWhereInput>>;
  disconnect?: Maybe<Array<FacilityItemWhereUniqueInput>>;
  set?: Maybe<Array<FacilityItemWhereUniqueInput>>;
  update?: Maybe<Array<FacilityItemUpdateWithWhereUniqueWithoutRefiredFromInput>>;
  updateMany?: Maybe<Array<FacilityItemUpdateManyWithWhereWithoutRefiredFromInput>>;
  upsert?: Maybe<Array<FacilityItemUpsertWithWhereUniqueWithoutRefiredFromInput>>;
};

export type FacilityItemUpdateManyWithoutTicketInput = {
  connect?: Maybe<Array<FacilityItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FacilityItemCreateOrConnectWithoutTicketInput>>;
  create?: Maybe<Array<FacilityItemCreateWithoutTicketInput>>;
  createMany?: Maybe<FacilityItemCreateManyTicketInputEnvelope>;
  delete?: Maybe<Array<FacilityItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<FacilityItemScalarWhereInput>>;
  disconnect?: Maybe<Array<FacilityItemWhereUniqueInput>>;
  set?: Maybe<Array<FacilityItemWhereUniqueInput>>;
  update?: Maybe<Array<FacilityItemUpdateWithWhereUniqueWithoutTicketInput>>;
  updateMany?: Maybe<Array<FacilityItemUpdateManyWithWhereWithoutTicketInput>>;
  upsert?: Maybe<Array<FacilityItemUpsertWithWhereUniqueWithoutTicketInput>>;
};

export type FacilityItemUpdateOneWithoutRefiredToInput = {
  connect?: Maybe<FacilityItemWhereUniqueInput>;
  connectOrCreate?: Maybe<FacilityItemCreateOrConnectWithoutRefiredToInput>;
  create?: Maybe<FacilityItemCreateWithoutRefiredToInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<FacilityItemUpdateWithoutRefiredToInput>;
  upsert?: Maybe<FacilityItemUpsertWithoutRefiredToInput>;
};

export type FacilityItemUpdateWithWhereUniqueWithoutMenuItemInput = {
  data: FacilityItemUpdateWithoutMenuItemInput;
  where: FacilityItemWhereUniqueInput;
};

export type FacilityItemUpdateWithWhereUniqueWithoutOrderItemInput = {
  data: FacilityItemUpdateWithoutOrderItemInput;
  where: FacilityItemWhereUniqueInput;
};

export type FacilityItemUpdateWithWhereUniqueWithoutRefiredFromInput = {
  data: FacilityItemUpdateWithoutRefiredFromInput;
  where: FacilityItemWhereUniqueInput;
};

export type FacilityItemUpdateWithWhereUniqueWithoutTicketInput = {
  data: FacilityItemUpdateWithoutTicketInput;
  where: FacilityItemWhereUniqueInput;
};

export type FacilityItemUpdateWithoutMenuItemInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  orderItem?: Maybe<OrderItemUpdateOneRequiredWithoutFacilityItemInput>;
  quantity?: Maybe<FloatFieldUpdateOperationsInput>;
  refired?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  refiredFrom?: Maybe<FacilityItemUpdateOneWithoutRefiredToInput>;
  refiredTo?: Maybe<FacilityItemUpdateManyWithoutRefiredFromInput>;
  ticket?: Maybe<KitchenTicketUpdateOneRequiredWithoutItemsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type FacilityItemUpdateWithoutOrderItemInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  menuItem?: Maybe<KitchenMenuItemUpdateOneRequiredWithoutFacilityItemsInput>;
  quantity?: Maybe<FloatFieldUpdateOperationsInput>;
  refired?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  refiredFrom?: Maybe<FacilityItemUpdateOneWithoutRefiredToInput>;
  refiredTo?: Maybe<FacilityItemUpdateManyWithoutRefiredFromInput>;
  ticket?: Maybe<KitchenTicketUpdateOneRequiredWithoutItemsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type FacilityItemUpdateWithoutRefiredFromInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  menuItem?: Maybe<KitchenMenuItemUpdateOneRequiredWithoutFacilityItemsInput>;
  orderItem?: Maybe<OrderItemUpdateOneRequiredWithoutFacilityItemInput>;
  quantity?: Maybe<FloatFieldUpdateOperationsInput>;
  refired?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  refiredTo?: Maybe<FacilityItemUpdateManyWithoutRefiredFromInput>;
  ticket?: Maybe<KitchenTicketUpdateOneRequiredWithoutItemsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type FacilityItemUpdateWithoutRefiredToInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  menuItem?: Maybe<KitchenMenuItemUpdateOneRequiredWithoutFacilityItemsInput>;
  orderItem?: Maybe<OrderItemUpdateOneRequiredWithoutFacilityItemInput>;
  quantity?: Maybe<FloatFieldUpdateOperationsInput>;
  refired?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  refiredFrom?: Maybe<FacilityItemUpdateOneWithoutRefiredToInput>;
  ticket?: Maybe<KitchenTicketUpdateOneRequiredWithoutItemsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type FacilityItemUpdateWithoutTicketInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  menuItem?: Maybe<KitchenMenuItemUpdateOneRequiredWithoutFacilityItemsInput>;
  orderItem?: Maybe<OrderItemUpdateOneRequiredWithoutFacilityItemInput>;
  quantity?: Maybe<FloatFieldUpdateOperationsInput>;
  refired?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  refiredFrom?: Maybe<FacilityItemUpdateOneWithoutRefiredToInput>;
  refiredTo?: Maybe<FacilityItemUpdateManyWithoutRefiredFromInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type FacilityItemUpsertWithWhereUniqueWithoutMenuItemInput = {
  create: FacilityItemCreateWithoutMenuItemInput;
  update: FacilityItemUpdateWithoutMenuItemInput;
  where: FacilityItemWhereUniqueInput;
};

export type FacilityItemUpsertWithWhereUniqueWithoutOrderItemInput = {
  create: FacilityItemCreateWithoutOrderItemInput;
  update: FacilityItemUpdateWithoutOrderItemInput;
  where: FacilityItemWhereUniqueInput;
};

export type FacilityItemUpsertWithWhereUniqueWithoutRefiredFromInput = {
  create: FacilityItemCreateWithoutRefiredFromInput;
  update: FacilityItemUpdateWithoutRefiredFromInput;
  where: FacilityItemWhereUniqueInput;
};

export type FacilityItemUpsertWithWhereUniqueWithoutTicketInput = {
  create: FacilityItemCreateWithoutTicketInput;
  update: FacilityItemUpdateWithoutTicketInput;
  where: FacilityItemWhereUniqueInput;
};

export type FacilityItemUpsertWithoutRefiredToInput = {
  create: FacilityItemCreateWithoutRefiredToInput;
  update: FacilityItemUpdateWithoutRefiredToInput;
};

export type FacilityItemWhereInput = {
  AND?: Maybe<Array<FacilityItemWhereInput>>;
  NOT?: Maybe<Array<FacilityItemWhereInput>>;
  OR?: Maybe<Array<FacilityItemWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  menuItem?: Maybe<KitchenMenuItemWhereInput>;
  menuItemId?: Maybe<StringFilter>;
  orderItem?: Maybe<OrderItemWhereInput>;
  orderItemId?: Maybe<StringFilter>;
  quantity?: Maybe<FloatFilter>;
  refired?: Maybe<BoolNullableFilter>;
  refiredFrom?: Maybe<FacilityItemWhereInput>;
  refiredFromId?: Maybe<StringNullableFilter>;
  refiredTo?: Maybe<FacilityItemListRelationFilter>;
  ticket?: Maybe<KitchenTicketWhereInput>;
  ticketId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type FacilityItemWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type FacilityListRelationFilter = {
  every?: Maybe<FacilityWhereInput>;
  none?: Maybe<FacilityWhereInput>;
  some?: Maybe<FacilityWhereInput>;
};

export type FacilityScalarWhereInput = {
  AND?: Maybe<Array<FacilityScalarWhereInput>>;
  NOT?: Maybe<Array<FacilityScalarWhereInput>>;
  OR?: Maybe<Array<FacilityScalarWhereInput>>;
  cravePlatforms?: Maybe<StringNullableListFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  deliveryLimitInSeconds?: Maybe<IntFilter>;
  enableCatering?: Maybe<BoolFilter>;
  enableKiosk?: Maybe<BoolFilter>;
  formattedAddress?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  isDeliveryFeeTaxable?: Maybe<BoolFilter>;
  isServiceFeeTaxable?: Maybe<BoolFilter>;
  kdsType?: Maybe<EnumKdsTypeFilter>;
  kioskLogoUrl?: Maybe<StringNullableFilter>;
  lat?: Maybe<FloatFilter>;
  lng?: Maybe<FloatFilter>;
  logoUrl?: Maybe<StringNullableFilter>;
  name?: Maybe<StringFilter>;
  operationHours?: Maybe<JsonNullableListFilter>;
  orderMethods?: Maybe<EnumOrderMethodNullableListFilter>;
  placeId?: Maybe<StringFilter>;
  taxRate?: Maybe<FloatFilter>;
  timeZone?: Maybe<StringFilter>;
  tookanTeamId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type FacilityUpdateManyMutationInput = {
  cravePlatforms?: Maybe<FacilityUpdatecravePlatformsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deliveryEligibleAreas?: Maybe<Scalars['Json']>;
  deliveryLimitInSeconds?: Maybe<IntFieldUpdateOperationsInput>;
  enableCatering?: Maybe<BoolFieldUpdateOperationsInput>;
  enableKiosk?: Maybe<BoolFieldUpdateOperationsInput>;
  formattedAddress?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isDeliveryFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isServiceFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  kdsType?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  kioskLogoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lat?: Maybe<FloatFieldUpdateOperationsInput>;
  lng?: Maybe<FloatFieldUpdateOperationsInput>;
  logoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationHours?: Maybe<FacilityUpdateoperationHoursInput>;
  orderMethods?: Maybe<FacilityUpdateorderMethodsInput>;
  placeId?: Maybe<StringFieldUpdateOperationsInput>;
  taxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  timeZone?: Maybe<StringFieldUpdateOperationsInput>;
  tookanTeamId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type FacilityUpdateManyWithWhereWithoutPowerBiReportsInput = {
  data: FacilityUpdateManyMutationInput;
  where: FacilityScalarWhereInput;
};

export type FacilityUpdateManyWithWhereWithoutUsersInput = {
  data: FacilityUpdateManyMutationInput;
  where: FacilityScalarWhereInput;
};

export type FacilityUpdateManyWithoutPowerBiReportsInput = {
  connect?: Maybe<Array<FacilityWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FacilityCreateOrConnectWithoutPowerBiReportsInput>>;
  create?: Maybe<Array<FacilityCreateWithoutPowerBiReportsInput>>;
  delete?: Maybe<Array<FacilityWhereUniqueInput>>;
  deleteMany?: Maybe<Array<FacilityScalarWhereInput>>;
  disconnect?: Maybe<Array<FacilityWhereUniqueInput>>;
  set?: Maybe<Array<FacilityWhereUniqueInput>>;
  update?: Maybe<Array<FacilityUpdateWithWhereUniqueWithoutPowerBiReportsInput>>;
  updateMany?: Maybe<Array<FacilityUpdateManyWithWhereWithoutPowerBiReportsInput>>;
  upsert?: Maybe<Array<FacilityUpsertWithWhereUniqueWithoutPowerBiReportsInput>>;
};

export type FacilityUpdateManyWithoutUsersInput = {
  connect?: Maybe<Array<FacilityWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FacilityCreateOrConnectWithoutUsersInput>>;
  create?: Maybe<Array<FacilityCreateWithoutUsersInput>>;
  delete?: Maybe<Array<FacilityWhereUniqueInput>>;
  deleteMany?: Maybe<Array<FacilityScalarWhereInput>>;
  disconnect?: Maybe<Array<FacilityWhereUniqueInput>>;
  set?: Maybe<Array<FacilityWhereUniqueInput>>;
  update?: Maybe<Array<FacilityUpdateWithWhereUniqueWithoutUsersInput>>;
  updateMany?: Maybe<Array<FacilityUpdateManyWithWhereWithoutUsersInput>>;
  upsert?: Maybe<Array<FacilityUpsertWithWhereUniqueWithoutUsersInput>>;
};

export type FacilityUpdateOneRequiredWithoutDeliveryEligibilitiesInput = {
  connect?: Maybe<FacilityWhereUniqueInput>;
  connectOrCreate?: Maybe<FacilityCreateOrConnectWithoutDeliveryEligibilitiesInput>;
  create?: Maybe<FacilityCreateWithoutDeliveryEligibilitiesInput>;
  update?: Maybe<FacilityUpdateWithoutDeliveryEligibilitiesInput>;
  upsert?: Maybe<FacilityUpsertWithoutDeliveryEligibilitiesInput>;
};

export type FacilityUpdateOneRequiredWithoutKitchensInput = {
  connect?: Maybe<FacilityWhereUniqueInput>;
  connectOrCreate?: Maybe<FacilityCreateOrConnectWithoutKitchensInput>;
  create?: Maybe<FacilityCreateWithoutKitchensInput>;
  update?: Maybe<FacilityUpdateWithoutKitchensInput>;
  upsert?: Maybe<FacilityUpsertWithoutKitchensInput>;
};

export type FacilityUpdateOneRequiredWithoutMenuCategoriesInput = {
  connect?: Maybe<FacilityWhereUniqueInput>;
  connectOrCreate?: Maybe<FacilityCreateOrConnectWithoutMenuCategoriesInput>;
  create?: Maybe<FacilityCreateWithoutMenuCategoriesInput>;
  update?: Maybe<FacilityUpdateWithoutMenuCategoriesInput>;
  upsert?: Maybe<FacilityUpsertWithoutMenuCategoriesInput>;
};

export type FacilityUpdateOneRequiredWithoutMenuSchedulesInput = {
  connect?: Maybe<FacilityWhereUniqueInput>;
  connectOrCreate?: Maybe<FacilityCreateOrConnectWithoutMenuSchedulesInput>;
  create?: Maybe<FacilityCreateWithoutMenuSchedulesInput>;
  update?: Maybe<FacilityUpdateWithoutMenuSchedulesInput>;
  upsert?: Maybe<FacilityUpsertWithoutMenuSchedulesInput>;
};

export type FacilityUpdateOneRequiredWithoutOrdersInput = {
  connect?: Maybe<FacilityWhereUniqueInput>;
  connectOrCreate?: Maybe<FacilityCreateOrConnectWithoutOrdersInput>;
  create?: Maybe<FacilityCreateWithoutOrdersInput>;
  update?: Maybe<FacilityUpdateWithoutOrdersInput>;
  upsert?: Maybe<FacilityUpsertWithoutOrdersInput>;
};

export type FacilityUpdateOneRequiredWithoutPromoBannersInput = {
  connect?: Maybe<FacilityWhereUniqueInput>;
  connectOrCreate?: Maybe<FacilityCreateOrConnectWithoutPromoBannersInput>;
  create?: Maybe<FacilityCreateWithoutPromoBannersInput>;
  update?: Maybe<FacilityUpdateWithoutPromoBannersInput>;
  upsert?: Maybe<FacilityUpsertWithoutPromoBannersInput>;
};

export type FacilityUpdateOneRequiredWithoutPromoFacilityRulesInput = {
  connect?: Maybe<FacilityWhereUniqueInput>;
  connectOrCreate?: Maybe<FacilityCreateOrConnectWithoutPromoFacilityRulesInput>;
  create?: Maybe<FacilityCreateWithoutPromoFacilityRulesInput>;
  update?: Maybe<FacilityUpdateWithoutPromoFacilityRulesInput>;
  upsert?: Maybe<FacilityUpsertWithoutPromoFacilityRulesInput>;
};

export type FacilityUpdateOneRequiredWithoutStationsInput = {
  connect?: Maybe<FacilityWhereUniqueInput>;
  connectOrCreate?: Maybe<FacilityCreateOrConnectWithoutStationsInput>;
  create?: Maybe<FacilityCreateWithoutStationsInput>;
  update?: Maybe<FacilityUpdateWithoutStationsInput>;
  upsert?: Maybe<FacilityUpsertWithoutStationsInput>;
};

export type FacilityUpdateOneRequiredWithoutWorkplacesInput = {
  connect?: Maybe<FacilityWhereUniqueInput>;
  connectOrCreate?: Maybe<FacilityCreateOrConnectWithoutWorkplacesInput>;
  create?: Maybe<FacilityCreateWithoutWorkplacesInput>;
  update?: Maybe<FacilityUpdateWithoutWorkplacesInput>;
  upsert?: Maybe<FacilityUpsertWithoutWorkplacesInput>;
};

export type FacilityUpdateWithWhereUniqueWithoutPowerBiReportsInput = {
  data: FacilityUpdateWithoutPowerBiReportsInput;
  where: FacilityWhereUniqueInput;
};

export type FacilityUpdateWithWhereUniqueWithoutUsersInput = {
  data: FacilityUpdateWithoutUsersInput;
  where: FacilityWhereUniqueInput;
};

export type FacilityUpdateWithoutDeliveryEligibilitiesInput = {
  cravePlatforms?: Maybe<FacilityUpdatecravePlatformsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deliveryEligibleAreas?: Maybe<Scalars['Json']>;
  deliveryLimitInSeconds?: Maybe<IntFieldUpdateOperationsInput>;
  enableCatering?: Maybe<BoolFieldUpdateOperationsInput>;
  enableKiosk?: Maybe<BoolFieldUpdateOperationsInput>;
  formattedAddress?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isDeliveryFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isServiceFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  kdsType?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  kioskLogoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  kitchens?: Maybe<KitchenUpdateManyWithoutFacilityInput>;
  lat?: Maybe<FloatFieldUpdateOperationsInput>;
  lng?: Maybe<FloatFieldUpdateOperationsInput>;
  logoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  menuCategories?: Maybe<MenuCategoryUpdateManyWithoutFacilityInput>;
  menuSchedules?: Maybe<MenuScheduleUpdateManyWithoutFacilityInput>;
  methodOverrides?: Maybe<OrderMethodOverrideUpdateManyWithoutFacilityInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationHours?: Maybe<FacilityUpdateoperationHoursInput>;
  orderMethods?: Maybe<FacilityUpdateorderMethodsInput>;
  orders?: Maybe<OrderUpdateManyWithoutFacilityInput>;
  placeId?: Maybe<StringFieldUpdateOperationsInput>;
  powerBiReports?: Maybe<PowerBiReportUpdateManyWithoutAvailableToFacilitiesInput>;
  promoBanners?: Maybe<PromoBannerUpdateManyWithoutFacilityInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleUpdateManyWithoutFacilityInput>;
  stations?: Maybe<StationUpdateManyWithoutFacilityInput>;
  taxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  timeZone?: Maybe<StringFieldUpdateOperationsInput>;
  tookanTeamId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  users?: Maybe<UserUpdateManyWithoutFacilitiesInput>;
  workplaces?: Maybe<WorkplaceUpdateManyWithoutFacilityInput>;
};

export type FacilityUpdateWithoutKitchensInput = {
  cravePlatforms?: Maybe<FacilityUpdatecravePlatformsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deliveryEligibilities?: Maybe<DeliveryEligibilityUpdateManyWithoutFacilityInput>;
  deliveryEligibleAreas?: Maybe<Scalars['Json']>;
  deliveryLimitInSeconds?: Maybe<IntFieldUpdateOperationsInput>;
  enableCatering?: Maybe<BoolFieldUpdateOperationsInput>;
  enableKiosk?: Maybe<BoolFieldUpdateOperationsInput>;
  formattedAddress?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isDeliveryFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isServiceFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  kdsType?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  kioskLogoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lat?: Maybe<FloatFieldUpdateOperationsInput>;
  lng?: Maybe<FloatFieldUpdateOperationsInput>;
  logoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  menuCategories?: Maybe<MenuCategoryUpdateManyWithoutFacilityInput>;
  menuSchedules?: Maybe<MenuScheduleUpdateManyWithoutFacilityInput>;
  methodOverrides?: Maybe<OrderMethodOverrideUpdateManyWithoutFacilityInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationHours?: Maybe<FacilityUpdateoperationHoursInput>;
  orderMethods?: Maybe<FacilityUpdateorderMethodsInput>;
  orders?: Maybe<OrderUpdateManyWithoutFacilityInput>;
  placeId?: Maybe<StringFieldUpdateOperationsInput>;
  powerBiReports?: Maybe<PowerBiReportUpdateManyWithoutAvailableToFacilitiesInput>;
  promoBanners?: Maybe<PromoBannerUpdateManyWithoutFacilityInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleUpdateManyWithoutFacilityInput>;
  stations?: Maybe<StationUpdateManyWithoutFacilityInput>;
  taxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  timeZone?: Maybe<StringFieldUpdateOperationsInput>;
  tookanTeamId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  users?: Maybe<UserUpdateManyWithoutFacilitiesInput>;
  workplaces?: Maybe<WorkplaceUpdateManyWithoutFacilityInput>;
};

export type FacilityUpdateWithoutMenuCategoriesInput = {
  cravePlatforms?: Maybe<FacilityUpdatecravePlatformsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deliveryEligibilities?: Maybe<DeliveryEligibilityUpdateManyWithoutFacilityInput>;
  deliveryEligibleAreas?: Maybe<Scalars['Json']>;
  deliveryLimitInSeconds?: Maybe<IntFieldUpdateOperationsInput>;
  enableCatering?: Maybe<BoolFieldUpdateOperationsInput>;
  enableKiosk?: Maybe<BoolFieldUpdateOperationsInput>;
  formattedAddress?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isDeliveryFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isServiceFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  kdsType?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  kioskLogoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  kitchens?: Maybe<KitchenUpdateManyWithoutFacilityInput>;
  lat?: Maybe<FloatFieldUpdateOperationsInput>;
  lng?: Maybe<FloatFieldUpdateOperationsInput>;
  logoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  menuSchedules?: Maybe<MenuScheduleUpdateManyWithoutFacilityInput>;
  methodOverrides?: Maybe<OrderMethodOverrideUpdateManyWithoutFacilityInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationHours?: Maybe<FacilityUpdateoperationHoursInput>;
  orderMethods?: Maybe<FacilityUpdateorderMethodsInput>;
  orders?: Maybe<OrderUpdateManyWithoutFacilityInput>;
  placeId?: Maybe<StringFieldUpdateOperationsInput>;
  powerBiReports?: Maybe<PowerBiReportUpdateManyWithoutAvailableToFacilitiesInput>;
  promoBanners?: Maybe<PromoBannerUpdateManyWithoutFacilityInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleUpdateManyWithoutFacilityInput>;
  stations?: Maybe<StationUpdateManyWithoutFacilityInput>;
  taxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  timeZone?: Maybe<StringFieldUpdateOperationsInput>;
  tookanTeamId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  users?: Maybe<UserUpdateManyWithoutFacilitiesInput>;
  workplaces?: Maybe<WorkplaceUpdateManyWithoutFacilityInput>;
};

export type FacilityUpdateWithoutMenuSchedulesInput = {
  cravePlatforms?: Maybe<FacilityUpdatecravePlatformsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deliveryEligibilities?: Maybe<DeliveryEligibilityUpdateManyWithoutFacilityInput>;
  deliveryEligibleAreas?: Maybe<Scalars['Json']>;
  deliveryLimitInSeconds?: Maybe<IntFieldUpdateOperationsInput>;
  enableCatering?: Maybe<BoolFieldUpdateOperationsInput>;
  enableKiosk?: Maybe<BoolFieldUpdateOperationsInput>;
  formattedAddress?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isDeliveryFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isServiceFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  kdsType?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  kioskLogoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  kitchens?: Maybe<KitchenUpdateManyWithoutFacilityInput>;
  lat?: Maybe<FloatFieldUpdateOperationsInput>;
  lng?: Maybe<FloatFieldUpdateOperationsInput>;
  logoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  menuCategories?: Maybe<MenuCategoryUpdateManyWithoutFacilityInput>;
  methodOverrides?: Maybe<OrderMethodOverrideUpdateManyWithoutFacilityInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationHours?: Maybe<FacilityUpdateoperationHoursInput>;
  orderMethods?: Maybe<FacilityUpdateorderMethodsInput>;
  orders?: Maybe<OrderUpdateManyWithoutFacilityInput>;
  placeId?: Maybe<StringFieldUpdateOperationsInput>;
  powerBiReports?: Maybe<PowerBiReportUpdateManyWithoutAvailableToFacilitiesInput>;
  promoBanners?: Maybe<PromoBannerUpdateManyWithoutFacilityInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleUpdateManyWithoutFacilityInput>;
  stations?: Maybe<StationUpdateManyWithoutFacilityInput>;
  taxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  timeZone?: Maybe<StringFieldUpdateOperationsInput>;
  tookanTeamId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  users?: Maybe<UserUpdateManyWithoutFacilitiesInput>;
  workplaces?: Maybe<WorkplaceUpdateManyWithoutFacilityInput>;
};

export type FacilityUpdateWithoutOrdersInput = {
  cravePlatforms?: Maybe<FacilityUpdatecravePlatformsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deliveryEligibilities?: Maybe<DeliveryEligibilityUpdateManyWithoutFacilityInput>;
  deliveryEligibleAreas?: Maybe<Scalars['Json']>;
  deliveryLimitInSeconds?: Maybe<IntFieldUpdateOperationsInput>;
  enableCatering?: Maybe<BoolFieldUpdateOperationsInput>;
  enableKiosk?: Maybe<BoolFieldUpdateOperationsInput>;
  formattedAddress?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isDeliveryFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isServiceFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  kdsType?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  kioskLogoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  kitchens?: Maybe<KitchenUpdateManyWithoutFacilityInput>;
  lat?: Maybe<FloatFieldUpdateOperationsInput>;
  lng?: Maybe<FloatFieldUpdateOperationsInput>;
  logoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  menuCategories?: Maybe<MenuCategoryUpdateManyWithoutFacilityInput>;
  menuSchedules?: Maybe<MenuScheduleUpdateManyWithoutFacilityInput>;
  methodOverrides?: Maybe<OrderMethodOverrideUpdateManyWithoutFacilityInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationHours?: Maybe<FacilityUpdateoperationHoursInput>;
  orderMethods?: Maybe<FacilityUpdateorderMethodsInput>;
  placeId?: Maybe<StringFieldUpdateOperationsInput>;
  powerBiReports?: Maybe<PowerBiReportUpdateManyWithoutAvailableToFacilitiesInput>;
  promoBanners?: Maybe<PromoBannerUpdateManyWithoutFacilityInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleUpdateManyWithoutFacilityInput>;
  stations?: Maybe<StationUpdateManyWithoutFacilityInput>;
  taxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  timeZone?: Maybe<StringFieldUpdateOperationsInput>;
  tookanTeamId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  users?: Maybe<UserUpdateManyWithoutFacilitiesInput>;
  workplaces?: Maybe<WorkplaceUpdateManyWithoutFacilityInput>;
};

export type FacilityUpdateWithoutPowerBiReportsInput = {
  cravePlatforms?: Maybe<FacilityUpdatecravePlatformsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deliveryEligibilities?: Maybe<DeliveryEligibilityUpdateManyWithoutFacilityInput>;
  deliveryEligibleAreas?: Maybe<Scalars['Json']>;
  deliveryLimitInSeconds?: Maybe<IntFieldUpdateOperationsInput>;
  enableCatering?: Maybe<BoolFieldUpdateOperationsInput>;
  enableKiosk?: Maybe<BoolFieldUpdateOperationsInput>;
  formattedAddress?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isDeliveryFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isServiceFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  kdsType?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  kioskLogoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  kitchens?: Maybe<KitchenUpdateManyWithoutFacilityInput>;
  lat?: Maybe<FloatFieldUpdateOperationsInput>;
  lng?: Maybe<FloatFieldUpdateOperationsInput>;
  logoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  menuCategories?: Maybe<MenuCategoryUpdateManyWithoutFacilityInput>;
  menuSchedules?: Maybe<MenuScheduleUpdateManyWithoutFacilityInput>;
  methodOverrides?: Maybe<OrderMethodOverrideUpdateManyWithoutFacilityInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationHours?: Maybe<FacilityUpdateoperationHoursInput>;
  orderMethods?: Maybe<FacilityUpdateorderMethodsInput>;
  orders?: Maybe<OrderUpdateManyWithoutFacilityInput>;
  placeId?: Maybe<StringFieldUpdateOperationsInput>;
  promoBanners?: Maybe<PromoBannerUpdateManyWithoutFacilityInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleUpdateManyWithoutFacilityInput>;
  stations?: Maybe<StationUpdateManyWithoutFacilityInput>;
  taxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  timeZone?: Maybe<StringFieldUpdateOperationsInput>;
  tookanTeamId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  users?: Maybe<UserUpdateManyWithoutFacilitiesInput>;
  workplaces?: Maybe<WorkplaceUpdateManyWithoutFacilityInput>;
};

export type FacilityUpdateWithoutPromoBannersInput = {
  cravePlatforms?: Maybe<FacilityUpdatecravePlatformsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deliveryEligibilities?: Maybe<DeliveryEligibilityUpdateManyWithoutFacilityInput>;
  deliveryEligibleAreas?: Maybe<Scalars['Json']>;
  deliveryLimitInSeconds?: Maybe<IntFieldUpdateOperationsInput>;
  enableCatering?: Maybe<BoolFieldUpdateOperationsInput>;
  enableKiosk?: Maybe<BoolFieldUpdateOperationsInput>;
  formattedAddress?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isDeliveryFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isServiceFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  kdsType?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  kioskLogoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  kitchens?: Maybe<KitchenUpdateManyWithoutFacilityInput>;
  lat?: Maybe<FloatFieldUpdateOperationsInput>;
  lng?: Maybe<FloatFieldUpdateOperationsInput>;
  logoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  menuCategories?: Maybe<MenuCategoryUpdateManyWithoutFacilityInput>;
  menuSchedules?: Maybe<MenuScheduleUpdateManyWithoutFacilityInput>;
  methodOverrides?: Maybe<OrderMethodOverrideUpdateManyWithoutFacilityInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationHours?: Maybe<FacilityUpdateoperationHoursInput>;
  orderMethods?: Maybe<FacilityUpdateorderMethodsInput>;
  orders?: Maybe<OrderUpdateManyWithoutFacilityInput>;
  placeId?: Maybe<StringFieldUpdateOperationsInput>;
  powerBiReports?: Maybe<PowerBiReportUpdateManyWithoutAvailableToFacilitiesInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleUpdateManyWithoutFacilityInput>;
  stations?: Maybe<StationUpdateManyWithoutFacilityInput>;
  taxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  timeZone?: Maybe<StringFieldUpdateOperationsInput>;
  tookanTeamId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  users?: Maybe<UserUpdateManyWithoutFacilitiesInput>;
  workplaces?: Maybe<WorkplaceUpdateManyWithoutFacilityInput>;
};

export type FacilityUpdateWithoutPromoFacilityRulesInput = {
  cravePlatforms?: Maybe<FacilityUpdatecravePlatformsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deliveryEligibilities?: Maybe<DeliveryEligibilityUpdateManyWithoutFacilityInput>;
  deliveryEligibleAreas?: Maybe<Scalars['Json']>;
  deliveryLimitInSeconds?: Maybe<IntFieldUpdateOperationsInput>;
  enableCatering?: Maybe<BoolFieldUpdateOperationsInput>;
  enableKiosk?: Maybe<BoolFieldUpdateOperationsInput>;
  formattedAddress?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isDeliveryFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isServiceFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  kdsType?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  kioskLogoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  kitchens?: Maybe<KitchenUpdateManyWithoutFacilityInput>;
  lat?: Maybe<FloatFieldUpdateOperationsInput>;
  lng?: Maybe<FloatFieldUpdateOperationsInput>;
  logoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  menuCategories?: Maybe<MenuCategoryUpdateManyWithoutFacilityInput>;
  menuSchedules?: Maybe<MenuScheduleUpdateManyWithoutFacilityInput>;
  methodOverrides?: Maybe<OrderMethodOverrideUpdateManyWithoutFacilityInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationHours?: Maybe<FacilityUpdateoperationHoursInput>;
  orderMethods?: Maybe<FacilityUpdateorderMethodsInput>;
  orders?: Maybe<OrderUpdateManyWithoutFacilityInput>;
  placeId?: Maybe<StringFieldUpdateOperationsInput>;
  powerBiReports?: Maybe<PowerBiReportUpdateManyWithoutAvailableToFacilitiesInput>;
  promoBanners?: Maybe<PromoBannerUpdateManyWithoutFacilityInput>;
  stations?: Maybe<StationUpdateManyWithoutFacilityInput>;
  taxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  timeZone?: Maybe<StringFieldUpdateOperationsInput>;
  tookanTeamId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  users?: Maybe<UserUpdateManyWithoutFacilitiesInput>;
  workplaces?: Maybe<WorkplaceUpdateManyWithoutFacilityInput>;
};

export type FacilityUpdateWithoutStationsInput = {
  cravePlatforms?: Maybe<FacilityUpdatecravePlatformsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deliveryEligibilities?: Maybe<DeliveryEligibilityUpdateManyWithoutFacilityInput>;
  deliveryEligibleAreas?: Maybe<Scalars['Json']>;
  deliveryLimitInSeconds?: Maybe<IntFieldUpdateOperationsInput>;
  enableCatering?: Maybe<BoolFieldUpdateOperationsInput>;
  enableKiosk?: Maybe<BoolFieldUpdateOperationsInput>;
  formattedAddress?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isDeliveryFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isServiceFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  kdsType?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  kioskLogoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  kitchens?: Maybe<KitchenUpdateManyWithoutFacilityInput>;
  lat?: Maybe<FloatFieldUpdateOperationsInput>;
  lng?: Maybe<FloatFieldUpdateOperationsInput>;
  logoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  menuCategories?: Maybe<MenuCategoryUpdateManyWithoutFacilityInput>;
  menuSchedules?: Maybe<MenuScheduleUpdateManyWithoutFacilityInput>;
  methodOverrides?: Maybe<OrderMethodOverrideUpdateManyWithoutFacilityInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationHours?: Maybe<FacilityUpdateoperationHoursInput>;
  orderMethods?: Maybe<FacilityUpdateorderMethodsInput>;
  orders?: Maybe<OrderUpdateManyWithoutFacilityInput>;
  placeId?: Maybe<StringFieldUpdateOperationsInput>;
  powerBiReports?: Maybe<PowerBiReportUpdateManyWithoutAvailableToFacilitiesInput>;
  promoBanners?: Maybe<PromoBannerUpdateManyWithoutFacilityInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleUpdateManyWithoutFacilityInput>;
  taxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  timeZone?: Maybe<StringFieldUpdateOperationsInput>;
  tookanTeamId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  users?: Maybe<UserUpdateManyWithoutFacilitiesInput>;
  workplaces?: Maybe<WorkplaceUpdateManyWithoutFacilityInput>;
};

export type FacilityUpdateWithoutUsersInput = {
  cravePlatforms?: Maybe<FacilityUpdatecravePlatformsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deliveryEligibilities?: Maybe<DeliveryEligibilityUpdateManyWithoutFacilityInput>;
  deliveryEligibleAreas?: Maybe<Scalars['Json']>;
  deliveryLimitInSeconds?: Maybe<IntFieldUpdateOperationsInput>;
  enableCatering?: Maybe<BoolFieldUpdateOperationsInput>;
  enableKiosk?: Maybe<BoolFieldUpdateOperationsInput>;
  formattedAddress?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isDeliveryFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isServiceFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  kdsType?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  kioskLogoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  kitchens?: Maybe<KitchenUpdateManyWithoutFacilityInput>;
  lat?: Maybe<FloatFieldUpdateOperationsInput>;
  lng?: Maybe<FloatFieldUpdateOperationsInput>;
  logoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  menuCategories?: Maybe<MenuCategoryUpdateManyWithoutFacilityInput>;
  menuSchedules?: Maybe<MenuScheduleUpdateManyWithoutFacilityInput>;
  methodOverrides?: Maybe<OrderMethodOverrideUpdateManyWithoutFacilityInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationHours?: Maybe<FacilityUpdateoperationHoursInput>;
  orderMethods?: Maybe<FacilityUpdateorderMethodsInput>;
  orders?: Maybe<OrderUpdateManyWithoutFacilityInput>;
  placeId?: Maybe<StringFieldUpdateOperationsInput>;
  powerBiReports?: Maybe<PowerBiReportUpdateManyWithoutAvailableToFacilitiesInput>;
  promoBanners?: Maybe<PromoBannerUpdateManyWithoutFacilityInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleUpdateManyWithoutFacilityInput>;
  stations?: Maybe<StationUpdateManyWithoutFacilityInput>;
  taxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  timeZone?: Maybe<StringFieldUpdateOperationsInput>;
  tookanTeamId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  workplaces?: Maybe<WorkplaceUpdateManyWithoutFacilityInput>;
};

export type FacilityUpdateWithoutWorkplacesInput = {
  cravePlatforms?: Maybe<FacilityUpdatecravePlatformsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deliveryEligibilities?: Maybe<DeliveryEligibilityUpdateManyWithoutFacilityInput>;
  deliveryEligibleAreas?: Maybe<Scalars['Json']>;
  deliveryLimitInSeconds?: Maybe<IntFieldUpdateOperationsInput>;
  enableCatering?: Maybe<BoolFieldUpdateOperationsInput>;
  enableKiosk?: Maybe<BoolFieldUpdateOperationsInput>;
  formattedAddress?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isDeliveryFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  isServiceFeeTaxable?: Maybe<BoolFieldUpdateOperationsInput>;
  kdsType?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  kioskLogoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  kitchens?: Maybe<KitchenUpdateManyWithoutFacilityInput>;
  lat?: Maybe<FloatFieldUpdateOperationsInput>;
  lng?: Maybe<FloatFieldUpdateOperationsInput>;
  logoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  menuCategories?: Maybe<MenuCategoryUpdateManyWithoutFacilityInput>;
  menuSchedules?: Maybe<MenuScheduleUpdateManyWithoutFacilityInput>;
  methodOverrides?: Maybe<OrderMethodOverrideUpdateManyWithoutFacilityInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationHours?: Maybe<FacilityUpdateoperationHoursInput>;
  orderMethods?: Maybe<FacilityUpdateorderMethodsInput>;
  orders?: Maybe<OrderUpdateManyWithoutFacilityInput>;
  placeId?: Maybe<StringFieldUpdateOperationsInput>;
  powerBiReports?: Maybe<PowerBiReportUpdateManyWithoutAvailableToFacilitiesInput>;
  promoBanners?: Maybe<PromoBannerUpdateManyWithoutFacilityInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleUpdateManyWithoutFacilityInput>;
  stations?: Maybe<StationUpdateManyWithoutFacilityInput>;
  taxRate?: Maybe<FloatFieldUpdateOperationsInput>;
  timeZone?: Maybe<StringFieldUpdateOperationsInput>;
  tookanTeamId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  users?: Maybe<UserUpdateManyWithoutFacilitiesInput>;
};

export type FacilityUpdatecravePlatformsInput = {
  push?: Maybe<Scalars['String']>;
  set?: Maybe<Array<Scalars['String']>>;
};

export type FacilityUpdateoperationHoursInput = {
  push?: Maybe<Scalars['Json']>;
  set?: Maybe<Array<Scalars['Json']>>;
};

export type FacilityUpdateorderMethodsInput = {
  push?: Maybe<OrderMethod>;
  set?: Maybe<Array<OrderMethod>>;
};

export type FacilityUpsertWithWhereUniqueWithoutPowerBiReportsInput = {
  create: FacilityCreateWithoutPowerBiReportsInput;
  update: FacilityUpdateWithoutPowerBiReportsInput;
  where: FacilityWhereUniqueInput;
};

export type FacilityUpsertWithWhereUniqueWithoutUsersInput = {
  create: FacilityCreateWithoutUsersInput;
  update: FacilityUpdateWithoutUsersInput;
  where: FacilityWhereUniqueInput;
};

export type FacilityUpsertWithoutDeliveryEligibilitiesInput = {
  create: FacilityCreateWithoutDeliveryEligibilitiesInput;
  update: FacilityUpdateWithoutDeliveryEligibilitiesInput;
};

export type FacilityUpsertWithoutKitchensInput = {
  create: FacilityCreateWithoutKitchensInput;
  update: FacilityUpdateWithoutKitchensInput;
};

export type FacilityUpsertWithoutMenuCategoriesInput = {
  create: FacilityCreateWithoutMenuCategoriesInput;
  update: FacilityUpdateWithoutMenuCategoriesInput;
};

export type FacilityUpsertWithoutMenuSchedulesInput = {
  create: FacilityCreateWithoutMenuSchedulesInput;
  update: FacilityUpdateWithoutMenuSchedulesInput;
};

export type FacilityUpsertWithoutOrdersInput = {
  create: FacilityCreateWithoutOrdersInput;
  update: FacilityUpdateWithoutOrdersInput;
};

export type FacilityUpsertWithoutPromoBannersInput = {
  create: FacilityCreateWithoutPromoBannersInput;
  update: FacilityUpdateWithoutPromoBannersInput;
};

export type FacilityUpsertWithoutPromoFacilityRulesInput = {
  create: FacilityCreateWithoutPromoFacilityRulesInput;
  update: FacilityUpdateWithoutPromoFacilityRulesInput;
};

export type FacilityUpsertWithoutStationsInput = {
  create: FacilityCreateWithoutStationsInput;
  update: FacilityUpdateWithoutStationsInput;
};

export type FacilityUpsertWithoutWorkplacesInput = {
  create: FacilityCreateWithoutWorkplacesInput;
  update: FacilityUpdateWithoutWorkplacesInput;
};

export type FacilityWhereInput = {
  AND?: Maybe<Array<FacilityWhereInput>>;
  NOT?: Maybe<Array<FacilityWhereInput>>;
  OR?: Maybe<Array<FacilityWhereInput>>;
  cravePlatforms?: Maybe<StringNullableListFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  deliveryEligibilities?: Maybe<DeliveryEligibilityListRelationFilter>;
  deliveryLimitInSeconds?: Maybe<IntFilter>;
  enableCatering?: Maybe<BoolFilter>;
  enableKiosk?: Maybe<BoolFilter>;
  formattedAddress?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  isDeliveryFeeTaxable?: Maybe<BoolFilter>;
  isServiceFeeTaxable?: Maybe<BoolFilter>;
  kdsType?: Maybe<EnumKdsTypeFilter>;
  kioskLogoUrl?: Maybe<StringNullableFilter>;
  kitchens?: Maybe<KitchenListRelationFilter>;
  lat?: Maybe<FloatFilter>;
  lng?: Maybe<FloatFilter>;
  logoUrl?: Maybe<StringNullableFilter>;
  menuCategories?: Maybe<MenuCategoryListRelationFilter>;
  menuSchedules?: Maybe<MenuScheduleListRelationFilter>;
  methodOverrides?: Maybe<OrderMethodOverrideListRelationFilter>;
  name?: Maybe<StringFilter>;
  operationHours?: Maybe<JsonNullableListFilter>;
  orderMethods?: Maybe<EnumOrderMethodNullableListFilter>;
  orders?: Maybe<OrderListRelationFilter>;
  placeId?: Maybe<StringFilter>;
  powerBiReports?: Maybe<PowerBiReportListRelationFilter>;
  promoBanners?: Maybe<PromoBannerListRelationFilter>;
  promoFacilityRules?: Maybe<PromoFacilityRuleListRelationFilter>;
  stations?: Maybe<StationListRelationFilter>;
  taxRate?: Maybe<FloatFilter>;
  timeZone?: Maybe<StringFilter>;
  tookanTeamId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  users?: Maybe<UserListRelationFilter>;
  workplaces?: Maybe<WorkplaceListRelationFilter>;
};

export type FacilityWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type FloatFieldUpdateOperationsInput = {
  decrement?: Maybe<Scalars['Float']>;
  divide?: Maybe<Scalars['Float']>;
  increment?: Maybe<Scalars['Float']>;
  multiply?: Maybe<Scalars['Float']>;
  set?: Maybe<Scalars['Float']>;
};

export type FloatFilter = {
  equals?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatFilter>;
  notIn?: Maybe<Array<Scalars['Float']>>;
};

export type FloatNullableFilter = {
  equals?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatNullableFilter>;
  notIn?: Maybe<Array<Scalars['Float']>>;
};

export type FloatNullableListFilter = {
  equals?: Maybe<Array<Scalars['Float']>>;
  has?: Maybe<Scalars['Float']>;
  hasEvery?: Maybe<Array<Scalars['Float']>>;
  hasSome?: Maybe<Array<Scalars['Float']>>;
  isEmpty?: Maybe<Scalars['Boolean']>;
};

export type IntFieldUpdateOperationsInput = {
  decrement?: Maybe<Scalars['Int']>;
  divide?: Maybe<Scalars['Int']>;
  increment?: Maybe<Scalars['Int']>;
  multiply?: Maybe<Scalars['Int']>;
  set?: Maybe<Scalars['Int']>;
};

export type IntFilter = {
  equals?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntFilter>;
  notIn?: Maybe<Array<Scalars['Int']>>;
};

export type IntNullableFilter = {
  equals?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntNullableFilter>;
  notIn?: Maybe<Array<Scalars['Int']>>;
};

export type IntNullableListFilter = {
  equals?: Maybe<Array<Scalars['Int']>>;
  has?: Maybe<Scalars['Int']>;
  hasEvery?: Maybe<Array<Scalars['Int']>>;
  hasSome?: Maybe<Array<Scalars['Int']>>;
  isEmpty?: Maybe<Scalars['Boolean']>;
};

export enum InventoryStatus {
  InStock = 'IN_STOCK',
  SoldOut = 'SOLD_OUT'
}

export type ItemSalesReport = {
  __typename?: 'ItemSalesReport';
  count: Scalars['Int'];
  id: Scalars['String'];
  name: Array<Scalars['String']>;
  sales: Scalars['Float'];
};


export type JsonNullableListFilter = {
  equals?: Maybe<Array<Scalars['Json']>>;
  has?: Maybe<Scalars['Json']>;
  hasEvery?: Maybe<Array<Scalars['Json']>>;
  hasSome?: Maybe<Array<Scalars['Json']>>;
  isEmpty?: Maybe<Scalars['Boolean']>;
};

export enum KdsType {
  Kitchen = 'KITCHEN',
  Station = 'STATION'
}

export enum KdsOrdersWorkplace {
  Expo = 'EXPO',
  Foh = 'FOH',
  OrderStatus = 'ORDER_STATUS',
  Packaging = 'PACKAGING',
  Pickup = 'PICKUP',
  Runner = 'RUNNER'
}

export enum KdsStationOrdersWorkplace {
  Expo = 'EXPO',
  Kiosk = 'KIOSK'
}

export type KioskCosts = {
  delivery?: Maybe<Scalars['Float']>;
  fee?: Maybe<Scalars['Float']>;
  giftCardsDiscount?: Maybe<Scalars['Float']>;
  promoDiscount?: Maybe<Scalars['Float']>;
  subtotal: Scalars['Float'];
  tax?: Maybe<Scalars['Float']>;
  tip?: Maybe<Scalars['Float']>;
  tipPercent?: Maybe<Scalars['Float']>;
  total: Scalars['Float'];
};

export type KioskOrderConfirmation = {
  __typename?: 'KioskOrderConfirmation';
  order?: Maybe<Order>;
  validation: KioskOrderValidation;
};

export type KioskOrderInput = {
  costs?: Maybe<KioskCosts>;
  fulfillmentAt?: Maybe<Scalars['DateTime']>;
  orderMethod?: Maybe<OrderMethod>;
  orderName?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
};

export type KioskOrderValidation = {
  __typename?: 'KioskOrderValidation';
  costs: OrderCost;
  errors: Array<OrderValidationError>;
  giftCardCodes: Array<Scalars['String']>;
  giftCardDiscounts: Array<Scalars['Float']>;
  promoCodes: Array<Scalars['String']>;
  promoErrors: Array<PromoValidationErrorWithCode>;
  unavailableItemIds: Array<Scalars['ID']>;
};

export type KioskPromoInput = {
  cart: CartInput;
  order: KioskOrderInput;
};

export type Kitchen = {
  __typename?: 'Kitchen';
  color?: Maybe<Scalars['String']>;
  cookTime: Scalars['Int'];
  cuisine?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  enableChowly: Scalars['Boolean'];
  facilityId: Scalars['String'];
  headerImgUrl?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  kitchenMenuItems: Array<KitchenMenuItem>;
  kitchenTicket: Array<KitchenTicket>;
  logoUrl?: Maybe<Scalars['String']>;
  /** Menu item count for kitchen list */
  menuItemCount?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  operationHours: Array<Scalars['Json']>;
  originalLocation?: Maybe<Scalars['String']>;
  owners: Array<User>;
  phone?: Maybe<Scalars['String']>;
  sortId: Scalars['Int'];
  status: KitchenStatus;
  story?: Maybe<Scalars['String']>;
  type: KitchenType;
  users: Array<User>;
  workplace?: Maybe<Workplace>;
};


export type KitchenKitchenMenuItemsArgs = {
  after?: Maybe<KitchenMenuItemWhereUniqueInput>;
  before?: Maybe<KitchenMenuItemWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type KitchenKitchenTicketArgs = {
  after?: Maybe<KitchenTicketWhereUniqueInput>;
  before?: Maybe<KitchenTicketWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type KitchenUsersArgs = {
  after?: Maybe<UserWhereUniqueInput>;
  before?: Maybe<UserWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type KitchenAggregatedReport = {
  __typename?: 'KitchenAggregatedReport';
  itemsCount: Scalars['Int'];
  itemsSold: Array<ItemSalesReport>;
  kitchenId: Scalars['String'];
  kitchenName: Scalars['String'];
  orderCount: Scalars['Int'];
  totalSales: Scalars['Float'];
};

export type KitchenCreateManyFacilityInput = {
  chowlyApiKey?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  cookTime?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  cuisine?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  enableChowly?: Maybe<Scalars['Boolean']>;
  headerImgUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  operationHours?: Maybe<KitchenCreateManyoperationHoursInput>;
  originalLocation?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  sortId?: Maybe<Scalars['Int']>;
  status?: Maybe<KitchenStatus>;
  story?: Maybe<Scalars['String']>;
  type?: Maybe<KitchenType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workplaceId?: Maybe<Scalars['String']>;
};

export type KitchenCreateManyFacilityInputEnvelope = {
  data?: Maybe<Array<KitchenCreateManyFacilityInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type KitchenCreateManyoperationHoursInput = {
  set?: Maybe<Array<Scalars['Json']>>;
};

export type KitchenCreateNestedManyWithoutFacilityInput = {
  connect?: Maybe<Array<KitchenWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<KitchenCreateOrConnectWithoutFacilityInput>>;
  create?: Maybe<Array<KitchenCreateWithoutFacilityInput>>;
  createMany?: Maybe<KitchenCreateManyFacilityInputEnvelope>;
};

export type KitchenCreateNestedManyWithoutPowerBiReportInput = {
  connect?: Maybe<Array<KitchenWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<KitchenCreateOrConnectWithoutPowerBiReportInput>>;
  create?: Maybe<Array<KitchenCreateWithoutPowerBiReportInput>>;
};

export type KitchenCreateNestedManyWithoutPromoFacilityRulesInput = {
  connect?: Maybe<Array<KitchenWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<KitchenCreateOrConnectWithoutPromoFacilityRulesInput>>;
  create?: Maybe<Array<KitchenCreateWithoutPromoFacilityRulesInput>>;
};

export type KitchenCreateNestedManyWithoutUsersInput = {
  connect?: Maybe<Array<KitchenWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<KitchenCreateOrConnectWithoutUsersInput>>;
  create?: Maybe<Array<KitchenCreateWithoutUsersInput>>;
};

export type KitchenCreateNestedOneWithoutExtraInput = {
  connect?: Maybe<KitchenWhereUniqueInput>;
  connectOrCreate?: Maybe<KitchenCreateOrConnectWithoutExtraInput>;
  create?: Maybe<KitchenCreateWithoutExtraInput>;
};

export type KitchenCreateNestedOneWithoutKitchenMenuItemsInput = {
  connect?: Maybe<KitchenWhereUniqueInput>;
  connectOrCreate?: Maybe<KitchenCreateOrConnectWithoutKitchenMenuItemsInput>;
  create?: Maybe<KitchenCreateWithoutKitchenMenuItemsInput>;
};

export type KitchenCreateNestedOneWithoutKitchenTicketInput = {
  connect?: Maybe<KitchenWhereUniqueInput>;
  connectOrCreate?: Maybe<KitchenCreateOrConnectWithoutKitchenTicketInput>;
  create?: Maybe<KitchenCreateWithoutKitchenTicketInput>;
};

export type KitchenCreateNestedOneWithoutMenuCategoriesInput = {
  connect?: Maybe<KitchenWhereUniqueInput>;
  connectOrCreate?: Maybe<KitchenCreateOrConnectWithoutMenuCategoriesInput>;
  create?: Maybe<KitchenCreateWithoutMenuCategoriesInput>;
};

export type KitchenCreateNestedOneWithoutMenuSchedulesInput = {
  connect?: Maybe<KitchenWhereUniqueInput>;
  connectOrCreate?: Maybe<KitchenCreateOrConnectWithoutMenuSchedulesInput>;
  create?: Maybe<KitchenCreateWithoutMenuSchedulesInput>;
};

export type KitchenCreateNestedOneWithoutWorkplaceInput = {
  connect?: Maybe<KitchenWhereUniqueInput>;
  connectOrCreate?: Maybe<KitchenCreateOrConnectWithoutWorkplaceInput>;
  create?: Maybe<KitchenCreateWithoutWorkplaceInput>;
};

export type KitchenCreateOrConnectWithoutExtraInput = {
  create: KitchenCreateWithoutExtraInput;
  where: KitchenWhereUniqueInput;
};

export type KitchenCreateOrConnectWithoutFacilityInput = {
  create: KitchenCreateWithoutFacilityInput;
  where: KitchenWhereUniqueInput;
};

export type KitchenCreateOrConnectWithoutKitchenMenuItemsInput = {
  create: KitchenCreateWithoutKitchenMenuItemsInput;
  where: KitchenWhereUniqueInput;
};

export type KitchenCreateOrConnectWithoutKitchenTicketInput = {
  create: KitchenCreateWithoutKitchenTicketInput;
  where: KitchenWhereUniqueInput;
};

export type KitchenCreateOrConnectWithoutMenuCategoriesInput = {
  create: KitchenCreateWithoutMenuCategoriesInput;
  where: KitchenWhereUniqueInput;
};

export type KitchenCreateOrConnectWithoutMenuSchedulesInput = {
  create: KitchenCreateWithoutMenuSchedulesInput;
  where: KitchenWhereUniqueInput;
};

export type KitchenCreateOrConnectWithoutPowerBiReportInput = {
  create: KitchenCreateWithoutPowerBiReportInput;
  where: KitchenWhereUniqueInput;
};

export type KitchenCreateOrConnectWithoutPromoFacilityRulesInput = {
  create: KitchenCreateWithoutPromoFacilityRulesInput;
  where: KitchenWhereUniqueInput;
};

export type KitchenCreateOrConnectWithoutUsersInput = {
  create: KitchenCreateWithoutUsersInput;
  where: KitchenWhereUniqueInput;
};

export type KitchenCreateOrConnectWithoutWorkplaceInput = {
  create: KitchenCreateWithoutWorkplaceInput;
  where: KitchenWhereUniqueInput;
};

export type KitchenCreateWithoutExtraInput = {
  chowlyApiKey?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  cookTime?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  cuisine?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  enableChowly?: Maybe<Scalars['Boolean']>;
  facility: FacilityCreateNestedOneWithoutKitchensInput;
  headerImgUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  kitchenMenuItems?: Maybe<KitchenMenuItemCreateNestedManyWithoutKitchenInput>;
  kitchenTicket?: Maybe<KitchenTicketCreateNestedManyWithoutKitchenInput>;
  logoUrl?: Maybe<Scalars['String']>;
  menuCategories?: Maybe<MenuCategoryCreateNestedManyWithoutKitchenInput>;
  menuSchedules?: Maybe<MenuScheduleCreateNestedManyWithoutKitchenInput>;
  name: Scalars['String'];
  operationHours?: Maybe<KitchenCreateoperationHoursInput>;
  originalLocation?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  powerBiReport?: Maybe<PowerBiReportCreateNestedManyWithoutAvailableToConceptsInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleCreateNestedManyWithoutKitchensInput>;
  sortId?: Maybe<Scalars['Int']>;
  status?: Maybe<KitchenStatus>;
  story?: Maybe<Scalars['String']>;
  type?: Maybe<KitchenType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserCreateNestedManyWithoutKitchensInput>;
  workplace?: Maybe<WorkplaceCreateNestedOneWithoutKitchenInput>;
};

export type KitchenCreateWithoutFacilityInput = {
  Extra?: Maybe<ExtraCreateNestedManyWithoutKitchenInput>;
  chowlyApiKey?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  cookTime?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  cuisine?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  enableChowly?: Maybe<Scalars['Boolean']>;
  headerImgUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  kitchenMenuItems?: Maybe<KitchenMenuItemCreateNestedManyWithoutKitchenInput>;
  kitchenTicket?: Maybe<KitchenTicketCreateNestedManyWithoutKitchenInput>;
  logoUrl?: Maybe<Scalars['String']>;
  menuCategories?: Maybe<MenuCategoryCreateNestedManyWithoutKitchenInput>;
  menuSchedules?: Maybe<MenuScheduleCreateNestedManyWithoutKitchenInput>;
  name: Scalars['String'];
  operationHours?: Maybe<KitchenCreateoperationHoursInput>;
  originalLocation?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  powerBiReport?: Maybe<PowerBiReportCreateNestedManyWithoutAvailableToConceptsInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleCreateNestedManyWithoutKitchensInput>;
  sortId?: Maybe<Scalars['Int']>;
  status?: Maybe<KitchenStatus>;
  story?: Maybe<Scalars['String']>;
  type?: Maybe<KitchenType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserCreateNestedManyWithoutKitchensInput>;
  workplace?: Maybe<WorkplaceCreateNestedOneWithoutKitchenInput>;
};

export type KitchenCreateWithoutKitchenMenuItemsInput = {
  Extra?: Maybe<ExtraCreateNestedManyWithoutKitchenInput>;
  chowlyApiKey?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  cookTime?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  cuisine?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  enableChowly?: Maybe<Scalars['Boolean']>;
  facility: FacilityCreateNestedOneWithoutKitchensInput;
  headerImgUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  kitchenTicket?: Maybe<KitchenTicketCreateNestedManyWithoutKitchenInput>;
  logoUrl?: Maybe<Scalars['String']>;
  menuCategories?: Maybe<MenuCategoryCreateNestedManyWithoutKitchenInput>;
  menuSchedules?: Maybe<MenuScheduleCreateNestedManyWithoutKitchenInput>;
  name: Scalars['String'];
  operationHours?: Maybe<KitchenCreateoperationHoursInput>;
  originalLocation?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  powerBiReport?: Maybe<PowerBiReportCreateNestedManyWithoutAvailableToConceptsInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleCreateNestedManyWithoutKitchensInput>;
  sortId?: Maybe<Scalars['Int']>;
  status?: Maybe<KitchenStatus>;
  story?: Maybe<Scalars['String']>;
  type?: Maybe<KitchenType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserCreateNestedManyWithoutKitchensInput>;
  workplace?: Maybe<WorkplaceCreateNestedOneWithoutKitchenInput>;
};

export type KitchenCreateWithoutKitchenTicketInput = {
  Extra?: Maybe<ExtraCreateNestedManyWithoutKitchenInput>;
  chowlyApiKey?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  cookTime?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  cuisine?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  enableChowly?: Maybe<Scalars['Boolean']>;
  facility: FacilityCreateNestedOneWithoutKitchensInput;
  headerImgUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  kitchenMenuItems?: Maybe<KitchenMenuItemCreateNestedManyWithoutKitchenInput>;
  logoUrl?: Maybe<Scalars['String']>;
  menuCategories?: Maybe<MenuCategoryCreateNestedManyWithoutKitchenInput>;
  menuSchedules?: Maybe<MenuScheduleCreateNestedManyWithoutKitchenInput>;
  name: Scalars['String'];
  operationHours?: Maybe<KitchenCreateoperationHoursInput>;
  originalLocation?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  powerBiReport?: Maybe<PowerBiReportCreateNestedManyWithoutAvailableToConceptsInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleCreateNestedManyWithoutKitchensInput>;
  sortId?: Maybe<Scalars['Int']>;
  status?: Maybe<KitchenStatus>;
  story?: Maybe<Scalars['String']>;
  type?: Maybe<KitchenType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserCreateNestedManyWithoutKitchensInput>;
  workplace?: Maybe<WorkplaceCreateNestedOneWithoutKitchenInput>;
};

export type KitchenCreateWithoutMenuCategoriesInput = {
  Extra?: Maybe<ExtraCreateNestedManyWithoutKitchenInput>;
  chowlyApiKey?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  cookTime?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  cuisine?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  enableChowly?: Maybe<Scalars['Boolean']>;
  facility: FacilityCreateNestedOneWithoutKitchensInput;
  headerImgUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  kitchenMenuItems?: Maybe<KitchenMenuItemCreateNestedManyWithoutKitchenInput>;
  kitchenTicket?: Maybe<KitchenTicketCreateNestedManyWithoutKitchenInput>;
  logoUrl?: Maybe<Scalars['String']>;
  menuSchedules?: Maybe<MenuScheduleCreateNestedManyWithoutKitchenInput>;
  name: Scalars['String'];
  operationHours?: Maybe<KitchenCreateoperationHoursInput>;
  originalLocation?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  powerBiReport?: Maybe<PowerBiReportCreateNestedManyWithoutAvailableToConceptsInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleCreateNestedManyWithoutKitchensInput>;
  sortId?: Maybe<Scalars['Int']>;
  status?: Maybe<KitchenStatus>;
  story?: Maybe<Scalars['String']>;
  type?: Maybe<KitchenType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserCreateNestedManyWithoutKitchensInput>;
  workplace?: Maybe<WorkplaceCreateNestedOneWithoutKitchenInput>;
};

export type KitchenCreateWithoutMenuSchedulesInput = {
  Extra?: Maybe<ExtraCreateNestedManyWithoutKitchenInput>;
  chowlyApiKey?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  cookTime?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  cuisine?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  enableChowly?: Maybe<Scalars['Boolean']>;
  facility: FacilityCreateNestedOneWithoutKitchensInput;
  headerImgUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  kitchenMenuItems?: Maybe<KitchenMenuItemCreateNestedManyWithoutKitchenInput>;
  kitchenTicket?: Maybe<KitchenTicketCreateNestedManyWithoutKitchenInput>;
  logoUrl?: Maybe<Scalars['String']>;
  menuCategories?: Maybe<MenuCategoryCreateNestedManyWithoutKitchenInput>;
  name: Scalars['String'];
  operationHours?: Maybe<KitchenCreateoperationHoursInput>;
  originalLocation?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  powerBiReport?: Maybe<PowerBiReportCreateNestedManyWithoutAvailableToConceptsInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleCreateNestedManyWithoutKitchensInput>;
  sortId?: Maybe<Scalars['Int']>;
  status?: Maybe<KitchenStatus>;
  story?: Maybe<Scalars['String']>;
  type?: Maybe<KitchenType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserCreateNestedManyWithoutKitchensInput>;
  workplace?: Maybe<WorkplaceCreateNestedOneWithoutKitchenInput>;
};

export type KitchenCreateWithoutPowerBiReportInput = {
  Extra?: Maybe<ExtraCreateNestedManyWithoutKitchenInput>;
  chowlyApiKey?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  cookTime?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  cuisine?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  enableChowly?: Maybe<Scalars['Boolean']>;
  facility: FacilityCreateNestedOneWithoutKitchensInput;
  headerImgUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  kitchenMenuItems?: Maybe<KitchenMenuItemCreateNestedManyWithoutKitchenInput>;
  kitchenTicket?: Maybe<KitchenTicketCreateNestedManyWithoutKitchenInput>;
  logoUrl?: Maybe<Scalars['String']>;
  menuCategories?: Maybe<MenuCategoryCreateNestedManyWithoutKitchenInput>;
  menuSchedules?: Maybe<MenuScheduleCreateNestedManyWithoutKitchenInput>;
  name: Scalars['String'];
  operationHours?: Maybe<KitchenCreateoperationHoursInput>;
  originalLocation?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  promoFacilityRules?: Maybe<PromoFacilityRuleCreateNestedManyWithoutKitchensInput>;
  sortId?: Maybe<Scalars['Int']>;
  status?: Maybe<KitchenStatus>;
  story?: Maybe<Scalars['String']>;
  type?: Maybe<KitchenType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserCreateNestedManyWithoutKitchensInput>;
  workplace?: Maybe<WorkplaceCreateNestedOneWithoutKitchenInput>;
};

export type KitchenCreateWithoutPromoFacilityRulesInput = {
  Extra?: Maybe<ExtraCreateNestedManyWithoutKitchenInput>;
  chowlyApiKey?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  cookTime?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  cuisine?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  enableChowly?: Maybe<Scalars['Boolean']>;
  facility: FacilityCreateNestedOneWithoutKitchensInput;
  headerImgUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  kitchenMenuItems?: Maybe<KitchenMenuItemCreateNestedManyWithoutKitchenInput>;
  kitchenTicket?: Maybe<KitchenTicketCreateNestedManyWithoutKitchenInput>;
  logoUrl?: Maybe<Scalars['String']>;
  menuCategories?: Maybe<MenuCategoryCreateNestedManyWithoutKitchenInput>;
  menuSchedules?: Maybe<MenuScheduleCreateNestedManyWithoutKitchenInput>;
  name: Scalars['String'];
  operationHours?: Maybe<KitchenCreateoperationHoursInput>;
  originalLocation?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  powerBiReport?: Maybe<PowerBiReportCreateNestedManyWithoutAvailableToConceptsInput>;
  sortId?: Maybe<Scalars['Int']>;
  status?: Maybe<KitchenStatus>;
  story?: Maybe<Scalars['String']>;
  type?: Maybe<KitchenType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserCreateNestedManyWithoutKitchensInput>;
  workplace?: Maybe<WorkplaceCreateNestedOneWithoutKitchenInput>;
};

export type KitchenCreateWithoutUsersInput = {
  Extra?: Maybe<ExtraCreateNestedManyWithoutKitchenInput>;
  chowlyApiKey?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  cookTime?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  cuisine?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  enableChowly?: Maybe<Scalars['Boolean']>;
  facility: FacilityCreateNestedOneWithoutKitchensInput;
  headerImgUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  kitchenMenuItems?: Maybe<KitchenMenuItemCreateNestedManyWithoutKitchenInput>;
  kitchenTicket?: Maybe<KitchenTicketCreateNestedManyWithoutKitchenInput>;
  logoUrl?: Maybe<Scalars['String']>;
  menuCategories?: Maybe<MenuCategoryCreateNestedManyWithoutKitchenInput>;
  menuSchedules?: Maybe<MenuScheduleCreateNestedManyWithoutKitchenInput>;
  name: Scalars['String'];
  operationHours?: Maybe<KitchenCreateoperationHoursInput>;
  originalLocation?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  powerBiReport?: Maybe<PowerBiReportCreateNestedManyWithoutAvailableToConceptsInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleCreateNestedManyWithoutKitchensInput>;
  sortId?: Maybe<Scalars['Int']>;
  status?: Maybe<KitchenStatus>;
  story?: Maybe<Scalars['String']>;
  type?: Maybe<KitchenType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workplace?: Maybe<WorkplaceCreateNestedOneWithoutKitchenInput>;
};

export type KitchenCreateWithoutWorkplaceInput = {
  Extra?: Maybe<ExtraCreateNestedManyWithoutKitchenInput>;
  chowlyApiKey?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  cookTime?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  cuisine?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  enableChowly?: Maybe<Scalars['Boolean']>;
  facility: FacilityCreateNestedOneWithoutKitchensInput;
  headerImgUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  kitchenMenuItems?: Maybe<KitchenMenuItemCreateNestedManyWithoutKitchenInput>;
  kitchenTicket?: Maybe<KitchenTicketCreateNestedManyWithoutKitchenInput>;
  logoUrl?: Maybe<Scalars['String']>;
  menuCategories?: Maybe<MenuCategoryCreateNestedManyWithoutKitchenInput>;
  menuSchedules?: Maybe<MenuScheduleCreateNestedManyWithoutKitchenInput>;
  name: Scalars['String'];
  operationHours?: Maybe<KitchenCreateoperationHoursInput>;
  originalLocation?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  powerBiReport?: Maybe<PowerBiReportCreateNestedManyWithoutAvailableToConceptsInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleCreateNestedManyWithoutKitchensInput>;
  sortId?: Maybe<Scalars['Int']>;
  status?: Maybe<KitchenStatus>;
  story?: Maybe<Scalars['String']>;
  type?: Maybe<KitchenType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserCreateNestedManyWithoutKitchensInput>;
};

export type KitchenCreateoperationHoursInput = {
  set?: Maybe<Array<Scalars['Json']>>;
};

export type KitchenListRelationFilter = {
  every?: Maybe<KitchenWhereInput>;
  none?: Maybe<KitchenWhereInput>;
  some?: Maybe<KitchenWhereInput>;
};

export type KitchenMenuItem = {
  __typename?: 'KitchenMenuItem';
  allergies: Array<Allergy>;
  availability: Array<MenuItemAvailability>;
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableTo?: Maybe<Scalars['DateTime']>;
  cookTime?: Maybe<Scalars['Int']>;
  description: Scalars['String'];
  dishTypes: Array<DishType>;
  extras: Array<Extra>;
  facilityItems: Array<FacilityItem>;
  globalSortId: Scalars['Int'];
  hasExtras: Scalars['Boolean'];
  id: Scalars['String'];
  imgUrl?: Maybe<Scalars['String']>;
  inventoryCount: Scalars['Int'];
  /** Number of pre-ordered items */
  inventoryPreSale: Scalars['Int'];
  inventoryStatus: InventoryStatus;
  isRetail: Scalars['Boolean'];
  kitchen: Kitchen;
  labels: Array<MenuItemLabel>;
  longDescription?: Maybe<Scalars['String']>;
  mealPackItems: Array<KitchenMenuItem>;
  mealPackStatus: KitchenMenuItemStatus;
  mealPacks: Array<KitchenMenuItem>;
  menuCategories: Array<MenuCategory>;
  name: Scalars['String'];
  price: Scalars['Float'];
  sortId: Scalars['Int'];
  sortKitchenIds: Array<Scalars['String']>;
  station?: Maybe<Station>;
  stationId?: Maybe<Scalars['String']>;
  status: KitchenMenuItemStatus;
  type: MenuItemType;
};


export type KitchenMenuItemExtrasArgs = {
  after?: Maybe<ExtraWhereUniqueInput>;
  before?: Maybe<ExtraWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type KitchenMenuItemFacilityItemsArgs = {
  after?: Maybe<FacilityItemWhereUniqueInput>;
  before?: Maybe<FacilityItemWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type KitchenMenuItemMealPackItemsArgs = {
  after?: Maybe<KitchenMenuItemWhereUniqueInput>;
  before?: Maybe<KitchenMenuItemWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type KitchenMenuItemMealPacksArgs = {
  after?: Maybe<KitchenMenuItemWhereUniqueInput>;
  before?: Maybe<KitchenMenuItemWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type KitchenMenuItemMenuCategoriesArgs = {
  after?: Maybe<MenuCategoryWhereUniqueInput>;
  before?: Maybe<MenuCategoryWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type KitchenMenuItemCreateInput = {
  allergies?: Maybe<KitchenMenuItemCreateallergiesInput>;
  availability?: Maybe<KitchenMenuItemCreateavailabilityInput>;
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableTo?: Maybe<Scalars['DateTime']>;
  cartItems?: Maybe<CartItemCreateNestedManyWithoutKitchenMenuItemInput>;
  cookTime?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  dishTypes?: Maybe<KitchenMenuItemCreatedishTypesInput>;
  extras?: Maybe<ExtraCreateNestedManyWithoutKitchenMenuItemsInput>;
  facilityItems?: Maybe<FacilityItemCreateNestedManyWithoutMenuItemInput>;
  globalSortId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  imgUrl?: Maybe<Scalars['String']>;
  inventoryCount?: Maybe<Scalars['Int']>;
  inventoryStatus?: Maybe<InventoryStatus>;
  isRetail?: Maybe<Scalars['Boolean']>;
  kitchen: KitchenCreateNestedOneWithoutKitchenMenuItemsInput;
  labels?: Maybe<KitchenMenuItemCreatelabelsInput>;
  longDescription?: Maybe<Scalars['String']>;
  mealPackItems?: Maybe<KitchenMenuItemCreateNestedManyWithoutMealPacksInput>;
  mealPacks?: Maybe<KitchenMenuItemCreateNestedManyWithoutMealPackItemsInput>;
  menuCategories?: Maybe<MenuCategoryCreateNestedManyWithoutMenuItemsInput>;
  menuScheduleCategories?: Maybe<MenuScheduleCategoryCreateNestedManyWithoutMenuItemsInput>;
  name: Scalars['String'];
  orderItems?: Maybe<OrderItemCreateNestedManyWithoutMenuItemInput>;
  price: Scalars['Float'];
  sortId?: Maybe<Scalars['Int']>;
  sortKitchenIds?: Maybe<KitchenMenuItemCreatesortKitchenIdsInput>;
  station?: Maybe<StationCreateNestedOneWithoutKitchenMenuItemsInput>;
  status: KitchenMenuItemStatus;
  type?: Maybe<MenuItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type KitchenMenuItemCreateManyKitchenInput = {
  allergies?: Maybe<KitchenMenuItemCreateManyallergiesInput>;
  availability?: Maybe<KitchenMenuItemCreateManyavailabilityInput>;
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableTo?: Maybe<Scalars['DateTime']>;
  cookTime?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  dishTypes?: Maybe<KitchenMenuItemCreateManydishTypesInput>;
  globalSortId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  imgUrl?: Maybe<Scalars['String']>;
  inventoryCount?: Maybe<Scalars['Int']>;
  inventoryStatus?: Maybe<InventoryStatus>;
  isRetail?: Maybe<Scalars['Boolean']>;
  labels?: Maybe<KitchenMenuItemCreateManylabelsInput>;
  longDescription?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  sortId?: Maybe<Scalars['Int']>;
  sortKitchenIds?: Maybe<KitchenMenuItemCreateManysortKitchenIdsInput>;
  stationId?: Maybe<Scalars['String']>;
  status: KitchenMenuItemStatus;
  type?: Maybe<MenuItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type KitchenMenuItemCreateManyKitchenInputEnvelope = {
  data?: Maybe<Array<KitchenMenuItemCreateManyKitchenInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type KitchenMenuItemCreateManyStationInput = {
  allergies?: Maybe<KitchenMenuItemCreateManyallergiesInput>;
  availability?: Maybe<KitchenMenuItemCreateManyavailabilityInput>;
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableTo?: Maybe<Scalars['DateTime']>;
  cookTime?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  dishTypes?: Maybe<KitchenMenuItemCreateManydishTypesInput>;
  globalSortId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  imgUrl?: Maybe<Scalars['String']>;
  inventoryCount?: Maybe<Scalars['Int']>;
  inventoryStatus?: Maybe<InventoryStatus>;
  isRetail?: Maybe<Scalars['Boolean']>;
  kitchenId: Scalars['String'];
  labels?: Maybe<KitchenMenuItemCreateManylabelsInput>;
  longDescription?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  sortId?: Maybe<Scalars['Int']>;
  sortKitchenIds?: Maybe<KitchenMenuItemCreateManysortKitchenIdsInput>;
  status: KitchenMenuItemStatus;
  type?: Maybe<MenuItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type KitchenMenuItemCreateManyStationInputEnvelope = {
  data?: Maybe<Array<KitchenMenuItemCreateManyStationInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type KitchenMenuItemCreateManyallergiesInput = {
  set?: Maybe<Array<Allergy>>;
};

export type KitchenMenuItemCreateManyavailabilityInput = {
  set?: Maybe<Array<MenuItemAvailability>>;
};

export type KitchenMenuItemCreateManydishTypesInput = {
  set?: Maybe<Array<DishType>>;
};

export type KitchenMenuItemCreateManylabelsInput = {
  set?: Maybe<Array<MenuItemLabel>>;
};

export type KitchenMenuItemCreateManysortKitchenIdsInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type KitchenMenuItemCreateNestedManyWithoutExtrasInput = {
  connect?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<KitchenMenuItemCreateOrConnectWithoutExtrasInput>>;
  create?: Maybe<Array<KitchenMenuItemCreateWithoutExtrasInput>>;
};

export type KitchenMenuItemCreateNestedManyWithoutKitchenInput = {
  connect?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<KitchenMenuItemCreateOrConnectWithoutKitchenInput>>;
  create?: Maybe<Array<KitchenMenuItemCreateWithoutKitchenInput>>;
  createMany?: Maybe<KitchenMenuItemCreateManyKitchenInputEnvelope>;
};

export type KitchenMenuItemCreateNestedManyWithoutMealPackItemsInput = {
  connect?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<KitchenMenuItemCreateOrConnectWithoutMealPackItemsInput>>;
  create?: Maybe<Array<KitchenMenuItemCreateWithoutMealPackItemsInput>>;
};

export type KitchenMenuItemCreateNestedManyWithoutMealPacksInput = {
  connect?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<KitchenMenuItemCreateOrConnectWithoutMealPacksInput>>;
  create?: Maybe<Array<KitchenMenuItemCreateWithoutMealPacksInput>>;
};

export type KitchenMenuItemCreateNestedManyWithoutMenuCategoriesInput = {
  connect?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<KitchenMenuItemCreateOrConnectWithoutMenuCategoriesInput>>;
  create?: Maybe<Array<KitchenMenuItemCreateWithoutMenuCategoriesInput>>;
};

export type KitchenMenuItemCreateNestedManyWithoutMenuScheduleCategoriesInput = {
  connect?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<KitchenMenuItemCreateOrConnectWithoutMenuScheduleCategoriesInput>>;
  create?: Maybe<Array<KitchenMenuItemCreateWithoutMenuScheduleCategoriesInput>>;
};

export type KitchenMenuItemCreateNestedManyWithoutStationInput = {
  connect?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<KitchenMenuItemCreateOrConnectWithoutStationInput>>;
  create?: Maybe<Array<KitchenMenuItemCreateWithoutStationInput>>;
  createMany?: Maybe<KitchenMenuItemCreateManyStationInputEnvelope>;
};

export type KitchenMenuItemCreateNestedOneWithoutCartItemsInput = {
  connect?: Maybe<KitchenMenuItemWhereUniqueInput>;
  connectOrCreate?: Maybe<KitchenMenuItemCreateOrConnectWithoutCartItemsInput>;
  create?: Maybe<KitchenMenuItemCreateWithoutCartItemsInput>;
};

export type KitchenMenuItemCreateNestedOneWithoutFacilityItemsInput = {
  connect?: Maybe<KitchenMenuItemWhereUniqueInput>;
  connectOrCreate?: Maybe<KitchenMenuItemCreateOrConnectWithoutFacilityItemsInput>;
  create?: Maybe<KitchenMenuItemCreateWithoutFacilityItemsInput>;
};

export type KitchenMenuItemCreateNestedOneWithoutOrderItemsInput = {
  connect?: Maybe<KitchenMenuItemWhereUniqueInput>;
  connectOrCreate?: Maybe<KitchenMenuItemCreateOrConnectWithoutOrderItemsInput>;
  create?: Maybe<KitchenMenuItemCreateWithoutOrderItemsInput>;
};

export type KitchenMenuItemCreateOrConnectWithoutCartItemsInput = {
  create: KitchenMenuItemCreateWithoutCartItemsInput;
  where: KitchenMenuItemWhereUniqueInput;
};

export type KitchenMenuItemCreateOrConnectWithoutExtrasInput = {
  create: KitchenMenuItemCreateWithoutExtrasInput;
  where: KitchenMenuItemWhereUniqueInput;
};

export type KitchenMenuItemCreateOrConnectWithoutFacilityItemsInput = {
  create: KitchenMenuItemCreateWithoutFacilityItemsInput;
  where: KitchenMenuItemWhereUniqueInput;
};

export type KitchenMenuItemCreateOrConnectWithoutKitchenInput = {
  create: KitchenMenuItemCreateWithoutKitchenInput;
  where: KitchenMenuItemWhereUniqueInput;
};

export type KitchenMenuItemCreateOrConnectWithoutMealPackItemsInput = {
  create: KitchenMenuItemCreateWithoutMealPackItemsInput;
  where: KitchenMenuItemWhereUniqueInput;
};

export type KitchenMenuItemCreateOrConnectWithoutMealPacksInput = {
  create: KitchenMenuItemCreateWithoutMealPacksInput;
  where: KitchenMenuItemWhereUniqueInput;
};

export type KitchenMenuItemCreateOrConnectWithoutMenuCategoriesInput = {
  create: KitchenMenuItemCreateWithoutMenuCategoriesInput;
  where: KitchenMenuItemWhereUniqueInput;
};

export type KitchenMenuItemCreateOrConnectWithoutMenuScheduleCategoriesInput = {
  create: KitchenMenuItemCreateWithoutMenuScheduleCategoriesInput;
  where: KitchenMenuItemWhereUniqueInput;
};

export type KitchenMenuItemCreateOrConnectWithoutOrderItemsInput = {
  create: KitchenMenuItemCreateWithoutOrderItemsInput;
  where: KitchenMenuItemWhereUniqueInput;
};

export type KitchenMenuItemCreateOrConnectWithoutStationInput = {
  create: KitchenMenuItemCreateWithoutStationInput;
  where: KitchenMenuItemWhereUniqueInput;
};

export type KitchenMenuItemCreateWithoutCartItemsInput = {
  allergies?: Maybe<KitchenMenuItemCreateallergiesInput>;
  availability?: Maybe<KitchenMenuItemCreateavailabilityInput>;
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableTo?: Maybe<Scalars['DateTime']>;
  cookTime?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  dishTypes?: Maybe<KitchenMenuItemCreatedishTypesInput>;
  extras?: Maybe<ExtraCreateNestedManyWithoutKitchenMenuItemsInput>;
  facilityItems?: Maybe<FacilityItemCreateNestedManyWithoutMenuItemInput>;
  globalSortId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  imgUrl?: Maybe<Scalars['String']>;
  inventoryCount?: Maybe<Scalars['Int']>;
  inventoryStatus?: Maybe<InventoryStatus>;
  isRetail?: Maybe<Scalars['Boolean']>;
  kitchen: KitchenCreateNestedOneWithoutKitchenMenuItemsInput;
  labels?: Maybe<KitchenMenuItemCreatelabelsInput>;
  longDescription?: Maybe<Scalars['String']>;
  mealPackItems?: Maybe<KitchenMenuItemCreateNestedManyWithoutMealPacksInput>;
  mealPacks?: Maybe<KitchenMenuItemCreateNestedManyWithoutMealPackItemsInput>;
  menuCategories?: Maybe<MenuCategoryCreateNestedManyWithoutMenuItemsInput>;
  menuScheduleCategories?: Maybe<MenuScheduleCategoryCreateNestedManyWithoutMenuItemsInput>;
  name: Scalars['String'];
  orderItems?: Maybe<OrderItemCreateNestedManyWithoutMenuItemInput>;
  price: Scalars['Float'];
  sortId?: Maybe<Scalars['Int']>;
  sortKitchenIds?: Maybe<KitchenMenuItemCreatesortKitchenIdsInput>;
  station?: Maybe<StationCreateNestedOneWithoutKitchenMenuItemsInput>;
  status: KitchenMenuItemStatus;
  type?: Maybe<MenuItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type KitchenMenuItemCreateWithoutExtrasInput = {
  allergies?: Maybe<KitchenMenuItemCreateallergiesInput>;
  availability?: Maybe<KitchenMenuItemCreateavailabilityInput>;
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableTo?: Maybe<Scalars['DateTime']>;
  cartItems?: Maybe<CartItemCreateNestedManyWithoutKitchenMenuItemInput>;
  cookTime?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  dishTypes?: Maybe<KitchenMenuItemCreatedishTypesInput>;
  facilityItems?: Maybe<FacilityItemCreateNestedManyWithoutMenuItemInput>;
  globalSortId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  imgUrl?: Maybe<Scalars['String']>;
  inventoryCount?: Maybe<Scalars['Int']>;
  inventoryStatus?: Maybe<InventoryStatus>;
  isRetail?: Maybe<Scalars['Boolean']>;
  kitchen: KitchenCreateNestedOneWithoutKitchenMenuItemsInput;
  labels?: Maybe<KitchenMenuItemCreatelabelsInput>;
  longDescription?: Maybe<Scalars['String']>;
  mealPackItems?: Maybe<KitchenMenuItemCreateNestedManyWithoutMealPacksInput>;
  mealPacks?: Maybe<KitchenMenuItemCreateNestedManyWithoutMealPackItemsInput>;
  menuCategories?: Maybe<MenuCategoryCreateNestedManyWithoutMenuItemsInput>;
  menuScheduleCategories?: Maybe<MenuScheduleCategoryCreateNestedManyWithoutMenuItemsInput>;
  name: Scalars['String'];
  orderItems?: Maybe<OrderItemCreateNestedManyWithoutMenuItemInput>;
  price: Scalars['Float'];
  sortId?: Maybe<Scalars['Int']>;
  sortKitchenIds?: Maybe<KitchenMenuItemCreatesortKitchenIdsInput>;
  station?: Maybe<StationCreateNestedOneWithoutKitchenMenuItemsInput>;
  status: KitchenMenuItemStatus;
  type?: Maybe<MenuItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type KitchenMenuItemCreateWithoutFacilityItemsInput = {
  allergies?: Maybe<KitchenMenuItemCreateallergiesInput>;
  availability?: Maybe<KitchenMenuItemCreateavailabilityInput>;
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableTo?: Maybe<Scalars['DateTime']>;
  cartItems?: Maybe<CartItemCreateNestedManyWithoutKitchenMenuItemInput>;
  cookTime?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  dishTypes?: Maybe<KitchenMenuItemCreatedishTypesInput>;
  extras?: Maybe<ExtraCreateNestedManyWithoutKitchenMenuItemsInput>;
  globalSortId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  imgUrl?: Maybe<Scalars['String']>;
  inventoryCount?: Maybe<Scalars['Int']>;
  inventoryStatus?: Maybe<InventoryStatus>;
  isRetail?: Maybe<Scalars['Boolean']>;
  kitchen: KitchenCreateNestedOneWithoutKitchenMenuItemsInput;
  labels?: Maybe<KitchenMenuItemCreatelabelsInput>;
  longDescription?: Maybe<Scalars['String']>;
  mealPackItems?: Maybe<KitchenMenuItemCreateNestedManyWithoutMealPacksInput>;
  mealPacks?: Maybe<KitchenMenuItemCreateNestedManyWithoutMealPackItemsInput>;
  menuCategories?: Maybe<MenuCategoryCreateNestedManyWithoutMenuItemsInput>;
  menuScheduleCategories?: Maybe<MenuScheduleCategoryCreateNestedManyWithoutMenuItemsInput>;
  name: Scalars['String'];
  orderItems?: Maybe<OrderItemCreateNestedManyWithoutMenuItemInput>;
  price: Scalars['Float'];
  sortId?: Maybe<Scalars['Int']>;
  sortKitchenIds?: Maybe<KitchenMenuItemCreatesortKitchenIdsInput>;
  station?: Maybe<StationCreateNestedOneWithoutKitchenMenuItemsInput>;
  status: KitchenMenuItemStatus;
  type?: Maybe<MenuItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type KitchenMenuItemCreateWithoutKitchenInput = {
  allergies?: Maybe<KitchenMenuItemCreateallergiesInput>;
  availability?: Maybe<KitchenMenuItemCreateavailabilityInput>;
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableTo?: Maybe<Scalars['DateTime']>;
  cartItems?: Maybe<CartItemCreateNestedManyWithoutKitchenMenuItemInput>;
  cookTime?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  dishTypes?: Maybe<KitchenMenuItemCreatedishTypesInput>;
  extras?: Maybe<ExtraCreateNestedManyWithoutKitchenMenuItemsInput>;
  facilityItems?: Maybe<FacilityItemCreateNestedManyWithoutMenuItemInput>;
  globalSortId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  imgUrl?: Maybe<Scalars['String']>;
  inventoryCount?: Maybe<Scalars['Int']>;
  inventoryStatus?: Maybe<InventoryStatus>;
  isRetail?: Maybe<Scalars['Boolean']>;
  labels?: Maybe<KitchenMenuItemCreatelabelsInput>;
  longDescription?: Maybe<Scalars['String']>;
  mealPackItems?: Maybe<KitchenMenuItemCreateNestedManyWithoutMealPacksInput>;
  mealPacks?: Maybe<KitchenMenuItemCreateNestedManyWithoutMealPackItemsInput>;
  menuCategories?: Maybe<MenuCategoryCreateNestedManyWithoutMenuItemsInput>;
  menuScheduleCategories?: Maybe<MenuScheduleCategoryCreateNestedManyWithoutMenuItemsInput>;
  name: Scalars['String'];
  orderItems?: Maybe<OrderItemCreateNestedManyWithoutMenuItemInput>;
  price: Scalars['Float'];
  sortId?: Maybe<Scalars['Int']>;
  sortKitchenIds?: Maybe<KitchenMenuItemCreatesortKitchenIdsInput>;
  station?: Maybe<StationCreateNestedOneWithoutKitchenMenuItemsInput>;
  status: KitchenMenuItemStatus;
  type?: Maybe<MenuItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type KitchenMenuItemCreateWithoutMealPackItemsInput = {
  allergies?: Maybe<KitchenMenuItemCreateallergiesInput>;
  availability?: Maybe<KitchenMenuItemCreateavailabilityInput>;
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableTo?: Maybe<Scalars['DateTime']>;
  cartItems?: Maybe<CartItemCreateNestedManyWithoutKitchenMenuItemInput>;
  cookTime?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  dishTypes?: Maybe<KitchenMenuItemCreatedishTypesInput>;
  extras?: Maybe<ExtraCreateNestedManyWithoutKitchenMenuItemsInput>;
  facilityItems?: Maybe<FacilityItemCreateNestedManyWithoutMenuItemInput>;
  globalSortId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  imgUrl?: Maybe<Scalars['String']>;
  inventoryCount?: Maybe<Scalars['Int']>;
  inventoryStatus?: Maybe<InventoryStatus>;
  isRetail?: Maybe<Scalars['Boolean']>;
  kitchen: KitchenCreateNestedOneWithoutKitchenMenuItemsInput;
  labels?: Maybe<KitchenMenuItemCreatelabelsInput>;
  longDescription?: Maybe<Scalars['String']>;
  mealPacks?: Maybe<KitchenMenuItemCreateNestedManyWithoutMealPackItemsInput>;
  menuCategories?: Maybe<MenuCategoryCreateNestedManyWithoutMenuItemsInput>;
  menuScheduleCategories?: Maybe<MenuScheduleCategoryCreateNestedManyWithoutMenuItemsInput>;
  name: Scalars['String'];
  orderItems?: Maybe<OrderItemCreateNestedManyWithoutMenuItemInput>;
  price: Scalars['Float'];
  sortId?: Maybe<Scalars['Int']>;
  sortKitchenIds?: Maybe<KitchenMenuItemCreatesortKitchenIdsInput>;
  station?: Maybe<StationCreateNestedOneWithoutKitchenMenuItemsInput>;
  status: KitchenMenuItemStatus;
  type?: Maybe<MenuItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type KitchenMenuItemCreateWithoutMealPacksInput = {
  allergies?: Maybe<KitchenMenuItemCreateallergiesInput>;
  availability?: Maybe<KitchenMenuItemCreateavailabilityInput>;
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableTo?: Maybe<Scalars['DateTime']>;
  cartItems?: Maybe<CartItemCreateNestedManyWithoutKitchenMenuItemInput>;
  cookTime?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  dishTypes?: Maybe<KitchenMenuItemCreatedishTypesInput>;
  extras?: Maybe<ExtraCreateNestedManyWithoutKitchenMenuItemsInput>;
  facilityItems?: Maybe<FacilityItemCreateNestedManyWithoutMenuItemInput>;
  globalSortId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  imgUrl?: Maybe<Scalars['String']>;
  inventoryCount?: Maybe<Scalars['Int']>;
  inventoryStatus?: Maybe<InventoryStatus>;
  isRetail?: Maybe<Scalars['Boolean']>;
  kitchen: KitchenCreateNestedOneWithoutKitchenMenuItemsInput;
  labels?: Maybe<KitchenMenuItemCreatelabelsInput>;
  longDescription?: Maybe<Scalars['String']>;
  mealPackItems?: Maybe<KitchenMenuItemCreateNestedManyWithoutMealPacksInput>;
  menuCategories?: Maybe<MenuCategoryCreateNestedManyWithoutMenuItemsInput>;
  menuScheduleCategories?: Maybe<MenuScheduleCategoryCreateNestedManyWithoutMenuItemsInput>;
  name: Scalars['String'];
  orderItems?: Maybe<OrderItemCreateNestedManyWithoutMenuItemInput>;
  price: Scalars['Float'];
  sortId?: Maybe<Scalars['Int']>;
  sortKitchenIds?: Maybe<KitchenMenuItemCreatesortKitchenIdsInput>;
  station?: Maybe<StationCreateNestedOneWithoutKitchenMenuItemsInput>;
  status: KitchenMenuItemStatus;
  type?: Maybe<MenuItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type KitchenMenuItemCreateWithoutMenuCategoriesInput = {
  allergies?: Maybe<KitchenMenuItemCreateallergiesInput>;
  availability?: Maybe<KitchenMenuItemCreateavailabilityInput>;
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableTo?: Maybe<Scalars['DateTime']>;
  cartItems?: Maybe<CartItemCreateNestedManyWithoutKitchenMenuItemInput>;
  cookTime?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  dishTypes?: Maybe<KitchenMenuItemCreatedishTypesInput>;
  extras?: Maybe<ExtraCreateNestedManyWithoutKitchenMenuItemsInput>;
  facilityItems?: Maybe<FacilityItemCreateNestedManyWithoutMenuItemInput>;
  globalSortId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  imgUrl?: Maybe<Scalars['String']>;
  inventoryCount?: Maybe<Scalars['Int']>;
  inventoryStatus?: Maybe<InventoryStatus>;
  isRetail?: Maybe<Scalars['Boolean']>;
  kitchen: KitchenCreateNestedOneWithoutKitchenMenuItemsInput;
  labels?: Maybe<KitchenMenuItemCreatelabelsInput>;
  longDescription?: Maybe<Scalars['String']>;
  mealPackItems?: Maybe<KitchenMenuItemCreateNestedManyWithoutMealPacksInput>;
  mealPacks?: Maybe<KitchenMenuItemCreateNestedManyWithoutMealPackItemsInput>;
  menuScheduleCategories?: Maybe<MenuScheduleCategoryCreateNestedManyWithoutMenuItemsInput>;
  name: Scalars['String'];
  orderItems?: Maybe<OrderItemCreateNestedManyWithoutMenuItemInput>;
  price: Scalars['Float'];
  sortId?: Maybe<Scalars['Int']>;
  sortKitchenIds?: Maybe<KitchenMenuItemCreatesortKitchenIdsInput>;
  station?: Maybe<StationCreateNestedOneWithoutKitchenMenuItemsInput>;
  status: KitchenMenuItemStatus;
  type?: Maybe<MenuItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type KitchenMenuItemCreateWithoutMenuScheduleCategoriesInput = {
  allergies?: Maybe<KitchenMenuItemCreateallergiesInput>;
  availability?: Maybe<KitchenMenuItemCreateavailabilityInput>;
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableTo?: Maybe<Scalars['DateTime']>;
  cartItems?: Maybe<CartItemCreateNestedManyWithoutKitchenMenuItemInput>;
  cookTime?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  dishTypes?: Maybe<KitchenMenuItemCreatedishTypesInput>;
  extras?: Maybe<ExtraCreateNestedManyWithoutKitchenMenuItemsInput>;
  facilityItems?: Maybe<FacilityItemCreateNestedManyWithoutMenuItemInput>;
  globalSortId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  imgUrl?: Maybe<Scalars['String']>;
  inventoryCount?: Maybe<Scalars['Int']>;
  inventoryStatus?: Maybe<InventoryStatus>;
  isRetail?: Maybe<Scalars['Boolean']>;
  kitchen: KitchenCreateNestedOneWithoutKitchenMenuItemsInput;
  labels?: Maybe<KitchenMenuItemCreatelabelsInput>;
  longDescription?: Maybe<Scalars['String']>;
  mealPackItems?: Maybe<KitchenMenuItemCreateNestedManyWithoutMealPacksInput>;
  mealPacks?: Maybe<KitchenMenuItemCreateNestedManyWithoutMealPackItemsInput>;
  menuCategories?: Maybe<MenuCategoryCreateNestedManyWithoutMenuItemsInput>;
  name: Scalars['String'];
  orderItems?: Maybe<OrderItemCreateNestedManyWithoutMenuItemInput>;
  price: Scalars['Float'];
  sortId?: Maybe<Scalars['Int']>;
  sortKitchenIds?: Maybe<KitchenMenuItemCreatesortKitchenIdsInput>;
  station?: Maybe<StationCreateNestedOneWithoutKitchenMenuItemsInput>;
  status: KitchenMenuItemStatus;
  type?: Maybe<MenuItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type KitchenMenuItemCreateWithoutOrderItemsInput = {
  allergies?: Maybe<KitchenMenuItemCreateallergiesInput>;
  availability?: Maybe<KitchenMenuItemCreateavailabilityInput>;
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableTo?: Maybe<Scalars['DateTime']>;
  cartItems?: Maybe<CartItemCreateNestedManyWithoutKitchenMenuItemInput>;
  cookTime?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  dishTypes?: Maybe<KitchenMenuItemCreatedishTypesInput>;
  extras?: Maybe<ExtraCreateNestedManyWithoutKitchenMenuItemsInput>;
  facilityItems?: Maybe<FacilityItemCreateNestedManyWithoutMenuItemInput>;
  globalSortId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  imgUrl?: Maybe<Scalars['String']>;
  inventoryCount?: Maybe<Scalars['Int']>;
  inventoryStatus?: Maybe<InventoryStatus>;
  isRetail?: Maybe<Scalars['Boolean']>;
  kitchen: KitchenCreateNestedOneWithoutKitchenMenuItemsInput;
  labels?: Maybe<KitchenMenuItemCreatelabelsInput>;
  longDescription?: Maybe<Scalars['String']>;
  mealPackItems?: Maybe<KitchenMenuItemCreateNestedManyWithoutMealPacksInput>;
  mealPacks?: Maybe<KitchenMenuItemCreateNestedManyWithoutMealPackItemsInput>;
  menuCategories?: Maybe<MenuCategoryCreateNestedManyWithoutMenuItemsInput>;
  menuScheduleCategories?: Maybe<MenuScheduleCategoryCreateNestedManyWithoutMenuItemsInput>;
  name: Scalars['String'];
  price: Scalars['Float'];
  sortId?: Maybe<Scalars['Int']>;
  sortKitchenIds?: Maybe<KitchenMenuItemCreatesortKitchenIdsInput>;
  station?: Maybe<StationCreateNestedOneWithoutKitchenMenuItemsInput>;
  status: KitchenMenuItemStatus;
  type?: Maybe<MenuItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type KitchenMenuItemCreateWithoutStationInput = {
  allergies?: Maybe<KitchenMenuItemCreateallergiesInput>;
  availability?: Maybe<KitchenMenuItemCreateavailabilityInput>;
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableTo?: Maybe<Scalars['DateTime']>;
  cartItems?: Maybe<CartItemCreateNestedManyWithoutKitchenMenuItemInput>;
  cookTime?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  dishTypes?: Maybe<KitchenMenuItemCreatedishTypesInput>;
  extras?: Maybe<ExtraCreateNestedManyWithoutKitchenMenuItemsInput>;
  facilityItems?: Maybe<FacilityItemCreateNestedManyWithoutMenuItemInput>;
  globalSortId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  imgUrl?: Maybe<Scalars['String']>;
  inventoryCount?: Maybe<Scalars['Int']>;
  inventoryStatus?: Maybe<InventoryStatus>;
  isRetail?: Maybe<Scalars['Boolean']>;
  kitchen: KitchenCreateNestedOneWithoutKitchenMenuItemsInput;
  labels?: Maybe<KitchenMenuItemCreatelabelsInput>;
  longDescription?: Maybe<Scalars['String']>;
  mealPackItems?: Maybe<KitchenMenuItemCreateNestedManyWithoutMealPacksInput>;
  mealPacks?: Maybe<KitchenMenuItemCreateNestedManyWithoutMealPackItemsInput>;
  menuCategories?: Maybe<MenuCategoryCreateNestedManyWithoutMenuItemsInput>;
  menuScheduleCategories?: Maybe<MenuScheduleCategoryCreateNestedManyWithoutMenuItemsInput>;
  name: Scalars['String'];
  orderItems?: Maybe<OrderItemCreateNestedManyWithoutMenuItemInput>;
  price: Scalars['Float'];
  sortId?: Maybe<Scalars['Int']>;
  sortKitchenIds?: Maybe<KitchenMenuItemCreatesortKitchenIdsInput>;
  status: KitchenMenuItemStatus;
  type?: Maybe<MenuItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type KitchenMenuItemCreateallergiesInput = {
  set?: Maybe<Array<Allergy>>;
};

export type KitchenMenuItemCreateavailabilityInput = {
  set?: Maybe<Array<MenuItemAvailability>>;
};

export type KitchenMenuItemCreatedishTypesInput = {
  set?: Maybe<Array<DishType>>;
};

export type KitchenMenuItemCreatelabelsInput = {
  set?: Maybe<Array<MenuItemLabel>>;
};

export type KitchenMenuItemCreatesortKitchenIdsInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type KitchenMenuItemInput = {
  extraItems?: Maybe<Array<Maybe<CartExtraItemInput>>>;
  id: Scalars['ID'];
  quantity?: Maybe<Scalars['Int']>;
};

export type KitchenMenuItemListRelationFilter = {
  every?: Maybe<KitchenMenuItemWhereInput>;
  none?: Maybe<KitchenMenuItemWhereInput>;
  some?: Maybe<KitchenMenuItemWhereInput>;
};

export type KitchenMenuItemScalarWhereInput = {
  AND?: Maybe<Array<KitchenMenuItemScalarWhereInput>>;
  NOT?: Maybe<Array<KitchenMenuItemScalarWhereInput>>;
  OR?: Maybe<Array<KitchenMenuItemScalarWhereInput>>;
  allergies?: Maybe<EnumAllergyNullableListFilter>;
  availability?: Maybe<EnumMenuItemAvailabilityNullableListFilter>;
  availableFrom?: Maybe<DateTimeNullableFilter>;
  availableTo?: Maybe<DateTimeNullableFilter>;
  cookTime?: Maybe<IntNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringFilter>;
  dishTypes?: Maybe<EnumDishTypeNullableListFilter>;
  globalSortId?: Maybe<IntFilter>;
  id?: Maybe<StringFilter>;
  imgUrl?: Maybe<StringNullableFilter>;
  inventoryCount?: Maybe<IntFilter>;
  inventoryStatus?: Maybe<EnumInventoryStatusFilter>;
  isRetail?: Maybe<BoolFilter>;
  kitchenId?: Maybe<StringFilter>;
  labels?: Maybe<EnumMenuItemLabelNullableListFilter>;
  longDescription?: Maybe<StringNullableFilter>;
  name?: Maybe<StringFilter>;
  price?: Maybe<FloatFilter>;
  sortId?: Maybe<IntFilter>;
  sortKitchenIds?: Maybe<StringNullableListFilter>;
  stationId?: Maybe<StringNullableFilter>;
  status?: Maybe<EnumKitchenMenuItemStatusFilter>;
  type?: Maybe<EnumMenuItemTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export enum KitchenMenuItemStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  Inactive = 'INACTIVE'
}

export type KitchenMenuItemUpdateInput = {
  allergies?: Maybe<KitchenMenuItemUpdateallergiesInput>;
  availability?: Maybe<KitchenMenuItemUpdateavailabilityInput>;
  availableFrom?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  availableTo?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cartItems?: Maybe<CartItemUpdateManyWithoutKitchenMenuItemInput>;
  cookTime?: Maybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  dishTypes?: Maybe<KitchenMenuItemUpdatedishTypesInput>;
  extras?: Maybe<ExtraUpdateManyWithoutKitchenMenuItemsInput>;
  facilityItems?: Maybe<FacilityItemUpdateManyWithoutMenuItemInput>;
  globalSortId?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  imgUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  inventoryCount?: Maybe<IntFieldUpdateOperationsInput>;
  inventoryStatus?: Maybe<EnumInventoryStatusFieldUpdateOperationsInput>;
  isRetail?: Maybe<BoolFieldUpdateOperationsInput>;
  kitchen?: Maybe<KitchenUpdateOneRequiredWithoutKitchenMenuItemsInput>;
  labels?: Maybe<KitchenMenuItemUpdatelabelsInput>;
  longDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mealPackItems?: Maybe<KitchenMenuItemUpdateManyWithoutMealPacksInput>;
  mealPacks?: Maybe<KitchenMenuItemUpdateManyWithoutMealPackItemsInput>;
  menuCategories?: Maybe<MenuCategoryUpdateManyWithoutMenuItemsInput>;
  menuScheduleCategories?: Maybe<MenuScheduleCategoryUpdateManyWithoutMenuItemsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  orderItems?: Maybe<OrderItemUpdateManyWithoutMenuItemInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  sortKitchenIds?: Maybe<KitchenMenuItemUpdatesortKitchenIdsInput>;
  station?: Maybe<StationUpdateOneWithoutKitchenMenuItemsInput>;
  status?: Maybe<EnumKitchenMenuItemStatusFieldUpdateOperationsInput>;
  type?: Maybe<EnumMenuItemTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type KitchenMenuItemUpdateManyMutationInput = {
  allergies?: Maybe<KitchenMenuItemUpdateallergiesInput>;
  availability?: Maybe<KitchenMenuItemUpdateavailabilityInput>;
  availableFrom?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  availableTo?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cookTime?: Maybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  dishTypes?: Maybe<KitchenMenuItemUpdatedishTypesInput>;
  globalSortId?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  imgUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  inventoryCount?: Maybe<IntFieldUpdateOperationsInput>;
  inventoryStatus?: Maybe<EnumInventoryStatusFieldUpdateOperationsInput>;
  isRetail?: Maybe<BoolFieldUpdateOperationsInput>;
  labels?: Maybe<KitchenMenuItemUpdatelabelsInput>;
  longDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  sortKitchenIds?: Maybe<KitchenMenuItemUpdatesortKitchenIdsInput>;
  status?: Maybe<EnumKitchenMenuItemStatusFieldUpdateOperationsInput>;
  type?: Maybe<EnumMenuItemTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type KitchenMenuItemUpdateManyWithWhereWithoutExtrasInput = {
  data: KitchenMenuItemUpdateManyMutationInput;
  where: KitchenMenuItemScalarWhereInput;
};

export type KitchenMenuItemUpdateManyWithWhereWithoutKitchenInput = {
  data: KitchenMenuItemUpdateManyMutationInput;
  where: KitchenMenuItemScalarWhereInput;
};

export type KitchenMenuItemUpdateManyWithWhereWithoutMealPackItemsInput = {
  data: KitchenMenuItemUpdateManyMutationInput;
  where: KitchenMenuItemScalarWhereInput;
};

export type KitchenMenuItemUpdateManyWithWhereWithoutMealPacksInput = {
  data: KitchenMenuItemUpdateManyMutationInput;
  where: KitchenMenuItemScalarWhereInput;
};

export type KitchenMenuItemUpdateManyWithWhereWithoutMenuCategoriesInput = {
  data: KitchenMenuItemUpdateManyMutationInput;
  where: KitchenMenuItemScalarWhereInput;
};

export type KitchenMenuItemUpdateManyWithWhereWithoutMenuScheduleCategoriesInput = {
  data: KitchenMenuItemUpdateManyMutationInput;
  where: KitchenMenuItemScalarWhereInput;
};

export type KitchenMenuItemUpdateManyWithWhereWithoutStationInput = {
  data: KitchenMenuItemUpdateManyMutationInput;
  where: KitchenMenuItemScalarWhereInput;
};

export type KitchenMenuItemUpdateManyWithoutExtrasInput = {
  connect?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<KitchenMenuItemCreateOrConnectWithoutExtrasInput>>;
  create?: Maybe<Array<KitchenMenuItemCreateWithoutExtrasInput>>;
  delete?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<KitchenMenuItemScalarWhereInput>>;
  disconnect?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  set?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  update?: Maybe<Array<KitchenMenuItemUpdateWithWhereUniqueWithoutExtrasInput>>;
  updateMany?: Maybe<Array<KitchenMenuItemUpdateManyWithWhereWithoutExtrasInput>>;
  upsert?: Maybe<Array<KitchenMenuItemUpsertWithWhereUniqueWithoutExtrasInput>>;
};

export type KitchenMenuItemUpdateManyWithoutKitchenInput = {
  connect?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<KitchenMenuItemCreateOrConnectWithoutKitchenInput>>;
  create?: Maybe<Array<KitchenMenuItemCreateWithoutKitchenInput>>;
  createMany?: Maybe<KitchenMenuItemCreateManyKitchenInputEnvelope>;
  delete?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<KitchenMenuItemScalarWhereInput>>;
  disconnect?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  set?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  update?: Maybe<Array<KitchenMenuItemUpdateWithWhereUniqueWithoutKitchenInput>>;
  updateMany?: Maybe<Array<KitchenMenuItemUpdateManyWithWhereWithoutKitchenInput>>;
  upsert?: Maybe<Array<KitchenMenuItemUpsertWithWhereUniqueWithoutKitchenInput>>;
};

export type KitchenMenuItemUpdateManyWithoutMealPackItemsInput = {
  connect?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<KitchenMenuItemCreateOrConnectWithoutMealPackItemsInput>>;
  create?: Maybe<Array<KitchenMenuItemCreateWithoutMealPackItemsInput>>;
  delete?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<KitchenMenuItemScalarWhereInput>>;
  disconnect?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  set?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  update?: Maybe<Array<KitchenMenuItemUpdateWithWhereUniqueWithoutMealPackItemsInput>>;
  updateMany?: Maybe<Array<KitchenMenuItemUpdateManyWithWhereWithoutMealPackItemsInput>>;
  upsert?: Maybe<Array<KitchenMenuItemUpsertWithWhereUniqueWithoutMealPackItemsInput>>;
};

export type KitchenMenuItemUpdateManyWithoutMealPacksInput = {
  connect?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<KitchenMenuItemCreateOrConnectWithoutMealPacksInput>>;
  create?: Maybe<Array<KitchenMenuItemCreateWithoutMealPacksInput>>;
  delete?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<KitchenMenuItemScalarWhereInput>>;
  disconnect?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  set?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  update?: Maybe<Array<KitchenMenuItemUpdateWithWhereUniqueWithoutMealPacksInput>>;
  updateMany?: Maybe<Array<KitchenMenuItemUpdateManyWithWhereWithoutMealPacksInput>>;
  upsert?: Maybe<Array<KitchenMenuItemUpsertWithWhereUniqueWithoutMealPacksInput>>;
};

export type KitchenMenuItemUpdateManyWithoutMenuCategoriesInput = {
  connect?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<KitchenMenuItemCreateOrConnectWithoutMenuCategoriesInput>>;
  create?: Maybe<Array<KitchenMenuItemCreateWithoutMenuCategoriesInput>>;
  delete?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<KitchenMenuItemScalarWhereInput>>;
  disconnect?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  set?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  update?: Maybe<Array<KitchenMenuItemUpdateWithWhereUniqueWithoutMenuCategoriesInput>>;
  updateMany?: Maybe<Array<KitchenMenuItemUpdateManyWithWhereWithoutMenuCategoriesInput>>;
  upsert?: Maybe<Array<KitchenMenuItemUpsertWithWhereUniqueWithoutMenuCategoriesInput>>;
};

export type KitchenMenuItemUpdateManyWithoutMenuScheduleCategoriesInput = {
  connect?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<KitchenMenuItemCreateOrConnectWithoutMenuScheduleCategoriesInput>>;
  create?: Maybe<Array<KitchenMenuItemCreateWithoutMenuScheduleCategoriesInput>>;
  delete?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<KitchenMenuItemScalarWhereInput>>;
  disconnect?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  set?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  update?: Maybe<Array<KitchenMenuItemUpdateWithWhereUniqueWithoutMenuScheduleCategoriesInput>>;
  updateMany?: Maybe<Array<KitchenMenuItemUpdateManyWithWhereWithoutMenuScheduleCategoriesInput>>;
  upsert?: Maybe<Array<KitchenMenuItemUpsertWithWhereUniqueWithoutMenuScheduleCategoriesInput>>;
};

export type KitchenMenuItemUpdateManyWithoutStationInput = {
  connect?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<KitchenMenuItemCreateOrConnectWithoutStationInput>>;
  create?: Maybe<Array<KitchenMenuItemCreateWithoutStationInput>>;
  createMany?: Maybe<KitchenMenuItemCreateManyStationInputEnvelope>;
  delete?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<KitchenMenuItemScalarWhereInput>>;
  disconnect?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  set?: Maybe<Array<KitchenMenuItemWhereUniqueInput>>;
  update?: Maybe<Array<KitchenMenuItemUpdateWithWhereUniqueWithoutStationInput>>;
  updateMany?: Maybe<Array<KitchenMenuItemUpdateManyWithWhereWithoutStationInput>>;
  upsert?: Maybe<Array<KitchenMenuItemUpsertWithWhereUniqueWithoutStationInput>>;
};

export type KitchenMenuItemUpdateOneRequiredWithoutCartItemsInput = {
  connect?: Maybe<KitchenMenuItemWhereUniqueInput>;
  connectOrCreate?: Maybe<KitchenMenuItemCreateOrConnectWithoutCartItemsInput>;
  create?: Maybe<KitchenMenuItemCreateWithoutCartItemsInput>;
  update?: Maybe<KitchenMenuItemUpdateWithoutCartItemsInput>;
  upsert?: Maybe<KitchenMenuItemUpsertWithoutCartItemsInput>;
};

export type KitchenMenuItemUpdateOneRequiredWithoutFacilityItemsInput = {
  connect?: Maybe<KitchenMenuItemWhereUniqueInput>;
  connectOrCreate?: Maybe<KitchenMenuItemCreateOrConnectWithoutFacilityItemsInput>;
  create?: Maybe<KitchenMenuItemCreateWithoutFacilityItemsInput>;
  update?: Maybe<KitchenMenuItemUpdateWithoutFacilityItemsInput>;
  upsert?: Maybe<KitchenMenuItemUpsertWithoutFacilityItemsInput>;
};

export type KitchenMenuItemUpdateOneRequiredWithoutOrderItemsInput = {
  connect?: Maybe<KitchenMenuItemWhereUniqueInput>;
  connectOrCreate?: Maybe<KitchenMenuItemCreateOrConnectWithoutOrderItemsInput>;
  create?: Maybe<KitchenMenuItemCreateWithoutOrderItemsInput>;
  update?: Maybe<KitchenMenuItemUpdateWithoutOrderItemsInput>;
  upsert?: Maybe<KitchenMenuItemUpsertWithoutOrderItemsInput>;
};

export type KitchenMenuItemUpdateWithWhereUniqueWithoutExtrasInput = {
  data: KitchenMenuItemUpdateWithoutExtrasInput;
  where: KitchenMenuItemWhereUniqueInput;
};

export type KitchenMenuItemUpdateWithWhereUniqueWithoutKitchenInput = {
  data: KitchenMenuItemUpdateWithoutKitchenInput;
  where: KitchenMenuItemWhereUniqueInput;
};

export type KitchenMenuItemUpdateWithWhereUniqueWithoutMealPackItemsInput = {
  data: KitchenMenuItemUpdateWithoutMealPackItemsInput;
  where: KitchenMenuItemWhereUniqueInput;
};

export type KitchenMenuItemUpdateWithWhereUniqueWithoutMealPacksInput = {
  data: KitchenMenuItemUpdateWithoutMealPacksInput;
  where: KitchenMenuItemWhereUniqueInput;
};

export type KitchenMenuItemUpdateWithWhereUniqueWithoutMenuCategoriesInput = {
  data: KitchenMenuItemUpdateWithoutMenuCategoriesInput;
  where: KitchenMenuItemWhereUniqueInput;
};

export type KitchenMenuItemUpdateWithWhereUniqueWithoutMenuScheduleCategoriesInput = {
  data: KitchenMenuItemUpdateWithoutMenuScheduleCategoriesInput;
  where: KitchenMenuItemWhereUniqueInput;
};

export type KitchenMenuItemUpdateWithWhereUniqueWithoutStationInput = {
  data: KitchenMenuItemUpdateWithoutStationInput;
  where: KitchenMenuItemWhereUniqueInput;
};

export type KitchenMenuItemUpdateWithoutCartItemsInput = {
  allergies?: Maybe<KitchenMenuItemUpdateallergiesInput>;
  availability?: Maybe<KitchenMenuItemUpdateavailabilityInput>;
  availableFrom?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  availableTo?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cookTime?: Maybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  dishTypes?: Maybe<KitchenMenuItemUpdatedishTypesInput>;
  extras?: Maybe<ExtraUpdateManyWithoutKitchenMenuItemsInput>;
  facilityItems?: Maybe<FacilityItemUpdateManyWithoutMenuItemInput>;
  globalSortId?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  imgUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  inventoryCount?: Maybe<IntFieldUpdateOperationsInput>;
  inventoryStatus?: Maybe<EnumInventoryStatusFieldUpdateOperationsInput>;
  isRetail?: Maybe<BoolFieldUpdateOperationsInput>;
  kitchen?: Maybe<KitchenUpdateOneRequiredWithoutKitchenMenuItemsInput>;
  labels?: Maybe<KitchenMenuItemUpdatelabelsInput>;
  longDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mealPackItems?: Maybe<KitchenMenuItemUpdateManyWithoutMealPacksInput>;
  mealPacks?: Maybe<KitchenMenuItemUpdateManyWithoutMealPackItemsInput>;
  menuCategories?: Maybe<MenuCategoryUpdateManyWithoutMenuItemsInput>;
  menuScheduleCategories?: Maybe<MenuScheduleCategoryUpdateManyWithoutMenuItemsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  orderItems?: Maybe<OrderItemUpdateManyWithoutMenuItemInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  sortKitchenIds?: Maybe<KitchenMenuItemUpdatesortKitchenIdsInput>;
  station?: Maybe<StationUpdateOneWithoutKitchenMenuItemsInput>;
  status?: Maybe<EnumKitchenMenuItemStatusFieldUpdateOperationsInput>;
  type?: Maybe<EnumMenuItemTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type KitchenMenuItemUpdateWithoutExtrasInput = {
  allergies?: Maybe<KitchenMenuItemUpdateallergiesInput>;
  availability?: Maybe<KitchenMenuItemUpdateavailabilityInput>;
  availableFrom?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  availableTo?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cartItems?: Maybe<CartItemUpdateManyWithoutKitchenMenuItemInput>;
  cookTime?: Maybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  dishTypes?: Maybe<KitchenMenuItemUpdatedishTypesInput>;
  facilityItems?: Maybe<FacilityItemUpdateManyWithoutMenuItemInput>;
  globalSortId?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  imgUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  inventoryCount?: Maybe<IntFieldUpdateOperationsInput>;
  inventoryStatus?: Maybe<EnumInventoryStatusFieldUpdateOperationsInput>;
  isRetail?: Maybe<BoolFieldUpdateOperationsInput>;
  kitchen?: Maybe<KitchenUpdateOneRequiredWithoutKitchenMenuItemsInput>;
  labels?: Maybe<KitchenMenuItemUpdatelabelsInput>;
  longDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mealPackItems?: Maybe<KitchenMenuItemUpdateManyWithoutMealPacksInput>;
  mealPacks?: Maybe<KitchenMenuItemUpdateManyWithoutMealPackItemsInput>;
  menuCategories?: Maybe<MenuCategoryUpdateManyWithoutMenuItemsInput>;
  menuScheduleCategories?: Maybe<MenuScheduleCategoryUpdateManyWithoutMenuItemsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  orderItems?: Maybe<OrderItemUpdateManyWithoutMenuItemInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  sortKitchenIds?: Maybe<KitchenMenuItemUpdatesortKitchenIdsInput>;
  station?: Maybe<StationUpdateOneWithoutKitchenMenuItemsInput>;
  status?: Maybe<EnumKitchenMenuItemStatusFieldUpdateOperationsInput>;
  type?: Maybe<EnumMenuItemTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type KitchenMenuItemUpdateWithoutFacilityItemsInput = {
  allergies?: Maybe<KitchenMenuItemUpdateallergiesInput>;
  availability?: Maybe<KitchenMenuItemUpdateavailabilityInput>;
  availableFrom?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  availableTo?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cartItems?: Maybe<CartItemUpdateManyWithoutKitchenMenuItemInput>;
  cookTime?: Maybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  dishTypes?: Maybe<KitchenMenuItemUpdatedishTypesInput>;
  extras?: Maybe<ExtraUpdateManyWithoutKitchenMenuItemsInput>;
  globalSortId?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  imgUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  inventoryCount?: Maybe<IntFieldUpdateOperationsInput>;
  inventoryStatus?: Maybe<EnumInventoryStatusFieldUpdateOperationsInput>;
  isRetail?: Maybe<BoolFieldUpdateOperationsInput>;
  kitchen?: Maybe<KitchenUpdateOneRequiredWithoutKitchenMenuItemsInput>;
  labels?: Maybe<KitchenMenuItemUpdatelabelsInput>;
  longDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mealPackItems?: Maybe<KitchenMenuItemUpdateManyWithoutMealPacksInput>;
  mealPacks?: Maybe<KitchenMenuItemUpdateManyWithoutMealPackItemsInput>;
  menuCategories?: Maybe<MenuCategoryUpdateManyWithoutMenuItemsInput>;
  menuScheduleCategories?: Maybe<MenuScheduleCategoryUpdateManyWithoutMenuItemsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  orderItems?: Maybe<OrderItemUpdateManyWithoutMenuItemInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  sortKitchenIds?: Maybe<KitchenMenuItemUpdatesortKitchenIdsInput>;
  station?: Maybe<StationUpdateOneWithoutKitchenMenuItemsInput>;
  status?: Maybe<EnumKitchenMenuItemStatusFieldUpdateOperationsInput>;
  type?: Maybe<EnumMenuItemTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type KitchenMenuItemUpdateWithoutKitchenInput = {
  allergies?: Maybe<KitchenMenuItemUpdateallergiesInput>;
  availability?: Maybe<KitchenMenuItemUpdateavailabilityInput>;
  availableFrom?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  availableTo?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cartItems?: Maybe<CartItemUpdateManyWithoutKitchenMenuItemInput>;
  cookTime?: Maybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  dishTypes?: Maybe<KitchenMenuItemUpdatedishTypesInput>;
  extras?: Maybe<ExtraUpdateManyWithoutKitchenMenuItemsInput>;
  facilityItems?: Maybe<FacilityItemUpdateManyWithoutMenuItemInput>;
  globalSortId?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  imgUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  inventoryCount?: Maybe<IntFieldUpdateOperationsInput>;
  inventoryStatus?: Maybe<EnumInventoryStatusFieldUpdateOperationsInput>;
  isRetail?: Maybe<BoolFieldUpdateOperationsInput>;
  labels?: Maybe<KitchenMenuItemUpdatelabelsInput>;
  longDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mealPackItems?: Maybe<KitchenMenuItemUpdateManyWithoutMealPacksInput>;
  mealPacks?: Maybe<KitchenMenuItemUpdateManyWithoutMealPackItemsInput>;
  menuCategories?: Maybe<MenuCategoryUpdateManyWithoutMenuItemsInput>;
  menuScheduleCategories?: Maybe<MenuScheduleCategoryUpdateManyWithoutMenuItemsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  orderItems?: Maybe<OrderItemUpdateManyWithoutMenuItemInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  sortKitchenIds?: Maybe<KitchenMenuItemUpdatesortKitchenIdsInput>;
  station?: Maybe<StationUpdateOneWithoutKitchenMenuItemsInput>;
  status?: Maybe<EnumKitchenMenuItemStatusFieldUpdateOperationsInput>;
  type?: Maybe<EnumMenuItemTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type KitchenMenuItemUpdateWithoutMealPackItemsInput = {
  allergies?: Maybe<KitchenMenuItemUpdateallergiesInput>;
  availability?: Maybe<KitchenMenuItemUpdateavailabilityInput>;
  availableFrom?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  availableTo?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cartItems?: Maybe<CartItemUpdateManyWithoutKitchenMenuItemInput>;
  cookTime?: Maybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  dishTypes?: Maybe<KitchenMenuItemUpdatedishTypesInput>;
  extras?: Maybe<ExtraUpdateManyWithoutKitchenMenuItemsInput>;
  facilityItems?: Maybe<FacilityItemUpdateManyWithoutMenuItemInput>;
  globalSortId?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  imgUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  inventoryCount?: Maybe<IntFieldUpdateOperationsInput>;
  inventoryStatus?: Maybe<EnumInventoryStatusFieldUpdateOperationsInput>;
  isRetail?: Maybe<BoolFieldUpdateOperationsInput>;
  kitchen?: Maybe<KitchenUpdateOneRequiredWithoutKitchenMenuItemsInput>;
  labels?: Maybe<KitchenMenuItemUpdatelabelsInput>;
  longDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mealPacks?: Maybe<KitchenMenuItemUpdateManyWithoutMealPackItemsInput>;
  menuCategories?: Maybe<MenuCategoryUpdateManyWithoutMenuItemsInput>;
  menuScheduleCategories?: Maybe<MenuScheduleCategoryUpdateManyWithoutMenuItemsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  orderItems?: Maybe<OrderItemUpdateManyWithoutMenuItemInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  sortKitchenIds?: Maybe<KitchenMenuItemUpdatesortKitchenIdsInput>;
  station?: Maybe<StationUpdateOneWithoutKitchenMenuItemsInput>;
  status?: Maybe<EnumKitchenMenuItemStatusFieldUpdateOperationsInput>;
  type?: Maybe<EnumMenuItemTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type KitchenMenuItemUpdateWithoutMealPacksInput = {
  allergies?: Maybe<KitchenMenuItemUpdateallergiesInput>;
  availability?: Maybe<KitchenMenuItemUpdateavailabilityInput>;
  availableFrom?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  availableTo?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cartItems?: Maybe<CartItemUpdateManyWithoutKitchenMenuItemInput>;
  cookTime?: Maybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  dishTypes?: Maybe<KitchenMenuItemUpdatedishTypesInput>;
  extras?: Maybe<ExtraUpdateManyWithoutKitchenMenuItemsInput>;
  facilityItems?: Maybe<FacilityItemUpdateManyWithoutMenuItemInput>;
  globalSortId?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  imgUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  inventoryCount?: Maybe<IntFieldUpdateOperationsInput>;
  inventoryStatus?: Maybe<EnumInventoryStatusFieldUpdateOperationsInput>;
  isRetail?: Maybe<BoolFieldUpdateOperationsInput>;
  kitchen?: Maybe<KitchenUpdateOneRequiredWithoutKitchenMenuItemsInput>;
  labels?: Maybe<KitchenMenuItemUpdatelabelsInput>;
  longDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mealPackItems?: Maybe<KitchenMenuItemUpdateManyWithoutMealPacksInput>;
  menuCategories?: Maybe<MenuCategoryUpdateManyWithoutMenuItemsInput>;
  menuScheduleCategories?: Maybe<MenuScheduleCategoryUpdateManyWithoutMenuItemsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  orderItems?: Maybe<OrderItemUpdateManyWithoutMenuItemInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  sortKitchenIds?: Maybe<KitchenMenuItemUpdatesortKitchenIdsInput>;
  station?: Maybe<StationUpdateOneWithoutKitchenMenuItemsInput>;
  status?: Maybe<EnumKitchenMenuItemStatusFieldUpdateOperationsInput>;
  type?: Maybe<EnumMenuItemTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type KitchenMenuItemUpdateWithoutMenuCategoriesInput = {
  allergies?: Maybe<KitchenMenuItemUpdateallergiesInput>;
  availability?: Maybe<KitchenMenuItemUpdateavailabilityInput>;
  availableFrom?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  availableTo?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cartItems?: Maybe<CartItemUpdateManyWithoutKitchenMenuItemInput>;
  cookTime?: Maybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  dishTypes?: Maybe<KitchenMenuItemUpdatedishTypesInput>;
  extras?: Maybe<ExtraUpdateManyWithoutKitchenMenuItemsInput>;
  facilityItems?: Maybe<FacilityItemUpdateManyWithoutMenuItemInput>;
  globalSortId?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  imgUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  inventoryCount?: Maybe<IntFieldUpdateOperationsInput>;
  inventoryStatus?: Maybe<EnumInventoryStatusFieldUpdateOperationsInput>;
  isRetail?: Maybe<BoolFieldUpdateOperationsInput>;
  kitchen?: Maybe<KitchenUpdateOneRequiredWithoutKitchenMenuItemsInput>;
  labels?: Maybe<KitchenMenuItemUpdatelabelsInput>;
  longDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mealPackItems?: Maybe<KitchenMenuItemUpdateManyWithoutMealPacksInput>;
  mealPacks?: Maybe<KitchenMenuItemUpdateManyWithoutMealPackItemsInput>;
  menuScheduleCategories?: Maybe<MenuScheduleCategoryUpdateManyWithoutMenuItemsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  orderItems?: Maybe<OrderItemUpdateManyWithoutMenuItemInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  sortKitchenIds?: Maybe<KitchenMenuItemUpdatesortKitchenIdsInput>;
  station?: Maybe<StationUpdateOneWithoutKitchenMenuItemsInput>;
  status?: Maybe<EnumKitchenMenuItemStatusFieldUpdateOperationsInput>;
  type?: Maybe<EnumMenuItemTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type KitchenMenuItemUpdateWithoutMenuScheduleCategoriesInput = {
  allergies?: Maybe<KitchenMenuItemUpdateallergiesInput>;
  availability?: Maybe<KitchenMenuItemUpdateavailabilityInput>;
  availableFrom?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  availableTo?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cartItems?: Maybe<CartItemUpdateManyWithoutKitchenMenuItemInput>;
  cookTime?: Maybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  dishTypes?: Maybe<KitchenMenuItemUpdatedishTypesInput>;
  extras?: Maybe<ExtraUpdateManyWithoutKitchenMenuItemsInput>;
  facilityItems?: Maybe<FacilityItemUpdateManyWithoutMenuItemInput>;
  globalSortId?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  imgUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  inventoryCount?: Maybe<IntFieldUpdateOperationsInput>;
  inventoryStatus?: Maybe<EnumInventoryStatusFieldUpdateOperationsInput>;
  isRetail?: Maybe<BoolFieldUpdateOperationsInput>;
  kitchen?: Maybe<KitchenUpdateOneRequiredWithoutKitchenMenuItemsInput>;
  labels?: Maybe<KitchenMenuItemUpdatelabelsInput>;
  longDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mealPackItems?: Maybe<KitchenMenuItemUpdateManyWithoutMealPacksInput>;
  mealPacks?: Maybe<KitchenMenuItemUpdateManyWithoutMealPackItemsInput>;
  menuCategories?: Maybe<MenuCategoryUpdateManyWithoutMenuItemsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  orderItems?: Maybe<OrderItemUpdateManyWithoutMenuItemInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  sortKitchenIds?: Maybe<KitchenMenuItemUpdatesortKitchenIdsInput>;
  station?: Maybe<StationUpdateOneWithoutKitchenMenuItemsInput>;
  status?: Maybe<EnumKitchenMenuItemStatusFieldUpdateOperationsInput>;
  type?: Maybe<EnumMenuItemTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type KitchenMenuItemUpdateWithoutOrderItemsInput = {
  allergies?: Maybe<KitchenMenuItemUpdateallergiesInput>;
  availability?: Maybe<KitchenMenuItemUpdateavailabilityInput>;
  availableFrom?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  availableTo?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cartItems?: Maybe<CartItemUpdateManyWithoutKitchenMenuItemInput>;
  cookTime?: Maybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  dishTypes?: Maybe<KitchenMenuItemUpdatedishTypesInput>;
  extras?: Maybe<ExtraUpdateManyWithoutKitchenMenuItemsInput>;
  facilityItems?: Maybe<FacilityItemUpdateManyWithoutMenuItemInput>;
  globalSortId?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  imgUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  inventoryCount?: Maybe<IntFieldUpdateOperationsInput>;
  inventoryStatus?: Maybe<EnumInventoryStatusFieldUpdateOperationsInput>;
  isRetail?: Maybe<BoolFieldUpdateOperationsInput>;
  kitchen?: Maybe<KitchenUpdateOneRequiredWithoutKitchenMenuItemsInput>;
  labels?: Maybe<KitchenMenuItemUpdatelabelsInput>;
  longDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mealPackItems?: Maybe<KitchenMenuItemUpdateManyWithoutMealPacksInput>;
  mealPacks?: Maybe<KitchenMenuItemUpdateManyWithoutMealPackItemsInput>;
  menuCategories?: Maybe<MenuCategoryUpdateManyWithoutMenuItemsInput>;
  menuScheduleCategories?: Maybe<MenuScheduleCategoryUpdateManyWithoutMenuItemsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  sortKitchenIds?: Maybe<KitchenMenuItemUpdatesortKitchenIdsInput>;
  station?: Maybe<StationUpdateOneWithoutKitchenMenuItemsInput>;
  status?: Maybe<EnumKitchenMenuItemStatusFieldUpdateOperationsInput>;
  type?: Maybe<EnumMenuItemTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type KitchenMenuItemUpdateWithoutStationInput = {
  allergies?: Maybe<KitchenMenuItemUpdateallergiesInput>;
  availability?: Maybe<KitchenMenuItemUpdateavailabilityInput>;
  availableFrom?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  availableTo?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cartItems?: Maybe<CartItemUpdateManyWithoutKitchenMenuItemInput>;
  cookTime?: Maybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  dishTypes?: Maybe<KitchenMenuItemUpdatedishTypesInput>;
  extras?: Maybe<ExtraUpdateManyWithoutKitchenMenuItemsInput>;
  facilityItems?: Maybe<FacilityItemUpdateManyWithoutMenuItemInput>;
  globalSortId?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  imgUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  inventoryCount?: Maybe<IntFieldUpdateOperationsInput>;
  inventoryStatus?: Maybe<EnumInventoryStatusFieldUpdateOperationsInput>;
  isRetail?: Maybe<BoolFieldUpdateOperationsInput>;
  kitchen?: Maybe<KitchenUpdateOneRequiredWithoutKitchenMenuItemsInput>;
  labels?: Maybe<KitchenMenuItemUpdatelabelsInput>;
  longDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mealPackItems?: Maybe<KitchenMenuItemUpdateManyWithoutMealPacksInput>;
  mealPacks?: Maybe<KitchenMenuItemUpdateManyWithoutMealPackItemsInput>;
  menuCategories?: Maybe<MenuCategoryUpdateManyWithoutMenuItemsInput>;
  menuScheduleCategories?: Maybe<MenuScheduleCategoryUpdateManyWithoutMenuItemsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  orderItems?: Maybe<OrderItemUpdateManyWithoutMenuItemInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  sortKitchenIds?: Maybe<KitchenMenuItemUpdatesortKitchenIdsInput>;
  status?: Maybe<EnumKitchenMenuItemStatusFieldUpdateOperationsInput>;
  type?: Maybe<EnumMenuItemTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type KitchenMenuItemUpdateallergiesInput = {
  push?: Maybe<Allergy>;
  set?: Maybe<Array<Allergy>>;
};

export type KitchenMenuItemUpdateavailabilityInput = {
  push?: Maybe<MenuItemAvailability>;
  set?: Maybe<Array<MenuItemAvailability>>;
};

export type KitchenMenuItemUpdatedishTypesInput = {
  push?: Maybe<DishType>;
  set?: Maybe<Array<DishType>>;
};

export type KitchenMenuItemUpdatelabelsInput = {
  push?: Maybe<MenuItemLabel>;
  set?: Maybe<Array<MenuItemLabel>>;
};

export type KitchenMenuItemUpdatesortKitchenIdsInput = {
  push?: Maybe<Scalars['String']>;
  set?: Maybe<Array<Scalars['String']>>;
};

export type KitchenMenuItemUpsertWithWhereUniqueWithoutExtrasInput = {
  create: KitchenMenuItemCreateWithoutExtrasInput;
  update: KitchenMenuItemUpdateWithoutExtrasInput;
  where: KitchenMenuItemWhereUniqueInput;
};

export type KitchenMenuItemUpsertWithWhereUniqueWithoutKitchenInput = {
  create: KitchenMenuItemCreateWithoutKitchenInput;
  update: KitchenMenuItemUpdateWithoutKitchenInput;
  where: KitchenMenuItemWhereUniqueInput;
};

export type KitchenMenuItemUpsertWithWhereUniqueWithoutMealPackItemsInput = {
  create: KitchenMenuItemCreateWithoutMealPackItemsInput;
  update: KitchenMenuItemUpdateWithoutMealPackItemsInput;
  where: KitchenMenuItemWhereUniqueInput;
};

export type KitchenMenuItemUpsertWithWhereUniqueWithoutMealPacksInput = {
  create: KitchenMenuItemCreateWithoutMealPacksInput;
  update: KitchenMenuItemUpdateWithoutMealPacksInput;
  where: KitchenMenuItemWhereUniqueInput;
};

export type KitchenMenuItemUpsertWithWhereUniqueWithoutMenuCategoriesInput = {
  create: KitchenMenuItemCreateWithoutMenuCategoriesInput;
  update: KitchenMenuItemUpdateWithoutMenuCategoriesInput;
  where: KitchenMenuItemWhereUniqueInput;
};

export type KitchenMenuItemUpsertWithWhereUniqueWithoutMenuScheduleCategoriesInput = {
  create: KitchenMenuItemCreateWithoutMenuScheduleCategoriesInput;
  update: KitchenMenuItemUpdateWithoutMenuScheduleCategoriesInput;
  where: KitchenMenuItemWhereUniqueInput;
};

export type KitchenMenuItemUpsertWithWhereUniqueWithoutStationInput = {
  create: KitchenMenuItemCreateWithoutStationInput;
  update: KitchenMenuItemUpdateWithoutStationInput;
  where: KitchenMenuItemWhereUniqueInput;
};

export type KitchenMenuItemUpsertWithoutCartItemsInput = {
  create: KitchenMenuItemCreateWithoutCartItemsInput;
  update: KitchenMenuItemUpdateWithoutCartItemsInput;
};

export type KitchenMenuItemUpsertWithoutFacilityItemsInput = {
  create: KitchenMenuItemCreateWithoutFacilityItemsInput;
  update: KitchenMenuItemUpdateWithoutFacilityItemsInput;
};

export type KitchenMenuItemUpsertWithoutOrderItemsInput = {
  create: KitchenMenuItemCreateWithoutOrderItemsInput;
  update: KitchenMenuItemUpdateWithoutOrderItemsInput;
};

export type KitchenMenuItemWhereInput = {
  AND?: Maybe<Array<KitchenMenuItemWhereInput>>;
  NOT?: Maybe<Array<KitchenMenuItemWhereInput>>;
  OR?: Maybe<Array<KitchenMenuItemWhereInput>>;
  allergies?: Maybe<EnumAllergyNullableListFilter>;
  availability?: Maybe<EnumMenuItemAvailabilityNullableListFilter>;
  availableFrom?: Maybe<DateTimeNullableFilter>;
  availableTo?: Maybe<DateTimeNullableFilter>;
  cartItems?: Maybe<CartItemListRelationFilter>;
  cookTime?: Maybe<IntNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringFilter>;
  dishTypes?: Maybe<EnumDishTypeNullableListFilter>;
  extras?: Maybe<ExtraListRelationFilter>;
  facilityItems?: Maybe<FacilityItemListRelationFilter>;
  globalSortId?: Maybe<IntFilter>;
  id?: Maybe<StringFilter>;
  imgUrl?: Maybe<StringNullableFilter>;
  inventoryCount?: Maybe<IntFilter>;
  inventoryStatus?: Maybe<EnumInventoryStatusFilter>;
  isRetail?: Maybe<BoolFilter>;
  kitchen?: Maybe<KitchenWhereInput>;
  kitchenId?: Maybe<StringFilter>;
  labels?: Maybe<EnumMenuItemLabelNullableListFilter>;
  longDescription?: Maybe<StringNullableFilter>;
  mealPackItems?: Maybe<KitchenMenuItemListRelationFilter>;
  mealPacks?: Maybe<KitchenMenuItemListRelationFilter>;
  menuCategories?: Maybe<MenuCategoryListRelationFilter>;
  menuScheduleCategories?: Maybe<MenuScheduleCategoryListRelationFilter>;
  name?: Maybe<StringFilter>;
  orderItems?: Maybe<OrderItemListRelationFilter>;
  price?: Maybe<FloatFilter>;
  sortId?: Maybe<IntFilter>;
  sortKitchenIds?: Maybe<StringNullableListFilter>;
  station?: Maybe<StationWhereInput>;
  stationId?: Maybe<StringNullableFilter>;
  status?: Maybe<EnumKitchenMenuItemStatusFilter>;
  type?: Maybe<EnumMenuItemTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type KitchenMenuItemWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type KitchenReport = {
  __typename?: 'KitchenReport';
  date: Scalars['DateTime'];
  itemsCount: Scalars['Int'];
  itemsSold: Array<ItemSalesReport>;
  kitchenName: Scalars['String'];
  orderCount: Scalars['Int'];
  totalSales: Scalars['Float'];
};

export type KitchenScalarWhereInput = {
  AND?: Maybe<Array<KitchenScalarWhereInput>>;
  NOT?: Maybe<Array<KitchenScalarWhereInput>>;
  OR?: Maybe<Array<KitchenScalarWhereInput>>;
  chowlyApiKey?: Maybe<StringNullableFilter>;
  color?: Maybe<StringNullableFilter>;
  cookTime?: Maybe<IntFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  cuisine?: Maybe<StringNullableFilter>;
  description?: Maybe<StringNullableFilter>;
  enableChowly?: Maybe<BoolFilter>;
  facilityId?: Maybe<StringFilter>;
  headerImgUrl?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  logoUrl?: Maybe<StringNullableFilter>;
  name?: Maybe<StringFilter>;
  operationHours?: Maybe<JsonNullableListFilter>;
  originalLocation?: Maybe<StringNullableFilter>;
  phone?: Maybe<StringNullableFilter>;
  sortId?: Maybe<IntFilter>;
  status?: Maybe<EnumKitchenStatusFilter>;
  story?: Maybe<StringNullableFilter>;
  type?: Maybe<EnumKitchenTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  workplaceId?: Maybe<StringNullableFilter>;
};

export enum KitchenStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  Inactive = 'INACTIVE'
}

export type KitchenTicket = {
  __typename?: 'KitchenTicket';
  cookTime?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  delayReason?: Maybe<KitchenTicketDelayReason>;
  delayTime?: Maybe<Scalars['Int']>;
  delayedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  items: Array<FacilityItem>;
  kitchen?: Maybe<Kitchen>;
  order: Order;
  preparedAt?: Maybe<Scalars['DateTime']>;
  preparingFrom?: Maybe<Scalars['DateTime']>;
  preparingUntil?: Maybe<Scalars['DateTime']>;
  refireReason?: Maybe<RefireReason>;
  refiredAt?: Maybe<Scalars['DateTime']>;
  refiredFrom?: Maybe<KitchenTicket>;
  refiredFromId?: Maybe<Scalars['String']>;
  refiredTo: Array<KitchenTicket>;
  shortId?: Maybe<Scalars['Int']>;
  station?: Maybe<Station>;
  status: KitchenTicketStatus;
  type: KdsType;
};


export type KitchenTicketItemsArgs = {
  after?: Maybe<FacilityItemWhereUniqueInput>;
  before?: Maybe<FacilityItemWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type KitchenTicketRefiredToArgs = {
  after?: Maybe<KitchenTicketWhereUniqueInput>;
  before?: Maybe<KitchenTicketWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type KitchenTicketCreateManyKitchenInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  delayReason?: Maybe<KitchenTicketDelayReason>;
  delayTime?: Maybe<Scalars['Int']>;
  delayedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  orderId: Scalars['String'];
  preparedAt?: Maybe<Scalars['DateTime']>;
  preparingFrom?: Maybe<Scalars['DateTime']>;
  preparingUntil?: Maybe<Scalars['DateTime']>;
  refireReason?: Maybe<RefireReason>;
  refiredAt?: Maybe<Scalars['DateTime']>;
  refiredFromId?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  stationId?: Maybe<Scalars['String']>;
  status?: Maybe<KitchenTicketStatus>;
  type?: Maybe<KdsType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type KitchenTicketCreateManyKitchenInputEnvelope = {
  data?: Maybe<Array<KitchenTicketCreateManyKitchenInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type KitchenTicketCreateManyOrderInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  delayReason?: Maybe<KitchenTicketDelayReason>;
  delayTime?: Maybe<Scalars['Int']>;
  delayedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  kitchenId?: Maybe<Scalars['String']>;
  preparedAt?: Maybe<Scalars['DateTime']>;
  preparingFrom?: Maybe<Scalars['DateTime']>;
  preparingUntil?: Maybe<Scalars['DateTime']>;
  refireReason?: Maybe<RefireReason>;
  refiredAt?: Maybe<Scalars['DateTime']>;
  refiredFromId?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  stationId?: Maybe<Scalars['String']>;
  status?: Maybe<KitchenTicketStatus>;
  type?: Maybe<KdsType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type KitchenTicketCreateManyOrderInputEnvelope = {
  data?: Maybe<Array<KitchenTicketCreateManyOrderInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type KitchenTicketCreateManyRefiredFromInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  delayReason?: Maybe<KitchenTicketDelayReason>;
  delayTime?: Maybe<Scalars['Int']>;
  delayedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  kitchenId?: Maybe<Scalars['String']>;
  orderId: Scalars['String'];
  preparedAt?: Maybe<Scalars['DateTime']>;
  preparingFrom?: Maybe<Scalars['DateTime']>;
  preparingUntil?: Maybe<Scalars['DateTime']>;
  refireReason?: Maybe<RefireReason>;
  refiredAt?: Maybe<Scalars['DateTime']>;
  shortId?: Maybe<Scalars['Int']>;
  stationId?: Maybe<Scalars['String']>;
  status?: Maybe<KitchenTicketStatus>;
  type?: Maybe<KdsType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type KitchenTicketCreateManyRefiredFromInputEnvelope = {
  data?: Maybe<Array<KitchenTicketCreateManyRefiredFromInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type KitchenTicketCreateManyStationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  delayReason?: Maybe<KitchenTicketDelayReason>;
  delayTime?: Maybe<Scalars['Int']>;
  delayedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  kitchenId?: Maybe<Scalars['String']>;
  orderId: Scalars['String'];
  preparedAt?: Maybe<Scalars['DateTime']>;
  preparingFrom?: Maybe<Scalars['DateTime']>;
  preparingUntil?: Maybe<Scalars['DateTime']>;
  refireReason?: Maybe<RefireReason>;
  refiredAt?: Maybe<Scalars['DateTime']>;
  refiredFromId?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['Int']>;
  status?: Maybe<KitchenTicketStatus>;
  type?: Maybe<KdsType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type KitchenTicketCreateManyStationInputEnvelope = {
  data?: Maybe<Array<KitchenTicketCreateManyStationInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type KitchenTicketCreateNestedManyWithoutKitchenInput = {
  connect?: Maybe<Array<KitchenTicketWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<KitchenTicketCreateOrConnectWithoutKitchenInput>>;
  create?: Maybe<Array<KitchenTicketCreateWithoutKitchenInput>>;
  createMany?: Maybe<KitchenTicketCreateManyKitchenInputEnvelope>;
};

export type KitchenTicketCreateNestedManyWithoutOrderInput = {
  connect?: Maybe<Array<KitchenTicketWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<KitchenTicketCreateOrConnectWithoutOrderInput>>;
  create?: Maybe<Array<KitchenTicketCreateWithoutOrderInput>>;
  createMany?: Maybe<KitchenTicketCreateManyOrderInputEnvelope>;
};

export type KitchenTicketCreateNestedManyWithoutRefiredFromInput = {
  connect?: Maybe<Array<KitchenTicketWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<KitchenTicketCreateOrConnectWithoutRefiredFromInput>>;
  create?: Maybe<Array<KitchenTicketCreateWithoutRefiredFromInput>>;
  createMany?: Maybe<KitchenTicketCreateManyRefiredFromInputEnvelope>;
};

export type KitchenTicketCreateNestedManyWithoutStationInput = {
  connect?: Maybe<Array<KitchenTicketWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<KitchenTicketCreateOrConnectWithoutStationInput>>;
  create?: Maybe<Array<KitchenTicketCreateWithoutStationInput>>;
  createMany?: Maybe<KitchenTicketCreateManyStationInputEnvelope>;
};

export type KitchenTicketCreateNestedOneWithoutItemsInput = {
  connect?: Maybe<KitchenTicketWhereUniqueInput>;
  connectOrCreate?: Maybe<KitchenTicketCreateOrConnectWithoutItemsInput>;
  create?: Maybe<KitchenTicketCreateWithoutItemsInput>;
};

export type KitchenTicketCreateNestedOneWithoutRefiredToInput = {
  connect?: Maybe<KitchenTicketWhereUniqueInput>;
  connectOrCreate?: Maybe<KitchenTicketCreateOrConnectWithoutRefiredToInput>;
  create?: Maybe<KitchenTicketCreateWithoutRefiredToInput>;
};

export type KitchenTicketCreateOrConnectWithoutItemsInput = {
  create: KitchenTicketCreateWithoutItemsInput;
  where: KitchenTicketWhereUniqueInput;
};

export type KitchenTicketCreateOrConnectWithoutKitchenInput = {
  create: KitchenTicketCreateWithoutKitchenInput;
  where: KitchenTicketWhereUniqueInput;
};

export type KitchenTicketCreateOrConnectWithoutOrderInput = {
  create: KitchenTicketCreateWithoutOrderInput;
  where: KitchenTicketWhereUniqueInput;
};

export type KitchenTicketCreateOrConnectWithoutRefiredFromInput = {
  create: KitchenTicketCreateWithoutRefiredFromInput;
  where: KitchenTicketWhereUniqueInput;
};

export type KitchenTicketCreateOrConnectWithoutRefiredToInput = {
  create: KitchenTicketCreateWithoutRefiredToInput;
  where: KitchenTicketWhereUniqueInput;
};

export type KitchenTicketCreateOrConnectWithoutStationInput = {
  create: KitchenTicketCreateWithoutStationInput;
  where: KitchenTicketWhereUniqueInput;
};

export type KitchenTicketCreateWithoutItemsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  delayReason?: Maybe<KitchenTicketDelayReason>;
  delayTime?: Maybe<Scalars['Int']>;
  delayedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  kitchen?: Maybe<KitchenCreateNestedOneWithoutKitchenTicketInput>;
  order: OrderCreateNestedOneWithoutKitchenTicketsInput;
  preparedAt?: Maybe<Scalars['DateTime']>;
  preparingFrom?: Maybe<Scalars['DateTime']>;
  preparingUntil?: Maybe<Scalars['DateTime']>;
  refireReason?: Maybe<RefireReason>;
  refiredAt?: Maybe<Scalars['DateTime']>;
  refiredFrom?: Maybe<KitchenTicketCreateNestedOneWithoutRefiredToInput>;
  refiredTo?: Maybe<KitchenTicketCreateNestedManyWithoutRefiredFromInput>;
  shortId?: Maybe<Scalars['Int']>;
  station?: Maybe<StationCreateNestedOneWithoutTicketsInput>;
  status?: Maybe<KitchenTicketStatus>;
  type?: Maybe<KdsType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type KitchenTicketCreateWithoutKitchenInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  delayReason?: Maybe<KitchenTicketDelayReason>;
  delayTime?: Maybe<Scalars['Int']>;
  delayedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  items?: Maybe<FacilityItemCreateNestedManyWithoutTicketInput>;
  order: OrderCreateNestedOneWithoutKitchenTicketsInput;
  preparedAt?: Maybe<Scalars['DateTime']>;
  preparingFrom?: Maybe<Scalars['DateTime']>;
  preparingUntil?: Maybe<Scalars['DateTime']>;
  refireReason?: Maybe<RefireReason>;
  refiredAt?: Maybe<Scalars['DateTime']>;
  refiredFrom?: Maybe<KitchenTicketCreateNestedOneWithoutRefiredToInput>;
  refiredTo?: Maybe<KitchenTicketCreateNestedManyWithoutRefiredFromInput>;
  shortId?: Maybe<Scalars['Int']>;
  station?: Maybe<StationCreateNestedOneWithoutTicketsInput>;
  status?: Maybe<KitchenTicketStatus>;
  type?: Maybe<KdsType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type KitchenTicketCreateWithoutOrderInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  delayReason?: Maybe<KitchenTicketDelayReason>;
  delayTime?: Maybe<Scalars['Int']>;
  delayedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  items?: Maybe<FacilityItemCreateNestedManyWithoutTicketInput>;
  kitchen?: Maybe<KitchenCreateNestedOneWithoutKitchenTicketInput>;
  preparedAt?: Maybe<Scalars['DateTime']>;
  preparingFrom?: Maybe<Scalars['DateTime']>;
  preparingUntil?: Maybe<Scalars['DateTime']>;
  refireReason?: Maybe<RefireReason>;
  refiredAt?: Maybe<Scalars['DateTime']>;
  refiredFrom?: Maybe<KitchenTicketCreateNestedOneWithoutRefiredToInput>;
  refiredTo?: Maybe<KitchenTicketCreateNestedManyWithoutRefiredFromInput>;
  shortId?: Maybe<Scalars['Int']>;
  station?: Maybe<StationCreateNestedOneWithoutTicketsInput>;
  status?: Maybe<KitchenTicketStatus>;
  type?: Maybe<KdsType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type KitchenTicketCreateWithoutRefiredFromInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  delayReason?: Maybe<KitchenTicketDelayReason>;
  delayTime?: Maybe<Scalars['Int']>;
  delayedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  items?: Maybe<FacilityItemCreateNestedManyWithoutTicketInput>;
  kitchen?: Maybe<KitchenCreateNestedOneWithoutKitchenTicketInput>;
  order: OrderCreateNestedOneWithoutKitchenTicketsInput;
  preparedAt?: Maybe<Scalars['DateTime']>;
  preparingFrom?: Maybe<Scalars['DateTime']>;
  preparingUntil?: Maybe<Scalars['DateTime']>;
  refireReason?: Maybe<RefireReason>;
  refiredAt?: Maybe<Scalars['DateTime']>;
  refiredTo?: Maybe<KitchenTicketCreateNestedManyWithoutRefiredFromInput>;
  shortId?: Maybe<Scalars['Int']>;
  station?: Maybe<StationCreateNestedOneWithoutTicketsInput>;
  status?: Maybe<KitchenTicketStatus>;
  type?: Maybe<KdsType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type KitchenTicketCreateWithoutRefiredToInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  delayReason?: Maybe<KitchenTicketDelayReason>;
  delayTime?: Maybe<Scalars['Int']>;
  delayedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  items?: Maybe<FacilityItemCreateNestedManyWithoutTicketInput>;
  kitchen?: Maybe<KitchenCreateNestedOneWithoutKitchenTicketInput>;
  order: OrderCreateNestedOneWithoutKitchenTicketsInput;
  preparedAt?: Maybe<Scalars['DateTime']>;
  preparingFrom?: Maybe<Scalars['DateTime']>;
  preparingUntil?: Maybe<Scalars['DateTime']>;
  refireReason?: Maybe<RefireReason>;
  refiredAt?: Maybe<Scalars['DateTime']>;
  refiredFrom?: Maybe<KitchenTicketCreateNestedOneWithoutRefiredToInput>;
  shortId?: Maybe<Scalars['Int']>;
  station?: Maybe<StationCreateNestedOneWithoutTicketsInput>;
  status?: Maybe<KitchenTicketStatus>;
  type?: Maybe<KdsType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type KitchenTicketCreateWithoutStationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  delayReason?: Maybe<KitchenTicketDelayReason>;
  delayTime?: Maybe<Scalars['Int']>;
  delayedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  items?: Maybe<FacilityItemCreateNestedManyWithoutTicketInput>;
  kitchen?: Maybe<KitchenCreateNestedOneWithoutKitchenTicketInput>;
  order: OrderCreateNestedOneWithoutKitchenTicketsInput;
  preparedAt?: Maybe<Scalars['DateTime']>;
  preparingFrom?: Maybe<Scalars['DateTime']>;
  preparingUntil?: Maybe<Scalars['DateTime']>;
  refireReason?: Maybe<RefireReason>;
  refiredAt?: Maybe<Scalars['DateTime']>;
  refiredFrom?: Maybe<KitchenTicketCreateNestedOneWithoutRefiredToInput>;
  refiredTo?: Maybe<KitchenTicketCreateNestedManyWithoutRefiredFromInput>;
  shortId?: Maybe<Scalars['Int']>;
  status?: Maybe<KitchenTicketStatus>;
  type?: Maybe<KdsType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum KitchenTicketDelayReason {
  Busy = 'BUSY',
  Mistake = 'MISTAKE',
  Other = 'OTHER'
}

export type KitchenTicketListRelationFilter = {
  every?: Maybe<KitchenTicketWhereInput>;
  none?: Maybe<KitchenTicketWhereInput>;
  some?: Maybe<KitchenTicketWhereInput>;
};

export type KitchenTicketScalarWhereInput = {
  AND?: Maybe<Array<KitchenTicketScalarWhereInput>>;
  NOT?: Maybe<Array<KitchenTicketScalarWhereInput>>;
  OR?: Maybe<Array<KitchenTicketScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  delayReason?: Maybe<EnumKitchenTicketDelayReasonNullableFilter>;
  delayTime?: Maybe<IntNullableFilter>;
  delayedAt?: Maybe<DateTimeNullableFilter>;
  id?: Maybe<StringFilter>;
  kitchenId?: Maybe<StringNullableFilter>;
  orderId?: Maybe<StringFilter>;
  preparedAt?: Maybe<DateTimeNullableFilter>;
  preparingFrom?: Maybe<DateTimeNullableFilter>;
  preparingUntil?: Maybe<DateTimeNullableFilter>;
  refireReason?: Maybe<EnumRefireReasonNullableFilter>;
  refiredAt?: Maybe<DateTimeNullableFilter>;
  refiredFromId?: Maybe<StringNullableFilter>;
  shortId?: Maybe<IntNullableFilter>;
  stationId?: Maybe<StringNullableFilter>;
  status?: Maybe<EnumKitchenTicketStatusFilter>;
  type?: Maybe<EnumKdsTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export enum KitchenTicketStatus {
  Done = 'DONE',
  Expo = 'EXPO',
  New = 'NEW',
  Refired = 'REFIRED',
  Started = 'STARTED'
}

export type KitchenTicketSubscription = {
  __typename?: 'KitchenTicketSubscription';
  data: KitchenTicket;
  operation: SubscriptionOperation;
};

export type KitchenTicketUpdateInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  delayReason?: Maybe<NullableEnumKitchenTicketDelayReasonFieldUpdateOperationsInput>;
  delayTime?: Maybe<NullableIntFieldUpdateOperationsInput>;
  delayedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  items?: Maybe<FacilityItemUpdateManyWithoutTicketInput>;
  kitchen?: Maybe<KitchenUpdateOneWithoutKitchenTicketInput>;
  order?: Maybe<OrderUpdateOneRequiredWithoutKitchenTicketsInput>;
  preparedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  preparingFrom?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  preparingUntil?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  refireReason?: Maybe<NullableEnumRefireReasonFieldUpdateOperationsInput>;
  refiredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  refiredFrom?: Maybe<KitchenTicketUpdateOneWithoutRefiredToInput>;
  refiredTo?: Maybe<KitchenTicketUpdateManyWithoutRefiredFromInput>;
  shortId?: Maybe<NullableIntFieldUpdateOperationsInput>;
  station?: Maybe<StationUpdateOneWithoutTicketsInput>;
  status?: Maybe<EnumKitchenTicketStatusFieldUpdateOperationsInput>;
  type?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type KitchenTicketUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  delayReason?: Maybe<NullableEnumKitchenTicketDelayReasonFieldUpdateOperationsInput>;
  delayTime?: Maybe<NullableIntFieldUpdateOperationsInput>;
  delayedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  preparedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  preparingFrom?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  preparingUntil?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  refireReason?: Maybe<NullableEnumRefireReasonFieldUpdateOperationsInput>;
  refiredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  shortId?: Maybe<NullableIntFieldUpdateOperationsInput>;
  status?: Maybe<EnumKitchenTicketStatusFieldUpdateOperationsInput>;
  type?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type KitchenTicketUpdateManyWithWhereWithoutKitchenInput = {
  data: KitchenTicketUpdateManyMutationInput;
  where: KitchenTicketScalarWhereInput;
};

export type KitchenTicketUpdateManyWithWhereWithoutOrderInput = {
  data: KitchenTicketUpdateManyMutationInput;
  where: KitchenTicketScalarWhereInput;
};

export type KitchenTicketUpdateManyWithWhereWithoutRefiredFromInput = {
  data: KitchenTicketUpdateManyMutationInput;
  where: KitchenTicketScalarWhereInput;
};

export type KitchenTicketUpdateManyWithWhereWithoutStationInput = {
  data: KitchenTicketUpdateManyMutationInput;
  where: KitchenTicketScalarWhereInput;
};

export type KitchenTicketUpdateManyWithoutKitchenInput = {
  connect?: Maybe<Array<KitchenTicketWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<KitchenTicketCreateOrConnectWithoutKitchenInput>>;
  create?: Maybe<Array<KitchenTicketCreateWithoutKitchenInput>>;
  createMany?: Maybe<KitchenTicketCreateManyKitchenInputEnvelope>;
  delete?: Maybe<Array<KitchenTicketWhereUniqueInput>>;
  deleteMany?: Maybe<Array<KitchenTicketScalarWhereInput>>;
  disconnect?: Maybe<Array<KitchenTicketWhereUniqueInput>>;
  set?: Maybe<Array<KitchenTicketWhereUniqueInput>>;
  update?: Maybe<Array<KitchenTicketUpdateWithWhereUniqueWithoutKitchenInput>>;
  updateMany?: Maybe<Array<KitchenTicketUpdateManyWithWhereWithoutKitchenInput>>;
  upsert?: Maybe<Array<KitchenTicketUpsertWithWhereUniqueWithoutKitchenInput>>;
};

export type KitchenTicketUpdateManyWithoutOrderInput = {
  connect?: Maybe<Array<KitchenTicketWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<KitchenTicketCreateOrConnectWithoutOrderInput>>;
  create?: Maybe<Array<KitchenTicketCreateWithoutOrderInput>>;
  createMany?: Maybe<KitchenTicketCreateManyOrderInputEnvelope>;
  delete?: Maybe<Array<KitchenTicketWhereUniqueInput>>;
  deleteMany?: Maybe<Array<KitchenTicketScalarWhereInput>>;
  disconnect?: Maybe<Array<KitchenTicketWhereUniqueInput>>;
  set?: Maybe<Array<KitchenTicketWhereUniqueInput>>;
  update?: Maybe<Array<KitchenTicketUpdateWithWhereUniqueWithoutOrderInput>>;
  updateMany?: Maybe<Array<KitchenTicketUpdateManyWithWhereWithoutOrderInput>>;
  upsert?: Maybe<Array<KitchenTicketUpsertWithWhereUniqueWithoutOrderInput>>;
};

export type KitchenTicketUpdateManyWithoutRefiredFromInput = {
  connect?: Maybe<Array<KitchenTicketWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<KitchenTicketCreateOrConnectWithoutRefiredFromInput>>;
  create?: Maybe<Array<KitchenTicketCreateWithoutRefiredFromInput>>;
  createMany?: Maybe<KitchenTicketCreateManyRefiredFromInputEnvelope>;
  delete?: Maybe<Array<KitchenTicketWhereUniqueInput>>;
  deleteMany?: Maybe<Array<KitchenTicketScalarWhereInput>>;
  disconnect?: Maybe<Array<KitchenTicketWhereUniqueInput>>;
  set?: Maybe<Array<KitchenTicketWhereUniqueInput>>;
  update?: Maybe<Array<KitchenTicketUpdateWithWhereUniqueWithoutRefiredFromInput>>;
  updateMany?: Maybe<Array<KitchenTicketUpdateManyWithWhereWithoutRefiredFromInput>>;
  upsert?: Maybe<Array<KitchenTicketUpsertWithWhereUniqueWithoutRefiredFromInput>>;
};

export type KitchenTicketUpdateManyWithoutStationInput = {
  connect?: Maybe<Array<KitchenTicketWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<KitchenTicketCreateOrConnectWithoutStationInput>>;
  create?: Maybe<Array<KitchenTicketCreateWithoutStationInput>>;
  createMany?: Maybe<KitchenTicketCreateManyStationInputEnvelope>;
  delete?: Maybe<Array<KitchenTicketWhereUniqueInput>>;
  deleteMany?: Maybe<Array<KitchenTicketScalarWhereInput>>;
  disconnect?: Maybe<Array<KitchenTicketWhereUniqueInput>>;
  set?: Maybe<Array<KitchenTicketWhereUniqueInput>>;
  update?: Maybe<Array<KitchenTicketUpdateWithWhereUniqueWithoutStationInput>>;
  updateMany?: Maybe<Array<KitchenTicketUpdateManyWithWhereWithoutStationInput>>;
  upsert?: Maybe<Array<KitchenTicketUpsertWithWhereUniqueWithoutStationInput>>;
};

export type KitchenTicketUpdateOneRequiredWithoutItemsInput = {
  connect?: Maybe<KitchenTicketWhereUniqueInput>;
  connectOrCreate?: Maybe<KitchenTicketCreateOrConnectWithoutItemsInput>;
  create?: Maybe<KitchenTicketCreateWithoutItemsInput>;
  update?: Maybe<KitchenTicketUpdateWithoutItemsInput>;
  upsert?: Maybe<KitchenTicketUpsertWithoutItemsInput>;
};

export type KitchenTicketUpdateOneWithoutRefiredToInput = {
  connect?: Maybe<KitchenTicketWhereUniqueInput>;
  connectOrCreate?: Maybe<KitchenTicketCreateOrConnectWithoutRefiredToInput>;
  create?: Maybe<KitchenTicketCreateWithoutRefiredToInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<KitchenTicketUpdateWithoutRefiredToInput>;
  upsert?: Maybe<KitchenTicketUpsertWithoutRefiredToInput>;
};

export type KitchenTicketUpdateWithWhereUniqueWithoutKitchenInput = {
  data: KitchenTicketUpdateWithoutKitchenInput;
  where: KitchenTicketWhereUniqueInput;
};

export type KitchenTicketUpdateWithWhereUniqueWithoutOrderInput = {
  data: KitchenTicketUpdateWithoutOrderInput;
  where: KitchenTicketWhereUniqueInput;
};

export type KitchenTicketUpdateWithWhereUniqueWithoutRefiredFromInput = {
  data: KitchenTicketUpdateWithoutRefiredFromInput;
  where: KitchenTicketWhereUniqueInput;
};

export type KitchenTicketUpdateWithWhereUniqueWithoutStationInput = {
  data: KitchenTicketUpdateWithoutStationInput;
  where: KitchenTicketWhereUniqueInput;
};

export type KitchenTicketUpdateWithoutItemsInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  delayReason?: Maybe<NullableEnumKitchenTicketDelayReasonFieldUpdateOperationsInput>;
  delayTime?: Maybe<NullableIntFieldUpdateOperationsInput>;
  delayedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  kitchen?: Maybe<KitchenUpdateOneWithoutKitchenTicketInput>;
  order?: Maybe<OrderUpdateOneRequiredWithoutKitchenTicketsInput>;
  preparedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  preparingFrom?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  preparingUntil?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  refireReason?: Maybe<NullableEnumRefireReasonFieldUpdateOperationsInput>;
  refiredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  refiredFrom?: Maybe<KitchenTicketUpdateOneWithoutRefiredToInput>;
  refiredTo?: Maybe<KitchenTicketUpdateManyWithoutRefiredFromInput>;
  shortId?: Maybe<NullableIntFieldUpdateOperationsInput>;
  station?: Maybe<StationUpdateOneWithoutTicketsInput>;
  status?: Maybe<EnumKitchenTicketStatusFieldUpdateOperationsInput>;
  type?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type KitchenTicketUpdateWithoutKitchenInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  delayReason?: Maybe<NullableEnumKitchenTicketDelayReasonFieldUpdateOperationsInput>;
  delayTime?: Maybe<NullableIntFieldUpdateOperationsInput>;
  delayedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  items?: Maybe<FacilityItemUpdateManyWithoutTicketInput>;
  order?: Maybe<OrderUpdateOneRequiredWithoutKitchenTicketsInput>;
  preparedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  preparingFrom?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  preparingUntil?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  refireReason?: Maybe<NullableEnumRefireReasonFieldUpdateOperationsInput>;
  refiredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  refiredFrom?: Maybe<KitchenTicketUpdateOneWithoutRefiredToInput>;
  refiredTo?: Maybe<KitchenTicketUpdateManyWithoutRefiredFromInput>;
  shortId?: Maybe<NullableIntFieldUpdateOperationsInput>;
  station?: Maybe<StationUpdateOneWithoutTicketsInput>;
  status?: Maybe<EnumKitchenTicketStatusFieldUpdateOperationsInput>;
  type?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type KitchenTicketUpdateWithoutOrderInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  delayReason?: Maybe<NullableEnumKitchenTicketDelayReasonFieldUpdateOperationsInput>;
  delayTime?: Maybe<NullableIntFieldUpdateOperationsInput>;
  delayedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  items?: Maybe<FacilityItemUpdateManyWithoutTicketInput>;
  kitchen?: Maybe<KitchenUpdateOneWithoutKitchenTicketInput>;
  preparedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  preparingFrom?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  preparingUntil?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  refireReason?: Maybe<NullableEnumRefireReasonFieldUpdateOperationsInput>;
  refiredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  refiredFrom?: Maybe<KitchenTicketUpdateOneWithoutRefiredToInput>;
  refiredTo?: Maybe<KitchenTicketUpdateManyWithoutRefiredFromInput>;
  shortId?: Maybe<NullableIntFieldUpdateOperationsInput>;
  station?: Maybe<StationUpdateOneWithoutTicketsInput>;
  status?: Maybe<EnumKitchenTicketStatusFieldUpdateOperationsInput>;
  type?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type KitchenTicketUpdateWithoutRefiredFromInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  delayReason?: Maybe<NullableEnumKitchenTicketDelayReasonFieldUpdateOperationsInput>;
  delayTime?: Maybe<NullableIntFieldUpdateOperationsInput>;
  delayedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  items?: Maybe<FacilityItemUpdateManyWithoutTicketInput>;
  kitchen?: Maybe<KitchenUpdateOneWithoutKitchenTicketInput>;
  order?: Maybe<OrderUpdateOneRequiredWithoutKitchenTicketsInput>;
  preparedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  preparingFrom?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  preparingUntil?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  refireReason?: Maybe<NullableEnumRefireReasonFieldUpdateOperationsInput>;
  refiredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  refiredTo?: Maybe<KitchenTicketUpdateManyWithoutRefiredFromInput>;
  shortId?: Maybe<NullableIntFieldUpdateOperationsInput>;
  station?: Maybe<StationUpdateOneWithoutTicketsInput>;
  status?: Maybe<EnumKitchenTicketStatusFieldUpdateOperationsInput>;
  type?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type KitchenTicketUpdateWithoutRefiredToInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  delayReason?: Maybe<NullableEnumKitchenTicketDelayReasonFieldUpdateOperationsInput>;
  delayTime?: Maybe<NullableIntFieldUpdateOperationsInput>;
  delayedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  items?: Maybe<FacilityItemUpdateManyWithoutTicketInput>;
  kitchen?: Maybe<KitchenUpdateOneWithoutKitchenTicketInput>;
  order?: Maybe<OrderUpdateOneRequiredWithoutKitchenTicketsInput>;
  preparedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  preparingFrom?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  preparingUntil?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  refireReason?: Maybe<NullableEnumRefireReasonFieldUpdateOperationsInput>;
  refiredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  refiredFrom?: Maybe<KitchenTicketUpdateOneWithoutRefiredToInput>;
  shortId?: Maybe<NullableIntFieldUpdateOperationsInput>;
  station?: Maybe<StationUpdateOneWithoutTicketsInput>;
  status?: Maybe<EnumKitchenTicketStatusFieldUpdateOperationsInput>;
  type?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type KitchenTicketUpdateWithoutStationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  delayReason?: Maybe<NullableEnumKitchenTicketDelayReasonFieldUpdateOperationsInput>;
  delayTime?: Maybe<NullableIntFieldUpdateOperationsInput>;
  delayedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  items?: Maybe<FacilityItemUpdateManyWithoutTicketInput>;
  kitchen?: Maybe<KitchenUpdateOneWithoutKitchenTicketInput>;
  order?: Maybe<OrderUpdateOneRequiredWithoutKitchenTicketsInput>;
  preparedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  preparingFrom?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  preparingUntil?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  refireReason?: Maybe<NullableEnumRefireReasonFieldUpdateOperationsInput>;
  refiredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  refiredFrom?: Maybe<KitchenTicketUpdateOneWithoutRefiredToInput>;
  refiredTo?: Maybe<KitchenTicketUpdateManyWithoutRefiredFromInput>;
  shortId?: Maybe<NullableIntFieldUpdateOperationsInput>;
  status?: Maybe<EnumKitchenTicketStatusFieldUpdateOperationsInput>;
  type?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type KitchenTicketUpsertWithWhereUniqueWithoutKitchenInput = {
  create: KitchenTicketCreateWithoutKitchenInput;
  update: KitchenTicketUpdateWithoutKitchenInput;
  where: KitchenTicketWhereUniqueInput;
};

export type KitchenTicketUpsertWithWhereUniqueWithoutOrderInput = {
  create: KitchenTicketCreateWithoutOrderInput;
  update: KitchenTicketUpdateWithoutOrderInput;
  where: KitchenTicketWhereUniqueInput;
};

export type KitchenTicketUpsertWithWhereUniqueWithoutRefiredFromInput = {
  create: KitchenTicketCreateWithoutRefiredFromInput;
  update: KitchenTicketUpdateWithoutRefiredFromInput;
  where: KitchenTicketWhereUniqueInput;
};

export type KitchenTicketUpsertWithWhereUniqueWithoutStationInput = {
  create: KitchenTicketCreateWithoutStationInput;
  update: KitchenTicketUpdateWithoutStationInput;
  where: KitchenTicketWhereUniqueInput;
};

export type KitchenTicketUpsertWithoutItemsInput = {
  create: KitchenTicketCreateWithoutItemsInput;
  update: KitchenTicketUpdateWithoutItemsInput;
};

export type KitchenTicketUpsertWithoutRefiredToInput = {
  create: KitchenTicketCreateWithoutRefiredToInput;
  update: KitchenTicketUpdateWithoutRefiredToInput;
};

export type KitchenTicketWhereInput = {
  AND?: Maybe<Array<KitchenTicketWhereInput>>;
  NOT?: Maybe<Array<KitchenTicketWhereInput>>;
  OR?: Maybe<Array<KitchenTicketWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  delayReason?: Maybe<EnumKitchenTicketDelayReasonNullableFilter>;
  delayTime?: Maybe<IntNullableFilter>;
  delayedAt?: Maybe<DateTimeNullableFilter>;
  id?: Maybe<StringFilter>;
  items?: Maybe<FacilityItemListRelationFilter>;
  kitchen?: Maybe<KitchenWhereInput>;
  kitchenId?: Maybe<StringNullableFilter>;
  order?: Maybe<OrderWhereInput>;
  orderId?: Maybe<StringFilter>;
  preparedAt?: Maybe<DateTimeNullableFilter>;
  preparingFrom?: Maybe<DateTimeNullableFilter>;
  preparingUntil?: Maybe<DateTimeNullableFilter>;
  refireReason?: Maybe<EnumRefireReasonNullableFilter>;
  refiredAt?: Maybe<DateTimeNullableFilter>;
  refiredFrom?: Maybe<KitchenTicketWhereInput>;
  refiredFromId?: Maybe<StringNullableFilter>;
  refiredTo?: Maybe<KitchenTicketListRelationFilter>;
  shortId?: Maybe<IntNullableFilter>;
  station?: Maybe<StationWhereInput>;
  stationId?: Maybe<StringNullableFilter>;
  status?: Maybe<EnumKitchenTicketStatusFilter>;
  type?: Maybe<EnumKdsTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type KitchenTicketWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum KitchenType {
  Crave = 'CRAVE',
  Default = 'DEFAULT'
}

export type KitchenUpdateManyMutationInput = {
  chowlyApiKey?: Maybe<NullableStringFieldUpdateOperationsInput>;
  color?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cookTime?: Maybe<IntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  cuisine?: Maybe<NullableStringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enableChowly?: Maybe<BoolFieldUpdateOperationsInput>;
  headerImgUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  logoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationHours?: Maybe<KitchenUpdateoperationHoursInput>;
  originalLocation?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  status?: Maybe<EnumKitchenStatusFieldUpdateOperationsInput>;
  story?: Maybe<NullableStringFieldUpdateOperationsInput>;
  type?: Maybe<EnumKitchenTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type KitchenUpdateManyWithWhereWithoutFacilityInput = {
  data: KitchenUpdateManyMutationInput;
  where: KitchenScalarWhereInput;
};

export type KitchenUpdateManyWithWhereWithoutPowerBiReportInput = {
  data: KitchenUpdateManyMutationInput;
  where: KitchenScalarWhereInput;
};

export type KitchenUpdateManyWithWhereWithoutPromoFacilityRulesInput = {
  data: KitchenUpdateManyMutationInput;
  where: KitchenScalarWhereInput;
};

export type KitchenUpdateManyWithWhereWithoutUsersInput = {
  data: KitchenUpdateManyMutationInput;
  where: KitchenScalarWhereInput;
};

export type KitchenUpdateManyWithoutFacilityInput = {
  connect?: Maybe<Array<KitchenWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<KitchenCreateOrConnectWithoutFacilityInput>>;
  create?: Maybe<Array<KitchenCreateWithoutFacilityInput>>;
  createMany?: Maybe<KitchenCreateManyFacilityInputEnvelope>;
  delete?: Maybe<Array<KitchenWhereUniqueInput>>;
  deleteMany?: Maybe<Array<KitchenScalarWhereInput>>;
  disconnect?: Maybe<Array<KitchenWhereUniqueInput>>;
  set?: Maybe<Array<KitchenWhereUniqueInput>>;
  update?: Maybe<Array<KitchenUpdateWithWhereUniqueWithoutFacilityInput>>;
  updateMany?: Maybe<Array<KitchenUpdateManyWithWhereWithoutFacilityInput>>;
  upsert?: Maybe<Array<KitchenUpsertWithWhereUniqueWithoutFacilityInput>>;
};

export type KitchenUpdateManyWithoutPowerBiReportInput = {
  connect?: Maybe<Array<KitchenWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<KitchenCreateOrConnectWithoutPowerBiReportInput>>;
  create?: Maybe<Array<KitchenCreateWithoutPowerBiReportInput>>;
  delete?: Maybe<Array<KitchenWhereUniqueInput>>;
  deleteMany?: Maybe<Array<KitchenScalarWhereInput>>;
  disconnect?: Maybe<Array<KitchenWhereUniqueInput>>;
  set?: Maybe<Array<KitchenWhereUniqueInput>>;
  update?: Maybe<Array<KitchenUpdateWithWhereUniqueWithoutPowerBiReportInput>>;
  updateMany?: Maybe<Array<KitchenUpdateManyWithWhereWithoutPowerBiReportInput>>;
  upsert?: Maybe<Array<KitchenUpsertWithWhereUniqueWithoutPowerBiReportInput>>;
};

export type KitchenUpdateManyWithoutPromoFacilityRulesInput = {
  connect?: Maybe<Array<KitchenWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<KitchenCreateOrConnectWithoutPromoFacilityRulesInput>>;
  create?: Maybe<Array<KitchenCreateWithoutPromoFacilityRulesInput>>;
  delete?: Maybe<Array<KitchenWhereUniqueInput>>;
  deleteMany?: Maybe<Array<KitchenScalarWhereInput>>;
  disconnect?: Maybe<Array<KitchenWhereUniqueInput>>;
  set?: Maybe<Array<KitchenWhereUniqueInput>>;
  update?: Maybe<Array<KitchenUpdateWithWhereUniqueWithoutPromoFacilityRulesInput>>;
  updateMany?: Maybe<Array<KitchenUpdateManyWithWhereWithoutPromoFacilityRulesInput>>;
  upsert?: Maybe<Array<KitchenUpsertWithWhereUniqueWithoutPromoFacilityRulesInput>>;
};

export type KitchenUpdateManyWithoutUsersInput = {
  connect?: Maybe<Array<KitchenWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<KitchenCreateOrConnectWithoutUsersInput>>;
  create?: Maybe<Array<KitchenCreateWithoutUsersInput>>;
  delete?: Maybe<Array<KitchenWhereUniqueInput>>;
  deleteMany?: Maybe<Array<KitchenScalarWhereInput>>;
  disconnect?: Maybe<Array<KitchenWhereUniqueInput>>;
  set?: Maybe<Array<KitchenWhereUniqueInput>>;
  update?: Maybe<Array<KitchenUpdateWithWhereUniqueWithoutUsersInput>>;
  updateMany?: Maybe<Array<KitchenUpdateManyWithWhereWithoutUsersInput>>;
  upsert?: Maybe<Array<KitchenUpsertWithWhereUniqueWithoutUsersInput>>;
};

export type KitchenUpdateOneRequiredWithoutKitchenMenuItemsInput = {
  connect?: Maybe<KitchenWhereUniqueInput>;
  connectOrCreate?: Maybe<KitchenCreateOrConnectWithoutKitchenMenuItemsInput>;
  create?: Maybe<KitchenCreateWithoutKitchenMenuItemsInput>;
  update?: Maybe<KitchenUpdateWithoutKitchenMenuItemsInput>;
  upsert?: Maybe<KitchenUpsertWithoutKitchenMenuItemsInput>;
};

export type KitchenUpdateOneWithoutExtraInput = {
  connect?: Maybe<KitchenWhereUniqueInput>;
  connectOrCreate?: Maybe<KitchenCreateOrConnectWithoutExtraInput>;
  create?: Maybe<KitchenCreateWithoutExtraInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<KitchenUpdateWithoutExtraInput>;
  upsert?: Maybe<KitchenUpsertWithoutExtraInput>;
};

export type KitchenUpdateOneWithoutKitchenTicketInput = {
  connect?: Maybe<KitchenWhereUniqueInput>;
  connectOrCreate?: Maybe<KitchenCreateOrConnectWithoutKitchenTicketInput>;
  create?: Maybe<KitchenCreateWithoutKitchenTicketInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<KitchenUpdateWithoutKitchenTicketInput>;
  upsert?: Maybe<KitchenUpsertWithoutKitchenTicketInput>;
};

export type KitchenUpdateOneWithoutMenuCategoriesInput = {
  connect?: Maybe<KitchenWhereUniqueInput>;
  connectOrCreate?: Maybe<KitchenCreateOrConnectWithoutMenuCategoriesInput>;
  create?: Maybe<KitchenCreateWithoutMenuCategoriesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<KitchenUpdateWithoutMenuCategoriesInput>;
  upsert?: Maybe<KitchenUpsertWithoutMenuCategoriesInput>;
};

export type KitchenUpdateOneWithoutMenuSchedulesInput = {
  connect?: Maybe<KitchenWhereUniqueInput>;
  connectOrCreate?: Maybe<KitchenCreateOrConnectWithoutMenuSchedulesInput>;
  create?: Maybe<KitchenCreateWithoutMenuSchedulesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<KitchenUpdateWithoutMenuSchedulesInput>;
  upsert?: Maybe<KitchenUpsertWithoutMenuSchedulesInput>;
};

export type KitchenUpdateOneWithoutWorkplaceInput = {
  connect?: Maybe<KitchenWhereUniqueInput>;
  connectOrCreate?: Maybe<KitchenCreateOrConnectWithoutWorkplaceInput>;
  create?: Maybe<KitchenCreateWithoutWorkplaceInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<KitchenUpdateWithoutWorkplaceInput>;
  upsert?: Maybe<KitchenUpsertWithoutWorkplaceInput>;
};

export type KitchenUpdateWithWhereUniqueWithoutFacilityInput = {
  data: KitchenUpdateWithoutFacilityInput;
  where: KitchenWhereUniqueInput;
};

export type KitchenUpdateWithWhereUniqueWithoutPowerBiReportInput = {
  data: KitchenUpdateWithoutPowerBiReportInput;
  where: KitchenWhereUniqueInput;
};

export type KitchenUpdateWithWhereUniqueWithoutPromoFacilityRulesInput = {
  data: KitchenUpdateWithoutPromoFacilityRulesInput;
  where: KitchenWhereUniqueInput;
};

export type KitchenUpdateWithWhereUniqueWithoutUsersInput = {
  data: KitchenUpdateWithoutUsersInput;
  where: KitchenWhereUniqueInput;
};

export type KitchenUpdateWithoutExtraInput = {
  chowlyApiKey?: Maybe<NullableStringFieldUpdateOperationsInput>;
  color?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cookTime?: Maybe<IntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  cuisine?: Maybe<NullableStringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enableChowly?: Maybe<BoolFieldUpdateOperationsInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutKitchensInput>;
  headerImgUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  kitchenMenuItems?: Maybe<KitchenMenuItemUpdateManyWithoutKitchenInput>;
  kitchenTicket?: Maybe<KitchenTicketUpdateManyWithoutKitchenInput>;
  logoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  menuCategories?: Maybe<MenuCategoryUpdateManyWithoutKitchenInput>;
  menuSchedules?: Maybe<MenuScheduleUpdateManyWithoutKitchenInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationHours?: Maybe<KitchenUpdateoperationHoursInput>;
  originalLocation?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  powerBiReport?: Maybe<PowerBiReportUpdateManyWithoutAvailableToConceptsInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleUpdateManyWithoutKitchensInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  status?: Maybe<EnumKitchenStatusFieldUpdateOperationsInput>;
  story?: Maybe<NullableStringFieldUpdateOperationsInput>;
  type?: Maybe<EnumKitchenTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  users?: Maybe<UserUpdateManyWithoutKitchensInput>;
  workplace?: Maybe<WorkplaceUpdateOneWithoutKitchenInput>;
};

export type KitchenUpdateWithoutFacilityInput = {
  Extra?: Maybe<ExtraUpdateManyWithoutKitchenInput>;
  chowlyApiKey?: Maybe<NullableStringFieldUpdateOperationsInput>;
  color?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cookTime?: Maybe<IntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  cuisine?: Maybe<NullableStringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enableChowly?: Maybe<BoolFieldUpdateOperationsInput>;
  headerImgUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  kitchenMenuItems?: Maybe<KitchenMenuItemUpdateManyWithoutKitchenInput>;
  kitchenTicket?: Maybe<KitchenTicketUpdateManyWithoutKitchenInput>;
  logoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  menuCategories?: Maybe<MenuCategoryUpdateManyWithoutKitchenInput>;
  menuSchedules?: Maybe<MenuScheduleUpdateManyWithoutKitchenInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationHours?: Maybe<KitchenUpdateoperationHoursInput>;
  originalLocation?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  powerBiReport?: Maybe<PowerBiReportUpdateManyWithoutAvailableToConceptsInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleUpdateManyWithoutKitchensInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  status?: Maybe<EnumKitchenStatusFieldUpdateOperationsInput>;
  story?: Maybe<NullableStringFieldUpdateOperationsInput>;
  type?: Maybe<EnumKitchenTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  users?: Maybe<UserUpdateManyWithoutKitchensInput>;
  workplace?: Maybe<WorkplaceUpdateOneWithoutKitchenInput>;
};

export type KitchenUpdateWithoutKitchenMenuItemsInput = {
  Extra?: Maybe<ExtraUpdateManyWithoutKitchenInput>;
  chowlyApiKey?: Maybe<NullableStringFieldUpdateOperationsInput>;
  color?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cookTime?: Maybe<IntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  cuisine?: Maybe<NullableStringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enableChowly?: Maybe<BoolFieldUpdateOperationsInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutKitchensInput>;
  headerImgUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  kitchenTicket?: Maybe<KitchenTicketUpdateManyWithoutKitchenInput>;
  logoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  menuCategories?: Maybe<MenuCategoryUpdateManyWithoutKitchenInput>;
  menuSchedules?: Maybe<MenuScheduleUpdateManyWithoutKitchenInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationHours?: Maybe<KitchenUpdateoperationHoursInput>;
  originalLocation?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  powerBiReport?: Maybe<PowerBiReportUpdateManyWithoutAvailableToConceptsInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleUpdateManyWithoutKitchensInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  status?: Maybe<EnumKitchenStatusFieldUpdateOperationsInput>;
  story?: Maybe<NullableStringFieldUpdateOperationsInput>;
  type?: Maybe<EnumKitchenTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  users?: Maybe<UserUpdateManyWithoutKitchensInput>;
  workplace?: Maybe<WorkplaceUpdateOneWithoutKitchenInput>;
};

export type KitchenUpdateWithoutKitchenTicketInput = {
  Extra?: Maybe<ExtraUpdateManyWithoutKitchenInput>;
  chowlyApiKey?: Maybe<NullableStringFieldUpdateOperationsInput>;
  color?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cookTime?: Maybe<IntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  cuisine?: Maybe<NullableStringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enableChowly?: Maybe<BoolFieldUpdateOperationsInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutKitchensInput>;
  headerImgUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  kitchenMenuItems?: Maybe<KitchenMenuItemUpdateManyWithoutKitchenInput>;
  logoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  menuCategories?: Maybe<MenuCategoryUpdateManyWithoutKitchenInput>;
  menuSchedules?: Maybe<MenuScheduleUpdateManyWithoutKitchenInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationHours?: Maybe<KitchenUpdateoperationHoursInput>;
  originalLocation?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  powerBiReport?: Maybe<PowerBiReportUpdateManyWithoutAvailableToConceptsInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleUpdateManyWithoutKitchensInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  status?: Maybe<EnumKitchenStatusFieldUpdateOperationsInput>;
  story?: Maybe<NullableStringFieldUpdateOperationsInput>;
  type?: Maybe<EnumKitchenTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  users?: Maybe<UserUpdateManyWithoutKitchensInput>;
  workplace?: Maybe<WorkplaceUpdateOneWithoutKitchenInput>;
};

export type KitchenUpdateWithoutMenuCategoriesInput = {
  Extra?: Maybe<ExtraUpdateManyWithoutKitchenInput>;
  chowlyApiKey?: Maybe<NullableStringFieldUpdateOperationsInput>;
  color?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cookTime?: Maybe<IntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  cuisine?: Maybe<NullableStringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enableChowly?: Maybe<BoolFieldUpdateOperationsInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutKitchensInput>;
  headerImgUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  kitchenMenuItems?: Maybe<KitchenMenuItemUpdateManyWithoutKitchenInput>;
  kitchenTicket?: Maybe<KitchenTicketUpdateManyWithoutKitchenInput>;
  logoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  menuSchedules?: Maybe<MenuScheduleUpdateManyWithoutKitchenInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationHours?: Maybe<KitchenUpdateoperationHoursInput>;
  originalLocation?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  powerBiReport?: Maybe<PowerBiReportUpdateManyWithoutAvailableToConceptsInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleUpdateManyWithoutKitchensInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  status?: Maybe<EnumKitchenStatusFieldUpdateOperationsInput>;
  story?: Maybe<NullableStringFieldUpdateOperationsInput>;
  type?: Maybe<EnumKitchenTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  users?: Maybe<UserUpdateManyWithoutKitchensInput>;
  workplace?: Maybe<WorkplaceUpdateOneWithoutKitchenInput>;
};

export type KitchenUpdateWithoutMenuSchedulesInput = {
  Extra?: Maybe<ExtraUpdateManyWithoutKitchenInput>;
  chowlyApiKey?: Maybe<NullableStringFieldUpdateOperationsInput>;
  color?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cookTime?: Maybe<IntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  cuisine?: Maybe<NullableStringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enableChowly?: Maybe<BoolFieldUpdateOperationsInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutKitchensInput>;
  headerImgUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  kitchenMenuItems?: Maybe<KitchenMenuItemUpdateManyWithoutKitchenInput>;
  kitchenTicket?: Maybe<KitchenTicketUpdateManyWithoutKitchenInput>;
  logoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  menuCategories?: Maybe<MenuCategoryUpdateManyWithoutKitchenInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationHours?: Maybe<KitchenUpdateoperationHoursInput>;
  originalLocation?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  powerBiReport?: Maybe<PowerBiReportUpdateManyWithoutAvailableToConceptsInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleUpdateManyWithoutKitchensInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  status?: Maybe<EnumKitchenStatusFieldUpdateOperationsInput>;
  story?: Maybe<NullableStringFieldUpdateOperationsInput>;
  type?: Maybe<EnumKitchenTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  users?: Maybe<UserUpdateManyWithoutKitchensInput>;
  workplace?: Maybe<WorkplaceUpdateOneWithoutKitchenInput>;
};

export type KitchenUpdateWithoutPowerBiReportInput = {
  Extra?: Maybe<ExtraUpdateManyWithoutKitchenInput>;
  chowlyApiKey?: Maybe<NullableStringFieldUpdateOperationsInput>;
  color?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cookTime?: Maybe<IntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  cuisine?: Maybe<NullableStringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enableChowly?: Maybe<BoolFieldUpdateOperationsInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutKitchensInput>;
  headerImgUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  kitchenMenuItems?: Maybe<KitchenMenuItemUpdateManyWithoutKitchenInput>;
  kitchenTicket?: Maybe<KitchenTicketUpdateManyWithoutKitchenInput>;
  logoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  menuCategories?: Maybe<MenuCategoryUpdateManyWithoutKitchenInput>;
  menuSchedules?: Maybe<MenuScheduleUpdateManyWithoutKitchenInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationHours?: Maybe<KitchenUpdateoperationHoursInput>;
  originalLocation?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleUpdateManyWithoutKitchensInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  status?: Maybe<EnumKitchenStatusFieldUpdateOperationsInput>;
  story?: Maybe<NullableStringFieldUpdateOperationsInput>;
  type?: Maybe<EnumKitchenTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  users?: Maybe<UserUpdateManyWithoutKitchensInput>;
  workplace?: Maybe<WorkplaceUpdateOneWithoutKitchenInput>;
};

export type KitchenUpdateWithoutPromoFacilityRulesInput = {
  Extra?: Maybe<ExtraUpdateManyWithoutKitchenInput>;
  chowlyApiKey?: Maybe<NullableStringFieldUpdateOperationsInput>;
  color?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cookTime?: Maybe<IntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  cuisine?: Maybe<NullableStringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enableChowly?: Maybe<BoolFieldUpdateOperationsInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutKitchensInput>;
  headerImgUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  kitchenMenuItems?: Maybe<KitchenMenuItemUpdateManyWithoutKitchenInput>;
  kitchenTicket?: Maybe<KitchenTicketUpdateManyWithoutKitchenInput>;
  logoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  menuCategories?: Maybe<MenuCategoryUpdateManyWithoutKitchenInput>;
  menuSchedules?: Maybe<MenuScheduleUpdateManyWithoutKitchenInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationHours?: Maybe<KitchenUpdateoperationHoursInput>;
  originalLocation?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  powerBiReport?: Maybe<PowerBiReportUpdateManyWithoutAvailableToConceptsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  status?: Maybe<EnumKitchenStatusFieldUpdateOperationsInput>;
  story?: Maybe<NullableStringFieldUpdateOperationsInput>;
  type?: Maybe<EnumKitchenTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  users?: Maybe<UserUpdateManyWithoutKitchensInput>;
  workplace?: Maybe<WorkplaceUpdateOneWithoutKitchenInput>;
};

export type KitchenUpdateWithoutUsersInput = {
  Extra?: Maybe<ExtraUpdateManyWithoutKitchenInput>;
  chowlyApiKey?: Maybe<NullableStringFieldUpdateOperationsInput>;
  color?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cookTime?: Maybe<IntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  cuisine?: Maybe<NullableStringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enableChowly?: Maybe<BoolFieldUpdateOperationsInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutKitchensInput>;
  headerImgUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  kitchenMenuItems?: Maybe<KitchenMenuItemUpdateManyWithoutKitchenInput>;
  kitchenTicket?: Maybe<KitchenTicketUpdateManyWithoutKitchenInput>;
  logoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  menuCategories?: Maybe<MenuCategoryUpdateManyWithoutKitchenInput>;
  menuSchedules?: Maybe<MenuScheduleUpdateManyWithoutKitchenInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationHours?: Maybe<KitchenUpdateoperationHoursInput>;
  originalLocation?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  powerBiReport?: Maybe<PowerBiReportUpdateManyWithoutAvailableToConceptsInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleUpdateManyWithoutKitchensInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  status?: Maybe<EnumKitchenStatusFieldUpdateOperationsInput>;
  story?: Maybe<NullableStringFieldUpdateOperationsInput>;
  type?: Maybe<EnumKitchenTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  workplace?: Maybe<WorkplaceUpdateOneWithoutKitchenInput>;
};

export type KitchenUpdateWithoutWorkplaceInput = {
  Extra?: Maybe<ExtraUpdateManyWithoutKitchenInput>;
  chowlyApiKey?: Maybe<NullableStringFieldUpdateOperationsInput>;
  color?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cookTime?: Maybe<IntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  cuisine?: Maybe<NullableStringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  enableChowly?: Maybe<BoolFieldUpdateOperationsInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutKitchensInput>;
  headerImgUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  kitchenMenuItems?: Maybe<KitchenMenuItemUpdateManyWithoutKitchenInput>;
  kitchenTicket?: Maybe<KitchenTicketUpdateManyWithoutKitchenInput>;
  logoUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  menuCategories?: Maybe<MenuCategoryUpdateManyWithoutKitchenInput>;
  menuSchedules?: Maybe<MenuScheduleUpdateManyWithoutKitchenInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationHours?: Maybe<KitchenUpdateoperationHoursInput>;
  originalLocation?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  powerBiReport?: Maybe<PowerBiReportUpdateManyWithoutAvailableToConceptsInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleUpdateManyWithoutKitchensInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  status?: Maybe<EnumKitchenStatusFieldUpdateOperationsInput>;
  story?: Maybe<NullableStringFieldUpdateOperationsInput>;
  type?: Maybe<EnumKitchenTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  users?: Maybe<UserUpdateManyWithoutKitchensInput>;
};

export type KitchenUpdateoperationHoursInput = {
  push?: Maybe<Scalars['Json']>;
  set?: Maybe<Array<Scalars['Json']>>;
};

export type KitchenUpsertInput = {
  color?: Maybe<Scalars['String']>;
  cookTime: Scalars['Int'];
  cuisine?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  enableChowly: Scalars['Boolean'];
  headerImgUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  logoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  originalLocation?: Maybe<Scalars['String']>;
  ownerIds: Array<Scalars['ID']>;
  phone?: Maybe<Scalars['String']>;
  status: KitchenStatus;
  story?: Maybe<Scalars['String']>;
};

export type KitchenUpsertWithWhereUniqueWithoutFacilityInput = {
  create: KitchenCreateWithoutFacilityInput;
  update: KitchenUpdateWithoutFacilityInput;
  where: KitchenWhereUniqueInput;
};

export type KitchenUpsertWithWhereUniqueWithoutPowerBiReportInput = {
  create: KitchenCreateWithoutPowerBiReportInput;
  update: KitchenUpdateWithoutPowerBiReportInput;
  where: KitchenWhereUniqueInput;
};

export type KitchenUpsertWithWhereUniqueWithoutPromoFacilityRulesInput = {
  create: KitchenCreateWithoutPromoFacilityRulesInput;
  update: KitchenUpdateWithoutPromoFacilityRulesInput;
  where: KitchenWhereUniqueInput;
};

export type KitchenUpsertWithWhereUniqueWithoutUsersInput = {
  create: KitchenCreateWithoutUsersInput;
  update: KitchenUpdateWithoutUsersInput;
  where: KitchenWhereUniqueInput;
};

export type KitchenUpsertWithoutExtraInput = {
  create: KitchenCreateWithoutExtraInput;
  update: KitchenUpdateWithoutExtraInput;
};

export type KitchenUpsertWithoutKitchenMenuItemsInput = {
  create: KitchenCreateWithoutKitchenMenuItemsInput;
  update: KitchenUpdateWithoutKitchenMenuItemsInput;
};

export type KitchenUpsertWithoutKitchenTicketInput = {
  create: KitchenCreateWithoutKitchenTicketInput;
  update: KitchenUpdateWithoutKitchenTicketInput;
};

export type KitchenUpsertWithoutMenuCategoriesInput = {
  create: KitchenCreateWithoutMenuCategoriesInput;
  update: KitchenUpdateWithoutMenuCategoriesInput;
};

export type KitchenUpsertWithoutMenuSchedulesInput = {
  create: KitchenCreateWithoutMenuSchedulesInput;
  update: KitchenUpdateWithoutMenuSchedulesInput;
};

export type KitchenUpsertWithoutWorkplaceInput = {
  create: KitchenCreateWithoutWorkplaceInput;
  update: KitchenUpdateWithoutWorkplaceInput;
};

export type KitchenWhereInput = {
  AND?: Maybe<Array<KitchenWhereInput>>;
  Extra?: Maybe<ExtraListRelationFilter>;
  NOT?: Maybe<Array<KitchenWhereInput>>;
  OR?: Maybe<Array<KitchenWhereInput>>;
  chowlyApiKey?: Maybe<StringNullableFilter>;
  color?: Maybe<StringNullableFilter>;
  cookTime?: Maybe<IntFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  cuisine?: Maybe<StringNullableFilter>;
  description?: Maybe<StringNullableFilter>;
  enableChowly?: Maybe<BoolFilter>;
  facility?: Maybe<FacilityWhereInput>;
  facilityId?: Maybe<StringFilter>;
  headerImgUrl?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  kitchenMenuItems?: Maybe<KitchenMenuItemListRelationFilter>;
  kitchenTicket?: Maybe<KitchenTicketListRelationFilter>;
  logoUrl?: Maybe<StringNullableFilter>;
  menuCategories?: Maybe<MenuCategoryListRelationFilter>;
  menuSchedules?: Maybe<MenuScheduleListRelationFilter>;
  name?: Maybe<StringFilter>;
  operationHours?: Maybe<JsonNullableListFilter>;
  originalLocation?: Maybe<StringNullableFilter>;
  phone?: Maybe<StringNullableFilter>;
  powerBiReport?: Maybe<PowerBiReportListRelationFilter>;
  promoFacilityRules?: Maybe<PromoFacilityRuleListRelationFilter>;
  sortId?: Maybe<IntFilter>;
  status?: Maybe<EnumKitchenStatusFilter>;
  story?: Maybe<StringNullableFilter>;
  type?: Maybe<EnumKitchenTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  users?: Maybe<UserListRelationFilter>;
  workplace?: Maybe<WorkplaceWhereInput>;
  workplaceId?: Maybe<StringNullableFilter>;
};

export type KitchenWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  workplaceId?: Maybe<Scalars['String']>;
};

export type MenuCategory = {
  __typename?: 'MenuCategory';
  categoryType: MenuCategoryType;
  id: Scalars['String'];
  menuItemType: MenuItemType;
  menuItems: Array<KitchenMenuItem>;
  name: Scalars['String'];
};


export type MenuCategoryMenuItemsArgs = {
  after?: Maybe<KitchenMenuItemWhereUniqueInput>;
  before?: Maybe<KitchenMenuItemWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type MenuCategoryCreateInput = {
  categoryType: MenuCategoryType;
  menuItemType: MenuItemType;
  name: Scalars['String'];
};

export type MenuCategoryCreateManyFacilityInput = {
  categoryType?: Maybe<MenuCategoryType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  kitchenId?: Maybe<Scalars['String']>;
  menuItemType?: Maybe<MenuItemType>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MenuCategoryCreateManyFacilityInputEnvelope = {
  data?: Maybe<Array<MenuCategoryCreateManyFacilityInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MenuCategoryCreateManyKitchenInput = {
  categoryType?: Maybe<MenuCategoryType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  facilityId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  menuItemType?: Maybe<MenuItemType>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MenuCategoryCreateManyKitchenInputEnvelope = {
  data?: Maybe<Array<MenuCategoryCreateManyKitchenInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MenuCategoryCreateNestedManyWithoutFacilityInput = {
  connect?: Maybe<Array<MenuCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MenuCategoryCreateOrConnectWithoutFacilityInput>>;
  create?: Maybe<Array<MenuCategoryCreateWithoutFacilityInput>>;
  createMany?: Maybe<MenuCategoryCreateManyFacilityInputEnvelope>;
};

export type MenuCategoryCreateNestedManyWithoutKitchenInput = {
  connect?: Maybe<Array<MenuCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MenuCategoryCreateOrConnectWithoutKitchenInput>>;
  create?: Maybe<Array<MenuCategoryCreateWithoutKitchenInput>>;
  createMany?: Maybe<MenuCategoryCreateManyKitchenInputEnvelope>;
};

export type MenuCategoryCreateNestedManyWithoutMenuItemsInput = {
  connect?: Maybe<Array<MenuCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MenuCategoryCreateOrConnectWithoutMenuItemsInput>>;
  create?: Maybe<Array<MenuCategoryCreateWithoutMenuItemsInput>>;
};

export type MenuCategoryCreateNestedOneWithoutMenuScheduleCategoriesInput = {
  connect?: Maybe<MenuCategoryWhereUniqueInput>;
  connectOrCreate?: Maybe<MenuCategoryCreateOrConnectWithoutMenuScheduleCategoriesInput>;
  create?: Maybe<MenuCategoryCreateWithoutMenuScheduleCategoriesInput>;
};

export type MenuCategoryCreateOrConnectWithoutFacilityInput = {
  create: MenuCategoryCreateWithoutFacilityInput;
  where: MenuCategoryWhereUniqueInput;
};

export type MenuCategoryCreateOrConnectWithoutKitchenInput = {
  create: MenuCategoryCreateWithoutKitchenInput;
  where: MenuCategoryWhereUniqueInput;
};

export type MenuCategoryCreateOrConnectWithoutMenuItemsInput = {
  create: MenuCategoryCreateWithoutMenuItemsInput;
  where: MenuCategoryWhereUniqueInput;
};

export type MenuCategoryCreateOrConnectWithoutMenuScheduleCategoriesInput = {
  create: MenuCategoryCreateWithoutMenuScheduleCategoriesInput;
  where: MenuCategoryWhereUniqueInput;
};

export type MenuCategoryCreateWithoutFacilityInput = {
  categoryType?: Maybe<MenuCategoryType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  kitchen?: Maybe<KitchenCreateNestedOneWithoutMenuCategoriesInput>;
  menuItemType?: Maybe<MenuItemType>;
  menuItems?: Maybe<KitchenMenuItemCreateNestedManyWithoutMenuCategoriesInput>;
  menuScheduleCategories?: Maybe<MenuScheduleCategoryCreateNestedManyWithoutCategoryInput>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MenuCategoryCreateWithoutKitchenInput = {
  categoryType?: Maybe<MenuCategoryType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  facility: FacilityCreateNestedOneWithoutMenuCategoriesInput;
  id?: Maybe<Scalars['String']>;
  menuItemType?: Maybe<MenuItemType>;
  menuItems?: Maybe<KitchenMenuItemCreateNestedManyWithoutMenuCategoriesInput>;
  menuScheduleCategories?: Maybe<MenuScheduleCategoryCreateNestedManyWithoutCategoryInput>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MenuCategoryCreateWithoutMenuItemsInput = {
  categoryType?: Maybe<MenuCategoryType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  facility: FacilityCreateNestedOneWithoutMenuCategoriesInput;
  id?: Maybe<Scalars['String']>;
  kitchen?: Maybe<KitchenCreateNestedOneWithoutMenuCategoriesInput>;
  menuItemType?: Maybe<MenuItemType>;
  menuScheduleCategories?: Maybe<MenuScheduleCategoryCreateNestedManyWithoutCategoryInput>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MenuCategoryCreateWithoutMenuScheduleCategoriesInput = {
  categoryType?: Maybe<MenuCategoryType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  facility: FacilityCreateNestedOneWithoutMenuCategoriesInput;
  id?: Maybe<Scalars['String']>;
  kitchen?: Maybe<KitchenCreateNestedOneWithoutMenuCategoriesInput>;
  menuItemType?: Maybe<MenuItemType>;
  menuItems?: Maybe<KitchenMenuItemCreateNestedManyWithoutMenuCategoriesInput>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MenuCategoryListRelationFilter = {
  every?: Maybe<MenuCategoryWhereInput>;
  none?: Maybe<MenuCategoryWhereInput>;
  some?: Maybe<MenuCategoryWhereInput>;
};

export type MenuCategoryScalarWhereInput = {
  AND?: Maybe<Array<MenuCategoryScalarWhereInput>>;
  NOT?: Maybe<Array<MenuCategoryScalarWhereInput>>;
  OR?: Maybe<Array<MenuCategoryScalarWhereInput>>;
  categoryType?: Maybe<EnumMenuCategoryTypeFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  facilityId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  kitchenId?: Maybe<StringNullableFilter>;
  menuItemType?: Maybe<EnumMenuItemTypeFilter>;
  name?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export enum MenuCategoryType {
  Catering = 'CATERING',
  Regular = 'REGULAR',
  Special = 'SPECIAL'
}

export type MenuCategoryUpdateManyMutationInput = {
  categoryType?: Maybe<EnumMenuCategoryTypeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  menuItemType?: Maybe<EnumMenuItemTypeFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type MenuCategoryUpdateManyWithWhereWithoutFacilityInput = {
  data: MenuCategoryUpdateManyMutationInput;
  where: MenuCategoryScalarWhereInput;
};

export type MenuCategoryUpdateManyWithWhereWithoutKitchenInput = {
  data: MenuCategoryUpdateManyMutationInput;
  where: MenuCategoryScalarWhereInput;
};

export type MenuCategoryUpdateManyWithWhereWithoutMenuItemsInput = {
  data: MenuCategoryUpdateManyMutationInput;
  where: MenuCategoryScalarWhereInput;
};

export type MenuCategoryUpdateManyWithoutFacilityInput = {
  connect?: Maybe<Array<MenuCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MenuCategoryCreateOrConnectWithoutFacilityInput>>;
  create?: Maybe<Array<MenuCategoryCreateWithoutFacilityInput>>;
  createMany?: Maybe<MenuCategoryCreateManyFacilityInputEnvelope>;
  delete?: Maybe<Array<MenuCategoryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<MenuCategoryScalarWhereInput>>;
  disconnect?: Maybe<Array<MenuCategoryWhereUniqueInput>>;
  set?: Maybe<Array<MenuCategoryWhereUniqueInput>>;
  update?: Maybe<Array<MenuCategoryUpdateWithWhereUniqueWithoutFacilityInput>>;
  updateMany?: Maybe<Array<MenuCategoryUpdateManyWithWhereWithoutFacilityInput>>;
  upsert?: Maybe<Array<MenuCategoryUpsertWithWhereUniqueWithoutFacilityInput>>;
};

export type MenuCategoryUpdateManyWithoutKitchenInput = {
  connect?: Maybe<Array<MenuCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MenuCategoryCreateOrConnectWithoutKitchenInput>>;
  create?: Maybe<Array<MenuCategoryCreateWithoutKitchenInput>>;
  createMany?: Maybe<MenuCategoryCreateManyKitchenInputEnvelope>;
  delete?: Maybe<Array<MenuCategoryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<MenuCategoryScalarWhereInput>>;
  disconnect?: Maybe<Array<MenuCategoryWhereUniqueInput>>;
  set?: Maybe<Array<MenuCategoryWhereUniqueInput>>;
  update?: Maybe<Array<MenuCategoryUpdateWithWhereUniqueWithoutKitchenInput>>;
  updateMany?: Maybe<Array<MenuCategoryUpdateManyWithWhereWithoutKitchenInput>>;
  upsert?: Maybe<Array<MenuCategoryUpsertWithWhereUniqueWithoutKitchenInput>>;
};

export type MenuCategoryUpdateManyWithoutMenuItemsInput = {
  connect?: Maybe<Array<MenuCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MenuCategoryCreateOrConnectWithoutMenuItemsInput>>;
  create?: Maybe<Array<MenuCategoryCreateWithoutMenuItemsInput>>;
  delete?: Maybe<Array<MenuCategoryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<MenuCategoryScalarWhereInput>>;
  disconnect?: Maybe<Array<MenuCategoryWhereUniqueInput>>;
  set?: Maybe<Array<MenuCategoryWhereUniqueInput>>;
  update?: Maybe<Array<MenuCategoryUpdateWithWhereUniqueWithoutMenuItemsInput>>;
  updateMany?: Maybe<Array<MenuCategoryUpdateManyWithWhereWithoutMenuItemsInput>>;
  upsert?: Maybe<Array<MenuCategoryUpsertWithWhereUniqueWithoutMenuItemsInput>>;
};

export type MenuCategoryUpdateOneRequiredWithoutMenuScheduleCategoriesInput = {
  connect?: Maybe<MenuCategoryWhereUniqueInput>;
  connectOrCreate?: Maybe<MenuCategoryCreateOrConnectWithoutMenuScheduleCategoriesInput>;
  create?: Maybe<MenuCategoryCreateWithoutMenuScheduleCategoriesInput>;
  update?: Maybe<MenuCategoryUpdateWithoutMenuScheduleCategoriesInput>;
  upsert?: Maybe<MenuCategoryUpsertWithoutMenuScheduleCategoriesInput>;
};

export type MenuCategoryUpdateWithWhereUniqueWithoutFacilityInput = {
  data: MenuCategoryUpdateWithoutFacilityInput;
  where: MenuCategoryWhereUniqueInput;
};

export type MenuCategoryUpdateWithWhereUniqueWithoutKitchenInput = {
  data: MenuCategoryUpdateWithoutKitchenInput;
  where: MenuCategoryWhereUniqueInput;
};

export type MenuCategoryUpdateWithWhereUniqueWithoutMenuItemsInput = {
  data: MenuCategoryUpdateWithoutMenuItemsInput;
  where: MenuCategoryWhereUniqueInput;
};

export type MenuCategoryUpdateWithoutFacilityInput = {
  categoryType?: Maybe<EnumMenuCategoryTypeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  kitchen?: Maybe<KitchenUpdateOneWithoutMenuCategoriesInput>;
  menuItemType?: Maybe<EnumMenuItemTypeFieldUpdateOperationsInput>;
  menuItems?: Maybe<KitchenMenuItemUpdateManyWithoutMenuCategoriesInput>;
  menuScheduleCategories?: Maybe<MenuScheduleCategoryUpdateManyWithoutCategoryInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type MenuCategoryUpdateWithoutKitchenInput = {
  categoryType?: Maybe<EnumMenuCategoryTypeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutMenuCategoriesInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  menuItemType?: Maybe<EnumMenuItemTypeFieldUpdateOperationsInput>;
  menuItems?: Maybe<KitchenMenuItemUpdateManyWithoutMenuCategoriesInput>;
  menuScheduleCategories?: Maybe<MenuScheduleCategoryUpdateManyWithoutCategoryInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type MenuCategoryUpdateWithoutMenuItemsInput = {
  categoryType?: Maybe<EnumMenuCategoryTypeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutMenuCategoriesInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  kitchen?: Maybe<KitchenUpdateOneWithoutMenuCategoriesInput>;
  menuItemType?: Maybe<EnumMenuItemTypeFieldUpdateOperationsInput>;
  menuScheduleCategories?: Maybe<MenuScheduleCategoryUpdateManyWithoutCategoryInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type MenuCategoryUpdateWithoutMenuScheduleCategoriesInput = {
  categoryType?: Maybe<EnumMenuCategoryTypeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutMenuCategoriesInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  kitchen?: Maybe<KitchenUpdateOneWithoutMenuCategoriesInput>;
  menuItemType?: Maybe<EnumMenuItemTypeFieldUpdateOperationsInput>;
  menuItems?: Maybe<KitchenMenuItemUpdateManyWithoutMenuCategoriesInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type MenuCategoryUpsertWithWhereUniqueWithoutFacilityInput = {
  create: MenuCategoryCreateWithoutFacilityInput;
  update: MenuCategoryUpdateWithoutFacilityInput;
  where: MenuCategoryWhereUniqueInput;
};

export type MenuCategoryUpsertWithWhereUniqueWithoutKitchenInput = {
  create: MenuCategoryCreateWithoutKitchenInput;
  update: MenuCategoryUpdateWithoutKitchenInput;
  where: MenuCategoryWhereUniqueInput;
};

export type MenuCategoryUpsertWithWhereUniqueWithoutMenuItemsInput = {
  create: MenuCategoryCreateWithoutMenuItemsInput;
  update: MenuCategoryUpdateWithoutMenuItemsInput;
  where: MenuCategoryWhereUniqueInput;
};

export type MenuCategoryUpsertWithoutMenuScheduleCategoriesInput = {
  create: MenuCategoryCreateWithoutMenuScheduleCategoriesInput;
  update: MenuCategoryUpdateWithoutMenuScheduleCategoriesInput;
};

export type MenuCategoryWhereInput = {
  AND?: Maybe<Array<MenuCategoryWhereInput>>;
  NOT?: Maybe<Array<MenuCategoryWhereInput>>;
  OR?: Maybe<Array<MenuCategoryWhereInput>>;
  categoryType?: Maybe<EnumMenuCategoryTypeFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  facility?: Maybe<FacilityWhereInput>;
  facilityId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  kitchen?: Maybe<KitchenWhereInput>;
  kitchenId?: Maybe<StringNullableFilter>;
  menuItemType?: Maybe<EnumMenuItemTypeFilter>;
  menuItems?: Maybe<KitchenMenuItemListRelationFilter>;
  menuScheduleCategories?: Maybe<MenuScheduleCategoryListRelationFilter>;
  name?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type MenuCategoryWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum MenuItemAvailability {
  Catering = 'CATERING',
  Consumer = 'CONSUMER',
  Kiosk = 'KIOSK',
  PickupOnly = 'PICKUP_ONLY'
}

export enum MenuItemLabel {
  Featured = 'FEATURED',
  New = 'NEW',
  Special = 'SPECIAL',
  ThreeCourses = 'THREE_COURSES'
}

export enum MenuItemType {
  Drink = 'DRINK',
  Food = 'FOOD',
  MealPack = 'MEAL_PACK'
}

export type MenuSchedule = {
  __typename?: 'MenuSchedule';
  end: Scalars['DateTime'];
  id: Scalars['String'];
  isActive: Scalars['Boolean'];
  kitchen?: Maybe<Kitchen>;
  name?: Maybe<Scalars['String']>;
  repeatOnDays: Array<Scalars['Int']>;
  scheduleCategories: Array<MenuScheduleCategory>;
  sortedCategoryIds: Array<Scalars['String']>;
  start: Scalars['DateTime'];
};


export type MenuScheduleScheduleCategoriesArgs = {
  after?: Maybe<MenuScheduleCategoryWhereUniqueInput>;
  before?: Maybe<MenuScheduleCategoryWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type MenuScheduleCategory = {
  __typename?: 'MenuScheduleCategory';
  category: MenuCategory;
  id: Scalars['String'];
  menuItems: Array<KitchenMenuItem>;
  sortedMenuItemIds: Array<Scalars['String']>;
};


export type MenuScheduleCategoryMenuItemsArgs = {
  after?: Maybe<KitchenMenuItemWhereUniqueInput>;
  before?: Maybe<KitchenMenuItemWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type MenuScheduleCategoryCreateManyCategoryInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  menuScheduleId: Scalars['String'];
  sortedMenuItemIds?: Maybe<MenuScheduleCategoryCreateManysortedMenuItemIdsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MenuScheduleCategoryCreateManyCategoryInputEnvelope = {
  data?: Maybe<Array<MenuScheduleCategoryCreateManyCategoryInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MenuScheduleCategoryCreateManyMenuScheduleInput = {
  categoryId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  sortedMenuItemIds?: Maybe<MenuScheduleCategoryCreateManysortedMenuItemIdsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MenuScheduleCategoryCreateManyMenuScheduleInputEnvelope = {
  data?: Maybe<Array<MenuScheduleCategoryCreateManyMenuScheduleInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MenuScheduleCategoryCreateManysortedMenuItemIdsInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type MenuScheduleCategoryCreateNestedManyWithoutCategoryInput = {
  connect?: Maybe<Array<MenuScheduleCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MenuScheduleCategoryCreateOrConnectWithoutCategoryInput>>;
  create?: Maybe<Array<MenuScheduleCategoryCreateWithoutCategoryInput>>;
  createMany?: Maybe<MenuScheduleCategoryCreateManyCategoryInputEnvelope>;
};

export type MenuScheduleCategoryCreateNestedManyWithoutMenuItemsInput = {
  connect?: Maybe<Array<MenuScheduleCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MenuScheduleCategoryCreateOrConnectWithoutMenuItemsInput>>;
  create?: Maybe<Array<MenuScheduleCategoryCreateWithoutMenuItemsInput>>;
};

export type MenuScheduleCategoryCreateNestedManyWithoutMenuScheduleInput = {
  connect?: Maybe<Array<MenuScheduleCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MenuScheduleCategoryCreateOrConnectWithoutMenuScheduleInput>>;
  create?: Maybe<Array<MenuScheduleCategoryCreateWithoutMenuScheduleInput>>;
  createMany?: Maybe<MenuScheduleCategoryCreateManyMenuScheduleInputEnvelope>;
};

export type MenuScheduleCategoryCreateOrConnectWithoutCategoryInput = {
  create: MenuScheduleCategoryCreateWithoutCategoryInput;
  where: MenuScheduleCategoryWhereUniqueInput;
};

export type MenuScheduleCategoryCreateOrConnectWithoutMenuItemsInput = {
  create: MenuScheduleCategoryCreateWithoutMenuItemsInput;
  where: MenuScheduleCategoryWhereUniqueInput;
};

export type MenuScheduleCategoryCreateOrConnectWithoutMenuScheduleInput = {
  create: MenuScheduleCategoryCreateWithoutMenuScheduleInput;
  where: MenuScheduleCategoryWhereUniqueInput;
};

export type MenuScheduleCategoryCreateWithoutCategoryInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  menuItems?: Maybe<KitchenMenuItemCreateNestedManyWithoutMenuScheduleCategoriesInput>;
  menuSchedule: MenuScheduleCreateNestedOneWithoutScheduleCategoriesInput;
  sortedMenuItemIds?: Maybe<MenuScheduleCategoryCreatesortedMenuItemIdsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MenuScheduleCategoryCreateWithoutMenuItemsInput = {
  category: MenuCategoryCreateNestedOneWithoutMenuScheduleCategoriesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  menuSchedule: MenuScheduleCreateNestedOneWithoutScheduleCategoriesInput;
  sortedMenuItemIds?: Maybe<MenuScheduleCategoryCreatesortedMenuItemIdsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MenuScheduleCategoryCreateWithoutMenuScheduleInput = {
  category: MenuCategoryCreateNestedOneWithoutMenuScheduleCategoriesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  menuItems?: Maybe<KitchenMenuItemCreateNestedManyWithoutMenuScheduleCategoriesInput>;
  sortedMenuItemIds?: Maybe<MenuScheduleCategoryCreatesortedMenuItemIdsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MenuScheduleCategoryCreatesortedMenuItemIdsInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type MenuScheduleCategoryInput = {
  categoryId: Scalars['ID'];
  id?: Maybe<Scalars['ID']>;
  sortedMenuItemIds: Array<Scalars['String']>;
};

export type MenuScheduleCategoryListRelationFilter = {
  every?: Maybe<MenuScheduleCategoryWhereInput>;
  none?: Maybe<MenuScheduleCategoryWhereInput>;
  some?: Maybe<MenuScheduleCategoryWhereInput>;
};

export type MenuScheduleCategoryScalarWhereInput = {
  AND?: Maybe<Array<MenuScheduleCategoryScalarWhereInput>>;
  NOT?: Maybe<Array<MenuScheduleCategoryScalarWhereInput>>;
  OR?: Maybe<Array<MenuScheduleCategoryScalarWhereInput>>;
  categoryId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  menuScheduleId?: Maybe<StringFilter>;
  sortedMenuItemIds?: Maybe<StringNullableListFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type MenuScheduleCategoryUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  sortedMenuItemIds?: Maybe<MenuScheduleCategoryUpdatesortedMenuItemIdsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type MenuScheduleCategoryUpdateManyWithWhereWithoutCategoryInput = {
  data: MenuScheduleCategoryUpdateManyMutationInput;
  where: MenuScheduleCategoryScalarWhereInput;
};

export type MenuScheduleCategoryUpdateManyWithWhereWithoutMenuItemsInput = {
  data: MenuScheduleCategoryUpdateManyMutationInput;
  where: MenuScheduleCategoryScalarWhereInput;
};

export type MenuScheduleCategoryUpdateManyWithWhereWithoutMenuScheduleInput = {
  data: MenuScheduleCategoryUpdateManyMutationInput;
  where: MenuScheduleCategoryScalarWhereInput;
};

export type MenuScheduleCategoryUpdateManyWithoutCategoryInput = {
  connect?: Maybe<Array<MenuScheduleCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MenuScheduleCategoryCreateOrConnectWithoutCategoryInput>>;
  create?: Maybe<Array<MenuScheduleCategoryCreateWithoutCategoryInput>>;
  createMany?: Maybe<MenuScheduleCategoryCreateManyCategoryInputEnvelope>;
  delete?: Maybe<Array<MenuScheduleCategoryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<MenuScheduleCategoryScalarWhereInput>>;
  disconnect?: Maybe<Array<MenuScheduleCategoryWhereUniqueInput>>;
  set?: Maybe<Array<MenuScheduleCategoryWhereUniqueInput>>;
  update?: Maybe<Array<MenuScheduleCategoryUpdateWithWhereUniqueWithoutCategoryInput>>;
  updateMany?: Maybe<Array<MenuScheduleCategoryUpdateManyWithWhereWithoutCategoryInput>>;
  upsert?: Maybe<Array<MenuScheduleCategoryUpsertWithWhereUniqueWithoutCategoryInput>>;
};

export type MenuScheduleCategoryUpdateManyWithoutMenuItemsInput = {
  connect?: Maybe<Array<MenuScheduleCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MenuScheduleCategoryCreateOrConnectWithoutMenuItemsInput>>;
  create?: Maybe<Array<MenuScheduleCategoryCreateWithoutMenuItemsInput>>;
  delete?: Maybe<Array<MenuScheduleCategoryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<MenuScheduleCategoryScalarWhereInput>>;
  disconnect?: Maybe<Array<MenuScheduleCategoryWhereUniqueInput>>;
  set?: Maybe<Array<MenuScheduleCategoryWhereUniqueInput>>;
  update?: Maybe<Array<MenuScheduleCategoryUpdateWithWhereUniqueWithoutMenuItemsInput>>;
  updateMany?: Maybe<Array<MenuScheduleCategoryUpdateManyWithWhereWithoutMenuItemsInput>>;
  upsert?: Maybe<Array<MenuScheduleCategoryUpsertWithWhereUniqueWithoutMenuItemsInput>>;
};

export type MenuScheduleCategoryUpdateManyWithoutMenuScheduleInput = {
  connect?: Maybe<Array<MenuScheduleCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MenuScheduleCategoryCreateOrConnectWithoutMenuScheduleInput>>;
  create?: Maybe<Array<MenuScheduleCategoryCreateWithoutMenuScheduleInput>>;
  createMany?: Maybe<MenuScheduleCategoryCreateManyMenuScheduleInputEnvelope>;
  delete?: Maybe<Array<MenuScheduleCategoryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<MenuScheduleCategoryScalarWhereInput>>;
  disconnect?: Maybe<Array<MenuScheduleCategoryWhereUniqueInput>>;
  set?: Maybe<Array<MenuScheduleCategoryWhereUniqueInput>>;
  update?: Maybe<Array<MenuScheduleCategoryUpdateWithWhereUniqueWithoutMenuScheduleInput>>;
  updateMany?: Maybe<Array<MenuScheduleCategoryUpdateManyWithWhereWithoutMenuScheduleInput>>;
  upsert?: Maybe<Array<MenuScheduleCategoryUpsertWithWhereUniqueWithoutMenuScheduleInput>>;
};

export type MenuScheduleCategoryUpdateWithWhereUniqueWithoutCategoryInput = {
  data: MenuScheduleCategoryUpdateWithoutCategoryInput;
  where: MenuScheduleCategoryWhereUniqueInput;
};

export type MenuScheduleCategoryUpdateWithWhereUniqueWithoutMenuItemsInput = {
  data: MenuScheduleCategoryUpdateWithoutMenuItemsInput;
  where: MenuScheduleCategoryWhereUniqueInput;
};

export type MenuScheduleCategoryUpdateWithWhereUniqueWithoutMenuScheduleInput = {
  data: MenuScheduleCategoryUpdateWithoutMenuScheduleInput;
  where: MenuScheduleCategoryWhereUniqueInput;
};

export type MenuScheduleCategoryUpdateWithoutCategoryInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  menuItems?: Maybe<KitchenMenuItemUpdateManyWithoutMenuScheduleCategoriesInput>;
  menuSchedule?: Maybe<MenuScheduleUpdateOneRequiredWithoutScheduleCategoriesInput>;
  sortedMenuItemIds?: Maybe<MenuScheduleCategoryUpdatesortedMenuItemIdsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type MenuScheduleCategoryUpdateWithoutMenuItemsInput = {
  category?: Maybe<MenuCategoryUpdateOneRequiredWithoutMenuScheduleCategoriesInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  menuSchedule?: Maybe<MenuScheduleUpdateOneRequiredWithoutScheduleCategoriesInput>;
  sortedMenuItemIds?: Maybe<MenuScheduleCategoryUpdatesortedMenuItemIdsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type MenuScheduleCategoryUpdateWithoutMenuScheduleInput = {
  category?: Maybe<MenuCategoryUpdateOneRequiredWithoutMenuScheduleCategoriesInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  menuItems?: Maybe<KitchenMenuItemUpdateManyWithoutMenuScheduleCategoriesInput>;
  sortedMenuItemIds?: Maybe<MenuScheduleCategoryUpdatesortedMenuItemIdsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type MenuScheduleCategoryUpdatesortedMenuItemIdsInput = {
  push?: Maybe<Scalars['String']>;
  set?: Maybe<Array<Scalars['String']>>;
};

export type MenuScheduleCategoryUpsertWithWhereUniqueWithoutCategoryInput = {
  create: MenuScheduleCategoryCreateWithoutCategoryInput;
  update: MenuScheduleCategoryUpdateWithoutCategoryInput;
  where: MenuScheduleCategoryWhereUniqueInput;
};

export type MenuScheduleCategoryUpsertWithWhereUniqueWithoutMenuItemsInput = {
  create: MenuScheduleCategoryCreateWithoutMenuItemsInput;
  update: MenuScheduleCategoryUpdateWithoutMenuItemsInput;
  where: MenuScheduleCategoryWhereUniqueInput;
};

export type MenuScheduleCategoryUpsertWithWhereUniqueWithoutMenuScheduleInput = {
  create: MenuScheduleCategoryCreateWithoutMenuScheduleInput;
  update: MenuScheduleCategoryUpdateWithoutMenuScheduleInput;
  where: MenuScheduleCategoryWhereUniqueInput;
};

export type MenuScheduleCategoryWhereInput = {
  AND?: Maybe<Array<MenuScheduleCategoryWhereInput>>;
  NOT?: Maybe<Array<MenuScheduleCategoryWhereInput>>;
  OR?: Maybe<Array<MenuScheduleCategoryWhereInput>>;
  category?: Maybe<MenuCategoryWhereInput>;
  categoryId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  menuItems?: Maybe<KitchenMenuItemListRelationFilter>;
  menuSchedule?: Maybe<MenuScheduleWhereInput>;
  menuScheduleId?: Maybe<StringFilter>;
  sortedMenuItemIds?: Maybe<StringNullableListFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type MenuScheduleCategoryWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type MenuScheduleCreateManyFacilityInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  end: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  kitchenId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  repeatOnDays?: Maybe<MenuScheduleCreateManyrepeatOnDaysInput>;
  sortedCategoryIds?: Maybe<MenuScheduleCreateManysortedCategoryIdsInput>;
  start: Scalars['DateTime'];
  type?: Maybe<MenuScheduleType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MenuScheduleCreateManyFacilityInputEnvelope = {
  data?: Maybe<Array<MenuScheduleCreateManyFacilityInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MenuScheduleCreateManyKitchenInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  end: Scalars['DateTime'];
  facilityId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  repeatOnDays?: Maybe<MenuScheduleCreateManyrepeatOnDaysInput>;
  sortedCategoryIds?: Maybe<MenuScheduleCreateManysortedCategoryIdsInput>;
  start: Scalars['DateTime'];
  type?: Maybe<MenuScheduleType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MenuScheduleCreateManyKitchenInputEnvelope = {
  data?: Maybe<Array<MenuScheduleCreateManyKitchenInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MenuScheduleCreateManyrepeatOnDaysInput = {
  set?: Maybe<Array<Scalars['Int']>>;
};

export type MenuScheduleCreateManysortedCategoryIdsInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type MenuScheduleCreateNestedManyWithoutFacilityInput = {
  connect?: Maybe<Array<MenuScheduleWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MenuScheduleCreateOrConnectWithoutFacilityInput>>;
  create?: Maybe<Array<MenuScheduleCreateWithoutFacilityInput>>;
  createMany?: Maybe<MenuScheduleCreateManyFacilityInputEnvelope>;
};

export type MenuScheduleCreateNestedManyWithoutKitchenInput = {
  connect?: Maybe<Array<MenuScheduleWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MenuScheduleCreateOrConnectWithoutKitchenInput>>;
  create?: Maybe<Array<MenuScheduleCreateWithoutKitchenInput>>;
  createMany?: Maybe<MenuScheduleCreateManyKitchenInputEnvelope>;
};

export type MenuScheduleCreateNestedOneWithoutScheduleCategoriesInput = {
  connect?: Maybe<MenuScheduleWhereUniqueInput>;
  connectOrCreate?: Maybe<MenuScheduleCreateOrConnectWithoutScheduleCategoriesInput>;
  create?: Maybe<MenuScheduleCreateWithoutScheduleCategoriesInput>;
};

export type MenuScheduleCreateOrConnectWithoutFacilityInput = {
  create: MenuScheduleCreateWithoutFacilityInput;
  where: MenuScheduleWhereUniqueInput;
};

export type MenuScheduleCreateOrConnectWithoutKitchenInput = {
  create: MenuScheduleCreateWithoutKitchenInput;
  where: MenuScheduleWhereUniqueInput;
};

export type MenuScheduleCreateOrConnectWithoutScheduleCategoriesInput = {
  create: MenuScheduleCreateWithoutScheduleCategoriesInput;
  where: MenuScheduleWhereUniqueInput;
};

export type MenuScheduleCreateWithoutFacilityInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  end: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  kitchen?: Maybe<KitchenCreateNestedOneWithoutMenuSchedulesInput>;
  name?: Maybe<Scalars['String']>;
  repeatOnDays?: Maybe<MenuScheduleCreaterepeatOnDaysInput>;
  scheduleCategories?: Maybe<MenuScheduleCategoryCreateNestedManyWithoutMenuScheduleInput>;
  sortedCategoryIds?: Maybe<MenuScheduleCreatesortedCategoryIdsInput>;
  start: Scalars['DateTime'];
  type?: Maybe<MenuScheduleType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MenuScheduleCreateWithoutKitchenInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  end: Scalars['DateTime'];
  facility: FacilityCreateNestedOneWithoutMenuSchedulesInput;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  repeatOnDays?: Maybe<MenuScheduleCreaterepeatOnDaysInput>;
  scheduleCategories?: Maybe<MenuScheduleCategoryCreateNestedManyWithoutMenuScheduleInput>;
  sortedCategoryIds?: Maybe<MenuScheduleCreatesortedCategoryIdsInput>;
  start: Scalars['DateTime'];
  type?: Maybe<MenuScheduleType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MenuScheduleCreateWithoutScheduleCategoriesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  end: Scalars['DateTime'];
  facility: FacilityCreateNestedOneWithoutMenuSchedulesInput;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  kitchen?: Maybe<KitchenCreateNestedOneWithoutMenuSchedulesInput>;
  name?: Maybe<Scalars['String']>;
  repeatOnDays?: Maybe<MenuScheduleCreaterepeatOnDaysInput>;
  sortedCategoryIds?: Maybe<MenuScheduleCreatesortedCategoryIdsInput>;
  start: Scalars['DateTime'];
  type?: Maybe<MenuScheduleType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MenuScheduleCreaterepeatOnDaysInput = {
  set?: Maybe<Array<Scalars['Int']>>;
};

export type MenuScheduleCreatesortedCategoryIdsInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export enum MenuScheduleErrors {
  Overlap = 'OVERLAP',
  WrongDatetime = 'WRONG_DATETIME'
}

export type MenuScheduleListRelationFilter = {
  every?: Maybe<MenuScheduleWhereInput>;
  none?: Maybe<MenuScheduleWhereInput>;
  some?: Maybe<MenuScheduleWhereInput>;
};

export type MenuScheduleScalarWhereInput = {
  AND?: Maybe<Array<MenuScheduleScalarWhereInput>>;
  NOT?: Maybe<Array<MenuScheduleScalarWhereInput>>;
  OR?: Maybe<Array<MenuScheduleScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  end?: Maybe<DateTimeFilter>;
  facilityId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  isActive?: Maybe<BoolFilter>;
  kitchenId?: Maybe<StringNullableFilter>;
  name?: Maybe<StringNullableFilter>;
  repeatOnDays?: Maybe<IntNullableListFilter>;
  sortedCategoryIds?: Maybe<StringNullableListFilter>;
  start?: Maybe<DateTimeFilter>;
  type?: Maybe<EnumMenuScheduleTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export enum MenuScheduleType {
  Regular = 'REGULAR',
  Special = 'SPECIAL'
}

export type MenuScheduleUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  end?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isActive?: Maybe<BoolFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  repeatOnDays?: Maybe<MenuScheduleUpdaterepeatOnDaysInput>;
  sortedCategoryIds?: Maybe<MenuScheduleUpdatesortedCategoryIdsInput>;
  start?: Maybe<DateTimeFieldUpdateOperationsInput>;
  type?: Maybe<EnumMenuScheduleTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type MenuScheduleUpdateManyWithWhereWithoutFacilityInput = {
  data: MenuScheduleUpdateManyMutationInput;
  where: MenuScheduleScalarWhereInput;
};

export type MenuScheduleUpdateManyWithWhereWithoutKitchenInput = {
  data: MenuScheduleUpdateManyMutationInput;
  where: MenuScheduleScalarWhereInput;
};

export type MenuScheduleUpdateManyWithoutFacilityInput = {
  connect?: Maybe<Array<MenuScheduleWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MenuScheduleCreateOrConnectWithoutFacilityInput>>;
  create?: Maybe<Array<MenuScheduleCreateWithoutFacilityInput>>;
  createMany?: Maybe<MenuScheduleCreateManyFacilityInputEnvelope>;
  delete?: Maybe<Array<MenuScheduleWhereUniqueInput>>;
  deleteMany?: Maybe<Array<MenuScheduleScalarWhereInput>>;
  disconnect?: Maybe<Array<MenuScheduleWhereUniqueInput>>;
  set?: Maybe<Array<MenuScheduleWhereUniqueInput>>;
  update?: Maybe<Array<MenuScheduleUpdateWithWhereUniqueWithoutFacilityInput>>;
  updateMany?: Maybe<Array<MenuScheduleUpdateManyWithWhereWithoutFacilityInput>>;
  upsert?: Maybe<Array<MenuScheduleUpsertWithWhereUniqueWithoutFacilityInput>>;
};

export type MenuScheduleUpdateManyWithoutKitchenInput = {
  connect?: Maybe<Array<MenuScheduleWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MenuScheduleCreateOrConnectWithoutKitchenInput>>;
  create?: Maybe<Array<MenuScheduleCreateWithoutKitchenInput>>;
  createMany?: Maybe<MenuScheduleCreateManyKitchenInputEnvelope>;
  delete?: Maybe<Array<MenuScheduleWhereUniqueInput>>;
  deleteMany?: Maybe<Array<MenuScheduleScalarWhereInput>>;
  disconnect?: Maybe<Array<MenuScheduleWhereUniqueInput>>;
  set?: Maybe<Array<MenuScheduleWhereUniqueInput>>;
  update?: Maybe<Array<MenuScheduleUpdateWithWhereUniqueWithoutKitchenInput>>;
  updateMany?: Maybe<Array<MenuScheduleUpdateManyWithWhereWithoutKitchenInput>>;
  upsert?: Maybe<Array<MenuScheduleUpsertWithWhereUniqueWithoutKitchenInput>>;
};

export type MenuScheduleUpdateOneRequiredWithoutScheduleCategoriesInput = {
  connect?: Maybe<MenuScheduleWhereUniqueInput>;
  connectOrCreate?: Maybe<MenuScheduleCreateOrConnectWithoutScheduleCategoriesInput>;
  create?: Maybe<MenuScheduleCreateWithoutScheduleCategoriesInput>;
  update?: Maybe<MenuScheduleUpdateWithoutScheduleCategoriesInput>;
  upsert?: Maybe<MenuScheduleUpsertWithoutScheduleCategoriesInput>;
};

export type MenuScheduleUpdateWithWhereUniqueWithoutFacilityInput = {
  data: MenuScheduleUpdateWithoutFacilityInput;
  where: MenuScheduleWhereUniqueInput;
};

export type MenuScheduleUpdateWithWhereUniqueWithoutKitchenInput = {
  data: MenuScheduleUpdateWithoutKitchenInput;
  where: MenuScheduleWhereUniqueInput;
};

export type MenuScheduleUpdateWithoutFacilityInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  end?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isActive?: Maybe<BoolFieldUpdateOperationsInput>;
  kitchen?: Maybe<KitchenUpdateOneWithoutMenuSchedulesInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  repeatOnDays?: Maybe<MenuScheduleUpdaterepeatOnDaysInput>;
  scheduleCategories?: Maybe<MenuScheduleCategoryUpdateManyWithoutMenuScheduleInput>;
  sortedCategoryIds?: Maybe<MenuScheduleUpdatesortedCategoryIdsInput>;
  start?: Maybe<DateTimeFieldUpdateOperationsInput>;
  type?: Maybe<EnumMenuScheduleTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type MenuScheduleUpdateWithoutKitchenInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  end?: Maybe<DateTimeFieldUpdateOperationsInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutMenuSchedulesInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isActive?: Maybe<BoolFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  repeatOnDays?: Maybe<MenuScheduleUpdaterepeatOnDaysInput>;
  scheduleCategories?: Maybe<MenuScheduleCategoryUpdateManyWithoutMenuScheduleInput>;
  sortedCategoryIds?: Maybe<MenuScheduleUpdatesortedCategoryIdsInput>;
  start?: Maybe<DateTimeFieldUpdateOperationsInput>;
  type?: Maybe<EnumMenuScheduleTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type MenuScheduleUpdateWithoutScheduleCategoriesInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  end?: Maybe<DateTimeFieldUpdateOperationsInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutMenuSchedulesInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isActive?: Maybe<BoolFieldUpdateOperationsInput>;
  kitchen?: Maybe<KitchenUpdateOneWithoutMenuSchedulesInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  repeatOnDays?: Maybe<MenuScheduleUpdaterepeatOnDaysInput>;
  sortedCategoryIds?: Maybe<MenuScheduleUpdatesortedCategoryIdsInput>;
  start?: Maybe<DateTimeFieldUpdateOperationsInput>;
  type?: Maybe<EnumMenuScheduleTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type MenuScheduleUpdaterepeatOnDaysInput = {
  push?: Maybe<Scalars['Int']>;
  set?: Maybe<Array<Scalars['Int']>>;
};

export type MenuScheduleUpdatesortedCategoryIdsInput = {
  push?: Maybe<Scalars['String']>;
  set?: Maybe<Array<Scalars['String']>>;
};

export type MenuScheduleUpsertInput = {
  deleteScheduleCategoryIds: Array<Scalars['String']>;
  /** Contains both end day (Date) and hours (Time) */
  end: Scalars['DateTime'];
  id?: Maybe<Scalars['ID']>;
  isActive: Scalars['Boolean'];
  kitchenId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  /** [0 - Sun, 1 - Mon, 2 - Tue, 3 - Wen, 4 - Thu, 5 - Fri, 6 - Sat] (based on the https://www.w3schools.com/jsref/jsref_getday.asp) */
  repeatOnDays: Array<Scalars['Int']>;
  scheduleCategories: Array<MenuScheduleCategoryInput>;
  sortedCategoryIds: Array<Scalars['String']>;
  /** Contains both start day (Date) and hours (Time) */
  start: Scalars['DateTime'];
};

export type MenuScheduleUpsertWithWhereUniqueWithoutFacilityInput = {
  create: MenuScheduleCreateWithoutFacilityInput;
  update: MenuScheduleUpdateWithoutFacilityInput;
  where: MenuScheduleWhereUniqueInput;
};

export type MenuScheduleUpsertWithWhereUniqueWithoutKitchenInput = {
  create: MenuScheduleCreateWithoutKitchenInput;
  update: MenuScheduleUpdateWithoutKitchenInput;
  where: MenuScheduleWhereUniqueInput;
};

export type MenuScheduleUpsertWithoutScheduleCategoriesInput = {
  create: MenuScheduleCreateWithoutScheduleCategoriesInput;
  update: MenuScheduleUpdateWithoutScheduleCategoriesInput;
};

export type MenuScheduleWhereInput = {
  AND?: Maybe<Array<MenuScheduleWhereInput>>;
  NOT?: Maybe<Array<MenuScheduleWhereInput>>;
  OR?: Maybe<Array<MenuScheduleWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  end?: Maybe<DateTimeFilter>;
  facility?: Maybe<FacilityWhereInput>;
  facilityId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  isActive?: Maybe<BoolFilter>;
  kitchen?: Maybe<KitchenWhereInput>;
  kitchenId?: Maybe<StringNullableFilter>;
  name?: Maybe<StringNullableFilter>;
  repeatOnDays?: Maybe<IntNullableListFilter>;
  scheduleCategories?: Maybe<MenuScheduleCategoryListRelationFilter>;
  sortedCategoryIds?: Maybe<StringNullableListFilter>;
  start?: Maybe<DateTimeFilter>;
  type?: Maybe<EnumMenuScheduleTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type MenuScheduleWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type Message = {
  __typename?: 'Message';
  orderId: Scalars['String'];
  shortId: Scalars['String'];
  status: OrderStatus;
  type: MessageType;
};

export enum MessageType {
  OrderReminderNextDay = 'ORDER_REMINDER_NEXT_DAY',
  OrderReminderToday = 'ORDER_REMINDER_TODAY',
  OrderStatusDelivery = 'ORDER_STATUS_DELIVERY',
  PickupOrderStatusReady = 'PICKUP_ORDER_STATUS_READY',
  Test = 'TEST'
}

export enum MobilePlatform {
  Android = 'ANDROID',
  Ios = 'IOS'
}

export type Mutation = {
  __typename?: 'Mutation';
  adminAddCustomerTag: UserCustomerInfo;
  /** Mutation for creating menu category in the system */
  adminCreateMenuCategory: MenuCategory;
  adminCreateOneKitchenMenuItem: KitchenMenuItem;
  adminCreateOrderBundleManually: OrderBundle;
  adminCreatePowerBiReport: PowerBiReport;
  adminCreateStation: Station;
  adminDeleteKitchen: Kitchen;
  /** Mutation for deleting menu category in the system */
  adminDeleteMenuCategory: MenuCategory;
  /** For admin portal users to delete menu schedule. */
  adminDeleteMenuSchedule: MenuSchedule;
  /** For admin portal users to delete modifier choice. */
  adminDeleteModifierChoice: ExtraItem;
  /** For admin portal users to delete modifier group. */
  adminDeleteModifierGroup: Extra;
  adminDeleteOneKitchenMenuItem: KitchenMenuItem;
  /** For admin portal users to delete a promo facility rule */
  adminDeleteOnePromoRule: PromoFacilityRule;
  /** For Crave admin to delete method override. */
  adminDeleteOrderMethodOverride: OrderMethodOverride;
  adminDeletePowerBiReport?: Maybe<PowerBiReport>;
  /** For admin portal users to delete a promo. */
  adminDeletePromo: Promo;
  adminDeleteStation?: Maybe<Station>;
  adminNotifyFutureOrders: Scalars['Boolean'];
  adminRemoveCustomerTag: UserCustomerInfo;
  adminSendMessageToAll: Scalars['Boolean'];
  adminSendTestMessage: SendResponses;
  adminSortModifierChoices: Array<ExtraItem>;
  adminSortModifierGroups: Scalars['Boolean'];
  adminUpdateChoiceStatus: ExtraItem;
  adminUpdateEmail: EmailUpdate;
  adminUpdateFacilityKioskLogo: Facility;
  adminUpdateFacilityLogo: Facility;
  /** For Crave admin to update facility operation hours. */
  adminUpdateFacilityOperationHours: Facility;
  adminUpdateGlobalSort: Scalars['Boolean'];
  /** For admin portal users to update kitchen color which displays in menu planner. */
  adminUpdateKitchenColor: Kitchen;
  adminUpdateKitchenMenuItemInventory: KitchenMenuItem;
  adminUpdateKitchenMenuItemStatus: KitchenMenuItem;
  /** For restaurant owner to update kitchen operation hours. */
  adminUpdateKitchenOperationHours: Kitchen;
  /** For admin portal users to update menu schedule status. */
  adminUpdateMenuScheduleStatus: MenuSchedule;
  adminUpdateModifierDefaultChoice: Extra;
  adminUpdateOneKitchenMenuItem?: Maybe<KitchenMenuItem>;
  adminUpdateOneKitchenTicket?: Maybe<KitchenTicket>;
  adminUpdateOrderDeliveryTime: Scalars['Boolean'];
  /** For admin portal users to update order method status(active/not active). */
  adminUpdateOrderMethodOverrideStatus: OrderMethodOverride;
  adminUpdateOrderPaidAt: Order;
  adminUpdateOrderPreparingUntil: Scalars['Boolean'];
  adminUpdateOrderStatus: Order;
  adminUpdatePassword: Scalars['Boolean'];
  adminUpdatePowerBiReport?: Maybe<PowerBiReport>;
  adminUpdatePromoBanner?: Maybe<PromoBanner>;
  adminUpdateReportSort: Scalars['Boolean'];
  adminUpdateSort: Scalars['Boolean'];
  adminUpdateStation?: Maybe<Station>;
  adminUpdateUser: User;
  adminUpdateUserAddress: Address;
  adminUpsertKitchen: Kitchen;
  /** For concepts to create and update menu schedule. */
  adminUpsertMenuSchedule: MenuSchedule;
  /** For concepts to create and update a modifier. */
  adminUpsertModifier: Extra;
  /** For Crave admin to create or update method override. */
  adminUpsertOrderMethodOverride: OrderMethodOverride;
  /** For creating and updating promos */
  adminUpsertPromo: Promo;
  /** For Crave admin to create and update special menu schedule. */
  adminUpsertSpecialMenuSchedule: MenuSchedule;
  clientLog: Scalars['Boolean'];
  createOrderBundles: Array<OrderBundle>;
  customerClearCart: UserCustomerInfo;
  customerConfirmKioskOrder: KioskOrderConfirmation;
  customerConfirmOrder: OrderConfirmation;
  customerCreateBraintreeClientToken: Scalars['String'];
  customerCreateCustomerLead: CustomerLead;
  customerCreateUser: User;
  customerCreateUserAddress: Address;
  customerDeleteAddress: Scalars['Boolean'];
  customerDeleteCartItem?: Maybe<UserCustomerInfo>;
  customerDeleteMyAccount: Scalars['Boolean'];
  customerKlaviyoReceipt: Scalars['Boolean'];
  customerRegisterDevice: Device;
  customerResetPassword: Scalars['Boolean'];
  customerSetDefaultAddress?: Maybe<Address>;
  customerSetupConsumerApp: ConsumerAppSetup;
  customerUpdateMenuItemsOnCart: UserCustomerInfo;
  customerUpdatePromosOnCart: UserCustomerInfo;
  customerUpdateUser: User;
  customerUpdateUserAddress: Address;
  customerUpdateUtensilsOnCart: UserCustomerInfo;
  customerUpsertCartItem: UserCustomerInfo;
  customerValidateKioskPromo: KioskOrderValidation;
  customerValidateOrder: OrderValidation;
  kdsCompleteKitchenTicket: KitchenTicket;
  kdsKitchenTicketStartPreparing: KitchenTicket;
  kdsOrderPickupComplete: Order;
  kdsRefireKitchenTicket: Order;
  kdsRefireOrder: Order;
  kdsRefireOrderItem: Order;
  kdsSetOrderWorkplace: Order;
  /** @deprecated Use more generic kdsSetOrderWorkplace */
  kdsSetPickupStation: Order;
  kdsUndoOrder: Order;
  kdsUndoTicket: KitchenTicket;
  setupConsumerApp: ConsumerAppSetup;
};


export type MutationAdminAddCustomerTagArgs = {
  customerId: Scalars['ID'];
  tag: TagCreateOrConnectInput;
};


export type MutationAdminCreateMenuCategoryArgs = {
  input: MenuCategoryCreateInput;
};


export type MutationAdminCreateOneKitchenMenuItemArgs = {
  data: KitchenMenuItemCreateInput;
};


export type MutationAdminCreateOrderBundleManuallyArgs = {
  orderId: Scalars['ID'];
};


export type MutationAdminCreatePowerBiReportArgs = {
  data: PowerBiReportCreateInput;
};


export type MutationAdminCreateStationArgs = {
  data: StationCreateInput;
};


export type MutationAdminDeleteKitchenArgs = {
  id: Scalars['ID'];
};


export type MutationAdminDeleteMenuCategoryArgs = {
  id: Scalars['ID'];
};


export type MutationAdminDeleteMenuScheduleArgs = {
  id: Scalars['ID'];
};


export type MutationAdminDeleteModifierChoiceArgs = {
  id: Scalars['ID'];
};


export type MutationAdminDeleteModifierGroupArgs = {
  id: Scalars['ID'];
};


export type MutationAdminDeleteOneKitchenMenuItemArgs = {
  id: Scalars['ID'];
};


export type MutationAdminDeleteOnePromoRuleArgs = {
  id: Scalars['ID'];
};


export type MutationAdminDeleteOrderMethodOverrideArgs = {
  id: Scalars['ID'];
};


export type MutationAdminDeletePowerBiReportArgs = {
  where: PowerBiReportWhereUniqueInput;
};


export type MutationAdminDeletePromoArgs = {
  id: Scalars['ID'];
};


export type MutationAdminDeleteStationArgs = {
  where: StationWhereUniqueInput;
};


export type MutationAdminNotifyFutureOrdersArgs = {
  send?: Maybe<Scalars['Boolean']>;
};


export type MutationAdminRemoveCustomerTagArgs = {
  customerId: Scalars['ID'];
  tagId: Scalars['ID'];
};


export type MutationAdminSendMessageToAllArgs = {
  message: Scalars['String'];
  send?: Maybe<Scalars['Boolean']>;
  title: Scalars['String'];
};


export type MutationAdminSendTestMessageArgs = {
  dryRun?: Maybe<Scalars['Boolean']>;
  type?: Maybe<MessageType>;
};


export type MutationAdminSortModifierChoicesArgs = {
  input: Array<Scalars['String']>;
};


export type MutationAdminSortModifierGroupsArgs = {
  input: Array<Scalars['String']>;
};


export type MutationAdminUpdateChoiceStatusArgs = {
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
};


export type MutationAdminUpdateEmailArgs = {
  input: EmailUpdateInput;
};


export type MutationAdminUpdateFacilityKioskLogoArgs = {
  id: Scalars['ID'];
  kioskLogoUrl?: Maybe<Scalars['String']>;
};


export type MutationAdminUpdateFacilityLogoArgs = {
  id: Scalars['ID'];
  logoUrl?: Maybe<Scalars['String']>;
};


export type MutationAdminUpdateFacilityOperationHoursArgs = {
  input: OperationHoursUpdateInput;
};


export type MutationAdminUpdateGlobalSortArgs = {
  input: Array<Scalars['String']>;
};


export type MutationAdminUpdateKitchenColorArgs = {
  color: Scalars['String'];
  id: Scalars['ID'];
};


export type MutationAdminUpdateKitchenMenuItemInventoryArgs = {
  id: Scalars['ID'];
  inventoryCount: Scalars['Int'];
};


export type MutationAdminUpdateKitchenMenuItemStatusArgs = {
  id: Scalars['ID'];
  status: KitchenMenuItemStatus;
};


export type MutationAdminUpdateKitchenOperationHoursArgs = {
  input: OperationHoursUpdateInput;
};


export type MutationAdminUpdateMenuScheduleStatusArgs = {
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
};


export type MutationAdminUpdateModifierDefaultChoiceArgs = {
  defaultItemId: Scalars['String'];
  id: Scalars['ID'];
};


export type MutationAdminUpdateOneKitchenMenuItemArgs = {
  data: KitchenMenuItemUpdateInput;
  where: KitchenMenuItemWhereUniqueInput;
};


export type MutationAdminUpdateOneKitchenTicketArgs = {
  data: KitchenTicketUpdateInput;
  where: KitchenTicketWhereUniqueInput;
};


export type MutationAdminUpdateOrderDeliveryTimeArgs = {
  input: UpdateDeliveryInput;
};


export type MutationAdminUpdateOrderMethodOverrideStatusArgs = {
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
};


export type MutationAdminUpdateOrderPaidAtArgs = {
  isPaid: Scalars['Boolean'];
  orderId: Scalars['ID'];
};


export type MutationAdminUpdateOrderPreparingUntilArgs = {
  input: UpdateOrderPreparingUntilInput;
};


export type MutationAdminUpdateOrderStatusArgs = {
  input: OrderStatusInput;
};


export type MutationAdminUpdatePasswordArgs = {
  input: PasswordUpdateInput;
};


export type MutationAdminUpdatePowerBiReportArgs = {
  data: PowerBiReportUpdateInput;
  where: PowerBiReportWhereUniqueInput;
};


export type MutationAdminUpdatePromoBannerArgs = {
  data: PromoBannerUpdateInput;
  where: PromoBannerWhereUniqueInput;
};


export type MutationAdminUpdateReportSortArgs = {
  input: Array<Scalars['String']>;
};


export type MutationAdminUpdateSortArgs = {
  input: Array<Scalars['String']>;
};


export type MutationAdminUpdateStationArgs = {
  data: StationUpdateInput;
  where: StationWhereUniqueInput;
};


export type MutationAdminUpdateUserArgs = {
  input: AdminUserUpdateInput;
};


export type MutationAdminUpdateUserAddressArgs = {
  input: AdminAddressUpdateInput;
};


export type MutationAdminUpsertKitchenArgs = {
  input: KitchenUpsertInput;
};


export type MutationAdminUpsertMenuScheduleArgs = {
  input: MenuScheduleUpsertInput;
};


export type MutationAdminUpsertModifierArgs = {
  input: ExtraUpsertInput;
};


export type MutationAdminUpsertOrderMethodOverrideArgs = {
  input: OrderMethodOverrideUpsertInput;
};


export type MutationAdminUpsertPromoArgs = {
  input: PromoUpsertInput;
};


export type MutationAdminUpsertSpecialMenuScheduleArgs = {
  input: SpecialMenuScheduleUpsertInput;
};


export type MutationClientLogArgs = {
  message: Scalars['String'];
  meta?: Maybe<Scalars['String']>;
};


export type MutationCreateOrderBundlesArgs = {
  date?: Maybe<Scalars['DateTime']>;
};


export type MutationCustomerConfirmKioskOrderArgs = {
  input: ConfirmKioskOrderInput;
};


export type MutationCustomerConfirmOrderArgs = {
  input: ConfirmOrderInput;
};


export type MutationCustomerCreateCustomerLeadArgs = {
  data: CustomerLeadCreateInput;
};


export type MutationCustomerCreateUserArgs = {
  input: UserCreateInput;
};


export type MutationCustomerCreateUserAddressArgs = {
  input: AddressCreateInput;
};


export type MutationCustomerDeleteAddressArgs = {
  id: Scalars['ID'];
};


export type MutationCustomerDeleteCartItemArgs = {
  input: DeleteCartItemInput;
};


export type MutationCustomerKlaviyoReceiptArgs = {
  checked?: Maybe<Scalars['Boolean']>;
  email: Scalars['String'];
  orderId: Scalars['ID'];
};


export type MutationCustomerRegisterDeviceArgs = {
  input: DeviceRegisterInput;
};


export type MutationCustomerResetPasswordArgs = {
  email: Scalars['String'];
};


export type MutationCustomerSetDefaultAddressArgs = {
  id: Scalars['ID'];
};


export type MutationCustomerSetupConsumerAppArgs = {
  input: SetupConsumerAppInput;
};


export type MutationCustomerUpdateMenuItemsOnCartArgs = {
  items?: Maybe<Array<Maybe<KitchenMenuItemInput>>>;
};


export type MutationCustomerUpdatePromosOnCartArgs = {
  orderDate?: Maybe<Scalars['DateTime']>;
  promoCodes: Array<Scalars['String']>;
};


export type MutationCustomerUpdateUserArgs = {
  input: UserUpdateInput;
};


export type MutationCustomerUpdateUserAddressArgs = {
  input: AddressUpdateInput;
};


export type MutationCustomerUpdateUtensilsOnCartArgs = {
  utensilsCount: Scalars['Int'];
};


export type MutationCustomerUpsertCartItemArgs = {
  input: UpsertCartItemInput;
};


export type MutationCustomerValidateKioskPromoArgs = {
  input: KioskPromoInput;
};


export type MutationCustomerValidateOrderArgs = {
  input: OrderInput;
};


export type MutationKdsCompleteKitchenTicketArgs = {
  id: Scalars['ID'];
};


export type MutationKdsKitchenTicketStartPreparingArgs = {
  ticketId: Scalars['ID'];
};


export type MutationKdsOrderPickupCompleteArgs = {
  orderId: Scalars['ID'];
};


export type MutationKdsRefireKitchenTicketArgs = {
  input: RefireKitchenTicketInput;
};


export type MutationKdsRefireOrderArgs = {
  input: RefireOrderInput;
};


export type MutationKdsRefireOrderItemArgs = {
  input: RefireOrderItemInput;
};


export type MutationKdsSetOrderWorkplaceArgs = {
  index: Scalars['Int'];
  orderId: Scalars['ID'];
  type: WorkplaceType;
};


export type MutationKdsSetPickupStationArgs = {
  orderId: Scalars['ID'];
  stationIndex: Scalars['Int'];
};


export type MutationKdsUndoOrderArgs = {
  input: UndoOrderInput;
};


export type MutationKdsUndoTicketArgs = {
  input: UndoTicketInput;
};


export type MutationSetupConsumerAppArgs = {
  input: SetupConsumerAppInput;
};

export type NestedBoolFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolFilter>;
};

export type NestedBoolNullableFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolNullableFilter>;
};

export type NestedDateTimeFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeFilter>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeNullableFilter>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
};

export type NestedEnumBraintreePaymentMethodFilter = {
  equals?: Maybe<BraintreePaymentMethod>;
  in?: Maybe<Array<BraintreePaymentMethod>>;
  not?: Maybe<NestedEnumBraintreePaymentMethodFilter>;
  notIn?: Maybe<Array<BraintreePaymentMethod>>;
};

export type NestedEnumBraintreePaymentSuccessStatusFilter = {
  equals?: Maybe<BraintreePaymentSuccessStatus>;
  in?: Maybe<Array<BraintreePaymentSuccessStatus>>;
  not?: Maybe<NestedEnumBraintreePaymentSuccessStatusFilter>;
  notIn?: Maybe<Array<BraintreePaymentSuccessStatus>>;
};

export type NestedEnumBusinessTypeNullableFilter = {
  equals?: Maybe<BusinessType>;
  in?: Maybe<Array<BusinessType>>;
  not?: Maybe<NestedEnumBusinessTypeNullableFilter>;
  notIn?: Maybe<Array<BusinessType>>;
};

export type NestedEnumCustomerRatingTypeFilter = {
  equals?: Maybe<CustomerRatingType>;
  in?: Maybe<Array<CustomerRatingType>>;
  not?: Maybe<NestedEnumCustomerRatingTypeFilter>;
  notIn?: Maybe<Array<CustomerRatingType>>;
};

export type NestedEnumDeliveryOptionNullableFilter = {
  equals?: Maybe<DeliveryOption>;
  in?: Maybe<Array<DeliveryOption>>;
  not?: Maybe<NestedEnumDeliveryOptionNullableFilter>;
  notIn?: Maybe<Array<DeliveryOption>>;
};

export type NestedEnumDeviceStatusFilter = {
  equals?: Maybe<DeviceStatus>;
  in?: Maybe<Array<DeviceStatus>>;
  not?: Maybe<NestedEnumDeviceStatusFilter>;
  notIn?: Maybe<Array<DeviceStatus>>;
};

export type NestedEnumInventoryStatusFilter = {
  equals?: Maybe<InventoryStatus>;
  in?: Maybe<Array<InventoryStatus>>;
  not?: Maybe<NestedEnumInventoryStatusFilter>;
  notIn?: Maybe<Array<InventoryStatus>>;
};

export type NestedEnumKdsTypeFilter = {
  equals?: Maybe<KdsType>;
  in?: Maybe<Array<KdsType>>;
  not?: Maybe<NestedEnumKdsTypeFilter>;
  notIn?: Maybe<Array<KdsType>>;
};

export type NestedEnumKitchenMenuItemStatusFilter = {
  equals?: Maybe<KitchenMenuItemStatus>;
  in?: Maybe<Array<KitchenMenuItemStatus>>;
  not?: Maybe<NestedEnumKitchenMenuItemStatusFilter>;
  notIn?: Maybe<Array<KitchenMenuItemStatus>>;
};

export type NestedEnumKitchenStatusFilter = {
  equals?: Maybe<KitchenStatus>;
  in?: Maybe<Array<KitchenStatus>>;
  not?: Maybe<NestedEnumKitchenStatusFilter>;
  notIn?: Maybe<Array<KitchenStatus>>;
};

export type NestedEnumKitchenTicketDelayReasonNullableFilter = {
  equals?: Maybe<KitchenTicketDelayReason>;
  in?: Maybe<Array<KitchenTicketDelayReason>>;
  not?: Maybe<NestedEnumKitchenTicketDelayReasonNullableFilter>;
  notIn?: Maybe<Array<KitchenTicketDelayReason>>;
};

export type NestedEnumKitchenTicketStatusFilter = {
  equals?: Maybe<KitchenTicketStatus>;
  in?: Maybe<Array<KitchenTicketStatus>>;
  not?: Maybe<NestedEnumKitchenTicketStatusFilter>;
  notIn?: Maybe<Array<KitchenTicketStatus>>;
};

export type NestedEnumKitchenTypeFilter = {
  equals?: Maybe<KitchenType>;
  in?: Maybe<Array<KitchenType>>;
  not?: Maybe<NestedEnumKitchenTypeFilter>;
  notIn?: Maybe<Array<KitchenType>>;
};

export type NestedEnumMenuCategoryTypeFilter = {
  equals?: Maybe<MenuCategoryType>;
  in?: Maybe<Array<MenuCategoryType>>;
  not?: Maybe<NestedEnumMenuCategoryTypeFilter>;
  notIn?: Maybe<Array<MenuCategoryType>>;
};

export type NestedEnumMenuItemTypeFilter = {
  equals?: Maybe<MenuItemType>;
  in?: Maybe<Array<MenuItemType>>;
  not?: Maybe<NestedEnumMenuItemTypeFilter>;
  notIn?: Maybe<Array<MenuItemType>>;
};

export type NestedEnumMenuScheduleTypeFilter = {
  equals?: Maybe<MenuScheduleType>;
  in?: Maybe<Array<MenuScheduleType>>;
  not?: Maybe<NestedEnumMenuScheduleTypeFilter>;
  notIn?: Maybe<Array<MenuScheduleType>>;
};

export type NestedEnumOrderBundleStatusFilter = {
  equals?: Maybe<OrderBundleStatus>;
  in?: Maybe<Array<OrderBundleStatus>>;
  not?: Maybe<NestedEnumOrderBundleStatusFilter>;
  notIn?: Maybe<Array<OrderBundleStatus>>;
};

export type NestedEnumOrderEventTypeFilter = {
  equals?: Maybe<OrderEventType>;
  in?: Maybe<Array<OrderEventType>>;
  not?: Maybe<NestedEnumOrderEventTypeFilter>;
  notIn?: Maybe<Array<OrderEventType>>;
};

export type NestedEnumOrderItemTypeFilter = {
  equals?: Maybe<OrderItemType>;
  in?: Maybe<Array<OrderItemType>>;
  not?: Maybe<NestedEnumOrderItemTypeFilter>;
  notIn?: Maybe<Array<OrderItemType>>;
};

export type NestedEnumOrderMethodFilter = {
  equals?: Maybe<OrderMethod>;
  in?: Maybe<Array<OrderMethod>>;
  not?: Maybe<NestedEnumOrderMethodFilter>;
  notIn?: Maybe<Array<OrderMethod>>;
};

export type NestedEnumOrderStatusFilter = {
  equals?: Maybe<OrderStatus>;
  in?: Maybe<Array<OrderStatus>>;
  not?: Maybe<NestedEnumOrderStatusFilter>;
  notIn?: Maybe<Array<OrderStatus>>;
};

export type NestedEnumPlatformFilter = {
  equals?: Maybe<Platform>;
  in?: Maybe<Array<Platform>>;
  not?: Maybe<NestedEnumPlatformFilter>;
  notIn?: Maybe<Array<Platform>>;
};

export type NestedEnumPromoAppliesToFilter = {
  equals?: Maybe<PromoAppliesTo>;
  in?: Maybe<Array<PromoAppliesTo>>;
  not?: Maybe<NestedEnumPromoAppliesToFilter>;
  notIn?: Maybe<Array<PromoAppliesTo>>;
};

export type NestedEnumPromoEligibilityFilter = {
  equals?: Maybe<PromoEligibility>;
  in?: Maybe<Array<PromoEligibility>>;
  not?: Maybe<NestedEnumPromoEligibilityFilter>;
  notIn?: Maybe<Array<PromoEligibility>>;
};

export type NestedEnumPromoRequirementFilter = {
  equals?: Maybe<PromoRequirement>;
  in?: Maybe<Array<PromoRequirement>>;
  not?: Maybe<NestedEnumPromoRequirementFilter>;
  notIn?: Maybe<Array<PromoRequirement>>;
};

export type NestedEnumPromoStatusFilter = {
  equals?: Maybe<PromoStatus>;
  in?: Maybe<Array<PromoStatus>>;
  not?: Maybe<NestedEnumPromoStatusFilter>;
  notIn?: Maybe<Array<PromoStatus>>;
};

export type NestedEnumPromoTypeFilter = {
  equals?: Maybe<PromoType>;
  in?: Maybe<Array<PromoType>>;
  not?: Maybe<NestedEnumPromoTypeFilter>;
  notIn?: Maybe<Array<PromoType>>;
};

export type NestedEnumPromoUsageLimitsFilter = {
  equals?: Maybe<PromoUsageLimits>;
  in?: Maybe<Array<PromoUsageLimits>>;
  not?: Maybe<NestedEnumPromoUsageLimitsFilter>;
  notIn?: Maybe<Array<PromoUsageLimits>>;
};

export type NestedEnumRefireReasonNullableFilter = {
  equals?: Maybe<RefireReason>;
  in?: Maybe<Array<RefireReason>>;
  not?: Maybe<NestedEnumRefireReasonNullableFilter>;
  notIn?: Maybe<Array<RefireReason>>;
};

export type NestedEnumWorkplaceTypeFilter = {
  equals?: Maybe<WorkplaceType>;
  in?: Maybe<Array<WorkplaceType>>;
  not?: Maybe<NestedEnumWorkplaceTypeFilter>;
  notIn?: Maybe<Array<WorkplaceType>>;
};

export type NestedFloatFilter = {
  equals?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatFilter>;
  notIn?: Maybe<Array<Scalars['Float']>>;
};

export type NestedFloatNullableFilter = {
  equals?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatNullableFilter>;
  notIn?: Maybe<Array<Scalars['Float']>>;
};

export type NestedIntFilter = {
  equals?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntFilter>;
  notIn?: Maybe<Array<Scalars['Int']>>;
};

export type NestedIntNullableFilter = {
  equals?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntNullableFilter>;
  notIn?: Maybe<Array<Scalars['Int']>>;
};

export type NestedStringFilter = {
  contains?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  equals?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringFilter>;
  notIn?: Maybe<Array<Scalars['String']>>;
  startsWith?: Maybe<Scalars['String']>;
};

export type NestedStringNullableFilter = {
  contains?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  equals?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringNullableFilter>;
  notIn?: Maybe<Array<Scalars['String']>>;
  startsWith?: Maybe<Scalars['String']>;
};

export type NullableBoolFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Boolean']>;
};

export type NullableDateTimeFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['DateTime']>;
};

export type NullableEnumBusinessTypeFieldUpdateOperationsInput = {
  set?: Maybe<BusinessType>;
};

export type NullableEnumDeliveryOptionFieldUpdateOperationsInput = {
  set?: Maybe<DeliveryOption>;
};

export type NullableEnumKitchenTicketDelayReasonFieldUpdateOperationsInput = {
  set?: Maybe<KitchenTicketDelayReason>;
};

export type NullableEnumRefireReasonFieldUpdateOperationsInput = {
  set?: Maybe<RefireReason>;
};

export type NullableFloatFieldUpdateOperationsInput = {
  decrement?: Maybe<Scalars['Float']>;
  divide?: Maybe<Scalars['Float']>;
  increment?: Maybe<Scalars['Float']>;
  multiply?: Maybe<Scalars['Float']>;
  set?: Maybe<Scalars['Float']>;
};

export type NullableIntFieldUpdateOperationsInput = {
  decrement?: Maybe<Scalars['Int']>;
  divide?: Maybe<Scalars['Int']>;
  increment?: Maybe<Scalars['Int']>;
  multiply?: Maybe<Scalars['Int']>;
  set?: Maybe<Scalars['Int']>;
};

export type NullableStringFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['String']>;
};

export type OperationHoursInput = {
  /** 0 for Sunday..6 for Saturday */
  day: Scalars['Int'];
  endHour: Scalars['Int'];
  endMinute: Scalars['Int'];
  isClosed: Scalars['Boolean'];
  startHour: Scalars['Int'];
  startMinute: Scalars['Int'];
};

export type OperationHoursUpdateInput = {
  id: Scalars['ID'];
  operationHours: Array<OperationHoursInput>;
};

export type OperationsAggregatedReport = {
  __typename?: 'OperationsAggregatedReport';
  date: Scalars['DateTime'];
  delivery: Scalars['Float'];
  expo: Scalars['Float'];
  kitchen: Scalars['Float'];
  packager: Scalars['Float'];
  runner: Scalars['Float'];
  timeOfDay: Scalars['String'];
  timeslotTime: Scalars['String'];
};

export type OperationsReport = {
  __typename?: 'OperationsReport';
  date: Scalars['DateTime'];
  delivery: Scalars['Int'];
  deliveryFinish?: Maybe<Scalars['DateTime']>;
  deliveryStart?: Maybe<Scalars['DateTime']>;
  expo: Scalars['Int'];
  kitchen: Scalars['Int'];
  orderShortId: Scalars['String'];
  packager: Scalars['Int'];
  runner: Scalars['Int'];
  startedAt: Scalars['DateTime'];
  submittedAt: Scalars['DateTime'];
  timePerKitchen: Scalars['Json'];
  timeslot: Scalars['DateTime'];
  timeslotTime: Scalars['DateTime'];
};

export type Order = {
  __typename?: 'Order';
  addressNote?: Maybe<Scalars['String']>;
  braintreeTransactions: Array<BraintreeTransaction>;
  bundle?: Maybe<OrderBundle>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  customer: UserCustomerInfo;
  deliverToEmail?: Maybe<Scalars['String']>;
  deliverToName?: Maybe<Scalars['String']>;
  deliverToPhone?: Maybe<Scalars['String']>;
  deliveredAt?: Maybe<Scalars['DateTime']>;
  delivery: Scalars['Float'];
  deliveryEstimation?: Maybe<Scalars['DateTime']>;
  deliveryOption?: Maybe<DeliveryOption>;
  deliveryTimeInSeconds?: Maybe<Scalars['Int']>;
  expeditedAt?: Maybe<Scalars['DateTime']>;
  facility: Facility;
  fee?: Maybe<Scalars['Float']>;
  formattedAddress?: Maybe<Scalars['String']>;
  fulfillmentAt: Scalars['DateTime'];
  fulfillmentBy: Scalars['DateTime'];
  giftCardCodes: Array<Scalars['String']>;
  giftCardDiscounts: Array<Scalars['Float']>;
  giftCardsDiscount: Scalars['Float'];
  id: Scalars['String'];
  isCatering: Scalars['Boolean'];
  isEmployee: Scalars['Boolean'];
  isOrderNow: Scalars['Boolean'];
  isPaidByInvoice: Scalars['Boolean'];
  isVip: Scalars['Boolean'];
  items: Array<OrderItem>;
  kdsType: KdsType;
  kitchenPreparingUntil?: Maybe<Scalars['DateTime']>;
  kitchenTickets: Array<KitchenTicket>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  locality?: Maybe<Scalars['String']>;
  method: OrderMethod;
  note?: Maybe<Scalars['String']>;
  numberOfGuests?: Maybe<Scalars['Int']>;
  orderEvents: Array<OrderEvent>;
  orderName?: Maybe<Scalars['String']>;
  overwraps?: Maybe<Scalars['Int']>;
  packager?: Maybe<Workplace>;
  packedAt?: Maybe<Scalars['DateTime']>;
  payedAt?: Maybe<Scalars['DateTime']>;
  pickup?: Maybe<Workplace>;
  placeId?: Maybe<Scalars['String']>;
  platform: Platform;
  postalCode?: Maybe<Scalars['String']>;
  preparingUntil?: Maybe<Scalars['DateTime']>;
  promoCodes: Array<Scalars['String']>;
  promoDiscount: Scalars['Float'];
  promos: Array<Promo>;
  refireDelay: Scalars['Int'];
  refired?: Maybe<Scalars['Boolean']>;
  retailItems: Array<OrderItem>;
  shortId: Scalars['String'];
  source?: Maybe<Scalars['String']>;
  status: OrderStatus;
  street?: Maybe<Scalars['String']>;
  submittedAt: Scalars['DateTime'];
  subtotal: Scalars['Float'];
  tags: Array<Tag>;
  tax?: Maybe<Scalars['Float']>;
  taxFees: Scalars['Float'];
  tip: Scalars['Float'];
  total: Scalars['Float'];
  utensilsCount: Scalars['Int'];
};


export type OrderBraintreeTransactionsArgs = {
  after?: Maybe<BraintreeTransactionWhereUniqueInput>;
  before?: Maybe<BraintreeTransactionWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type OrderItemsArgs = {
  after?: Maybe<OrderItemWhereUniqueInput>;
  before?: Maybe<OrderItemWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type OrderKitchenTicketsArgs = {
  after?: Maybe<KitchenTicketWhereUniqueInput>;
  before?: Maybe<KitchenTicketWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type OrderOrderEventsArgs = {
  after?: Maybe<OrderEventWhereUniqueInput>;
  before?: Maybe<OrderEventWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type OrderPromosArgs = {
  after?: Maybe<PromoWhereUniqueInput>;
  before?: Maybe<PromoWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type OrderTagsArgs = {
  after?: Maybe<TagWhereUniqueInput>;
  before?: Maybe<TagWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type OrderBundle = {
  __typename?: 'OrderBundle';
  createdAt: Scalars['DateTime'];
  deliveryStart: Scalars['DateTime'];
  id: Scalars['String'];
  isLocked: Scalars['Boolean'];
  orders: Array<Order>;
  shortId: Scalars['Int'];
  sortedOrderIds: Array<Scalars['String']>;
  status: OrderBundleStatus;
};


export type OrderBundleOrdersArgs = {
  after?: Maybe<OrderWhereUniqueInput>;
  before?: Maybe<OrderWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type OrderBundleCreateNestedOneWithoutOrdersInput = {
  connect?: Maybe<OrderBundleWhereUniqueInput>;
  connectOrCreate?: Maybe<OrderBundleCreateOrConnectWithoutOrdersInput>;
  create?: Maybe<OrderBundleCreateWithoutOrdersInput>;
};

export type OrderBundleCreateOrConnectWithoutOrdersInput = {
  create: OrderBundleCreateWithoutOrdersInput;
  where: OrderBundleWhereUniqueInput;
};

export type OrderBundleCreateWithoutOrdersInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  deliveryStart: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  isLocked?: Maybe<Scalars['Boolean']>;
  shortId?: Maybe<Scalars['Int']>;
  sortedOrderIds?: Maybe<OrderBundleCreatesortedOrderIdsInput>;
  status?: Maybe<OrderBundleStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderBundleCreatesortedOrderIdsInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export enum OrderBundleStatus {
  Completed = 'COMPLETED',
  Delivery = 'DELIVERY',
  New = 'NEW',
  Ready = 'READY'
}

export type OrderBundleUpdateOneWithoutOrdersInput = {
  connect?: Maybe<OrderBundleWhereUniqueInput>;
  connectOrCreate?: Maybe<OrderBundleCreateOrConnectWithoutOrdersInput>;
  create?: Maybe<OrderBundleCreateWithoutOrdersInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<OrderBundleUpdateWithoutOrdersInput>;
  upsert?: Maybe<OrderBundleUpsertWithoutOrdersInput>;
};

export type OrderBundleUpdateWithoutOrdersInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deliveryStart?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isLocked?: Maybe<BoolFieldUpdateOperationsInput>;
  shortId?: Maybe<IntFieldUpdateOperationsInput>;
  sortedOrderIds?: Maybe<OrderBundleUpdatesortedOrderIdsInput>;
  status?: Maybe<EnumOrderBundleStatusFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderBundleUpdatesortedOrderIdsInput = {
  push?: Maybe<Scalars['String']>;
  set?: Maybe<Array<Scalars['String']>>;
};

export type OrderBundleUpsertWithoutOrdersInput = {
  create: OrderBundleCreateWithoutOrdersInput;
  update: OrderBundleUpdateWithoutOrdersInput;
};

export type OrderBundleWhereInput = {
  AND?: Maybe<Array<OrderBundleWhereInput>>;
  NOT?: Maybe<Array<OrderBundleWhereInput>>;
  OR?: Maybe<Array<OrderBundleWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  deliveryStart?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  isLocked?: Maybe<BoolFilter>;
  orders?: Maybe<OrderListRelationFilter>;
  shortId?: Maybe<IntFilter>;
  sortedOrderIds?: Maybe<StringNullableListFilter>;
  status?: Maybe<EnumOrderBundleStatusFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type OrderBundleWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type OrderConfirmation = {
  __typename?: 'OrderConfirmation';
  order?: Maybe<Order>;
  transaction?: Maybe<BraintreeTransaction>;
  validation: OrderValidation;
};

export type OrderCost = {
  __typename?: 'OrderCost';
  delivery: Scalars['Float'];
  fee: Scalars['Float'];
  giftCardsDiscount: Scalars['Float'];
  promoDiscount: Scalars['Float'];
  subtotal: Scalars['Float'];
  tax: Scalars['Float'];
  taxFees: Scalars['Float'];
  tip: Scalars['Float'];
  total: Scalars['Float'];
};

export type OrderCreateManyCustomerInput = {
  addressNote?: Maybe<Scalars['String']>;
  bundleId?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deliverToEmail?: Maybe<Scalars['String']>;
  deliverToName?: Maybe<Scalars['String']>;
  deliverToPhone?: Maybe<Scalars['String']>;
  deliveredAt?: Maybe<Scalars['DateTime']>;
  delivery: Scalars['Float'];
  deliveryEstimation?: Maybe<Scalars['DateTime']>;
  deliveryOption?: Maybe<DeliveryOption>;
  deliveryTimeInSeconds?: Maybe<Scalars['Int']>;
  expeditedAt?: Maybe<Scalars['DateTime']>;
  expoId?: Maybe<Scalars['String']>;
  facilityId: Scalars['String'];
  fee?: Maybe<Scalars['Float']>;
  formattedAddress?: Maybe<Scalars['String']>;
  fulfillmentAt: Scalars['DateTime'];
  fulfillmentBy: Scalars['DateTime'];
  giftCardCodes?: Maybe<OrderCreateManygiftCardCodesInput>;
  giftCardDiscounts?: Maybe<OrderCreateManygiftCardDiscountsInput>;
  giftCardsDiscount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  isEmployee?: Maybe<Scalars['Boolean']>;
  isOrderNow?: Maybe<Scalars['Boolean']>;
  isPaidByInvoice?: Maybe<Scalars['Boolean']>;
  isVip?: Maybe<Scalars['Boolean']>;
  kdsType?: Maybe<KdsType>;
  kitchenPreparingUntil?: Maybe<Scalars['DateTime']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  locality?: Maybe<Scalars['String']>;
  method?: Maybe<OrderMethod>;
  note?: Maybe<Scalars['String']>;
  numberOfGuests?: Maybe<Scalars['Int']>;
  orderName?: Maybe<Scalars['String']>;
  overwraps?: Maybe<Scalars['Int']>;
  packagerId?: Maybe<Scalars['String']>;
  packedAt?: Maybe<Scalars['DateTime']>;
  payedAt?: Maybe<Scalars['DateTime']>;
  pickupId?: Maybe<Scalars['String']>;
  placeId?: Maybe<Scalars['String']>;
  platform?: Maybe<Platform>;
  postalCode?: Maybe<Scalars['String']>;
  preparingUntil?: Maybe<Scalars['DateTime']>;
  promoCodes?: Maybe<OrderCreateManypromoCodesInput>;
  promoDiscount: Scalars['Float'];
  refireDelay?: Maybe<Scalars['Int']>;
  refired?: Maybe<Scalars['Boolean']>;
  region?: Maybe<Scalars['String']>;
  runnerId?: Maybe<Scalars['String']>;
  sequentialNumber?: Maybe<Scalars['Int']>;
  shortId: Scalars['String'];
  source?: Maybe<Scalars['String']>;
  status: OrderStatus;
  street?: Maybe<Scalars['String']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Float'];
  tax?: Maybe<Scalars['Float']>;
  taxFees: Scalars['Float'];
  tip: Scalars['Float'];
  tookanTaskId?: Maybe<Scalars['String']>;
  total: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  utensilsCount?: Maybe<Scalars['Int']>;
};

export type OrderCreateManyCustomerInputEnvelope = {
  data?: Maybe<Array<OrderCreateManyCustomerInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OrderCreateManyExpoInput = {
  addressNote?: Maybe<Scalars['String']>;
  bundleId?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerId: Scalars['String'];
  deliverToEmail?: Maybe<Scalars['String']>;
  deliverToName?: Maybe<Scalars['String']>;
  deliverToPhone?: Maybe<Scalars['String']>;
  deliveredAt?: Maybe<Scalars['DateTime']>;
  delivery: Scalars['Float'];
  deliveryEstimation?: Maybe<Scalars['DateTime']>;
  deliveryOption?: Maybe<DeliveryOption>;
  deliveryTimeInSeconds?: Maybe<Scalars['Int']>;
  expeditedAt?: Maybe<Scalars['DateTime']>;
  facilityId: Scalars['String'];
  fee?: Maybe<Scalars['Float']>;
  formattedAddress?: Maybe<Scalars['String']>;
  fulfillmentAt: Scalars['DateTime'];
  fulfillmentBy: Scalars['DateTime'];
  giftCardCodes?: Maybe<OrderCreateManygiftCardCodesInput>;
  giftCardDiscounts?: Maybe<OrderCreateManygiftCardDiscountsInput>;
  giftCardsDiscount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  isEmployee?: Maybe<Scalars['Boolean']>;
  isOrderNow?: Maybe<Scalars['Boolean']>;
  isPaidByInvoice?: Maybe<Scalars['Boolean']>;
  isVip?: Maybe<Scalars['Boolean']>;
  kdsType?: Maybe<KdsType>;
  kitchenPreparingUntil?: Maybe<Scalars['DateTime']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  locality?: Maybe<Scalars['String']>;
  method?: Maybe<OrderMethod>;
  note?: Maybe<Scalars['String']>;
  numberOfGuests?: Maybe<Scalars['Int']>;
  orderName?: Maybe<Scalars['String']>;
  overwraps?: Maybe<Scalars['Int']>;
  packagerId?: Maybe<Scalars['String']>;
  packedAt?: Maybe<Scalars['DateTime']>;
  payedAt?: Maybe<Scalars['DateTime']>;
  pickupId?: Maybe<Scalars['String']>;
  placeId?: Maybe<Scalars['String']>;
  platform?: Maybe<Platform>;
  postalCode?: Maybe<Scalars['String']>;
  preparingUntil?: Maybe<Scalars['DateTime']>;
  promoCodes?: Maybe<OrderCreateManypromoCodesInput>;
  promoDiscount: Scalars['Float'];
  refireDelay?: Maybe<Scalars['Int']>;
  refired?: Maybe<Scalars['Boolean']>;
  region?: Maybe<Scalars['String']>;
  runnerId?: Maybe<Scalars['String']>;
  sequentialNumber?: Maybe<Scalars['Int']>;
  shortId: Scalars['String'];
  source?: Maybe<Scalars['String']>;
  status: OrderStatus;
  street?: Maybe<Scalars['String']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Float'];
  tax?: Maybe<Scalars['Float']>;
  taxFees: Scalars['Float'];
  tip: Scalars['Float'];
  tookanTaskId?: Maybe<Scalars['String']>;
  total: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  utensilsCount?: Maybe<Scalars['Int']>;
};

export type OrderCreateManyExpoInputEnvelope = {
  data?: Maybe<Array<OrderCreateManyExpoInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OrderCreateManyFacilityInput = {
  addressNote?: Maybe<Scalars['String']>;
  bundleId?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerId: Scalars['String'];
  deliverToEmail?: Maybe<Scalars['String']>;
  deliverToName?: Maybe<Scalars['String']>;
  deliverToPhone?: Maybe<Scalars['String']>;
  deliveredAt?: Maybe<Scalars['DateTime']>;
  delivery: Scalars['Float'];
  deliveryEstimation?: Maybe<Scalars['DateTime']>;
  deliveryOption?: Maybe<DeliveryOption>;
  deliveryTimeInSeconds?: Maybe<Scalars['Int']>;
  expeditedAt?: Maybe<Scalars['DateTime']>;
  expoId?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['Float']>;
  formattedAddress?: Maybe<Scalars['String']>;
  fulfillmentAt: Scalars['DateTime'];
  fulfillmentBy: Scalars['DateTime'];
  giftCardCodes?: Maybe<OrderCreateManygiftCardCodesInput>;
  giftCardDiscounts?: Maybe<OrderCreateManygiftCardDiscountsInput>;
  giftCardsDiscount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  isEmployee?: Maybe<Scalars['Boolean']>;
  isOrderNow?: Maybe<Scalars['Boolean']>;
  isPaidByInvoice?: Maybe<Scalars['Boolean']>;
  isVip?: Maybe<Scalars['Boolean']>;
  kdsType?: Maybe<KdsType>;
  kitchenPreparingUntil?: Maybe<Scalars['DateTime']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  locality?: Maybe<Scalars['String']>;
  method?: Maybe<OrderMethod>;
  note?: Maybe<Scalars['String']>;
  numberOfGuests?: Maybe<Scalars['Int']>;
  orderName?: Maybe<Scalars['String']>;
  overwraps?: Maybe<Scalars['Int']>;
  packagerId?: Maybe<Scalars['String']>;
  packedAt?: Maybe<Scalars['DateTime']>;
  payedAt?: Maybe<Scalars['DateTime']>;
  pickupId?: Maybe<Scalars['String']>;
  placeId?: Maybe<Scalars['String']>;
  platform?: Maybe<Platform>;
  postalCode?: Maybe<Scalars['String']>;
  preparingUntil?: Maybe<Scalars['DateTime']>;
  promoCodes?: Maybe<OrderCreateManypromoCodesInput>;
  promoDiscount: Scalars['Float'];
  refireDelay?: Maybe<Scalars['Int']>;
  refired?: Maybe<Scalars['Boolean']>;
  region?: Maybe<Scalars['String']>;
  runnerId?: Maybe<Scalars['String']>;
  sequentialNumber?: Maybe<Scalars['Int']>;
  shortId: Scalars['String'];
  source?: Maybe<Scalars['String']>;
  status: OrderStatus;
  street?: Maybe<Scalars['String']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Float'];
  tax?: Maybe<Scalars['Float']>;
  taxFees: Scalars['Float'];
  tip: Scalars['Float'];
  tookanTaskId?: Maybe<Scalars['String']>;
  total: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  utensilsCount?: Maybe<Scalars['Int']>;
};

export type OrderCreateManyFacilityInputEnvelope = {
  data?: Maybe<Array<OrderCreateManyFacilityInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OrderCreateManyPackagerInput = {
  addressNote?: Maybe<Scalars['String']>;
  bundleId?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerId: Scalars['String'];
  deliverToEmail?: Maybe<Scalars['String']>;
  deliverToName?: Maybe<Scalars['String']>;
  deliverToPhone?: Maybe<Scalars['String']>;
  deliveredAt?: Maybe<Scalars['DateTime']>;
  delivery: Scalars['Float'];
  deliveryEstimation?: Maybe<Scalars['DateTime']>;
  deliveryOption?: Maybe<DeliveryOption>;
  deliveryTimeInSeconds?: Maybe<Scalars['Int']>;
  expeditedAt?: Maybe<Scalars['DateTime']>;
  expoId?: Maybe<Scalars['String']>;
  facilityId: Scalars['String'];
  fee?: Maybe<Scalars['Float']>;
  formattedAddress?: Maybe<Scalars['String']>;
  fulfillmentAt: Scalars['DateTime'];
  fulfillmentBy: Scalars['DateTime'];
  giftCardCodes?: Maybe<OrderCreateManygiftCardCodesInput>;
  giftCardDiscounts?: Maybe<OrderCreateManygiftCardDiscountsInput>;
  giftCardsDiscount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  isEmployee?: Maybe<Scalars['Boolean']>;
  isOrderNow?: Maybe<Scalars['Boolean']>;
  isPaidByInvoice?: Maybe<Scalars['Boolean']>;
  isVip?: Maybe<Scalars['Boolean']>;
  kdsType?: Maybe<KdsType>;
  kitchenPreparingUntil?: Maybe<Scalars['DateTime']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  locality?: Maybe<Scalars['String']>;
  method?: Maybe<OrderMethod>;
  note?: Maybe<Scalars['String']>;
  numberOfGuests?: Maybe<Scalars['Int']>;
  orderName?: Maybe<Scalars['String']>;
  overwraps?: Maybe<Scalars['Int']>;
  packedAt?: Maybe<Scalars['DateTime']>;
  payedAt?: Maybe<Scalars['DateTime']>;
  pickupId?: Maybe<Scalars['String']>;
  placeId?: Maybe<Scalars['String']>;
  platform?: Maybe<Platform>;
  postalCode?: Maybe<Scalars['String']>;
  preparingUntil?: Maybe<Scalars['DateTime']>;
  promoCodes?: Maybe<OrderCreateManypromoCodesInput>;
  promoDiscount: Scalars['Float'];
  refireDelay?: Maybe<Scalars['Int']>;
  refired?: Maybe<Scalars['Boolean']>;
  region?: Maybe<Scalars['String']>;
  runnerId?: Maybe<Scalars['String']>;
  sequentialNumber?: Maybe<Scalars['Int']>;
  shortId: Scalars['String'];
  source?: Maybe<Scalars['String']>;
  status: OrderStatus;
  street?: Maybe<Scalars['String']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Float'];
  tax?: Maybe<Scalars['Float']>;
  taxFees: Scalars['Float'];
  tip: Scalars['Float'];
  tookanTaskId?: Maybe<Scalars['String']>;
  total: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  utensilsCount?: Maybe<Scalars['Int']>;
};

export type OrderCreateManyPackagerInputEnvelope = {
  data?: Maybe<Array<OrderCreateManyPackagerInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OrderCreateManyPickupInput = {
  addressNote?: Maybe<Scalars['String']>;
  bundleId?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerId: Scalars['String'];
  deliverToEmail?: Maybe<Scalars['String']>;
  deliverToName?: Maybe<Scalars['String']>;
  deliverToPhone?: Maybe<Scalars['String']>;
  deliveredAt?: Maybe<Scalars['DateTime']>;
  delivery: Scalars['Float'];
  deliveryEstimation?: Maybe<Scalars['DateTime']>;
  deliveryOption?: Maybe<DeliveryOption>;
  deliveryTimeInSeconds?: Maybe<Scalars['Int']>;
  expeditedAt?: Maybe<Scalars['DateTime']>;
  expoId?: Maybe<Scalars['String']>;
  facilityId: Scalars['String'];
  fee?: Maybe<Scalars['Float']>;
  formattedAddress?: Maybe<Scalars['String']>;
  fulfillmentAt: Scalars['DateTime'];
  fulfillmentBy: Scalars['DateTime'];
  giftCardCodes?: Maybe<OrderCreateManygiftCardCodesInput>;
  giftCardDiscounts?: Maybe<OrderCreateManygiftCardDiscountsInput>;
  giftCardsDiscount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  isEmployee?: Maybe<Scalars['Boolean']>;
  isOrderNow?: Maybe<Scalars['Boolean']>;
  isPaidByInvoice?: Maybe<Scalars['Boolean']>;
  isVip?: Maybe<Scalars['Boolean']>;
  kdsType?: Maybe<KdsType>;
  kitchenPreparingUntil?: Maybe<Scalars['DateTime']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  locality?: Maybe<Scalars['String']>;
  method?: Maybe<OrderMethod>;
  note?: Maybe<Scalars['String']>;
  numberOfGuests?: Maybe<Scalars['Int']>;
  orderName?: Maybe<Scalars['String']>;
  overwraps?: Maybe<Scalars['Int']>;
  packagerId?: Maybe<Scalars['String']>;
  packedAt?: Maybe<Scalars['DateTime']>;
  payedAt?: Maybe<Scalars['DateTime']>;
  placeId?: Maybe<Scalars['String']>;
  platform?: Maybe<Platform>;
  postalCode?: Maybe<Scalars['String']>;
  preparingUntil?: Maybe<Scalars['DateTime']>;
  promoCodes?: Maybe<OrderCreateManypromoCodesInput>;
  promoDiscount: Scalars['Float'];
  refireDelay?: Maybe<Scalars['Int']>;
  refired?: Maybe<Scalars['Boolean']>;
  region?: Maybe<Scalars['String']>;
  runnerId?: Maybe<Scalars['String']>;
  sequentialNumber?: Maybe<Scalars['Int']>;
  shortId: Scalars['String'];
  source?: Maybe<Scalars['String']>;
  status: OrderStatus;
  street?: Maybe<Scalars['String']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Float'];
  tax?: Maybe<Scalars['Float']>;
  taxFees: Scalars['Float'];
  tip: Scalars['Float'];
  tookanTaskId?: Maybe<Scalars['String']>;
  total: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  utensilsCount?: Maybe<Scalars['Int']>;
};

export type OrderCreateManyPickupInputEnvelope = {
  data?: Maybe<Array<OrderCreateManyPickupInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OrderCreateManyRunnerInput = {
  addressNote?: Maybe<Scalars['String']>;
  bundleId?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerId: Scalars['String'];
  deliverToEmail?: Maybe<Scalars['String']>;
  deliverToName?: Maybe<Scalars['String']>;
  deliverToPhone?: Maybe<Scalars['String']>;
  deliveredAt?: Maybe<Scalars['DateTime']>;
  delivery: Scalars['Float'];
  deliveryEstimation?: Maybe<Scalars['DateTime']>;
  deliveryOption?: Maybe<DeliveryOption>;
  deliveryTimeInSeconds?: Maybe<Scalars['Int']>;
  expeditedAt?: Maybe<Scalars['DateTime']>;
  expoId?: Maybe<Scalars['String']>;
  facilityId: Scalars['String'];
  fee?: Maybe<Scalars['Float']>;
  formattedAddress?: Maybe<Scalars['String']>;
  fulfillmentAt: Scalars['DateTime'];
  fulfillmentBy: Scalars['DateTime'];
  giftCardCodes?: Maybe<OrderCreateManygiftCardCodesInput>;
  giftCardDiscounts?: Maybe<OrderCreateManygiftCardDiscountsInput>;
  giftCardsDiscount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  isEmployee?: Maybe<Scalars['Boolean']>;
  isOrderNow?: Maybe<Scalars['Boolean']>;
  isPaidByInvoice?: Maybe<Scalars['Boolean']>;
  isVip?: Maybe<Scalars['Boolean']>;
  kdsType?: Maybe<KdsType>;
  kitchenPreparingUntil?: Maybe<Scalars['DateTime']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  locality?: Maybe<Scalars['String']>;
  method?: Maybe<OrderMethod>;
  note?: Maybe<Scalars['String']>;
  numberOfGuests?: Maybe<Scalars['Int']>;
  orderName?: Maybe<Scalars['String']>;
  overwraps?: Maybe<Scalars['Int']>;
  packagerId?: Maybe<Scalars['String']>;
  packedAt?: Maybe<Scalars['DateTime']>;
  payedAt?: Maybe<Scalars['DateTime']>;
  pickupId?: Maybe<Scalars['String']>;
  placeId?: Maybe<Scalars['String']>;
  platform?: Maybe<Platform>;
  postalCode?: Maybe<Scalars['String']>;
  preparingUntil?: Maybe<Scalars['DateTime']>;
  promoCodes?: Maybe<OrderCreateManypromoCodesInput>;
  promoDiscount: Scalars['Float'];
  refireDelay?: Maybe<Scalars['Int']>;
  refired?: Maybe<Scalars['Boolean']>;
  region?: Maybe<Scalars['String']>;
  sequentialNumber?: Maybe<Scalars['Int']>;
  shortId: Scalars['String'];
  source?: Maybe<Scalars['String']>;
  status: OrderStatus;
  street?: Maybe<Scalars['String']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Float'];
  tax?: Maybe<Scalars['Float']>;
  taxFees: Scalars['Float'];
  tip: Scalars['Float'];
  tookanTaskId?: Maybe<Scalars['String']>;
  total: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  utensilsCount?: Maybe<Scalars['Int']>;
};

export type OrderCreateManyRunnerInputEnvelope = {
  data?: Maybe<Array<OrderCreateManyRunnerInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OrderCreateManygiftCardCodesInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type OrderCreateManygiftCardDiscountsInput = {
  set?: Maybe<Array<Scalars['Float']>>;
};

export type OrderCreateManypromoCodesInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type OrderCreateNestedManyWithoutCustomerInput = {
  connect?: Maybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrderCreateOrConnectWithoutCustomerInput>>;
  create?: Maybe<Array<OrderCreateWithoutCustomerInput>>;
  createMany?: Maybe<OrderCreateManyCustomerInputEnvelope>;
};

export type OrderCreateNestedManyWithoutExpoInput = {
  connect?: Maybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrderCreateOrConnectWithoutExpoInput>>;
  create?: Maybe<Array<OrderCreateWithoutExpoInput>>;
  createMany?: Maybe<OrderCreateManyExpoInputEnvelope>;
};

export type OrderCreateNestedManyWithoutFacilityInput = {
  connect?: Maybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrderCreateOrConnectWithoutFacilityInput>>;
  create?: Maybe<Array<OrderCreateWithoutFacilityInput>>;
  createMany?: Maybe<OrderCreateManyFacilityInputEnvelope>;
};

export type OrderCreateNestedManyWithoutPackagerInput = {
  connect?: Maybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrderCreateOrConnectWithoutPackagerInput>>;
  create?: Maybe<Array<OrderCreateWithoutPackagerInput>>;
  createMany?: Maybe<OrderCreateManyPackagerInputEnvelope>;
};

export type OrderCreateNestedManyWithoutPickupInput = {
  connect?: Maybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrderCreateOrConnectWithoutPickupInput>>;
  create?: Maybe<Array<OrderCreateWithoutPickupInput>>;
  createMany?: Maybe<OrderCreateManyPickupInputEnvelope>;
};

export type OrderCreateNestedManyWithoutPromosInput = {
  connect?: Maybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrderCreateOrConnectWithoutPromosInput>>;
  create?: Maybe<Array<OrderCreateWithoutPromosInput>>;
};

export type OrderCreateNestedManyWithoutRunnerInput = {
  connect?: Maybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrderCreateOrConnectWithoutRunnerInput>>;
  create?: Maybe<Array<OrderCreateWithoutRunnerInput>>;
  createMany?: Maybe<OrderCreateManyRunnerInputEnvelope>;
};

export type OrderCreateNestedManyWithoutTagsInput = {
  connect?: Maybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrderCreateOrConnectWithoutTagsInput>>;
  create?: Maybe<Array<OrderCreateWithoutTagsInput>>;
};

export type OrderCreateNestedOneWithoutItemsInput = {
  connect?: Maybe<OrderWhereUniqueInput>;
  connectOrCreate?: Maybe<OrderCreateOrConnectWithoutItemsInput>;
  create?: Maybe<OrderCreateWithoutItemsInput>;
};

export type OrderCreateNestedOneWithoutKitchenTicketsInput = {
  connect?: Maybe<OrderWhereUniqueInput>;
  connectOrCreate?: Maybe<OrderCreateOrConnectWithoutKitchenTicketsInput>;
  create?: Maybe<OrderCreateWithoutKitchenTicketsInput>;
};

export type OrderCreateOrConnectWithoutCustomerInput = {
  create: OrderCreateWithoutCustomerInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateOrConnectWithoutExpoInput = {
  create: OrderCreateWithoutExpoInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateOrConnectWithoutFacilityInput = {
  create: OrderCreateWithoutFacilityInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateOrConnectWithoutItemsInput = {
  create: OrderCreateWithoutItemsInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateOrConnectWithoutKitchenTicketsInput = {
  create: OrderCreateWithoutKitchenTicketsInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateOrConnectWithoutPackagerInput = {
  create: OrderCreateWithoutPackagerInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateOrConnectWithoutPickupInput = {
  create: OrderCreateWithoutPickupInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateOrConnectWithoutPromosInput = {
  create: OrderCreateWithoutPromosInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateOrConnectWithoutRunnerInput = {
  create: OrderCreateWithoutRunnerInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateOrConnectWithoutTagsInput = {
  create: OrderCreateWithoutTagsInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateWithoutCustomerInput = {
  addressNote?: Maybe<Scalars['String']>;
  braintreeTransactions?: Maybe<BraintreeTransactionCreateNestedManyWithoutOrderInput>;
  bundle?: Maybe<OrderBundleCreateNestedOneWithoutOrdersInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deliverToEmail?: Maybe<Scalars['String']>;
  deliverToName?: Maybe<Scalars['String']>;
  deliverToPhone?: Maybe<Scalars['String']>;
  deliveredAt?: Maybe<Scalars['DateTime']>;
  delivery: Scalars['Float'];
  deliveryEstimation?: Maybe<Scalars['DateTime']>;
  deliveryOption?: Maybe<DeliveryOption>;
  deliveryTimeInSeconds?: Maybe<Scalars['Int']>;
  expeditedAt?: Maybe<Scalars['DateTime']>;
  expo?: Maybe<WorkplaceCreateNestedOneWithoutExpoInput>;
  facility: FacilityCreateNestedOneWithoutOrdersInput;
  fee?: Maybe<Scalars['Float']>;
  formattedAddress?: Maybe<Scalars['String']>;
  fulfillmentAt: Scalars['DateTime'];
  fulfillmentBy: Scalars['DateTime'];
  giftCardCodes?: Maybe<OrderCreategiftCardCodesInput>;
  giftCardDiscounts?: Maybe<OrderCreategiftCardDiscountsInput>;
  giftCardsDiscount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  isEmployee?: Maybe<Scalars['Boolean']>;
  isOrderNow?: Maybe<Scalars['Boolean']>;
  isPaidByInvoice?: Maybe<Scalars['Boolean']>;
  isVip?: Maybe<Scalars['Boolean']>;
  items?: Maybe<OrderItemCreateNestedManyWithoutOrderInput>;
  kdsType?: Maybe<KdsType>;
  kitchenPreparingUntil?: Maybe<Scalars['DateTime']>;
  kitchenTickets?: Maybe<KitchenTicketCreateNestedManyWithoutOrderInput>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  locality?: Maybe<Scalars['String']>;
  method?: Maybe<OrderMethod>;
  note?: Maybe<Scalars['String']>;
  numberOfGuests?: Maybe<Scalars['Int']>;
  orderEvents?: Maybe<OrderEventCreateNestedManyWithoutOrderInput>;
  orderName?: Maybe<Scalars['String']>;
  overwraps?: Maybe<Scalars['Int']>;
  packager?: Maybe<WorkplaceCreateNestedOneWithoutPackagerInput>;
  packedAt?: Maybe<Scalars['DateTime']>;
  payedAt?: Maybe<Scalars['DateTime']>;
  pickup?: Maybe<WorkplaceCreateNestedOneWithoutPickupInput>;
  placeId?: Maybe<Scalars['String']>;
  platform?: Maybe<Platform>;
  postalCode?: Maybe<Scalars['String']>;
  preparingUntil?: Maybe<Scalars['DateTime']>;
  promoCodes?: Maybe<OrderCreatepromoCodesInput>;
  promoDiscount: Scalars['Float'];
  promos?: Maybe<PromoCreateNestedManyWithoutOrdersInput>;
  refireDelay?: Maybe<Scalars['Int']>;
  refired?: Maybe<Scalars['Boolean']>;
  region?: Maybe<Scalars['String']>;
  runner?: Maybe<WorkplaceCreateNestedOneWithoutRunnerInput>;
  sequentialNumber?: Maybe<Scalars['Int']>;
  shortId: Scalars['String'];
  source?: Maybe<Scalars['String']>;
  status: OrderStatus;
  street?: Maybe<Scalars['String']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Float'];
  tags?: Maybe<TagCreateNestedManyWithoutOrdersInput>;
  tax?: Maybe<Scalars['Float']>;
  taxFees: Scalars['Float'];
  tip: Scalars['Float'];
  tookanTaskId?: Maybe<Scalars['String']>;
  total: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  utensilsCount?: Maybe<Scalars['Int']>;
};

export type OrderCreateWithoutExpoInput = {
  addressNote?: Maybe<Scalars['String']>;
  braintreeTransactions?: Maybe<BraintreeTransactionCreateNestedManyWithoutOrderInput>;
  bundle?: Maybe<OrderBundleCreateNestedOneWithoutOrdersInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customer: UserCustomerInfoCreateNestedOneWithoutOrdersInput;
  deliverToEmail?: Maybe<Scalars['String']>;
  deliverToName?: Maybe<Scalars['String']>;
  deliverToPhone?: Maybe<Scalars['String']>;
  deliveredAt?: Maybe<Scalars['DateTime']>;
  delivery: Scalars['Float'];
  deliveryEstimation?: Maybe<Scalars['DateTime']>;
  deliveryOption?: Maybe<DeliveryOption>;
  deliveryTimeInSeconds?: Maybe<Scalars['Int']>;
  expeditedAt?: Maybe<Scalars['DateTime']>;
  facility: FacilityCreateNestedOneWithoutOrdersInput;
  fee?: Maybe<Scalars['Float']>;
  formattedAddress?: Maybe<Scalars['String']>;
  fulfillmentAt: Scalars['DateTime'];
  fulfillmentBy: Scalars['DateTime'];
  giftCardCodes?: Maybe<OrderCreategiftCardCodesInput>;
  giftCardDiscounts?: Maybe<OrderCreategiftCardDiscountsInput>;
  giftCardsDiscount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  isEmployee?: Maybe<Scalars['Boolean']>;
  isOrderNow?: Maybe<Scalars['Boolean']>;
  isPaidByInvoice?: Maybe<Scalars['Boolean']>;
  isVip?: Maybe<Scalars['Boolean']>;
  items?: Maybe<OrderItemCreateNestedManyWithoutOrderInput>;
  kdsType?: Maybe<KdsType>;
  kitchenPreparingUntil?: Maybe<Scalars['DateTime']>;
  kitchenTickets?: Maybe<KitchenTicketCreateNestedManyWithoutOrderInput>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  locality?: Maybe<Scalars['String']>;
  method?: Maybe<OrderMethod>;
  note?: Maybe<Scalars['String']>;
  numberOfGuests?: Maybe<Scalars['Int']>;
  orderEvents?: Maybe<OrderEventCreateNestedManyWithoutOrderInput>;
  orderName?: Maybe<Scalars['String']>;
  overwraps?: Maybe<Scalars['Int']>;
  packager?: Maybe<WorkplaceCreateNestedOneWithoutPackagerInput>;
  packedAt?: Maybe<Scalars['DateTime']>;
  payedAt?: Maybe<Scalars['DateTime']>;
  pickup?: Maybe<WorkplaceCreateNestedOneWithoutPickupInput>;
  placeId?: Maybe<Scalars['String']>;
  platform?: Maybe<Platform>;
  postalCode?: Maybe<Scalars['String']>;
  preparingUntil?: Maybe<Scalars['DateTime']>;
  promoCodes?: Maybe<OrderCreatepromoCodesInput>;
  promoDiscount: Scalars['Float'];
  promos?: Maybe<PromoCreateNestedManyWithoutOrdersInput>;
  refireDelay?: Maybe<Scalars['Int']>;
  refired?: Maybe<Scalars['Boolean']>;
  region?: Maybe<Scalars['String']>;
  runner?: Maybe<WorkplaceCreateNestedOneWithoutRunnerInput>;
  sequentialNumber?: Maybe<Scalars['Int']>;
  shortId: Scalars['String'];
  source?: Maybe<Scalars['String']>;
  status: OrderStatus;
  street?: Maybe<Scalars['String']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Float'];
  tags?: Maybe<TagCreateNestedManyWithoutOrdersInput>;
  tax?: Maybe<Scalars['Float']>;
  taxFees: Scalars['Float'];
  tip: Scalars['Float'];
  tookanTaskId?: Maybe<Scalars['String']>;
  total: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  utensilsCount?: Maybe<Scalars['Int']>;
};

export type OrderCreateWithoutFacilityInput = {
  addressNote?: Maybe<Scalars['String']>;
  braintreeTransactions?: Maybe<BraintreeTransactionCreateNestedManyWithoutOrderInput>;
  bundle?: Maybe<OrderBundleCreateNestedOneWithoutOrdersInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customer: UserCustomerInfoCreateNestedOneWithoutOrdersInput;
  deliverToEmail?: Maybe<Scalars['String']>;
  deliverToName?: Maybe<Scalars['String']>;
  deliverToPhone?: Maybe<Scalars['String']>;
  deliveredAt?: Maybe<Scalars['DateTime']>;
  delivery: Scalars['Float'];
  deliveryEstimation?: Maybe<Scalars['DateTime']>;
  deliveryOption?: Maybe<DeliveryOption>;
  deliveryTimeInSeconds?: Maybe<Scalars['Int']>;
  expeditedAt?: Maybe<Scalars['DateTime']>;
  expo?: Maybe<WorkplaceCreateNestedOneWithoutExpoInput>;
  fee?: Maybe<Scalars['Float']>;
  formattedAddress?: Maybe<Scalars['String']>;
  fulfillmentAt: Scalars['DateTime'];
  fulfillmentBy: Scalars['DateTime'];
  giftCardCodes?: Maybe<OrderCreategiftCardCodesInput>;
  giftCardDiscounts?: Maybe<OrderCreategiftCardDiscountsInput>;
  giftCardsDiscount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  isEmployee?: Maybe<Scalars['Boolean']>;
  isOrderNow?: Maybe<Scalars['Boolean']>;
  isPaidByInvoice?: Maybe<Scalars['Boolean']>;
  isVip?: Maybe<Scalars['Boolean']>;
  items?: Maybe<OrderItemCreateNestedManyWithoutOrderInput>;
  kdsType?: Maybe<KdsType>;
  kitchenPreparingUntil?: Maybe<Scalars['DateTime']>;
  kitchenTickets?: Maybe<KitchenTicketCreateNestedManyWithoutOrderInput>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  locality?: Maybe<Scalars['String']>;
  method?: Maybe<OrderMethod>;
  note?: Maybe<Scalars['String']>;
  numberOfGuests?: Maybe<Scalars['Int']>;
  orderEvents?: Maybe<OrderEventCreateNestedManyWithoutOrderInput>;
  orderName?: Maybe<Scalars['String']>;
  overwraps?: Maybe<Scalars['Int']>;
  packager?: Maybe<WorkplaceCreateNestedOneWithoutPackagerInput>;
  packedAt?: Maybe<Scalars['DateTime']>;
  payedAt?: Maybe<Scalars['DateTime']>;
  pickup?: Maybe<WorkplaceCreateNestedOneWithoutPickupInput>;
  placeId?: Maybe<Scalars['String']>;
  platform?: Maybe<Platform>;
  postalCode?: Maybe<Scalars['String']>;
  preparingUntil?: Maybe<Scalars['DateTime']>;
  promoCodes?: Maybe<OrderCreatepromoCodesInput>;
  promoDiscount: Scalars['Float'];
  promos?: Maybe<PromoCreateNestedManyWithoutOrdersInput>;
  refireDelay?: Maybe<Scalars['Int']>;
  refired?: Maybe<Scalars['Boolean']>;
  region?: Maybe<Scalars['String']>;
  runner?: Maybe<WorkplaceCreateNestedOneWithoutRunnerInput>;
  sequentialNumber?: Maybe<Scalars['Int']>;
  shortId: Scalars['String'];
  source?: Maybe<Scalars['String']>;
  status: OrderStatus;
  street?: Maybe<Scalars['String']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Float'];
  tags?: Maybe<TagCreateNestedManyWithoutOrdersInput>;
  tax?: Maybe<Scalars['Float']>;
  taxFees: Scalars['Float'];
  tip: Scalars['Float'];
  tookanTaskId?: Maybe<Scalars['String']>;
  total: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  utensilsCount?: Maybe<Scalars['Int']>;
};

export type OrderCreateWithoutItemsInput = {
  addressNote?: Maybe<Scalars['String']>;
  braintreeTransactions?: Maybe<BraintreeTransactionCreateNestedManyWithoutOrderInput>;
  bundle?: Maybe<OrderBundleCreateNestedOneWithoutOrdersInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customer: UserCustomerInfoCreateNestedOneWithoutOrdersInput;
  deliverToEmail?: Maybe<Scalars['String']>;
  deliverToName?: Maybe<Scalars['String']>;
  deliverToPhone?: Maybe<Scalars['String']>;
  deliveredAt?: Maybe<Scalars['DateTime']>;
  delivery: Scalars['Float'];
  deliveryEstimation?: Maybe<Scalars['DateTime']>;
  deliveryOption?: Maybe<DeliveryOption>;
  deliveryTimeInSeconds?: Maybe<Scalars['Int']>;
  expeditedAt?: Maybe<Scalars['DateTime']>;
  expo?: Maybe<WorkplaceCreateNestedOneWithoutExpoInput>;
  facility: FacilityCreateNestedOneWithoutOrdersInput;
  fee?: Maybe<Scalars['Float']>;
  formattedAddress?: Maybe<Scalars['String']>;
  fulfillmentAt: Scalars['DateTime'];
  fulfillmentBy: Scalars['DateTime'];
  giftCardCodes?: Maybe<OrderCreategiftCardCodesInput>;
  giftCardDiscounts?: Maybe<OrderCreategiftCardDiscountsInput>;
  giftCardsDiscount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  isEmployee?: Maybe<Scalars['Boolean']>;
  isOrderNow?: Maybe<Scalars['Boolean']>;
  isPaidByInvoice?: Maybe<Scalars['Boolean']>;
  isVip?: Maybe<Scalars['Boolean']>;
  kdsType?: Maybe<KdsType>;
  kitchenPreparingUntil?: Maybe<Scalars['DateTime']>;
  kitchenTickets?: Maybe<KitchenTicketCreateNestedManyWithoutOrderInput>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  locality?: Maybe<Scalars['String']>;
  method?: Maybe<OrderMethod>;
  note?: Maybe<Scalars['String']>;
  numberOfGuests?: Maybe<Scalars['Int']>;
  orderEvents?: Maybe<OrderEventCreateNestedManyWithoutOrderInput>;
  orderName?: Maybe<Scalars['String']>;
  overwraps?: Maybe<Scalars['Int']>;
  packager?: Maybe<WorkplaceCreateNestedOneWithoutPackagerInput>;
  packedAt?: Maybe<Scalars['DateTime']>;
  payedAt?: Maybe<Scalars['DateTime']>;
  pickup?: Maybe<WorkplaceCreateNestedOneWithoutPickupInput>;
  placeId?: Maybe<Scalars['String']>;
  platform?: Maybe<Platform>;
  postalCode?: Maybe<Scalars['String']>;
  preparingUntil?: Maybe<Scalars['DateTime']>;
  promoCodes?: Maybe<OrderCreatepromoCodesInput>;
  promoDiscount: Scalars['Float'];
  promos?: Maybe<PromoCreateNestedManyWithoutOrdersInput>;
  refireDelay?: Maybe<Scalars['Int']>;
  refired?: Maybe<Scalars['Boolean']>;
  region?: Maybe<Scalars['String']>;
  runner?: Maybe<WorkplaceCreateNestedOneWithoutRunnerInput>;
  sequentialNumber?: Maybe<Scalars['Int']>;
  shortId: Scalars['String'];
  source?: Maybe<Scalars['String']>;
  status: OrderStatus;
  street?: Maybe<Scalars['String']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Float'];
  tags?: Maybe<TagCreateNestedManyWithoutOrdersInput>;
  tax?: Maybe<Scalars['Float']>;
  taxFees: Scalars['Float'];
  tip: Scalars['Float'];
  tookanTaskId?: Maybe<Scalars['String']>;
  total: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  utensilsCount?: Maybe<Scalars['Int']>;
};

export type OrderCreateWithoutKitchenTicketsInput = {
  addressNote?: Maybe<Scalars['String']>;
  braintreeTransactions?: Maybe<BraintreeTransactionCreateNestedManyWithoutOrderInput>;
  bundle?: Maybe<OrderBundleCreateNestedOneWithoutOrdersInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customer: UserCustomerInfoCreateNestedOneWithoutOrdersInput;
  deliverToEmail?: Maybe<Scalars['String']>;
  deliverToName?: Maybe<Scalars['String']>;
  deliverToPhone?: Maybe<Scalars['String']>;
  deliveredAt?: Maybe<Scalars['DateTime']>;
  delivery: Scalars['Float'];
  deliveryEstimation?: Maybe<Scalars['DateTime']>;
  deliveryOption?: Maybe<DeliveryOption>;
  deliveryTimeInSeconds?: Maybe<Scalars['Int']>;
  expeditedAt?: Maybe<Scalars['DateTime']>;
  expo?: Maybe<WorkplaceCreateNestedOneWithoutExpoInput>;
  facility: FacilityCreateNestedOneWithoutOrdersInput;
  fee?: Maybe<Scalars['Float']>;
  formattedAddress?: Maybe<Scalars['String']>;
  fulfillmentAt: Scalars['DateTime'];
  fulfillmentBy: Scalars['DateTime'];
  giftCardCodes?: Maybe<OrderCreategiftCardCodesInput>;
  giftCardDiscounts?: Maybe<OrderCreategiftCardDiscountsInput>;
  giftCardsDiscount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  isEmployee?: Maybe<Scalars['Boolean']>;
  isOrderNow?: Maybe<Scalars['Boolean']>;
  isPaidByInvoice?: Maybe<Scalars['Boolean']>;
  isVip?: Maybe<Scalars['Boolean']>;
  items?: Maybe<OrderItemCreateNestedManyWithoutOrderInput>;
  kdsType?: Maybe<KdsType>;
  kitchenPreparingUntil?: Maybe<Scalars['DateTime']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  locality?: Maybe<Scalars['String']>;
  method?: Maybe<OrderMethod>;
  note?: Maybe<Scalars['String']>;
  numberOfGuests?: Maybe<Scalars['Int']>;
  orderEvents?: Maybe<OrderEventCreateNestedManyWithoutOrderInput>;
  orderName?: Maybe<Scalars['String']>;
  overwraps?: Maybe<Scalars['Int']>;
  packager?: Maybe<WorkplaceCreateNestedOneWithoutPackagerInput>;
  packedAt?: Maybe<Scalars['DateTime']>;
  payedAt?: Maybe<Scalars['DateTime']>;
  pickup?: Maybe<WorkplaceCreateNestedOneWithoutPickupInput>;
  placeId?: Maybe<Scalars['String']>;
  platform?: Maybe<Platform>;
  postalCode?: Maybe<Scalars['String']>;
  preparingUntil?: Maybe<Scalars['DateTime']>;
  promoCodes?: Maybe<OrderCreatepromoCodesInput>;
  promoDiscount: Scalars['Float'];
  promos?: Maybe<PromoCreateNestedManyWithoutOrdersInput>;
  refireDelay?: Maybe<Scalars['Int']>;
  refired?: Maybe<Scalars['Boolean']>;
  region?: Maybe<Scalars['String']>;
  runner?: Maybe<WorkplaceCreateNestedOneWithoutRunnerInput>;
  sequentialNumber?: Maybe<Scalars['Int']>;
  shortId: Scalars['String'];
  source?: Maybe<Scalars['String']>;
  status: OrderStatus;
  street?: Maybe<Scalars['String']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Float'];
  tags?: Maybe<TagCreateNestedManyWithoutOrdersInput>;
  tax?: Maybe<Scalars['Float']>;
  taxFees: Scalars['Float'];
  tip: Scalars['Float'];
  tookanTaskId?: Maybe<Scalars['String']>;
  total: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  utensilsCount?: Maybe<Scalars['Int']>;
};

export type OrderCreateWithoutPackagerInput = {
  addressNote?: Maybe<Scalars['String']>;
  braintreeTransactions?: Maybe<BraintreeTransactionCreateNestedManyWithoutOrderInput>;
  bundle?: Maybe<OrderBundleCreateNestedOneWithoutOrdersInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customer: UserCustomerInfoCreateNestedOneWithoutOrdersInput;
  deliverToEmail?: Maybe<Scalars['String']>;
  deliverToName?: Maybe<Scalars['String']>;
  deliverToPhone?: Maybe<Scalars['String']>;
  deliveredAt?: Maybe<Scalars['DateTime']>;
  delivery: Scalars['Float'];
  deliveryEstimation?: Maybe<Scalars['DateTime']>;
  deliveryOption?: Maybe<DeliveryOption>;
  deliveryTimeInSeconds?: Maybe<Scalars['Int']>;
  expeditedAt?: Maybe<Scalars['DateTime']>;
  expo?: Maybe<WorkplaceCreateNestedOneWithoutExpoInput>;
  facility: FacilityCreateNestedOneWithoutOrdersInput;
  fee?: Maybe<Scalars['Float']>;
  formattedAddress?: Maybe<Scalars['String']>;
  fulfillmentAt: Scalars['DateTime'];
  fulfillmentBy: Scalars['DateTime'];
  giftCardCodes?: Maybe<OrderCreategiftCardCodesInput>;
  giftCardDiscounts?: Maybe<OrderCreategiftCardDiscountsInput>;
  giftCardsDiscount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  isEmployee?: Maybe<Scalars['Boolean']>;
  isOrderNow?: Maybe<Scalars['Boolean']>;
  isPaidByInvoice?: Maybe<Scalars['Boolean']>;
  isVip?: Maybe<Scalars['Boolean']>;
  items?: Maybe<OrderItemCreateNestedManyWithoutOrderInput>;
  kdsType?: Maybe<KdsType>;
  kitchenPreparingUntil?: Maybe<Scalars['DateTime']>;
  kitchenTickets?: Maybe<KitchenTicketCreateNestedManyWithoutOrderInput>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  locality?: Maybe<Scalars['String']>;
  method?: Maybe<OrderMethod>;
  note?: Maybe<Scalars['String']>;
  numberOfGuests?: Maybe<Scalars['Int']>;
  orderEvents?: Maybe<OrderEventCreateNestedManyWithoutOrderInput>;
  orderName?: Maybe<Scalars['String']>;
  overwraps?: Maybe<Scalars['Int']>;
  packedAt?: Maybe<Scalars['DateTime']>;
  payedAt?: Maybe<Scalars['DateTime']>;
  pickup?: Maybe<WorkplaceCreateNestedOneWithoutPickupInput>;
  placeId?: Maybe<Scalars['String']>;
  platform?: Maybe<Platform>;
  postalCode?: Maybe<Scalars['String']>;
  preparingUntil?: Maybe<Scalars['DateTime']>;
  promoCodes?: Maybe<OrderCreatepromoCodesInput>;
  promoDiscount: Scalars['Float'];
  promos?: Maybe<PromoCreateNestedManyWithoutOrdersInput>;
  refireDelay?: Maybe<Scalars['Int']>;
  refired?: Maybe<Scalars['Boolean']>;
  region?: Maybe<Scalars['String']>;
  runner?: Maybe<WorkplaceCreateNestedOneWithoutRunnerInput>;
  sequentialNumber?: Maybe<Scalars['Int']>;
  shortId: Scalars['String'];
  source?: Maybe<Scalars['String']>;
  status: OrderStatus;
  street?: Maybe<Scalars['String']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Float'];
  tags?: Maybe<TagCreateNestedManyWithoutOrdersInput>;
  tax?: Maybe<Scalars['Float']>;
  taxFees: Scalars['Float'];
  tip: Scalars['Float'];
  tookanTaskId?: Maybe<Scalars['String']>;
  total: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  utensilsCount?: Maybe<Scalars['Int']>;
};

export type OrderCreateWithoutPickupInput = {
  addressNote?: Maybe<Scalars['String']>;
  braintreeTransactions?: Maybe<BraintreeTransactionCreateNestedManyWithoutOrderInput>;
  bundle?: Maybe<OrderBundleCreateNestedOneWithoutOrdersInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customer: UserCustomerInfoCreateNestedOneWithoutOrdersInput;
  deliverToEmail?: Maybe<Scalars['String']>;
  deliverToName?: Maybe<Scalars['String']>;
  deliverToPhone?: Maybe<Scalars['String']>;
  deliveredAt?: Maybe<Scalars['DateTime']>;
  delivery: Scalars['Float'];
  deliveryEstimation?: Maybe<Scalars['DateTime']>;
  deliveryOption?: Maybe<DeliveryOption>;
  deliveryTimeInSeconds?: Maybe<Scalars['Int']>;
  expeditedAt?: Maybe<Scalars['DateTime']>;
  expo?: Maybe<WorkplaceCreateNestedOneWithoutExpoInput>;
  facility: FacilityCreateNestedOneWithoutOrdersInput;
  fee?: Maybe<Scalars['Float']>;
  formattedAddress?: Maybe<Scalars['String']>;
  fulfillmentAt: Scalars['DateTime'];
  fulfillmentBy: Scalars['DateTime'];
  giftCardCodes?: Maybe<OrderCreategiftCardCodesInput>;
  giftCardDiscounts?: Maybe<OrderCreategiftCardDiscountsInput>;
  giftCardsDiscount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  isEmployee?: Maybe<Scalars['Boolean']>;
  isOrderNow?: Maybe<Scalars['Boolean']>;
  isPaidByInvoice?: Maybe<Scalars['Boolean']>;
  isVip?: Maybe<Scalars['Boolean']>;
  items?: Maybe<OrderItemCreateNestedManyWithoutOrderInput>;
  kdsType?: Maybe<KdsType>;
  kitchenPreparingUntil?: Maybe<Scalars['DateTime']>;
  kitchenTickets?: Maybe<KitchenTicketCreateNestedManyWithoutOrderInput>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  locality?: Maybe<Scalars['String']>;
  method?: Maybe<OrderMethod>;
  note?: Maybe<Scalars['String']>;
  numberOfGuests?: Maybe<Scalars['Int']>;
  orderEvents?: Maybe<OrderEventCreateNestedManyWithoutOrderInput>;
  orderName?: Maybe<Scalars['String']>;
  overwraps?: Maybe<Scalars['Int']>;
  packager?: Maybe<WorkplaceCreateNestedOneWithoutPackagerInput>;
  packedAt?: Maybe<Scalars['DateTime']>;
  payedAt?: Maybe<Scalars['DateTime']>;
  placeId?: Maybe<Scalars['String']>;
  platform?: Maybe<Platform>;
  postalCode?: Maybe<Scalars['String']>;
  preparingUntil?: Maybe<Scalars['DateTime']>;
  promoCodes?: Maybe<OrderCreatepromoCodesInput>;
  promoDiscount: Scalars['Float'];
  promos?: Maybe<PromoCreateNestedManyWithoutOrdersInput>;
  refireDelay?: Maybe<Scalars['Int']>;
  refired?: Maybe<Scalars['Boolean']>;
  region?: Maybe<Scalars['String']>;
  runner?: Maybe<WorkplaceCreateNestedOneWithoutRunnerInput>;
  sequentialNumber?: Maybe<Scalars['Int']>;
  shortId: Scalars['String'];
  source?: Maybe<Scalars['String']>;
  status: OrderStatus;
  street?: Maybe<Scalars['String']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Float'];
  tags?: Maybe<TagCreateNestedManyWithoutOrdersInput>;
  tax?: Maybe<Scalars['Float']>;
  taxFees: Scalars['Float'];
  tip: Scalars['Float'];
  tookanTaskId?: Maybe<Scalars['String']>;
  total: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  utensilsCount?: Maybe<Scalars['Int']>;
};

export type OrderCreateWithoutPromosInput = {
  addressNote?: Maybe<Scalars['String']>;
  braintreeTransactions?: Maybe<BraintreeTransactionCreateNestedManyWithoutOrderInput>;
  bundle?: Maybe<OrderBundleCreateNestedOneWithoutOrdersInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customer: UserCustomerInfoCreateNestedOneWithoutOrdersInput;
  deliverToEmail?: Maybe<Scalars['String']>;
  deliverToName?: Maybe<Scalars['String']>;
  deliverToPhone?: Maybe<Scalars['String']>;
  deliveredAt?: Maybe<Scalars['DateTime']>;
  delivery: Scalars['Float'];
  deliveryEstimation?: Maybe<Scalars['DateTime']>;
  deliveryOption?: Maybe<DeliveryOption>;
  deliveryTimeInSeconds?: Maybe<Scalars['Int']>;
  expeditedAt?: Maybe<Scalars['DateTime']>;
  expo?: Maybe<WorkplaceCreateNestedOneWithoutExpoInput>;
  facility: FacilityCreateNestedOneWithoutOrdersInput;
  fee?: Maybe<Scalars['Float']>;
  formattedAddress?: Maybe<Scalars['String']>;
  fulfillmentAt: Scalars['DateTime'];
  fulfillmentBy: Scalars['DateTime'];
  giftCardCodes?: Maybe<OrderCreategiftCardCodesInput>;
  giftCardDiscounts?: Maybe<OrderCreategiftCardDiscountsInput>;
  giftCardsDiscount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  isEmployee?: Maybe<Scalars['Boolean']>;
  isOrderNow?: Maybe<Scalars['Boolean']>;
  isPaidByInvoice?: Maybe<Scalars['Boolean']>;
  isVip?: Maybe<Scalars['Boolean']>;
  items?: Maybe<OrderItemCreateNestedManyWithoutOrderInput>;
  kdsType?: Maybe<KdsType>;
  kitchenPreparingUntil?: Maybe<Scalars['DateTime']>;
  kitchenTickets?: Maybe<KitchenTicketCreateNestedManyWithoutOrderInput>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  locality?: Maybe<Scalars['String']>;
  method?: Maybe<OrderMethod>;
  note?: Maybe<Scalars['String']>;
  numberOfGuests?: Maybe<Scalars['Int']>;
  orderEvents?: Maybe<OrderEventCreateNestedManyWithoutOrderInput>;
  orderName?: Maybe<Scalars['String']>;
  overwraps?: Maybe<Scalars['Int']>;
  packager?: Maybe<WorkplaceCreateNestedOneWithoutPackagerInput>;
  packedAt?: Maybe<Scalars['DateTime']>;
  payedAt?: Maybe<Scalars['DateTime']>;
  pickup?: Maybe<WorkplaceCreateNestedOneWithoutPickupInput>;
  placeId?: Maybe<Scalars['String']>;
  platform?: Maybe<Platform>;
  postalCode?: Maybe<Scalars['String']>;
  preparingUntil?: Maybe<Scalars['DateTime']>;
  promoCodes?: Maybe<OrderCreatepromoCodesInput>;
  promoDiscount: Scalars['Float'];
  refireDelay?: Maybe<Scalars['Int']>;
  refired?: Maybe<Scalars['Boolean']>;
  region?: Maybe<Scalars['String']>;
  runner?: Maybe<WorkplaceCreateNestedOneWithoutRunnerInput>;
  sequentialNumber?: Maybe<Scalars['Int']>;
  shortId: Scalars['String'];
  source?: Maybe<Scalars['String']>;
  status: OrderStatus;
  street?: Maybe<Scalars['String']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Float'];
  tags?: Maybe<TagCreateNestedManyWithoutOrdersInput>;
  tax?: Maybe<Scalars['Float']>;
  taxFees: Scalars['Float'];
  tip: Scalars['Float'];
  tookanTaskId?: Maybe<Scalars['String']>;
  total: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  utensilsCount?: Maybe<Scalars['Int']>;
};

export type OrderCreateWithoutRunnerInput = {
  addressNote?: Maybe<Scalars['String']>;
  braintreeTransactions?: Maybe<BraintreeTransactionCreateNestedManyWithoutOrderInput>;
  bundle?: Maybe<OrderBundleCreateNestedOneWithoutOrdersInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customer: UserCustomerInfoCreateNestedOneWithoutOrdersInput;
  deliverToEmail?: Maybe<Scalars['String']>;
  deliverToName?: Maybe<Scalars['String']>;
  deliverToPhone?: Maybe<Scalars['String']>;
  deliveredAt?: Maybe<Scalars['DateTime']>;
  delivery: Scalars['Float'];
  deliveryEstimation?: Maybe<Scalars['DateTime']>;
  deliveryOption?: Maybe<DeliveryOption>;
  deliveryTimeInSeconds?: Maybe<Scalars['Int']>;
  expeditedAt?: Maybe<Scalars['DateTime']>;
  expo?: Maybe<WorkplaceCreateNestedOneWithoutExpoInput>;
  facility: FacilityCreateNestedOneWithoutOrdersInput;
  fee?: Maybe<Scalars['Float']>;
  formattedAddress?: Maybe<Scalars['String']>;
  fulfillmentAt: Scalars['DateTime'];
  fulfillmentBy: Scalars['DateTime'];
  giftCardCodes?: Maybe<OrderCreategiftCardCodesInput>;
  giftCardDiscounts?: Maybe<OrderCreategiftCardDiscountsInput>;
  giftCardsDiscount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  isEmployee?: Maybe<Scalars['Boolean']>;
  isOrderNow?: Maybe<Scalars['Boolean']>;
  isPaidByInvoice?: Maybe<Scalars['Boolean']>;
  isVip?: Maybe<Scalars['Boolean']>;
  items?: Maybe<OrderItemCreateNestedManyWithoutOrderInput>;
  kdsType?: Maybe<KdsType>;
  kitchenPreparingUntil?: Maybe<Scalars['DateTime']>;
  kitchenTickets?: Maybe<KitchenTicketCreateNestedManyWithoutOrderInput>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  locality?: Maybe<Scalars['String']>;
  method?: Maybe<OrderMethod>;
  note?: Maybe<Scalars['String']>;
  numberOfGuests?: Maybe<Scalars['Int']>;
  orderEvents?: Maybe<OrderEventCreateNestedManyWithoutOrderInput>;
  orderName?: Maybe<Scalars['String']>;
  overwraps?: Maybe<Scalars['Int']>;
  packager?: Maybe<WorkplaceCreateNestedOneWithoutPackagerInput>;
  packedAt?: Maybe<Scalars['DateTime']>;
  payedAt?: Maybe<Scalars['DateTime']>;
  pickup?: Maybe<WorkplaceCreateNestedOneWithoutPickupInput>;
  placeId?: Maybe<Scalars['String']>;
  platform?: Maybe<Platform>;
  postalCode?: Maybe<Scalars['String']>;
  preparingUntil?: Maybe<Scalars['DateTime']>;
  promoCodes?: Maybe<OrderCreatepromoCodesInput>;
  promoDiscount: Scalars['Float'];
  promos?: Maybe<PromoCreateNestedManyWithoutOrdersInput>;
  refireDelay?: Maybe<Scalars['Int']>;
  refired?: Maybe<Scalars['Boolean']>;
  region?: Maybe<Scalars['String']>;
  sequentialNumber?: Maybe<Scalars['Int']>;
  shortId: Scalars['String'];
  source?: Maybe<Scalars['String']>;
  status: OrderStatus;
  street?: Maybe<Scalars['String']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Float'];
  tags?: Maybe<TagCreateNestedManyWithoutOrdersInput>;
  tax?: Maybe<Scalars['Float']>;
  taxFees: Scalars['Float'];
  tip: Scalars['Float'];
  tookanTaskId?: Maybe<Scalars['String']>;
  total: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  utensilsCount?: Maybe<Scalars['Int']>;
};

export type OrderCreateWithoutTagsInput = {
  addressNote?: Maybe<Scalars['String']>;
  braintreeTransactions?: Maybe<BraintreeTransactionCreateNestedManyWithoutOrderInput>;
  bundle?: Maybe<OrderBundleCreateNestedOneWithoutOrdersInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customer: UserCustomerInfoCreateNestedOneWithoutOrdersInput;
  deliverToEmail?: Maybe<Scalars['String']>;
  deliverToName?: Maybe<Scalars['String']>;
  deliverToPhone?: Maybe<Scalars['String']>;
  deliveredAt?: Maybe<Scalars['DateTime']>;
  delivery: Scalars['Float'];
  deliveryEstimation?: Maybe<Scalars['DateTime']>;
  deliveryOption?: Maybe<DeliveryOption>;
  deliveryTimeInSeconds?: Maybe<Scalars['Int']>;
  expeditedAt?: Maybe<Scalars['DateTime']>;
  expo?: Maybe<WorkplaceCreateNestedOneWithoutExpoInput>;
  facility: FacilityCreateNestedOneWithoutOrdersInput;
  fee?: Maybe<Scalars['Float']>;
  formattedAddress?: Maybe<Scalars['String']>;
  fulfillmentAt: Scalars['DateTime'];
  fulfillmentBy: Scalars['DateTime'];
  giftCardCodes?: Maybe<OrderCreategiftCardCodesInput>;
  giftCardDiscounts?: Maybe<OrderCreategiftCardDiscountsInput>;
  giftCardsDiscount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  isEmployee?: Maybe<Scalars['Boolean']>;
  isOrderNow?: Maybe<Scalars['Boolean']>;
  isPaidByInvoice?: Maybe<Scalars['Boolean']>;
  isVip?: Maybe<Scalars['Boolean']>;
  items?: Maybe<OrderItemCreateNestedManyWithoutOrderInput>;
  kdsType?: Maybe<KdsType>;
  kitchenPreparingUntil?: Maybe<Scalars['DateTime']>;
  kitchenTickets?: Maybe<KitchenTicketCreateNestedManyWithoutOrderInput>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  locality?: Maybe<Scalars['String']>;
  method?: Maybe<OrderMethod>;
  note?: Maybe<Scalars['String']>;
  numberOfGuests?: Maybe<Scalars['Int']>;
  orderEvents?: Maybe<OrderEventCreateNestedManyWithoutOrderInput>;
  orderName?: Maybe<Scalars['String']>;
  overwraps?: Maybe<Scalars['Int']>;
  packager?: Maybe<WorkplaceCreateNestedOneWithoutPackagerInput>;
  packedAt?: Maybe<Scalars['DateTime']>;
  payedAt?: Maybe<Scalars['DateTime']>;
  pickup?: Maybe<WorkplaceCreateNestedOneWithoutPickupInput>;
  placeId?: Maybe<Scalars['String']>;
  platform?: Maybe<Platform>;
  postalCode?: Maybe<Scalars['String']>;
  preparingUntil?: Maybe<Scalars['DateTime']>;
  promoCodes?: Maybe<OrderCreatepromoCodesInput>;
  promoDiscount: Scalars['Float'];
  promos?: Maybe<PromoCreateNestedManyWithoutOrdersInput>;
  refireDelay?: Maybe<Scalars['Int']>;
  refired?: Maybe<Scalars['Boolean']>;
  region?: Maybe<Scalars['String']>;
  runner?: Maybe<WorkplaceCreateNestedOneWithoutRunnerInput>;
  sequentialNumber?: Maybe<Scalars['Int']>;
  shortId: Scalars['String'];
  source?: Maybe<Scalars['String']>;
  status: OrderStatus;
  street?: Maybe<Scalars['String']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  subtotal: Scalars['Float'];
  tax?: Maybe<Scalars['Float']>;
  taxFees: Scalars['Float'];
  tip: Scalars['Float'];
  tookanTaskId?: Maybe<Scalars['String']>;
  total: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  utensilsCount?: Maybe<Scalars['Int']>;
};

export type OrderCreategiftCardCodesInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type OrderCreategiftCardDiscountsInput = {
  set?: Maybe<Array<Scalars['Float']>>;
};

export type OrderCreatepromoCodesInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type OrderEvent = {
  __typename?: 'OrderEvent';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  metadata: Scalars['Json'];
  type: OrderEventType;
  updatedAt: Scalars['DateTime'];
};

export type OrderEventCreateManyOrderInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  metadata: Scalars['Json'];
  type: OrderEventType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderEventCreateManyOrderInputEnvelope = {
  data?: Maybe<Array<OrderEventCreateManyOrderInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OrderEventCreateNestedManyWithoutOrderInput = {
  connect?: Maybe<Array<OrderEventWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrderEventCreateOrConnectWithoutOrderInput>>;
  create?: Maybe<Array<OrderEventCreateWithoutOrderInput>>;
  createMany?: Maybe<OrderEventCreateManyOrderInputEnvelope>;
};

export type OrderEventCreateOrConnectWithoutOrderInput = {
  create: OrderEventCreateWithoutOrderInput;
  where: OrderEventWhereUniqueInput;
};

export type OrderEventCreateWithoutOrderInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  metadata: Scalars['Json'];
  type: OrderEventType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderEventListRelationFilter = {
  every?: Maybe<OrderEventWhereInput>;
  none?: Maybe<OrderEventWhereInput>;
  some?: Maybe<OrderEventWhereInput>;
};

export type OrderEventScalarWhereInput = {
  AND?: Maybe<Array<OrderEventScalarWhereInput>>;
  NOT?: Maybe<Array<OrderEventScalarWhereInput>>;
  OR?: Maybe<Array<OrderEventScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  orderId?: Maybe<StringFilter>;
  type?: Maybe<EnumOrderEventTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export enum OrderEventType {
  Created = 'CREATED',
  ExpoRefired = 'EXPO_REFIRED',
  KitchenDelay = 'KITCHEN_DELAY',
  KitchenStatusUpdate = 'KITCHEN_STATUS_UPDATE',
  StatusUpdate = 'STATUS_UPDATE',
  WorkplaceUpdate = 'WORKPLACE_UPDATE'
}

export type OrderEventUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  metadata?: Maybe<Scalars['Json']>;
  type?: Maybe<EnumOrderEventTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderEventUpdateManyWithWhereWithoutOrderInput = {
  data: OrderEventUpdateManyMutationInput;
  where: OrderEventScalarWhereInput;
};

export type OrderEventUpdateManyWithoutOrderInput = {
  connect?: Maybe<Array<OrderEventWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrderEventCreateOrConnectWithoutOrderInput>>;
  create?: Maybe<Array<OrderEventCreateWithoutOrderInput>>;
  createMany?: Maybe<OrderEventCreateManyOrderInputEnvelope>;
  delete?: Maybe<Array<OrderEventWhereUniqueInput>>;
  deleteMany?: Maybe<Array<OrderEventScalarWhereInput>>;
  disconnect?: Maybe<Array<OrderEventWhereUniqueInput>>;
  set?: Maybe<Array<OrderEventWhereUniqueInput>>;
  update?: Maybe<Array<OrderEventUpdateWithWhereUniqueWithoutOrderInput>>;
  updateMany?: Maybe<Array<OrderEventUpdateManyWithWhereWithoutOrderInput>>;
  upsert?: Maybe<Array<OrderEventUpsertWithWhereUniqueWithoutOrderInput>>;
};

export type OrderEventUpdateWithWhereUniqueWithoutOrderInput = {
  data: OrderEventUpdateWithoutOrderInput;
  where: OrderEventWhereUniqueInput;
};

export type OrderEventUpdateWithoutOrderInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  metadata?: Maybe<Scalars['Json']>;
  type?: Maybe<EnumOrderEventTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderEventUpsertWithWhereUniqueWithoutOrderInput = {
  create: OrderEventCreateWithoutOrderInput;
  update: OrderEventUpdateWithoutOrderInput;
  where: OrderEventWhereUniqueInput;
};

export type OrderEventWhereInput = {
  AND?: Maybe<Array<OrderEventWhereInput>>;
  NOT?: Maybe<Array<OrderEventWhereInput>>;
  OR?: Maybe<Array<OrderEventWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  order?: Maybe<OrderWhereInput>;
  orderId?: Maybe<StringFilter>;
  type?: Maybe<EnumOrderEventTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type OrderEventWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type OrderExtraItem = {
  __typename?: 'OrderExtraItem';
  extraItem: ExtraItem;
  extraItemId: Scalars['ID'];
  quantity: Scalars['Float'];
};

export type OrderInput = {
  addressId?: Maybe<Scalars['ID']>;
  deliverToEmail?: Maybe<Scalars['String']>;
  deliverToName?: Maybe<Scalars['String']>;
  deliverToPhone?: Maybe<Scalars['String']>;
  deliveryOption?: Maybe<DeliveryOption>;
  fulfillmentAt?: Maybe<Scalars['DateTime']>;
  isOrderNow: Scalars['Boolean'];
  note?: Maybe<Scalars['String']>;
  numberOfGuests?: Maybe<Scalars['Int']>;
  orderMethod: OrderMethod;
  orderName?: Maybe<Scalars['String']>;
  tip?: Maybe<Scalars['Float']>;
  tipPercent?: Maybe<Scalars['Float']>;
};

export type OrderItem = {
  __typename?: 'OrderItem';
  extraItems: Array<OrderItemExtra>;
  facilityItem: Array<FacilityItem>;
  id: Scalars['String'];
  mealPackOrderItems: Array<OrderItem>;
  menuItem: KitchenMenuItem;
  menuItemId: Scalars['String'];
  name: Scalars['String'];
  order: Order;
  orderId: Scalars['String'];
  orderMealPack?: Maybe<OrderItem>;
  orderMealPackId?: Maybe<Scalars['String']>;
  price: Scalars['Float'];
  quantity: Scalars['Float'];
  type: OrderItemType;
};


export type OrderItemExtraItemsArgs = {
  after?: Maybe<OrderItemExtraWhereUniqueInput>;
  before?: Maybe<OrderItemExtraWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type OrderItemFacilityItemArgs = {
  after?: Maybe<FacilityItemWhereUniqueInput>;
  before?: Maybe<FacilityItemWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type OrderItemMealPackOrderItemsArgs = {
  after?: Maybe<OrderItemWhereUniqueInput>;
  before?: Maybe<OrderItemWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type OrderItemCreateManyMenuItemInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  orderId: Scalars['String'];
  orderMealPackId?: Maybe<Scalars['String']>;
  price: Scalars['Float'];
  quantity: Scalars['Float'];
  type?: Maybe<OrderItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderItemCreateManyMenuItemInputEnvelope = {
  data?: Maybe<Array<OrderItemCreateManyMenuItemInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OrderItemCreateManyOrderInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  menuItemId: Scalars['String'];
  name: Scalars['String'];
  orderMealPackId?: Maybe<Scalars['String']>;
  price: Scalars['Float'];
  quantity: Scalars['Float'];
  type?: Maybe<OrderItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderItemCreateManyOrderInputEnvelope = {
  data?: Maybe<Array<OrderItemCreateManyOrderInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OrderItemCreateManyOrderMealPackInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  menuItemId: Scalars['String'];
  name: Scalars['String'];
  orderId: Scalars['String'];
  price: Scalars['Float'];
  quantity: Scalars['Float'];
  type?: Maybe<OrderItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderItemCreateManyOrderMealPackInputEnvelope = {
  data?: Maybe<Array<OrderItemCreateManyOrderMealPackInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OrderItemCreateNestedManyWithoutMenuItemInput = {
  connect?: Maybe<Array<OrderItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrderItemCreateOrConnectWithoutMenuItemInput>>;
  create?: Maybe<Array<OrderItemCreateWithoutMenuItemInput>>;
  createMany?: Maybe<OrderItemCreateManyMenuItemInputEnvelope>;
};

export type OrderItemCreateNestedManyWithoutOrderInput = {
  connect?: Maybe<Array<OrderItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrderItemCreateOrConnectWithoutOrderInput>>;
  create?: Maybe<Array<OrderItemCreateWithoutOrderInput>>;
  createMany?: Maybe<OrderItemCreateManyOrderInputEnvelope>;
};

export type OrderItemCreateNestedManyWithoutOrderMealPackInput = {
  connect?: Maybe<Array<OrderItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrderItemCreateOrConnectWithoutOrderMealPackInput>>;
  create?: Maybe<Array<OrderItemCreateWithoutOrderMealPackInput>>;
  createMany?: Maybe<OrderItemCreateManyOrderMealPackInputEnvelope>;
};

export type OrderItemCreateNestedOneWithoutExtraItemsInput = {
  connect?: Maybe<OrderItemWhereUniqueInput>;
  connectOrCreate?: Maybe<OrderItemCreateOrConnectWithoutExtraItemsInput>;
  create?: Maybe<OrderItemCreateWithoutExtraItemsInput>;
};

export type OrderItemCreateNestedOneWithoutFacilityItemInput = {
  connect?: Maybe<OrderItemWhereUniqueInput>;
  connectOrCreate?: Maybe<OrderItemCreateOrConnectWithoutFacilityItemInput>;
  create?: Maybe<OrderItemCreateWithoutFacilityItemInput>;
};

export type OrderItemCreateNestedOneWithoutMealPackOrderItemsInput = {
  connect?: Maybe<OrderItemWhereUniqueInput>;
  connectOrCreate?: Maybe<OrderItemCreateOrConnectWithoutMealPackOrderItemsInput>;
  create?: Maybe<OrderItemCreateWithoutMealPackOrderItemsInput>;
};

export type OrderItemCreateOrConnectWithoutExtraItemsInput = {
  create: OrderItemCreateWithoutExtraItemsInput;
  where: OrderItemWhereUniqueInput;
};

export type OrderItemCreateOrConnectWithoutFacilityItemInput = {
  create: OrderItemCreateWithoutFacilityItemInput;
  where: OrderItemWhereUniqueInput;
};

export type OrderItemCreateOrConnectWithoutMealPackOrderItemsInput = {
  create: OrderItemCreateWithoutMealPackOrderItemsInput;
  where: OrderItemWhereUniqueInput;
};

export type OrderItemCreateOrConnectWithoutMenuItemInput = {
  create: OrderItemCreateWithoutMenuItemInput;
  where: OrderItemWhereUniqueInput;
};

export type OrderItemCreateOrConnectWithoutOrderInput = {
  create: OrderItemCreateWithoutOrderInput;
  where: OrderItemWhereUniqueInput;
};

export type OrderItemCreateOrConnectWithoutOrderMealPackInput = {
  create: OrderItemCreateWithoutOrderMealPackInput;
  where: OrderItemWhereUniqueInput;
};

export type OrderItemCreateWithoutExtraItemsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  facilityItem?: Maybe<FacilityItemCreateNestedManyWithoutOrderItemInput>;
  id?: Maybe<Scalars['String']>;
  mealPackOrderItems?: Maybe<OrderItemCreateNestedManyWithoutOrderMealPackInput>;
  menuItem: KitchenMenuItemCreateNestedOneWithoutOrderItemsInput;
  name: Scalars['String'];
  order: OrderCreateNestedOneWithoutItemsInput;
  orderMealPack?: Maybe<OrderItemCreateNestedOneWithoutMealPackOrderItemsInput>;
  price: Scalars['Float'];
  quantity: Scalars['Float'];
  type?: Maybe<OrderItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderItemCreateWithoutFacilityItemInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  extraItems?: Maybe<OrderItemExtraCreateNestedManyWithoutOrderItemInput>;
  id?: Maybe<Scalars['String']>;
  mealPackOrderItems?: Maybe<OrderItemCreateNestedManyWithoutOrderMealPackInput>;
  menuItem: KitchenMenuItemCreateNestedOneWithoutOrderItemsInput;
  name: Scalars['String'];
  order: OrderCreateNestedOneWithoutItemsInput;
  orderMealPack?: Maybe<OrderItemCreateNestedOneWithoutMealPackOrderItemsInput>;
  price: Scalars['Float'];
  quantity: Scalars['Float'];
  type?: Maybe<OrderItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderItemCreateWithoutMealPackOrderItemsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  extraItems?: Maybe<OrderItemExtraCreateNestedManyWithoutOrderItemInput>;
  facilityItem?: Maybe<FacilityItemCreateNestedManyWithoutOrderItemInput>;
  id?: Maybe<Scalars['String']>;
  menuItem: KitchenMenuItemCreateNestedOneWithoutOrderItemsInput;
  name: Scalars['String'];
  order: OrderCreateNestedOneWithoutItemsInput;
  orderMealPack?: Maybe<OrderItemCreateNestedOneWithoutMealPackOrderItemsInput>;
  price: Scalars['Float'];
  quantity: Scalars['Float'];
  type?: Maybe<OrderItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderItemCreateWithoutMenuItemInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  extraItems?: Maybe<OrderItemExtraCreateNestedManyWithoutOrderItemInput>;
  facilityItem?: Maybe<FacilityItemCreateNestedManyWithoutOrderItemInput>;
  id?: Maybe<Scalars['String']>;
  mealPackOrderItems?: Maybe<OrderItemCreateNestedManyWithoutOrderMealPackInput>;
  name: Scalars['String'];
  order: OrderCreateNestedOneWithoutItemsInput;
  orderMealPack?: Maybe<OrderItemCreateNestedOneWithoutMealPackOrderItemsInput>;
  price: Scalars['Float'];
  quantity: Scalars['Float'];
  type?: Maybe<OrderItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderItemCreateWithoutOrderInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  extraItems?: Maybe<OrderItemExtraCreateNestedManyWithoutOrderItemInput>;
  facilityItem?: Maybe<FacilityItemCreateNestedManyWithoutOrderItemInput>;
  id?: Maybe<Scalars['String']>;
  mealPackOrderItems?: Maybe<OrderItemCreateNestedManyWithoutOrderMealPackInput>;
  menuItem: KitchenMenuItemCreateNestedOneWithoutOrderItemsInput;
  name: Scalars['String'];
  orderMealPack?: Maybe<OrderItemCreateNestedOneWithoutMealPackOrderItemsInput>;
  price: Scalars['Float'];
  quantity: Scalars['Float'];
  type?: Maybe<OrderItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderItemCreateWithoutOrderMealPackInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  extraItems?: Maybe<OrderItemExtraCreateNestedManyWithoutOrderItemInput>;
  facilityItem?: Maybe<FacilityItemCreateNestedManyWithoutOrderItemInput>;
  id?: Maybe<Scalars['String']>;
  mealPackOrderItems?: Maybe<OrderItemCreateNestedManyWithoutOrderMealPackInput>;
  menuItem: KitchenMenuItemCreateNestedOneWithoutOrderItemsInput;
  name: Scalars['String'];
  order: OrderCreateNestedOneWithoutItemsInput;
  price: Scalars['Float'];
  quantity: Scalars['Float'];
  type?: Maybe<OrderItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderItemExtra = {
  __typename?: 'OrderItemExtra';
  extraItem?: Maybe<ExtraItem>;
  extraItemSortId: Scalars['Int'];
  extraSortId: Scalars['Int'];
  id: Scalars['String'];
  mealPackItemId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  orderItem: OrderItem;
  price: Scalars['Float'];
  quantity: Scalars['Int'];
};

export type OrderItemExtraCreateManyExtraItemInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  extraItemSortId?: Maybe<Scalars['Int']>;
  extraSortId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  mealPackItemId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  orderItemId: Scalars['String'];
  price: Scalars['Float'];
  quantity: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderItemExtraCreateManyExtraItemInputEnvelope = {
  data?: Maybe<Array<OrderItemExtraCreateManyExtraItemInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OrderItemExtraCreateManyOrderItemInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  extraItemId?: Maybe<Scalars['String']>;
  extraItemSortId?: Maybe<Scalars['Int']>;
  extraSortId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  mealPackItemId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  quantity: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderItemExtraCreateManyOrderItemInputEnvelope = {
  data?: Maybe<Array<OrderItemExtraCreateManyOrderItemInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OrderItemExtraCreateNestedManyWithoutExtraItemInput = {
  connect?: Maybe<Array<OrderItemExtraWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrderItemExtraCreateOrConnectWithoutExtraItemInput>>;
  create?: Maybe<Array<OrderItemExtraCreateWithoutExtraItemInput>>;
  createMany?: Maybe<OrderItemExtraCreateManyExtraItemInputEnvelope>;
};

export type OrderItemExtraCreateNestedManyWithoutOrderItemInput = {
  connect?: Maybe<Array<OrderItemExtraWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrderItemExtraCreateOrConnectWithoutOrderItemInput>>;
  create?: Maybe<Array<OrderItemExtraCreateWithoutOrderItemInput>>;
  createMany?: Maybe<OrderItemExtraCreateManyOrderItemInputEnvelope>;
};

export type OrderItemExtraCreateOrConnectWithoutExtraItemInput = {
  create: OrderItemExtraCreateWithoutExtraItemInput;
  where: OrderItemExtraWhereUniqueInput;
};

export type OrderItemExtraCreateOrConnectWithoutOrderItemInput = {
  create: OrderItemExtraCreateWithoutOrderItemInput;
  where: OrderItemExtraWhereUniqueInput;
};

export type OrderItemExtraCreateWithoutExtraItemInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  extraItemSortId?: Maybe<Scalars['Int']>;
  extraSortId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  mealPackItemId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  orderItem: OrderItemCreateNestedOneWithoutExtraItemsInput;
  price: Scalars['Float'];
  quantity: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderItemExtraCreateWithoutOrderItemInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  extraItem?: Maybe<ExtraItemCreateNestedOneWithoutOrderItemExtrasInput>;
  extraItemSortId?: Maybe<Scalars['Int']>;
  extraSortId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  mealPackItemId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  quantity: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderItemExtraListRelationFilter = {
  every?: Maybe<OrderItemExtraWhereInput>;
  none?: Maybe<OrderItemExtraWhereInput>;
  some?: Maybe<OrderItemExtraWhereInput>;
};

export type OrderItemExtraScalarWhereInput = {
  AND?: Maybe<Array<OrderItemExtraScalarWhereInput>>;
  NOT?: Maybe<Array<OrderItemExtraScalarWhereInput>>;
  OR?: Maybe<Array<OrderItemExtraScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  extraItemId?: Maybe<StringNullableFilter>;
  extraItemSortId?: Maybe<IntFilter>;
  extraSortId?: Maybe<IntFilter>;
  id?: Maybe<StringFilter>;
  mealPackItemId?: Maybe<StringNullableFilter>;
  name?: Maybe<StringFilter>;
  orderItemId?: Maybe<StringFilter>;
  price?: Maybe<FloatFilter>;
  quantity?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type OrderItemExtraUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  extraItemSortId?: Maybe<IntFieldUpdateOperationsInput>;
  extraSortId?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  mealPackItemId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  quantity?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderItemExtraUpdateManyWithWhereWithoutExtraItemInput = {
  data: OrderItemExtraUpdateManyMutationInput;
  where: OrderItemExtraScalarWhereInput;
};

export type OrderItemExtraUpdateManyWithWhereWithoutOrderItemInput = {
  data: OrderItemExtraUpdateManyMutationInput;
  where: OrderItemExtraScalarWhereInput;
};

export type OrderItemExtraUpdateManyWithoutExtraItemInput = {
  connect?: Maybe<Array<OrderItemExtraWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrderItemExtraCreateOrConnectWithoutExtraItemInput>>;
  create?: Maybe<Array<OrderItemExtraCreateWithoutExtraItemInput>>;
  createMany?: Maybe<OrderItemExtraCreateManyExtraItemInputEnvelope>;
  delete?: Maybe<Array<OrderItemExtraWhereUniqueInput>>;
  deleteMany?: Maybe<Array<OrderItemExtraScalarWhereInput>>;
  disconnect?: Maybe<Array<OrderItemExtraWhereUniqueInput>>;
  set?: Maybe<Array<OrderItemExtraWhereUniqueInput>>;
  update?: Maybe<Array<OrderItemExtraUpdateWithWhereUniqueWithoutExtraItemInput>>;
  updateMany?: Maybe<Array<OrderItemExtraUpdateManyWithWhereWithoutExtraItemInput>>;
  upsert?: Maybe<Array<OrderItemExtraUpsertWithWhereUniqueWithoutExtraItemInput>>;
};

export type OrderItemExtraUpdateManyWithoutOrderItemInput = {
  connect?: Maybe<Array<OrderItemExtraWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrderItemExtraCreateOrConnectWithoutOrderItemInput>>;
  create?: Maybe<Array<OrderItemExtraCreateWithoutOrderItemInput>>;
  createMany?: Maybe<OrderItemExtraCreateManyOrderItemInputEnvelope>;
  delete?: Maybe<Array<OrderItemExtraWhereUniqueInput>>;
  deleteMany?: Maybe<Array<OrderItemExtraScalarWhereInput>>;
  disconnect?: Maybe<Array<OrderItemExtraWhereUniqueInput>>;
  set?: Maybe<Array<OrderItemExtraWhereUniqueInput>>;
  update?: Maybe<Array<OrderItemExtraUpdateWithWhereUniqueWithoutOrderItemInput>>;
  updateMany?: Maybe<Array<OrderItemExtraUpdateManyWithWhereWithoutOrderItemInput>>;
  upsert?: Maybe<Array<OrderItemExtraUpsertWithWhereUniqueWithoutOrderItemInput>>;
};

export type OrderItemExtraUpdateWithWhereUniqueWithoutExtraItemInput = {
  data: OrderItemExtraUpdateWithoutExtraItemInput;
  where: OrderItemExtraWhereUniqueInput;
};

export type OrderItemExtraUpdateWithWhereUniqueWithoutOrderItemInput = {
  data: OrderItemExtraUpdateWithoutOrderItemInput;
  where: OrderItemExtraWhereUniqueInput;
};

export type OrderItemExtraUpdateWithoutExtraItemInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  extraItemSortId?: Maybe<IntFieldUpdateOperationsInput>;
  extraSortId?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  mealPackItemId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  orderItem?: Maybe<OrderItemUpdateOneRequiredWithoutExtraItemsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  quantity?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderItemExtraUpdateWithoutOrderItemInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  extraItem?: Maybe<ExtraItemUpdateOneWithoutOrderItemExtrasInput>;
  extraItemSortId?: Maybe<IntFieldUpdateOperationsInput>;
  extraSortId?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  mealPackItemId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  quantity?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderItemExtraUpsertWithWhereUniqueWithoutExtraItemInput = {
  create: OrderItemExtraCreateWithoutExtraItemInput;
  update: OrderItemExtraUpdateWithoutExtraItemInput;
  where: OrderItemExtraWhereUniqueInput;
};

export type OrderItemExtraUpsertWithWhereUniqueWithoutOrderItemInput = {
  create: OrderItemExtraCreateWithoutOrderItemInput;
  update: OrderItemExtraUpdateWithoutOrderItemInput;
  where: OrderItemExtraWhereUniqueInput;
};

export type OrderItemExtraWhereInput = {
  AND?: Maybe<Array<OrderItemExtraWhereInput>>;
  NOT?: Maybe<Array<OrderItemExtraWhereInput>>;
  OR?: Maybe<Array<OrderItemExtraWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  extraItem?: Maybe<ExtraItemWhereInput>;
  extraItemId?: Maybe<StringNullableFilter>;
  extraItemSortId?: Maybe<IntFilter>;
  extraSortId?: Maybe<IntFilter>;
  id?: Maybe<StringFilter>;
  mealPackItemId?: Maybe<StringNullableFilter>;
  name?: Maybe<StringFilter>;
  orderItem?: Maybe<OrderItemWhereInput>;
  orderItemId?: Maybe<StringFilter>;
  price?: Maybe<FloatFilter>;
  quantity?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type OrderItemExtraWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type OrderItemInput = {
  extraItems?: Maybe<Array<Maybe<ExtraItemInput>>>;
  productId: Scalars['ID'];
  quantity: Scalars['Float'];
};

export type OrderItemListRelationFilter = {
  every?: Maybe<OrderItemWhereInput>;
  none?: Maybe<OrderItemWhereInput>;
  some?: Maybe<OrderItemWhereInput>;
};

export type OrderItemScalarWhereInput = {
  AND?: Maybe<Array<OrderItemScalarWhereInput>>;
  NOT?: Maybe<Array<OrderItemScalarWhereInput>>;
  OR?: Maybe<Array<OrderItemScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  menuItemId?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  orderId?: Maybe<StringFilter>;
  orderMealPackId?: Maybe<StringNullableFilter>;
  price?: Maybe<FloatFilter>;
  quantity?: Maybe<FloatFilter>;
  type?: Maybe<EnumOrderItemTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export enum OrderItemType {
  Item = 'ITEM',
  MealPack = 'MEAL_PACK',
  MealPackItem = 'MEAL_PACK_ITEM'
}

export type OrderItemUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  quantity?: Maybe<FloatFieldUpdateOperationsInput>;
  type?: Maybe<EnumOrderItemTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderItemUpdateManyWithWhereWithoutMenuItemInput = {
  data: OrderItemUpdateManyMutationInput;
  where: OrderItemScalarWhereInput;
};

export type OrderItemUpdateManyWithWhereWithoutOrderInput = {
  data: OrderItemUpdateManyMutationInput;
  where: OrderItemScalarWhereInput;
};

export type OrderItemUpdateManyWithWhereWithoutOrderMealPackInput = {
  data: OrderItemUpdateManyMutationInput;
  where: OrderItemScalarWhereInput;
};

export type OrderItemUpdateManyWithoutMenuItemInput = {
  connect?: Maybe<Array<OrderItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrderItemCreateOrConnectWithoutMenuItemInput>>;
  create?: Maybe<Array<OrderItemCreateWithoutMenuItemInput>>;
  createMany?: Maybe<OrderItemCreateManyMenuItemInputEnvelope>;
  delete?: Maybe<Array<OrderItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<OrderItemScalarWhereInput>>;
  disconnect?: Maybe<Array<OrderItemWhereUniqueInput>>;
  set?: Maybe<Array<OrderItemWhereUniqueInput>>;
  update?: Maybe<Array<OrderItemUpdateWithWhereUniqueWithoutMenuItemInput>>;
  updateMany?: Maybe<Array<OrderItemUpdateManyWithWhereWithoutMenuItemInput>>;
  upsert?: Maybe<Array<OrderItemUpsertWithWhereUniqueWithoutMenuItemInput>>;
};

export type OrderItemUpdateManyWithoutOrderInput = {
  connect?: Maybe<Array<OrderItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrderItemCreateOrConnectWithoutOrderInput>>;
  create?: Maybe<Array<OrderItemCreateWithoutOrderInput>>;
  createMany?: Maybe<OrderItemCreateManyOrderInputEnvelope>;
  delete?: Maybe<Array<OrderItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<OrderItemScalarWhereInput>>;
  disconnect?: Maybe<Array<OrderItemWhereUniqueInput>>;
  set?: Maybe<Array<OrderItemWhereUniqueInput>>;
  update?: Maybe<Array<OrderItemUpdateWithWhereUniqueWithoutOrderInput>>;
  updateMany?: Maybe<Array<OrderItemUpdateManyWithWhereWithoutOrderInput>>;
  upsert?: Maybe<Array<OrderItemUpsertWithWhereUniqueWithoutOrderInput>>;
};

export type OrderItemUpdateManyWithoutOrderMealPackInput = {
  connect?: Maybe<Array<OrderItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrderItemCreateOrConnectWithoutOrderMealPackInput>>;
  create?: Maybe<Array<OrderItemCreateWithoutOrderMealPackInput>>;
  createMany?: Maybe<OrderItemCreateManyOrderMealPackInputEnvelope>;
  delete?: Maybe<Array<OrderItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<OrderItemScalarWhereInput>>;
  disconnect?: Maybe<Array<OrderItemWhereUniqueInput>>;
  set?: Maybe<Array<OrderItemWhereUniqueInput>>;
  update?: Maybe<Array<OrderItemUpdateWithWhereUniqueWithoutOrderMealPackInput>>;
  updateMany?: Maybe<Array<OrderItemUpdateManyWithWhereWithoutOrderMealPackInput>>;
  upsert?: Maybe<Array<OrderItemUpsertWithWhereUniqueWithoutOrderMealPackInput>>;
};

export type OrderItemUpdateOneRequiredWithoutExtraItemsInput = {
  connect?: Maybe<OrderItemWhereUniqueInput>;
  connectOrCreate?: Maybe<OrderItemCreateOrConnectWithoutExtraItemsInput>;
  create?: Maybe<OrderItemCreateWithoutExtraItemsInput>;
  update?: Maybe<OrderItemUpdateWithoutExtraItemsInput>;
  upsert?: Maybe<OrderItemUpsertWithoutExtraItemsInput>;
};

export type OrderItemUpdateOneRequiredWithoutFacilityItemInput = {
  connect?: Maybe<OrderItemWhereUniqueInput>;
  connectOrCreate?: Maybe<OrderItemCreateOrConnectWithoutFacilityItemInput>;
  create?: Maybe<OrderItemCreateWithoutFacilityItemInput>;
  update?: Maybe<OrderItemUpdateWithoutFacilityItemInput>;
  upsert?: Maybe<OrderItemUpsertWithoutFacilityItemInput>;
};

export type OrderItemUpdateOneWithoutMealPackOrderItemsInput = {
  connect?: Maybe<OrderItemWhereUniqueInput>;
  connectOrCreate?: Maybe<OrderItemCreateOrConnectWithoutMealPackOrderItemsInput>;
  create?: Maybe<OrderItemCreateWithoutMealPackOrderItemsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<OrderItemUpdateWithoutMealPackOrderItemsInput>;
  upsert?: Maybe<OrderItemUpsertWithoutMealPackOrderItemsInput>;
};

export type OrderItemUpdateWithWhereUniqueWithoutMenuItemInput = {
  data: OrderItemUpdateWithoutMenuItemInput;
  where: OrderItemWhereUniqueInput;
};

export type OrderItemUpdateWithWhereUniqueWithoutOrderInput = {
  data: OrderItemUpdateWithoutOrderInput;
  where: OrderItemWhereUniqueInput;
};

export type OrderItemUpdateWithWhereUniqueWithoutOrderMealPackInput = {
  data: OrderItemUpdateWithoutOrderMealPackInput;
  where: OrderItemWhereUniqueInput;
};

export type OrderItemUpdateWithoutExtraItemsInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  facilityItem?: Maybe<FacilityItemUpdateManyWithoutOrderItemInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  mealPackOrderItems?: Maybe<OrderItemUpdateManyWithoutOrderMealPackInput>;
  menuItem?: Maybe<KitchenMenuItemUpdateOneRequiredWithoutOrderItemsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<OrderUpdateOneRequiredWithoutItemsInput>;
  orderMealPack?: Maybe<OrderItemUpdateOneWithoutMealPackOrderItemsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  quantity?: Maybe<FloatFieldUpdateOperationsInput>;
  type?: Maybe<EnumOrderItemTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderItemUpdateWithoutFacilityItemInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  extraItems?: Maybe<OrderItemExtraUpdateManyWithoutOrderItemInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  mealPackOrderItems?: Maybe<OrderItemUpdateManyWithoutOrderMealPackInput>;
  menuItem?: Maybe<KitchenMenuItemUpdateOneRequiredWithoutOrderItemsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<OrderUpdateOneRequiredWithoutItemsInput>;
  orderMealPack?: Maybe<OrderItemUpdateOneWithoutMealPackOrderItemsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  quantity?: Maybe<FloatFieldUpdateOperationsInput>;
  type?: Maybe<EnumOrderItemTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderItemUpdateWithoutMealPackOrderItemsInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  extraItems?: Maybe<OrderItemExtraUpdateManyWithoutOrderItemInput>;
  facilityItem?: Maybe<FacilityItemUpdateManyWithoutOrderItemInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  menuItem?: Maybe<KitchenMenuItemUpdateOneRequiredWithoutOrderItemsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<OrderUpdateOneRequiredWithoutItemsInput>;
  orderMealPack?: Maybe<OrderItemUpdateOneWithoutMealPackOrderItemsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  quantity?: Maybe<FloatFieldUpdateOperationsInput>;
  type?: Maybe<EnumOrderItemTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderItemUpdateWithoutMenuItemInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  extraItems?: Maybe<OrderItemExtraUpdateManyWithoutOrderItemInput>;
  facilityItem?: Maybe<FacilityItemUpdateManyWithoutOrderItemInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  mealPackOrderItems?: Maybe<OrderItemUpdateManyWithoutOrderMealPackInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<OrderUpdateOneRequiredWithoutItemsInput>;
  orderMealPack?: Maybe<OrderItemUpdateOneWithoutMealPackOrderItemsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  quantity?: Maybe<FloatFieldUpdateOperationsInput>;
  type?: Maybe<EnumOrderItemTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderItemUpdateWithoutOrderInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  extraItems?: Maybe<OrderItemExtraUpdateManyWithoutOrderItemInput>;
  facilityItem?: Maybe<FacilityItemUpdateManyWithoutOrderItemInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  mealPackOrderItems?: Maybe<OrderItemUpdateManyWithoutOrderMealPackInput>;
  menuItem?: Maybe<KitchenMenuItemUpdateOneRequiredWithoutOrderItemsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  orderMealPack?: Maybe<OrderItemUpdateOneWithoutMealPackOrderItemsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  quantity?: Maybe<FloatFieldUpdateOperationsInput>;
  type?: Maybe<EnumOrderItemTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderItemUpdateWithoutOrderMealPackInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  extraItems?: Maybe<OrderItemExtraUpdateManyWithoutOrderItemInput>;
  facilityItem?: Maybe<FacilityItemUpdateManyWithoutOrderItemInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  mealPackOrderItems?: Maybe<OrderItemUpdateManyWithoutOrderMealPackInput>;
  menuItem?: Maybe<KitchenMenuItemUpdateOneRequiredWithoutOrderItemsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<OrderUpdateOneRequiredWithoutItemsInput>;
  price?: Maybe<FloatFieldUpdateOperationsInput>;
  quantity?: Maybe<FloatFieldUpdateOperationsInput>;
  type?: Maybe<EnumOrderItemTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderItemUpsertWithWhereUniqueWithoutMenuItemInput = {
  create: OrderItemCreateWithoutMenuItemInput;
  update: OrderItemUpdateWithoutMenuItemInput;
  where: OrderItemWhereUniqueInput;
};

export type OrderItemUpsertWithWhereUniqueWithoutOrderInput = {
  create: OrderItemCreateWithoutOrderInput;
  update: OrderItemUpdateWithoutOrderInput;
  where: OrderItemWhereUniqueInput;
};

export type OrderItemUpsertWithWhereUniqueWithoutOrderMealPackInput = {
  create: OrderItemCreateWithoutOrderMealPackInput;
  update: OrderItemUpdateWithoutOrderMealPackInput;
  where: OrderItemWhereUniqueInput;
};

export type OrderItemUpsertWithoutExtraItemsInput = {
  create: OrderItemCreateWithoutExtraItemsInput;
  update: OrderItemUpdateWithoutExtraItemsInput;
};

export type OrderItemUpsertWithoutFacilityItemInput = {
  create: OrderItemCreateWithoutFacilityItemInput;
  update: OrderItemUpdateWithoutFacilityItemInput;
};

export type OrderItemUpsertWithoutMealPackOrderItemsInput = {
  create: OrderItemCreateWithoutMealPackOrderItemsInput;
  update: OrderItemUpdateWithoutMealPackOrderItemsInput;
};

export type OrderItemWhereInput = {
  AND?: Maybe<Array<OrderItemWhereInput>>;
  NOT?: Maybe<Array<OrderItemWhereInput>>;
  OR?: Maybe<Array<OrderItemWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  extraItems?: Maybe<OrderItemExtraListRelationFilter>;
  facilityItem?: Maybe<FacilityItemListRelationFilter>;
  id?: Maybe<StringFilter>;
  mealPackOrderItems?: Maybe<OrderItemListRelationFilter>;
  menuItem?: Maybe<KitchenMenuItemWhereInput>;
  menuItemId?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  order?: Maybe<OrderWhereInput>;
  orderId?: Maybe<StringFilter>;
  orderMealPack?: Maybe<OrderItemWhereInput>;
  orderMealPackId?: Maybe<StringNullableFilter>;
  price?: Maybe<FloatFilter>;
  quantity?: Maybe<FloatFilter>;
  type?: Maybe<EnumOrderItemTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type OrderItemWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type OrderListRelationFilter = {
  every?: Maybe<OrderWhereInput>;
  none?: Maybe<OrderWhereInput>;
  some?: Maybe<OrderWhereInput>;
};

export enum OrderMethod {
  Delivery = 'DELIVERY',
  PickUp = 'PICK_UP'
}

export type OrderMethodOverride = {
  __typename?: 'OrderMethodOverride';
  disabledOrderMethods: Array<OrderMethod>;
  end: Scalars['DateTime'];
  id: Scalars['String'];
  isActive: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  repeatOnDays: Array<Scalars['Int']>;
  start: Scalars['DateTime'];
};

export type OrderMethodOverrideCreateManyFacilityInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  disabledOrderMethods?: Maybe<OrderMethodOverrideCreateManydisabledOrderMethodsInput>;
  end: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  repeatOnDays?: Maybe<OrderMethodOverrideCreateManyrepeatOnDaysInput>;
  start: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderMethodOverrideCreateManyFacilityInputEnvelope = {
  data?: Maybe<Array<OrderMethodOverrideCreateManyFacilityInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OrderMethodOverrideCreateManydisabledOrderMethodsInput = {
  set?: Maybe<Array<OrderMethod>>;
};

export type OrderMethodOverrideCreateManyrepeatOnDaysInput = {
  set?: Maybe<Array<Scalars['Int']>>;
};

export type OrderMethodOverrideCreateNestedManyWithoutFacilityInput = {
  connect?: Maybe<Array<OrderMethodOverrideWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrderMethodOverrideCreateOrConnectWithoutFacilityInput>>;
  create?: Maybe<Array<OrderMethodOverrideCreateWithoutFacilityInput>>;
  createMany?: Maybe<OrderMethodOverrideCreateManyFacilityInputEnvelope>;
};

export type OrderMethodOverrideCreateOrConnectWithoutFacilityInput = {
  create: OrderMethodOverrideCreateWithoutFacilityInput;
  where: OrderMethodOverrideWhereUniqueInput;
};

export type OrderMethodOverrideCreateWithoutFacilityInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  disabledOrderMethods?: Maybe<OrderMethodOverrideCreatedisabledOrderMethodsInput>;
  end: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  repeatOnDays?: Maybe<OrderMethodOverrideCreaterepeatOnDaysInput>;
  start: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderMethodOverrideCreatedisabledOrderMethodsInput = {
  set?: Maybe<Array<OrderMethod>>;
};

export type OrderMethodOverrideCreaterepeatOnDaysInput = {
  set?: Maybe<Array<Scalars['Int']>>;
};

export enum OrderMethodOverrideErrors {
  Overlap = 'OVERLAP',
  WrongDatetime = 'WRONG_DATETIME'
}

export type OrderMethodOverrideListRelationFilter = {
  every?: Maybe<OrderMethodOverrideWhereInput>;
  none?: Maybe<OrderMethodOverrideWhereInput>;
  some?: Maybe<OrderMethodOverrideWhereInput>;
};

export type OrderMethodOverrideScalarWhereInput = {
  AND?: Maybe<Array<OrderMethodOverrideScalarWhereInput>>;
  NOT?: Maybe<Array<OrderMethodOverrideScalarWhereInput>>;
  OR?: Maybe<Array<OrderMethodOverrideScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  disabledOrderMethods?: Maybe<EnumOrderMethodNullableListFilter>;
  end?: Maybe<DateTimeFilter>;
  facilityId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  isActive?: Maybe<BoolFilter>;
  name?: Maybe<StringNullableFilter>;
  repeatOnDays?: Maybe<IntNullableListFilter>;
  start?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type OrderMethodOverrideUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  disabledOrderMethods?: Maybe<OrderMethodOverrideUpdatedisabledOrderMethodsInput>;
  end?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isActive?: Maybe<BoolFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  repeatOnDays?: Maybe<OrderMethodOverrideUpdaterepeatOnDaysInput>;
  start?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderMethodOverrideUpdateManyWithWhereWithoutFacilityInput = {
  data: OrderMethodOverrideUpdateManyMutationInput;
  where: OrderMethodOverrideScalarWhereInput;
};

export type OrderMethodOverrideUpdateManyWithoutFacilityInput = {
  connect?: Maybe<Array<OrderMethodOverrideWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrderMethodOverrideCreateOrConnectWithoutFacilityInput>>;
  create?: Maybe<Array<OrderMethodOverrideCreateWithoutFacilityInput>>;
  createMany?: Maybe<OrderMethodOverrideCreateManyFacilityInputEnvelope>;
  delete?: Maybe<Array<OrderMethodOverrideWhereUniqueInput>>;
  deleteMany?: Maybe<Array<OrderMethodOverrideScalarWhereInput>>;
  disconnect?: Maybe<Array<OrderMethodOverrideWhereUniqueInput>>;
  set?: Maybe<Array<OrderMethodOverrideWhereUniqueInput>>;
  update?: Maybe<Array<OrderMethodOverrideUpdateWithWhereUniqueWithoutFacilityInput>>;
  updateMany?: Maybe<Array<OrderMethodOverrideUpdateManyWithWhereWithoutFacilityInput>>;
  upsert?: Maybe<Array<OrderMethodOverrideUpsertWithWhereUniqueWithoutFacilityInput>>;
};

export type OrderMethodOverrideUpdateWithWhereUniqueWithoutFacilityInput = {
  data: OrderMethodOverrideUpdateWithoutFacilityInput;
  where: OrderMethodOverrideWhereUniqueInput;
};

export type OrderMethodOverrideUpdateWithoutFacilityInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  disabledOrderMethods?: Maybe<OrderMethodOverrideUpdatedisabledOrderMethodsInput>;
  end?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isActive?: Maybe<BoolFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  repeatOnDays?: Maybe<OrderMethodOverrideUpdaterepeatOnDaysInput>;
  start?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderMethodOverrideUpdatedisabledOrderMethodsInput = {
  push?: Maybe<OrderMethod>;
  set?: Maybe<Array<OrderMethod>>;
};

export type OrderMethodOverrideUpdaterepeatOnDaysInput = {
  push?: Maybe<Scalars['Int']>;
  set?: Maybe<Array<Scalars['Int']>>;
};

export type OrderMethodOverrideUpsertInput = {
  disabledOrderMethods: Array<OrderMethod>;
  /** Contains both end day (Date) and hours (Time) */
  end: Scalars['DateTime'];
  id?: Maybe<Scalars['ID']>;
  isActive: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  /** [0 - Sun, 1 - Mon, 2 - Tue, 3 - Wen, 4 - Thu, 5 - Fri, 6 - Sat] (based on the https://www.w3schools.com/jsref/jsref_getday.asp) */
  repeatOnDays: Array<Scalars['Int']>;
  /** Contains both start day (Date) and hours (Time) */
  start: Scalars['DateTime'];
};

export type OrderMethodOverrideUpsertWithWhereUniqueWithoutFacilityInput = {
  create: OrderMethodOverrideCreateWithoutFacilityInput;
  update: OrderMethodOverrideUpdateWithoutFacilityInput;
  where: OrderMethodOverrideWhereUniqueInput;
};

export type OrderMethodOverrideWhereInput = {
  AND?: Maybe<Array<OrderMethodOverrideWhereInput>>;
  NOT?: Maybe<Array<OrderMethodOverrideWhereInput>>;
  OR?: Maybe<Array<OrderMethodOverrideWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  disabledOrderMethods?: Maybe<EnumOrderMethodNullableListFilter>;
  end?: Maybe<DateTimeFilter>;
  facility?: Maybe<FacilityWhereInput>;
  facilityId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  isActive?: Maybe<BoolFilter>;
  name?: Maybe<StringNullableFilter>;
  repeatOnDays?: Maybe<IntNullableListFilter>;
  start?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type OrderMethodOverrideWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type OrderOverwrapsInput = {
  orderId: Scalars['ID'];
  overwraps: Scalars['Int'];
};

export type OrderScalarWhereInput = {
  AND?: Maybe<Array<OrderScalarWhereInput>>;
  NOT?: Maybe<Array<OrderScalarWhereInput>>;
  OR?: Maybe<Array<OrderScalarWhereInput>>;
  addressNote?: Maybe<StringNullableFilter>;
  bundleId?: Maybe<StringNullableFilter>;
  canceledAt?: Maybe<DateTimeNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  customerId?: Maybe<StringFilter>;
  deliverToEmail?: Maybe<StringNullableFilter>;
  deliverToName?: Maybe<StringNullableFilter>;
  deliverToPhone?: Maybe<StringNullableFilter>;
  deliveredAt?: Maybe<DateTimeNullableFilter>;
  delivery?: Maybe<FloatFilter>;
  deliveryEstimation?: Maybe<DateTimeNullableFilter>;
  deliveryOption?: Maybe<EnumDeliveryOptionNullableFilter>;
  deliveryTimeInSeconds?: Maybe<IntNullableFilter>;
  expeditedAt?: Maybe<DateTimeNullableFilter>;
  expoId?: Maybe<StringNullableFilter>;
  facilityId?: Maybe<StringFilter>;
  fee?: Maybe<FloatNullableFilter>;
  formattedAddress?: Maybe<StringNullableFilter>;
  fulfillmentAt?: Maybe<DateTimeFilter>;
  fulfillmentBy?: Maybe<DateTimeFilter>;
  giftCardCodes?: Maybe<StringNullableListFilter>;
  giftCardDiscounts?: Maybe<FloatNullableListFilter>;
  giftCardsDiscount?: Maybe<FloatFilter>;
  id?: Maybe<StringFilter>;
  isEmployee?: Maybe<BoolFilter>;
  isOrderNow?: Maybe<BoolFilter>;
  isPaidByInvoice?: Maybe<BoolFilter>;
  isVip?: Maybe<BoolFilter>;
  kdsType?: Maybe<EnumKdsTypeFilter>;
  kitchenPreparingUntil?: Maybe<DateTimeNullableFilter>;
  lat?: Maybe<FloatNullableFilter>;
  lng?: Maybe<FloatNullableFilter>;
  locality?: Maybe<StringNullableFilter>;
  method?: Maybe<EnumOrderMethodFilter>;
  note?: Maybe<StringNullableFilter>;
  numberOfGuests?: Maybe<IntNullableFilter>;
  orderName?: Maybe<StringNullableFilter>;
  overwraps?: Maybe<IntNullableFilter>;
  packagerId?: Maybe<StringNullableFilter>;
  packedAt?: Maybe<DateTimeNullableFilter>;
  payedAt?: Maybe<DateTimeNullableFilter>;
  pickupId?: Maybe<StringNullableFilter>;
  placeId?: Maybe<StringNullableFilter>;
  platform?: Maybe<EnumPlatformFilter>;
  postalCode?: Maybe<StringNullableFilter>;
  preparingUntil?: Maybe<DateTimeNullableFilter>;
  promoCodes?: Maybe<StringNullableListFilter>;
  promoDiscount?: Maybe<FloatFilter>;
  refireDelay?: Maybe<IntFilter>;
  refired?: Maybe<BoolNullableFilter>;
  region?: Maybe<StringNullableFilter>;
  runnerId?: Maybe<StringNullableFilter>;
  sequentialNumber?: Maybe<IntFilter>;
  shortId?: Maybe<StringFilter>;
  source?: Maybe<StringNullableFilter>;
  status?: Maybe<EnumOrderStatusFilter>;
  street?: Maybe<StringNullableFilter>;
  submittedAt?: Maybe<DateTimeFilter>;
  subtotal?: Maybe<FloatFilter>;
  tax?: Maybe<FloatNullableFilter>;
  taxFees?: Maybe<FloatFilter>;
  tip?: Maybe<FloatFilter>;
  tookanTaskId?: Maybe<StringNullableFilter>;
  total?: Maybe<FloatFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  utensilsCount?: Maybe<IntFilter>;
};

export type OrderStatistics = {
  __typename?: 'OrderStatistics';
  expo: Scalars['Int'];
  packaging: Scalars['Int'];
};

export enum OrderStatus {
  Arrived = 'ARRIVED',
  Assigning = 'ASSIGNING',
  Canceled = 'CANCELED',
  Complete = 'COMPLETE',
  Delivery = 'DELIVERY',
  ExpoUndone = 'EXPO_UNDONE',
  New = 'NEW',
  Packing = 'PACKING',
  Preparing = 'PREPARING',
  QaPass = 'QA_PASS',
  Ready = 'READY',
  Runner = 'RUNNER'
}

export type OrderStatusInput = {
  orderId: Scalars['ID'];
  status: OrderStatus;
};

export type OrderSubscription = {
  __typename?: 'OrderSubscription';
  data: Order;
  operation: SubscriptionOperation;
};

export type OrderUpdateManyMutationInput = {
  addressNote?: Maybe<NullableStringFieldUpdateOperationsInput>;
  canceledAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deliverToEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliverToName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliverToPhone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliveredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  delivery?: Maybe<FloatFieldUpdateOperationsInput>;
  deliveryEstimation?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  deliveryOption?: Maybe<NullableEnumDeliveryOptionFieldUpdateOperationsInput>;
  deliveryTimeInSeconds?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expeditedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  fee?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  formattedAddress?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fulfillmentAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  fulfillmentBy?: Maybe<DateTimeFieldUpdateOperationsInput>;
  giftCardCodes?: Maybe<OrderUpdategiftCardCodesInput>;
  giftCardDiscounts?: Maybe<OrderUpdategiftCardDiscountsInput>;
  giftCardsDiscount?: Maybe<FloatFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isEmployee?: Maybe<BoolFieldUpdateOperationsInput>;
  isOrderNow?: Maybe<BoolFieldUpdateOperationsInput>;
  isPaidByInvoice?: Maybe<BoolFieldUpdateOperationsInput>;
  isVip?: Maybe<BoolFieldUpdateOperationsInput>;
  kdsType?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  kitchenPreparingUntil?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  lat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  lng?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  locality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  method?: Maybe<EnumOrderMethodFieldUpdateOperationsInput>;
  note?: Maybe<NullableStringFieldUpdateOperationsInput>;
  numberOfGuests?: Maybe<NullableIntFieldUpdateOperationsInput>;
  orderName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  overwraps?: Maybe<NullableIntFieldUpdateOperationsInput>;
  packedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  payedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  placeId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  platform?: Maybe<EnumPlatformFieldUpdateOperationsInput>;
  postalCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  preparingUntil?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  promoCodes?: Maybe<OrderUpdatepromoCodesInput>;
  promoDiscount?: Maybe<FloatFieldUpdateOperationsInput>;
  refireDelay?: Maybe<IntFieldUpdateOperationsInput>;
  refired?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  region?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sequentialNumber?: Maybe<IntFieldUpdateOperationsInput>;
  shortId?: Maybe<StringFieldUpdateOperationsInput>;
  source?: Maybe<NullableStringFieldUpdateOperationsInput>;
  status?: Maybe<EnumOrderStatusFieldUpdateOperationsInput>;
  street?: Maybe<NullableStringFieldUpdateOperationsInput>;
  submittedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  subtotal?: Maybe<FloatFieldUpdateOperationsInput>;
  tax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  taxFees?: Maybe<FloatFieldUpdateOperationsInput>;
  tip?: Maybe<FloatFieldUpdateOperationsInput>;
  tookanTaskId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  total?: Maybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  utensilsCount?: Maybe<IntFieldUpdateOperationsInput>;
};

export type OrderUpdateManyWithWhereWithoutCustomerInput = {
  data: OrderUpdateManyMutationInput;
  where: OrderScalarWhereInput;
};

export type OrderUpdateManyWithWhereWithoutExpoInput = {
  data: OrderUpdateManyMutationInput;
  where: OrderScalarWhereInput;
};

export type OrderUpdateManyWithWhereWithoutFacilityInput = {
  data: OrderUpdateManyMutationInput;
  where: OrderScalarWhereInput;
};

export type OrderUpdateManyWithWhereWithoutPackagerInput = {
  data: OrderUpdateManyMutationInput;
  where: OrderScalarWhereInput;
};

export type OrderUpdateManyWithWhereWithoutPickupInput = {
  data: OrderUpdateManyMutationInput;
  where: OrderScalarWhereInput;
};

export type OrderUpdateManyWithWhereWithoutPromosInput = {
  data: OrderUpdateManyMutationInput;
  where: OrderScalarWhereInput;
};

export type OrderUpdateManyWithWhereWithoutRunnerInput = {
  data: OrderUpdateManyMutationInput;
  where: OrderScalarWhereInput;
};

export type OrderUpdateManyWithWhereWithoutTagsInput = {
  data: OrderUpdateManyMutationInput;
  where: OrderScalarWhereInput;
};

export type OrderUpdateManyWithoutCustomerInput = {
  connect?: Maybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrderCreateOrConnectWithoutCustomerInput>>;
  create?: Maybe<Array<OrderCreateWithoutCustomerInput>>;
  createMany?: Maybe<OrderCreateManyCustomerInputEnvelope>;
  delete?: Maybe<Array<OrderWhereUniqueInput>>;
  deleteMany?: Maybe<Array<OrderScalarWhereInput>>;
  disconnect?: Maybe<Array<OrderWhereUniqueInput>>;
  set?: Maybe<Array<OrderWhereUniqueInput>>;
  update?: Maybe<Array<OrderUpdateWithWhereUniqueWithoutCustomerInput>>;
  updateMany?: Maybe<Array<OrderUpdateManyWithWhereWithoutCustomerInput>>;
  upsert?: Maybe<Array<OrderUpsertWithWhereUniqueWithoutCustomerInput>>;
};

export type OrderUpdateManyWithoutExpoInput = {
  connect?: Maybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrderCreateOrConnectWithoutExpoInput>>;
  create?: Maybe<Array<OrderCreateWithoutExpoInput>>;
  createMany?: Maybe<OrderCreateManyExpoInputEnvelope>;
  delete?: Maybe<Array<OrderWhereUniqueInput>>;
  deleteMany?: Maybe<Array<OrderScalarWhereInput>>;
  disconnect?: Maybe<Array<OrderWhereUniqueInput>>;
  set?: Maybe<Array<OrderWhereUniqueInput>>;
  update?: Maybe<Array<OrderUpdateWithWhereUniqueWithoutExpoInput>>;
  updateMany?: Maybe<Array<OrderUpdateManyWithWhereWithoutExpoInput>>;
  upsert?: Maybe<Array<OrderUpsertWithWhereUniqueWithoutExpoInput>>;
};

export type OrderUpdateManyWithoutFacilityInput = {
  connect?: Maybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrderCreateOrConnectWithoutFacilityInput>>;
  create?: Maybe<Array<OrderCreateWithoutFacilityInput>>;
  createMany?: Maybe<OrderCreateManyFacilityInputEnvelope>;
  delete?: Maybe<Array<OrderWhereUniqueInput>>;
  deleteMany?: Maybe<Array<OrderScalarWhereInput>>;
  disconnect?: Maybe<Array<OrderWhereUniqueInput>>;
  set?: Maybe<Array<OrderWhereUniqueInput>>;
  update?: Maybe<Array<OrderUpdateWithWhereUniqueWithoutFacilityInput>>;
  updateMany?: Maybe<Array<OrderUpdateManyWithWhereWithoutFacilityInput>>;
  upsert?: Maybe<Array<OrderUpsertWithWhereUniqueWithoutFacilityInput>>;
};

export type OrderUpdateManyWithoutPackagerInput = {
  connect?: Maybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrderCreateOrConnectWithoutPackagerInput>>;
  create?: Maybe<Array<OrderCreateWithoutPackagerInput>>;
  createMany?: Maybe<OrderCreateManyPackagerInputEnvelope>;
  delete?: Maybe<Array<OrderWhereUniqueInput>>;
  deleteMany?: Maybe<Array<OrderScalarWhereInput>>;
  disconnect?: Maybe<Array<OrderWhereUniqueInput>>;
  set?: Maybe<Array<OrderWhereUniqueInput>>;
  update?: Maybe<Array<OrderUpdateWithWhereUniqueWithoutPackagerInput>>;
  updateMany?: Maybe<Array<OrderUpdateManyWithWhereWithoutPackagerInput>>;
  upsert?: Maybe<Array<OrderUpsertWithWhereUniqueWithoutPackagerInput>>;
};

export type OrderUpdateManyWithoutPickupInput = {
  connect?: Maybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrderCreateOrConnectWithoutPickupInput>>;
  create?: Maybe<Array<OrderCreateWithoutPickupInput>>;
  createMany?: Maybe<OrderCreateManyPickupInputEnvelope>;
  delete?: Maybe<Array<OrderWhereUniqueInput>>;
  deleteMany?: Maybe<Array<OrderScalarWhereInput>>;
  disconnect?: Maybe<Array<OrderWhereUniqueInput>>;
  set?: Maybe<Array<OrderWhereUniqueInput>>;
  update?: Maybe<Array<OrderUpdateWithWhereUniqueWithoutPickupInput>>;
  updateMany?: Maybe<Array<OrderUpdateManyWithWhereWithoutPickupInput>>;
  upsert?: Maybe<Array<OrderUpsertWithWhereUniqueWithoutPickupInput>>;
};

export type OrderUpdateManyWithoutPromosInput = {
  connect?: Maybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrderCreateOrConnectWithoutPromosInput>>;
  create?: Maybe<Array<OrderCreateWithoutPromosInput>>;
  delete?: Maybe<Array<OrderWhereUniqueInput>>;
  deleteMany?: Maybe<Array<OrderScalarWhereInput>>;
  disconnect?: Maybe<Array<OrderWhereUniqueInput>>;
  set?: Maybe<Array<OrderWhereUniqueInput>>;
  update?: Maybe<Array<OrderUpdateWithWhereUniqueWithoutPromosInput>>;
  updateMany?: Maybe<Array<OrderUpdateManyWithWhereWithoutPromosInput>>;
  upsert?: Maybe<Array<OrderUpsertWithWhereUniqueWithoutPromosInput>>;
};

export type OrderUpdateManyWithoutRunnerInput = {
  connect?: Maybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrderCreateOrConnectWithoutRunnerInput>>;
  create?: Maybe<Array<OrderCreateWithoutRunnerInput>>;
  createMany?: Maybe<OrderCreateManyRunnerInputEnvelope>;
  delete?: Maybe<Array<OrderWhereUniqueInput>>;
  deleteMany?: Maybe<Array<OrderScalarWhereInput>>;
  disconnect?: Maybe<Array<OrderWhereUniqueInput>>;
  set?: Maybe<Array<OrderWhereUniqueInput>>;
  update?: Maybe<Array<OrderUpdateWithWhereUniqueWithoutRunnerInput>>;
  updateMany?: Maybe<Array<OrderUpdateManyWithWhereWithoutRunnerInput>>;
  upsert?: Maybe<Array<OrderUpsertWithWhereUniqueWithoutRunnerInput>>;
};

export type OrderUpdateManyWithoutTagsInput = {
  connect?: Maybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrderCreateOrConnectWithoutTagsInput>>;
  create?: Maybe<Array<OrderCreateWithoutTagsInput>>;
  delete?: Maybe<Array<OrderWhereUniqueInput>>;
  deleteMany?: Maybe<Array<OrderScalarWhereInput>>;
  disconnect?: Maybe<Array<OrderWhereUniqueInput>>;
  set?: Maybe<Array<OrderWhereUniqueInput>>;
  update?: Maybe<Array<OrderUpdateWithWhereUniqueWithoutTagsInput>>;
  updateMany?: Maybe<Array<OrderUpdateManyWithWhereWithoutTagsInput>>;
  upsert?: Maybe<Array<OrderUpsertWithWhereUniqueWithoutTagsInput>>;
};

export type OrderUpdateOneRequiredWithoutItemsInput = {
  connect?: Maybe<OrderWhereUniqueInput>;
  connectOrCreate?: Maybe<OrderCreateOrConnectWithoutItemsInput>;
  create?: Maybe<OrderCreateWithoutItemsInput>;
  update?: Maybe<OrderUpdateWithoutItemsInput>;
  upsert?: Maybe<OrderUpsertWithoutItemsInput>;
};

export type OrderUpdateOneRequiredWithoutKitchenTicketsInput = {
  connect?: Maybe<OrderWhereUniqueInput>;
  connectOrCreate?: Maybe<OrderCreateOrConnectWithoutKitchenTicketsInput>;
  create?: Maybe<OrderCreateWithoutKitchenTicketsInput>;
  update?: Maybe<OrderUpdateWithoutKitchenTicketsInput>;
  upsert?: Maybe<OrderUpsertWithoutKitchenTicketsInput>;
};

export type OrderUpdateWithWhereUniqueWithoutCustomerInput = {
  data: OrderUpdateWithoutCustomerInput;
  where: OrderWhereUniqueInput;
};

export type OrderUpdateWithWhereUniqueWithoutExpoInput = {
  data: OrderUpdateWithoutExpoInput;
  where: OrderWhereUniqueInput;
};

export type OrderUpdateWithWhereUniqueWithoutFacilityInput = {
  data: OrderUpdateWithoutFacilityInput;
  where: OrderWhereUniqueInput;
};

export type OrderUpdateWithWhereUniqueWithoutPackagerInput = {
  data: OrderUpdateWithoutPackagerInput;
  where: OrderWhereUniqueInput;
};

export type OrderUpdateWithWhereUniqueWithoutPickupInput = {
  data: OrderUpdateWithoutPickupInput;
  where: OrderWhereUniqueInput;
};

export type OrderUpdateWithWhereUniqueWithoutPromosInput = {
  data: OrderUpdateWithoutPromosInput;
  where: OrderWhereUniqueInput;
};

export type OrderUpdateWithWhereUniqueWithoutRunnerInput = {
  data: OrderUpdateWithoutRunnerInput;
  where: OrderWhereUniqueInput;
};

export type OrderUpdateWithWhereUniqueWithoutTagsInput = {
  data: OrderUpdateWithoutTagsInput;
  where: OrderWhereUniqueInput;
};

export type OrderUpdateWithoutCustomerInput = {
  addressNote?: Maybe<NullableStringFieldUpdateOperationsInput>;
  braintreeTransactions?: Maybe<BraintreeTransactionUpdateManyWithoutOrderInput>;
  bundle?: Maybe<OrderBundleUpdateOneWithoutOrdersInput>;
  canceledAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deliverToEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliverToName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliverToPhone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliveredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  delivery?: Maybe<FloatFieldUpdateOperationsInput>;
  deliveryEstimation?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  deliveryOption?: Maybe<NullableEnumDeliveryOptionFieldUpdateOperationsInput>;
  deliveryTimeInSeconds?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expeditedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  expo?: Maybe<WorkplaceUpdateOneWithoutExpoInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutOrdersInput>;
  fee?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  formattedAddress?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fulfillmentAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  fulfillmentBy?: Maybe<DateTimeFieldUpdateOperationsInput>;
  giftCardCodes?: Maybe<OrderUpdategiftCardCodesInput>;
  giftCardDiscounts?: Maybe<OrderUpdategiftCardDiscountsInput>;
  giftCardsDiscount?: Maybe<FloatFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isEmployee?: Maybe<BoolFieldUpdateOperationsInput>;
  isOrderNow?: Maybe<BoolFieldUpdateOperationsInput>;
  isPaidByInvoice?: Maybe<BoolFieldUpdateOperationsInput>;
  isVip?: Maybe<BoolFieldUpdateOperationsInput>;
  items?: Maybe<OrderItemUpdateManyWithoutOrderInput>;
  kdsType?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  kitchenPreparingUntil?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  kitchenTickets?: Maybe<KitchenTicketUpdateManyWithoutOrderInput>;
  lat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  lng?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  locality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  method?: Maybe<EnumOrderMethodFieldUpdateOperationsInput>;
  note?: Maybe<NullableStringFieldUpdateOperationsInput>;
  numberOfGuests?: Maybe<NullableIntFieldUpdateOperationsInput>;
  orderEvents?: Maybe<OrderEventUpdateManyWithoutOrderInput>;
  orderName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  overwraps?: Maybe<NullableIntFieldUpdateOperationsInput>;
  packager?: Maybe<WorkplaceUpdateOneWithoutPackagerInput>;
  packedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  payedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  pickup?: Maybe<WorkplaceUpdateOneWithoutPickupInput>;
  placeId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  platform?: Maybe<EnumPlatformFieldUpdateOperationsInput>;
  postalCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  preparingUntil?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  promoCodes?: Maybe<OrderUpdatepromoCodesInput>;
  promoDiscount?: Maybe<FloatFieldUpdateOperationsInput>;
  promos?: Maybe<PromoUpdateManyWithoutOrdersInput>;
  refireDelay?: Maybe<IntFieldUpdateOperationsInput>;
  refired?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  region?: Maybe<NullableStringFieldUpdateOperationsInput>;
  runner?: Maybe<WorkplaceUpdateOneWithoutRunnerInput>;
  sequentialNumber?: Maybe<IntFieldUpdateOperationsInput>;
  shortId?: Maybe<StringFieldUpdateOperationsInput>;
  source?: Maybe<NullableStringFieldUpdateOperationsInput>;
  status?: Maybe<EnumOrderStatusFieldUpdateOperationsInput>;
  street?: Maybe<NullableStringFieldUpdateOperationsInput>;
  submittedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  subtotal?: Maybe<FloatFieldUpdateOperationsInput>;
  tags?: Maybe<TagUpdateManyWithoutOrdersInput>;
  tax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  taxFees?: Maybe<FloatFieldUpdateOperationsInput>;
  tip?: Maybe<FloatFieldUpdateOperationsInput>;
  tookanTaskId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  total?: Maybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  utensilsCount?: Maybe<IntFieldUpdateOperationsInput>;
};

export type OrderUpdateWithoutExpoInput = {
  addressNote?: Maybe<NullableStringFieldUpdateOperationsInput>;
  braintreeTransactions?: Maybe<BraintreeTransactionUpdateManyWithoutOrderInput>;
  bundle?: Maybe<OrderBundleUpdateOneWithoutOrdersInput>;
  canceledAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customer?: Maybe<UserCustomerInfoUpdateOneRequiredWithoutOrdersInput>;
  deliverToEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliverToName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliverToPhone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliveredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  delivery?: Maybe<FloatFieldUpdateOperationsInput>;
  deliveryEstimation?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  deliveryOption?: Maybe<NullableEnumDeliveryOptionFieldUpdateOperationsInput>;
  deliveryTimeInSeconds?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expeditedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutOrdersInput>;
  fee?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  formattedAddress?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fulfillmentAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  fulfillmentBy?: Maybe<DateTimeFieldUpdateOperationsInput>;
  giftCardCodes?: Maybe<OrderUpdategiftCardCodesInput>;
  giftCardDiscounts?: Maybe<OrderUpdategiftCardDiscountsInput>;
  giftCardsDiscount?: Maybe<FloatFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isEmployee?: Maybe<BoolFieldUpdateOperationsInput>;
  isOrderNow?: Maybe<BoolFieldUpdateOperationsInput>;
  isPaidByInvoice?: Maybe<BoolFieldUpdateOperationsInput>;
  isVip?: Maybe<BoolFieldUpdateOperationsInput>;
  items?: Maybe<OrderItemUpdateManyWithoutOrderInput>;
  kdsType?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  kitchenPreparingUntil?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  kitchenTickets?: Maybe<KitchenTicketUpdateManyWithoutOrderInput>;
  lat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  lng?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  locality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  method?: Maybe<EnumOrderMethodFieldUpdateOperationsInput>;
  note?: Maybe<NullableStringFieldUpdateOperationsInput>;
  numberOfGuests?: Maybe<NullableIntFieldUpdateOperationsInput>;
  orderEvents?: Maybe<OrderEventUpdateManyWithoutOrderInput>;
  orderName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  overwraps?: Maybe<NullableIntFieldUpdateOperationsInput>;
  packager?: Maybe<WorkplaceUpdateOneWithoutPackagerInput>;
  packedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  payedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  pickup?: Maybe<WorkplaceUpdateOneWithoutPickupInput>;
  placeId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  platform?: Maybe<EnumPlatformFieldUpdateOperationsInput>;
  postalCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  preparingUntil?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  promoCodes?: Maybe<OrderUpdatepromoCodesInput>;
  promoDiscount?: Maybe<FloatFieldUpdateOperationsInput>;
  promos?: Maybe<PromoUpdateManyWithoutOrdersInput>;
  refireDelay?: Maybe<IntFieldUpdateOperationsInput>;
  refired?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  region?: Maybe<NullableStringFieldUpdateOperationsInput>;
  runner?: Maybe<WorkplaceUpdateOneWithoutRunnerInput>;
  sequentialNumber?: Maybe<IntFieldUpdateOperationsInput>;
  shortId?: Maybe<StringFieldUpdateOperationsInput>;
  source?: Maybe<NullableStringFieldUpdateOperationsInput>;
  status?: Maybe<EnumOrderStatusFieldUpdateOperationsInput>;
  street?: Maybe<NullableStringFieldUpdateOperationsInput>;
  submittedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  subtotal?: Maybe<FloatFieldUpdateOperationsInput>;
  tags?: Maybe<TagUpdateManyWithoutOrdersInput>;
  tax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  taxFees?: Maybe<FloatFieldUpdateOperationsInput>;
  tip?: Maybe<FloatFieldUpdateOperationsInput>;
  tookanTaskId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  total?: Maybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  utensilsCount?: Maybe<IntFieldUpdateOperationsInput>;
};

export type OrderUpdateWithoutFacilityInput = {
  addressNote?: Maybe<NullableStringFieldUpdateOperationsInput>;
  braintreeTransactions?: Maybe<BraintreeTransactionUpdateManyWithoutOrderInput>;
  bundle?: Maybe<OrderBundleUpdateOneWithoutOrdersInput>;
  canceledAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customer?: Maybe<UserCustomerInfoUpdateOneRequiredWithoutOrdersInput>;
  deliverToEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliverToName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliverToPhone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliveredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  delivery?: Maybe<FloatFieldUpdateOperationsInput>;
  deliveryEstimation?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  deliveryOption?: Maybe<NullableEnumDeliveryOptionFieldUpdateOperationsInput>;
  deliveryTimeInSeconds?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expeditedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  expo?: Maybe<WorkplaceUpdateOneWithoutExpoInput>;
  fee?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  formattedAddress?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fulfillmentAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  fulfillmentBy?: Maybe<DateTimeFieldUpdateOperationsInput>;
  giftCardCodes?: Maybe<OrderUpdategiftCardCodesInput>;
  giftCardDiscounts?: Maybe<OrderUpdategiftCardDiscountsInput>;
  giftCardsDiscount?: Maybe<FloatFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isEmployee?: Maybe<BoolFieldUpdateOperationsInput>;
  isOrderNow?: Maybe<BoolFieldUpdateOperationsInput>;
  isPaidByInvoice?: Maybe<BoolFieldUpdateOperationsInput>;
  isVip?: Maybe<BoolFieldUpdateOperationsInput>;
  items?: Maybe<OrderItemUpdateManyWithoutOrderInput>;
  kdsType?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  kitchenPreparingUntil?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  kitchenTickets?: Maybe<KitchenTicketUpdateManyWithoutOrderInput>;
  lat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  lng?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  locality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  method?: Maybe<EnumOrderMethodFieldUpdateOperationsInput>;
  note?: Maybe<NullableStringFieldUpdateOperationsInput>;
  numberOfGuests?: Maybe<NullableIntFieldUpdateOperationsInput>;
  orderEvents?: Maybe<OrderEventUpdateManyWithoutOrderInput>;
  orderName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  overwraps?: Maybe<NullableIntFieldUpdateOperationsInput>;
  packager?: Maybe<WorkplaceUpdateOneWithoutPackagerInput>;
  packedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  payedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  pickup?: Maybe<WorkplaceUpdateOneWithoutPickupInput>;
  placeId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  platform?: Maybe<EnumPlatformFieldUpdateOperationsInput>;
  postalCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  preparingUntil?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  promoCodes?: Maybe<OrderUpdatepromoCodesInput>;
  promoDiscount?: Maybe<FloatFieldUpdateOperationsInput>;
  promos?: Maybe<PromoUpdateManyWithoutOrdersInput>;
  refireDelay?: Maybe<IntFieldUpdateOperationsInput>;
  refired?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  region?: Maybe<NullableStringFieldUpdateOperationsInput>;
  runner?: Maybe<WorkplaceUpdateOneWithoutRunnerInput>;
  sequentialNumber?: Maybe<IntFieldUpdateOperationsInput>;
  shortId?: Maybe<StringFieldUpdateOperationsInput>;
  source?: Maybe<NullableStringFieldUpdateOperationsInput>;
  status?: Maybe<EnumOrderStatusFieldUpdateOperationsInput>;
  street?: Maybe<NullableStringFieldUpdateOperationsInput>;
  submittedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  subtotal?: Maybe<FloatFieldUpdateOperationsInput>;
  tags?: Maybe<TagUpdateManyWithoutOrdersInput>;
  tax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  taxFees?: Maybe<FloatFieldUpdateOperationsInput>;
  tip?: Maybe<FloatFieldUpdateOperationsInput>;
  tookanTaskId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  total?: Maybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  utensilsCount?: Maybe<IntFieldUpdateOperationsInput>;
};

export type OrderUpdateWithoutItemsInput = {
  addressNote?: Maybe<NullableStringFieldUpdateOperationsInput>;
  braintreeTransactions?: Maybe<BraintreeTransactionUpdateManyWithoutOrderInput>;
  bundle?: Maybe<OrderBundleUpdateOneWithoutOrdersInput>;
  canceledAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customer?: Maybe<UserCustomerInfoUpdateOneRequiredWithoutOrdersInput>;
  deliverToEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliverToName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliverToPhone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliveredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  delivery?: Maybe<FloatFieldUpdateOperationsInput>;
  deliveryEstimation?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  deliveryOption?: Maybe<NullableEnumDeliveryOptionFieldUpdateOperationsInput>;
  deliveryTimeInSeconds?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expeditedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  expo?: Maybe<WorkplaceUpdateOneWithoutExpoInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutOrdersInput>;
  fee?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  formattedAddress?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fulfillmentAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  fulfillmentBy?: Maybe<DateTimeFieldUpdateOperationsInput>;
  giftCardCodes?: Maybe<OrderUpdategiftCardCodesInput>;
  giftCardDiscounts?: Maybe<OrderUpdategiftCardDiscountsInput>;
  giftCardsDiscount?: Maybe<FloatFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isEmployee?: Maybe<BoolFieldUpdateOperationsInput>;
  isOrderNow?: Maybe<BoolFieldUpdateOperationsInput>;
  isPaidByInvoice?: Maybe<BoolFieldUpdateOperationsInput>;
  isVip?: Maybe<BoolFieldUpdateOperationsInput>;
  kdsType?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  kitchenPreparingUntil?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  kitchenTickets?: Maybe<KitchenTicketUpdateManyWithoutOrderInput>;
  lat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  lng?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  locality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  method?: Maybe<EnumOrderMethodFieldUpdateOperationsInput>;
  note?: Maybe<NullableStringFieldUpdateOperationsInput>;
  numberOfGuests?: Maybe<NullableIntFieldUpdateOperationsInput>;
  orderEvents?: Maybe<OrderEventUpdateManyWithoutOrderInput>;
  orderName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  overwraps?: Maybe<NullableIntFieldUpdateOperationsInput>;
  packager?: Maybe<WorkplaceUpdateOneWithoutPackagerInput>;
  packedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  payedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  pickup?: Maybe<WorkplaceUpdateOneWithoutPickupInput>;
  placeId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  platform?: Maybe<EnumPlatformFieldUpdateOperationsInput>;
  postalCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  preparingUntil?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  promoCodes?: Maybe<OrderUpdatepromoCodesInput>;
  promoDiscount?: Maybe<FloatFieldUpdateOperationsInput>;
  promos?: Maybe<PromoUpdateManyWithoutOrdersInput>;
  refireDelay?: Maybe<IntFieldUpdateOperationsInput>;
  refired?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  region?: Maybe<NullableStringFieldUpdateOperationsInput>;
  runner?: Maybe<WorkplaceUpdateOneWithoutRunnerInput>;
  sequentialNumber?: Maybe<IntFieldUpdateOperationsInput>;
  shortId?: Maybe<StringFieldUpdateOperationsInput>;
  source?: Maybe<NullableStringFieldUpdateOperationsInput>;
  status?: Maybe<EnumOrderStatusFieldUpdateOperationsInput>;
  street?: Maybe<NullableStringFieldUpdateOperationsInput>;
  submittedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  subtotal?: Maybe<FloatFieldUpdateOperationsInput>;
  tags?: Maybe<TagUpdateManyWithoutOrdersInput>;
  tax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  taxFees?: Maybe<FloatFieldUpdateOperationsInput>;
  tip?: Maybe<FloatFieldUpdateOperationsInput>;
  tookanTaskId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  total?: Maybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  utensilsCount?: Maybe<IntFieldUpdateOperationsInput>;
};

export type OrderUpdateWithoutKitchenTicketsInput = {
  addressNote?: Maybe<NullableStringFieldUpdateOperationsInput>;
  braintreeTransactions?: Maybe<BraintreeTransactionUpdateManyWithoutOrderInput>;
  bundle?: Maybe<OrderBundleUpdateOneWithoutOrdersInput>;
  canceledAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customer?: Maybe<UserCustomerInfoUpdateOneRequiredWithoutOrdersInput>;
  deliverToEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliverToName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliverToPhone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliveredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  delivery?: Maybe<FloatFieldUpdateOperationsInput>;
  deliveryEstimation?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  deliveryOption?: Maybe<NullableEnumDeliveryOptionFieldUpdateOperationsInput>;
  deliveryTimeInSeconds?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expeditedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  expo?: Maybe<WorkplaceUpdateOneWithoutExpoInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutOrdersInput>;
  fee?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  formattedAddress?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fulfillmentAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  fulfillmentBy?: Maybe<DateTimeFieldUpdateOperationsInput>;
  giftCardCodes?: Maybe<OrderUpdategiftCardCodesInput>;
  giftCardDiscounts?: Maybe<OrderUpdategiftCardDiscountsInput>;
  giftCardsDiscount?: Maybe<FloatFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isEmployee?: Maybe<BoolFieldUpdateOperationsInput>;
  isOrderNow?: Maybe<BoolFieldUpdateOperationsInput>;
  isPaidByInvoice?: Maybe<BoolFieldUpdateOperationsInput>;
  isVip?: Maybe<BoolFieldUpdateOperationsInput>;
  items?: Maybe<OrderItemUpdateManyWithoutOrderInput>;
  kdsType?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  kitchenPreparingUntil?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  lat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  lng?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  locality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  method?: Maybe<EnumOrderMethodFieldUpdateOperationsInput>;
  note?: Maybe<NullableStringFieldUpdateOperationsInput>;
  numberOfGuests?: Maybe<NullableIntFieldUpdateOperationsInput>;
  orderEvents?: Maybe<OrderEventUpdateManyWithoutOrderInput>;
  orderName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  overwraps?: Maybe<NullableIntFieldUpdateOperationsInput>;
  packager?: Maybe<WorkplaceUpdateOneWithoutPackagerInput>;
  packedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  payedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  pickup?: Maybe<WorkplaceUpdateOneWithoutPickupInput>;
  placeId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  platform?: Maybe<EnumPlatformFieldUpdateOperationsInput>;
  postalCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  preparingUntil?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  promoCodes?: Maybe<OrderUpdatepromoCodesInput>;
  promoDiscount?: Maybe<FloatFieldUpdateOperationsInput>;
  promos?: Maybe<PromoUpdateManyWithoutOrdersInput>;
  refireDelay?: Maybe<IntFieldUpdateOperationsInput>;
  refired?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  region?: Maybe<NullableStringFieldUpdateOperationsInput>;
  runner?: Maybe<WorkplaceUpdateOneWithoutRunnerInput>;
  sequentialNumber?: Maybe<IntFieldUpdateOperationsInput>;
  shortId?: Maybe<StringFieldUpdateOperationsInput>;
  source?: Maybe<NullableStringFieldUpdateOperationsInput>;
  status?: Maybe<EnumOrderStatusFieldUpdateOperationsInput>;
  street?: Maybe<NullableStringFieldUpdateOperationsInput>;
  submittedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  subtotal?: Maybe<FloatFieldUpdateOperationsInput>;
  tags?: Maybe<TagUpdateManyWithoutOrdersInput>;
  tax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  taxFees?: Maybe<FloatFieldUpdateOperationsInput>;
  tip?: Maybe<FloatFieldUpdateOperationsInput>;
  tookanTaskId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  total?: Maybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  utensilsCount?: Maybe<IntFieldUpdateOperationsInput>;
};

export type OrderUpdateWithoutPackagerInput = {
  addressNote?: Maybe<NullableStringFieldUpdateOperationsInput>;
  braintreeTransactions?: Maybe<BraintreeTransactionUpdateManyWithoutOrderInput>;
  bundle?: Maybe<OrderBundleUpdateOneWithoutOrdersInput>;
  canceledAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customer?: Maybe<UserCustomerInfoUpdateOneRequiredWithoutOrdersInput>;
  deliverToEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliverToName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliverToPhone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliveredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  delivery?: Maybe<FloatFieldUpdateOperationsInput>;
  deliveryEstimation?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  deliveryOption?: Maybe<NullableEnumDeliveryOptionFieldUpdateOperationsInput>;
  deliveryTimeInSeconds?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expeditedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  expo?: Maybe<WorkplaceUpdateOneWithoutExpoInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutOrdersInput>;
  fee?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  formattedAddress?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fulfillmentAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  fulfillmentBy?: Maybe<DateTimeFieldUpdateOperationsInput>;
  giftCardCodes?: Maybe<OrderUpdategiftCardCodesInput>;
  giftCardDiscounts?: Maybe<OrderUpdategiftCardDiscountsInput>;
  giftCardsDiscount?: Maybe<FloatFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isEmployee?: Maybe<BoolFieldUpdateOperationsInput>;
  isOrderNow?: Maybe<BoolFieldUpdateOperationsInput>;
  isPaidByInvoice?: Maybe<BoolFieldUpdateOperationsInput>;
  isVip?: Maybe<BoolFieldUpdateOperationsInput>;
  items?: Maybe<OrderItemUpdateManyWithoutOrderInput>;
  kdsType?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  kitchenPreparingUntil?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  kitchenTickets?: Maybe<KitchenTicketUpdateManyWithoutOrderInput>;
  lat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  lng?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  locality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  method?: Maybe<EnumOrderMethodFieldUpdateOperationsInput>;
  note?: Maybe<NullableStringFieldUpdateOperationsInput>;
  numberOfGuests?: Maybe<NullableIntFieldUpdateOperationsInput>;
  orderEvents?: Maybe<OrderEventUpdateManyWithoutOrderInput>;
  orderName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  overwraps?: Maybe<NullableIntFieldUpdateOperationsInput>;
  packedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  payedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  pickup?: Maybe<WorkplaceUpdateOneWithoutPickupInput>;
  placeId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  platform?: Maybe<EnumPlatformFieldUpdateOperationsInput>;
  postalCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  preparingUntil?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  promoCodes?: Maybe<OrderUpdatepromoCodesInput>;
  promoDiscount?: Maybe<FloatFieldUpdateOperationsInput>;
  promos?: Maybe<PromoUpdateManyWithoutOrdersInput>;
  refireDelay?: Maybe<IntFieldUpdateOperationsInput>;
  refired?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  region?: Maybe<NullableStringFieldUpdateOperationsInput>;
  runner?: Maybe<WorkplaceUpdateOneWithoutRunnerInput>;
  sequentialNumber?: Maybe<IntFieldUpdateOperationsInput>;
  shortId?: Maybe<StringFieldUpdateOperationsInput>;
  source?: Maybe<NullableStringFieldUpdateOperationsInput>;
  status?: Maybe<EnumOrderStatusFieldUpdateOperationsInput>;
  street?: Maybe<NullableStringFieldUpdateOperationsInput>;
  submittedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  subtotal?: Maybe<FloatFieldUpdateOperationsInput>;
  tags?: Maybe<TagUpdateManyWithoutOrdersInput>;
  tax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  taxFees?: Maybe<FloatFieldUpdateOperationsInput>;
  tip?: Maybe<FloatFieldUpdateOperationsInput>;
  tookanTaskId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  total?: Maybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  utensilsCount?: Maybe<IntFieldUpdateOperationsInput>;
};

export type OrderUpdateWithoutPickupInput = {
  addressNote?: Maybe<NullableStringFieldUpdateOperationsInput>;
  braintreeTransactions?: Maybe<BraintreeTransactionUpdateManyWithoutOrderInput>;
  bundle?: Maybe<OrderBundleUpdateOneWithoutOrdersInput>;
  canceledAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customer?: Maybe<UserCustomerInfoUpdateOneRequiredWithoutOrdersInput>;
  deliverToEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliverToName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliverToPhone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliveredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  delivery?: Maybe<FloatFieldUpdateOperationsInput>;
  deliveryEstimation?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  deliveryOption?: Maybe<NullableEnumDeliveryOptionFieldUpdateOperationsInput>;
  deliveryTimeInSeconds?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expeditedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  expo?: Maybe<WorkplaceUpdateOneWithoutExpoInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutOrdersInput>;
  fee?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  formattedAddress?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fulfillmentAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  fulfillmentBy?: Maybe<DateTimeFieldUpdateOperationsInput>;
  giftCardCodes?: Maybe<OrderUpdategiftCardCodesInput>;
  giftCardDiscounts?: Maybe<OrderUpdategiftCardDiscountsInput>;
  giftCardsDiscount?: Maybe<FloatFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isEmployee?: Maybe<BoolFieldUpdateOperationsInput>;
  isOrderNow?: Maybe<BoolFieldUpdateOperationsInput>;
  isPaidByInvoice?: Maybe<BoolFieldUpdateOperationsInput>;
  isVip?: Maybe<BoolFieldUpdateOperationsInput>;
  items?: Maybe<OrderItemUpdateManyWithoutOrderInput>;
  kdsType?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  kitchenPreparingUntil?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  kitchenTickets?: Maybe<KitchenTicketUpdateManyWithoutOrderInput>;
  lat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  lng?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  locality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  method?: Maybe<EnumOrderMethodFieldUpdateOperationsInput>;
  note?: Maybe<NullableStringFieldUpdateOperationsInput>;
  numberOfGuests?: Maybe<NullableIntFieldUpdateOperationsInput>;
  orderEvents?: Maybe<OrderEventUpdateManyWithoutOrderInput>;
  orderName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  overwraps?: Maybe<NullableIntFieldUpdateOperationsInput>;
  packager?: Maybe<WorkplaceUpdateOneWithoutPackagerInput>;
  packedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  payedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  placeId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  platform?: Maybe<EnumPlatformFieldUpdateOperationsInput>;
  postalCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  preparingUntil?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  promoCodes?: Maybe<OrderUpdatepromoCodesInput>;
  promoDiscount?: Maybe<FloatFieldUpdateOperationsInput>;
  promos?: Maybe<PromoUpdateManyWithoutOrdersInput>;
  refireDelay?: Maybe<IntFieldUpdateOperationsInput>;
  refired?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  region?: Maybe<NullableStringFieldUpdateOperationsInput>;
  runner?: Maybe<WorkplaceUpdateOneWithoutRunnerInput>;
  sequentialNumber?: Maybe<IntFieldUpdateOperationsInput>;
  shortId?: Maybe<StringFieldUpdateOperationsInput>;
  source?: Maybe<NullableStringFieldUpdateOperationsInput>;
  status?: Maybe<EnumOrderStatusFieldUpdateOperationsInput>;
  street?: Maybe<NullableStringFieldUpdateOperationsInput>;
  submittedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  subtotal?: Maybe<FloatFieldUpdateOperationsInput>;
  tags?: Maybe<TagUpdateManyWithoutOrdersInput>;
  tax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  taxFees?: Maybe<FloatFieldUpdateOperationsInput>;
  tip?: Maybe<FloatFieldUpdateOperationsInput>;
  tookanTaskId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  total?: Maybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  utensilsCount?: Maybe<IntFieldUpdateOperationsInput>;
};

export type OrderUpdateWithoutPromosInput = {
  addressNote?: Maybe<NullableStringFieldUpdateOperationsInput>;
  braintreeTransactions?: Maybe<BraintreeTransactionUpdateManyWithoutOrderInput>;
  bundle?: Maybe<OrderBundleUpdateOneWithoutOrdersInput>;
  canceledAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customer?: Maybe<UserCustomerInfoUpdateOneRequiredWithoutOrdersInput>;
  deliverToEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliverToName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliverToPhone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliveredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  delivery?: Maybe<FloatFieldUpdateOperationsInput>;
  deliveryEstimation?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  deliveryOption?: Maybe<NullableEnumDeliveryOptionFieldUpdateOperationsInput>;
  deliveryTimeInSeconds?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expeditedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  expo?: Maybe<WorkplaceUpdateOneWithoutExpoInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutOrdersInput>;
  fee?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  formattedAddress?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fulfillmentAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  fulfillmentBy?: Maybe<DateTimeFieldUpdateOperationsInput>;
  giftCardCodes?: Maybe<OrderUpdategiftCardCodesInput>;
  giftCardDiscounts?: Maybe<OrderUpdategiftCardDiscountsInput>;
  giftCardsDiscount?: Maybe<FloatFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isEmployee?: Maybe<BoolFieldUpdateOperationsInput>;
  isOrderNow?: Maybe<BoolFieldUpdateOperationsInput>;
  isPaidByInvoice?: Maybe<BoolFieldUpdateOperationsInput>;
  isVip?: Maybe<BoolFieldUpdateOperationsInput>;
  items?: Maybe<OrderItemUpdateManyWithoutOrderInput>;
  kdsType?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  kitchenPreparingUntil?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  kitchenTickets?: Maybe<KitchenTicketUpdateManyWithoutOrderInput>;
  lat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  lng?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  locality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  method?: Maybe<EnumOrderMethodFieldUpdateOperationsInput>;
  note?: Maybe<NullableStringFieldUpdateOperationsInput>;
  numberOfGuests?: Maybe<NullableIntFieldUpdateOperationsInput>;
  orderEvents?: Maybe<OrderEventUpdateManyWithoutOrderInput>;
  orderName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  overwraps?: Maybe<NullableIntFieldUpdateOperationsInput>;
  packager?: Maybe<WorkplaceUpdateOneWithoutPackagerInput>;
  packedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  payedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  pickup?: Maybe<WorkplaceUpdateOneWithoutPickupInput>;
  placeId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  platform?: Maybe<EnumPlatformFieldUpdateOperationsInput>;
  postalCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  preparingUntil?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  promoCodes?: Maybe<OrderUpdatepromoCodesInput>;
  promoDiscount?: Maybe<FloatFieldUpdateOperationsInput>;
  refireDelay?: Maybe<IntFieldUpdateOperationsInput>;
  refired?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  region?: Maybe<NullableStringFieldUpdateOperationsInput>;
  runner?: Maybe<WorkplaceUpdateOneWithoutRunnerInput>;
  sequentialNumber?: Maybe<IntFieldUpdateOperationsInput>;
  shortId?: Maybe<StringFieldUpdateOperationsInput>;
  source?: Maybe<NullableStringFieldUpdateOperationsInput>;
  status?: Maybe<EnumOrderStatusFieldUpdateOperationsInput>;
  street?: Maybe<NullableStringFieldUpdateOperationsInput>;
  submittedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  subtotal?: Maybe<FloatFieldUpdateOperationsInput>;
  tags?: Maybe<TagUpdateManyWithoutOrdersInput>;
  tax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  taxFees?: Maybe<FloatFieldUpdateOperationsInput>;
  tip?: Maybe<FloatFieldUpdateOperationsInput>;
  tookanTaskId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  total?: Maybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  utensilsCount?: Maybe<IntFieldUpdateOperationsInput>;
};

export type OrderUpdateWithoutRunnerInput = {
  addressNote?: Maybe<NullableStringFieldUpdateOperationsInput>;
  braintreeTransactions?: Maybe<BraintreeTransactionUpdateManyWithoutOrderInput>;
  bundle?: Maybe<OrderBundleUpdateOneWithoutOrdersInput>;
  canceledAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customer?: Maybe<UserCustomerInfoUpdateOneRequiredWithoutOrdersInput>;
  deliverToEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliverToName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliverToPhone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliveredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  delivery?: Maybe<FloatFieldUpdateOperationsInput>;
  deliveryEstimation?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  deliveryOption?: Maybe<NullableEnumDeliveryOptionFieldUpdateOperationsInput>;
  deliveryTimeInSeconds?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expeditedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  expo?: Maybe<WorkplaceUpdateOneWithoutExpoInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutOrdersInput>;
  fee?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  formattedAddress?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fulfillmentAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  fulfillmentBy?: Maybe<DateTimeFieldUpdateOperationsInput>;
  giftCardCodes?: Maybe<OrderUpdategiftCardCodesInput>;
  giftCardDiscounts?: Maybe<OrderUpdategiftCardDiscountsInput>;
  giftCardsDiscount?: Maybe<FloatFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isEmployee?: Maybe<BoolFieldUpdateOperationsInput>;
  isOrderNow?: Maybe<BoolFieldUpdateOperationsInput>;
  isPaidByInvoice?: Maybe<BoolFieldUpdateOperationsInput>;
  isVip?: Maybe<BoolFieldUpdateOperationsInput>;
  items?: Maybe<OrderItemUpdateManyWithoutOrderInput>;
  kdsType?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  kitchenPreparingUntil?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  kitchenTickets?: Maybe<KitchenTicketUpdateManyWithoutOrderInput>;
  lat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  lng?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  locality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  method?: Maybe<EnumOrderMethodFieldUpdateOperationsInput>;
  note?: Maybe<NullableStringFieldUpdateOperationsInput>;
  numberOfGuests?: Maybe<NullableIntFieldUpdateOperationsInput>;
  orderEvents?: Maybe<OrderEventUpdateManyWithoutOrderInput>;
  orderName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  overwraps?: Maybe<NullableIntFieldUpdateOperationsInput>;
  packager?: Maybe<WorkplaceUpdateOneWithoutPackagerInput>;
  packedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  payedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  pickup?: Maybe<WorkplaceUpdateOneWithoutPickupInput>;
  placeId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  platform?: Maybe<EnumPlatformFieldUpdateOperationsInput>;
  postalCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  preparingUntil?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  promoCodes?: Maybe<OrderUpdatepromoCodesInput>;
  promoDiscount?: Maybe<FloatFieldUpdateOperationsInput>;
  promos?: Maybe<PromoUpdateManyWithoutOrdersInput>;
  refireDelay?: Maybe<IntFieldUpdateOperationsInput>;
  refired?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  region?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sequentialNumber?: Maybe<IntFieldUpdateOperationsInput>;
  shortId?: Maybe<StringFieldUpdateOperationsInput>;
  source?: Maybe<NullableStringFieldUpdateOperationsInput>;
  status?: Maybe<EnumOrderStatusFieldUpdateOperationsInput>;
  street?: Maybe<NullableStringFieldUpdateOperationsInput>;
  submittedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  subtotal?: Maybe<FloatFieldUpdateOperationsInput>;
  tags?: Maybe<TagUpdateManyWithoutOrdersInput>;
  tax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  taxFees?: Maybe<FloatFieldUpdateOperationsInput>;
  tip?: Maybe<FloatFieldUpdateOperationsInput>;
  tookanTaskId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  total?: Maybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  utensilsCount?: Maybe<IntFieldUpdateOperationsInput>;
};

export type OrderUpdateWithoutTagsInput = {
  addressNote?: Maybe<NullableStringFieldUpdateOperationsInput>;
  braintreeTransactions?: Maybe<BraintreeTransactionUpdateManyWithoutOrderInput>;
  bundle?: Maybe<OrderBundleUpdateOneWithoutOrdersInput>;
  canceledAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customer?: Maybe<UserCustomerInfoUpdateOneRequiredWithoutOrdersInput>;
  deliverToEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliverToName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliverToPhone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  deliveredAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  delivery?: Maybe<FloatFieldUpdateOperationsInput>;
  deliveryEstimation?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  deliveryOption?: Maybe<NullableEnumDeliveryOptionFieldUpdateOperationsInput>;
  deliveryTimeInSeconds?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expeditedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  expo?: Maybe<WorkplaceUpdateOneWithoutExpoInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutOrdersInput>;
  fee?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  formattedAddress?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fulfillmentAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  fulfillmentBy?: Maybe<DateTimeFieldUpdateOperationsInput>;
  giftCardCodes?: Maybe<OrderUpdategiftCardCodesInput>;
  giftCardDiscounts?: Maybe<OrderUpdategiftCardDiscountsInput>;
  giftCardsDiscount?: Maybe<FloatFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isEmployee?: Maybe<BoolFieldUpdateOperationsInput>;
  isOrderNow?: Maybe<BoolFieldUpdateOperationsInput>;
  isPaidByInvoice?: Maybe<BoolFieldUpdateOperationsInput>;
  isVip?: Maybe<BoolFieldUpdateOperationsInput>;
  items?: Maybe<OrderItemUpdateManyWithoutOrderInput>;
  kdsType?: Maybe<EnumKdsTypeFieldUpdateOperationsInput>;
  kitchenPreparingUntil?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  kitchenTickets?: Maybe<KitchenTicketUpdateManyWithoutOrderInput>;
  lat?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  lng?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  locality?: Maybe<NullableStringFieldUpdateOperationsInput>;
  method?: Maybe<EnumOrderMethodFieldUpdateOperationsInput>;
  note?: Maybe<NullableStringFieldUpdateOperationsInput>;
  numberOfGuests?: Maybe<NullableIntFieldUpdateOperationsInput>;
  orderEvents?: Maybe<OrderEventUpdateManyWithoutOrderInput>;
  orderName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  overwraps?: Maybe<NullableIntFieldUpdateOperationsInput>;
  packager?: Maybe<WorkplaceUpdateOneWithoutPackagerInput>;
  packedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  payedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  pickup?: Maybe<WorkplaceUpdateOneWithoutPickupInput>;
  placeId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  platform?: Maybe<EnumPlatformFieldUpdateOperationsInput>;
  postalCode?: Maybe<NullableStringFieldUpdateOperationsInput>;
  preparingUntil?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  promoCodes?: Maybe<OrderUpdatepromoCodesInput>;
  promoDiscount?: Maybe<FloatFieldUpdateOperationsInput>;
  promos?: Maybe<PromoUpdateManyWithoutOrdersInput>;
  refireDelay?: Maybe<IntFieldUpdateOperationsInput>;
  refired?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  region?: Maybe<NullableStringFieldUpdateOperationsInput>;
  runner?: Maybe<WorkplaceUpdateOneWithoutRunnerInput>;
  sequentialNumber?: Maybe<IntFieldUpdateOperationsInput>;
  shortId?: Maybe<StringFieldUpdateOperationsInput>;
  source?: Maybe<NullableStringFieldUpdateOperationsInput>;
  status?: Maybe<EnumOrderStatusFieldUpdateOperationsInput>;
  street?: Maybe<NullableStringFieldUpdateOperationsInput>;
  submittedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  subtotal?: Maybe<FloatFieldUpdateOperationsInput>;
  tax?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  taxFees?: Maybe<FloatFieldUpdateOperationsInput>;
  tip?: Maybe<FloatFieldUpdateOperationsInput>;
  tookanTaskId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  total?: Maybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  utensilsCount?: Maybe<IntFieldUpdateOperationsInput>;
};

export type OrderUpdategiftCardCodesInput = {
  push?: Maybe<Scalars['String']>;
  set?: Maybe<Array<Scalars['String']>>;
};

export type OrderUpdategiftCardDiscountsInput = {
  push?: Maybe<Scalars['Float']>;
  set?: Maybe<Array<Scalars['Float']>>;
};

export type OrderUpdatepromoCodesInput = {
  push?: Maybe<Scalars['String']>;
  set?: Maybe<Array<Scalars['String']>>;
};

export type OrderUpsertWithWhereUniqueWithoutCustomerInput = {
  create: OrderCreateWithoutCustomerInput;
  update: OrderUpdateWithoutCustomerInput;
  where: OrderWhereUniqueInput;
};

export type OrderUpsertWithWhereUniqueWithoutExpoInput = {
  create: OrderCreateWithoutExpoInput;
  update: OrderUpdateWithoutExpoInput;
  where: OrderWhereUniqueInput;
};

export type OrderUpsertWithWhereUniqueWithoutFacilityInput = {
  create: OrderCreateWithoutFacilityInput;
  update: OrderUpdateWithoutFacilityInput;
  where: OrderWhereUniqueInput;
};

export type OrderUpsertWithWhereUniqueWithoutPackagerInput = {
  create: OrderCreateWithoutPackagerInput;
  update: OrderUpdateWithoutPackagerInput;
  where: OrderWhereUniqueInput;
};

export type OrderUpsertWithWhereUniqueWithoutPickupInput = {
  create: OrderCreateWithoutPickupInput;
  update: OrderUpdateWithoutPickupInput;
  where: OrderWhereUniqueInput;
};

export type OrderUpsertWithWhereUniqueWithoutPromosInput = {
  create: OrderCreateWithoutPromosInput;
  update: OrderUpdateWithoutPromosInput;
  where: OrderWhereUniqueInput;
};

export type OrderUpsertWithWhereUniqueWithoutRunnerInput = {
  create: OrderCreateWithoutRunnerInput;
  update: OrderUpdateWithoutRunnerInput;
  where: OrderWhereUniqueInput;
};

export type OrderUpsertWithWhereUniqueWithoutTagsInput = {
  create: OrderCreateWithoutTagsInput;
  update: OrderUpdateWithoutTagsInput;
  where: OrderWhereUniqueInput;
};

export type OrderUpsertWithoutItemsInput = {
  create: OrderCreateWithoutItemsInput;
  update: OrderUpdateWithoutItemsInput;
};

export type OrderUpsertWithoutKitchenTicketsInput = {
  create: OrderCreateWithoutKitchenTicketsInput;
  update: OrderUpdateWithoutKitchenTicketsInput;
};

export type OrderValidation = {
  __typename?: 'OrderValidation';
  address?: Maybe<Address>;
  costs: OrderCost;
  delivery?: Maybe<DeliveryInfo>;
  errors: Array<OrderValidationError>;
  giftCardCodes: Array<Scalars['String']>;
  giftCardDiscounts: Array<Scalars['Float']>;
  orderMethod: OrderMethod;
  pickupOnlyItemIds: Array<Scalars['ID']>;
  promoCodes: Array<Scalars['String']>;
  promoErrors: Array<PromoValidationErrorWithCode>;
  unavailableItemIds: Array<Scalars['ID']>;
};

export enum OrderValidationError {
  KitchenIsNotAvailable = 'KITCHEN_IS_NOT_AVAILABLE',
  MissingAddress = 'MISSING_ADDRESS',
  MissingItems = 'MISSING_ITEMS',
  OrderMethodNotAvailable = 'ORDER_METHOD_NOT_AVAILABLE',
  OutOfDelivery = 'OUT_OF_DELIVERY',
  PickupOnlyItems = 'PICKUP_ONLY_ITEMS',
  TimeSlotPassed = 'TIME_SLOT_PASSED',
  UnavailableItems = 'UNAVAILABLE_ITEMS'
}

export type OrderWhereInput = {
  AND?: Maybe<Array<OrderWhereInput>>;
  NOT?: Maybe<Array<OrderWhereInput>>;
  OR?: Maybe<Array<OrderWhereInput>>;
  addressNote?: Maybe<StringNullableFilter>;
  braintreeTransactions?: Maybe<BraintreeTransactionListRelationFilter>;
  bundle?: Maybe<OrderBundleWhereInput>;
  bundleId?: Maybe<StringNullableFilter>;
  canceledAt?: Maybe<DateTimeNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  customer?: Maybe<UserCustomerInfoWhereInput>;
  customerId?: Maybe<StringFilter>;
  deliverToEmail?: Maybe<StringNullableFilter>;
  deliverToName?: Maybe<StringNullableFilter>;
  deliverToPhone?: Maybe<StringNullableFilter>;
  deliveredAt?: Maybe<DateTimeNullableFilter>;
  delivery?: Maybe<FloatFilter>;
  deliveryEstimation?: Maybe<DateTimeNullableFilter>;
  deliveryOption?: Maybe<EnumDeliveryOptionNullableFilter>;
  deliveryTimeInSeconds?: Maybe<IntNullableFilter>;
  expeditedAt?: Maybe<DateTimeNullableFilter>;
  expo?: Maybe<WorkplaceWhereInput>;
  expoId?: Maybe<StringNullableFilter>;
  facility?: Maybe<FacilityWhereInput>;
  facilityId?: Maybe<StringFilter>;
  fee?: Maybe<FloatNullableFilter>;
  formattedAddress?: Maybe<StringNullableFilter>;
  fulfillmentAt?: Maybe<DateTimeFilter>;
  fulfillmentBy?: Maybe<DateTimeFilter>;
  giftCardCodes?: Maybe<StringNullableListFilter>;
  giftCardDiscounts?: Maybe<FloatNullableListFilter>;
  giftCardsDiscount?: Maybe<FloatFilter>;
  id?: Maybe<StringFilter>;
  isEmployee?: Maybe<BoolFilter>;
  isOrderNow?: Maybe<BoolFilter>;
  isPaidByInvoice?: Maybe<BoolFilter>;
  isVip?: Maybe<BoolFilter>;
  items?: Maybe<OrderItemListRelationFilter>;
  kdsType?: Maybe<EnumKdsTypeFilter>;
  kitchenPreparingUntil?: Maybe<DateTimeNullableFilter>;
  kitchenTickets?: Maybe<KitchenTicketListRelationFilter>;
  lat?: Maybe<FloatNullableFilter>;
  lng?: Maybe<FloatNullableFilter>;
  locality?: Maybe<StringNullableFilter>;
  method?: Maybe<EnumOrderMethodFilter>;
  note?: Maybe<StringNullableFilter>;
  numberOfGuests?: Maybe<IntNullableFilter>;
  orderEvents?: Maybe<OrderEventListRelationFilter>;
  orderName?: Maybe<StringNullableFilter>;
  overwraps?: Maybe<IntNullableFilter>;
  packager?: Maybe<WorkplaceWhereInput>;
  packagerId?: Maybe<StringNullableFilter>;
  packedAt?: Maybe<DateTimeNullableFilter>;
  payedAt?: Maybe<DateTimeNullableFilter>;
  pickup?: Maybe<WorkplaceWhereInput>;
  pickupId?: Maybe<StringNullableFilter>;
  placeId?: Maybe<StringNullableFilter>;
  platform?: Maybe<EnumPlatformFilter>;
  postalCode?: Maybe<StringNullableFilter>;
  preparingUntil?: Maybe<DateTimeNullableFilter>;
  promoCodes?: Maybe<StringNullableListFilter>;
  promoDiscount?: Maybe<FloatFilter>;
  promos?: Maybe<PromoListRelationFilter>;
  refireDelay?: Maybe<IntFilter>;
  refired?: Maybe<BoolNullableFilter>;
  region?: Maybe<StringNullableFilter>;
  runner?: Maybe<WorkplaceWhereInput>;
  runnerId?: Maybe<StringNullableFilter>;
  sequentialNumber?: Maybe<IntFilter>;
  shortId?: Maybe<StringFilter>;
  source?: Maybe<StringNullableFilter>;
  status?: Maybe<EnumOrderStatusFilter>;
  street?: Maybe<StringNullableFilter>;
  submittedAt?: Maybe<DateTimeFilter>;
  subtotal?: Maybe<FloatFilter>;
  tags?: Maybe<TagListRelationFilter>;
  tax?: Maybe<FloatNullableFilter>;
  taxFees?: Maybe<FloatFilter>;
  tip?: Maybe<FloatFilter>;
  tookanTaskId?: Maybe<StringNullableFilter>;
  total?: Maybe<FloatFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  utensilsCount?: Maybe<IntFilter>;
};

export type OrderWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type PaginationArg = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};

export type PasswordUpdateInput = {
  customerId: Scalars['ID'];
  password: Scalars['String'];
};

export enum Platform {
  Catering = 'CATERING',
  Chowly = 'CHOWLY',
  Consumer = 'CONSUMER',
  Kiosk = 'KIOSK'
}

export type PlatformMixReport = {
  __typename?: 'PlatformMixReport';
  allOrderCount: Scalars['Int'];
  appDeliveryOrderCount: Scalars['Int'];
  appPickupOrderCount: Scalars['Int'];
  cateringDeliveryOrderCount: Scalars['Int'];
  cateringPickupOrderCount: Scalars['Int'];
  date: Scalars['DateTime'];
  employeeDeliveryOrderCount: Scalars['Int'];
  employeePickupOrderCount: Scalars['Int'];
};

export type PlatformUsers = {
  __typename?: 'PlatformUsers';
  corporateAdmins: Array<User>;
  craveEmployees: Array<User>;
  customerSupportUsers: Array<User>;
  facilityAdmins: Array<User>;
  kioskUsers: Array<User>;
  restaurantEmployees: Array<User>;
  restaurantOwners: Array<User>;
};

export type PowerBiReport = {
  __typename?: 'PowerBiReport';
  availableToConcepts: Array<Kitchen>;
  availableToFacilities: Array<Facility>;
  availableToRoles: Array<UserRole>;
  id: Scalars['String'];
  reportId: Scalars['String'];
  title: Scalars['String'];
  url: Scalars['String'];
};


export type PowerBiReportAvailableToConceptsArgs = {
  after?: Maybe<KitchenWhereUniqueInput>;
  before?: Maybe<KitchenWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type PowerBiReportAvailableToFacilitiesArgs = {
  after?: Maybe<FacilityWhereUniqueInput>;
  before?: Maybe<FacilityWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type PowerBiReportCreateInput = {
  availableToConcepts?: Maybe<KitchenCreateNestedManyWithoutPowerBiReportInput>;
  availableToFacilities?: Maybe<FacilityCreateNestedManyWithoutPowerBiReportsInput>;
  availableToRoles?: Maybe<PowerBiReportCreateavailableToRolesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  datasetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  reportId?: Maybe<Scalars['String']>;
  sortId?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  workspaceId?: Maybe<Scalars['String']>;
};

export type PowerBiReportCreateNestedManyWithoutAvailableToConceptsInput = {
  connect?: Maybe<Array<PowerBiReportWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PowerBiReportCreateOrConnectWithoutAvailableToConceptsInput>>;
  create?: Maybe<Array<PowerBiReportCreateWithoutAvailableToConceptsInput>>;
};

export type PowerBiReportCreateNestedManyWithoutAvailableToFacilitiesInput = {
  connect?: Maybe<Array<PowerBiReportWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PowerBiReportCreateOrConnectWithoutAvailableToFacilitiesInput>>;
  create?: Maybe<Array<PowerBiReportCreateWithoutAvailableToFacilitiesInput>>;
};

export type PowerBiReportCreateOrConnectWithoutAvailableToConceptsInput = {
  create: PowerBiReportCreateWithoutAvailableToConceptsInput;
  where: PowerBiReportWhereUniqueInput;
};

export type PowerBiReportCreateOrConnectWithoutAvailableToFacilitiesInput = {
  create: PowerBiReportCreateWithoutAvailableToFacilitiesInput;
  where: PowerBiReportWhereUniqueInput;
};

export type PowerBiReportCreateWithoutAvailableToConceptsInput = {
  availableToFacilities?: Maybe<FacilityCreateNestedManyWithoutPowerBiReportsInput>;
  availableToRoles?: Maybe<PowerBiReportCreateavailableToRolesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  datasetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  reportId?: Maybe<Scalars['String']>;
  sortId?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  workspaceId?: Maybe<Scalars['String']>;
};

export type PowerBiReportCreateWithoutAvailableToFacilitiesInput = {
  availableToConcepts?: Maybe<KitchenCreateNestedManyWithoutPowerBiReportInput>;
  availableToRoles?: Maybe<PowerBiReportCreateavailableToRolesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  datasetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  reportId?: Maybe<Scalars['String']>;
  sortId?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  workspaceId?: Maybe<Scalars['String']>;
};

export type PowerBiReportCreateavailableToRolesInput = {
  set?: Maybe<Array<UserRole>>;
};

export type PowerBiReportListRelationFilter = {
  every?: Maybe<PowerBiReportWhereInput>;
  none?: Maybe<PowerBiReportWhereInput>;
  some?: Maybe<PowerBiReportWhereInput>;
};

export type PowerBiReportScalarWhereInput = {
  AND?: Maybe<Array<PowerBiReportScalarWhereInput>>;
  NOT?: Maybe<Array<PowerBiReportScalarWhereInput>>;
  OR?: Maybe<Array<PowerBiReportScalarWhereInput>>;
  availableToRoles?: Maybe<EnumUserRoleNullableListFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  datasetId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  reportId?: Maybe<StringFilter>;
  sortId?: Maybe<IntFilter>;
  title?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  url?: Maybe<StringFilter>;
  workspaceId?: Maybe<StringFilter>;
};

export type PowerBiReportUpdateInput = {
  availableToConcepts?: Maybe<KitchenUpdateManyWithoutPowerBiReportInput>;
  availableToFacilities?: Maybe<FacilityUpdateManyWithoutPowerBiReportsInput>;
  availableToRoles?: Maybe<PowerBiReportUpdateavailableToRolesInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  datasetId?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  reportId?: Maybe<StringFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  url?: Maybe<StringFieldUpdateOperationsInput>;
  workspaceId?: Maybe<StringFieldUpdateOperationsInput>;
};

export type PowerBiReportUpdateManyMutationInput = {
  availableToRoles?: Maybe<PowerBiReportUpdateavailableToRolesInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  datasetId?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  reportId?: Maybe<StringFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  url?: Maybe<StringFieldUpdateOperationsInput>;
  workspaceId?: Maybe<StringFieldUpdateOperationsInput>;
};

export type PowerBiReportUpdateManyWithWhereWithoutAvailableToConceptsInput = {
  data: PowerBiReportUpdateManyMutationInput;
  where: PowerBiReportScalarWhereInput;
};

export type PowerBiReportUpdateManyWithWhereWithoutAvailableToFacilitiesInput = {
  data: PowerBiReportUpdateManyMutationInput;
  where: PowerBiReportScalarWhereInput;
};

export type PowerBiReportUpdateManyWithoutAvailableToConceptsInput = {
  connect?: Maybe<Array<PowerBiReportWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PowerBiReportCreateOrConnectWithoutAvailableToConceptsInput>>;
  create?: Maybe<Array<PowerBiReportCreateWithoutAvailableToConceptsInput>>;
  delete?: Maybe<Array<PowerBiReportWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PowerBiReportScalarWhereInput>>;
  disconnect?: Maybe<Array<PowerBiReportWhereUniqueInput>>;
  set?: Maybe<Array<PowerBiReportWhereUniqueInput>>;
  update?: Maybe<Array<PowerBiReportUpdateWithWhereUniqueWithoutAvailableToConceptsInput>>;
  updateMany?: Maybe<Array<PowerBiReportUpdateManyWithWhereWithoutAvailableToConceptsInput>>;
  upsert?: Maybe<Array<PowerBiReportUpsertWithWhereUniqueWithoutAvailableToConceptsInput>>;
};

export type PowerBiReportUpdateManyWithoutAvailableToFacilitiesInput = {
  connect?: Maybe<Array<PowerBiReportWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PowerBiReportCreateOrConnectWithoutAvailableToFacilitiesInput>>;
  create?: Maybe<Array<PowerBiReportCreateWithoutAvailableToFacilitiesInput>>;
  delete?: Maybe<Array<PowerBiReportWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PowerBiReportScalarWhereInput>>;
  disconnect?: Maybe<Array<PowerBiReportWhereUniqueInput>>;
  set?: Maybe<Array<PowerBiReportWhereUniqueInput>>;
  update?: Maybe<Array<PowerBiReportUpdateWithWhereUniqueWithoutAvailableToFacilitiesInput>>;
  updateMany?: Maybe<Array<PowerBiReportUpdateManyWithWhereWithoutAvailableToFacilitiesInput>>;
  upsert?: Maybe<Array<PowerBiReportUpsertWithWhereUniqueWithoutAvailableToFacilitiesInput>>;
};

export type PowerBiReportUpdateWithWhereUniqueWithoutAvailableToConceptsInput = {
  data: PowerBiReportUpdateWithoutAvailableToConceptsInput;
  where: PowerBiReportWhereUniqueInput;
};

export type PowerBiReportUpdateWithWhereUniqueWithoutAvailableToFacilitiesInput = {
  data: PowerBiReportUpdateWithoutAvailableToFacilitiesInput;
  where: PowerBiReportWhereUniqueInput;
};

export type PowerBiReportUpdateWithoutAvailableToConceptsInput = {
  availableToFacilities?: Maybe<FacilityUpdateManyWithoutPowerBiReportsInput>;
  availableToRoles?: Maybe<PowerBiReportUpdateavailableToRolesInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  datasetId?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  reportId?: Maybe<StringFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  url?: Maybe<StringFieldUpdateOperationsInput>;
  workspaceId?: Maybe<StringFieldUpdateOperationsInput>;
};

export type PowerBiReportUpdateWithoutAvailableToFacilitiesInput = {
  availableToConcepts?: Maybe<KitchenUpdateManyWithoutPowerBiReportInput>;
  availableToRoles?: Maybe<PowerBiReportUpdateavailableToRolesInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  datasetId?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  reportId?: Maybe<StringFieldUpdateOperationsInput>;
  sortId?: Maybe<IntFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  url?: Maybe<StringFieldUpdateOperationsInput>;
  workspaceId?: Maybe<StringFieldUpdateOperationsInput>;
};

export type PowerBiReportUpdateavailableToRolesInput = {
  push?: Maybe<UserRole>;
  set?: Maybe<Array<UserRole>>;
};

export type PowerBiReportUpsertWithWhereUniqueWithoutAvailableToConceptsInput = {
  create: PowerBiReportCreateWithoutAvailableToConceptsInput;
  update: PowerBiReportUpdateWithoutAvailableToConceptsInput;
  where: PowerBiReportWhereUniqueInput;
};

export type PowerBiReportUpsertWithWhereUniqueWithoutAvailableToFacilitiesInput = {
  create: PowerBiReportCreateWithoutAvailableToFacilitiesInput;
  update: PowerBiReportUpdateWithoutAvailableToFacilitiesInput;
  where: PowerBiReportWhereUniqueInput;
};

export type PowerBiReportWhereInput = {
  AND?: Maybe<Array<PowerBiReportWhereInput>>;
  NOT?: Maybe<Array<PowerBiReportWhereInput>>;
  OR?: Maybe<Array<PowerBiReportWhereInput>>;
  availableToConcepts?: Maybe<KitchenListRelationFilter>;
  availableToFacilities?: Maybe<FacilityListRelationFilter>;
  availableToRoles?: Maybe<EnumUserRoleNullableListFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  datasetId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  reportId?: Maybe<StringFilter>;
  sortId?: Maybe<IntFilter>;
  title?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  url?: Maybe<StringFilter>;
  workspaceId?: Maybe<StringFilter>;
};

export type PowerBiReportWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type PowerBiReportsAndToken = {
  __typename?: 'PowerBiReportsAndToken';
  embedToken: Scalars['String'];
  reports: Array<PowerBiReport>;
};

export type Promo = {
  __typename?: 'Promo';
  appliesTo: PromoAppliesTo;
  appliesToDefinition?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  definition?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  eligibility: PromoEligibility;
  eligibilityDefinition?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isAllKitchensAllFacilities: Scalars['Boolean'];
  method: Array<OrderMethod>;
  orderCount: Scalars['Int'];
  orders: Array<Order>;
  promoFacilityRules: Array<PromoFacilityRule>;
  requirements: PromoRequirement;
  requirementsDefinition?: Maybe<Scalars['Float']>;
  status: PromoStatus;
  type: PromoType;
  typeDefinition?: Maybe<Scalars['String']>;
  usageLimit: PromoUsageLimits;
  usageLimitDefinition?: Maybe<Scalars['String']>;
  validSince: Scalars['DateTime'];
  validTill?: Maybe<Scalars['DateTime']>;
};


export type PromoOrdersArgs = {
  after?: Maybe<OrderWhereUniqueInput>;
  before?: Maybe<OrderWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type PromoPromoFacilityRulesArgs = {
  after?: Maybe<PromoFacilityRuleWhereUniqueInput>;
  before?: Maybe<PromoFacilityRuleWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type PromoAggregatedReport = {
  __typename?: 'PromoAggregatedReport';
  code: Scalars['String'];
  craveSpend: Scalars['Float'];
  customerSpend: Scalars['Float'];
  orderCount: Scalars['Int'];
  totalSpend: Scalars['Float'];
};

export enum PromoAppliesTo {
  Category = 'CATEGORY',
  ExceptItems = 'EXCEPT_ITEMS',
  Item = 'ITEM',
  Order = 'ORDER'
}

export type PromoBanner = {
  __typename?: 'PromoBanner';
  active: Scalars['Boolean'];
  color: Scalars['String'];
  description: Scalars['String'];
  hasDarkStatusBar: Scalars['Boolean'];
  id: Scalars['String'];
  textColor: Scalars['String'];
  title: Scalars['String'];
};

export type PromoBannerCreateManyFacilityInput = {
  active: Scalars['Boolean'];
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  textColor?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PromoBannerCreateManyFacilityInputEnvelope = {
  data?: Maybe<Array<PromoBannerCreateManyFacilityInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PromoBannerCreateNestedManyWithoutFacilityInput = {
  connect?: Maybe<Array<PromoBannerWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PromoBannerCreateOrConnectWithoutFacilityInput>>;
  create?: Maybe<Array<PromoBannerCreateWithoutFacilityInput>>;
  createMany?: Maybe<PromoBannerCreateManyFacilityInputEnvelope>;
};

export type PromoBannerCreateOrConnectWithoutFacilityInput = {
  create: PromoBannerCreateWithoutFacilityInput;
  where: PromoBannerWhereUniqueInput;
};

export type PromoBannerCreateWithoutFacilityInput = {
  active: Scalars['Boolean'];
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  textColor?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PromoBannerListRelationFilter = {
  every?: Maybe<PromoBannerWhereInput>;
  none?: Maybe<PromoBannerWhereInput>;
  some?: Maybe<PromoBannerWhereInput>;
};

export type PromoBannerScalarWhereInput = {
  AND?: Maybe<Array<PromoBannerScalarWhereInput>>;
  NOT?: Maybe<Array<PromoBannerScalarWhereInput>>;
  OR?: Maybe<Array<PromoBannerScalarWhereInput>>;
  active?: Maybe<BoolFilter>;
  color?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringFilter>;
  facilityId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  textColor?: Maybe<StringFilter>;
  title?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type PromoBannerUpdateInput = {
  active?: Maybe<BoolFieldUpdateOperationsInput>;
  color?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutPromoBannersInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  textColor?: Maybe<StringFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type PromoBannerUpdateManyMutationInput = {
  active?: Maybe<BoolFieldUpdateOperationsInput>;
  color?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  textColor?: Maybe<StringFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type PromoBannerUpdateManyWithWhereWithoutFacilityInput = {
  data: PromoBannerUpdateManyMutationInput;
  where: PromoBannerScalarWhereInput;
};

export type PromoBannerUpdateManyWithoutFacilityInput = {
  connect?: Maybe<Array<PromoBannerWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PromoBannerCreateOrConnectWithoutFacilityInput>>;
  create?: Maybe<Array<PromoBannerCreateWithoutFacilityInput>>;
  createMany?: Maybe<PromoBannerCreateManyFacilityInputEnvelope>;
  delete?: Maybe<Array<PromoBannerWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PromoBannerScalarWhereInput>>;
  disconnect?: Maybe<Array<PromoBannerWhereUniqueInput>>;
  set?: Maybe<Array<PromoBannerWhereUniqueInput>>;
  update?: Maybe<Array<PromoBannerUpdateWithWhereUniqueWithoutFacilityInput>>;
  updateMany?: Maybe<Array<PromoBannerUpdateManyWithWhereWithoutFacilityInput>>;
  upsert?: Maybe<Array<PromoBannerUpsertWithWhereUniqueWithoutFacilityInput>>;
};

export type PromoBannerUpdateWithWhereUniqueWithoutFacilityInput = {
  data: PromoBannerUpdateWithoutFacilityInput;
  where: PromoBannerWhereUniqueInput;
};

export type PromoBannerUpdateWithoutFacilityInput = {
  active?: Maybe<BoolFieldUpdateOperationsInput>;
  color?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  textColor?: Maybe<StringFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type PromoBannerUpsertWithWhereUniqueWithoutFacilityInput = {
  create: PromoBannerCreateWithoutFacilityInput;
  update: PromoBannerUpdateWithoutFacilityInput;
  where: PromoBannerWhereUniqueInput;
};

export type PromoBannerWhereInput = {
  AND?: Maybe<Array<PromoBannerWhereInput>>;
  NOT?: Maybe<Array<PromoBannerWhereInput>>;
  OR?: Maybe<Array<PromoBannerWhereInput>>;
  active?: Maybe<BoolFilter>;
  color?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringFilter>;
  facility?: Maybe<FacilityWhereInput>;
  facilityId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  textColor?: Maybe<StringFilter>;
  title?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type PromoBannerWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type PromoCreateNestedManyWithoutOrdersInput = {
  connect?: Maybe<Array<PromoWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PromoCreateOrConnectWithoutOrdersInput>>;
  create?: Maybe<Array<PromoCreateWithoutOrdersInput>>;
};

export type PromoCreateNestedOneWithoutPromoFacilityRulesInput = {
  connect?: Maybe<PromoWhereUniqueInput>;
  connectOrCreate?: Maybe<PromoCreateOrConnectWithoutPromoFacilityRulesInput>;
  create?: Maybe<PromoCreateWithoutPromoFacilityRulesInput>;
};

export type PromoCreateOrConnectWithoutOrdersInput = {
  create: PromoCreateWithoutOrdersInput;
  where: PromoWhereUniqueInput;
};

export type PromoCreateOrConnectWithoutPromoFacilityRulesInput = {
  create: PromoCreateWithoutPromoFacilityRulesInput;
  where: PromoWhereUniqueInput;
};

export type PromoCreateWithoutOrdersInput = {
  appliesTo?: Maybe<PromoAppliesTo>;
  appliesToDefinition?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  definition?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  eligibility?: Maybe<PromoEligibility>;
  eligibilityDefinition?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isAllKitchensAllFacilities?: Maybe<Scalars['Boolean']>;
  method?: Maybe<PromoCreatemethodInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleCreateNestedManyWithoutPromoInput>;
  requirements?: Maybe<PromoRequirement>;
  requirementsDefinition?: Maybe<Scalars['Float']>;
  status?: Maybe<PromoStatus>;
  type: PromoType;
  typeDefinition?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageLimit?: Maybe<PromoUsageLimits>;
  usageLimitDefinition?: Maybe<Scalars['String']>;
  validSince: Scalars['DateTime'];
  validTill?: Maybe<Scalars['DateTime']>;
};

export type PromoCreateWithoutPromoFacilityRulesInput = {
  appliesTo?: Maybe<PromoAppliesTo>;
  appliesToDefinition?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  definition?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  eligibility?: Maybe<PromoEligibility>;
  eligibilityDefinition?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isAllKitchensAllFacilities?: Maybe<Scalars['Boolean']>;
  method?: Maybe<PromoCreatemethodInput>;
  orders?: Maybe<OrderCreateNestedManyWithoutPromosInput>;
  requirements?: Maybe<PromoRequirement>;
  requirementsDefinition?: Maybe<Scalars['Float']>;
  status?: Maybe<PromoStatus>;
  type: PromoType;
  typeDefinition?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageLimit?: Maybe<PromoUsageLimits>;
  usageLimitDefinition?: Maybe<Scalars['String']>;
  validSince: Scalars['DateTime'];
  validTill?: Maybe<Scalars['DateTime']>;
};

export type PromoCreatemethodInput = {
  set?: Maybe<Array<OrderMethod>>;
};

export enum PromoEligibility {
  Customer = 'CUSTOMER',
  Employee = 'EMPLOYEE',
  Everyone = 'EVERYONE',
  Segment = 'SEGMENT'
}

export type PromoFacilityRule = {
  __typename?: 'PromoFacilityRule';
  facilityId: Scalars['String'];
  id: Scalars['String'];
  isAllKitchens: Scalars['Boolean'];
  kitchens: Array<Kitchen>;
  promoId: Scalars['String'];
};


export type PromoFacilityRuleKitchensArgs = {
  after?: Maybe<KitchenWhereUniqueInput>;
  before?: Maybe<KitchenWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type PromoFacilityRuleCreateManyFacilityInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isAllKitchens: Scalars['Boolean'];
  promoId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PromoFacilityRuleCreateManyFacilityInputEnvelope = {
  data?: Maybe<Array<PromoFacilityRuleCreateManyFacilityInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PromoFacilityRuleCreateManyPromoInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  facilityId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  isAllKitchens: Scalars['Boolean'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PromoFacilityRuleCreateManyPromoInputEnvelope = {
  data?: Maybe<Array<PromoFacilityRuleCreateManyPromoInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PromoFacilityRuleCreateNestedManyWithoutFacilityInput = {
  connect?: Maybe<Array<PromoFacilityRuleWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PromoFacilityRuleCreateOrConnectWithoutFacilityInput>>;
  create?: Maybe<Array<PromoFacilityRuleCreateWithoutFacilityInput>>;
  createMany?: Maybe<PromoFacilityRuleCreateManyFacilityInputEnvelope>;
};

export type PromoFacilityRuleCreateNestedManyWithoutKitchensInput = {
  connect?: Maybe<Array<PromoFacilityRuleWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PromoFacilityRuleCreateOrConnectWithoutKitchensInput>>;
  create?: Maybe<Array<PromoFacilityRuleCreateWithoutKitchensInput>>;
};

export type PromoFacilityRuleCreateNestedManyWithoutPromoInput = {
  connect?: Maybe<Array<PromoFacilityRuleWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PromoFacilityRuleCreateOrConnectWithoutPromoInput>>;
  create?: Maybe<Array<PromoFacilityRuleCreateWithoutPromoInput>>;
  createMany?: Maybe<PromoFacilityRuleCreateManyPromoInputEnvelope>;
};

export type PromoFacilityRuleCreateOrConnectWithoutFacilityInput = {
  create: PromoFacilityRuleCreateWithoutFacilityInput;
  where: PromoFacilityRuleWhereUniqueInput;
};

export type PromoFacilityRuleCreateOrConnectWithoutKitchensInput = {
  create: PromoFacilityRuleCreateWithoutKitchensInput;
  where: PromoFacilityRuleWhereUniqueInput;
};

export type PromoFacilityRuleCreateOrConnectWithoutPromoInput = {
  create: PromoFacilityRuleCreateWithoutPromoInput;
  where: PromoFacilityRuleWhereUniqueInput;
};

export type PromoFacilityRuleCreateWithoutFacilityInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isAllKitchens: Scalars['Boolean'];
  kitchens?: Maybe<KitchenCreateNestedManyWithoutPromoFacilityRulesInput>;
  promo: PromoCreateNestedOneWithoutPromoFacilityRulesInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PromoFacilityRuleCreateWithoutKitchensInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  facility: FacilityCreateNestedOneWithoutPromoFacilityRulesInput;
  id?: Maybe<Scalars['String']>;
  isAllKitchens: Scalars['Boolean'];
  promo: PromoCreateNestedOneWithoutPromoFacilityRulesInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PromoFacilityRuleCreateWithoutPromoInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  facility: FacilityCreateNestedOneWithoutPromoFacilityRulesInput;
  id?: Maybe<Scalars['String']>;
  isAllKitchens: Scalars['Boolean'];
  kitchens?: Maybe<KitchenCreateNestedManyWithoutPromoFacilityRulesInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PromoFacilityRuleListRelationFilter = {
  every?: Maybe<PromoFacilityRuleWhereInput>;
  none?: Maybe<PromoFacilityRuleWhereInput>;
  some?: Maybe<PromoFacilityRuleWhereInput>;
};

export type PromoFacilityRuleScalarWhereInput = {
  AND?: Maybe<Array<PromoFacilityRuleScalarWhereInput>>;
  NOT?: Maybe<Array<PromoFacilityRuleScalarWhereInput>>;
  OR?: Maybe<Array<PromoFacilityRuleScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  facilityId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  isAllKitchens?: Maybe<BoolFilter>;
  promoId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type PromoFacilityRuleUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isAllKitchens?: Maybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type PromoFacilityRuleUpdateManyWithWhereWithoutFacilityInput = {
  data: PromoFacilityRuleUpdateManyMutationInput;
  where: PromoFacilityRuleScalarWhereInput;
};

export type PromoFacilityRuleUpdateManyWithWhereWithoutKitchensInput = {
  data: PromoFacilityRuleUpdateManyMutationInput;
  where: PromoFacilityRuleScalarWhereInput;
};

export type PromoFacilityRuleUpdateManyWithWhereWithoutPromoInput = {
  data: PromoFacilityRuleUpdateManyMutationInput;
  where: PromoFacilityRuleScalarWhereInput;
};

export type PromoFacilityRuleUpdateManyWithoutFacilityInput = {
  connect?: Maybe<Array<PromoFacilityRuleWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PromoFacilityRuleCreateOrConnectWithoutFacilityInput>>;
  create?: Maybe<Array<PromoFacilityRuleCreateWithoutFacilityInput>>;
  createMany?: Maybe<PromoFacilityRuleCreateManyFacilityInputEnvelope>;
  delete?: Maybe<Array<PromoFacilityRuleWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PromoFacilityRuleScalarWhereInput>>;
  disconnect?: Maybe<Array<PromoFacilityRuleWhereUniqueInput>>;
  set?: Maybe<Array<PromoFacilityRuleWhereUniqueInput>>;
  update?: Maybe<Array<PromoFacilityRuleUpdateWithWhereUniqueWithoutFacilityInput>>;
  updateMany?: Maybe<Array<PromoFacilityRuleUpdateManyWithWhereWithoutFacilityInput>>;
  upsert?: Maybe<Array<PromoFacilityRuleUpsertWithWhereUniqueWithoutFacilityInput>>;
};

export type PromoFacilityRuleUpdateManyWithoutKitchensInput = {
  connect?: Maybe<Array<PromoFacilityRuleWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PromoFacilityRuleCreateOrConnectWithoutKitchensInput>>;
  create?: Maybe<Array<PromoFacilityRuleCreateWithoutKitchensInput>>;
  delete?: Maybe<Array<PromoFacilityRuleWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PromoFacilityRuleScalarWhereInput>>;
  disconnect?: Maybe<Array<PromoFacilityRuleWhereUniqueInput>>;
  set?: Maybe<Array<PromoFacilityRuleWhereUniqueInput>>;
  update?: Maybe<Array<PromoFacilityRuleUpdateWithWhereUniqueWithoutKitchensInput>>;
  updateMany?: Maybe<Array<PromoFacilityRuleUpdateManyWithWhereWithoutKitchensInput>>;
  upsert?: Maybe<Array<PromoFacilityRuleUpsertWithWhereUniqueWithoutKitchensInput>>;
};

export type PromoFacilityRuleUpdateManyWithoutPromoInput = {
  connect?: Maybe<Array<PromoFacilityRuleWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PromoFacilityRuleCreateOrConnectWithoutPromoInput>>;
  create?: Maybe<Array<PromoFacilityRuleCreateWithoutPromoInput>>;
  createMany?: Maybe<PromoFacilityRuleCreateManyPromoInputEnvelope>;
  delete?: Maybe<Array<PromoFacilityRuleWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PromoFacilityRuleScalarWhereInput>>;
  disconnect?: Maybe<Array<PromoFacilityRuleWhereUniqueInput>>;
  set?: Maybe<Array<PromoFacilityRuleWhereUniqueInput>>;
  update?: Maybe<Array<PromoFacilityRuleUpdateWithWhereUniqueWithoutPromoInput>>;
  updateMany?: Maybe<Array<PromoFacilityRuleUpdateManyWithWhereWithoutPromoInput>>;
  upsert?: Maybe<Array<PromoFacilityRuleUpsertWithWhereUniqueWithoutPromoInput>>;
};

export type PromoFacilityRuleUpdateWithWhereUniqueWithoutFacilityInput = {
  data: PromoFacilityRuleUpdateWithoutFacilityInput;
  where: PromoFacilityRuleWhereUniqueInput;
};

export type PromoFacilityRuleUpdateWithWhereUniqueWithoutKitchensInput = {
  data: PromoFacilityRuleUpdateWithoutKitchensInput;
  where: PromoFacilityRuleWhereUniqueInput;
};

export type PromoFacilityRuleUpdateWithWhereUniqueWithoutPromoInput = {
  data: PromoFacilityRuleUpdateWithoutPromoInput;
  where: PromoFacilityRuleWhereUniqueInput;
};

export type PromoFacilityRuleUpdateWithoutFacilityInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isAllKitchens?: Maybe<BoolFieldUpdateOperationsInput>;
  kitchens?: Maybe<KitchenUpdateManyWithoutPromoFacilityRulesInput>;
  promo?: Maybe<PromoUpdateOneRequiredWithoutPromoFacilityRulesInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type PromoFacilityRuleUpdateWithoutKitchensInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutPromoFacilityRulesInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isAllKitchens?: Maybe<BoolFieldUpdateOperationsInput>;
  promo?: Maybe<PromoUpdateOneRequiredWithoutPromoFacilityRulesInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type PromoFacilityRuleUpdateWithoutPromoInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutPromoFacilityRulesInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isAllKitchens?: Maybe<BoolFieldUpdateOperationsInput>;
  kitchens?: Maybe<KitchenUpdateManyWithoutPromoFacilityRulesInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type PromoFacilityRuleUpsertWithWhereUniqueWithoutFacilityInput = {
  create: PromoFacilityRuleCreateWithoutFacilityInput;
  update: PromoFacilityRuleUpdateWithoutFacilityInput;
  where: PromoFacilityRuleWhereUniqueInput;
};

export type PromoFacilityRuleUpsertWithWhereUniqueWithoutKitchensInput = {
  create: PromoFacilityRuleCreateWithoutKitchensInput;
  update: PromoFacilityRuleUpdateWithoutKitchensInput;
  where: PromoFacilityRuleWhereUniqueInput;
};

export type PromoFacilityRuleUpsertWithWhereUniqueWithoutPromoInput = {
  create: PromoFacilityRuleCreateWithoutPromoInput;
  update: PromoFacilityRuleUpdateWithoutPromoInput;
  where: PromoFacilityRuleWhereUniqueInput;
};

export type PromoFacilityRuleWhereInput = {
  AND?: Maybe<Array<PromoFacilityRuleWhereInput>>;
  NOT?: Maybe<Array<PromoFacilityRuleWhereInput>>;
  OR?: Maybe<Array<PromoFacilityRuleWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  facility?: Maybe<FacilityWhereInput>;
  facilityId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  isAllKitchens?: Maybe<BoolFilter>;
  kitchens?: Maybe<KitchenListRelationFilter>;
  promo?: Maybe<PromoWhereInput>;
  promoId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type PromoFacilityRuleWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type PromoFacilityRulesInput = {
  facilityId: Scalars['ID'];
  id?: Maybe<Scalars['ID']>;
  isAllKitchens: Scalars['Boolean'];
  kitchens?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum PromoFilter {
  Active = 'ACTIVE',
  All = 'ALL',
  Expired = 'EXPIRED',
  Inactive = 'INACTIVE',
  Scheduled = 'SCHEDULED'
}

export type PromoListRelationFilter = {
  every?: Maybe<PromoWhereInput>;
  none?: Maybe<PromoWhereInput>;
  some?: Maybe<PromoWhereInput>;
};

export type PromoReport = {
  __typename?: 'PromoReport';
  code: Scalars['String'];
  craveSpend: Scalars['Float'];
  customerSpend: Scalars['Float'];
  date: Scalars['DateTime'];
  orderCount: Scalars['Int'];
  totalSpend: Scalars['Float'];
};

export enum PromoRequirement {
  MinAmount = 'MIN_AMOUNT',
  MinItems = 'MIN_ITEMS',
  None = 'NONE'
}

export type PromoScalarWhereInput = {
  AND?: Maybe<Array<PromoScalarWhereInput>>;
  NOT?: Maybe<Array<PromoScalarWhereInput>>;
  OR?: Maybe<Array<PromoScalarWhereInput>>;
  appliesTo?: Maybe<EnumPromoAppliesToFilter>;
  appliesToDefinition?: Maybe<StringNullableFilter>;
  code?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  definition?: Maybe<StringNullableFilter>;
  description?: Maybe<StringNullableFilter>;
  eligibility?: Maybe<EnumPromoEligibilityFilter>;
  eligibilityDefinition?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  isAllKitchensAllFacilities?: Maybe<BoolFilter>;
  method?: Maybe<EnumOrderMethodNullableListFilter>;
  requirements?: Maybe<EnumPromoRequirementFilter>;
  requirementsDefinition?: Maybe<FloatNullableFilter>;
  status?: Maybe<EnumPromoStatusFilter>;
  type?: Maybe<EnumPromoTypeFilter>;
  typeDefinition?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  usageLimit?: Maybe<EnumPromoUsageLimitsFilter>;
  usageLimitDefinition?: Maybe<StringNullableFilter>;
  validSince?: Maybe<DateTimeFilter>;
  validTill?: Maybe<DateTimeNullableFilter>;
};

export enum PromoStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  Disabled = 'DISABLED'
}

export enum PromoType {
  BuyXGetY = 'BUY_X_GET_Y',
  DiscountAmount = 'DISCOUNT_AMOUNT',
  DiscountPercentage = 'DISCOUNT_PERCENTAGE',
  FreeDelivery = 'FREE_DELIVERY',
  FreeX = 'FREE_X',
  GiftCard = 'GIFT_CARD'
}

export type PromoUpdateManyMutationInput = {
  appliesTo?: Maybe<EnumPromoAppliesToFieldUpdateOperationsInput>;
  appliesToDefinition?: Maybe<NullableStringFieldUpdateOperationsInput>;
  code?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  definition?: Maybe<NullableStringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  eligibility?: Maybe<EnumPromoEligibilityFieldUpdateOperationsInput>;
  eligibilityDefinition?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isAllKitchensAllFacilities?: Maybe<BoolFieldUpdateOperationsInput>;
  method?: Maybe<PromoUpdatemethodInput>;
  requirements?: Maybe<EnumPromoRequirementFieldUpdateOperationsInput>;
  requirementsDefinition?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  status?: Maybe<EnumPromoStatusFieldUpdateOperationsInput>;
  type?: Maybe<EnumPromoTypeFieldUpdateOperationsInput>;
  typeDefinition?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  usageLimit?: Maybe<EnumPromoUsageLimitsFieldUpdateOperationsInput>;
  usageLimitDefinition?: Maybe<NullableStringFieldUpdateOperationsInput>;
  validSince?: Maybe<DateTimeFieldUpdateOperationsInput>;
  validTill?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type PromoUpdateManyWithWhereWithoutOrdersInput = {
  data: PromoUpdateManyMutationInput;
  where: PromoScalarWhereInput;
};

export type PromoUpdateManyWithoutOrdersInput = {
  connect?: Maybe<Array<PromoWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PromoCreateOrConnectWithoutOrdersInput>>;
  create?: Maybe<Array<PromoCreateWithoutOrdersInput>>;
  delete?: Maybe<Array<PromoWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PromoScalarWhereInput>>;
  disconnect?: Maybe<Array<PromoWhereUniqueInput>>;
  set?: Maybe<Array<PromoWhereUniqueInput>>;
  update?: Maybe<Array<PromoUpdateWithWhereUniqueWithoutOrdersInput>>;
  updateMany?: Maybe<Array<PromoUpdateManyWithWhereWithoutOrdersInput>>;
  upsert?: Maybe<Array<PromoUpsertWithWhereUniqueWithoutOrdersInput>>;
};

export type PromoUpdateOneRequiredWithoutPromoFacilityRulesInput = {
  connect?: Maybe<PromoWhereUniqueInput>;
  connectOrCreate?: Maybe<PromoCreateOrConnectWithoutPromoFacilityRulesInput>;
  create?: Maybe<PromoCreateWithoutPromoFacilityRulesInput>;
  update?: Maybe<PromoUpdateWithoutPromoFacilityRulesInput>;
  upsert?: Maybe<PromoUpsertWithoutPromoFacilityRulesInput>;
};

export type PromoUpdateWithWhereUniqueWithoutOrdersInput = {
  data: PromoUpdateWithoutOrdersInput;
  where: PromoWhereUniqueInput;
};

export type PromoUpdateWithoutOrdersInput = {
  appliesTo?: Maybe<EnumPromoAppliesToFieldUpdateOperationsInput>;
  appliesToDefinition?: Maybe<NullableStringFieldUpdateOperationsInput>;
  code?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  definition?: Maybe<NullableStringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  eligibility?: Maybe<EnumPromoEligibilityFieldUpdateOperationsInput>;
  eligibilityDefinition?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isAllKitchensAllFacilities?: Maybe<BoolFieldUpdateOperationsInput>;
  method?: Maybe<PromoUpdatemethodInput>;
  promoFacilityRules?: Maybe<PromoFacilityRuleUpdateManyWithoutPromoInput>;
  requirements?: Maybe<EnumPromoRequirementFieldUpdateOperationsInput>;
  requirementsDefinition?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  status?: Maybe<EnumPromoStatusFieldUpdateOperationsInput>;
  type?: Maybe<EnumPromoTypeFieldUpdateOperationsInput>;
  typeDefinition?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  usageLimit?: Maybe<EnumPromoUsageLimitsFieldUpdateOperationsInput>;
  usageLimitDefinition?: Maybe<NullableStringFieldUpdateOperationsInput>;
  validSince?: Maybe<DateTimeFieldUpdateOperationsInput>;
  validTill?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type PromoUpdateWithoutPromoFacilityRulesInput = {
  appliesTo?: Maybe<EnumPromoAppliesToFieldUpdateOperationsInput>;
  appliesToDefinition?: Maybe<NullableStringFieldUpdateOperationsInput>;
  code?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  definition?: Maybe<NullableStringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  eligibility?: Maybe<EnumPromoEligibilityFieldUpdateOperationsInput>;
  eligibilityDefinition?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isAllKitchensAllFacilities?: Maybe<BoolFieldUpdateOperationsInput>;
  method?: Maybe<PromoUpdatemethodInput>;
  orders?: Maybe<OrderUpdateManyWithoutPromosInput>;
  requirements?: Maybe<EnumPromoRequirementFieldUpdateOperationsInput>;
  requirementsDefinition?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  status?: Maybe<EnumPromoStatusFieldUpdateOperationsInput>;
  type?: Maybe<EnumPromoTypeFieldUpdateOperationsInput>;
  typeDefinition?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  usageLimit?: Maybe<EnumPromoUsageLimitsFieldUpdateOperationsInput>;
  usageLimitDefinition?: Maybe<NullableStringFieldUpdateOperationsInput>;
  validSince?: Maybe<DateTimeFieldUpdateOperationsInput>;
  validTill?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type PromoUpdatemethodInput = {
  push?: Maybe<OrderMethod>;
  set?: Maybe<Array<OrderMethod>>;
};

export type PromoUpsertInput = {
  /** What the promo applies to (default: ORDER) */
  appliesTo: PromoAppliesTo;
  appliesToDefinition?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  /** Promo eligibility (default: EVERYONE) */
  eligibility: PromoEligibility;
  eligibilityDefinition?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isAllKitchensAllFacilities: Scalars['Boolean'];
  /** Order method the promo applies to */
  method: Array<OrderMethod>;
  orderCount?: Maybe<Scalars['Int']>;
  promoFacilityRules?: Maybe<Array<Maybe<PromoFacilityRulesInput>>>;
  /** Promo requirements (default: NONE) */
  requirements: PromoRequirement;
  requirementsDefinition?: Maybe<Scalars['Float']>;
  /** Promo status (default: ACTIVE) */
  status: PromoStatus;
  /** Promo type (ex: DISCOUNT_PERCENTAGE) */
  type: PromoType;
  typeDefinition?: Maybe<Scalars['String']>;
  /** Promo usage limit (default: ONCE) */
  usageLimit: PromoUsageLimits;
  usageLimitDefinition?: Maybe<Scalars['String']>;
  /** Contains both start day (Date) and hours (Time) */
  validSince: Scalars['DateTime'];
  /** Contains both end day (Date) and hours (Time) */
  validTill?: Maybe<Scalars['DateTime']>;
};

export type PromoUpsertWithWhereUniqueWithoutOrdersInput = {
  create: PromoCreateWithoutOrdersInput;
  update: PromoUpdateWithoutOrdersInput;
  where: PromoWhereUniqueInput;
};

export type PromoUpsertWithoutPromoFacilityRulesInput = {
  create: PromoCreateWithoutPromoFacilityRulesInput;
  update: PromoUpdateWithoutPromoFacilityRulesInput;
};

export enum PromoUsageLimits {
  DayOfWeek = 'DAY_OF_WEEK',
  NumberOfTimes = 'NUMBER_OF_TIMES',
  Once = 'ONCE',
  OncePerDay = 'ONCE_PER_DAY'
}

export type PromoUsers = {
  __typename?: 'PromoUsers';
  otherUsers: Array<User>;
  selectedUsers: Array<User>;
};

export enum PromoValidationError {
  InvalidPromo = 'INVALID_PROMO',
  PromoBuyXGetYInvalid = 'PROMO_BUY_X_GET_Y_INVALID',
  PromoFreeDeliveryInvalid = 'PROMO_FREE_DELIVERY_INVALID',
  PromoFreeXInvalid = 'PROMO_FREE_X_INVALID',
  PromoIsNotCustomer = 'PROMO_IS_NOT_CUSTOMER',
  PromoIsNotEmployee = 'PROMO_IS_NOT_EMPLOYEE',
  PromoIsNotInSegmentGroup = 'PROMO_IS_NOT_IN_SEGMENT_GROUP',
  PromoNotApplicable = 'PROMO_NOT_APPLICABLE',
  PromoNotYetValid = 'PROMO_NOT_YET_VALID',
  PromoNoMoreValid = 'PROMO_NO_MORE_VALID',
  PromoUnderMinAmount = 'PROMO_UNDER_MIN_AMOUNT',
  PromoUnderMinItems = 'PROMO_UNDER_MIN_ITEMS',
  PromoWasUsed = 'PROMO_WAS_USED',
  PromoWasUsedX = 'PROMO_WAS_USED_X',
  PromoWrongDayOfWeek = 'PROMO_WRONG_DAY_OF_WEEK',
  PromoWrongOrderMethod = 'PROMO_WRONG_ORDER_METHOD',
  TooManyPromos = 'TOO_MANY_PROMOS'
}

export type PromoValidationErrorWithCode = {
  __typename?: 'PromoValidationErrorWithCode';
  error: PromoValidationError;
  promoCode: Scalars['String'];
};

export type PromoWhereInput = {
  AND?: Maybe<Array<PromoWhereInput>>;
  NOT?: Maybe<Array<PromoWhereInput>>;
  OR?: Maybe<Array<PromoWhereInput>>;
  appliesTo?: Maybe<EnumPromoAppliesToFilter>;
  appliesToDefinition?: Maybe<StringNullableFilter>;
  code?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  definition?: Maybe<StringNullableFilter>;
  description?: Maybe<StringNullableFilter>;
  eligibility?: Maybe<EnumPromoEligibilityFilter>;
  eligibilityDefinition?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  isAllKitchensAllFacilities?: Maybe<BoolFilter>;
  method?: Maybe<EnumOrderMethodNullableListFilter>;
  orders?: Maybe<OrderListRelationFilter>;
  promoFacilityRules?: Maybe<PromoFacilityRuleListRelationFilter>;
  requirements?: Maybe<EnumPromoRequirementFilter>;
  requirementsDefinition?: Maybe<FloatNullableFilter>;
  status?: Maybe<EnumPromoStatusFilter>;
  type?: Maybe<EnumPromoTypeFilter>;
  typeDefinition?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  usageLimit?: Maybe<EnumPromoUsageLimitsFilter>;
  usageLimitDefinition?: Maybe<StringNullableFilter>;
  validSince?: Maybe<DateTimeFilter>;
  validTill?: Maybe<DateTimeNullableFilter>;
};

export type PromoWhereUniqueInput = {
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type PromosInput = {
  date?: Maybe<Scalars['DateTime']>;
  filter: PromoFilter;
  pagination: PaginationArg;
  query: Scalars['String'];
};

export type PromosList = {
  __typename?: 'PromosList';
  promos: Array<Promo>;
  total: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  addressAutocompleteOptions: Scalars['String'];
  /** For corporate admin to pull all kitchens from all facilities */
  adminAllKitchens: Array<Kitchen>;
  /** For Crave admin users to view menu schedules of concepts. */
  adminAllMenuSchedules: Array<MenuSchedule>;
  adminAvailablePowerBiReports: Array<AvailablePowerBiReport>;
  adminAvailablePowerBiWorkspaces: Array<AvailablePowerBiWorkspace>;
  adminCookTimesReport: Array<CookTimesReport>;
  adminDailySalesReport: Array<DailySalesReport>;
  adminDrivers: Array<Driver>;
  adminExtras: Array<Extra>;
  adminKitchen: Kitchen;
  adminKitchenMenuItem: KitchenMenuItem;
  adminKitchenMenuItems: Array<KitchenMenuItem>;
  adminKitchenModifier: Extra;
  adminKitchenReport: Array<KitchenReport>;
  adminKitchens: Array<Kitchen>;
  adminMenuCategories: Array<MenuCategory>;
  /** For concepts to view menu schedule detail. */
  adminMenuSchedule?: Maybe<MenuSchedule>;
  /** For concepts to view their menu schedules. */
  adminMenuSchedules: Array<MenuSchedule>;
  adminOperationsAggregatedReport: Array<OperationsAggregatedReport>;
  adminOperationsReport: Array<OperationsReport>;
  adminOrder: Order;
  adminOrderBundles: Array<OrderBundle>;
  /** For admins to view order method override */
  adminOrderMethodOverride?: Maybe<OrderMethodOverride>;
  /** For admins to view order method overrides */
  adminOrderMethodOverrides: Array<OrderMethodOverride>;
  adminOrders: Array<Order>;
  adminPlatformMixReport: Array<PlatformMixReport>;
  adminPowerBiReportsAll: Array<PowerBiReport>;
  adminPowerBiReportsAndToken: PowerBiReportsAndToken;
  adminPromo?: Maybe<Promo>;
  adminPromoBanner?: Maybe<PromoBanner>;
  adminPromoReport: Array<PromoReport>;
  adminPromoUsers: PromoUsers;
  adminPromos: PromosList;
  adminRestaurantOwners: Array<User>;
  adminSegments: Array<Segment>;
  /** For Crave admin users to view special menu schedules. */
  adminSpecialMenuSchedules: Array<MenuSchedule>;
  adminStation: Station;
  adminStations: Array<Station>;
  adminTags: Array<Tag>;
  adminWorkplaces: Array<Workplace>;
  cateringUserAddress: Address;
  customer: User;
  /** For customers to know which dates are available for given order method */
  customerAvailableDates: Array<Scalars['DateTime']>;
  customerCartCosts: CartCosts;
  customerDeliveryInfos: Array<DeliveryInfo>;
  /** For customers to choose from available facilities */
  customerFacilities: Array<Facility>;
  /** For customers to view menu */
  customerFullMenu: Array<CustomerKitchen>;
  customerKitchen: CustomerKitchen;
  customerKitchenMenuItem: KitchenMenuItem;
  /** For customers to view available kitchens */
  customerKitchens: Array<CustomerKitchen>;
  /** @deprecated Regardless of currently selected time */
  customerKitchensAll: Array<CustomerKitchen>;
  /**
   * All kitchens open first, closed second.
   *
   * For CONSUMER platform only (Menu items are filtrated by CA availability)
   */
  customerKitchensAllSortedByOpen: Array<CustomerKitchenByOpen>;
  customerKitchensItemSearch: Array<KitchenMenuItem>;
  /** For customers to view menu */
  customerMenu: Array<CustomerKitchen>;
  /** For customers to display list of categories available within selected time */
  customerMenuCategories: Array<MenuCategory>;
  /** For customers to display list of categories available within selected time */
  customerMenuKitchens: Array<CustomerKitchenInList>;
  customerOrder: Order;
  customerOrders: Array<Order>;
  customerPromoBanner?: Maybe<PromoBanner>;
  /** For customers to view special menu */
  customerSpecialMenu: Array<MenuCategory>;
  /** For customers to view suggested menu */
  customerSuggestedItems: Array<MenuCategory>;
  /**
   * For customers to know which time slots are available for given date and order method.
   *
   * Returned time slots are in UTC
   *
   * @consumer: Time slots are filtrated by menu schedules so only time slots with scheduled menus in the time are returned
   */
  customerTimeslots: Array<Timeslot>;
  customers: CustomersReturnType;
  kdsDoneKitchenTickets: Array<KitchenTicket>;
  kdsDoneOrders: Array<Order>;
  kdsDoneStationTickets: Array<KitchenTicket>;
  kdsFacilityOrders: Array<Order>;
  kdsKitchenTicket: KitchenTicket;
  kdsKitchenTickets: Array<KitchenTicket>;
  kdsOrderBundles: Array<OrderBundle>;
  kdsOrderStatistics: OrderStatistics;
  kdsOrders: Array<Order>;
  kdsStationDoneOrders: Array<Order>;
  kdsStationOrders: Array<Order>;
  kdsStationStatistics: StationStatistics;
  kdsStationTickets: Array<KitchenTicket>;
  kdsTicketStatistics: TicketStatistics;
  kioskCartCosts: CartCosts;
  kioskTags: Array<Tag>;
  me?: Maybe<User>;
  platformUsers: PlatformUsers;
  scheduledNotifications: Scalars['Boolean'];
};


export type QueryAddressAutocompleteOptionsArgs = {
  input: Scalars['String'];
};


export type QueryAdminAllMenuSchedulesArgs = {
  end: Scalars['DateTime'];
  kitchenIds: Array<Scalars['ID']>;
  start: Scalars['DateTime'];
};


export type QueryAdminAvailablePowerBiReportsArgs = {
  workspaceId: Scalars['String'];
};


export type QueryAdminCookTimesReportArgs = {
  endDate?: Maybe<Scalars['DateTime']>;
  facilityId?: Maybe<Scalars['String']>;
  role?: Maybe<UserRole>;
  startDate?: Maybe<Scalars['DateTime']>;
};


export type QueryAdminDailySalesReportArgs = {
  endDate?: Maybe<Scalars['DateTime']>;
  facilityId?: Maybe<Scalars['String']>;
  role?: Maybe<UserRole>;
  startDate?: Maybe<Scalars['DateTime']>;
};


export type QueryAdminExtrasArgs = {
  kitchenId: Scalars['ID'];
};


export type QueryAdminKitchenArgs = {
  kitchenId: Scalars['ID'];
};


export type QueryAdminKitchenMenuItemArgs = {
  id: Scalars['ID'];
};


export type QueryAdminKitchenMenuItemsArgs = {
  activeItems?: Maybe<Scalars['Boolean']>;
  kitchenIds?: Maybe<Array<Scalars['ID']>>;
  menuCategoryType?: Maybe<MenuCategoryType>;
};


export type QueryAdminKitchenModifierArgs = {
  id: Scalars['ID'];
};


export type QueryAdminKitchenReportArgs = {
  endDate?: Maybe<Scalars['DateTime']>;
  facilityId?: Maybe<Scalars['String']>;
  role?: Maybe<UserRole>;
  startDate?: Maybe<Scalars['DateTime']>;
};


export type QueryAdminKitchensArgs = {
  activeKitchens?: Maybe<Scalars['Boolean']>;
};


export type QueryAdminMenuCategoriesArgs = {
  type?: Maybe<MenuCategoryType>;
};


export type QueryAdminMenuScheduleArgs = {
  id: Scalars['ID'];
};


export type QueryAdminMenuSchedulesArgs = {
  end: Scalars['DateTime'];
  kitchenId: Scalars['ID'];
  start: Scalars['DateTime'];
};


export type QueryAdminOperationsAggregatedReportArgs = {
  endDate?: Maybe<Scalars['DateTime']>;
  facilityId?: Maybe<Scalars['String']>;
  role?: Maybe<UserRole>;
  startDate?: Maybe<Scalars['DateTime']>;
};


export type QueryAdminOperationsReportArgs = {
  endDate?: Maybe<Scalars['DateTime']>;
  facilityId?: Maybe<Scalars['String']>;
  role?: Maybe<UserRole>;
  startDate?: Maybe<Scalars['DateTime']>;
};


export type QueryAdminOrderArgs = {
  id: Scalars['ID'];
};


export type QueryAdminOrderBundlesArgs = {
  end: Scalars['DateTime'];
  start: Scalars['DateTime'];
  statuses?: Maybe<Array<Maybe<OrderBundleStatus>>>;
};


export type QueryAdminOrderMethodOverrideArgs = {
  id: Scalars['ID'];
};


export type QueryAdminOrderMethodOverridesArgs = {
  end: Scalars['DateTime'];
  start: Scalars['DateTime'];
};


export type QueryAdminOrdersArgs = {
  bundled?: Maybe<Scalars['Boolean']>;
  customerId?: Maybe<Scalars['ID']>;
  end?: Maybe<Scalars['DateTime']>;
  isCatering?: Maybe<Scalars['Boolean']>;
  method?: Maybe<OrderMethod>;
  start?: Maybe<Scalars['DateTime']>;
};


export type QueryAdminPlatformMixReportArgs = {
  endDate?: Maybe<Scalars['DateTime']>;
  facilityId?: Maybe<Scalars['String']>;
  role?: Maybe<UserRole>;
  startDate?: Maybe<Scalars['DateTime']>;
};


export type QueryAdminPowerBiReportsAllArgs = {
  after?: Maybe<PowerBiReportWhereUniqueInput>;
  before?: Maybe<PowerBiReportWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<QueryPowerBiReportsOrderByInput>>;
};


export type QueryAdminPromoArgs = {
  where: PromoWhereUniqueInput;
};


export type QueryAdminPromoReportArgs = {
  endDate?: Maybe<Scalars['DateTime']>;
  facilityId?: Maybe<Scalars['String']>;
  role?: Maybe<UserRole>;
  startDate?: Maybe<Scalars['DateTime']>;
};


export type QueryAdminPromoUsersArgs = {
  isEmployee: Scalars['Boolean'];
  pagination: PaginationArg;
  query?: Maybe<Scalars['String']>;
  selectedUsers?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type QueryAdminPromosArgs = {
  input: PromosInput;
};


export type QueryAdminSpecialMenuSchedulesArgs = {
  end: Scalars['DateTime'];
  start: Scalars['DateTime'];
};


export type QueryAdminStationArgs = {
  id: Scalars['ID'];
};


export type QueryAdminStationsArgs = {
  where?: Maybe<StationWhereInput>;
};


export type QueryAdminTagsArgs = {
  pagination: PaginationArg;
  query?: Maybe<Scalars['String']>;
};


export type QueryAdminWorkplacesArgs = {
  where?: Maybe<WorkplaceWhereInput>;
};


export type QueryCateringUserAddressArgs = {
  placeId: Scalars['ID'];
};


export type QueryCustomerArgs = {
  id: Scalars['ID'];
};


export type QueryCustomerAvailableDatesArgs = {
  orderMethod: OrderMethod;
};


export type QueryCustomerCartCostsArgs = {
  addressId?: Maybe<Scalars['ID']>;
  orderMethod: OrderMethod;
  placeId?: Maybe<Scalars['String']>;
};


export type QueryCustomerDeliveryInfosArgs = {
  placeId: Scalars['String'];
};


export type QueryCustomerFullMenuArgs = {
  date?: Maybe<Scalars['DateTime']>;
};


export type QueryCustomerKitchenArgs = {
  date?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
};


export type QueryCustomerKitchenMenuItemArgs = {
  id: Scalars['ID'];
};


export type QueryCustomerKitchensArgs = {
  date?: Maybe<Scalars['DateTime']>;
};


export type QueryCustomerKitchensAllArgs = {
  facilityId?: Maybe<Scalars['ID']>;
};


export type QueryCustomerKitchensAllSortedByOpenArgs = {
  date?: Maybe<Scalars['DateTime']>;
};


export type QueryCustomerKitchensItemSearchArgs = {
  searchValue?: Maybe<Scalars['String']>;
};


export type QueryCustomerMenuArgs = {
  categoryId?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  kitchenId?: Maybe<Scalars['String']>;
  orderMethod?: OrderMethod;
  preselectFirstCategory?: Maybe<Scalars['Boolean']>;
};


export type QueryCustomerMenuCategoriesArgs = {
  date?: Maybe<Scalars['DateTime']>;
  kitchenId?: Maybe<Scalars['String']>;
};


export type QueryCustomerMenuKitchensArgs = {
  date?: Maybe<Scalars['DateTime']>;
};


export type QueryCustomerOrderArgs = {
  id: Scalars['ID'];
};


export type QueryCustomerSpecialMenuArgs = {
  date?: Maybe<Scalars['DateTime']>;
};


export type QueryCustomerSuggestedItemsArgs = {
  date?: Maybe<Scalars['DateTime']>;
};


export type QueryCustomerTimeslotsArgs = {
  date: Scalars['DateTime'];
  orderMethod: OrderMethod;
};


export type QueryCustomersArgs = {
  nameFilter: Scalars['String'];
  orderBy: UserOrderByArg;
  pagination: PaginationArg;
};


export type QueryKdsDoneKitchenTicketsArgs = {
  kitchenId: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryKdsDoneOrdersArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  type: KdsOrdersWorkplace;
};


export type QueryKdsDoneStationTicketsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  stationId: Scalars['ID'];
};


export type QueryKdsFacilityOrdersArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  shortId?: Maybe<Scalars['String']>;
};


export type QueryKdsKitchenTicketArgs = {
  id: Scalars['ID'];
};


export type QueryKdsKitchenTicketsArgs = {
  endDate?: Maybe<Scalars['DateTime']>;
  kitchenId: Scalars['ID'];
  startDate?: Maybe<Scalars['DateTime']>;
};


export type QueryKdsOrdersArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  type: KdsOrdersWorkplace;
  workplace?: Maybe<Scalars['Int']>;
};


export type QueryKdsStationDoneOrdersArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryKdsStationOrdersArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  type: KdsStationOrdersWorkplace;
  workplace?: Maybe<Scalars['Int']>;
};


export type QueryKdsStationTicketsArgs = {
  endDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  stationId: Scalars['ID'];
};


export type QueryKioskCartCostsArgs = {
  cartInput?: Maybe<Scalars['String']>;
};


export type QueryKioskTagsArgs = {
  query?: Maybe<Scalars['String']>;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type QueryPowerBiReportsOrderByInput = {
  sortId?: Maybe<SortOrder>;
};

export type RefireKitchenTicketInput = {
  delay: Scalars['Int'];
  kitchenTicketId: Scalars['String'];
  reason?: Maybe<RefireReason>;
};

export type RefireOrderInput = {
  delay: Scalars['Int'];
  orderId: Scalars['String'];
  reason?: Maybe<RefireReason>;
};

export type RefireOrderItemInput = {
  delay: Scalars['Int'];
  facilityItemId: Scalars['String'];
  quantity: Scalars['Int'];
  reason?: Maybe<RefireReason>;
};

export enum RefireReason {
  MissingItem = 'MISSING_ITEM',
  NotFresh = 'NOT_FRESH',
  WrongIngredients = 'WRONG_INGREDIENTS',
  WrongPresentation = 'WRONG_PRESENTATION',
  WrongTemperature = 'WRONG_TEMPERATURE'
}

export enum ScreenType {
  Expo = 'EXPO',
  Foh = 'FOH',
  Kitchen = 'KITCHEN',
  OrderAssignment = 'ORDER_ASSIGNMENT',
  OrderStatus = 'ORDER_STATUS',
  Packager = 'PACKAGER',
  Pickup = 'PICKUP',
  Runner = 'RUNNER',
  Station = 'STATION',
  TableAssignment = 'TABLE_ASSIGNMENT'
}

export type Segment = {
  __typename?: 'Segment';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type SendError = {
  __typename?: 'SendError';
  code: Scalars['String'];
  message: Scalars['String'];
};

export type SendResponse = {
  __typename?: 'SendResponse';
  error?: Maybe<SendError>;
  messageId?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  token?: Maybe<Scalars['String']>;
};

export type SendResponses = {
  __typename?: 'SendResponses';
  failedRegistrationTokens: Array<Scalars['String']>;
  failureCount: Scalars['Int'];
  invalidRegistrationTokens: Array<Scalars['String']>;
  responses: Array<Maybe<SendResponse>>;
  successCount: Scalars['Int'];
};

export type SetupConsumerAppInput = {
  platform: MobilePlatform;
  version: Scalars['String'];
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export enum SortOrderArg {
  Asc = 'asc',
  Desc = 'desc'
}

export type SpecialMenuScheduleUpsertInput = {
  deleteScheduleCategoryIds: Array<Scalars['String']>;
  /** Contains both end day (Date) and hours (Time) */
  end: Scalars['DateTime'];
  id?: Maybe<Scalars['ID']>;
  isActive: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  /** [0 - Sun, 1 - Mon, 2 - Tue, 3 - Wen, 4 - Thu, 5 - Fri, 6 - Sat] (based on the https://www.w3schools.com/jsref/jsref_getday.asp) */
  repeatOnDays: Array<Scalars['Int']>;
  scheduleCategories: Array<MenuScheduleCategoryInput>;
  sortedCategoryIds: Array<Scalars['String']>;
  /** Contains both start day (Date) and hours (Time) */
  start: Scalars['DateTime'];
};

export type Station = {
  __typename?: 'Station';
  id: Scalars['String'];
  kitchen: Scalars['Int'];
  name: Scalars['String'];
};

export type StationCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  facility: FacilityCreateNestedOneWithoutStationsInput;
  id?: Maybe<Scalars['String']>;
  kitchenMenuItems?: Maybe<KitchenMenuItemCreateNestedManyWithoutStationInput>;
  name: Scalars['String'];
  tickets?: Maybe<KitchenTicketCreateNestedManyWithoutStationInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StationCreateManyFacilityInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StationCreateManyFacilityInputEnvelope = {
  data?: Maybe<Array<StationCreateManyFacilityInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StationCreateNestedManyWithoutFacilityInput = {
  connect?: Maybe<Array<StationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StationCreateOrConnectWithoutFacilityInput>>;
  create?: Maybe<Array<StationCreateWithoutFacilityInput>>;
  createMany?: Maybe<StationCreateManyFacilityInputEnvelope>;
};

export type StationCreateNestedOneWithoutKitchenMenuItemsInput = {
  connect?: Maybe<StationWhereUniqueInput>;
  connectOrCreate?: Maybe<StationCreateOrConnectWithoutKitchenMenuItemsInput>;
  create?: Maybe<StationCreateWithoutKitchenMenuItemsInput>;
};

export type StationCreateNestedOneWithoutTicketsInput = {
  connect?: Maybe<StationWhereUniqueInput>;
  connectOrCreate?: Maybe<StationCreateOrConnectWithoutTicketsInput>;
  create?: Maybe<StationCreateWithoutTicketsInput>;
};

export type StationCreateOrConnectWithoutFacilityInput = {
  create: StationCreateWithoutFacilityInput;
  where: StationWhereUniqueInput;
};

export type StationCreateOrConnectWithoutKitchenMenuItemsInput = {
  create: StationCreateWithoutKitchenMenuItemsInput;
  where: StationWhereUniqueInput;
};

export type StationCreateOrConnectWithoutTicketsInput = {
  create: StationCreateWithoutTicketsInput;
  where: StationWhereUniqueInput;
};

export type StationCreateWithoutFacilityInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  kitchenMenuItems?: Maybe<KitchenMenuItemCreateNestedManyWithoutStationInput>;
  name: Scalars['String'];
  tickets?: Maybe<KitchenTicketCreateNestedManyWithoutStationInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StationCreateWithoutKitchenMenuItemsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  facility: FacilityCreateNestedOneWithoutStationsInput;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  tickets?: Maybe<KitchenTicketCreateNestedManyWithoutStationInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StationCreateWithoutTicketsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  facility: FacilityCreateNestedOneWithoutStationsInput;
  id?: Maybe<Scalars['String']>;
  kitchenMenuItems?: Maybe<KitchenMenuItemCreateNestedManyWithoutStationInput>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StationListRelationFilter = {
  every?: Maybe<StationWhereInput>;
  none?: Maybe<StationWhereInput>;
  some?: Maybe<StationWhereInput>;
};

export type StationScalarWhereInput = {
  AND?: Maybe<Array<StationScalarWhereInput>>;
  NOT?: Maybe<Array<StationScalarWhereInput>>;
  OR?: Maybe<Array<StationScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  facilityId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type StationStatistics = {
  __typename?: 'StationStatistics';
  station: Scalars['Int'];
};

export type StationUpdateInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutStationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  kitchenMenuItems?: Maybe<KitchenMenuItemUpdateManyWithoutStationInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  tickets?: Maybe<KitchenTicketUpdateManyWithoutStationInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type StationUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type StationUpdateManyWithWhereWithoutFacilityInput = {
  data: StationUpdateManyMutationInput;
  where: StationScalarWhereInput;
};

export type StationUpdateManyWithoutFacilityInput = {
  connect?: Maybe<Array<StationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StationCreateOrConnectWithoutFacilityInput>>;
  create?: Maybe<Array<StationCreateWithoutFacilityInput>>;
  createMany?: Maybe<StationCreateManyFacilityInputEnvelope>;
  delete?: Maybe<Array<StationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StationScalarWhereInput>>;
  disconnect?: Maybe<Array<StationWhereUniqueInput>>;
  set?: Maybe<Array<StationWhereUniqueInput>>;
  update?: Maybe<Array<StationUpdateWithWhereUniqueWithoutFacilityInput>>;
  updateMany?: Maybe<Array<StationUpdateManyWithWhereWithoutFacilityInput>>;
  upsert?: Maybe<Array<StationUpsertWithWhereUniqueWithoutFacilityInput>>;
};

export type StationUpdateOneWithoutKitchenMenuItemsInput = {
  connect?: Maybe<StationWhereUniqueInput>;
  connectOrCreate?: Maybe<StationCreateOrConnectWithoutKitchenMenuItemsInput>;
  create?: Maybe<StationCreateWithoutKitchenMenuItemsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<StationUpdateWithoutKitchenMenuItemsInput>;
  upsert?: Maybe<StationUpsertWithoutKitchenMenuItemsInput>;
};

export type StationUpdateOneWithoutTicketsInput = {
  connect?: Maybe<StationWhereUniqueInput>;
  connectOrCreate?: Maybe<StationCreateOrConnectWithoutTicketsInput>;
  create?: Maybe<StationCreateWithoutTicketsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<StationUpdateWithoutTicketsInput>;
  upsert?: Maybe<StationUpsertWithoutTicketsInput>;
};

export type StationUpdateWithWhereUniqueWithoutFacilityInput = {
  data: StationUpdateWithoutFacilityInput;
  where: StationWhereUniqueInput;
};

export type StationUpdateWithoutFacilityInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  kitchenMenuItems?: Maybe<KitchenMenuItemUpdateManyWithoutStationInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  tickets?: Maybe<KitchenTicketUpdateManyWithoutStationInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type StationUpdateWithoutKitchenMenuItemsInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutStationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  tickets?: Maybe<KitchenTicketUpdateManyWithoutStationInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type StationUpdateWithoutTicketsInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutStationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  kitchenMenuItems?: Maybe<KitchenMenuItemUpdateManyWithoutStationInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type StationUpsertWithWhereUniqueWithoutFacilityInput = {
  create: StationCreateWithoutFacilityInput;
  update: StationUpdateWithoutFacilityInput;
  where: StationWhereUniqueInput;
};

export type StationUpsertWithoutKitchenMenuItemsInput = {
  create: StationCreateWithoutKitchenMenuItemsInput;
  update: StationUpdateWithoutKitchenMenuItemsInput;
};

export type StationUpsertWithoutTicketsInput = {
  create: StationCreateWithoutTicketsInput;
  update: StationUpdateWithoutTicketsInput;
};

export type StationWhereInput = {
  AND?: Maybe<Array<StationWhereInput>>;
  NOT?: Maybe<Array<StationWhereInput>>;
  OR?: Maybe<Array<StationWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  facility?: Maybe<FacilityWhereInput>;
  facilityId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  kitchenMenuItems?: Maybe<KitchenMenuItemListRelationFilter>;
  name?: Maybe<StringFilter>;
  tickets?: Maybe<KitchenTicketListRelationFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type StationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type StringFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['String']>;
};

export type StringFilter = {
  contains?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  equals?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringFilter>;
  notIn?: Maybe<Array<Scalars['String']>>;
  startsWith?: Maybe<Scalars['String']>;
};

export type StringNullableFilter = {
  contains?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  equals?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringNullableFilter>;
  notIn?: Maybe<Array<Scalars['String']>>;
  startsWith?: Maybe<Scalars['String']>;
};

export type StringNullableListFilter = {
  equals?: Maybe<Array<Scalars['String']>>;
  has?: Maybe<Scalars['String']>;
  hasEvery?: Maybe<Array<Scalars['String']>>;
  hasSome?: Maybe<Array<Scalars['String']>>;
  isEmpty?: Maybe<Scalars['Boolean']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  orderChanged?: Maybe<OrderSubscription>;
  stationTicketChanged?: Maybe<KitchenTicketSubscription>;
  ticketChanged?: Maybe<KitchenTicketSubscription>;
};


export type SubscriptionOrderChangedArgs = {
  endDate?: Maybe<Scalars['DateTime']>;
  screenId?: Maybe<Scalars['String']>;
  screenType?: Maybe<ScreenType>;
  startDate?: Maybe<Scalars['DateTime']>;
  statuses?: Maybe<Array<Maybe<OrderStatus>>>;
  workplace?: Maybe<Scalars['Int']>;
};


export type SubscriptionStationTicketChangedArgs = {
  screenId?: Maybe<Scalars['String']>;
  screenType?: Maybe<ScreenType>;
  stationId?: Maybe<Scalars['ID']>;
};


export type SubscriptionTicketChangedArgs = {
  kitchenId?: Maybe<Scalars['ID']>;
  screenId?: Maybe<Scalars['String']>;
  screenType?: Maybe<ScreenType>;
};

export enum SubscriptionOperation {
  Create = 'create',
  Delete = 'delete',
  DeleteMany = 'deleteMany',
  Update = 'update',
  UpdateMany = 'updateMany',
  Upsert = 'upsert'
}

export type Tag = {
  __typename?: 'Tag';
  customers: Array<UserCustomerInfo>;
  id: Scalars['String'];
  name: Scalars['String'];
  orders: Array<Order>;
};


export type TagCustomersArgs = {
  after?: Maybe<UserCustomerInfoWhereUniqueInput>;
  before?: Maybe<UserCustomerInfoWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type TagOrdersArgs = {
  after?: Maybe<OrderWhereUniqueInput>;
  before?: Maybe<OrderWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type TagCreateNestedManyWithoutCustomersInput = {
  connect?: Maybe<Array<TagWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<TagCreateOrConnectWithoutCustomersInput>>;
  create?: Maybe<Array<TagCreateWithoutCustomersInput>>;
};

export type TagCreateNestedManyWithoutOrdersInput = {
  connect?: Maybe<Array<TagWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<TagCreateOrConnectWithoutOrdersInput>>;
  create?: Maybe<Array<TagCreateWithoutOrdersInput>>;
};

export type TagCreateOrConnectInput = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
};

export type TagCreateOrConnectWithoutCustomersInput = {
  create: TagCreateWithoutCustomersInput;
  where: TagWhereUniqueInput;
};

export type TagCreateOrConnectWithoutOrdersInput = {
  create: TagCreateWithoutOrdersInput;
  where: TagWhereUniqueInput;
};

export type TagCreateWithoutCustomersInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  orders?: Maybe<OrderCreateNestedManyWithoutTagsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TagCreateWithoutOrdersInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  customers?: Maybe<UserCustomerInfoCreateNestedManyWithoutTagsInput>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TagListRelationFilter = {
  every?: Maybe<TagWhereInput>;
  none?: Maybe<TagWhereInput>;
  some?: Maybe<TagWhereInput>;
};

export type TagScalarWhereInput = {
  AND?: Maybe<Array<TagScalarWhereInput>>;
  NOT?: Maybe<Array<TagScalarWhereInput>>;
  OR?: Maybe<Array<TagScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type TagUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type TagUpdateManyWithWhereWithoutCustomersInput = {
  data: TagUpdateManyMutationInput;
  where: TagScalarWhereInput;
};

export type TagUpdateManyWithWhereWithoutOrdersInput = {
  data: TagUpdateManyMutationInput;
  where: TagScalarWhereInput;
};

export type TagUpdateManyWithoutCustomersInput = {
  connect?: Maybe<Array<TagWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<TagCreateOrConnectWithoutCustomersInput>>;
  create?: Maybe<Array<TagCreateWithoutCustomersInput>>;
  delete?: Maybe<Array<TagWhereUniqueInput>>;
  deleteMany?: Maybe<Array<TagScalarWhereInput>>;
  disconnect?: Maybe<Array<TagWhereUniqueInput>>;
  set?: Maybe<Array<TagWhereUniqueInput>>;
  update?: Maybe<Array<TagUpdateWithWhereUniqueWithoutCustomersInput>>;
  updateMany?: Maybe<Array<TagUpdateManyWithWhereWithoutCustomersInput>>;
  upsert?: Maybe<Array<TagUpsertWithWhereUniqueWithoutCustomersInput>>;
};

export type TagUpdateManyWithoutOrdersInput = {
  connect?: Maybe<Array<TagWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<TagCreateOrConnectWithoutOrdersInput>>;
  create?: Maybe<Array<TagCreateWithoutOrdersInput>>;
  delete?: Maybe<Array<TagWhereUniqueInput>>;
  deleteMany?: Maybe<Array<TagScalarWhereInput>>;
  disconnect?: Maybe<Array<TagWhereUniqueInput>>;
  set?: Maybe<Array<TagWhereUniqueInput>>;
  update?: Maybe<Array<TagUpdateWithWhereUniqueWithoutOrdersInput>>;
  updateMany?: Maybe<Array<TagUpdateManyWithWhereWithoutOrdersInput>>;
  upsert?: Maybe<Array<TagUpsertWithWhereUniqueWithoutOrdersInput>>;
};

export type TagUpdateWithWhereUniqueWithoutCustomersInput = {
  data: TagUpdateWithoutCustomersInput;
  where: TagWhereUniqueInput;
};

export type TagUpdateWithWhereUniqueWithoutOrdersInput = {
  data: TagUpdateWithoutOrdersInput;
  where: TagWhereUniqueInput;
};

export type TagUpdateWithoutCustomersInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  orders?: Maybe<OrderUpdateManyWithoutTagsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type TagUpdateWithoutOrdersInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customers?: Maybe<UserCustomerInfoUpdateManyWithoutTagsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type TagUpsertWithWhereUniqueWithoutCustomersInput = {
  create: TagCreateWithoutCustomersInput;
  update: TagUpdateWithoutCustomersInput;
  where: TagWhereUniqueInput;
};

export type TagUpsertWithWhereUniqueWithoutOrdersInput = {
  create: TagCreateWithoutOrdersInput;
  update: TagUpdateWithoutOrdersInput;
  where: TagWhereUniqueInput;
};

export type TagWhereInput = {
  AND?: Maybe<Array<TagWhereInput>>;
  NOT?: Maybe<Array<TagWhereInput>>;
  OR?: Maybe<Array<TagWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  customers?: Maybe<UserCustomerInfoListRelationFilter>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  orders?: Maybe<OrderListRelationFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type TagWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type TicketStatistics = {
  __typename?: 'TicketStatistics';
  kitchen: Scalars['Int'];
};

export type Timeslot = {
  __typename?: 'Timeslot';
  end: Scalars['DateTime'];
  start: Scalars['DateTime'];
};

export type UndoOrderInput = {
  orderId: Scalars['String'];
};

export type UndoTicketInput = {
  ticketId: Scalars['String'];
};

export type UpdateDeliveryInput = {
  deliveredAt: Scalars['DateTime'];
  orderId: Scalars['String'];
};

export type UpdateOrderPreparingUntilInput = {
  orderId: Scalars['String'];
  preparingUntil: Scalars['DateTime'];
};

export type UpsertCartItemInput = {
  cartItemId?: Maybe<Scalars['ID']>;
  extraItems?: Maybe<Array<Maybe<CartExtraItemInput>>>;
  kitchenMenuItemId: Scalars['ID'];
  quantity?: Maybe<Scalars['Int']>;
};

export type User = {
  __typename?: 'User';
  createdAt: Scalars['DateTime'];
  customerInfo?: Maybe<UserCustomerInfo>;
  devices: Array<Device>;
  email?: Maybe<Scalars['String']>;
  facilities: Array<Facility>;
  id: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  kitchens: Array<Kitchen>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  roles: Array<UserRole>;
};


export type UserDevicesArgs = {
  after?: Maybe<DeviceWhereUniqueInput>;
  before?: Maybe<DeviceWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type UserFacilitiesArgs = {
  after?: Maybe<FacilityWhereUniqueInput>;
  before?: Maybe<FacilityWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type UserKitchensArgs = {
  after?: Maybe<KitchenWhereUniqueInput>;
  before?: Maybe<KitchenWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type UserCreateInput = {
  address?: Maybe<AddressCreateInput>;
  browserNotifications?: Maybe<Scalars['Boolean']>;
  businessName?: Maybe<Scalars['String']>;
  businessType?: Maybe<BusinessType>;
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  phone: Scalars['String'];
};

export type UserCreateNestedManyWithoutFacilitiesInput = {
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutFacilitiesInput>>;
  create?: Maybe<Array<UserCreateWithoutFacilitiesInput>>;
};

export type UserCreateNestedManyWithoutKitchensInput = {
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutKitchensInput>>;
  create?: Maybe<Array<UserCreateWithoutKitchensInput>>;
};

export type UserCreateNestedOneWithoutCustomerInfoInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutCustomerInfoInput>;
  create?: Maybe<UserCreateWithoutCustomerInfoInput>;
};

export type UserCreateOrConnectWithoutCustomerInfoInput = {
  create: UserCreateWithoutCustomerInfoInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutFacilitiesInput = {
  create: UserCreateWithoutFacilitiesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutKitchensInput = {
  create: UserCreateWithoutKitchensInput;
  where: UserWhereUniqueInput;
};

export type UserCreateWithoutCustomerInfoInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  devices?: Maybe<DeviceCreateNestedManyWithoutUserInput>;
  email?: Maybe<Scalars['String']>;
  facilities?: Maybe<FacilityCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  kitchens?: Maybe<KitchenCreateNestedManyWithoutUsersInput>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  roles?: Maybe<UserCreaterolesInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserCreateWithoutFacilitiesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  customerInfo?: Maybe<UserCustomerInfoCreateNestedOneWithoutUserInput>;
  devices?: Maybe<DeviceCreateNestedManyWithoutUserInput>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  kitchens?: Maybe<KitchenCreateNestedManyWithoutUsersInput>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  roles?: Maybe<UserCreaterolesInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserCreateWithoutKitchensInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  customerInfo?: Maybe<UserCustomerInfoCreateNestedOneWithoutUserInput>;
  devices?: Maybe<DeviceCreateNestedManyWithoutUserInput>;
  email?: Maybe<Scalars['String']>;
  facilities?: Maybe<FacilityCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  roles?: Maybe<UserCreaterolesInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserCreaterolesInput = {
  set?: Maybe<Array<UserRole>>;
};

export type UserCustomerInfo = {
  __typename?: 'UserCustomerInfo';
  addresses: Array<Address>;
  businessName?: Maybe<Scalars['String']>;
  businessType?: Maybe<BusinessType>;
  cart: Cart;
  customerNotes: Array<Scalars['String']>;
  defaultAddressId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  internalNotes: Array<Scalars['String']>;
  isAllowedToPayByInvoice: Scalars['Boolean'];
  isEmployee: Scalars['Boolean'];
  orders: Array<Order>;
  ordersCount: Scalars['Int'];
  shouldDisplayRating: Scalars['Boolean'];
  tags: Array<Tag>;
  user?: Maybe<User>;
  vip: Scalars['Boolean'];
};


export type UserCustomerInfoAddressesArgs = {
  after?: Maybe<AddressWhereUniqueInput>;
  before?: Maybe<AddressWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type UserCustomerInfoOrdersArgs = {
  after?: Maybe<OrderWhereUniqueInput>;
  before?: Maybe<OrderWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type UserCustomerInfoTagsArgs = {
  after?: Maybe<TagWhereUniqueInput>;
  before?: Maybe<TagWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type UserCustomerInfoCreateManyCartInput = {
  braintreeCustomerId?: Maybe<Scalars['String']>;
  businessName?: Maybe<Scalars['String']>;
  businessType?: Maybe<BusinessType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerNotes?: Maybe<UserCustomerInfoCreateManycustomerNotesInput>;
  defaultAddressId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  imgUrl?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<UserCustomerInfoCreateManyinternalNotesInput>;
  isAllowedToPayByInvoice?: Maybe<Scalars['Boolean']>;
  isEmployee?: Maybe<Scalars['Boolean']>;
  ordersCount?: Maybe<Scalars['Int']>;
  shouldDisplayRating?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  vip?: Maybe<Scalars['Boolean']>;
};

export type UserCustomerInfoCreateManyCartInputEnvelope = {
  data?: Maybe<Array<UserCustomerInfoCreateManyCartInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type UserCustomerInfoCreateManycustomerNotesInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type UserCustomerInfoCreateManyinternalNotesInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type UserCustomerInfoCreateNestedManyWithoutCartInput = {
  connect?: Maybe<Array<UserCustomerInfoWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserCustomerInfoCreateOrConnectWithoutCartInput>>;
  create?: Maybe<Array<UserCustomerInfoCreateWithoutCartInput>>;
  createMany?: Maybe<UserCustomerInfoCreateManyCartInputEnvelope>;
};

export type UserCustomerInfoCreateNestedManyWithoutTagsInput = {
  connect?: Maybe<Array<UserCustomerInfoWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserCustomerInfoCreateOrConnectWithoutTagsInput>>;
  create?: Maybe<Array<UserCustomerInfoCreateWithoutTagsInput>>;
};

export type UserCustomerInfoCreateNestedOneWithoutAddressesInput = {
  connect?: Maybe<UserCustomerInfoWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCustomerInfoCreateOrConnectWithoutAddressesInput>;
  create?: Maybe<UserCustomerInfoCreateWithoutAddressesInput>;
};

export type UserCustomerInfoCreateNestedOneWithoutOrdersInput = {
  connect?: Maybe<UserCustomerInfoWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCustomerInfoCreateOrConnectWithoutOrdersInput>;
  create?: Maybe<UserCustomerInfoCreateWithoutOrdersInput>;
};

export type UserCustomerInfoCreateNestedOneWithoutUserInput = {
  connect?: Maybe<UserCustomerInfoWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCustomerInfoCreateOrConnectWithoutUserInput>;
  create?: Maybe<UserCustomerInfoCreateWithoutUserInput>;
};

export type UserCustomerInfoCreateOrConnectWithoutAddressesInput = {
  create: UserCustomerInfoCreateWithoutAddressesInput;
  where: UserCustomerInfoWhereUniqueInput;
};

export type UserCustomerInfoCreateOrConnectWithoutCartInput = {
  create: UserCustomerInfoCreateWithoutCartInput;
  where: UserCustomerInfoWhereUniqueInput;
};

export type UserCustomerInfoCreateOrConnectWithoutOrdersInput = {
  create: UserCustomerInfoCreateWithoutOrdersInput;
  where: UserCustomerInfoWhereUniqueInput;
};

export type UserCustomerInfoCreateOrConnectWithoutTagsInput = {
  create: UserCustomerInfoCreateWithoutTagsInput;
  where: UserCustomerInfoWhereUniqueInput;
};

export type UserCustomerInfoCreateOrConnectWithoutUserInput = {
  create: UserCustomerInfoCreateWithoutUserInput;
  where: UserCustomerInfoWhereUniqueInput;
};

export type UserCustomerInfoCreateWithoutAddressesInput = {
  braintreeCustomerId?: Maybe<Scalars['String']>;
  businessName?: Maybe<Scalars['String']>;
  businessType?: Maybe<BusinessType>;
  cart: CartCreateNestedOneWithoutUserCustomerInfoInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerNotes?: Maybe<UserCustomerInfoCreatecustomerNotesInput>;
  defaultAddressId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  imgUrl?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<UserCustomerInfoCreateinternalNotesInput>;
  isAllowedToPayByInvoice?: Maybe<Scalars['Boolean']>;
  isEmployee?: Maybe<Scalars['Boolean']>;
  orders?: Maybe<OrderCreateNestedManyWithoutCustomerInput>;
  ordersCount?: Maybe<Scalars['Int']>;
  ratings?: Maybe<CustomerRatingCreateNestedManyWithoutCustomerInput>;
  shouldDisplayRating?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<TagCreateNestedManyWithoutCustomersInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutCustomerInfoInput>;
  vip?: Maybe<Scalars['Boolean']>;
};

export type UserCustomerInfoCreateWithoutCartInput = {
  addresses?: Maybe<AddressCreateNestedManyWithoutCustomerInfoInput>;
  braintreeCustomerId?: Maybe<Scalars['String']>;
  businessName?: Maybe<Scalars['String']>;
  businessType?: Maybe<BusinessType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerNotes?: Maybe<UserCustomerInfoCreatecustomerNotesInput>;
  defaultAddressId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  imgUrl?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<UserCustomerInfoCreateinternalNotesInput>;
  isAllowedToPayByInvoice?: Maybe<Scalars['Boolean']>;
  isEmployee?: Maybe<Scalars['Boolean']>;
  orders?: Maybe<OrderCreateNestedManyWithoutCustomerInput>;
  ordersCount?: Maybe<Scalars['Int']>;
  ratings?: Maybe<CustomerRatingCreateNestedManyWithoutCustomerInput>;
  shouldDisplayRating?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<TagCreateNestedManyWithoutCustomersInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutCustomerInfoInput>;
  vip?: Maybe<Scalars['Boolean']>;
};

export type UserCustomerInfoCreateWithoutOrdersInput = {
  addresses?: Maybe<AddressCreateNestedManyWithoutCustomerInfoInput>;
  braintreeCustomerId?: Maybe<Scalars['String']>;
  businessName?: Maybe<Scalars['String']>;
  businessType?: Maybe<BusinessType>;
  cart: CartCreateNestedOneWithoutUserCustomerInfoInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerNotes?: Maybe<UserCustomerInfoCreatecustomerNotesInput>;
  defaultAddressId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  imgUrl?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<UserCustomerInfoCreateinternalNotesInput>;
  isAllowedToPayByInvoice?: Maybe<Scalars['Boolean']>;
  isEmployee?: Maybe<Scalars['Boolean']>;
  ordersCount?: Maybe<Scalars['Int']>;
  ratings?: Maybe<CustomerRatingCreateNestedManyWithoutCustomerInput>;
  shouldDisplayRating?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<TagCreateNestedManyWithoutCustomersInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutCustomerInfoInput>;
  vip?: Maybe<Scalars['Boolean']>;
};

export type UserCustomerInfoCreateWithoutTagsInput = {
  addresses?: Maybe<AddressCreateNestedManyWithoutCustomerInfoInput>;
  braintreeCustomerId?: Maybe<Scalars['String']>;
  businessName?: Maybe<Scalars['String']>;
  businessType?: Maybe<BusinessType>;
  cart: CartCreateNestedOneWithoutUserCustomerInfoInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerNotes?: Maybe<UserCustomerInfoCreatecustomerNotesInput>;
  defaultAddressId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  imgUrl?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<UserCustomerInfoCreateinternalNotesInput>;
  isAllowedToPayByInvoice?: Maybe<Scalars['Boolean']>;
  isEmployee?: Maybe<Scalars['Boolean']>;
  orders?: Maybe<OrderCreateNestedManyWithoutCustomerInput>;
  ordersCount?: Maybe<Scalars['Int']>;
  ratings?: Maybe<CustomerRatingCreateNestedManyWithoutCustomerInput>;
  shouldDisplayRating?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutCustomerInfoInput>;
  vip?: Maybe<Scalars['Boolean']>;
};

export type UserCustomerInfoCreateWithoutUserInput = {
  addresses?: Maybe<AddressCreateNestedManyWithoutCustomerInfoInput>;
  braintreeCustomerId?: Maybe<Scalars['String']>;
  businessName?: Maybe<Scalars['String']>;
  businessType?: Maybe<BusinessType>;
  cart: CartCreateNestedOneWithoutUserCustomerInfoInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerNotes?: Maybe<UserCustomerInfoCreatecustomerNotesInput>;
  defaultAddressId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  imgUrl?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<UserCustomerInfoCreateinternalNotesInput>;
  isAllowedToPayByInvoice?: Maybe<Scalars['Boolean']>;
  isEmployee?: Maybe<Scalars['Boolean']>;
  orders?: Maybe<OrderCreateNestedManyWithoutCustomerInput>;
  ordersCount?: Maybe<Scalars['Int']>;
  ratings?: Maybe<CustomerRatingCreateNestedManyWithoutCustomerInput>;
  shouldDisplayRating?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<TagCreateNestedManyWithoutCustomersInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vip?: Maybe<Scalars['Boolean']>;
};

export type UserCustomerInfoCreatecustomerNotesInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type UserCustomerInfoCreateinternalNotesInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type UserCustomerInfoListRelationFilter = {
  every?: Maybe<UserCustomerInfoWhereInput>;
  none?: Maybe<UserCustomerInfoWhereInput>;
  some?: Maybe<UserCustomerInfoWhereInput>;
};

export type UserCustomerInfoScalarWhereInput = {
  AND?: Maybe<Array<UserCustomerInfoScalarWhereInput>>;
  NOT?: Maybe<Array<UserCustomerInfoScalarWhereInput>>;
  OR?: Maybe<Array<UserCustomerInfoScalarWhereInput>>;
  braintreeCustomerId?: Maybe<StringNullableFilter>;
  businessName?: Maybe<StringNullableFilter>;
  businessType?: Maybe<EnumBusinessTypeNullableFilter>;
  cartId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  customerNotes?: Maybe<StringNullableListFilter>;
  defaultAddressId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  imgUrl?: Maybe<StringNullableFilter>;
  internalNotes?: Maybe<StringNullableListFilter>;
  isAllowedToPayByInvoice?: Maybe<BoolFilter>;
  isEmployee?: Maybe<BoolFilter>;
  ordersCount?: Maybe<IntFilter>;
  shouldDisplayRating?: Maybe<BoolFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  userId?: Maybe<StringNullableFilter>;
  vip?: Maybe<BoolFilter>;
};

export type UserCustomerInfoUpdateManyMutationInput = {
  braintreeCustomerId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  businessName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  businessType?: Maybe<NullableEnumBusinessTypeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customerNotes?: Maybe<UserCustomerInfoUpdatecustomerNotesInput>;
  defaultAddressId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  imgUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  internalNotes?: Maybe<UserCustomerInfoUpdateinternalNotesInput>;
  isAllowedToPayByInvoice?: Maybe<BoolFieldUpdateOperationsInput>;
  isEmployee?: Maybe<BoolFieldUpdateOperationsInput>;
  ordersCount?: Maybe<IntFieldUpdateOperationsInput>;
  shouldDisplayRating?: Maybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  vip?: Maybe<BoolFieldUpdateOperationsInput>;
};

export type UserCustomerInfoUpdateManyWithWhereWithoutCartInput = {
  data: UserCustomerInfoUpdateManyMutationInput;
  where: UserCustomerInfoScalarWhereInput;
};

export type UserCustomerInfoUpdateManyWithWhereWithoutTagsInput = {
  data: UserCustomerInfoUpdateManyMutationInput;
  where: UserCustomerInfoScalarWhereInput;
};

export type UserCustomerInfoUpdateManyWithoutCartInput = {
  connect?: Maybe<Array<UserCustomerInfoWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserCustomerInfoCreateOrConnectWithoutCartInput>>;
  create?: Maybe<Array<UserCustomerInfoCreateWithoutCartInput>>;
  createMany?: Maybe<UserCustomerInfoCreateManyCartInputEnvelope>;
  delete?: Maybe<Array<UserCustomerInfoWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UserCustomerInfoScalarWhereInput>>;
  disconnect?: Maybe<Array<UserCustomerInfoWhereUniqueInput>>;
  set?: Maybe<Array<UserCustomerInfoWhereUniqueInput>>;
  update?: Maybe<Array<UserCustomerInfoUpdateWithWhereUniqueWithoutCartInput>>;
  updateMany?: Maybe<Array<UserCustomerInfoUpdateManyWithWhereWithoutCartInput>>;
  upsert?: Maybe<Array<UserCustomerInfoUpsertWithWhereUniqueWithoutCartInput>>;
};

export type UserCustomerInfoUpdateManyWithoutTagsInput = {
  connect?: Maybe<Array<UserCustomerInfoWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserCustomerInfoCreateOrConnectWithoutTagsInput>>;
  create?: Maybe<Array<UserCustomerInfoCreateWithoutTagsInput>>;
  delete?: Maybe<Array<UserCustomerInfoWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UserCustomerInfoScalarWhereInput>>;
  disconnect?: Maybe<Array<UserCustomerInfoWhereUniqueInput>>;
  set?: Maybe<Array<UserCustomerInfoWhereUniqueInput>>;
  update?: Maybe<Array<UserCustomerInfoUpdateWithWhereUniqueWithoutTagsInput>>;
  updateMany?: Maybe<Array<UserCustomerInfoUpdateManyWithWhereWithoutTagsInput>>;
  upsert?: Maybe<Array<UserCustomerInfoUpsertWithWhereUniqueWithoutTagsInput>>;
};

export type UserCustomerInfoUpdateOneRequiredWithoutAddressesInput = {
  connect?: Maybe<UserCustomerInfoWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCustomerInfoCreateOrConnectWithoutAddressesInput>;
  create?: Maybe<UserCustomerInfoCreateWithoutAddressesInput>;
  update?: Maybe<UserCustomerInfoUpdateWithoutAddressesInput>;
  upsert?: Maybe<UserCustomerInfoUpsertWithoutAddressesInput>;
};

export type UserCustomerInfoUpdateOneRequiredWithoutOrdersInput = {
  connect?: Maybe<UserCustomerInfoWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCustomerInfoCreateOrConnectWithoutOrdersInput>;
  create?: Maybe<UserCustomerInfoCreateWithoutOrdersInput>;
  update?: Maybe<UserCustomerInfoUpdateWithoutOrdersInput>;
  upsert?: Maybe<UserCustomerInfoUpsertWithoutOrdersInput>;
};

export type UserCustomerInfoUpdateOneWithoutUserInput = {
  connect?: Maybe<UserCustomerInfoWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCustomerInfoCreateOrConnectWithoutUserInput>;
  create?: Maybe<UserCustomerInfoCreateWithoutUserInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserCustomerInfoUpdateWithoutUserInput>;
  upsert?: Maybe<UserCustomerInfoUpsertWithoutUserInput>;
};

export type UserCustomerInfoUpdateWithWhereUniqueWithoutCartInput = {
  data: UserCustomerInfoUpdateWithoutCartInput;
  where: UserCustomerInfoWhereUniqueInput;
};

export type UserCustomerInfoUpdateWithWhereUniqueWithoutTagsInput = {
  data: UserCustomerInfoUpdateWithoutTagsInput;
  where: UserCustomerInfoWhereUniqueInput;
};

export type UserCustomerInfoUpdateWithoutAddressesInput = {
  braintreeCustomerId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  businessName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  businessType?: Maybe<NullableEnumBusinessTypeFieldUpdateOperationsInput>;
  cart?: Maybe<CartUpdateOneRequiredWithoutUserCustomerInfoInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customerNotes?: Maybe<UserCustomerInfoUpdatecustomerNotesInput>;
  defaultAddressId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  imgUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  internalNotes?: Maybe<UserCustomerInfoUpdateinternalNotesInput>;
  isAllowedToPayByInvoice?: Maybe<BoolFieldUpdateOperationsInput>;
  isEmployee?: Maybe<BoolFieldUpdateOperationsInput>;
  orders?: Maybe<OrderUpdateManyWithoutCustomerInput>;
  ordersCount?: Maybe<IntFieldUpdateOperationsInput>;
  ratings?: Maybe<CustomerRatingUpdateManyWithoutCustomerInput>;
  shouldDisplayRating?: Maybe<BoolFieldUpdateOperationsInput>;
  tags?: Maybe<TagUpdateManyWithoutCustomersInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutCustomerInfoInput>;
  vip?: Maybe<BoolFieldUpdateOperationsInput>;
};

export type UserCustomerInfoUpdateWithoutCartInput = {
  addresses?: Maybe<AddressUpdateManyWithoutCustomerInfoInput>;
  braintreeCustomerId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  businessName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  businessType?: Maybe<NullableEnumBusinessTypeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customerNotes?: Maybe<UserCustomerInfoUpdatecustomerNotesInput>;
  defaultAddressId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  imgUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  internalNotes?: Maybe<UserCustomerInfoUpdateinternalNotesInput>;
  isAllowedToPayByInvoice?: Maybe<BoolFieldUpdateOperationsInput>;
  isEmployee?: Maybe<BoolFieldUpdateOperationsInput>;
  orders?: Maybe<OrderUpdateManyWithoutCustomerInput>;
  ordersCount?: Maybe<IntFieldUpdateOperationsInput>;
  ratings?: Maybe<CustomerRatingUpdateManyWithoutCustomerInput>;
  shouldDisplayRating?: Maybe<BoolFieldUpdateOperationsInput>;
  tags?: Maybe<TagUpdateManyWithoutCustomersInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutCustomerInfoInput>;
  vip?: Maybe<BoolFieldUpdateOperationsInput>;
};

export type UserCustomerInfoUpdateWithoutOrdersInput = {
  addresses?: Maybe<AddressUpdateManyWithoutCustomerInfoInput>;
  braintreeCustomerId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  businessName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  businessType?: Maybe<NullableEnumBusinessTypeFieldUpdateOperationsInput>;
  cart?: Maybe<CartUpdateOneRequiredWithoutUserCustomerInfoInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customerNotes?: Maybe<UserCustomerInfoUpdatecustomerNotesInput>;
  defaultAddressId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  imgUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  internalNotes?: Maybe<UserCustomerInfoUpdateinternalNotesInput>;
  isAllowedToPayByInvoice?: Maybe<BoolFieldUpdateOperationsInput>;
  isEmployee?: Maybe<BoolFieldUpdateOperationsInput>;
  ordersCount?: Maybe<IntFieldUpdateOperationsInput>;
  ratings?: Maybe<CustomerRatingUpdateManyWithoutCustomerInput>;
  shouldDisplayRating?: Maybe<BoolFieldUpdateOperationsInput>;
  tags?: Maybe<TagUpdateManyWithoutCustomersInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutCustomerInfoInput>;
  vip?: Maybe<BoolFieldUpdateOperationsInput>;
};

export type UserCustomerInfoUpdateWithoutTagsInput = {
  addresses?: Maybe<AddressUpdateManyWithoutCustomerInfoInput>;
  braintreeCustomerId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  businessName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  businessType?: Maybe<NullableEnumBusinessTypeFieldUpdateOperationsInput>;
  cart?: Maybe<CartUpdateOneRequiredWithoutUserCustomerInfoInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customerNotes?: Maybe<UserCustomerInfoUpdatecustomerNotesInput>;
  defaultAddressId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  imgUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  internalNotes?: Maybe<UserCustomerInfoUpdateinternalNotesInput>;
  isAllowedToPayByInvoice?: Maybe<BoolFieldUpdateOperationsInput>;
  isEmployee?: Maybe<BoolFieldUpdateOperationsInput>;
  orders?: Maybe<OrderUpdateManyWithoutCustomerInput>;
  ordersCount?: Maybe<IntFieldUpdateOperationsInput>;
  ratings?: Maybe<CustomerRatingUpdateManyWithoutCustomerInput>;
  shouldDisplayRating?: Maybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutCustomerInfoInput>;
  vip?: Maybe<BoolFieldUpdateOperationsInput>;
};

export type UserCustomerInfoUpdateWithoutUserInput = {
  addresses?: Maybe<AddressUpdateManyWithoutCustomerInfoInput>;
  braintreeCustomerId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  businessName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  businessType?: Maybe<NullableEnumBusinessTypeFieldUpdateOperationsInput>;
  cart?: Maybe<CartUpdateOneRequiredWithoutUserCustomerInfoInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customerNotes?: Maybe<UserCustomerInfoUpdatecustomerNotesInput>;
  defaultAddressId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  imgUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  internalNotes?: Maybe<UserCustomerInfoUpdateinternalNotesInput>;
  isAllowedToPayByInvoice?: Maybe<BoolFieldUpdateOperationsInput>;
  isEmployee?: Maybe<BoolFieldUpdateOperationsInput>;
  orders?: Maybe<OrderUpdateManyWithoutCustomerInput>;
  ordersCount?: Maybe<IntFieldUpdateOperationsInput>;
  ratings?: Maybe<CustomerRatingUpdateManyWithoutCustomerInput>;
  shouldDisplayRating?: Maybe<BoolFieldUpdateOperationsInput>;
  tags?: Maybe<TagUpdateManyWithoutCustomersInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  vip?: Maybe<BoolFieldUpdateOperationsInput>;
};

export type UserCustomerInfoUpdatecustomerNotesInput = {
  push?: Maybe<Scalars['String']>;
  set?: Maybe<Array<Scalars['String']>>;
};

export type UserCustomerInfoUpdateinternalNotesInput = {
  push?: Maybe<Scalars['String']>;
  set?: Maybe<Array<Scalars['String']>>;
};

export type UserCustomerInfoUpsertWithWhereUniqueWithoutCartInput = {
  create: UserCustomerInfoCreateWithoutCartInput;
  update: UserCustomerInfoUpdateWithoutCartInput;
  where: UserCustomerInfoWhereUniqueInput;
};

export type UserCustomerInfoUpsertWithWhereUniqueWithoutTagsInput = {
  create: UserCustomerInfoCreateWithoutTagsInput;
  update: UserCustomerInfoUpdateWithoutTagsInput;
  where: UserCustomerInfoWhereUniqueInput;
};

export type UserCustomerInfoUpsertWithoutAddressesInput = {
  create: UserCustomerInfoCreateWithoutAddressesInput;
  update: UserCustomerInfoUpdateWithoutAddressesInput;
};

export type UserCustomerInfoUpsertWithoutOrdersInput = {
  create: UserCustomerInfoCreateWithoutOrdersInput;
  update: UserCustomerInfoUpdateWithoutOrdersInput;
};

export type UserCustomerInfoUpsertWithoutUserInput = {
  create: UserCustomerInfoCreateWithoutUserInput;
  update: UserCustomerInfoUpdateWithoutUserInput;
};

export type UserCustomerInfoWhereInput = {
  AND?: Maybe<Array<UserCustomerInfoWhereInput>>;
  NOT?: Maybe<Array<UserCustomerInfoWhereInput>>;
  OR?: Maybe<Array<UserCustomerInfoWhereInput>>;
  addresses?: Maybe<AddressListRelationFilter>;
  braintreeCustomerId?: Maybe<StringNullableFilter>;
  businessName?: Maybe<StringNullableFilter>;
  businessType?: Maybe<EnumBusinessTypeNullableFilter>;
  cart?: Maybe<CartWhereInput>;
  cartId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  customerNotes?: Maybe<StringNullableListFilter>;
  defaultAddressId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  imgUrl?: Maybe<StringNullableFilter>;
  internalNotes?: Maybe<StringNullableListFilter>;
  isAllowedToPayByInvoice?: Maybe<BoolFilter>;
  isEmployee?: Maybe<BoolFilter>;
  orders?: Maybe<OrderListRelationFilter>;
  ordersCount?: Maybe<IntFilter>;
  ratings?: Maybe<CustomerRatingListRelationFilter>;
  shouldDisplayRating?: Maybe<BoolFilter>;
  tags?: Maybe<TagListRelationFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  user?: Maybe<UserWhereInput>;
  userId?: Maybe<StringNullableFilter>;
  vip?: Maybe<BoolFilter>;
};

export type UserCustomerInfoWhereUniqueInput = {
  defaultAddressId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type UserListRelationFilter = {
  every?: Maybe<UserWhereInput>;
  none?: Maybe<UserWhereInput>;
  some?: Maybe<UserWhereInput>;
};

export type UserOrderByArg = {
  column: UserOrderByEnum;
  order: SortOrderArg;
};

export enum UserOrderByEnum {
  CreatedAt = 'createdAt',
  Name = 'name',
  Roles = 'roles'
}

export enum UserRole {
  CateringCustomer = 'CATERING_CUSTOMER',
  CorporateAdmin = 'CORPORATE_ADMIN',
  CraveEmployee = 'CRAVE_EMPLOYEE',
  Customer = 'CUSTOMER',
  CustomerSupport = 'CUSTOMER_SUPPORT',
  FacilityAdmin = 'FACILITY_ADMIN',
  KioskCustomer = 'KIOSK_CUSTOMER',
  RestaurantEmployee = 'RESTAURANT_EMPLOYEE',
  RestaurantOwner = 'RESTAURANT_OWNER'
}

export type UserScalarWhereInput = {
  AND?: Maybe<Array<UserScalarWhereInput>>;
  NOT?: Maybe<Array<UserScalarWhereInput>>;
  OR?: Maybe<Array<UserScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  email?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  isDeleted?: Maybe<BoolFilter>;
  name?: Maybe<StringNullableFilter>;
  phone?: Maybe<StringNullableFilter>;
  roles?: Maybe<EnumUserRoleNullableListFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type UserUpdateInput = {
  businessName?: Maybe<Scalars['String']>;
  businessType?: Maybe<BusinessType>;
  id: Scalars['ID'];
  imgUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type UserUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isDeleted?: Maybe<BoolFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  roles?: Maybe<UserUpdaterolesInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateManyWithWhereWithoutFacilitiesInput = {
  data: UserUpdateManyMutationInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithWhereWithoutKitchensInput = {
  data: UserUpdateManyMutationInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithoutFacilitiesInput = {
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutFacilitiesInput>>;
  create?: Maybe<Array<UserCreateWithoutFacilitiesInput>>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutFacilitiesInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereWithoutFacilitiesInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutFacilitiesInput>>;
};

export type UserUpdateManyWithoutKitchensInput = {
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutKitchensInput>>;
  create?: Maybe<Array<UserCreateWithoutKitchensInput>>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutKitchensInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereWithoutKitchensInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutKitchensInput>>;
};

export type UserUpdateOneWithoutCustomerInfoInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutCustomerInfoInput>;
  create?: Maybe<UserCreateWithoutCustomerInfoInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserUpdateWithoutCustomerInfoInput>;
  upsert?: Maybe<UserUpsertWithoutCustomerInfoInput>;
};

export type UserUpdateWithWhereUniqueWithoutFacilitiesInput = {
  data: UserUpdateWithoutFacilitiesInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithWhereUniqueWithoutKitchensInput = {
  data: UserUpdateWithoutKitchensInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithoutCustomerInfoInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  devices?: Maybe<DeviceUpdateManyWithoutUserInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  facilities?: Maybe<FacilityUpdateManyWithoutUsersInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isDeleted?: Maybe<BoolFieldUpdateOperationsInput>;
  kitchens?: Maybe<KitchenUpdateManyWithoutUsersInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  roles?: Maybe<UserUpdaterolesInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutFacilitiesInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customerInfo?: Maybe<UserCustomerInfoUpdateOneWithoutUserInput>;
  devices?: Maybe<DeviceUpdateManyWithoutUserInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isDeleted?: Maybe<BoolFieldUpdateOperationsInput>;
  kitchens?: Maybe<KitchenUpdateManyWithoutUsersInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  roles?: Maybe<UserUpdaterolesInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutKitchensInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customerInfo?: Maybe<UserCustomerInfoUpdateOneWithoutUserInput>;
  devices?: Maybe<DeviceUpdateManyWithoutUserInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  facilities?: Maybe<FacilityUpdateManyWithoutUsersInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isDeleted?: Maybe<BoolFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  roles?: Maybe<UserUpdaterolesInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpdaterolesInput = {
  push?: Maybe<UserRole>;
  set?: Maybe<Array<UserRole>>;
};

export type UserUpsertWithWhereUniqueWithoutFacilitiesInput = {
  create: UserCreateWithoutFacilitiesInput;
  update: UserUpdateWithoutFacilitiesInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithWhereUniqueWithoutKitchensInput = {
  create: UserCreateWithoutKitchensInput;
  update: UserUpdateWithoutKitchensInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithoutCustomerInfoInput = {
  create: UserCreateWithoutCustomerInfoInput;
  update: UserUpdateWithoutCustomerInfoInput;
};

export type UserWhereInput = {
  AND?: Maybe<Array<UserWhereInput>>;
  NOT?: Maybe<Array<UserWhereInput>>;
  OR?: Maybe<Array<UserWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  customerInfo?: Maybe<UserCustomerInfoWhereInput>;
  devices?: Maybe<DeviceListRelationFilter>;
  email?: Maybe<StringNullableFilter>;
  facilities?: Maybe<FacilityListRelationFilter>;
  id?: Maybe<StringFilter>;
  isDeleted?: Maybe<BoolFilter>;
  kitchens?: Maybe<KitchenListRelationFilter>;
  name?: Maybe<StringNullableFilter>;
  phone?: Maybe<StringNullableFilter>;
  roles?: Maybe<EnumUserRoleNullableListFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type UserWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type Workplace = {
  __typename?: 'Workplace';
  id: Scalars['String'];
  index: Scalars['Int'];
  type: WorkplaceType;
};

export type WorkplaceCreateManyFacilityInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  type: WorkplaceType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type WorkplaceCreateManyFacilityInputEnvelope = {
  data?: Maybe<Array<WorkplaceCreateManyFacilityInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type WorkplaceCreateNestedManyWithoutFacilityInput = {
  connect?: Maybe<Array<WorkplaceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorkplaceCreateOrConnectWithoutFacilityInput>>;
  create?: Maybe<Array<WorkplaceCreateWithoutFacilityInput>>;
  createMany?: Maybe<WorkplaceCreateManyFacilityInputEnvelope>;
};

export type WorkplaceCreateNestedOneWithoutExpoInput = {
  connect?: Maybe<WorkplaceWhereUniqueInput>;
  connectOrCreate?: Maybe<WorkplaceCreateOrConnectWithoutExpoInput>;
  create?: Maybe<WorkplaceCreateWithoutExpoInput>;
};

export type WorkplaceCreateNestedOneWithoutKitchenInput = {
  connect?: Maybe<WorkplaceWhereUniqueInput>;
  connectOrCreate?: Maybe<WorkplaceCreateOrConnectWithoutKitchenInput>;
  create?: Maybe<WorkplaceCreateWithoutKitchenInput>;
};

export type WorkplaceCreateNestedOneWithoutPackagerInput = {
  connect?: Maybe<WorkplaceWhereUniqueInput>;
  connectOrCreate?: Maybe<WorkplaceCreateOrConnectWithoutPackagerInput>;
  create?: Maybe<WorkplaceCreateWithoutPackagerInput>;
};

export type WorkplaceCreateNestedOneWithoutPickupInput = {
  connect?: Maybe<WorkplaceWhereUniqueInput>;
  connectOrCreate?: Maybe<WorkplaceCreateOrConnectWithoutPickupInput>;
  create?: Maybe<WorkplaceCreateWithoutPickupInput>;
};

export type WorkplaceCreateNestedOneWithoutRunnerInput = {
  connect?: Maybe<WorkplaceWhereUniqueInput>;
  connectOrCreate?: Maybe<WorkplaceCreateOrConnectWithoutRunnerInput>;
  create?: Maybe<WorkplaceCreateWithoutRunnerInput>;
};

export type WorkplaceCreateOrConnectWithoutExpoInput = {
  create: WorkplaceCreateWithoutExpoInput;
  where: WorkplaceWhereUniqueInput;
};

export type WorkplaceCreateOrConnectWithoutFacilityInput = {
  create: WorkplaceCreateWithoutFacilityInput;
  where: WorkplaceWhereUniqueInput;
};

export type WorkplaceCreateOrConnectWithoutKitchenInput = {
  create: WorkplaceCreateWithoutKitchenInput;
  where: WorkplaceWhereUniqueInput;
};

export type WorkplaceCreateOrConnectWithoutPackagerInput = {
  create: WorkplaceCreateWithoutPackagerInput;
  where: WorkplaceWhereUniqueInput;
};

export type WorkplaceCreateOrConnectWithoutPickupInput = {
  create: WorkplaceCreateWithoutPickupInput;
  where: WorkplaceWhereUniqueInput;
};

export type WorkplaceCreateOrConnectWithoutRunnerInput = {
  create: WorkplaceCreateWithoutRunnerInput;
  where: WorkplaceWhereUniqueInput;
};

export type WorkplaceCreateWithoutExpoInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  facility: FacilityCreateNestedOneWithoutWorkplacesInput;
  id?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  kitchen?: Maybe<KitchenCreateNestedOneWithoutWorkplaceInput>;
  packager?: Maybe<OrderCreateNestedManyWithoutPackagerInput>;
  pickup?: Maybe<OrderCreateNestedManyWithoutPickupInput>;
  runner?: Maybe<OrderCreateNestedManyWithoutRunnerInput>;
  type: WorkplaceType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type WorkplaceCreateWithoutFacilityInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  expo?: Maybe<OrderCreateNestedManyWithoutExpoInput>;
  id?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  kitchen?: Maybe<KitchenCreateNestedOneWithoutWorkplaceInput>;
  packager?: Maybe<OrderCreateNestedManyWithoutPackagerInput>;
  pickup?: Maybe<OrderCreateNestedManyWithoutPickupInput>;
  runner?: Maybe<OrderCreateNestedManyWithoutRunnerInput>;
  type: WorkplaceType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type WorkplaceCreateWithoutKitchenInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  expo?: Maybe<OrderCreateNestedManyWithoutExpoInput>;
  facility: FacilityCreateNestedOneWithoutWorkplacesInput;
  id?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  packager?: Maybe<OrderCreateNestedManyWithoutPackagerInput>;
  pickup?: Maybe<OrderCreateNestedManyWithoutPickupInput>;
  runner?: Maybe<OrderCreateNestedManyWithoutRunnerInput>;
  type: WorkplaceType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type WorkplaceCreateWithoutPackagerInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  expo?: Maybe<OrderCreateNestedManyWithoutExpoInput>;
  facility: FacilityCreateNestedOneWithoutWorkplacesInput;
  id?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  kitchen?: Maybe<KitchenCreateNestedOneWithoutWorkplaceInput>;
  pickup?: Maybe<OrderCreateNestedManyWithoutPickupInput>;
  runner?: Maybe<OrderCreateNestedManyWithoutRunnerInput>;
  type: WorkplaceType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type WorkplaceCreateWithoutPickupInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  expo?: Maybe<OrderCreateNestedManyWithoutExpoInput>;
  facility: FacilityCreateNestedOneWithoutWorkplacesInput;
  id?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  kitchen?: Maybe<KitchenCreateNestedOneWithoutWorkplaceInput>;
  packager?: Maybe<OrderCreateNestedManyWithoutPackagerInput>;
  runner?: Maybe<OrderCreateNestedManyWithoutRunnerInput>;
  type: WorkplaceType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type WorkplaceCreateWithoutRunnerInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  expo?: Maybe<OrderCreateNestedManyWithoutExpoInput>;
  facility: FacilityCreateNestedOneWithoutWorkplacesInput;
  id?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  kitchen?: Maybe<KitchenCreateNestedOneWithoutWorkplaceInput>;
  packager?: Maybe<OrderCreateNestedManyWithoutPackagerInput>;
  pickup?: Maybe<OrderCreateNestedManyWithoutPickupInput>;
  type: WorkplaceType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type WorkplaceListRelationFilter = {
  every?: Maybe<WorkplaceWhereInput>;
  none?: Maybe<WorkplaceWhereInput>;
  some?: Maybe<WorkplaceWhereInput>;
};

export type WorkplaceScalarWhereInput = {
  AND?: Maybe<Array<WorkplaceScalarWhereInput>>;
  NOT?: Maybe<Array<WorkplaceScalarWhereInput>>;
  OR?: Maybe<Array<WorkplaceScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  facilityId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  index?: Maybe<IntFilter>;
  type?: Maybe<EnumWorkplaceTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export enum WorkplaceType {
  Expo = 'EXPO',
  Kitchen = 'KITCHEN',
  Packaging = 'PACKAGING',
  Pickup = 'PICKUP',
  Runner = 'RUNNER'
}

export type WorkplaceTypeIndexFacilityIdCompoundUniqueInput = {
  facilityId: Scalars['String'];
  index: Scalars['Int'];
  type: WorkplaceType;
};

export type WorkplaceUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  index?: Maybe<IntFieldUpdateOperationsInput>;
  type?: Maybe<EnumWorkplaceTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type WorkplaceUpdateManyWithWhereWithoutFacilityInput = {
  data: WorkplaceUpdateManyMutationInput;
  where: WorkplaceScalarWhereInput;
};

export type WorkplaceUpdateManyWithoutFacilityInput = {
  connect?: Maybe<Array<WorkplaceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorkplaceCreateOrConnectWithoutFacilityInput>>;
  create?: Maybe<Array<WorkplaceCreateWithoutFacilityInput>>;
  createMany?: Maybe<WorkplaceCreateManyFacilityInputEnvelope>;
  delete?: Maybe<Array<WorkplaceWhereUniqueInput>>;
  deleteMany?: Maybe<Array<WorkplaceScalarWhereInput>>;
  disconnect?: Maybe<Array<WorkplaceWhereUniqueInput>>;
  set?: Maybe<Array<WorkplaceWhereUniqueInput>>;
  update?: Maybe<Array<WorkplaceUpdateWithWhereUniqueWithoutFacilityInput>>;
  updateMany?: Maybe<Array<WorkplaceUpdateManyWithWhereWithoutFacilityInput>>;
  upsert?: Maybe<Array<WorkplaceUpsertWithWhereUniqueWithoutFacilityInput>>;
};

export type WorkplaceUpdateOneWithoutExpoInput = {
  connect?: Maybe<WorkplaceWhereUniqueInput>;
  connectOrCreate?: Maybe<WorkplaceCreateOrConnectWithoutExpoInput>;
  create?: Maybe<WorkplaceCreateWithoutExpoInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<WorkplaceUpdateWithoutExpoInput>;
  upsert?: Maybe<WorkplaceUpsertWithoutExpoInput>;
};

export type WorkplaceUpdateOneWithoutKitchenInput = {
  connect?: Maybe<WorkplaceWhereUniqueInput>;
  connectOrCreate?: Maybe<WorkplaceCreateOrConnectWithoutKitchenInput>;
  create?: Maybe<WorkplaceCreateWithoutKitchenInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<WorkplaceUpdateWithoutKitchenInput>;
  upsert?: Maybe<WorkplaceUpsertWithoutKitchenInput>;
};

export type WorkplaceUpdateOneWithoutPackagerInput = {
  connect?: Maybe<WorkplaceWhereUniqueInput>;
  connectOrCreate?: Maybe<WorkplaceCreateOrConnectWithoutPackagerInput>;
  create?: Maybe<WorkplaceCreateWithoutPackagerInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<WorkplaceUpdateWithoutPackagerInput>;
  upsert?: Maybe<WorkplaceUpsertWithoutPackagerInput>;
};

export type WorkplaceUpdateOneWithoutPickupInput = {
  connect?: Maybe<WorkplaceWhereUniqueInput>;
  connectOrCreate?: Maybe<WorkplaceCreateOrConnectWithoutPickupInput>;
  create?: Maybe<WorkplaceCreateWithoutPickupInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<WorkplaceUpdateWithoutPickupInput>;
  upsert?: Maybe<WorkplaceUpsertWithoutPickupInput>;
};

export type WorkplaceUpdateOneWithoutRunnerInput = {
  connect?: Maybe<WorkplaceWhereUniqueInput>;
  connectOrCreate?: Maybe<WorkplaceCreateOrConnectWithoutRunnerInput>;
  create?: Maybe<WorkplaceCreateWithoutRunnerInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<WorkplaceUpdateWithoutRunnerInput>;
  upsert?: Maybe<WorkplaceUpsertWithoutRunnerInput>;
};

export type WorkplaceUpdateWithWhereUniqueWithoutFacilityInput = {
  data: WorkplaceUpdateWithoutFacilityInput;
  where: WorkplaceWhereUniqueInput;
};

export type WorkplaceUpdateWithoutExpoInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutWorkplacesInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  index?: Maybe<IntFieldUpdateOperationsInput>;
  kitchen?: Maybe<KitchenUpdateOneWithoutWorkplaceInput>;
  packager?: Maybe<OrderUpdateManyWithoutPackagerInput>;
  pickup?: Maybe<OrderUpdateManyWithoutPickupInput>;
  runner?: Maybe<OrderUpdateManyWithoutRunnerInput>;
  type?: Maybe<EnumWorkplaceTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type WorkplaceUpdateWithoutFacilityInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  expo?: Maybe<OrderUpdateManyWithoutExpoInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  index?: Maybe<IntFieldUpdateOperationsInput>;
  kitchen?: Maybe<KitchenUpdateOneWithoutWorkplaceInput>;
  packager?: Maybe<OrderUpdateManyWithoutPackagerInput>;
  pickup?: Maybe<OrderUpdateManyWithoutPickupInput>;
  runner?: Maybe<OrderUpdateManyWithoutRunnerInput>;
  type?: Maybe<EnumWorkplaceTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type WorkplaceUpdateWithoutKitchenInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  expo?: Maybe<OrderUpdateManyWithoutExpoInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutWorkplacesInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  index?: Maybe<IntFieldUpdateOperationsInput>;
  packager?: Maybe<OrderUpdateManyWithoutPackagerInput>;
  pickup?: Maybe<OrderUpdateManyWithoutPickupInput>;
  runner?: Maybe<OrderUpdateManyWithoutRunnerInput>;
  type?: Maybe<EnumWorkplaceTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type WorkplaceUpdateWithoutPackagerInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  expo?: Maybe<OrderUpdateManyWithoutExpoInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutWorkplacesInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  index?: Maybe<IntFieldUpdateOperationsInput>;
  kitchen?: Maybe<KitchenUpdateOneWithoutWorkplaceInput>;
  pickup?: Maybe<OrderUpdateManyWithoutPickupInput>;
  runner?: Maybe<OrderUpdateManyWithoutRunnerInput>;
  type?: Maybe<EnumWorkplaceTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type WorkplaceUpdateWithoutPickupInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  expo?: Maybe<OrderUpdateManyWithoutExpoInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutWorkplacesInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  index?: Maybe<IntFieldUpdateOperationsInput>;
  kitchen?: Maybe<KitchenUpdateOneWithoutWorkplaceInput>;
  packager?: Maybe<OrderUpdateManyWithoutPackagerInput>;
  runner?: Maybe<OrderUpdateManyWithoutRunnerInput>;
  type?: Maybe<EnumWorkplaceTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type WorkplaceUpdateWithoutRunnerInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  expo?: Maybe<OrderUpdateManyWithoutExpoInput>;
  facility?: Maybe<FacilityUpdateOneRequiredWithoutWorkplacesInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  index?: Maybe<IntFieldUpdateOperationsInput>;
  kitchen?: Maybe<KitchenUpdateOneWithoutWorkplaceInput>;
  packager?: Maybe<OrderUpdateManyWithoutPackagerInput>;
  pickup?: Maybe<OrderUpdateManyWithoutPickupInput>;
  type?: Maybe<EnumWorkplaceTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type WorkplaceUpsertWithWhereUniqueWithoutFacilityInput = {
  create: WorkplaceCreateWithoutFacilityInput;
  update: WorkplaceUpdateWithoutFacilityInput;
  where: WorkplaceWhereUniqueInput;
};

export type WorkplaceUpsertWithoutExpoInput = {
  create: WorkplaceCreateWithoutExpoInput;
  update: WorkplaceUpdateWithoutExpoInput;
};

export type WorkplaceUpsertWithoutKitchenInput = {
  create: WorkplaceCreateWithoutKitchenInput;
  update: WorkplaceUpdateWithoutKitchenInput;
};

export type WorkplaceUpsertWithoutPackagerInput = {
  create: WorkplaceCreateWithoutPackagerInput;
  update: WorkplaceUpdateWithoutPackagerInput;
};

export type WorkplaceUpsertWithoutPickupInput = {
  create: WorkplaceCreateWithoutPickupInput;
  update: WorkplaceUpdateWithoutPickupInput;
};

export type WorkplaceUpsertWithoutRunnerInput = {
  create: WorkplaceCreateWithoutRunnerInput;
  update: WorkplaceUpdateWithoutRunnerInput;
};

export type WorkplaceWhereInput = {
  AND?: Maybe<Array<WorkplaceWhereInput>>;
  NOT?: Maybe<Array<WorkplaceWhereInput>>;
  OR?: Maybe<Array<WorkplaceWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  expo?: Maybe<OrderListRelationFilter>;
  facility?: Maybe<FacilityWhereInput>;
  facilityId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  index?: Maybe<IntFilter>;
  kitchen?: Maybe<KitchenWhereInput>;
  packager?: Maybe<OrderListRelationFilter>;
  pickup?: Maybe<OrderListRelationFilter>;
  runner?: Maybe<OrderListRelationFilter>;
  type?: Maybe<EnumWorkplaceTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type WorkplaceWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  type_index_facilityId?: Maybe<WorkplaceTypeIndexFacilityIdCompoundUniqueInput>;
};

export type ConfirmGenericCosts = {
  subtotal?: Maybe<Scalars['Int']>;
  tax?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type ExtraFragment = (
  { __typename?: 'Extra' }
  & Pick<Extra, 'id' | 'name' | 'required' | 'defaultItemId' | 'minimum' | 'maximum' | 'sortId' | 'createdAt' | 'singleOption'>
);

export type KitchenMenuItemInMenuFragment = (
  { __typename?: 'KitchenMenuItem' }
  & Pick<KitchenMenuItem, 'id' | 'name' | 'imgUrl' | 'price' | 'description' | 'labels' | 'availability' | 'inventoryStatus' | 'dishTypes' | 'allergies' | 'globalSortId'>
);

export type OrderValidationFragment = (
  { __typename?: 'KioskOrderValidation' }
  & Pick<KioskOrderValidation, 'unavailableItemIds' | 'errors'>
);

export type DeliveryFragment = (
  { __typename?: 'DeliveryInfo' }
  & Pick<DeliveryInfo, 'isDeliverable' | 'deliveryTimeInSeconds'>
);

export type OrderCostsFragment = (
  { __typename?: 'OrderCost' }
  & Pick<OrderCost, 'promoDiscount' | 'giftCardsDiscount' | 'subtotal' | 'taxFees' | 'delivery' | 'total' | 'fee' | 'tax' | 'tip'>
);

export type OrderInfoFragment = (
  { __typename?: 'Order' }
  & Pick<Order, 'id' | 'shortId' | 'fulfillmentBy' | 'orderName'>
);

export type PromoValidationFragment = (
  { __typename?: 'KioskOrderValidation' }
  & Pick<KioskOrderValidation, 'unavailableItemIds' | 'errors' | 'promoCodes' | 'giftCardCodes'>
  & { promoErrors: Array<(
    { __typename?: 'PromoValidationErrorWithCode' }
    & Pick<PromoValidationErrorWithCode, 'promoCode' | 'error'>
  )> }
);

export type KioskDeliveryAddressFragment = (
  { __typename?: 'Address' }
  & Pick<Address, 'id' | 'street' | 'locality' | 'region'>
);

export type ResetPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'customerResetPassword'>
);

export type SendReceiptMutationVariables = Exact<{
  email: Scalars['String'];
  orderId: Scalars['ID'];
  checked: Scalars['Boolean'];
}>;


export type SendReceiptMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'customerKlaviyoReceipt'>
);

export type ConfirmOrderMutationVariables = Exact<{
  input: ConfirmKioskOrderInput;
}>;


export type ConfirmOrderMutation = (
  { __typename?: 'Mutation' }
  & { customerConfirmKioskOrder: (
    { __typename?: 'KioskOrderConfirmation' }
    & { order?: Maybe<(
      { __typename?: 'Order' }
      & OrderInfoFragment
    )>, validation: (
      { __typename?: 'KioskOrderValidation' }
      & { costs: (
        { __typename?: 'OrderCost' }
        & OrderCostsFragment
      ) }
      & OrderValidationFragment
    ) }
  ) }
);

export type PromoValidationMutationVariables = Exact<{
  input: KioskPromoInput;
}>;


export type PromoValidationMutation = (
  { __typename?: 'Mutation' }
  & { customerValidateKioskPromo: (
    { __typename?: 'KioskOrderValidation' }
    & { costs: (
      { __typename?: 'OrderCost' }
      & OrderCostsFragment
    ) }
    & PromoValidationFragment
  ) }
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name' | 'email' | 'roles'>
    & { kitchens: Array<(
      { __typename?: 'Kitchen' }
      & Pick<Kitchen, 'id'>
    )>, facilities: Array<(
      { __typename?: 'Facility' }
      & Pick<Facility, 'id' | 'name' | 'operationHours' | 'kdsType' | 'timeZone' | 'kioskLogoUrl'>
    )>, customerInfo?: Maybe<(
      { __typename?: 'UserCustomerInfo' }
      & Pick<UserCustomerInfo, 'defaultAddressId'>
      & { tags: Array<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'id' | 'name'>
      )>, addresses: Array<(
        { __typename?: 'Address' }
        & KioskDeliveryAddressFragment
      )> }
    )> }
  )> }
);

export type AvailableTimeslotsQueryVariables = Exact<{
  orderMethod: OrderMethod;
  date: Scalars['DateTime'];
}>;


export type AvailableTimeslotsQuery = (
  { __typename?: 'Query' }
  & { customerTimeslots: Array<(
    { __typename?: 'Timeslot' }
    & Pick<Timeslot, 'start' | 'end'>
  )> }
);

export type KitchensQueryVariables = Exact<{
  date?: Maybe<Scalars['DateTime']>;
}>;


export type KitchensQuery = (
  { __typename?: 'Query' }
  & { customerKitchens: Array<(
    { __typename?: 'CustomerKitchen' }
    & Pick<CustomerKitchen, 'id' | 'name' | 'headerImgUrl' | 'status' | 'logoUrl' | 'cuisine' | 'originalLocation' | 'type'>
  )> }
);

export type DashboardQueryVariables = Exact<{
  date?: Maybe<Scalars['DateTime']>;
}>;


export type DashboardQuery = (
  { __typename?: 'Query' }
  & { customerSpecialMenu: Array<(
    { __typename?: 'MenuCategory' }
    & Pick<MenuCategory, 'id' | 'name' | 'categoryType' | 'menuItemType'>
    & { menuItems: Array<(
      { __typename?: 'KitchenMenuItem' }
      & KitchenMenuItemInMenuFragment
    )> }
  )> }
);

export type CategoriesQueryVariables = Exact<{
  date?: Maybe<Scalars['DateTime']>;
}>;


export type CategoriesQuery = (
  { __typename?: 'Query' }
  & { customerSpecialMenu: Array<(
    { __typename?: 'MenuCategory' }
    & Pick<MenuCategory, 'id'>
  )> }
);

export type RestaurantDetailQueryVariables = Exact<{
  id: Scalars['ID'];
  date?: Maybe<Scalars['DateTime']>;
}>;


export type RestaurantDetailQuery = (
  { __typename?: 'Query' }
  & { customerKitchen: (
    { __typename?: 'CustomerKitchen' }
    & Pick<CustomerKitchen, 'id' | 'name' | 'phone' | 'status' | 'story' | 'description' | 'headerImgUrl' | 'logoUrl' | 'originalLocation'>
    & { menuCategories: Array<(
      { __typename?: 'MenuCategory' }
      & Pick<MenuCategory, 'id'>
      & { menuItems: Array<(
        { __typename?: 'KitchenMenuItem' }
        & KitchenMenuItemInMenuFragment
      )> }
    )> }
  ) }
);

export type KitchenMenuItemQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type KitchenMenuItemQuery = (
  { __typename?: 'Query' }
  & { customerKitchenMenuItem: (
    { __typename?: 'KitchenMenuItem' }
    & Pick<KitchenMenuItem, 'id' | 'name' | 'labels' | 'description' | 'longDescription' | 'imgUrl' | 'price' | 'allergies' | 'dishTypes' | 'inventoryStatus' | 'isRetail' | 'availability' | 'globalSortId' | 'hasExtras' | 'inventoryCount'>
    & { kitchen: (
      { __typename?: 'Kitchen' }
      & Pick<Kitchen, 'id' | 'name' | 'logoUrl'>
    ), extras: Array<(
      { __typename?: 'Extra' }
      & { items: Array<(
        { __typename?: 'ExtraItem' }
        & Pick<ExtraItem, 'id' | 'name' | 'price' | 'isActive' | 'sortId' | 'createdAt'>
      )> }
      & ExtraFragment
    )> }
  ) }
);

export type KioskCartCostsQueryVariables = Exact<{
  cartInput?: Maybe<Scalars['String']>;
}>;


export type KioskCartCostsQuery = (
  { __typename?: 'Query' }
  & { kioskCartCosts: (
    { __typename?: 'CartCosts' }
    & Pick<CartCosts, 'subtotal' | 'promoDiscount' | 'giftCardsDiscount' | 'tax' | 'fee' | 'taxFees' | 'delivery'>
  ) }
);

export const ExtraFragmentDoc = gql`
    fragment Extra on Extra {
  id
  name
  required
  defaultItemId
  minimum
  maximum
  sortId
  createdAt
  singleOption
}
    `;
export const KitchenMenuItemInMenuFragmentDoc = gql`
    fragment KitchenMenuItemInMenu on KitchenMenuItem {
  id
  name
  imgUrl
  price
  description
  labels
  availability
  inventoryStatus
  dishTypes
  allergies
  globalSortId
}
    `;
export const OrderValidationFragmentDoc = gql`
    fragment OrderValidation on KioskOrderValidation {
  unavailableItemIds
  errors
}
    `;
export const DeliveryFragmentDoc = gql`
    fragment Delivery on DeliveryInfo {
  isDeliverable
  deliveryTimeInSeconds
}
    `;
export const OrderCostsFragmentDoc = gql`
    fragment OrderCosts on OrderCost {
  promoDiscount
  giftCardsDiscount
  subtotal
  taxFees
  delivery
  total
  fee
  tax
  tip
}
    `;
export const OrderInfoFragmentDoc = gql`
    fragment OrderInfo on Order {
  id
  shortId
  fulfillmentBy
  orderName
}
    `;
export const PromoValidationFragmentDoc = gql`
    fragment PromoValidation on KioskOrderValidation {
  unavailableItemIds
  errors
  promoErrors {
    promoCode
    error
  }
  promoCodes
  giftCardCodes
}
    `;
export const KioskDeliveryAddressFragmentDoc = gql`
    fragment KioskDeliveryAddress on Address {
  id
  street
  locality
  region
}
    `;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($email: String!) {
  customerResetPassword(email: $email)
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const SendReceiptDocument = gql`
    mutation SendReceipt($email: String!, $orderId: ID!, $checked: Boolean!) {
  customerKlaviyoReceipt(email: $email, orderId: $orderId, checked: $checked)
}
    `;
export type SendReceiptMutationFn = Apollo.MutationFunction<SendReceiptMutation, SendReceiptMutationVariables>;

/**
 * __useSendReceiptMutation__
 *
 * To run a mutation, you first call `useSendReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendReceiptMutation, { data, loading, error }] = useSendReceiptMutation({
 *   variables: {
 *      email: // value for 'email'
 *      orderId: // value for 'orderId'
 *      checked: // value for 'checked'
 *   },
 * });
 */
export function useSendReceiptMutation(baseOptions?: Apollo.MutationHookOptions<SendReceiptMutation, SendReceiptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendReceiptMutation, SendReceiptMutationVariables>(SendReceiptDocument, options);
      }
export type SendReceiptMutationHookResult = ReturnType<typeof useSendReceiptMutation>;
export type SendReceiptMutationResult = Apollo.MutationResult<SendReceiptMutation>;
export type SendReceiptMutationOptions = Apollo.BaseMutationOptions<SendReceiptMutation, SendReceiptMutationVariables>;
export const ConfirmOrderDocument = gql`
    mutation ConfirmOrder($input: ConfirmKioskOrderInput!) {
  customerConfirmKioskOrder(input: $input) {
    order {
      ...OrderInfo
    }
    validation {
      ...OrderValidation
      costs {
        ...OrderCosts
      }
    }
  }
}
    ${OrderInfoFragmentDoc}
${OrderValidationFragmentDoc}
${OrderCostsFragmentDoc}`;
export type ConfirmOrderMutationFn = Apollo.MutationFunction<ConfirmOrderMutation, ConfirmOrderMutationVariables>;

/**
 * __useConfirmOrderMutation__
 *
 * To run a mutation, you first call `useConfirmOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmOrderMutation, { data, loading, error }] = useConfirmOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmOrderMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmOrderMutation, ConfirmOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmOrderMutation, ConfirmOrderMutationVariables>(ConfirmOrderDocument, options);
      }
export type ConfirmOrderMutationHookResult = ReturnType<typeof useConfirmOrderMutation>;
export type ConfirmOrderMutationResult = Apollo.MutationResult<ConfirmOrderMutation>;
export type ConfirmOrderMutationOptions = Apollo.BaseMutationOptions<ConfirmOrderMutation, ConfirmOrderMutationVariables>;
export const PromoValidationDocument = gql`
    mutation PromoValidation($input: KioskPromoInput!) {
  customerValidateKioskPromo(input: $input) {
    ...PromoValidation
    costs {
      ...OrderCosts
    }
  }
}
    ${PromoValidationFragmentDoc}
${OrderCostsFragmentDoc}`;
export type PromoValidationMutationFn = Apollo.MutationFunction<PromoValidationMutation, PromoValidationMutationVariables>;

/**
 * __usePromoValidationMutation__
 *
 * To run a mutation, you first call `usePromoValidationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePromoValidationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [promoValidationMutation, { data, loading, error }] = usePromoValidationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePromoValidationMutation(baseOptions?: Apollo.MutationHookOptions<PromoValidationMutation, PromoValidationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PromoValidationMutation, PromoValidationMutationVariables>(PromoValidationDocument, options);
      }
export type PromoValidationMutationHookResult = ReturnType<typeof usePromoValidationMutation>;
export type PromoValidationMutationResult = Apollo.MutationResult<PromoValidationMutation>;
export type PromoValidationMutationOptions = Apollo.BaseMutationOptions<PromoValidationMutation, PromoValidationMutationVariables>;
export const MeDocument = gql`
    query Me {
  me {
    id
    name
    email
    roles
    kitchens {
      id
    }
    facilities {
      id
      name
      operationHours
      kdsType
      timeZone
      kioskLogoUrl
    }
    customerInfo {
      tags {
        id
        name
      }
      defaultAddressId
      addresses {
        ...KioskDeliveryAddress
      }
    }
  }
}
    ${KioskDeliveryAddressFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const AvailableTimeslotsDocument = gql`
    query AvailableTimeslots($orderMethod: OrderMethod!, $date: DateTime!) {
  customerTimeslots(orderMethod: $orderMethod, date: $date) {
    start
    end
  }
}
    `;

/**
 * __useAvailableTimeslotsQuery__
 *
 * To run a query within a React component, call `useAvailableTimeslotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableTimeslotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableTimeslotsQuery({
 *   variables: {
 *      orderMethod: // value for 'orderMethod'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useAvailableTimeslotsQuery(baseOptions: Apollo.QueryHookOptions<AvailableTimeslotsQuery, AvailableTimeslotsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AvailableTimeslotsQuery, AvailableTimeslotsQueryVariables>(AvailableTimeslotsDocument, options);
      }
export function useAvailableTimeslotsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AvailableTimeslotsQuery, AvailableTimeslotsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AvailableTimeslotsQuery, AvailableTimeslotsQueryVariables>(AvailableTimeslotsDocument, options);
        }
export type AvailableTimeslotsQueryHookResult = ReturnType<typeof useAvailableTimeslotsQuery>;
export type AvailableTimeslotsLazyQueryHookResult = ReturnType<typeof useAvailableTimeslotsLazyQuery>;
export type AvailableTimeslotsQueryResult = Apollo.QueryResult<AvailableTimeslotsQuery, AvailableTimeslotsQueryVariables>;
export const KitchensDocument = gql`
    query Kitchens($date: DateTime) {
  customerKitchens(date: $date) {
    id
    name
    headerImgUrl
    status
    logoUrl
    cuisine
    originalLocation
    type
  }
}
    `;

/**
 * __useKitchensQuery__
 *
 * To run a query within a React component, call `useKitchensQuery` and pass it any options that fit your needs.
 * When your component renders, `useKitchensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKitchensQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useKitchensQuery(baseOptions?: Apollo.QueryHookOptions<KitchensQuery, KitchensQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KitchensQuery, KitchensQueryVariables>(KitchensDocument, options);
      }
export function useKitchensLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KitchensQuery, KitchensQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KitchensQuery, KitchensQueryVariables>(KitchensDocument, options);
        }
export type KitchensQueryHookResult = ReturnType<typeof useKitchensQuery>;
export type KitchensLazyQueryHookResult = ReturnType<typeof useKitchensLazyQuery>;
export type KitchensQueryResult = Apollo.QueryResult<KitchensQuery, KitchensQueryVariables>;
export const DashboardDocument = gql`
    query Dashboard($date: DateTime) {
  customerSpecialMenu(date: $date) {
    id
    name
    categoryType
    menuItemType
    menuItems {
      ...KitchenMenuItemInMenu
    }
  }
}
    ${KitchenMenuItemInMenuFragmentDoc}`;

/**
 * __useDashboardQuery__
 *
 * To run a query within a React component, call `useDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useDashboardQuery(baseOptions?: Apollo.QueryHookOptions<DashboardQuery, DashboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardQuery, DashboardQueryVariables>(DashboardDocument, options);
      }
export function useDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardQuery, DashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardQuery, DashboardQueryVariables>(DashboardDocument, options);
        }
export type DashboardQueryHookResult = ReturnType<typeof useDashboardQuery>;
export type DashboardLazyQueryHookResult = ReturnType<typeof useDashboardLazyQuery>;
export type DashboardQueryResult = Apollo.QueryResult<DashboardQuery, DashboardQueryVariables>;
export const CategoriesDocument = gql`
    query Categories($date: DateTime) {
  customerSpecialMenu(date: $date) {
    id
  }
}
    `;

/**
 * __useCategoriesQuery__
 *
 * To run a query within a React component, call `useCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<CategoriesQuery, CategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, options);
      }
export function useCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoriesQuery, CategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, options);
        }
export type CategoriesQueryHookResult = ReturnType<typeof useCategoriesQuery>;
export type CategoriesLazyQueryHookResult = ReturnType<typeof useCategoriesLazyQuery>;
export type CategoriesQueryResult = Apollo.QueryResult<CategoriesQuery, CategoriesQueryVariables>;
export const RestaurantDetailDocument = gql`
    query restaurantDetail($id: ID!, $date: DateTime) {
  customerKitchen(id: $id, date: $date) {
    id
    name
    phone
    status
    story
    description
    headerImgUrl
    logoUrl
    originalLocation
    menuCategories {
      id
      menuItems {
        ...KitchenMenuItemInMenu
      }
    }
  }
}
    ${KitchenMenuItemInMenuFragmentDoc}`;

/**
 * __useRestaurantDetailQuery__
 *
 * To run a query within a React component, call `useRestaurantDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useRestaurantDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRestaurantDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useRestaurantDetailQuery(baseOptions: Apollo.QueryHookOptions<RestaurantDetailQuery, RestaurantDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RestaurantDetailQuery, RestaurantDetailQueryVariables>(RestaurantDetailDocument, options);
      }
export function useRestaurantDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RestaurantDetailQuery, RestaurantDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RestaurantDetailQuery, RestaurantDetailQueryVariables>(RestaurantDetailDocument, options);
        }
export type RestaurantDetailQueryHookResult = ReturnType<typeof useRestaurantDetailQuery>;
export type RestaurantDetailLazyQueryHookResult = ReturnType<typeof useRestaurantDetailLazyQuery>;
export type RestaurantDetailQueryResult = Apollo.QueryResult<RestaurantDetailQuery, RestaurantDetailQueryVariables>;
export const KitchenMenuItemDocument = gql`
    query KitchenMenuItem($id: ID!) {
  customerKitchenMenuItem(id: $id) {
    id
    name
    labels
    description
    longDescription
    imgUrl
    price
    allergies
    dishTypes
    inventoryStatus
    isRetail
    availability
    kitchen {
      id
      name
      logoUrl
    }
    extras {
      ...Extra
      items {
        id
        name
        price
        isActive
        sortId
        createdAt
      }
    }
    globalSortId
    hasExtras
    inventoryCount
  }
}
    ${ExtraFragmentDoc}`;

/**
 * __useKitchenMenuItemQuery__
 *
 * To run a query within a React component, call `useKitchenMenuItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useKitchenMenuItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKitchenMenuItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useKitchenMenuItemQuery(baseOptions: Apollo.QueryHookOptions<KitchenMenuItemQuery, KitchenMenuItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KitchenMenuItemQuery, KitchenMenuItemQueryVariables>(KitchenMenuItemDocument, options);
      }
export function useKitchenMenuItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KitchenMenuItemQuery, KitchenMenuItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KitchenMenuItemQuery, KitchenMenuItemQueryVariables>(KitchenMenuItemDocument, options);
        }
export type KitchenMenuItemQueryHookResult = ReturnType<typeof useKitchenMenuItemQuery>;
export type KitchenMenuItemLazyQueryHookResult = ReturnType<typeof useKitchenMenuItemLazyQuery>;
export type KitchenMenuItemQueryResult = Apollo.QueryResult<KitchenMenuItemQuery, KitchenMenuItemQueryVariables>;
export const KioskCartCostsDocument = gql`
    query KioskCartCosts($cartInput: String) {
  kioskCartCosts(cartInput: $cartInput) {
    subtotal
    promoDiscount
    giftCardsDiscount
    tax
    fee
    taxFees
    delivery
  }
}
    `;

/**
 * __useKioskCartCostsQuery__
 *
 * To run a query within a React component, call `useKioskCartCostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useKioskCartCostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKioskCartCostsQuery({
 *   variables: {
 *      cartInput: // value for 'cartInput'
 *   },
 * });
 */
export function useKioskCartCostsQuery(baseOptions?: Apollo.QueryHookOptions<KioskCartCostsQuery, KioskCartCostsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KioskCartCostsQuery, KioskCartCostsQueryVariables>(KioskCartCostsDocument, options);
      }
export function useKioskCartCostsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KioskCartCostsQuery, KioskCartCostsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KioskCartCostsQuery, KioskCartCostsQueryVariables>(KioskCartCostsDocument, options);
        }
export type KioskCartCostsQueryHookResult = ReturnType<typeof useKioskCartCostsQuery>;
export type KioskCartCostsLazyQueryHookResult = ReturnType<typeof useKioskCartCostsLazyQuery>;
export type KioskCartCostsQueryResult = Apollo.QueryResult<KioskCartCostsQuery, KioskCartCostsQueryVariables>;