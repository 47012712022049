import React from 'react';

import {
  KitchenMenuItemInMenuFragment,
  InventoryStatus,
} from 'codegen/generated/graphql';
import { Logo } from 'components/Image';
import { Column, Row } from 'components/Layout';
import Text from 'components/Text';
import { priceFormatter } from 'utils/formatHelpers';

import { ImageWrapper, Overlay, OverlayWrapper, ItemName } from '../styles';

type Props = {
  items: KitchenMenuItemInMenuFragment[];
  handleModalOpen: (id: string) => void;
};

const MenuItems = ({ items, handleModalOpen }: Props) => {
  return (
    <>
      <Row
        $justifyContent="flex-start"
        gutter={[30, 50]}
        $padding="0 10px 75px"
      >
        {items &&
          items.map((item, i) => {
            const soldOut = item.inventoryStatus === InventoryStatus.SoldOut;

            return (
              <Column
                span={8}
                key={i}
                $justifyContent="center"
                onClick={() => handleModalOpen(item.id)}
              >
                <Row>
                  <ImageWrapper>
                    <OverlayWrapper>
                      <Logo
                        src={item.imgUrl || './svg/menu-item.svg'}
                        height={120}
                        width={120}
                        $borderRadius="xxxxl"
                      />
                      <Overlay $soldOut={soldOut} />
                    </OverlayWrapper>
                  </ImageWrapper>
                </Row>
                <Row $minHeight="90px" $maxHeight="90px">
                  <ItemName $soldOut={soldOut}>{item.name}</ItemName>
                </Row>
                <Row>
                  <Text color={soldOut ? 'gray' : 'black'} textAlign="center">
                    {soldOut ? 'Sold Out' : priceFormatter(item.price)}
                  </Text>
                </Row>
              </Column>
            );
          })}
      </Row>
    </>
  );
};

export default MenuItems;
