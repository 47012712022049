import React from 'react';

import Text from 'components/Text';

import { StyledTag } from './styles';

interface Props {
  tagLabel?: string;
  color: string;
}

const MenuItemTag = ({ tagLabel, color }: Props) => {
  return (
    <StyledTag color={color}>
      <Text fontSize="lg" fontWeight="bold" color="white" alignSelf="center">
        {tagLabel}
      </Text>
    </StyledTag>
  );
};

export default MenuItemTag;
