import React from 'react';

import { Popover, Button } from 'antd';
import styled from 'styled-components';

import { Row } from 'components/Layout';
import { isProduction } from 'utils/env';

import FacilitySwitch from './components/FacilitySwitch';
import PaymentsSwitch from './components/PaymentsSwitch';

const Container = styled.div`
  position: fixed;
  background-color: white;
  padding: 20px;
  margin: 20px;
  left: 0;
  bottom: 0;
  box-shadow: 0px 2px 10px rgba(97, 97, 97, 0.1);
`;

const DevToolsWidget = () => {
  if (isProduction) return null;

  const content = (
    <>
      <Row style={{ gap: 5, padding: 10 }}>
        <FacilitySwitch />
        <PaymentsSwitch />
      </Row>
    </>
  );

  return (
    <Container>
      <Popover
        placement="left"
        content={content}
        title="Dev Tools"
        trigger="click"
      >
        <Button type="text">Dev</Button>
      </Popover>
    </Container>
  );
};

export default DevToolsWidget;
