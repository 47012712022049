import { ReactiveVar } from '@apollo/client';
import { uniqueId } from 'lodash';

import { KitchenMenuItemQuery } from '../../../codegen/generated/graphql';
import { MenuItem } from '../models';

export default function createMenuItem(menuItemVar: ReactiveVar<MenuItem>) {
  const createNewMenuItem = (
    menuItem: KitchenMenuItemQuery['customerKitchenMenuItem'],
  ) => {
    menuItemVar({
      id: uniqueId('menu_item_'),
      kitchenMenuItem: menuItem,
      selectedExtras: [],
      price: menuItem.price,
      quantity: 1,
      totalPrice: menuItem.price,
      sortNumber: 1,
    });
  };

  return createNewMenuItem;
}
