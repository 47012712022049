import { ReactiveVar } from '@apollo/client';

import { getSubTotal } from '../helpers';
import { Cart } from '../models';

export default (singleCartVar: ReactiveVar<Cart>) => {
  return (
    id: number,
    subTotal: number,
    tax: number,
    fee: number,
    promoDiscount: number,
    giftCardsDiscount: number,
    promoCodes?: string[] | null,
    delivery?: number,
    recalculateDiscount?: boolean,
    tip?: number,
  ) => {
    //TODO: promoCodes to uppercase in array
    const cartsWithEdit = (cart: Cart) => {
      let subTotal = 0;
      cart.items.forEach((item) => {
        subTotal = getSubTotal(item, subTotal);
      });

      return cart.id === id
        ? {
            ...cart,
            subTotal,
            tip: tip ?? cart.tip,
            delivery,
            tax,
            promoDiscount,
            giftCardsDiscount,
            total:
              subTotal +
              fee +
              tax +
              cart.tip -
              promoDiscount -
              giftCardsDiscount +
              (delivery ?? 0),
            promoCodes: promoCodes ?? cart.promoCodes,
            recalculateDiscount,
            fee,
          }
        : cart;
    };

    singleCartVar(cartsWithEdit(singleCartVar()));
  };
};
