import { cartMutations } from 'utils/apollo/mutations';

import { ItemQuantity, AddToCart } from '../types';

export const setItemQuantity = ({
  quantity,
  menuItemId,
  cartMenuItemId,
  isCheckout,
}: ItemQuantity) => {
  if (menuItemId) {
    cartMutations.editMenuItem({
      id: menuItemId,
      quantity,
    });
  }
  if (isCheckout && quantity > 1) {
    cartMutations.editCartMenuItem({
      cartId: 1,
      id: cartMenuItemId,
      quantity,
    });
  }

  return quantity;
};

export const addToCart = ({ menuItem, isEdit }: AddToCart) => {
  for (
    let i = 0;
    i < (isEdit ? menuItem.quantity - 1 : menuItem.quantity);
    i++
  ) {
    cartMutations.editCart(1, menuItem);
  }
};
