import { Row, Typography } from 'antd';
import styled from 'styled-components';

import { parseColor, theme } from 'theme';

const { Title } = Typography;

export const ImageWrapper = styled.div`
  display: flex;
  flex: 2;
  align-items: center;
  justify-content: center;
`;

export const OverlayWrapper = styled.div`
  position: relative;
  width: 120px;
  height: 120px;
`;

interface Props {
  $soldOut?: boolean;
}

export const Overlay = styled.div<Props>(
  ({ $soldOut }) => `
  position: absolute;
  top: 0;
  left: 0;
  clear: float;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: ${
    $soldOut ? `${parseColor(['gray', 0.5])}` : 'transparent'
  };
  `,
);

export const ItemName = styled(Title).attrs({
  level: 5,
  ellipsis: {
    rows: 2,
  },
})<Props>(
  ({ $soldOut }) => `
  color: ${$soldOut ? `${parseColor(['gray', 0.9])}` : 'black'} !important;
  margin-top: ${theme.spacing.sm}
  `,
);

export const CategoryWrapper = styled.div`
  padding: 60px;
  display: flex;
  flex-direction: row;
`;

export const Wrapper = styled.div`
  position: relative;
  width: 90%;
  overflow: hidden;
`;

export const ScrollRow = styled(Row)`
  div.ant-row.ant-row-center {
    overflow-y: hidden;
    overflow-x: scroll;
    justify: center;
    width: 90%;
    height: 100%;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;
