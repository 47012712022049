import React from 'react';

import { KitchensQuery } from 'codegen/generated/graphql';
import { Column, Row } from 'components/Layout';
import { SPECIAL_MENU } from 'utils/constants';

import { KitchenCard } from './styles';

type Props = {
  kitchens: KitchensQuery['customerKitchens'];
  selectedKitchen: string;
  setSelectedKitchen: (value: string) => void;
  index: number;
};

const KitchenCards = ({
  kitchens,
  selectedKitchen,
  setSelectedKitchen,
  index,
}: Props) => {
  return (
    <>
      <Row
        align="middle"
        justify="center"
        gutter={[30, 30]}
        $padding={
          kitchens.length === 5
            ? '60px'
            : kitchens.length === 4
            ? '30px 50px 60px'
            : kitchens.length === 3
            ? '30px 130px 60px'
            : '30px 10px 60px'
        }
        $minHeight="380px"
      >
        {index < 1 && (
          <Column>
            <KitchenCard
              onClick={() => setSelectedKitchen(SPECIAL_MENU)}
              hoverable
              $border={selectedKitchen === SPECIAL_MENU}
              $logoUrl="/images/top-picks.png"
              $special={true}
            />
          </Column>
        )}
        {kitchens.map((i) => {
          return (
            <Column key={i.id}>
              <KitchenCard
                onClick={() => setSelectedKitchen(i.id)}
                hoverable
                $border={selectedKitchen === i.id}
                $logoUrl={i.logoUrl || '/images/top-picks.png'}
              />
            </Column>
          );
        })}
      </Row>
    </>
  );
};

export default KitchenCards;
