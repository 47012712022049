import { Checkbox as AntdCheckbox } from 'antd';
import styled, { Color } from 'styled-components';

import { theme } from 'theme';

export const Checkbox = styled(AntdCheckbox)<{
  $checked?: boolean;
  $borderColor?: keyof Color;
  $whiteCheck?: boolean;
}>(
  ({ $checked = false, $borderColor = 'tan', $whiteCheck = false }) => `
   padding-right: 10px;
  .ant-checkbox-inner {
    height: 25px;
    width: 25px;
    border-radius: 3px !important;
    border: 1px solid ${$borderColor} !important;
    background-color: 5px solid ${theme.color.white};
  }
 .ant-checkbox-checked .ant-checkbox-inner::after {
    border: 2px solid ${$whiteCheck ? 'white' : 'black'};
    border-top: 0;
    border-left: 0;
    transform: rotate(45deg) scale(1.5) translate(-50%, -50%)
  }
  .ant-checkbox-checked::after {
    border: none;
  }
  .ant-checkbox-inner {
    border-radius: 0px !important;
    background-color: ${
      $checked
        ? `${theme.color.tan}`
        : $whiteCheck
        ? `${theme.color.black}`
        : `${theme.color.white}`
    } !important;
    color: black !important;
  } 
  `,
);
