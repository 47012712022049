import { ReactiveVar } from '@apollo/client';

import { Cart } from '../models';

export default (singleCartVar: ReactiveVar<Cart>) => {
  return (id: number, recalculateDiscount: boolean) => {
    const cartsWithEdit = (cart: Cart) => {
      return cart.id === id
        ? {
            ...cart,
            recalculateDiscount,
          }
        : cart;
    };

    singleCartVar(cartsWithEdit(singleCartVar()));
  };
};
