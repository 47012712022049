import React, { useContext, useEffect, useState } from 'react';

import { message } from 'antd';
import { isEqual } from 'lodash';

import {
  PromoValidationErrorWithCode,
  usePromoValidationMutation,
} from 'codegen/generated/graphql';
import Button from 'components/Button';
import InputModal from 'components/InputModal';
import OrderSummary from 'containers/OrderSummary';
import { cartMutations } from 'utils/apollo/mutations/index';
import { PROMO_VALIDATION_ERRORS } from 'utils/constants';
import { useAppContext } from 'utils/context';
import { CartContext } from 'utils/context/cartContext';
import { KeyboardContext } from 'utils/context/keyboard';
import { reportValidationErrors } from 'utils/errors';
import { formatPromo } from 'utils/helpers/formatters';

import CheckoutHeader from './components/CheckoutHeader';
import CheckoutPriceBar from './components/CheckoutPriceBar';
import { reduceCartItems } from './components/CheckoutPriceBar/utils';

import {
  TopCheckoutSection,
  CheckoutContainer,
  OrderSummaryWrapper,
  ButtonWrapper,
} from './styles';

const PROMO_INPUT = 'promo_input';

const Checkout = () => {
  const { cart } = useAppContext();

  const { setRecalculate } = useContext(CartContext);
  const { keyboard } = useContext(KeyboardContext);

  const [showPromoInput, setShowPromoInput] = useState(false);
  const [showGiftCardsInput, setShowGiftCardsInput] = useState(false);
  const [promoErrors, setPromoErrors] = useState<
    PromoValidationErrorWithCode[]
  >([]);

  const [validatePromo, { data, loading }] = usePromoValidationMutation({
    refetchQueries: ['kioskCartCosts'],
  });
  const promo = data?.customerValidateKioskPromo;
  const promoCodes = promo
    ? [...promo.promoCodes, ...promo.giftCardCodes]
    : cart.promoCodes;

  const promoErrorMessages = promoErrors.map(
    ({ error, promoCode }) => `${promoCode} ${PROMO_VALIDATION_ERRORS[error]}`,
  );

  const submitPromo = async (code = '') => {
    try {
      const { data, errors: queryErrors } = await validatePromo({
        variables: {
          input: {
            order: {
              orderName: cart.orderName,
            },
            cart: {
              promoCodes: code ? [...promoCodes, code] : promoCodes,
              cartItems: reduceCartItems(cart.items).map((item) => ({
                id: item.kitchenMenuItem?.id ?? '',
                quantity: item.quantity,
                extraItems: item.selectedExtras.map((extra) => ({
                  extraItemId: extra.item.id,
                  quantity: extra.quantity,
                })),
              })),
            },
          },
        },
      });

      const { errors, promoErrors } = data?.customerValidateKioskPromo ?? {};

      if (reportValidationErrors(promoErrors, errors, queryErrors)) return;

      if (data) {
        const promo = data.customerValidateKioskPromo;
        const updatedPromoCodes = [...promo.promoCodes, ...promo.giftCardCodes];
        const { costs } = promo;

        if (costs) {
          cartMutations.editCartCosts(
            1,
            costs.subtotal,
            costs.tax,
            costs.fee,
            costs.promoDiscount,
            costs.giftCardsDiscount,
            updatedPromoCodes,
            cart.delivery,
            false,
          );

          //FIXME: Is it necessary to use reCalculate here? Refine setRecalculate behaviour
          setRecalculate(false);
        }

        if (!isEqual(promo.promoErrors, promoErrors))
          setPromoErrors(promo.promoErrors);

        if (code) {
          if (!promo.promoErrors.length) {
            setShowPromoInput(false);
            setShowGiftCardsInput(false);
            message.success(
              `${showPromoInput ? 'Promo' : 'Gift Card'} successfully added`,
            );
          } else keyboard?.current.clearInput(PROMO_INPUT);
        }
      }
    } catch (e) {
      return reportValidationErrors([], [], [e]);
    }
  };

  // Recalculate discount when flag is set in cart

  useEffect(
    () => {
      if (cart.recalculateDiscount) submitPromo();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cart.recalculateDiscount],
  );

  const openPromoInput = () => {
    setPromoErrors([]);
    setShowPromoInput(true);
  };

  const openGiftCardsInput = () => {
    setPromoErrors([]);
    setShowGiftCardsInput(true);
  };

  return (
    <CheckoutContainer>
      <script src="https://js.stripe.com/terminal/v1/"></script>
      <TopCheckoutSection>
        <CheckoutHeader orderName={cart.orderName} />
        <OrderSummaryWrapper>
          <OrderSummary menuItems={cart} />
        </OrderSummaryWrapper>
        <ButtonWrapper>
          <Button
            text={'Apply Promo Code'}
            onClick={openPromoInput}
            border={2}
            borderColor="black"
            flex={1}
          />

          <Button
            text="Use Gift Card"
            onClick={openGiftCardsInput}
            border={2}
            borderColor="black"
            flex={1}
          />
        </ButtonWrapper>

        <InputModal
          id={PROMO_INPUT}
          title={'Apply Promo Code'}
          onSubmit={(inputState) => submitPromo(inputState)}
          visible={showPromoInput}
          message="Enter your promo code below."
          onClose={() => setShowPromoInput(false)}
          submitText="Apply"
          errorMessages={promoErrorMessages}
          loading={loading}
          format={formatPromo}
        />
        <InputModal
          id={PROMO_INPUT}
          title={'Use a Gift Card'}
          onSubmit={(inputState) => submitPromo(inputState)}
          visible={showGiftCardsInput}
          message="Enter your gift card code below."
          onClose={() => setShowGiftCardsInput(false)}
          submitText="Apply"
          errorMessages={promoErrorMessages}
          loading={loading}
          format={formatPromo}
        />
      </TopCheckoutSection>

      <CheckoutPriceBar promoCodes={cart.promoCodes} cart={cart} />
    </CheckoutContainer>
  );
};

export default Checkout;
