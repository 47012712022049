import React, { useContext, useState } from 'react';

import { Reader } from '@stripe/terminal-js';
import { message, Row, Col } from 'antd';

import { AuthContext } from 'authContext';
import Button from 'components/Button';
import { Input } from 'components/Input';
import Text from 'components/Text';
import {
  createTerminal,
  discoverStripeReaders,
  readerRegistration,
} from 'containers/Checkout/components/CheckoutPriceBar/stripeUtils';
import { usePaymentCtx } from 'paymentContext';

import { StyledForm } from './styles';

const StripeCardReaders = () => {
  const { isPaymentMocked } = usePaymentCtx();
  const [readers, setReaders] = useState<Reader[]>([]);

  const findReaders = async () => {
    const terminal = await createTerminal();
    if (!terminal) {
      console.error('Unable to load stripe terminal...');

      return;
    }
    const readers = await discoverStripeReaders(terminal, isPaymentMocked);
    setReaders(readers);
  };

  const { user } = useContext(AuthContext);
  const [registrationCode, setRegistrationCode] = useState('');
  const [success, setSuccess] = useState(false);

  const attemptRegistration = async () => {
    const newReader = await readerRegistration(
      registrationCode,
      user?.email ?? '',
      'tml_ETXpDQsRtXOL7a', // TODO: dynamicall get stripe location from user user?.stripeLocationId
    );
    if (newReader.error) {
      console.error(newReader.error);
      message.error('Invalid registration code, please try again');

      return;
    } else {
      setSuccess(true);
    }
    setRegistrationCode('');
    findReaders();
  };

  if (!isPaymentMocked) findReaders();

  return (
    <>
      <Text spaceAfter={20} fontSize={'lg'} color={'black'} fontWeight={'bold'}>
        Terminal ID
      </Text>
      <Text spaceAfter={20}>
        Enter the terminal ID to connect your card reader.
      </Text>
      <StyledForm>
        <Row>
          <Col>
            <Row>
              <Input
                autoFocus
                width={'340px'}
                placeholder="Terminal ID"
                value={registrationCode}
                onChange={(e) => setRegistrationCode(e.toString())}
                height="50px"
                borderColor="#000000"
                align="left"
              />
            </Row>
            <Text
              marginTop={80}
              fontSize={'lg'}
              color={'black'}
              fontWeight={'bold'}
              spaceAfter={20}
            >
              Readers
            </Text>
            {readers.map((reader) => {
              return (
                <Text
                  spaceAfter={20}
                  key={reader.id}
                  fontSize={'lg'}
                  color={'black'}
                >
                  {reader.label} - {reader.ip_address} - {reader.location} -{' '}
                  {reader.status}
                </Text>
              );
            })}
            {readers.length === 0 && (
              <Text spaceAfter={20} fontSize={'sm'} color={'black'}>
                No card readers found.
              </Text>
            )}
            <Row>
              <Button text={'Refresh readers'} primary onClick={findReaders} />
            </Row>
          </Col>
        </Row>
        <Row justify="end" align="middle">
          {success && <Text marginRight="sm">Changes have been saved.</Text>}
          <Button
            padding="0 60px"
            text={'Save'}
            primary
            onClick={attemptRegistration}
          />
        </Row>
      </StyledForm>
    </>
  );
};

export default StripeCardReaders;
