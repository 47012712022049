import { Layout, Button } from 'antd';
import styled from 'styled-components';

import { background } from 'utils/styleHelpers';

interface LinkProps {
  $padding?: string;
}

export const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  justify-content: center;
  overflow: hidden;
  margin: 0 auto;
  .ant-layout {
    ${background('eggshell')};
  }
  .ant-layout-content {
    ${background('eggshell')};
    padding-top: 30px;
  }
  .ant-card {
    text-align: center;
    height: 1500px;

    .ant-card-body {
      padding: 40px;
      height: 100%;
    }
  }
`;

export const StyledLayout = styled(Layout)`
  text-align: center;
  padding: 54px;
`;

export const GoBackButton = styled(Button)<LinkProps>(
  ({ $padding = '0px 16px' }) => `
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  color: black;
  border: none !important;
  transition: none !important;
  padding: ${$padding};
  font-size: 18px !important;
  font-weight: 600;
  height: 22px !important;
  ${background('eggshell')}
  span {
    border: none !important;
  } 
  `,
);
