import { Input } from 'antd';
import styled, { FontSizes } from 'styled-components';

import { theme } from 'theme';
import { textAlign, fontSize as textSize } from 'utils/styleHelpers';

import { Props } from './Input';

export const InputAtom = styled(Input)<Props>(
  ({
    align = 'center',
    width = '465px',
    height = '80px',
    padding = '5px 40px',
    fontSize = 'lg',
    placeholderColor = 'lightGray',
    borderColor,
  }) => `
    ${textSize(fontSize)};
    ${textAlign(align)};
    width: ${width};
    height: ${height};
    padding: ${padding};
    border-radius: 50px;
    border: 1px solid ${borderColor ? borderColor : '#d9d9d9'} !important;
    font-weight: ${theme.fontWeight.bold};
    color: ${theme.color.black};
    background: ${theme.color.white} !important;
    .ant-input {
      ${textSize(fontSize)};
      font-weight: ${theme.fontWeight.bold};
    }
    &.ant-input:hover, &.ant-input:focus {
      border-color: ${borderColor ? borderColor : '#fff0f0'} !important;
    }
    .ant-input::placeholder {
      text-align: ${align};
      font-weight: ${theme.fontWeight.bold};
    }
    &.ant-input-borderless:hover {
      background: ${theme.color.white};
    }
    ::placeholder {
      color: ${placeholderColor};
      font-weight: ${placeholderColor === 'gray' ? '400' : '600'};

    }
  
`,
);

interface CounterProps {
  $width?: string;
  marginTop?: number;
  marginBottom?: number;
}

export const CounterWrapper = styled.div<CounterProps>(
  ({ $width = '465px', marginTop, marginBottom }) => `
  position: relative;
  width: ${$width};
  ${marginTop && `margin-top: ${marginTop}px;`}
  ${marginBottom && `margin-bottom: ${marginBottom}px;`}
  height: 80px;
  `,
);

interface OverlayProps {
  $top?: string;
  $right?: string;
  $fontSize?: keyof FontSizes;
  $letterSpacing?: string;
  $promo?: boolean;
}

export const Overlay = styled.span<OverlayProps>(
  ({
    $top = '25px',
    $right = '25px',
    $fontSize = 'lg',
    $letterSpacing = '-2px',
    $promo = false,
  }) => `
  position: absolute;
  top: ${$promo ? '-3px' : $top};
  right: ${$promo ? '0px' : $right};
  font-size: ${textSize($fontSize)} !important;
  font-weight: ${theme.fontWeight.thin};
  color: ${theme.color.gray} !important;
  font-weight: ${theme.fontWeight.thin};
  letter-spacing: ${$letterSpacing};
  clear: float;
  `,
);
