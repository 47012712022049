import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router';

import { KitchenMenuItem } from 'codegen/generated/graphql';
import CartMenuItem from 'components/CartMenuItem';
import InfoModal from 'components/InfoModal';
import Scroll from 'components/Scroll';
import MenuItemModal from 'containers/MenuItemModal';
import { Cart, MenuItem } from 'utils/apollo/models';
import { cartMutations } from 'utils/apollo/mutations';
import { CartContext } from 'utils/context/cartContext';

interface Props {
  menuItems: Cart;
  isCheckout?: boolean;
}

const OrderSummary = ({ menuItems }: Props) => {
  const menuItemsDisplay: MenuItem[] = menuItems.items;
  const [showMenuItemModal, setShowMenuItemModal] = useState(false);
  const [menuItemCart, setMenuItem] = useState<MenuItem>();
  const [itemId, setId] = useState('');
  const [showRemoveItemModal, setShowRemoveItemModal] = useState(false);
  const [removeItemId, setRemoveItemId] = useState('');
  const { replace } = useHistory();
  const { setRecalculate } = useContext(CartContext);

  useEffect(() => {
    const editItem = menuItemsDisplay.filter((item) => itemId === item.id);
    setMenuItem(editItem[0]);
  }, [itemId, menuItemsDisplay]);

  const onClickAction = () => {
    cartMutations.removeCartMenuItem(1, removeItemId);
    if (menuItemsDisplay.length <= 1) {
      replace('menu');
    }
    setRecalculate(true);
    setShowRemoveItemModal(false);
  };

  return (
    <>
      <Scroll>
        {menuItems.items.length > 0 &&
          menuItems.items.map((menuItem: MenuItem) => {
            return (
              <CartMenuItem
                key={menuItem?.id}
                //TODO: refactor to split out these from being a total object
                menuItem={{
                  name: menuItem?.kitchenMenuItem?.name,
                  price: menuItem?.kitchenMenuItem?.price,
                  selectedExtras: menuItem.selectedExtras,
                  totalPrice: menuItem.totalPrice,
                  quantity: menuItem.quantity,
                  kitchenMenuItemId: menuItem?.kitchenMenuItem?.id,
                  id: menuItem.id,
                  kitchenMenuItem: menuItem.kitchenMenuItem,
                }}
                setRemoveModal={(id) => {
                  setRemoveItemId(id);
                  setShowRemoveItemModal(true);
                }}
                setMenuItemModal={(id, menuItem) => {
                  if (menuItem && id) {
                    setId(id);
                    setShowMenuItemModal(true);
                  }
                }}
              />
            );
          })}
      </Scroll>
      <InfoModal
        visible={showRemoveItemModal}
        title="Are you sure you want to remove this item from your cart?"
        buttonText="Remove Item"
        onClickAction={onClickAction}
        onClickBack={() => setShowRemoveItemModal(false)}
        basic
      />
      {itemId && (
        <MenuItemModal
          isCheckout={true}
          cartMenuItem={menuItemCart}
          handleModalClose={() => {
            setShowMenuItemModal(false);
            cartMutations.deleteMenuItem(menuItemCart?.id ?? '');
          }}
          kitchenMenuItemId={menuItemCart?.kitchenMenuItem?.id ?? ''}
          id={itemId}
          visible={showMenuItemModal}
          kitchenMenuItem={menuItemCart?.kitchenMenuItem as KitchenMenuItem}
        />
      )}
    </>
  );
};

export default OrderSummary;
