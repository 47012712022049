const Routes = {
  LOGIN: {
    path: '/',
    hash: '#/',
  },
  CLOSED: {
    path: '/closed',
    hash: '#/closed',
  },
  CHECKOUT: {
    path: '/checkout',
    hash: '#/checkout',
  },
  MENU: {
    path: '/menu',
    hash: '#/menu',
  },
  RESET_PASSWORD: {
    path: '/reset-password',
    hash: '#/reset-password',
  },
  SETTINGS: {
    path: '/settings',
    hash: '#/settings',
  },
  DEVELOPER_TOOLS: {
    path: '/developer',
    hash: '#/developer',
  },
};

export default Routes;
