import { transparentize } from 'polished';
import { DefaultTheme } from 'styled-components';

// TODO: Setup values for the project
export const theme: DefaultTheme = {
  name: 'Kiosk App Theme',
  breakpoints: ['1336px', '1440px', '1920px', '2560px'],
  color: {
    transparent: 'rgba(0,0,0,0)',
    primary: '#FFFFFF',
    white: '#FFFFFF',
    black: '#161615',
    gray: '#C4C4C4',
    lightGray: '#EEEEEE',
    tan: '#F0E7D7',
    eggshell: '#FAF9F7',
    forest: '#0C3B32',
    green: '#669563',
    warning: '#B85151',
  },
  fontSize: {
    h1: '56px',
    h2: '34px',
    h3: '24px',

    xxs: '14px',
    xs: '16px',
    sm: '18px',
    lg: '22px',
    xl: '24px',
    xxl: '50px',
  },
  spacing: {
    none: '0',
    xs: '8px',
    sm: '12px',
    md: '16px',
    'md-lg': '20px',
    lg: '24px',
    xl: '40px',
    layout: '250px',
    auto: 'auto',
  },
  fontFamily: {
    primary: 'benton-sans',
  },
  fontWeight: {
    thin: '300',
    normal: '400',
    semiBold: '600',
    bold: '700',
  },
  lineHeight: {
    small: '1',
    normal: '1.35',
    large: '1.5',
    xLarge: '1.75',
    xxLarge: '2',
    xxxl: '5.75',
    initial: 'initial',
  },
  iconButtonSizes: {
    small: '24px',
    normal: '32px',
    medium: '40px',
    large: '48px',
  },
  iconSizes: {
    small: '14px',
    normal: '18px',
    medium: '20px',
    large: '24px',
  },
  borderRadius: {
    none: '0',
    xSmall: '6px',
    small: '10px',
    normal: '12px',
    medium: '16px',
    large: '20px',
    xl: '25px',
    xxl: '50px',
    xxxxl: '100%',
    half: '50%',
  },
  letterSpacings: {},
  sizes: [],
  borders: [
    '0px solid rgba(0, 0, 0, 0)',
    '1px solid rgba(48, 30, 53, 0.1)',
    '1px solid rgba(0, 0, 0, 0.06)',
    '2px solid #0080FF',
    '1px solid #DDE4EB',
    '2px solid #F4F4F5',
    '2px solid #FEA026',
    '2px dotted #CC2E2E',
    '2px solid #EAE8EA',
  ],
  borderWidths: [],
  borderStyles: {},
  shadows: {
    card: '0px 0px 16px rgba(135, 135, 135, 0.08)',
    smallCard: '0px -1px 16px rgba(135, 135, 135, 0.13)',
    inlineCard: '0px 4px 10px rgba(145, 145, 145, 0.08)',
    popover: '0px 0px 10px rgba(84, 84, 84, 0.09)',
    border: '0px 0px 0px 2px black',
  },
  modalSize: {
    alert: 340,
    normal: 440,
    large: 600,
    small: 200,
  },
  /**
   * Z-Indices higher than 10
   * needs to put here
   * lower than 10 values used inside
   * component elements.
   * Modal Should be in front all
   * Shadow Should be behind modal
   * Navbar is the 3rd on top
   * Arragement should be modal,shadow,navbar, ... others
   */
  zIndices: {
    dropdown: 15,
    modal: 15,
    shadow: 14,
    navbar: 10,
    common: 5,
  },
  transitions: {},
};

export const parseColor = (
  value: keyof typeof theme.color | [keyof typeof theme.color, number],
) => {
  let parsedColor = '';
  if (typeof value === 'string') {
    parsedColor = theme.color[value];
  }
  if (Array.isArray(value)) {
    const [c, opacity] = value;
    parsedColor = transparentize(1 - opacity, theme.color[c]);
  }

  return parsedColor;
};
