import React from 'react';

import { Switch, Tooltip } from 'antd';

import { usePaymentCtx } from 'paymentContext';

const PaymentsSwitch = () => {
  const { isPaymentMocked, setIsPaymentMocked } = usePaymentCtx();

  return (
    <Tooltip title="Payments Switch">
      <Switch
        onChange={(state) => setIsPaymentMocked(!state)}
        checkedChildren={<span>Payments enabled</span>}
        unCheckedChildren={<span>Payments mocked</span>}
        checked={!isPaymentMocked}
      />
    </Tooltip>
  );
};

export default PaymentsSwitch;
