import { ReactiveVar } from '@apollo/client';
import { merge, uniqueId } from 'lodash';

import { Cart, MenuItem } from '../models';

let sortNumber = 0;

export default (singleCartVar: ReactiveVar<Cart>) => {
  return (id: number, item: MenuItem | undefined, tipAmount?: number) => {
    sortNumber = sortNumber + 1;

    const cartsWithEdit = (cart: Cart) => {
      const updatedCartItem = merge({}, item, {
        ...{
          id: uniqueId('menu_item_'),
          quantity: 1,
          selectedExtras: item?.selectedExtras,
          sortNumber,
        },
      });

      const allCartItems = [...cart.items, updatedCartItem];
      const sortedCartItems = allCartItems.sort((a, b) =>
        Number(a.sortNumber) > Number(b.sortNumber) ? 1 : -1,
      );
      const promoDiscount = cart.promoDiscount ?? 0;
      const giftCardsDiscount = cart.giftCardsDiscount ?? 0;

      const fee = cart.fee;
      const delivery = cart.delivery ?? 0;
      const subTotal = cart.subTotal;
      const tax = cart.tax;
      const tip = cart.tip;

      return cart.id === id
        ? {
            id: cart.id,
            orderName: cart.orderName,
            items: item ? sortedCartItems : cart.items,
            subTotal,
            tax,
            tip,
            delivery,
            fee,
            // FIXME: Use total calculated on backend
            total:
              subTotal +
              fee +
              tax +
              tip -
              promoDiscount +
              giftCardsDiscount +
              delivery,
            promoDiscount: cart.promoDiscount,
            giftCardsDiscount: cart.giftCardsDiscount,
            promoCodes: cart.promoCodes,
            recalculateDiscount: cart.subTotal > 0,
          }
        : cart;
    };

    singleCartVar(cartsWithEdit(singleCartVar()));
  };
};
