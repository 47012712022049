import React, {
  FC,
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from 'react';

import { InputProps } from 'antd';
import _uniqueId from 'lodash/uniqueId';
import { Color, FontSizes } from 'styled-components';

import { TextAlignProps } from 'utils/styleHelpers';

import { KeyboardContext, ACTIONS } from '../../utils/context/keyboard';

import { InputAtom } from './styles';

export interface Props extends InputProps {
  display?: 'flex' | 'inline';
  background?: keyof Color;
  color?: keyof Color;
  height?: string;
  align?: TextAlignProps;
  width?: string;
  padding?: string;
  fontSize?: keyof FontSizes;
  placeholderColor?: keyof Color;
  borderColor?: string;
}

interface ExtendedProps extends Omit<Props, 'onChange'> {
  onChange?: (e: string) => void;
  value?: string;
}

export const Input: FC<ExtendedProps> = ({
  onChange = () => {},
  value,
  id,
  ...props
}) => {
  const [uid] = useState(id || _uniqueId('input-prefix'));
  const { dispatch, keyboard } = useContext(KeyboardContext);
  const ref = useRef<any>();
  const setValue = useCallback(
    (value: string) => {
      onChange(value);
    },
    [onChange],
  );

  useEffect(() => {
    ref &&
      dispatch({
        type: ACTIONS.ADD_INPUT_REFERENCE,
        payload: { ref },
      });
  }, [dispatch, ref]);

  const handleOnFocus = () => {
    dispatch({
      type: ACTIONS.SET_FOCUSED_INPUT,
      payload: { id: uid },
    });
    dispatch({
      type: ACTIONS.SET_ON_CHANGE_HANDLER,
      payload: (inputs: any) => setValue(inputs[uid]),
    });
  };

  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (keyboard && keyboard.current) {
        keyboard.current.setInput(e.target.value);
      }
      setValue(e.target.value);
    },
    [keyboard, setValue],
  );

  return (
    <InputAtom
      ref={ref}
      id={id}
      value={value}
      {...props}
      onFocus={handleOnFocus}
      onChange={handleOnChange}
      onPressEnter={(e: any) => {
        if (e.key === 'Enter') e.preventDefault();
      }}
    />
  );
};
