import { MenuItem } from 'utils/apollo/models';

export const reduceCartItems = (cartItems: MenuItem[]) => {
  //stringify item obj and remove kiosk menu id and sort
  const cartItemsToString = cartItems.map((item) =>
    JSON.stringify(
      Object.fromEntries(
        Object.entries(item).filter(
          ([key]) => key !== 'id' && key !== 'sortNumber' && key !== 'quantity',
        ),
      ),
    ),
  );

  //Get total number of duplicate items
  const itemCount = cartItemsToString.reduce(
    (prev, curr) => ({ ...prev, [curr]: (prev[curr] || 0) + 1 }),
    {} as { [key: string]: number },
  );

  //update those items with new quantity value
  const updatedItems = cartItemsToString.map((item) => {
    return {
      ...JSON.parse(item),
      id: JSON.parse(item).kitchenMenuItem.id,
      quantity: itemCount[item],
    };
  });

  //create new obj to replace cart.items
  const updateCartItems = updatedItems.filter(
    (item, index, updatedItemsArr) =>
      index ===
      updatedItemsArr.findIndex((updatedItem) => {
        const filterItemString = JSON.stringify({
          item: item.kitchenMenuItem,
          selectedExtras: item.selectedExtras,
          id: item.id,
        });

        const updatedItemString = JSON.stringify({
          item: updatedItem.kitchenMenuItem,
          selectedExtras: updatedItem.selectedExtras,
          id: updatedItem.id,
        });

        return filterItemString === updatedItemString;
      }),
  );

  return updateCartItems as MenuItem[];
};
