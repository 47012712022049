import { Row } from 'antd';
import styled from 'styled-components';

export interface Props {
  $width?: string;
  $height?: string;
  $marginT?: string;
  $marginB?: string;
  $alignItems?: string;
}

export const QuantitySelectorWrapper = styled(Row)`
  flex: 3;
  width: 125px;
  height: 50px;
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: space-between;
  align-items: center;
  .ant-btn {
    background: #f0e7d7;
    font-size: 15px;
    font-weight: 600;
  }
  .ant-btn:focus {
    background: #f0e7d7;
    border-color: #f0e7d7;
  }
  .ant-btn:hover {
    background: #f0e7d7;
    border-color: #f0e7d7;
  }
`;

export const MultiSelectQuantityCounterWrapper = styled(Row)`
  align-items: center;
  .ant-btn {
    background: #f0e7d7;
    font-size: 15px;
    font-weight: 600;
  }
  button[disabled] {
    background: #f4f4f4;
  }
`;
