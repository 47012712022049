import React, { useState, useCallback, useContext } from 'react';
import { useHistory } from 'react-router';

import { Image, message, Button as AntButton } from 'antd';
import { useLongPress, LongPressDetectEvents } from 'use-long-press';

import { AuthContext } from 'authContext';
import ActionModal from 'components/ActionModal';
import { Input } from 'components/Input';
import { Row } from 'components/Layout';
import Text from 'components/Text';
import { ButtonLink, AntButtonWrapper } from 'containers/SideBar/styles';
import Routes from 'router/routes';
import { auth } from 'utils/firebase';

type Props = {
  showLogo?: boolean;
};

const LongPressLogout = ({ showLogo }: Props) => {
  const [showLogOutModal, setShowLogOutModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const history = useHistory();
  const { user, logout } = useContext(AuthContext);

  // Long-press for secret logout feature
  const secretLogoutEnabled = true;
  const callback = useCallback(() => {
    setShowLogOutModal(true);
  }, []);
  const bind = useLongPress(secretLogoutEnabled ? callback : null, {
    threshold: 5000,
    captureEvent: true,
    detect: LongPressDetectEvents.BOTH,
  });

  const onSubmit = async () => {
    try {
      history.push(Routes.LOGIN.path);
      logout();
    } catch (e) {
      message.error((e as Error).message);
    }
  };

  const checkPassword = async () => {
    try {
      await auth.signInWithEmailAndPassword(user?.email ?? '', password);
      history.push(Routes.SETTINGS.path);
    } catch (e) {
      setError((e as Error).message);
    }
  };

  const facilityLogo =
    user?.facilities[0].kioskLogoUrl ?? '/svg/crave-black.svg';

  return (
    <>
      <ButtonLink {...bind}>
        <Image
          src={showLogo ? facilityLogo : '/images/closed-kiosk.png'}
          alt="Facility Logo"
          preview={false}
        />
      </ButtonLink>
      <ActionModal
        visible={showLogOutModal}
        title="Are you sure you would like to log out?"
        buttonText="Log Out"
        onClickAction={onSubmit}
        onClickBack={() => setShowLogOutModal(false)}
      >
        <AntButtonWrapper $display="flex" $flexDirection="column">
          <AntButton
            onClick={() => {
              setShowLogOutModal(false);
              setShowPasswordModal(true);
            }}
          >
            Update Kiosk Settings
          </AntButton>
        </AntButtonWrapper>
      </ActionModal>
      <ActionModal
        visible={showPasswordModal}
        title="Enter your password."
        buttonText="Submit"
        onClickAction={() => checkPassword()}
        onClickBack={() => {
          setPassword('');
          setError('');
          setShowPasswordModal(false);
        }}
        width="720px"
        error={error}
      >
        <Text spaceAfter="lg" textAlign="center">
          Enter the password for {user?.name} to proceed.
        </Text>
        <Row $margin="0 0 80px 0">
          <Input
            name="password"
            type="password"
            onChange={(e) => setPassword(e)}
            value={password}
            placeholder={'Password'}
            bordered={true}
            width="340px"
            height="50px"
            align="left"
            borderColor={error ? '#B85151' : '#000000'}
          />
        </Row>
      </ActionModal>
    </>
  );
};

export default LongPressLogout;
