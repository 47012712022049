import { InMemoryCache } from '@apollo/client';

import { cartsVars, menuItemVars } from './localStore';

export const cache = new InMemoryCache({
  dataIdFromObject: (data) => {
    // Handles repeating ids on categories
    if (data.__typename === 'MenuCategory') {
      return undefined;
    }

    if (!data.id || !data.__typename) {
      return undefined;
    }

    return `${data.__typename}:${data.id}`;
  },
  typePolicies: {
    Query: {
      fields: {
        carts: {
          read() {
            return cartsVars();
          },
        },
        menuItem: {
          read() {
            return menuItemVars();
          },
        },
      },
    },
  },
});
