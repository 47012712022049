import { OrderValidationError } from 'codegen/generated/graphql';

const ORDER_VALIDATION_ERROR_FNS: {
  [key in OrderValidationError]?: string;
} = {
  [OrderValidationError.MissingItems]:
    'Please add an item to cart to place an order',
  [OrderValidationError.PickupOnlyItems]:
    'At least one item in your cart is not available for delivery, please remove it to proceed',
  [OrderValidationError.KitchenIsNotAvailable]:
    'At least one item in your cart is not available, please remove it to proceed',
  [OrderValidationError.TimeSlotPassed]:
    'The order time you selected is no longer available, please select a new time',
};

export const getOrderValidationErrorMessage = (error: OrderValidationError) => {
  const errorMessage = ORDER_VALIDATION_ERROR_FNS[error];

  if (!errorMessage)
    return 'There was an issue processing your order. Please try again.';

  return errorMessage;
};
