import styled from 'styled-components';

import { theme } from 'theme';

export const CarouselWrapper = styled.div`
  .ant-carousel {
    li.slick-active,
    slick-dots,
    slick-dots-bottom,
    button {
      height: 14px !important;
      width: 14px !important;
      border-radius: 50% !important;
      background: ${theme.color.black} !important;
    }
    .slick-dots li {
      background: ${theme.color.lightGray};
      border-radius: 50%;
      height: 14px;
      width: 14px;
    }
    li {
      margin: 12px;
    }

    .slick-slider {
      padding-bottom: 30px;
    }
  }
`;
