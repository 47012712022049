import React from 'react';

import LandingLayout from 'containers/LandingLayout';
import LoginCont from 'containers/Login';

const Login: React.FC = () => {
  return (
    <LandingLayout>
      <LoginCont />
    </LandingLayout>
  );
};

export default Login;
