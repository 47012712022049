import styled from 'styled-components';

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0px 70px 0px 75px;
`;

interface InputModalProps {
  $width?: string;
}

export const InputModalWrapper = styled.div<InputModalProps>(
  ({ $width = '465px' }) => `
  position: relative;
  width: ${$width};
  margin-top: 50px;
  margin-bottom: 16px;
  height: 80px;
  `,
);

export const InputModalButtonWrapper = styled.div`
  width: 200px;
  align-self: center;
`;
