import {
  Allergy,
  DishType,
  PromoValidationError,
  OrderValidationError,
} from 'codegen/generated/graphql';

export const allergyValues = [
  { value: Allergy.PeanutFree, label: 'Peanut free' },
  { value: Allergy.DairyFree, label: 'Dairy free' },
  { value: Allergy.GlutenFree, label: 'Gluten free' },
  { value: Allergy.ShellfishFree, label: 'Shellfish free' },
  { value: Allergy.TreeNutFree, label: 'Tree nut free' },
];

export const dishTypeValues = [
  { value: DishType.KidFriendly, label: 'Kid friendly' },
  { value: DishType.Alcohol, label: 'Alcohol' },
  { value: DishType.Vegan, label: 'Vegan' },
  { value: DishType.Vegetarian, label: 'Vegetarian' },
];
export const ITEMS_MAX = 12;

export const KITCHENS_MAX = 7;

export const TAX = 0.06;

export const SERVICE_FEE = 0.1;

export const DELIVERY_FEE = 4.99;

export const TAG_NAMES = {
  DELIVERY: 'delivery',
};

export const TipAmounts = [
  { label: '10%', amount: 0.1 },
  { label: '15%', amount: 0.15 },
  { label: '20%', amount: 0.2 },
  { label: 'Custom', amount: 0.0 },
];
export const MAX_TIP_AMOUNT = 99.99;
export const TOUCH_KEYBOARD_INPUT_LIMITS = {
  orderNameInput: 25,
};

export const ACCEPTABLE_NAMES = ['Dick', 'Fanny', 'Willy', 'Gaylord'];

export const PROMO_VALIDATION_ERRORS: { [k in PromoValidationError]: string } =
  {
    [PromoValidationError.InvalidPromo]:
      'Code is invalid. Try using a different code',
    [PromoValidationError.PromoNotApplicable]:
      'The order does not contain a single item that is described in the promo',
    [PromoValidationError.PromoBuyXGetYInvalid]:
      'The order does not contain one of the items or just one item from promo type Buy X get Y',
    [PromoValidationError.PromoFreeDeliveryInvalid]:
      'Promo offers free delivery but the order is pick up',
    [PromoValidationError.PromoFreeXInvalid]:
      'The order does not contain an item that is described in type FREE X',
    [PromoValidationError.PromoNotYetValid]:
      "Code isn't valid yet. Try using it at a later time",
    [PromoValidationError.PromoIsNotCustomer]:
      'Promo is invalid. Try a different promo code',
    [PromoValidationError.PromoIsNotEmployee]:
      'Promo is valid only for employees. Try a different promo code',
    [PromoValidationError.PromoIsNotInSegmentGroup]:
      'Promo is invalid. Try a different promo code',
    [PromoValidationError.PromoNoMoreValid]:
      'Code has expired. Try using a different code',
    [PromoValidationError.PromoUnderMinAmount]:
      'Promo requires a minimum order amount. Try to add more items to the cart',
    [PromoValidationError.PromoUnderMinItems]:
      'Promo needs a minimum amount of items to apply. Try to add more items to the cart',
    [PromoValidationError.PromoWasUsed]:
      'Code has already been used. Try a different code',
    [PromoValidationError.PromoWasUsedX]:
      'Code was used too many times. Try a different code',
    [PromoValidationError.PromoWrongDayOfWeek]:
      "Code doesn't apply to this day of the week. Try ordering for a different day of the week",
    [PromoValidationError.PromoWrongOrderMethod]:
      "Promo isn't applicable for this order method. Try to change the order method",
    [PromoValidationError.TooManyPromos]:
      'You can use only one promo code per order.',
  };

export const ORDER_VALIDATION_ERRORS: { [k in OrderValidationError]: string } =
  {
    [OrderValidationError.KitchenIsNotAvailable]: 'Kitchen is not available.',
    [OrderValidationError.MissingAddress]:
      'Delivery address is required. Please make sure you type your address correctly.',
    [OrderValidationError.MissingItems]: 'Please add items to your cart.',
    [OrderValidationError.OrderMethodNotAvailable]:
      'Order method is not available.',
    [OrderValidationError.OutOfDelivery]: 'Out of delivery zone.',
    [OrderValidationError.PickupOnlyItems]: 'Pickup only items.',
    [OrderValidationError.TimeSlotPassed]: 'Time slot passed.',
    [OrderValidationError.UnavailableItems]: 'Unavailable items.',
  };

export const SPECIAL_MENU = 'SpecialMenu';
