import { ReactiveVar } from '@apollo/client';

import { cartsInitialValue } from '../localStore';
import { Cart } from '../models';

export default function deleteCart(cartVars: ReactiveVar<Cart>) {
  return () => {
    cartVars(cartsInitialValue);
  };
}
