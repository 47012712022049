import React, { useContext } from 'react';

import { format } from 'date-fns';

import { ItemWrapper } from 'components/Layout';
import Text from 'components/Text';
import { HoursContext } from 'utils/context/operationHours';

const Closed = () => {
  const { timeToReopen } = useContext(HoursContext);

  return (
    <>
      <ItemWrapper $width="550px" $flex={2}>
        <Text
          fontSize="h2"
          color="white"
          textAlign="center"
          fontWeight="semiBold"
        >
          This facility is currently
        </Text>
        <Text
          fontSize="h2"
          color="white"
          textAlign="center"
          fontWeight="semiBold"
        >
          closed or not taking new orders.
        </Text>
      </ItemWrapper>
      <ItemWrapper $width="530px" $flex={9}>
        <Text
          fontSize="h2"
          color="white"
          textAlign="center"
          fontWeight="semiBold"
        >
          {`Orders are expected to resume at ${format(
            timeToReopen,
            'hh:mm b',
          )}.`}
        </Text>
      </ItemWrapper>
    </>
  );
};

export default Closed;
