import React, { useContext } from 'react';

import { Switch, Tooltip } from 'antd';

import { FacilityContext } from 'utils/context/facilityContext';
import {
  Location,
  FacilityId,
  Timezone,
} from 'utils/context/facilityContext/constants';

const FacilitySwitch = () => {
  const { location, setFacilityId, setFacilityTz, setLocation } =
    useContext(FacilityContext);

  const selectFacility = (isUS: boolean) => {
    setLocation(isUS ? Location.US : Location.EU);
    setFacilityId(isUS ? FacilityId.US : FacilityId.EU);
    setFacilityTz(isUS ? Timezone.US : Timezone.EU);
    window.location.reload(true);
  };

  return (
    <Tooltip title="Facility Switch: use US (Boise HQ) or EU (Prague) facility to work and test in the timezone you prefer.">
      <Switch
        onChange={selectFacility}
        checkedChildren={Location.US}
        unCheckedChildren={Location.EU}
        checked={location === Location.US}
      />
    </Tooltip>
  );
};

export default FacilitySwitch;
