import React, { useRef } from 'react';

import { Carousel as AntCarousel } from 'antd';

import { CarouselWrapper } from './styles';

const Carousel: React.FC = ({ children }) => {
  const carousel = useRef<any>(null);

  return (
    <CarouselWrapper>
      <AntCarousel
        ref={carousel}
        swipeToSlide
        draggable
        dots
        edgeFriction={1}
        dotPosition="bottom"
      >
        {children}
      </AntCarousel>
    </CarouselWrapper>
  );
};

export default Carousel;
