import React from 'react';

import { sumBy } from 'lodash';

import {
  Allergy,
  DishType,
  KitchenMenuItemQuery,
} from 'codegen/generated/graphql';
import Button from 'components/Button';
import { Row, Column } from 'components/Layout';
import QuantitySelector from 'components/QuantitySelector';
import Text from 'components/Text';
import { MenuItem } from 'utils/apollo/models';
import { priceFormatter } from 'utils/formatHelpers';

import ItemDetail from './ItemDetail';
import ItemExtras from './ItemExtras';
import ResturauntLogo from './RestaurantLogo';

interface Props {
  resturauntLogoImg: string;
  name: string;
  description: string;
  allergies: Allergy[];
  dishTypes: DishType[];
  price: number;
  extras: KitchenMenuItemQuery['customerKitchenMenuItem']['extras'];
  isCheckout: boolean;
  cartMenuItem: MenuItem | undefined;
  menuItem: MenuItem;
  itemQuantity: number;
  soldOut: boolean;
  setItemQuantity: (quantity: number) => void;
  showSubTotal: boolean;
  handleAddCartClick: () => void;
}

const MenuItemModalBody = ({
  resturauntLogoImg,
  name,
  description,
  allergies,
  dishTypes,
  price,
  extras,
  isCheckout,
  cartMenuItem,
  menuItem,
  itemQuantity,
  soldOut,
  setItemQuantity,
  showSubTotal,
  handleAddCartClick,
}: Props) => {
  const extrasMinimumMet = () => {
    if (!extras.length) {
      return true;
    } else {
      const extrasWithRequiredMin = extras.filter((extra) => extra.required);

      if (!extrasWithRequiredMin.length) {
        return true;
      } else {
        const itemWithExtras = isCheckout ? cartMenuItem : menuItem;
        let minimumMet = true;

        extrasWithRequiredMin.forEach((extra) => {
          //if extra is single-select, make sure one of it's choices is selected
          const singleMinMet = () => {
            if (!extra.singleOption) {
              return true;
            }

            return extra.items.some((extraItem) =>
              itemWithExtras?.selectedExtras.some(
                (selected) => selected.item.id === extraItem.id,
              ),
            );
          };

          //if extra is multi-select, make sure sumBy of it's selected items is greater than or equal to extra.min
          const multiMinMet = () => {
            if (extra.singleOption) {
              return true;
            }
            const selectedChoices = itemWithExtras?.selectedExtras.filter(
              (selected) => selected.parentId === extra.id,
            );

            return sumBy(selectedChoices, 'quantity') >= extra.minimum;
          };

          if (!singleMinMet() || !multiMinMet()) {
            minimumMet = false;
          }
        });

        return minimumMet;
      }
    }
  };

  return (
    <Column $padding="40px 45px 0 45px !important">
      <ResturauntLogo logoSrc={resturauntLogoImg} />
      <ItemDetail
        name={name}
        description={description}
        allergies={allergies}
        dishTypes={dishTypes}
        price={price}
      />

      {extras.length > 0 && (
        <ItemExtras
          extras={extras}
          isCheckout={isCheckout ?? false}
          cartMenuItem={cartMenuItem}
          menuItem={menuItem}
        />
      )}
      <Row $width="fit-content">
        <QuantitySelector
          size="middle"
          quantity={itemQuantity}
          handleAddBtnClick={() => {
            setItemQuantity(itemQuantity + 1);
          }}
          handleMinusBtnClick={() => {
            if (itemQuantity > 1) {
              setItemQuantity(itemQuantity - 1);
            }
          }}
        />
        {showSubTotal && (
          <Text marginLeft="sm" alignSelf="center">
            {!cartMenuItem
              ? priceFormatter(menuItem.totalPrice * itemQuantity)
              : priceFormatter(cartMenuItem.totalPrice * itemQuantity)}
          </Text>
        )}
      </Row>
      <Button
        display="inline"
        primary
        fontSize="h3"
        text={isCheckout ? 'Update' : soldOut ? 'Sold Out' : 'Add to Cart'}
        onClick={handleAddCartClick}
        disabled={soldOut || !extrasMinimumMet()}
      />
    </Column>
  );
};

export default MenuItemModalBody;
