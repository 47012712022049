import { message } from 'antd';
import { uniq } from 'lodash';

import {
  OrderValidationError,
  PromoValidationErrorWithCode,
} from 'codegen/generated/graphql';

import { ORDER_VALIDATION_ERRORS, PROMO_VALIDATION_ERRORS } from './constants';

export const getPromoErrorMessage = (e: PromoValidationErrorWithCode) =>
  `${e.promoCode}: ${PROMO_VALIDATION_ERRORS[e.error]}`;

export const getOrderErrorMessage = (e: OrderValidationError) =>
  ORDER_VALIDATION_ERRORS[e];

export const getQueryErrorMessage = (e: unknown) =>
  `Validation failed: ${(e as Error)?.message || 'Unknown error'}`;

export const formatValidationErrors = (
  promoErrors?: PromoValidationErrorWithCode[] | null,
  orderErrors?: OrderValidationError[] | null,
  queryErrors?: readonly unknown[] | null,
) =>
  promoErrors?.length || orderErrors?.length || queryErrors?.length
    ? uniq([
        ...(promoErrors?.map(getPromoErrorMessage) ?? []),
        ...(orderErrors?.map(getOrderErrorMessage) ?? []),
        ...(queryErrors?.map(getQueryErrorMessage) ?? []),
      ])
    : [];

export const reportValidationErrors = (
  ...errors: Parameters<typeof formatValidationErrors>
) => formatValidationErrors(...errors).map((e) => message.error(e)).length;
