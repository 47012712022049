import React, { useRef, useEffect } from 'react';

import { ItemWrapper } from 'components/Layout';
import Scroll from 'components/Scroll';
import OrderSummary from 'containers/OrderSummary';
import { useAppContext } from 'utils/context';

import { SideCartContainer } from '../styles';

const SideCart = () => {
  const { cart: menuItems } = useAppContext();
  const itemRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    itemRef?.current?.scrollIntoView({ behavior: 'smooth' });
  };
  useEffect(scrollToBottom, [menuItems]);

  return (
    <SideCartContainer>
      <Scroll>
        <ItemWrapper>
          <OrderSummary menuItems={menuItems} />
          <div ref={itemRef} />
        </ItemWrapper>
      </Scroll>
    </SideCartContainer>
  );
};

export default SideCart;
