import { isStaging } from 'utils/env';

import { Props } from './types';

export enum Location {
  'EU' = 'EU',
  'US' = 'US',
}

export enum Timezone {
  'EU' = 'Europe/Prague',
  'US' = 'America/Boise',
}

export enum FacilityId {
  'EU' = 'facility-prague',
  'US' = 'facility-downtown',
}

export const LOCATION = !isStaging ? Location.US : Location.EU;
export const TIMEZONE = !isStaging ? Timezone.US : Timezone.EU;
export const FACILITY_ID = !isStaging ? FacilityId.US : FacilityId.EU;

// Todo: Location, TimeZone & facilityId can't have real initial values. It will be hard to debug when we have tens of each values
export const INITIAL_VALUES: Props = {
  location: LOCATION,
  facilityId: FACILITY_ID,
  facilityTz: TIMEZONE,
  setLocation: () => null,
  setFacilityId: () => null,
  setFacilityTz: () => null,
};
