import React from 'react';

import LandingLayout from 'containers/LandingLayout';
import ResetPassword from 'containers/ResetPassword';

const ForgotPassword = () => {
  return (
    <LandingLayout>
      <ResetPassword />
    </LandingLayout>
  );
};

export default ForgotPassword;
