import React, { useState } from 'react';

import Button from 'components/Button';
import InfoModal from 'components/InfoModal';
import { InfoModalProps } from 'components/InfoModal/InfoModal';
import { Input } from 'components/Input';
import { ItemWrapper } from 'components/Layout';
import Text from 'components/Text';

import { Wrapper, InputModalWrapper, InputModalButtonWrapper } from './styles';

interface InputModalProps extends InfoModalProps {
  id: string;
  message: string;
  onClose: () => void;
  onChange?: (e: string) => void;
  placeholder?: string;
  submitText: string;
  onSubmit: (state: string) => void;
  value?: string;
  loading?: boolean;
  errorMessages: string[];
  format: (s: string) => string;
}

const InputModal = ({
  title,
  visible,
  id,
  onClose,
  placeholder,
  submitText,
  onSubmit,
  loading,
  message,
  errorMessages,
  format,
}: InputModalProps) => {
  const [inputState, setInputState] = useState<string>('');

  const handleInputSubmit = () => {
    onSubmit(format(inputState));
    setInputState('');
  };

  return (
    <InfoModal
      title={title}
      visible={visible}
      width={'719px'}
      cancelText={'Cancel'}
    >
      <Wrapper>
        <Text alignSelf="center" textAlign="center">
          {message}
        </Text>
        <InputModalWrapper>
          <Input
            id={id}
            bordered={false}
            onChange={setInputState}
            placeholder={placeholder}
            value={format(inputState)}
            align="center"
            borderColor={errorMessages.length > 0 ? '#B85151' : 'black'}
          />
        </InputModalWrapper>
        {errorMessages?.map((errorMessage, index) => {
          return (
            <ItemWrapper $flex={0.25} key={index}>
              <Text
                color="warning"
                textAlign="center"
                fontSize="lg"
                spaceAfter="lg"
              >
                {errorMessage}
              </Text>
            </ItemWrapper>
          );
        })}
        <InputModalButtonWrapper>
          <Button
            marginT="xl"
            size="large"
            primary
            text={submitText}
            padding="11px 30px"
            onClick={handleInputSubmit}
            // FIXME: Loading spinner is back
            // loading={loading}
            disabled={loading}
            color="white"
          />
        </InputModalButtonWrapper>

        <Button marginT="lg" skeleton text="Go Back" onClick={onClose} />
      </Wrapper>
    </InfoModal>
  );
};

export default InputModal;
