import { Layout } from 'antd';
import styled from 'styled-components';

import { background } from 'utils/styleHelpers';

const { Sider } = Layout;

export const AntLayout = styled(Layout)`
  position: absolute;
  display: flex;
  flex-direction: row;
  height: 100%;
  width: calc(100% - 320px);
  justify-content: center;
  overflow: hidden;
  margin: 0 auto;
  text-align: center;

  .ant-layout {
    height: 100%;
  }
  .ant-layout-content {
    ${background('eggshell')};
    padding: 20px;
  }
  .ant-card {
    text-align: center;
  }
`;

export const AntSider = styled(Sider).attrs({
  width: 320,
})`
  height: 100vh;
  width: 100%;
  background: white !important;
  position: fixed;
  right: 0;
  z-index: 1;
  width: 320px;
`;
