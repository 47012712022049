import React, {
  FC,
  createContext,
  useState,
  useContext,
  useEffect,
} from 'react';

import { useReactiveVar } from '@apollo/client';

import { AuthContext } from 'authContext';
import {
  cartsVars,
  menuItemVars,
  menuItemInitialValue,
  cartsInitialValue,
} from 'utils/apollo/localStore';
import { Cart, MenuItem } from 'utils/apollo/models';
import { cartMutations } from 'utils/apollo/mutations';

interface Props {
  ageConfirmed: boolean;
  cart: Cart;
  menuItems: MenuItem;
  isPaymentProcessing: boolean;
  emptyCart: boolean;
  setAgeConfirmed: (value: boolean) => void;
  setIsPaymentProcessing: (value: boolean) => void;
  setStartNewOrder: (value: boolean) => void;
  selectedKitchen: string;
  setSelectedKitchen: (value: string) => void;
}

export const AppContext = createContext<Props>({
  ageConfirmed: false,
  cart: cartsInitialValue,
  menuItems: menuItemInitialValue,
  isPaymentProcessing: false,
  emptyCart: true,
  setAgeConfirmed: (_) => undefined,
  setIsPaymentProcessing: (_) => undefined,
  setStartNewOrder: (_) => undefined,
  selectedKitchen: '',
  setSelectedKitchen: () => '',
});

export const useAppContext = () => useContext(AppContext);

const AppContextProvider: FC = ({ children }) => {
  const { isDelivery } = useContext(AuthContext);

  const [ageConfirmed, setAgeConfirmed] = useState(false);
  const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);
  const [startNewOrder, setStartNewOrder] = useState(true);
  const [emptyCart, setEmptyCart] = useState(true);
  const [selectedKitchen, setSelectedKitchen] = useState('SpecialMenu');
  const cart = useReactiveVar(cartsVars);
  const menuItems = useReactiveVar(menuItemVars);

  useEffect(() => {
    if (startNewOrder) {
      cartMutations.deleteCart();
      cartMutations.createCart('', isDelivery);
      setSelectedKitchen('SpecialMenu');
      setStartNewOrder(false);
    }
  }, [startNewOrder, isDelivery]);

  useEffect(() => {
    if (cart.items.length > 0) setEmptyCart(false);
    else setEmptyCart(true);
  }, [cart]);

  return (
    <>
      <AppContext.Provider
        value={{
          ageConfirmed,
          setAgeConfirmed,
          cart,
          menuItems,
          isPaymentProcessing,
          setIsPaymentProcessing,
          setStartNewOrder,
          emptyCart,
          selectedKitchen,
          setSelectedKitchen,
        }}
      >
        {children}
      </AppContext.Provider>
    </>
  );
};

export default AppContextProvider;
