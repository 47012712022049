import { ReactiveVar } from '@apollo/client';

import { DELIVERY_FEE } from 'utils/constants';

import { Cart } from '../models';

export default function createAddCart(singleCartVar: ReactiveVar<Cart>) {
  const createNewCart = (orderName: string, isDelivery: boolean) => {
    return {
      items: [],
      orderName,
      subTotal: 0,
      tax: 0,
      total: 0,
      fee: 0,
      tip: 0,
      delivery: isDelivery ? DELIVERY_FEE : 0,
      id: 1,
      promoDiscount: 0,
      giftCardsDiscount: 0,
      promoCodes: [],
    };
  };

  return (orderName: string, isDelivery: boolean) => {
    singleCartVar(createNewCart(orderName, isDelivery));
  };
}
