import { Button } from 'antd';
import styled from 'styled-components';

export const KeyboardWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0px;
  width: 100%;
  display: block;
  transform: translateY(100%);
  transition: all 0.35s;

  &.show {
    display: block;
    transform: translateY(0);
    z-index: 9999;
  }
`;

export const CloseBtn = styled(Button)`
  background-color: black !important;
  margin-left: 20px;
`;
