import { Card } from 'antd';
import styled from 'styled-components';

interface Props {
  $border: boolean;
  $logoUrl?: string;
  $special?: boolean;
}

export const KitchenCard = styled(Card)<Props>(
  ({ $border, $logoUrl, $special = false }) => `
    width: 150px;
    height: 150px;
    border-radius: 30px !important;
    box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.1) !important;
    border: ${$border ? '2px solid #000000' : 'none'} !important;
    background: no-repeat center/${
      $special ? '100%' : '80%'
    } url('${$logoUrl}') !important;
  `,
);
