import styled from 'styled-components';

import { theme } from 'theme';

export const CheckoutBarContainerWrapper = styled.div`
  width: 100%;
  padding: 50px;
  background-color: ${theme.color.black};
`;

export const PlaceOrderBtnWrapper = styled.div`
  align-self: flex-end;
  flex: 5;
  margin-top: 20px;
`;

export const Wrapper = styled.div`
  algin-items: center;
  display: flex;
  flex-direction: column;
  padding: 0px 70px 0px 75px;
`;
