import { Modal as M } from 'antd';
import styled from 'styled-components';

import { theme } from 'theme';

interface Props {
  $height?: string;
  $width?: string;
}

export const Modal = styled(M)<Props>(
  ({ $height = 'auto', $width = '535px' }) => `
  width: ${$width};
  text-align: center !important;
  white-space: pre-wrap;
 .ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.5) !important;
  }
  .ant-modal-content {
    padding: 35px !important;
    height: ${$height};
    align-items: center;
    justify-content: center;
    border-radius: 40px;
  }
  .ant-modal-title {
    width: 100%;
    border-bottom: none !important;
    padding: 20px 10px;
  }
  .ant-modal-header {
    border-bottom: none;
  }
  .ant-modal-header .ant-modal-title {
    font-weight: ${theme.fontWeight.bold};
    font-size: ${theme.fontSize.h2};
    line-height: ${theme.lineHeight.normal};
    text-align: center;
  }
  .ant-modal-centered {
    justify-content: center;
    flex-direction: center;

  }
  `,
);
