import styled, { BorderRadius } from 'styled-components';

import { marginT, radius, SpacingType } from 'utils/styleHelpers';

export const Logo = styled.img<{
  $marginTop?: SpacingType;
  $borderRadius?: keyof BorderRadius;
  objectFit?: string;
  $maxHeight?: string;
}>(
  ({ $marginTop, $borderRadius, objectFit = 'cover', $maxHeight }) => `
  margin: 0 auto;
  object-fit: ${objectFit};
 ${radius($borderRadius ?? 'none')}
 ${marginT($marginTop ?? 'none')}
  max-height: ${$maxHeight ? $maxHeight : 'none'};
`,
);
