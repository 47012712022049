import React from 'react';

import { ItemWrapper } from 'components/Layout';
import LongPressLogout from 'components/LongPress';
import Text from 'components/Text';
import { useAppContext } from 'utils/context';

import { ButtonLink, OrderInfoContainer } from '../styles';

interface Props {
  isDelivery: boolean;
  deliveryAddress: {
    line1: string | null | undefined;
    line2: string;
  };
  orderName: string;
  setOpenModal: React.Dispatch<boolean>;
}

const OrderInfo = ({
  isDelivery,
  deliveryAddress,
  orderName,
  setOpenModal,
}: Props) => {
  const { cart } = useAppContext();

  const showStartOver = cart.items.length > 0 ? true : false;

  return (
    <OrderInfoContainer>
      <ItemWrapper $flex={1} $padding="90px 0 0 0">
        <LongPressLogout showLogo />
      </ItemWrapper>
      {isDelivery && (
        <>
          <ItemWrapper $margin="32px 0 0 0" $flex={0.5}>
            <Text textAlign="center" fontSize="sm" fontWeight="bold">
              Delivery To
            </Text>
          </ItemWrapper>
          <ItemWrapper $flex={1}>
            <Text textAlign="center">
              {deliveryAddress ? deliveryAddress.line1 : ''}
            </Text>
          </ItemWrapper>
          <ItemWrapper $flex={1}>
            <Text textAlign="center">
              {deliveryAddress ? deliveryAddress.line2 : ''}
            </Text>
          </ItemWrapper>
        </>
      )}
      {cart.orderName && (
        <ItemWrapper $flex={0.5} $padding="32px 0 0 0">
          <Text textAlign="center" fontSize="sm" fontWeight="bold">
            Order Name
          </Text>
        </ItemWrapper>
      )}
      <ItemWrapper $flex={1}>
        <Text textAlign="center">{orderName}</Text>
      </ItemWrapper>
      {showStartOver && (
        <ItemWrapper $margin="32px 0 32px 0" $flex={1}>
          <ButtonLink
            $showTextColor={showStartOver}
            onClick={() => setOpenModal(true)}
          >
            Start Over
          </ButtonLink>
        </ItemWrapper>
      )}
    </OrderInfoContainer>
  );
};

export default OrderInfo;
