import { Col, Row as R, RowProps as InitialRowProps } from 'antd';
import { Property as CsstypeProperty } from 'csstype';
import styled from 'styled-components';

import { theme } from 'theme';
import {
  SpacingType,
  justifyContent,
  JustifyContentProps,
  display,
  alignItems,
  AlignItemsProps,
  FlexDirectionProps,
  flexDirection,
  paddingB,
  TextAlignProps,
  textAlign,
  minHeight,
  position,
  PositonType,
  ColorProps,
  background,
} from 'utils/styleHelpers';

interface WrapperProps {
  $padding?: string;
  $flex?: number;
  $display?: CsstypeProperty.Display;
  $justify?: JustifyContentProps;
  $align?: AlignItemsProps;
  $margin?: string;
  $flexDirection?: FlexDirectionProps;
  $height?: string;
  $width?: string;
  $position?: PositonType;
  $rightPos?: string;
}

export const ItemWrapper = styled.div<WrapperProps>(
  ({
    $padding = '0px',
    $flex,
    $display = 'inline',
    $justify = 'center',
    $align = 'center',
    $margin = '0 auto',
    $flexDirection = 'column',
    $height,
    $width,
    $position = 'relative',
    $rightPos,
  }) => `
  ${display($display)}
  ${justifyContent($justify)}
  ${alignItems($align)}
  ${flexDirection($flexDirection)}
  ${position($position)}
  padding: ${$padding};
  right: ${$rightPos};
  flex: ${$flex};
  margin: ${$margin} !important;
  height: ${$height};
  width: ${$width};
`,
);
interface ColProps {
  $justifyContent?: JustifyContentProps;
  $padding?: string;
  $width?: string;
  $paddingBottom?: SpacingType;
}

export const Column = styled(Col)<ColProps>(
  ({
    $justifyContent = 'center',
    $padding = 0,
    $width = 'auto',
    $paddingBottom = 'none',
  }) => `
    ${justifyContent($justifyContent)}
    ${paddingB($paddingBottom)}
    padding: ${$padding};
    width: ${$width};
  `,
);

interface RowProps extends InitialRowProps {
  $padding?: string;
  $textAlign?: TextAlignProps;
  $minHeight?: string;
  $maxHeight?: string;
  $justifyContent?: JustifyContentProps;
  $flex?: number;
  $width?: string;
  $position?: PositonType;
  $topPos?: string;
  $rightPos?: string;
  $bgColor?: ColorProps;
  $borderRadius?: string;
  $height?: string;
  $margin?: string;
}

export const Row = styled(R)<RowProps>(
  ({
    $padding = '0',
    $textAlign = 'center',
    $minHeight = 'auto',
    $maxHeight = 'auto',
    $justifyContent = 'center',
    $flex = '1',
    $width = 'auto',
    $position = 'relative',
    $topPos,
    $rightPos,
    $bgColor = 'transparent',
    $borderRadius,
    $height,
    $margin,
  }) => `
    ${textAlign($textAlign)};
    ${minHeight($minHeight)};
    ${justifyContent($justifyContent)};
    ${position($position)};
    ${background($bgColor)};
    border-radius: ${$borderRadius};
    max-height: ${$maxHeight};
    height: ${$height};
    flex: ${$flex};
    padding: ${$padding}; 
    display: flex;
    top: ${$topPos};
    right: ${$rightPos};
    margin: ${$margin};
    width: ${$width};
  `,
);

export const Divider = styled.div`
  border-bottom: 1px solid ${theme.color.lightGray};
  padding-top: 5px;
  width: 100%;
`;
