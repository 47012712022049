import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { AuthContext } from 'authContext';
import { UserRole } from 'codegen/generated/graphql';
import Routes from 'router/routes';

const PublicRoute: React.FC<{
  component: React.FC;
  path: string;
  exact: boolean;
}> = ({ component, path, exact }) => {
  const { user } = useContext(AuthContext);

  return !user || !user.roles.includes(UserRole.KioskCustomer) ? (
    <Route path={path} exact={exact} component={component} />
  ) : (
    <Redirect to={Routes.MENU.path} />
  );
};
export default PublicRoute;
