import React from 'react';

import { Logo } from 'components/Image';

interface Props {
  logoSrc: string;
}

const RestaurantLogo = ({ logoSrc }: Props) => {
  return (
    <Logo src={logoSrc} width="100px" height="100px" objectFit="contain" />
  );
};

export default RestaurantLogo;
