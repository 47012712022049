import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
`;

export const Scrollable = styled.div`
  overflow-x: hidden;
  overflow-y: scroll;
  justify: center;
  width: 100%;
  height: 100%;
  scroll-behavior: auto;
  scroll-snap-type: y mandatory;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  ::-webkit-scrollbar {
    display: none;
  }
`;
