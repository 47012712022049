import React, { useState } from 'react';
import { useHistory } from 'react-router';

import { Space } from 'antd';

import { useResetPasswordMutation } from 'codegen/generated/graphql';
import Button from 'components/Button';
import { Input } from 'components/Input';
import { ItemWrapper } from 'components/Layout';
import Text from 'components/Text';
import Routes from 'router/routes';

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [resetPassword] = useResetPasswordMutation();
  const [message, setMessage] = useState({ error: '', sucess: '' });

  const { push } = useHistory();

  return (
    <>
      <ItemWrapper $flex={0.7}>
        <Text
          color="white"
          textAlign="center"
          fontWeight="semiBold"
          fontSize="h3"
        >
          No worries.
        </Text>
        <Text
          color="white"
          textAlign="center"
          fontWeight="semiBold"
          fontSize="h3"
        >
          Enter your email and we&apos;ll send
        </Text>
        <Text
          color="white"
          textAlign="center"
          fontWeight="semiBold"
          fontSize="h3"
        >
          you a link to reset your password.
        </Text>
      </ItemWrapper>
      <ItemWrapper $flex={0.75}>
        <Input
          value={email}
          placeholder="Email"
          onChange={(e) => setEmail(e)}
          bordered={false}
          width="650px"
          align="left"
          onPressEnter={(e) => {
            if (e.key === 'Enter') e.preventDefault();
          }}
        />
      </ItemWrapper>
      {message.error && (
        <ItemWrapper $flex={1}>
          <Text color="warning" textAlign="left" displayFlex={false}>
            {message.error}
          </Text>
        </ItemWrapper>
      )}
      {message.sucess && (
        <ItemWrapper $flex={1}>
          <Text color="green" textAlign="left" displayFlex={false}>
            {message.sucess}
          </Text>
        </ItemWrapper>
      )}
      <ItemWrapper $flex={3}>
        <Space size="small" direction="vertical">
          <Button
            title="Reset password"
            size="large"
            onClick={async () => {
              try {
                await resetPassword({ variables: { email } });
                setMessage({ error: '', sucess: 'Email was sent!' });
              } catch (err) {
                setMessage({ error: 'Invalid email!', sucess: '' });
              }
            }}
            text="Send Recovery Link"
          />
          <div
            onClick={() => {
              push(Routes.LOGIN.path);
            }}
          >
            <Text marginTop="md-lg" color="white" textAlign="center">
              Return to Login
            </Text>
          </div>
        </Space>
      </ItemWrapper>
    </>
  );
};

export default ResetPassword;
