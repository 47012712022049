import React from 'react';

import { Row } from 'components/Layout';
import Text from 'components/Text';

import { Box } from '../styles';

interface Props {
  subtotal: ({
    id: string;
    label: string;
    value: string;
  } | null)[];
  total: {
    label: string;
    value: string;
  };
}

const PriceSection = ({ subtotal, total }: Props) => {
  return (
    <Box bgColor="lightGray" height={210}>
      {subtotal.map((subtotalAmount, i) => (
        <Row key={i} $margin="0 0 12px 0" $justifyContent="space-between">
          <Text
            key={`label${subtotalAmount?.label}-${i}`}
            fontWeight="bold"
            fontSize="xxs"
          >
            {subtotalAmount?.label}
          </Text>
          <Text
            key={`value${subtotalAmount?.value}-${i}`}
            fontWeight="bold"
            fontSize="xxs"
          >
            {subtotalAmount?.value}
          </Text>
        </Row>
      ))}
      <Row>
        <Text fontWeight="bold" fontSize="xxs">
          {total.label}
        </Text>
      </Row>
      <Row>
        <Text fontWeight="bold" fontSize="h2">{`$${total.value}`}</Text>
      </Row>
    </Box>
  );
};

export default PriceSection;
