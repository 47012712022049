import { ReactiveVar } from '@apollo/client';
import { without } from 'lodash';

import { KitchenMenuItemQuery } from 'codegen/generated/graphql';

import { merge, getMenuItemSubTotal } from '../helpers';
import { Cart, ExtraItem } from '../models';

interface Props {
  cartId: number;
  id?: string;
  extras?: ExtraItem[] | [];
  price?: number;
  quantity?: number;
  menuItem?: KitchenMenuItemQuery['customerKitchenMenuItem'];
}

export default (cartsVar: ReactiveVar<Cart>) => {
  return ({ cartId, extras, price, quantity, menuItem, id }: Props) => {
    const carts = (cart: Cart) => {
      if (cart.id === cartId) {
        //new values
        const values = {
          selectedExtras: extras,
          price,
          quantity,
        };
        //Get current menu item to update
        const menuItemToUpdate = cart.items.find((i) => i.id === id);

        //Update exisiting menu item
        const updatedMenuItem = merge({}, menuItemToUpdate, values);

        //Get subTotal
        const menuItemSubTotal = getMenuItemSubTotal(
          updatedMenuItem.kitchenMenuItem,
          updatedMenuItem.quantity,
          updatedMenuItem.selectedExtras,
        );

        //Update the price of the menu item
        const updatedMenuItemPrice = merge({}, updatedMenuItem, {
          totalPrice: menuItemSubTotal,
        });

        //Get all other menuItems
        const otherMenuItems = without(cart.items, menuItemToUpdate);

        //Create new Arr with exisiting menu items and newly updated menu item
        const allMenuItems = [...otherMenuItems, updatedMenuItemPrice].sort(
          (a, b) => (a.sortNumber > b.sortNumber ? 1 : -1),
        );

        //update the cart.items arr with the newly update array of menu items
        const updatedCart = merge({}, cart, {
          items: allMenuItems,
          subTotal: cart.subTotal,
          tax: cart.tax,
          total: cart.tax + cart.subTotal,
        }) as Cart;

        return updatedCart;
      } else {
        return cart;
      }
    };

    cartsVar(carts(cartsVar()));
  };
};
