import { pullAt } from 'lodash';

import { KitchenMenuItemQuery } from 'codegen/generated/graphql';

import { TAX } from '../constants';
import { MenuItem, ExtraItem } from './models';

// TODO: Change subtotal calculation - https://github.com/oakslab/crave-monorepo/pull/3007#discussion_r869531325
export const getSubTotal = (
  item: MenuItem | undefined,
  subTotal: number,
  remove?: boolean,
) => {
  if (item?.kitchenMenuItem) subTotal = subTotal + item.kitchenMenuItem.price;

  if (item?.selectedExtras !== undefined)
    item.selectedExtras.forEach((extra) => {
      subTotal = subTotal + extra.item.price * extra.quantity;
    });

  return subTotal;
};

export const getTaxAmount = (subTotal: number) => {
  return subTotal * TAX;
};

export const getTipTotal = (
  subTotal: number,
  tax: number,
  tipAmount: number,
) => {
  const subTotalTax = subTotal + tax;
  const tipTotal = subTotalTax * tipAmount;

  return tipTotal;
};

export const merge = (target: any, ...sources: any) => {
  const selectedExrasArray = [] as ExtraItem[];

  for (const source of sources) {
    for (const key of Object.keys(source)) {
      const val = source[key];

      if (key === 'selectedExtras' && val !== undefined) {
        for (const extra of val) {
          const existing: number = selectedExrasArray.findIndex(
            (selected) => selected.item.id === extra.item.id,
          );
          //for single select extras to replace old choice with new incoming choice
          const existingSingleSelectExtra = selectedExrasArray.findIndex(
            (selected) =>
              selected.parentId === extra.parentId && extra.singleSelect,
          );

          //new choice
          if (
            existing === -1 &&
            existingSingleSelectExtra === -1 &&
            extra.quantity !== 0
          ) {
            selectedExrasArray.push(extra);
            //new single-select choice, replacing old single-select choice
          } else if (existing === -1 && existingSingleSelectExtra !== -1) {
            selectedExrasArray[existingSingleSelectExtra] = extra;
            //remove existing multi-select choice because incoming quantity is 0
          } else if (existing !== -1 && extra.quantity === 0) {
            pullAt(selectedExrasArray, [existing]);
            //existing multi-select
          } else {
            selectedExrasArray[existing] = extra;
          }
        }
      } else {
        if (val !== undefined) {
          target[key] = val;
        }
      }
    }
  }

  target['selectedExtras'] = selectedExrasArray;

  return target;
};

export const getMenuItemSubTotal = (
  item: KitchenMenuItemQuery['customerKitchenMenuItem'],
  itemQuantity: number,
  extras: ExtraItem[],
) => {
  let subTotal = item.price;
  extras.forEach((i) => {
    subTotal = subTotal + i.quantity * i.item.price;
  });

  return subTotal;
};
